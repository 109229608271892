import React, { Component } from 'react';
import classnames from 'classnames/bind';
import { convertToDecimal } from 'services';

import Image from 'components/Image/Image';
import Header from 'components/Typography/Header/Header';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';

import Bottle from 'components/Icons/Bottle';

import MDRender from 'services/MDRender';

import styles from './NewProductCard.css';

const cx = classnames.bind(styles);

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];

const plantTypes = {
  Sativa: { color: '#FDD835', name: 'Sativa', icon: <Icon name="sun" inverted style={{ marginRight: '0em' }} size="large" /> },
  Indica: { color: '#5094B1', name: 'Indica', icon: <Icon name="moon outline" inverted style={{ marginRight: '0em' }} size="large" /> },
  Hybrid: { color: '#39D4CF', name: 'Hybrid', icon: <Icon name="magic" inverted style={{ marginRight: '0em' }} size="large" /> },
  CBD: { color: '#edb3bf', name: 'CBD', icon: <Bottle color="#edb3bf" /> },
  'Hybrid: Sativa-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #FDD835 85%)',
    name: 'Hybrid: Sativa',
    icon: <div style={{ display: 'inline-block' }}>
      <Icon name="magic" inverted size="large" /> :
      <Icon name="sun" inverted size="large" />
    </div>,
  },
  'Hybrid: Indica-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #5094B1 85%)',
    name: 'Hybrid: Indica',
    icon: <div style={{ display: 'inline-block', color: 'white' }}>
      <Icon name="magic" inverted size="large" /> :
      <Icon name="moon outline" inverted size="large" />
    </div>,
  },
  'Indica: Sativa-dominant': {
    color: 'linear-gradient(90deg, #5094B1 10%, #FDD835 85%)',
    name: 'Indica: Sativa',
    icon: <div style={{ display: 'inline-block' }}>
      <Icon name="moon outline" size="large" /> :
      <Icon name="sun" inverted size="large" />
    </div>,
  },
};


const NewProductCard = ({ card }) => {
  const markdownDesc = MDRender(card.description);
  let cost = '';
  let quantity = null;
  switch (card.packing) {
    case 'oz': {
      const steps = Object.keys(card.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a));
      cost = card.price[steps[steps.length - 1]];
      quantity = steps[steps.length - 1];
      break;
    }

    case 'piece': {
      cost = card.price;
      quantity = 1;
      break;
    }

    case 'gram': {
      const grams = Object.keys(card.price);
      const parseGrams = grams.map(value => parseFloat(value));
      parseGrams.sort((a, b) => a - b);
      cost = card.price[parseGrams[0]];
      quantity = parseGrams[0].toString();
      break;
    }

    case 'piece_discrete': {
      cost = card.price[0];
      quantity = 1;
      break;
    }

    case 'gram_oz': {
      const filterGramOz = [];
      Object.keys(card.price).forEach((price, index) => {
        if (GRAM_OZ.indexOf(price) >= 0) {
          filterGramOz.push({
            position: index,
            packing: price,
            cost: card.price[price],
          });
        }
      });
      const sortGramOz = filterGramOz
        .sort((a, b) => a.position - b.position)
        .map(value => value.packing);
      cost = card.price[sortGramOz[0]];
      quantity = sortGramOz[0];
      break;
    }

    default:
      break;
  }

  return (
    <div className={cx('product-container')}>
      <div style={{
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '0.75em',
        height: '100%',
      }}>
        <div>
          <div style={{ position: 'relative', minHeight: '178.82px' }}>
            <Image src={card.image && card.image.default} />
            <Button style={{ position: 'absolute', bottom: '-4px', left: '50%', transform: 'translate(-50%, 3px)' }} type="button" color="orange">Quick view</Button>
            {
              card.plant_type &&
              <div style={{ position: 'absolute', backgroundColor: plantTypes[card.plant_type].color, top: '0px', right: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: '4px', padding: '0.5em' }}>
                {plantTypes[card.plant_type].icon}
              </div>
            }
          </div>
          <div>
            <div style={{
              marginTop: '1em'
            }}>
              <div style={{ fontWeight: 700, fontSize: '1.2em' }}>{card.name}</div>
              <div style={{ color: '#696969', fontSize: '0.9em' }}>{card.brand}</div>
            </div>
            <div className={cx('product-description')} dangerouslySetInnerHTML={{
              __html: markdownDesc.length > 115 ? `${markdownDesc.slice(0, 115)}...` : markdownDesc,
            }} />

            <div style={{ marginTop: '1em' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '33%' }}>Size:</div>
                <div>{card.packing}</div>
              </div>
              <div style={{ marginTop: '0.25em', display: 'flex' }}>
                <div style={{ width: '33.33333333%' }}>Scent:</div>
                <div style={{ color: 'black', fontWeight: 600, textDecoration: 'underline', width: '33.33333333%' }}>8.4 fl oz</div>
                <div style={{ width: '33.33333333%' }}>16.9 fl oz</div>
              </div>
              <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', }}>
                <div style={{ display: 'flex', width: '33%' }}>Quantity</div>
                <div style={{ display: 'flex', width: '33.33333333%', fontSize: '1.4em' }}>
                  <span>-</span><span style={{ width: '36px', textAlign: 'center' }}> 1 </span><span>+</span>
                </div>
                <div style={{ color: 'black', fontWeight: 600, textDecoration: 'underline', width: '33.33333333%' }}>Use Guide</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: card.product_id % 2 === 0 ? 'black' : '#db2828', fontSize: '1.5em', textDecoration: card.product_id % 2 === 0 && 'line-through' }}>${!cost ? 0 : Math.round(cost) === 0 ? cost.toFixed(2) : Math.round(cost)}</div>
            {
              card.product_id % 2 === 0 ?
                <div style={{ width: '41px', fontSize: '1.5em', marginLeft: '0.5em' }}>${Math.round(cost) - 2}</div> :
                <div style={{ width: '41px', fontSize: '0.7em', marginLeft: '0.5em', lineHeight: '1em' }}>Save up to 10%</div>
            }
          </div>
          <div style={{ display: 'flex', fontSize: '1.3em', alignItems: 'center', color: '#fda285' }}>
            <Icon color="orange" size="large" name="cart" /> add
          </div>
        </div>
      </div >
    </div >
  )
}

export default NewProductCard;
