import React from 'react';

export const IconBigTimer = () => (
  <svg
    width="28px"
    height="32px"
    viewBox="0 0 28 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Orders"
        transform="translate(-670.000000, -597.000000)"
        fillRule="nonzero"
        fill="#5595B0"
      >
        <g id="Group-3" transform="translate(666.000000, 596.000000)">
          <g id="ic_timer_black_24px">
            <path
              d="M22.5,1.5 L13.5,1.5 L13.5,4.5 L22.5,4.5 L22.5,1.5 Z M16.5,21 L19.5,21 L19.5,12 L16.5,12 L16.5,21 Z M28.545,11.085 L30.675,8.955 C30.03,8.19 29.325,7.47 28.56,6.84 L26.43,8.97 C24.105,7.11 21.18,6 18,6 C10.545,6 4.5,12.045 4.5,19.5 C4.5,26.955 10.53,33 18,33 C25.47,33 31.5,26.955 31.5,19.5 C31.5,16.32 30.39,13.395 28.545,11.085 Z M18,30 C12.195,30 7.5,25.305 7.5,19.5 C7.5,13.695 12.195,9 18,9 C23.805,9 28.5,13.695 28.5,19.5 C28.5,25.305 23.805,30 18,30 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const IconBigCar = () => (
  <svg
    width="58px"
    height="47px"
    viewBox="0 0 58 47"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Orders" transform="translate(-691.000000, -451.000000)" fillRule="nonzero">
        <g id="Group-5" transform="translate(397.000000, 451.000000)">
          <g id="car" transform="translate(294.000000, 0.000000)">
            <g id="Group">
              <polygon
                id="Shape"
                fill="#BDC3C7"
                points="6.651 18.837 4.651 19.837 4.651 4.837 6.651 4.837"
              />
              <path
                d="M55.709,29.666 C53.918,26.779 50.612,23.67 44.674,23.67 L39.674,23.67 C39.674,23.67 39.591,11.837 23.674,11.837 C23.674,11.837 12.037,12.563 4.651,18.34 C0.992,21.202 3.875,24.656 2.201,28.359 C1.374,30.189 0.541,32.376 0.062,34.588 C-0.423,36.827 2.034,38.837 5.171,38.837 L6.674,38.837 C6.674,34.419 10.256,30.837 14.674,30.837 C19.092,30.837 22.674,34.419 22.674,38.837 L34.674,38.837 C34.674,34.419 38.256,30.837 42.674,30.837 C47.091,30.837 50.672,34.417 50.674,38.834 L50.674,38.837 L54.603,38.837 C56.299,38.837 57.674,37.844 57.674,36.619 C57.674,34.229 57.061,31.847 55.709,29.666 Z"
                id="Shape"
                fill="#35C3EE"
              />
              <path
                d="M23.674,16.447 C23.674,16.447 14.151,15.674 9.651,22.837 L34.674,22.837 C34.674,22.837 33.757,16.267 23.674,16.447 Z"
                id="Shape"
                fill="#556080"
              />
              <circle id="Oval" fill="#ECF0F1" cx="5.651" cy="3.837" r="3" />
              <circle id="Oval" fill="#556080" cx="14.674" cy="38.837" r="8" />
              <circle id="Oval" fill="#D1D4D1" cx="14.674" cy="38.837" r="3" />
              <circle id="Oval" fill="#556080" cx="42.674" cy="38.837" r="8" />
              <circle id="Oval" fill="#D1D4D1" cx="42.674" cy="38.837" r="3" />
              <path
                d="M25.651,31.17 C25.098,31.17 24.651,30.722 24.651,30.17 L24.651,27.837 L20.673,27.837 C20.12,27.837 19.673,27.389 19.673,26.837 C19.673,26.285 20.12,25.837 20.673,25.837 L25.651,25.837 C26.204,25.837 26.651,26.285 26.651,26.837 L26.651,30.17 C26.651,30.722 26.203,31.17 25.651,31.17 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M57.122,32.821 C56.8,31.739 56.338,30.68 55.709,29.666 C54.979,28.489 53.99,27.278 52.68,26.252 C51.83,26.95 51.309,27.861 51.309,28.864 C51.309,31.058 53.771,32.836 56.809,32.836 C56.915,32.837 57.017,32.825 57.122,32.821 Z"
                id="Shape"
                fill="#F0C419"
              />
              <path
                d="M24.651,22.837 L26.651,22.837 L26.651,16.626 C26.035,16.54 25.364,16.491 24.651,16.471 L24.651,22.837 Z"
                id="Shape"
                fill="#8697CB"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Cross = props => (
  <svg
    onClick={props.onClick}
    width="14px"
    style={props.styles}
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-4"
        transform="translate(-1080.000000, -313.000000)"
        fillRule="nonzero"
        fill="#656565"
      >
        <g id="ic_close_black_24px-copy" transform="translate(1075.000000, 308.000000)">
          <polygon
            id="Shape"
            points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const NextArrow = props => (
  <svg
    style={props.styles}
    width="8px"
    height="12px"
    viewBox="0 0 15 24"
    fill="#a0a0a0"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-1109.000000, -435.000000)"
        fillRule="nonzero"
        fill={props.styles && props.styles.fill === '#fff' ? '#fff' : '#363F48'}
      >
        <g id="Group-9" transform="translate(301.000000, 245.000000)">
          <polygon
            id="Shape"
            transform="translate(815.526400, 202.000000) scale(-1, 1) translate(-815.526400, -202.000000) "
            points="822.64 210.8128 813.8464 202 822.64 193.1872 819.9328 190.48 808.4128 202 819.9328 213.52"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const PrevArrow = props => (
  <svg
    style={props.styles}
    width="8px"
    height="12px"
    viewBox="0 0 15 24"
    fill="#a0a0a0"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-316.000000, -435.000000)"
        fillRule="nonzero"
        fill={props.styles && props.styles.fill === '#fff' ? '#fff' : '#363F48'}
      >
        <g id="Group-9" transform="translate(301.000000, 245.000000)">
          <polygon
            id="Shape"
            points="29.5872 210.8128 20.7936 202 29.5872 193.1872 26.88 190.48 15.36 202 26.88 213.52"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Car = () => (
  <svg
    width="24px"
    height="20px"
    viewBox="0 0 24 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Current-orders-menagement"
        transform="translate(-652.000000, -297.000000)"
        fillRule="nonzero"
      >
        <g id="Group-4" transform="translate(310.000000, 292.000000)">
          <g id="Group-8" transform="translate(316.000000, 5.000000)">
            <g id="car" transform="translate(26.000000, 0.000000)">
              <g id="Group">
                <polygon
                  id="Shape"
                  fill="#BDC3C7"
                  points="2.76711631 7.83704253 1.935026 8.25308768 1.935026 2.0124104 2.76711631 2.0124104"
                />
                <path
                  d="M23.1774594,12.3423955 C22.4323225,11.1412731 21.0568772,9.84778875 18.5864011,9.84778875 L16.5061754,9.84778875 C16.5061754,9.84778875 16.4716436,4.92472647 9.84945293,4.92472647 C9.84945293,4.92472647 5.0079355,5.22677525 1.935026,7.63026809 C0.412716791,8.82098932 1.61217496,10.2580093 0.91571538,11.7986245 C0.571646039,12.5599871 0.225080427,13.4698778 0.0257947994,14.3901697 C-0.175987099,15.3216948 0.84623584,16.1579456 2.15136948,16.1579456 L2.77668535,16.1579456 C2.77668535,14.3198581 4.26695908,12.8295844 6.10504656,12.8295844 C7.94313405,12.8295844 9.43340778,14.3198581 9.43340778,16.1579456 L14.4259496,16.1579456 C14.4259496,14.3198581 15.9162233,12.8295844 17.7543108,12.8295844 C19.5919823,12.8295844 21.08184,14.319026 21.082672,16.1566974 L21.082672,16.1579456 L22.7173134,16.1579456 C23.422926,16.1579456 23.9949881,15.7448127 23.9949881,15.2351574 C23.9949881,14.2408095 23.7399524,13.24979 23.1774594,12.3423955 Z"
                  id="Shape"
                  fill="#35C3EE"
                />
                <path
                  d="M9.84945293,6.84269462 C9.84945293,6.84269462 5.88745495,6.52109172 4.01525176,9.50122314 L14.4259496,9.50122314 C14.4259496,9.50122314 14.0444362,6.76780649 9.84945293,6.84269462 Z"
                  id="Shape"
                  fill="#556080"
                />
                <circle id="Oval" fill="#ECF0F1" cx="2.35107116" cy="1.59636525" r="1.24813546" />
                <circle id="Oval" fill="#556080" cx="6.10504656" cy="16.1579456" r="3.32836122" />
                <circle id="Oval" fill="#D1D4D1" cx="6.10504656" cy="16.1579456" r="1.24813546" />
                <circle id="Oval" fill="#556080" cx="17.7543108" cy="16.1579456" r="3.32836122" />
                <circle id="Oval" fill="#D1D4D1" cx="17.7543108" cy="16.1579456" r="1.24813546" />
                <path
                  d="M10.6719742,12.9681274 C10.4419012,12.9681274 10.255929,12.7817392 10.255929,12.5520822 L10.255929,11.5814489 L8.60090143,11.5814489 C8.37082846,11.5814489 8.18485628,11.3950607 8.18485628,11.1654037 C8.18485628,10.9357468 8.37082846,10.7493586 8.60090143,10.7493586 L10.6719742,10.7493586 C10.9020472,10.7493586 11.0880194,10.9357468 11.0880194,11.1654037 L11.0880194,12.5520822 C11.0880194,12.7817392 10.9016311,12.9681274 10.6719742,12.9681274 Z"
                  id="Shape"
                  fill="#FFFFFF"
                />
                <path
                  d="M23.7653312,13.6550179 C23.6313646,13.2048571 23.4391518,12.7642653 23.1774594,12.3423955 C22.8737464,11.8527103 22.4622778,11.3488797 21.9172586,10.9220173 C21.5636202,11.2124169 21.3468607,11.591434 21.3468607,12.0087273 C21.3468607,12.9215303 22.3711639,13.6612586 23.6351091,13.6612586 C23.6792098,13.6616747 23.7216464,13.6566821 23.7653312,13.6550179 Z"
                  id="Shape"
                  fill="#F0C419"
                />
                <path
                  d="M10.255929,9.50122314 L11.0880194,9.50122314 L11.0880194,6.9171667 C10.8317355,6.88138682 10.5525692,6.8610006 10.255929,6.8526797 L10.255929,9.50122314 Z"
                  id="Shape"
                  fill="#8697CB"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Timer = () => (
  <svg
    width="18px"
    height="21px"
    viewBox="0 0 18 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Current-orders-menagement"
        transform="translate(-1074.000000, -293.000000)"
        fillRule="nonzero"
        fill="#5595B0"
      >
        <g id="Group-4" transform="translate(310.000000, 292.000000)">
          <g id="Group-10" transform="translate(761.000000, 0.000000)">
            <g id="ic_timer_black_24px">
              <path
                d="M15,1 L9,1 L9,3 L15,3 L15,1 Z M11,14 L13,14 L13,8 L11,8 L11,14 Z M19.03,7.39 L20.45,5.97 C20.02,5.46 19.55,4.98 19.04,4.56 L17.62,5.98 C16.07,4.74 14.12,4 12,4 C7.03,4 3,8.03 3,13 C3,17.97 7.02,22 12,22 C16.98,22 21,17.97 21,13 C21,10.88 20.26,8.93 19.03,7.39 Z M12,20 C8.13,20 5,16.87 5,13 C5,9.13 8.13,6 12,6 C15.87,6 19,9.13 19,13 C19,16.87 15.87,20 12,20 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
