import React from 'react';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import classnames from 'classnames/bind';
import styles from './TotalPrice.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { sumPrice, discountCost, taxCost, shippingCost, itemsDeliveryCost } = props;

  const finalPrice = sumPrice - discountCost + (shippingCost + itemsDeliveryCost) + taxCost;

  return (
    <Grid>
      <GridRow textAlign="right" className={cx('custom-row')}>
        <GridColumn computer={13} mobile={10} tablet={13}>
          <div className={cx('total', 'total--second')}>tax:</div>
        </GridColumn>
        <GridColumn computer={3} textAlign="left" mobile={6} tablet={3}>
          <div className={cx('price', 'price--second')}>
            $
            {taxCost}
          </div>
        </GridColumn>
      </GridRow>
      {discountCost !== 0 ? (
        <GridRow textAlign="right" className={cx('custom-row')}>
          <GridColumn computer={13} mobile={10} tablet={13}>
            <div className={cx('total', 'total--second')}>Discount:</div>
          </GridColumn>
          <GridColumn computer={3} textAlign="left" mobile={6} tablet={3}>
            <div className={cx('price', 'price--second')}>
              $
              {discountCost}
            </div>
          </GridColumn>
        </GridRow>
      ) : null}
      {shippingCost > 0 ? (
        <GridRow textAlign="right" className={cx('custom-row')}>
          <GridColumn computer={13} mobile={10} tablet={13}>
            <div className={cx('total', 'total--second')}>Shipping cost:</div>
          </GridColumn>
          <GridColumn computer={3} textAlign="left" mobile={6} tablet={3}>
            <div className={cx('price', 'price--second')}>
              $
              {shippingCost + itemsDeliveryCost}
            </div>
          </GridColumn>
        </GridRow>
      ) : null}
      <GridRow textAlign="right" className={cx('custom-row')}>
        <GridColumn computer={13} mobile={10} tablet={13}>
          <div className={cx('total', 'total--primary')}>total:</div>
        </GridColumn>
        <GridColumn computer={3} textAlign="left" mobile={6} tablet={3}>
          <div className={cx('price', 'price--primary')}>
            $
            {finalPrice && finalPrice.toFixed(2)}
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
