import React, { Component } from 'react';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Input/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import TextArea from 'components/TextArea/TextArea';
import Icon from 'components/Icons/Icon';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import ItemGroup from 'components/Item/ItemGroup';
import Item from 'components/Item/Item';
import ItemImage from 'components/Item/ItemImage';
import ItemContent from 'components/Item/ItemContent';
import ItemHeader from 'components/Item/ItemHeader';
import ItemMeta from 'components/Item/ItemMeta';
import ItemDescription from 'components/Item/ItemDescription';
import Image from 'components/Image/Image';

import Segment from 'components/Segment/Segment';
import Header from 'components/Typography/Header/Header';
import HeaderSubheader from 'components/Typography/Header/HeaderSubheader';

import Label from 'components/Label/Label';


import Button from 'components/Button/Button';

const fields_order = {
  id: 'ID',
  instant_view_link: 'Instant view link',
  store: 'Store',
  order_status: 'Order status',
  user_name: 'User name',
  user_email: 'User email',
  user_phone: 'User phone',
  user_getheally_phone: 'User GetHeally phone',
  getheally_resources: 'GetHeally resources',
  user_resources: 'User resources',
  order_type: 'Order type',
  address: 'Address',
  phone: 'Cell phone',
  payment_type: 'Payment type',
  delivery_time: 'Delivery time',
  total_price: 'Total price',
  delivery_cost: 'Shipping cost',
  final_order_price: 'Final order price',
  tax: 'Tax',
  coupon: 'Coupon',
  updated_at: 'Updated at',
  creation_date: 'Created at',
  manager_notes: 'Manager notes',
  list_of_products: 'Products',
};

const fields_coupon = {
  amount: 'Amount',
  code: 'Code',
  coupon_subtotal: 'Coupon subtotal',
  discount_type: 'Discount type',
  max_coupon_discount: 'Max coupon discount',
};

class ShowOrder extends Component {
  renderResources = data => (
    <Grid>
      <GridRow style={{ border: '1px solid #d4d4d5', background: '#f3f4f5' }}>
        <GridColumn style={{ width: '25%' }} width={4}>ID photo</GridColumn>
        <GridColumn style={{ width: '25%' }} width={4}>Evaluation</GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn style={{ width: '25%' }} width={4}>{data.id_photo ? <Image src={data.id_photo.url} /> : 'no resource'}</GridColumn>
        <GridColumn style={{ width: '25%' }} width={4}>{data.evaluation ? <Image src={data.evaluation.url} /> : 'no resource'}</GridColumn>
      </GridRow>
    </Grid>
  )

  renderObject = (object, field) => {
    if (field === 'user_resources' || field === 'getheally_resources') return null;

    return (
      <Grid>
        {object.map((v, key) => (
          <GridRow key={key}>
            <GridColumn width={3} style={{ width: '18.75%' }}>
              <Image src={v.image && v.image.wide} />
            </GridColumn>
            <GridColumn width={7} style={{ width: '43.75%' }}>
              <Header>{v.name}</Header>
              {v.brand && (
                <HeaderSubheader>
                  Brand:
                  {v.brand}
                </HeaderSubheader>
              )}
            </GridColumn>
            <GridColumn width={3} verticalAlign="middle" style={{ display: 'flex', alignItems: 'center', width: '18.75%' }}>
              {v.quantity}
            </GridColumn>
            <GridColumn width={3} verticalAlign="middle" style={{ display: 'flex', alignItems: 'center', width: '18.75%' }}>
              <Icon name="dollar" />
              {v.price}
            </GridColumn>
          </GridRow>
        ))}
      </Grid>
    );
  }

  renderDate = (value) => {
    const date = new Date(value);

    return date.toLocaleString();
  }

  renderCoupon = (couponDate) => {
    const couponDetails = couponDate ? Object.keys(couponDate) : [];

    return couponDetails.map((field, key) => (
      <Grid key={key}>
        <GridRow>
          <GridColumn style={{ width: '25%' }}><span style={{ fontWeight: 600 }}>{fields_coupon[field]}</span></GridColumn>
          <GridColumn style={{ width: '25%' }}><Label color="teal">{couponDate[field]}</Label></GridColumn>
        </GridRow>
      </Grid>
    ));
  }

  render() {
    const { isFetchingShowOrder, order } = this.props;

    const orderArray = order ? Object.keys(order) : [];

    return (
      <Segment loading={isFetchingShowOrder || !order} basic>
        <Grid verticalAlign="middle">
          {orderArray.map((v, key) => (
            <GridRow key={key} style={v === 'coupon' ? { backgroundColor: order[v] ? '#f5f5f5' : 'white' } : null}>
              <GridColumn style={{ width: '25%' }} width={4}><span style={{ fontWeight: 600 }}>{fields_order[v]}</span></GridColumn>
              {v === 'id' && <GridColumn width={12}>{order[v]}</GridColumn>}
              {v === 'instant_view_link' && <GridColumn width={12}><a href="">{order[v]}</a></GridColumn>}
              {v === 'store' && <GridColumn style={{ width: '75%' }} width={12}><a href="">{order[v]}</a></GridColumn>}
              {v === 'brand' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
              {v === 'order_status' && <GridColumn style={{ width: '75%' }} width={12}><Label color="yellow">{order[v]}</Label></GridColumn>}
              {v === 'user_name' && <GridColumn style={{ width: '75%' }} width={12}><Label>{order[v] || 'no set'}</Label></GridColumn>}
              {v === 'user_email' && <GridColumn style={{ width: '75%' }} width={12}><a href="#">{order[v]}</a></GridColumn>}
              {v === 'user_getheally_phone' && <GridColumn style={{ width: '75%' }} width={12}><Label color="red">{order[v] || 'no phone'}</Label></GridColumn>}
              {v === 'order_type' && <GridColumn style={{ width: '75%' }} width={12}><Label>{order[v]}</Label></GridColumn>}
              {v === 'getheally_resources' && <GridColumn style={{ width: '75%' }} width={12}>{this.renderResources(order[v])}</GridColumn>}
              {v === 'user_resources' && <GridColumn style={{ width: '75%' }} width={12}>{this.renderResources(order[v])}</GridColumn>}
              {v === 'address' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
              {v === 'phone' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
              {v === 'notes' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
              {v === 'user_phone' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
              {v === 'payment_type' && <GridColumn style={{ width: '75%' }} width={12}><Label color="teal">{order[v]}</Label></GridColumn>}
              {v === 'list_of_products' && <GridColumn verticalAlign="middle" style={{ width: '75%' }} width={12}>{this.renderObject(order[v], v)}</GridColumn>}
              {v === 'delivery_time' && <GridColumn style={{ width: '75%' }} width={12}><Label>{order[v] || 'no time set'}</Label></GridColumn>}
              {v === 'total_price' && (
                <GridColumn style={{ width: '75%' }} width={12}>
                  <Icon name="dollar" />
                  <span style={{ fontWeight: 600, fontSize: '1.2em' }}>
                    {order[v]}
                  </span>
                </GridColumn>
              )}
              {v === 'delivery_cost' && (
                <GridColumn style={{ width: '75%' }} width={12}>
                  <Icon name="dollar" />
                  <span style={{ fontWeight: 600, fontSize: '1.2em' }}>
                    {order[v]}
                  </span>
                </GridColumn>
              )}
              {v === 'tax' && (
                <GridColumn style={{ width: '75%' }} width={12}>
                  <span style={{ fontWeight: 600, fontSize: '1.2em' }}>{order[v]}</span>
                </GridColumn>
              )}
              {v === 'coupon' && <GridColumn style={{ width: '75%' }} width={12}>{order[v] ? this.renderCoupon(order[v]) : <Label>no coupon applied</Label>}</GridColumn>}
              {v === 'final_order_price' && (
                <GridColumn style={{ width: '75%' }} width={12}>
                  <Icon name="dollar" />
                  <span style={{ fontWeight: 600, fontSize: '1.2em' }}>
                    {order[v]}
                  </span>
                </GridColumn>
              )}
              {v === 'updated_at' && <GridColumn style={{ width: '75%' }} width={12}>{this.renderDate(order[v])}</GridColumn>}
              {v === 'creation_date' && <GridColumn style={{ width: '75%' }} width={12}>{this.renderDate(order[v])}</GridColumn>}
              {v === 'manager_notes' && <GridColumn style={{ width: '75%' }} width={12}>{order[v]}</GridColumn>}
            </GridRow>
          ))}
        </Grid>
      </Segment>
    );
  }
}

export default ShowOrder;
