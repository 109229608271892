import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import Icon from 'components/Icons/Icon';

import styles from './Dropdown.css';

const cx = classnames.bind(styles);

const DropdownHeader = (props) => {
  const { children, className, content, icon } = props;

  const classes = cx('header', className);
  const rest = getUnhandledProps(DropdownHeader, props);
  const ElementType = getElementType(DropdownHeader, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    )
  }

  return (
    <ElementType {...rest} className={classes}>
      {Icon.create(icon, { autoGenerateKey: false })}
      {content}
    </ElementType>
  )
}

DropdownHeader.create = createShorthandFactory(DropdownHeader, content => ({ content }));

export default DropdownHeader;
