/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import checkboxStyles from './CheckBox.module.scss';

const cx = classnames.bind(checkboxStyles);

class CheckBox extends Component {
  state = {
    checked: this.props.checked,
  };

  onClick = () => {
    const { onChecked } = this.props;
    this.setState({
      checked: !this.state.checked,
    });

    onChecked(!this.state.checked);
  };

  render() {
    const { styles } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        style={styles ? { ...styles } : null}
        className={this.state.checked ? cx('checkbox-active') : cx('checkbox')}
        onClick={this.onClick}
      />
    );
  }
}

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChecked: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
};

export default CheckBox;
