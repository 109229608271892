/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './Button.module.scss';

const cx = classnames.bind(styles);

const Button = props => {
  const { name, type, onClick, style } = props;
  return (
    <button
      style={style && style}
      className={type ? cx(`${type}`) : cx('main-button')}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default Button;
