export const getStores = state => state;

export const getStoreInfo = state => state.storeInfo;

export const getStoreQuery = state => state.storeQuery;

export const getBufferStoreInfo = state => state.bufferStoreInfo;

export const getFetchingStoreInfo = state => state.isFetchingStoreInfo;

export const getIsSameDayDelivery = state => state.isSameDayDelivery;

export const getOneStoreForSameDelivery = state => state.oneStoreForSameDayDelivery;

export const getMessageForStoresOvernight = state => state.messageForStoresOvernight;

export const getListStores = state => state.listStores;

export const getDeliveryMessage = state => state.deliveryMessage;

export const getSameDay = state => state.sameDay;
export const getDeliveryCost = state => state.deliveryCost;
