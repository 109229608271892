const whiteAddressList = ['staging', 'preprod', 'local', 'ximxim'];

export const checkEmail = (email) => {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
  return reg.test(email);
};

export const getDay = (number) => {
  const days = {
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SUT',
  };

  return days[number];
};

export const localStorageSupport = () => {
  const test = 'test';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (err) {
    return false;
  }
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const saveLocalStorage = (name, data, isSaveLocal = true) => new Promise((resolve) => {
  if (localStorageSupport() && isSaveLocal) {
    localStorage.setItem(name, JSON.stringify(data));
    resolve(true);
  } else {
    const date = new Date();
    const domainPath = window.location.hostname.match('getheally') ? 'getheally' : 'heallybuy';
    date.setTime(date.getTime() + 1000 * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
      data
    )};expires="${date.toUTCString()}";domain=.${domainPath}.com;path=/`;
    resolve(true);
  }
});

export const getLocalStorage = (name, isGetLocal = true) => {
  if (localStorageSupport() && isGetLocal) {
    if (localStorage[name]) {
      return JSON.parse(localStorage[name]);
    }
    return '';
  }
  const cookie = getCookie(name);

  return cookie;
};

export const convertToDecimal = (data) => {
  if (data.indexOf('/') >= 0) {
    const fraction = data;
    const split = fraction.split('/');

    return parseInt(split[0], 10) / parseInt(split[1], 10);
  }
  return parseInt(data, 10);
};

export const parseAddressHost = (address) => {
  const fullAddress = address;
  const partsAddress = fullAddress.split('.');

  if (partsAddress.length >= 3) {
    if (partsAddress.indexOf('g') >= 0) {
      if (whiteAddressList.indexOf(partsAddress[0]) >= 0) {
        return null;
      }
      return partsAddress[0];
    }
  }
  return null;
};

export const getLocallyDeliveryAddress = (address) => {
  const addr = address.split(',');
  if (address && address.length > 0 && addr.length > 3) {
    const sliceAddr = addr.slice(0, addr.length - 3);
    return sliceAddr.join();
  }

  return '';
};

export const deleteLocalStorageItem = (item) => {
  if (localStorageSupport()) {
    if (localStorage[item]) {
      localStorage.removeItem(item);
      return true;
    }
  }
  return false;
}
