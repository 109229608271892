import { connect } from 'react-redux';
import UserOrders from '../components/UserOrders';

import {
  fetchUserOrders,
  addOrdersToCart,
  closeModalStoreType,
  onContinueOrderAgain,
  closeInActiveStoreModal,
} from 'modules/orders/actions';

import { fetchStoreInfo } from 'modules/stores/actions';

import { setDeliveryAddress } from 'modules/base/actions';

import { openModalWindow } from 'modules/user/actions';

import { addComment, updateComment } from 'modules/products/actions';

import { getStoreInfo, getFetchingStoreInfo, getBufferStoreInfo } from 'modules/stores/selectors';

import { getLogInInfo } from 'modules/user/selectors';

import { getBrandInformation } from 'modules/base/selectors';

import {
  getListOrders,
  getListReceivedOrders,
  getOpenModalStoreType,
  getIsFetchingUserOrders,
  getOrderInfo,
  getInactiveStore,
} from 'modules/orders/selectors';

import {
  getNewComment,
  getUpdateComment,
} from 'modules/products/selectors';

const mapStateToProps = state => ({
  listOrders: getListOrders(state.orders),
  receivedOrders: getListReceivedOrders(state.orders),
  openModalStoreType: getOpenModalStoreType(state.orders),
  orderInfo: getOrderInfo(state.orders),
  storeInfo: getStoreInfo(state.stores),
  bufferStoreInfo: getBufferStoreInfo(state.stores),
  isFetchingStoreInfo: getFetchingStoreInfo(state.stores),
  isFetchingUserOrders: getIsFetchingUserOrders(state.orders),
  successLogIn: getLogInInfo(state.user),
  inactiveStore: getInactiveStore(state.orders),
  brandInformation: getBrandInformation(state.base),
  newProductComment: getNewComment(state.products),
  updateProductComment: getUpdateComment(state.products),
});

export default connect(
  mapStateToProps,
  {
    fetchUserOrders,
    addOrdersToCart,
    fetchStoreInfo,
    onContinueOrderAgain,
    closeModalStoreType,
    openModalWindow,
    setDeliveryAddress,
    closeInActiveStoreModal,
    addComment,
    updateComment,
  }
)(UserOrders);
