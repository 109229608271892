/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import stylesCustom from './ToggleButton.module.scss';

const cx = classnames.bind(stylesCustom);

const ToggleButton = (props) => {
  const { stateToggle, handleChange, styles, size } = props;
  return (
    <label className={cx(size && size === 'large' ? 'switch-large' : 'switch')}>
      <input
        type={cx('checkbox')}
        value={stateToggle}
        checked={stateToggle}
        onChange={() => handleChange(!stateToggle)}
      />
      <span
        style={styles && { ...styles }}
        className={cx(size && size === 'large' ? 'slider-large' : 'slider', 'round')}
      />
    </label>
  );
};

ToggleButton.propTypes = {
  stateToggle: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
  size: PropTypes.string,
};

export default ToggleButton;
