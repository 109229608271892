import { handleActions } from 'redux-actions';
import { getLocalStorage, getLocallyDeliveryAddress } from 'services';
import * as constants from './constants';

const initialState = {
  isFetchingCartInfo: true,
  cartProducts: [],
  itemsProducts: {},
  storeId: null,
  storeType: '',
  totalPrice: 0,
  totalQuantity: null,
  totalPriceWithTax: 0,
  deliveryAddress: getLocalStorage('deliveryAddress'),
  orderNotes: "",
  phoneNumber: "",
  isFetchingCartProducts: false,
  isInitializedCard: false,
  addToCartLoader: false,
  error: false,
  cartConflict: false,
  triggerCartConflict: false,
  tax: 0,
  couponCode: getLocalStorage('coupon_code'),
  totalPriceWithCoupon: 0,
  couponType: null,
  discountCode: '',
  animationFrame: true,
  totalDiscount: 0,
  prices: null,
  costs: null,
  resultPrice: 0,
  paymentMethod: null,
  locallyDeliveryAddress: getLocallyDeliveryAddress(getLocalStorage('deliveryAddress')),
  itemsDeliveryCost: 0,
  sumPrice: 0,
  taxPrice: 0,
  discountPrice: 0,
  deliveryPrice: 0,
  isSuccessApplyDiscountCode: false,
  statusCart: 'IDLE',
};

export default handleActions(
  {
    [constants.FETCH_CART_INFO]: (state, action) => ({
      ...state,
      isFetchingCartInfo: action.isFetchingCartInfo,
      statusCart: 'RUNNING'
    }),

    [constants.FETCH_CART_INFO_SUCCESS]: (state, action) => ({
      ...state,
      itemsProducts: action.cartInfo.items,
      totalPrice: action.cartInfo.total_price,
      totalQuantity: action.cartInfo.total_quantity,
      tax: parseFloat(action.cartInfo.tax),
      totalPriceWithTax: action.cartInfo.price_with_tax,
      storeId: action.cartInfo.store_id,
      storeType: action.cartInfo.store_type,
      isFetchingCartInfo: false,
      bufferTotalPrice: action.cartInfo.total_price,
      bufferPriceWithTax: action.cartInfo.price_with_tax,
      sumPrice: action.cartInfo.sum_price,
      taxPrice: parseFloat(action.cartInfo.tax),
      itemsDeliveryCost: parseFloat(action.cartInfo.items_delivery_cost),
      statusCart: 'SUCCESS',
    }),

    [constants.FETCH_CART_INFO_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      isFetchingCartInfo: false,
      statusCart: 'FAIL'
    }),

    [constants.ADD_TO_CART_PRODUCT]: (state, action) => {
      const itemsProducts = state.itemsProducts;
      const product = action.product;
      const quantity = action.quantity;

      // I think that not good solution (It's need to think how made better)

      if (!itemsProducts[product.id]) {
        itemsProducts[product.id] = {};
        itemsProducts[product.id].price = product.price;
        itemsProducts[product.id].packing = product.packing;
        itemsProducts[product.id].quantity = quantity;
      } else {
        itemsProducts[product.id].quantity = quantity;
      }

      if (quantity == 0) {
        delete itemsProducts[product.id];
      }

      return {
        ...state,
        itemsProducts,
        addToCartLoader: true,
      };
    },

    [constants.ADD_TO_CART_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
      //itemsProducts: action.addProduct.items,
      totalQuantity: action.addProduct.total_quantity,
      totalPrice: action.addProduct.total_price,
      totalPriceWithTax: action.addProduct.price_with_tax,
      tax: parseFloat(action.addProduct.tax),
      addToCartLoader: false,
      bufferPriceWithTax: action.addProduct.price_with_tax,
      bufferTotalPrice: action.addProduct.total_price,
      itemsDeliveryCost: parseFloat(action.addProduct.items_delivery_cost),
      sumPrice: action.addProduct.sum_price,
      taxPrice: parseFloat(action.addProduct.tax),
    }),

    [constants.SET_USER_INFO]: (state, action) => ({
      ...state,
      phoneNumber: action.userInfo.phoneNumber,
    }),

    // [constants.SET_DELIVERY_ADDRESS]: (state, action) => ({
    //   ...state,
    //   deliveryAddress: action.deliveryAddress,
    //   locallyDeliveryAddress: getLocallyDeliveryAddress(action.deliveryAddress),
    // }),

    [constants.SET_ORDER_NOTES]: (state, action) => ({
      ...state,
      orderNotes: action.orderNotes,
    }),

    [constants.SET_PHONE_NUMBER]: (state, action) => ({
      ...state,
      phoneNumber: action.phoneNumber,
    }),

    [constants.FETCH_CART_PRODUCTS]: (state, action) => ({
      ...state,
      isFetchingCartProducts: true,
      error: false,
    }),

    [constants.FETCH_CART_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      cartProducts: action.cartProducts,
      isFetchingCartProducts: false,
      error: false,
    }),

    [constants.FETCH_CART_PRODUCTS_FAIL]: (state, action) => ({
      ...state,
      error: true,
      isFetchingCartProducts: false,
    }),

    [constants.SET_TOTAL_QUANTITY]: (state, action) => ({
      ...state,
      totalQuantity: action.totalQuantity,
      cartProducts: action.cartProducts,
    }),

    [constants.INITIALIZE_CART]: (state, action) => ({
      ...state,
      isInitializedCard: true,
    }),

    [constants.INITIALIZE_CART_SUCCESS]: (state, action) => ({
      ...state,
      storeId: action.payload.store_id,
      storeType: action.payload.store_type,
      totalQuantity: 1,
      isInitializedCard: false,
    }),

    [constants.INITIALIZE_CART_FAIL]: (state, action) => ({
      ...state,
      error: true,
      isInitializedCard: false,
    }),

    [constants.RESET_CART_SUCCESS]: (state, action) => ({
      ...state,
      paymentMethod: null,
      orderNotes: '',
    }),

    [constants.CART_CONFLICT]: (state, action) => ({
      ...state,
      cartConflict: true,
      triggerCartConflict: true,
      isFetchingCartInfo: false,
    }),

    [constants.CART_PICK_SUCCESS]: (state, action) => ({
      ...state,
      itemsProducts: action.cartInfo.items,
      totalPrice: action.cartInfo.total_price,
      totalQuantity: action.cartInfo.total_quantity,
      tax: parseFloat(action.cartInfo.tax),
      totalPriceWithTax: action.cartInfo.price_with_tax,
      storeId: action.cartInfo.store_id,
      storeType: action.cartInfo.store_type,
      isFetchingCartInfo: false,
      cartConflict: false,
      sumPrice: action.cartInfo.sum_price,
      taxPrice: parseFloat(action.cartInfo.tax),
      itemsDeliveryCost:
        (action.cartInfo.items_delivery_cost && parseFloat(action.cartInfo.items_delivery_cost))
        || 0,
    }),

    [constants.REMOVE_PRODUCT_FROM_CART]: (state, action) => {
      const cartProducts = state.cartProducts.filter(value => value.id !== action.product.id);

      return {
        ...state,
        cartProducts,
      };
    },

    [constants.APPLY_DISCOUNT_CODE]: (state, action) => ({
      ...state,
      isSuccessApplyDiscountCode: action.value,
      discountPrice: state.sumPrice,
    }),

    [constants.APPLY_DISCOUNT_CODE_SUCCESS]: (state, action) => ({
      ...state,
      totalPrice: action.couponCodeInfo.total_price,
      totalPriceWithTax: action.couponCodeInfo.price_with_tax,
      totalPriceWithCoupon: action.couponCodeInfo.price_with_coupon,
      totalDiscount: action.couponCodeInfo.total_price - action.couponCodeInfo.price_with_coupon,
      couponType: action.couponCodeInfo.coupon_type,
      sumPrice: action.couponCodeInfo.sum_price,
      discountPrice: action.couponCodeInfo.discount_price,
      isSuccessApplyDiscountCode: true,
    }),

    [constants.APPLY_DISCOUNT_CODE_FAIL]: (state, action) => ({
      ...state,
      totalPriceWithCoupon: null,
      totalDiscount: 0,
      totalPriceWithTax: state.bufferPriceWithTax,
      totalPrice: state.bufferTotalPrice,
      discountCode: '',
      discountPrice: 0,
      isSuccessApplyDiscountCode: false,
    }),

    [constants.SET_DISCOUNT_CODE]: (state, action) => ({
      ...state,
      discountCode: action.discountCode,
    }),

    [constants.ANIMATION_FRAME]: (state, action) => ({
      ...state,
      animationFrame: action.animationFrame,
    }),

    [constants.RESET_INFO_CART]: (state, action) => ({
      ...state,
      totalPrice: null,
      totalPriceWithTax: null,
      totalPriceWithCoupon: null,
      totalDiscount: null,
      discountCode: '',
      couponType: null,
      itemsProducts: {},
      itemsDeliveryCost: 0,
      sumPrice: 0,
      taxPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      paymentMethod: null,
      orderNotes: '',
      isSuccessApplyDiscountCode: false,
      discountCode: '',
      totalQuantity: 0,
      cartProducts: [],
    }),

    [constants.FETCH_SHIPPING_COST_INFO_SUCCESS]: (state, action) => ({
      ...state,
      deliveryPrice: action.shippingCost,
    }),

    [constants.FETCH_SHIPPING_COST_INFO_FAIL]: (state, action) => ({
      ...state,
      deliveryPrice: action.shippingCost,
    }),

    [constants.RESET_STATE_PRICES_AND_COSTS]: (state, action) => ({
      ...state,
      deliveryPrice: 0,
    }),

    [constants.SET_PAYMENT_METHOD]: (state, action) => ({
      ...state,
      paymentMethod: action.paymentMethod,
    }),

    [constants.SET_LOCALLY_DELIVERY_ADDRESS]: (state, action) => ({
      ...state,
      locallyDeliveryAddress: action.address,
    }),

    [constants.SET_DELIVERY_ADDRESS_WITH_ZIP]: (state, action) => ({
      ...state,
      deliveryAddress: action.addressWithZip,
    }),

    [constants.RESET_CART_WITH_PRODUCTS]: (state, _) => ({
      ...state,
      cartProducts: [],
    })
  },
  initialState
);
