import { Component } from 'react';
import _ from 'lodash';

const getDefaultPropName = prop => `default${prop[0].toUpperCase() + prop.slice(1)}`;

export const getAutoControlledStateValue = (propName, props, state, includeDefaults = false) => {
  const propValue = props[propName];
  if (propValue !== undefined) return propValue;

  if (includeDefaults) {
    const defaultProp = props[getDefaultPropName(propName)];

    if (defaultProp !== undefined) return defaultProp;

    if (state) {
      const initialState = state[propName];
      if (initialState !== undefined) return initialState;
    }
  }

  if (propName === 'checked') return false;
  if (propName === 'value') return props.multiple ? [] : '';
};

class AutoControlledComponent extends Component {
  constructor(...args) {
    super(...args);

    const { autoControlledProps } = this.constructor;
    const state = _.invoke(this, 'getInitialAutoControlledState', this.props) || {};

    if (process.env.NODE_ENV !== 'production') {
      const { defaultProps, name, propTypes } = this.constructor;
      if (!autoControlledProps) {
        console.error('Error with autoControlledProps');
      }

      const illegalDefaults = _.intersection(autoControlledProps, _.keys(defaultProps));

      if (!_.isEmpty(illegalDefaults)) {
        console.error(`Error with illegalDefaults. See ${name} props: "${illegalDefaults}".`);
      }
      const illegalAutoControlled = _.filter(autoControlledProps, prop => _.startsWith(prop, 'default'));

      if (!_.isEmpty(illegalAutoControlled)) {
        console.error('Error with illegalAutoControlled');
      }
    }

    const initialAutoControlledState = autoControlledProps.reduce((acc, prop) => {
      acc[prop] = getAutoControlledStateValue(prop, this.props, state, true);

      if (process.env.NODE_ENV !== 'production') {
        const defaultPropName = getDefaultPropName(prop);
        const { name } = this.constructor;

        if (!_.isUndefined(this.props[defaultPropName]) && !_.isUndefined(this.props[prop])) {
          console.error(`${name} prop "${prop}" is auto controlled.`);
        }
      }
      return acc;
    }, {});

    this.state = { ...state, ...initialAutoControlledState };
  }

  componentWillReceiveProps(nextProps) {
    const { autoControlledProps } = this.constructor;

    const newState = autoControlledProps.reduce((acc, prop) => {
      const isNextDefined = !_.isUndefined(nextProps[prop]);

      if (isNextDefined) acc[prop] = nextProps[prop];

      return acc;
    }, {});

    if (Object.keys(newState).length > 0) this.setState(newState);
  }

  trySetState = (maybeState, state) => {
    const { autoControlledProps } = this.constructor;

    if (process.env.NODE_ENV !== 'production') {
      const { name } = this.constructor;

      const illegalKeys = _.difference(_.keys(maybeState), autoControlledProps);
      if (!_.isEmpty(illegalKeys)) {
        console.error(`${name} called trySetState() with controlled props: "${illegalKeys}".`);
      }
    }

    let newState = Object.keys(maybeState).reduce((acc, prop) => {
      if (this.props[prop] !== undefined) return acc;

      if (autoControlledProps.indexOf(prop) === -1) return acc;

      acc[prop] = maybeState[prop];
      return acc;
    }, {});

    if (state) newState = { ...newState, ...state };

    if (Object.keys(newState).length > 0) this.setState(newState);
  };
}

export { AutoControlledComponent };
