import React from 'react';

const DeliveryLinks = (props) => {
  const styles_one = {
    color: '#636363',
    textDecoration: 'underline',
    cursor: 'pointer',
  };

  const styles_two = {
    color: '#4bc6b4',
    textDecoration: 'underline',
    cursor: 'pointer',
  };

  if (props.index === 1) {
    return (
      <span>
        &nbsp;Check out our{' '}
        <a style={styles_one} onClick={props.goToOvernightStores}>
          overnight
        </a>{' '}
        for more options
      </span>
    );
  } else if (props.index === 2) {
    return (
      <span>
        &nbsp;Check out our{' '}
        <a style={styles_one} onClick={props.goToSameDayStores}>
          same&nbsp;day
        </a>{' '}
        delivery for more options
      </span>
    );
  } else if (props.index === 3) {
    return (
      <span>
        &nbsp;Check out other{' '}
        <a style={styles_two} onClick={props.goToSameDayStores}>
          same&nbsp;day
        </a>{' '}
        or{' '}
        <a style={styles_two} onClick={props.goToOvernightStores}>
          overnight
        </a>{' '}
        delivery options
      </span>
    );
  } else if (props.index === 4) {
    return (
      <span>
        &nbsp;Check out other{' '}
        <a style={styles_two} onClick={props.goToOvernightStores}>
          overnight
        </a>{' '}
        or{' '}
        <a style={styles_two} onClick={props.goToSameDayStores}>
          same&nbsp;day
        </a>{' '}
        delivery options
      </span>
    );
  } else if (props.index === 5) {
    return (
      <span>
        &nbsp;Check out other{' '}
        <a style={styles_two} onClick={props.goToSameDayStores}>
          same&nbsp;day
        </a>{' '}
        delivery options.
      </span>
    );
  } else if (props.index === 6) {
    return (
      <span>
        &nbsp;Check out other{' '}
        <a style={styles_two} onClick={props.goToOvernightStores}>
          overnight
        </a>{' '}
        delivery options.
      </span>
    );
  }
  return null;
};

export default DeliveryLinks;
