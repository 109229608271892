import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps
} from 'utils/lib';

import styles from './Menu.scss';

const cx = classnames.bind(styles);

const MenuMenu = (props) => {
  const { children, className, content, position } = props;

  const classes = cx(position, 'menu', className);
  const rest = getUnhandledProps(MenuMenu, props);
  const ElementType = getElementType(MenuMenu, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  )
}

export default MenuMenu;
