import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Form from 'components/Form/Form';
import Input from 'components/Input/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import TextArea from 'components/TextArea/TextArea';

import Label from 'components/Label/Label';
import Image from 'components/Image/Image';

import styles from './ShowSuggestProduct.scss';
import {
  FIELDS_SUGGEST_PRODUCT,
  INPUT_FIELDS,
  FIELDS_WITH_LIST,
  TEXTAREA_FIELDS,
  OPTIONS_PACKING,
  OPTIONS_PLANT_TYPE,
} from '../../services';

const cx = classnames.bind(styles);

class ShowSuggestProduct extends Component {
  state = {}

  componentDidMount() {
    const { listCategories, formattedListBrands, fetchListBrands, fetchListOfCategory } = this.props;

    if (listCategories && listCategories.length === 0) {
      fetchListOfCategory();
    }

    if (formattedListBrands && formattedListBrands.length === 0) {
      fetchListBrands();
    }
  }

  renderListWithLabels = (labels, color) => labels.map((label, key) => (
    <Label key={label.id} color={color}>{label.name}</Label>
  ))

  renderEditGroups = (groups) => {
    const groupsOptions = groups.map(group => ({
      key: group.id,
      text: group.name,
      value: `${group.name}_${group.id}`,
    }));

    const groupsNames = groups.filter(v => v.name);
    return <Dropdown selection multiple fluid name="groups" selectedLabel={{ value: 'testgroup' }} options={groupsOptions} search />;
  }

  getListOfAllRemedies = () => {
    const { listOfRemedies, getListOfAllRemedies } = this.props;

    if (listOfRemedies && listOfRemedies.length === 0) {
      getListOfAllRemedies();
    }
  }

  render() {
    const { suggestProduct, isEditSuggestProduct, isShowSuggestProduct, listCategories, formattedListBrands, listOfRemedies, isFetchingListOfRemedies } = this.props;

    const objSuggestProduct = suggestProduct ? Object.keys(suggestProduct) : [];

    return (
      <Segment basic loading={isShowSuggestProduct}>
        <Grid verticalAlign="middle">
          {
            objSuggestProduct.map((value, key) => (
              <GridRow key={key}>
                <GridColumn width={4} style={{ width: '25%', fontWeight: 600 }}>{FIELDS_SUGGEST_PRODUCT[value]}</GridColumn>
                {
                  isEditSuggestProduct
                    ? (
                      <Fragment>
                        {INPUT_FIELDS.indexOf(value) >= 0 && <GridColumn width={12} style={{ width: '75%' }}><Input fluid name={value} defaultValue={suggestProduct[value]} /></GridColumn>}
                        {FIELDS_WITH_LIST.indexOf(value) >= 0 && <GridColumn width={12} style={{ width: '75%' }}><Dropdown selection fluid name={value} defaultValue={suggestProduct[value]} options={value === 'packing' ? OPTIONS_PACKING : OPTIONS_PLANT_TYPE} /></GridColumn>}
                        {TEXTAREA_FIELDS.indexOf(value) >= 0
                          && (
                            <GridColumn width={12} style={{ width: '75%' }}>
                              <Form>
                                <TextArea style={{ width: '100%' }} fluid name={value} defaultValue={suggestProduct[value]} />
                                <div>Possible markdown syntax: ~subscript~ ^superscript^ **bold** _italic_</div>
                              </Form>
                            </GridColumn>
                          )}
                        {value === 'groups' && <GridColumn width={12} style={{ width: '75%' }}>{this.renderEditGroups(suggestProduct[value])}</GridColumn>}
                        {value === 'category' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown options={listCategories} selection fluid search value={suggestProduct[value]} /></GridColumn>}
                        {value === 'brand' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown options={formattedListBrands} selection fluid search value={suggestProduct[value]} /></GridColumn>}
                        {value === 'remedies' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown loading={isFetchingListOfRemedies} options={listOfRemedies} selection fluid search value={suggestProduct[value]} onOpen={this.getListOfAllRemedies} /></GridColumn>}
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        {value === 'groups' && <GridColumn width={12} style={{ width: '75%' }}>{this.renderListWithLabels(suggestProduct[value], 'blue')}</GridColumn>}
                        {value === 'remedies' && <GridColumn width={12} style={{ width: '75%' }}>{this.renderListWithLabels(suggestProduct[value], 'purple')}</GridColumn>}
                        {value === 'strains' && <GridColumn width={12} style={{ width: '75%' }}>{this.renderListWithLabels(suggestProduct[value], 'teal')}</GridColumn>}
                        {value === 'image' && <GridColumn width={12} style={{ width: '75%' }}><Image size="small" src={suggestProduct[value].wide} /></GridColumn>}
                        {typeof suggestProduct[value] === 'string' && (
                          <GridColumn width={12} style={{ width: '75%' }}>
                            {suggestProduct[value] || <Label color="red">not set</Label>}
                          </GridColumn>
                        )}
                      </Fragment>
                    )
                }
              </GridRow>
            ))
          }
        </Grid>
      </Segment>
    );
  }
}

export default ShowSuggestProduct;
