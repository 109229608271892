function convertTime(time) {
  const hourEnd = time.indexOf(':');
  const H = +time.substr(0, hourEnd);
  const h = H % 12 || 12;
  const ampm = H < 12 ? 'AM' : 'PM';
  time = h + time.substr(hourEnd, 3) + ampm;

  return time;
}

export default convertTime;
