/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames/bind';

import { isNil, getElementType, getUnhandledProps } from 'utils/lib';

import styles from './Comment.css';

const cx = classnames.bind(styles);

const CommentContent = (props) => {
  const { className, children, content } = props;
  const classes = cx(className, 'content');
  const rest = getUnhandledProps(CommentContent, props);
  const ElementType = getElementType(CommentContent, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default CommentContent;
