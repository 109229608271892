import React from 'react';
import Image from 'components/Image/Image';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Icon from 'components/Icons/Icon';

import classnames from 'classnames/bind';
import Packing from './components/Packing';

import styles from './ProductInfo.module.scss';

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];
const cx = classnames.bind(styles);

export default (props) => {
  const { product, itemProduct, removeFromCart, setProduct, disabledPacking, onClick } = props;

  const brand = product.brand ? `${product.brand}` : '';
  const filterGramOz = [];

  if (product.packing === 'gram_oz' && typeof product.price === 'object') {
    Object.keys(product.price).forEach((price, index) => {
      if (GRAM_OZ.indexOf(price) >= 0) {
        filterGramOz.push({
          position: index,
          packing: price,
          cost: product.price[price],
        });
      }
    });
  }

  return (
    <GridRow className={cx('info-product-grid')}>
      <GridColumn mobile={16} className={cx('cross-icon-mobile')} textAlign="right">
        <div className={cx('delete-product')}>
          {!disabledPacking && (
            <Icon
              style={{ cursor: 'pointer' }}
              name="close"
              color="grey"
              onClick={() => removeFromCart(product, 0)}
            />
          )}
        </div>
      </GridColumn>
      <GridColumn computer={3} tablet={3} mobile={6}>
        <Image src={product.image && product.image.wide} onClick={() => onClick(product.id)} style={{ cursor: 'pointer' }} />
      </GridColumn>
      <GridColumn computer={6} tablet={6} mobile={10} className={cx('description-container-wrapper')}>
        <div className={cx('description-container')}>
          <div onClick={() => onClick(product.id)} className={cx('description-title')}>
            {product.name}
          </div>
          <div className={cx('description')}>{brand !== '' ? `By ${brand}` : ''}</div>
        </div>
      </GridColumn>
      <GridColumn computer={5} tablet={5} verticalAlign="middle" mobile={16}>
        <Packing
          itemProduct={itemProduct}
          disabledPacking={disabledPacking}
          filterGramOz={filterGramOz}
          product={product}
          setProduct={setProduct}
          removeFromCart={quantity => removeFromCart(product, quantity)}
        />
      </GridColumn>
      <GridColumn
        computer={2}
        textAlign="center"
        verticalAlign="middle"
        className={cx('cross-icon')}
      >
        <div className={cx('delete-product')}>
          {!disabledPacking && (
            <Icon
              style={{ cursor: 'pointer' }}
              name="close"
              color="grey"
              onClick={() => removeFromCart(product, 0)}
            />
          )}
        </div>
      </GridColumn>
    </GridRow>
  );
};
