import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import Image from 'components/Image/Image';
import Icon from 'components/Icons/Icon';
import Container from 'components/Container/Container';
import Label from 'components/Label/Label';
import MenuMenu from 'components/Menu/MenuMenu';

import styles from './Header.module.scss';

const logo = require('images/logo_white.png');

const cx = classnames.bind(styles);

const links = {
  orders: '/seller/manage_orders',
  products: '/seller/manage_products',
  delivery: '/seller/delivery_location',
  suggest: '/seller/suggest_products',
};

class Header extends Component {
  state = {}

  render() {
    const { history, domainPath, toggleSidebar, enabledSidebar } = this.props;
    return (
      <Menu fixed="top" className={cx('custom-header')} inverted borderless>
        <Container style={{ display: 'flex', flexDirection: 'inherit', alignItems: 'inherit' }}>
          {
            enabledSidebar &&
            <MenuItem onClick={toggleSidebar}>
              <Icon name="bars" link size="large" />
            </MenuItem>
          }
          <MenuItem as="a" onClick={() => history.push(`${domainPath}`)}>
            <Image style={{ width: '6.5em' }} src={logo} />
          </MenuItem>
          <MenuItem position="right">
            <Icon name="alarm" size="large" color="orange" style={{ cursor: 'pointer' }} className={cx('scale-icon')} />
            {/* <Label style={{ top: '0.2em', margin: '0em 0em 0em -2.7em' }} color="yellow" floating circular>
              22
            </Label> */}
          </MenuItem>
        </Container>
      </Menu>
    );
  }
}

export default Header;
