import React, { useEffect, useState } from 'react';

import useDebounce from 'hooks/debounce.hook';

import Loader from 'components/Loaders/Loader';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import CardGroup from 'components/Card/CardGroup';
import Input from 'components/Input/Input';

import Header from 'components/Typography/Header/Header';

import StoreCard from 'components/StoreCard';

const Stores = ({ fetchStores, stores, history, domainPath }) => {
  const [stateStores, setStateStores] = useState([]);
  const [searchStore, setSearchStore] = useState('');
  const debouncedSearchStore = useDebounce(searchStore, 300);

  useEffect(() => {
    fetchStores();
  }, [])

  useEffect(() => {
    if (stores.status === 'SUCCESS' && stores.data.length > 1) {
      setStateStores(stores.data);
    }

    if (stores.data && stores.data.length === 1) {
      handleClickStore(stores.data[0])
    }
  }, [stores.data]);

  useEffect(() => {
    if (debouncedSearchStore && debouncedSearchStore.trim().length > 1) {
      const filterStores = stateStores.filter(store => store.name.indexOf(debouncedSearchStore) >= 0);
      setStateStores(filterStores);
    }

    if (debouncedSearchStore.trim().length === 0) {
      setStateStores(stores.data);
    }
  }, [debouncedSearchStore]);

  const handleSearch = (value) => {
    setSearchStore(value);
  }

  if (stores.status === 'RUNNING') {
    return (
      <Loader active center />
    )
  }

  const handleClickStore = (store) => {
    const query = `${domainPath}doctor/products?order_type=delivery&store=${store.id}`

    history.push(query);
  }

  return (
    <>
      {stateStores && stateStores.length > 0 &&
        <Grid>
          <GridRow>
            <GridColumn width={16} textAlign="center">
              <Header as="h1">Stores</Header>
            </GridColumn>
          </GridRow>
          <GridRow textAlign="center">
            <GridColumn width={8} textAlign="center">
              <Input
                placeholder="Search..."
                fluid
                value={searchStore}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={16}>
              <CardGroup>
                {stateStores.map(store => (
                  <StoreCard infoCard={store} key={store.id} onClick={handleClickStore} />
                ))}
              </CardGroup>
            </GridColumn>
          </GridRow>
        </Grid>
      }
    </>
  )
}

export default Stores;
