import React, { Component } from 'react';
import scroll from 'scroll';
import ease from 'ease-component';


import ProductCard from 'components/ProductCard';
import CardBuy from 'components/ProductCard/CardBuy';
import NewProductCard from 'components/NewProductCard';
import Header from 'components/Typography/Header/Header';

import throttle from 'services/throttle';

import classnames from 'classnames/bind';
import NextArrowButton from '../NextArrow/NextArrowButton';
import PrevArrowButton from '../PrevArrow/PrevArrowButton';
import styles from './SliderCards.module.scss';

const cx = classnames.bind(styles);

class SliderCards extends Component {
  counter = 80;
  constructor(props) {
    super(props);

    this.state = {
      preButtonClassName: 'scale-out-center',
      nextButtonClassName: 'scale-in-center',
    };

    this.categoryContainer = React.createRef();
  }

  handleScroll = throttle(() => {
    const scrollLeft = Math.ceil(this.categoryContainer.current.scrollLeft);
    const widthContainer = this.categoryContainer.current.scrollWidth - this.categoryContainer.current.clientWidth;
    let nextButtonClassName = 'scale-in-center';
    let preButtonClassName = 'scale-in-center';
    if (scrollLeft === widthContainer || scrollLeft - 1 === widthContainer) {
      nextButtonClassName = 'scale-out-center';
    }

    if (scrollLeft === 0) {
      preButtonClassName = 'scale-out-center';
    }

    this.setState({
      nextButtonClassName,
      preButtonClassName,
    });
  }, 100);

  scrollToDirection = (direction) => {
    const { windowHeight } = this.props;
    let widthContainer = 900;

    if (windowHeight <= 750 && !window.isTablet) {
      widthContainer = 792;
    }

    const far = widthContainer * direction;
    const pos = this.categoryContainer.current.scrollLeft + far;

    if (this.categoryContainer.current.scroll) {
      this.categoryContainer.current.scroll({ left: pos, behavior: 'smooth' });
    } else {
      this.categoryContainer.current.scrollLeft = pos;
    }
  };

  transitionDelay = () => {
    this.counter += 20;

    return this.counter;
  }

  render() {
    const {
      categories,
      onBuy,
      onBuyNow,
      handleClick,
      onCloseCardBuy,
      checkToCart,
      itemsProducts,
      paddingBottom,
      setProduct,
      devicePrefix,
      windowHeight,
      id,
      rating,
      onClickCategory,
    } = this.props;

    let marginBottomMobile = null;

    if (window.isMobile && window.orientation === 90) {
      marginBottomMobile = {
        marginBottom: '60px',
      };
    }

    return (
      <div
        style={paddingBottom ? marginBottomMobile || { marginBottom: '120px' } : null}
        className={cx('slider-card-container')}
        id={`category-${id}`}
      >
        <div className={cx('category-container')}>
          <Header
            as="h2"
            id={categories.name.toLowerCase()}
            style={categories.info_page_html ? { cursor: 'pointer' } : null}
            className={cx('category-title', `category-title--${devicePrefix}`)}
            onClick={categories.info_page_html ? () => onClickCategory(categories.name, categories.info_page_html) : null}
          >
            {categories.name}
          </Header>
          <div className={cx('category-description')} />
        </div>
        {window.isMobile ? (
          <div className={cx(`${devicePrefix}-slider-products-container`)}>
            <div className={cx('slider-products-wrapper')}>
              {categories
                && categories.products.map((value, key) => (
                  <div className={cx('flip-card', value.in_cart && 'flip-card-rotate')} key={`card_buy_mobile_${value.id}`}>
                    <div className={cx('flip-card-inner', value.in_cart && 'flip-card-inner-rotate')}>
                      <CardBuy
                        key={`card_buy_mobile_${value.id}`}
                        card={value}
                        setProduct={setProduct}
                        onBuyNow={onBuyNow}
                        checkToCart={checkToCart}
                        itemsProducts={itemsProducts}
                        openModal={card => handleClick(categories.id, card)}
                        closeCardBuy={card => onCloseCardBuy(categories.id, card)}
                      />
                      <ProductCard
                        key={value.id}
                        card={value}
                        categoriesId={categories.id}
                        onClick={card => handleClick(categories.id, card)}
                        onBuy={(card, quantity) => onBuy(categories.id, card, quantity)}
                        rating={rating}
                      />
                      {/* <NewProductCard card={value} /> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
            <div style={{ position: 'relative' }}>
              <PrevArrowButton
                customClassName={cx(this.state.preButtonClassName)}
                id={categories.name.toLowerCase()}
                visible={categories.products.length < 4 || window.isTablet}
                toScroll={direction => this.scrollToDirection(direction)}
              />
              <div
                style={
                  categories.products.length < 4
                    || window.isTablet
                    || this.state.nextButtonClassName === 'scale-out-center'
                    ? { opacity: 0 }
                    : null
                }
                className={cx('fade-to-white-right')}
              />
              <div style={this.state.preButtonClassName === 'scale-out-center' ? { opacity: 0 } : null} className={cx('fade-to-white-left')} />
              <div
                className={cx(`${devicePrefix}-slider-products-wrapper-desktop`)}
                ref={this.categoryContainer}
                onScroll={window.isTablet ? null : () => this.handleScroll()}
              >
                {categories
                  && categories.products.map(value => (
                    <div className={cx('flip-card', value.in_cart && 'flip-card-rotate')} key={value.id}>
                      <div className={cx('flip-card-inner', value.in_cart && 'flip-card-inner-rotate')}>
                        <CardBuy
                          key={value.id}
                          card={value}
                          setProduct={setProduct}
                          onBuyNow={onBuyNow}
                          checkToCart={checkToCart}
                          itemsProducts={itemsProducts}
                          openModal={card => handleClick(categories.id, card)}
                          closeCardBuy={card => onCloseCardBuy(categories.id, card)}
                          windowHeight={windowHeight}
                        />
                        <ProductCard
                          key={`original${value.id}`}
                          card={value}
                          categoriesId={categories.id}
                          onClick={card => handleClick(categories.id, card)}
                          onBuy={(card, quantity) => onBuy(categories.id, card, quantity)}
                          windowHeight={windowHeight}
                          devicePrefix={devicePrefix}
                          rating={rating}
                        />
                        {/* <NewProductCard card={value} /> */}
                      </div>
                    </div>
                  ))}
              </div>
              <NextArrowButton
                customClassName={
                  categories.products.length < 4 || window.isTablet
                    ? cx('scale-out-center')
                    : cx(this.state.nextButtonClassName)
                }
                id={categories.name.toLowerCase()}
                toScroll={direction => this.scrollToDirection(direction)}
              />
            </div>
          )}
      </div>
    );
  }
}

export default SliderCards;
