import queryString from 'query-string';

const origin = window.location.origin;

const encodeQueryData = data => {
  const ret = [];
  for (const d in data) ret.push(`${d}=${data[d]}`);
  return ret.join('&');
};

export const request = (uri, method = 'GET', data) => {
  const requestUri = uri[0] === '/' ? origin + uri : `${origin + window.location.pathname}/${uri}`;

  let contentType;
  let body;

  if (method !== 'GET') {
    switch (data.constructor.name) {
      case 'HTMLFormElement':
        body = new FormData(data);
        break;
      case 'FormData':
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        contentType = 'application/json';
    }
  }

  const headers = {
    Accept: 'application/json',
    'X-REQUESTED-WITH': 'XMLHttpRequest',
  };

  if (contentType) headers['Content-type'] = contentType;

  const options = {
    method,
    headers,
    credentials: 'include',
  };

  if (method !== 'GET' && body) options.body = body;
  return fetch(requestUri, options).then(response => {
    if (response.status === 400) {
      window.Raven && window.Raven.captureException(new Error('Bad server request', { logger: requestUri }));
    }
    return response;
  });
};

export const generateQuery = (name, results, isShort = true) => {
  if (!results) {
    window.Raven && window.Raven.captureMessage('Error generate query on stores page', { level: 'error' });
    return undefined;
  }
  const res = results.address_components.find(value => value.types.indexOf(name) > -1);

  if (!res) return undefined;
  return isShort ? res.short_name : res.long_name;
};

export const removeQueryParameters = (param, router) => {
  if (queryString.parse(router.location.search)[param]) {
    const objectQueryString = queryString.parse(router.location.search);
    delete objectQueryString[param];

    const queryStrings = encodeQueryData(objectQueryString);
    router.replace(`${router.location.pathname}?${queryStrings}`);
    return true;
  }

  return false;
};
