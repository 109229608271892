import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Dropdown.css';

const cx = classnames.bind(styles);

const DropdownMenu = (props) => {
  const { children, className, content, direction, open, scrolling } = props;
  const classes = cx(
    direction,
    useKeyOnly(open, 'visible'),
    useKeyOnly(scrolling, 'scrolling'),
    'menu',
    'transition',
    className,
  );
  const rest = getUnhandledProps(DropdownMenu, props);
  const ElementType = getElementType(DropdownMenu, props);

  return (
    <ElementType {...rest} className={classes} style={open ? { display: 'block' } : null}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default DropdownMenu;
