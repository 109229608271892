import React from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
} from 'utils/lib';

import styles from './Item.scss';
import Item from './Item';

const cx = classnames.bind(styles);

const ItemGroup = (props) => {
  const { children, className, content, divided, items, link, relaxed, unstackable } = props;

  const classes = cx(
    'ui',
    useKeyOnly(divided, 'divided'),
    useKeyOnly(link, 'link'),
    useKeyOnly(unstackable, 'unstackable'),
    useKeyOrValueAndKey(relaxed, 'relaxed'),
    'items',
    className
  );
  const rest = getUnhandledProps(ItemGroup, props);
  const ElementType = getElementType(ItemGroup, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }
  if (!isNil(content)) {
    return (
      <ElementType {...rest} className={classes}>
        {content}
      </ElementType>
    );
  }

  const itemsJSX = _.map(items, (item) => {
    const { childKey, ...itemProps } = item;
    const finalKey = childKey
      || [itemProps.content, itemProps.description, itemProps.header, itemProps.meta].join('-');

    return <Item {...itemProps} key={finalKey} />;
  });

  return (
    <ElementType {...rest} className={classes}>
      {itemsJSX}
    </ElementType>
  );
};

export default ItemGroup;
