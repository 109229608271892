import React from 'react';

import TextField from 'components/TextField';
import { checkEmail } from 'services';

import classnames from 'classnames/bind';
import styles from './UserProfileData.module.scss';

const cx = classnames.bind(styles);

const userProfileData = {
  first_name: 'First Name',
  last_name: 'Last Name',
  birth_date: 'Birth date',
  email: 'E-mail',
  phone: 'Tel.',
  address: 'Address',
};

const privateDataLabel = {
  password: 'Password',
  password_confirmation: 'Confirmation password',
};

const exceptionFields = ['id', 'type', 'age', 'face_photo', 'id_photo', 'resources'];

export default (props) => {
  const { renderUserEdit, userProfile, onChange, onChangePassword, privateData } = props;

  if (!renderUserEdit) {
    return (
      <div className={cx('user-profile-block')}>
        {Object.keys(userProfile).map(
          (profile, key) =>
            exceptionFields.indexOf(profile) === -1 ? (
              <TextField
                disabled
                key={key}
                label={userProfileData[profile]}
                placeholder=""
                inputType="text"
                mask={profile}
                value={userProfile[profile].value}
              />
            ) : null
        )}
      </div>
    );
  }
  return (
    <div className={cx('user-profile-block')}>
      {Object.keys(userProfile).map(
        (profile, key) =>
          exceptionFields.indexOf(profile) === -1 ? (
            <TextField
              key={profile}
              label={userProfileData[profile]}
              placeholder=""
              inputType="text"
              mask={profile}
              value={userProfile[profile].value}
              error={userProfile[profile].error}
              onChangeField={onChange}
            />
          ) : null
      )}
      {Object.keys(privateData).map(value => (
        <TextField
          key={value}
          inputType="password"
          label={privateDataLabel[value]}
          placeholder=""
          mask={value}
          value={privateData[value].value}
          error={privateData[value].error}
          onChangePassword={onChangePassword}
        />
      ))}
    </div>
  );
};
