import React, { Component, createRef } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  eventStack,
  getElementType,
  isBrowser,
  isRefObject,
  getUnhandledProps,
} from 'utils/lib';

import styles from './Sticky.module.scss';

const cx = classnames.bind(styles);

class Sticky extends Component {
  static defaultProps = {
    active: true,
    bottomOffset: 0,
    offset: 0,
    scrollContext: isBrowser() ? window : null,
  }

  state = {
    sticky: false,
  }

  stickyRef = createRef();

  triggerRef = createRef();

  componentDidMount() {
    if (!isBrowser()) return;
    const { active } = this.props;

    if (active) {
      this.handleUpdate();
      this.addListeners(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { active: current, scrollContext: currentScrollContext } = this.props;
    const { active: next, scrollContext: nextScrollContext } = nextProps;

    if (current === next) {
      if (currentScrollContext !== nextScrollContext) {
        this.removeListeners();
        this.addListeners(nextProps);
      }
      return;
    }

    if (next) {
      this.handleUpdate();
      this.addListeners(nextProps);
      return;
    }

    this.removeListeners();
    this.setState({ sticky: false });
  }

  componentWillUnmount() {
    if (!isBrowser()) return;
    const { active } = this.props;

    if (active) {
      this.removeListeners();
      cancelAnimationFrame(this.frameId);
    }
  }

  addListeners = (props) => {
    const { scrollContext } = props;
    const scrollContextNode = isRefObject(scrollContext) ? scrollContext.current : scrollContext;

    if (scrollContextNode) {
      eventStack.sub('resize', this.handleUpdate, { target: scrollContextNode });
      eventStack.sub('scroll', this.handleUpdate, { target: scrollContextNode });
    }
  }

  removeListeners = () => {
    const { scrollContext } = this.props;
    const scrollContextNode = isRefObject(scrollContext) ? scrollContext.current : scrollContext;

    if (scrollContextNode) {
      eventStack.unsub('resize', this.handleUpdate, { target: scrollContextNode });
      eventStack.unsub('scroll', this.handleUpdate, { target: scrollContextNode });
    }
  }

  update = (e) => {
    const { pushing } = this.state;

    this.ticking = false;
    this.assignRects();

    if (pushing) {
      if (this.didReachStartingPoint()) return this.stickToContextTop(e);
      if (this.didTouchScreenBottom()) return this.stickToScreenBottom(e);
      return this.stickToContextBottom(e);
    }

    if (this.isOversized()) {
      if (this.contextRect.top > 0) return this.stickToContextTop(e);
      if (this.contextRect.bottom < window.innerHeight) return this.stickToContextBottom(e);
    }

    if (this.didTouchScreenTop()) {
      if (this.didReachContextBottom()) return this.stickToContextBottom(e);
      return this.stickToScreenTop(e);
    }

    return this.stickToContextTop(e);
  }

  handleUpdate = (e) => {
    if (!this.ticking) {
      this.ticking = true;
      this.frameId = requestAnimationFrame(() => this.update(e));
    }
  }

  assignRects = () => {
    const { context } = this.props;
    const contextNode = isRefObject(context) ? context.current : context || document.body;

    this.triggerRect = this.triggerRef.current.getBoundingClientRect();
    this.contextRect = contextNode.getBoundingClientRect();
    this.stickyRect = this.stickyRef.current.getBoundingClientRect();
  }

  computeStyle() {
    const { styleElement } = this.props;
    const { bottom, bound, sticky, top } = this.state;

    if (!sticky) return styleElement;
    return {
      bottom: bound ? 0 : bottom,
      top: bound ? undefined : top,
      width: this.triggerRect.width,
      ...styleElement,
    };
  }

  didReachContextBottom = () => {
    const { offset } = this.props;

    return this.stickyRect.height + offset >= this.contextRect.bottom;
  }

  didReachStartingPoint = () => this.stickyRect.top <= this.triggerRect.top;

  didTouchScreenTop = () => this.triggerRect.top < this.props.offset;

  didTouchScreenBottom = () => {
    const { bottomOffset } = this.props;

    return this.contextRect.bottom + bottomOffset > window.innerHeight;
  }

  isOversized = () => this.stickyRect.height > window.innerHeight;

  pushing = (pushing) => {
    const { pushing: possible } = this.props;

    if (possible) this.setState({ pushing });
  }

  stick = (e, bound) => {
    this.setState({ bound, sticky: true });
    _.invoke(this.props, 'onStick', e, this.props);
  }

  unstick = (e, bound) => {
    this.setState({ bound, sticky: false });
    _.invoke(this.props, 'onUnstick', e, this.props);
  }

  stickToContextBottom = (e) => {
    _.invoke(this.props, 'onBottom', e, this.props);

    this.stick(e, true);
    this.pushing(true);
  }

  stickToContextTop = (e) => {
    _.invoke(this.props, 'onTop', e, this.props);

    this.unstick(e, false);
    this.pushing(false);
  }

  stickToScreenBottom = (e) => {
    const { bottomOffset: bottom } = this.props;

    this.stick(e, false);
    this.setState({ bottom, top: null });
  }

  stickToScreenTop = (e) => {
    const { offset: top } = this.props;

    this.stick(e, false);
    this.setState({ top, bottom: null });
  }

  render() {
    const { children, className } = this.props;
    const { bottom, bound, sticky } = this.state;
    const rest = getUnhandledProps(Sticky, this.props);
    const ElementType = getElementType(Sticky, this.props);

    const containerClasses = cx(
      sticky && 'ui',
      sticky && 'stuck-container',
      sticky && (bound ? 'bound-container' : 'fixed-container'),
      className,
    );
    const elementClasses = cx(
      'ui',
      sticky && (bound ? 'bound bottom' : 'fixed'),
      sticky && !bound && (bottom === null ? 'top' : 'bottom'),
      'sticky',
    );
    const triggerStyles = sticky && this.stickyRect ? { height: this.stickyRect.height } : {};

    return (
      <ElementType {...rest} className={containerClasses}>
        <div ref={this.triggerRef} style={triggerStyles} />
        <div className={elementClasses} ref={this.stickyRef} style={this.computeStyle()}>
          {children}
        </div>
      </ElementType>
    );
  }
}

export default Sticky;
