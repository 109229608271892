import React, { Component } from 'react';

import Image from 'components/Image/Image';

import classnames from 'classnames/bind';
import styles from './StoreInfo.module.scss';

const cx = classnames.bind(styles);

class StoreInfo extends Component {
  // componentDidMount() {
  //   const { storeId, fetchStoreInfo, bufferStoreInfo } = this.props;
  //   if (storeId) {
  //     fetchStoreInfo(storeId, bufferStoreInfo, true);
  //   }
  // }

  render() {
    const { storeInfo } = this.props;

    return (
      <div style={{ marginLeft: '12px' }} className={cx('store-info-wrapper')}>
        <Image
          src={storeInfo && storeInfo.logo.in_cart}
          alt={storeInfo && storeInfo.name}
          title={storeInfo && storeInfo.name}
        />
      </div>
    );
  }
}

export default StoreInfo;
