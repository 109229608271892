export const getTotalQuantity = state => state.totalQuantity;

export const getTotalPrice = state => state.totalPrice;

export const getItemsProducts = state => state.itemsProducts;

export const getCartProducts = state => state;

export const getStoreId = state => state.storeId;

export const getStoreType = state => state.storeType;

export const getCartConflict = state => state.cartConflict;

// export const getTaxPrice = state => state.tax;

export const getPriceWithTax = state => state.totalPriceWithTax;

export const getAddToCartLoader = state => state.addToCartLoader;

export const getDeliveryAddress = state => state.deliveryAddress;

export const getOrderNotes = state => state.orderNotes;

export const getPhoneNumber = state => state.phoneNumber;

export const getTriggerCartConflict = state => state.triggerCartConflict;

export const getIsFetchingCartInfo = state => state.isFetchingCartInfo;

export const getCouponType = state => state.couponType;

export const getDiscountCode = state => state.discountCode;
export const getAnimationFrame = state => state.animationFrame;

export const getPrices = state => state.prices;
export const getCosts = state => state.costs;
export const getResultPrice = state => state.resultPrice;

export const getPaymentMethod = state => state.paymentMethod;

export const getLocallyDeliveryAddress = state => state.locallyDeliveryAddress;

export const getLocallyStateAddress = (state) => {
  if (state.deliveryAddress && state.deliveryAddress.length > 0) {
    const addr = state.deliveryAddress.split(',');

    if (addr.length >= 3) {
      const sliceAddr = addr.slice(addr.length - 3);

      return sliceAddr.join();
    }
    return state.deliveryAddress;
  }

  return '';
};

export const getItemsDeliveryCost = state => state.itemsDeliveryCost;

export const getSumPrice = state => state.sumPrice;
export const getTaxPrice = state => state.taxPrice;
export const getDiscountPrice = state => state.discountPrice;
export const getDeliveryPrice = state => state.deliveryPrice;

export const getIsSuccessApplyDiscountCode = state => state.isSuccessApplyDiscountCode;

export const getStatusCart = state => state.statusCart;
