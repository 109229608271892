import React, { Component } from 'react';
import classnames from 'classnames/bind';
import scroll from 'scroll';
import ease from 'ease-component';

import Container from 'components/Container/Container';
import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';

import Table from 'components/Table/Table';
import TableHeader from 'components/Table/TableHeader';
import TableRow from 'components/Table/TableRow';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import TableBody from 'components/Table/TableBody';
import TableCell from 'components/Table/TableCell';

import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import ModalActions from 'components/ModalWindow/ModalActions';

import Image from 'components/Image/Image';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';

import PanelTools from './components/PanelTools';
import ShowSuggestProduct from './components/ModalBodies/ShowSuggestProduct';
import CreateSuggestProduct from './components/ModalBodies/CreateSuggestProduct';

import styles from './SuggestProducts.module.scss';

const cx = classnames.bind(styles);

const REQUIRED_CREATE_SUGGEST_PRODUCT_FIELD = ['name', 'description', 'packing', 'category_id'];

class SuggestProducts extends Component {
  state = {
    product: null,
    isOpenModalCreateSuggestProduct: false,
    requiredFieldsArray: [],
  }

  componentDidMount() {
    const { fetchSuggestProducts } = this.props;

    fetchSuggestProducts();
  }

  showSuggestProduct = (id) => {
    const { showSuggestProduct } = this.props;

    this.setState({
      showSuggestProduct: true,
      animation: true,
    });

    showSuggestProduct(id);
  }

  onCloseSuggestProduct = () => {
    this.setState({
      animation: false,
    }, () => {
      clearTimeout(this.counter);
      this.counter = setTimeout(() => {
        this.setState({
          showSuggestProduct: false,
          isEditSuggestProduct: false,
        });
      }, 250);
    });
  }

  deleteSuggestProduct = (product) => {
    this.setState({
      product,
      isApprovedDelete: true,
    });
  }

  declineDeleteLocation = () => {
    this.setState({
      product: null,
      isApprovedDelete: false,
    });
  }

  approveDeleteLocation = () => {
    const { product } = this.state;
    const { deleteSuggestProduct } = this.props;

    if (product.id) {
      this.setState({
        isApprovedDelete: false,
      }, () => {
        deleteSuggestProduct(product.id);
      });
    }
  }

  onEditSuggestProduct = () => {
    this.setState({
      isEditSuggestProduct: true,
    });
  }

  showAndEditSuggestProduct = (id) => {
    const { showSuggestProduct } = this.props;
    this.setState({
      isEditSuggestProduct: true,
      animation: true,
      showSuggestProduct: true,
    }, () => {
      showSuggestProduct(id);
    });
  }

  createSuggestProduct = () => {
    this.setState({
      isOpenModalCreateSuggestProduct: true,
      animation: true,
    });
  }

  closeCreateModalSuggestProduct = () => {
    this.setState({
      isOpenModalCreateSuggestProduct: false,
      animation: false,
      requiredFieldsArray: [],
    }, () => {
      this.newSuggestProduct = null;
    });
  }

  createOrUpdateNewSuggestProduct = (action) => {
    const { createNewSuggestProduct, updateSuggestProduct } = this.props;

    if (this.newSuggestProduct) {
      const requiredFieldsArray = [];

      REQUIRED_CREATE_SUGGEST_PRODUCT_FIELD.forEach((field, index) => {
        if (this.newSuggestProduct[field] === undefined || this.newSuggestProduct[field].length === 0) {
          requiredFieldsArray.push(field);
        }
      });

      if (requiredFieldsArray.length > 0) {
        this.setState({
          requiredFieldsArray,
        }, () => {
          // scroll.top(document.body, 100, { duration: 200 }, { ease: ease.inOutBounce });
        });
      } else if (action === 'craete') {
        createNewSuggestProduct({ ...this.newSuggestProduct });
      } else {
        updateSuggestProduct({ ...this.newSuggestProduct });
      }
    }

    if (this.newSuggestProduct === undefined || this.newSuggestProduct === null) {
      this.setState({
        requiredFieldsArray: REQUIRED_CREATE_SUGGEST_PRODUCT_FIELD,
      });
    }
  }

  onChange = (e, data) => {
    const { requiredFieldsArray } = this.state;
    if (data) {
      if (!this.newSuggestProduct) {
        this.newSuggestProduct = {};
      }

      if (requiredFieldsArray.length > 0) {
        if (requiredFieldsArray.indexOf(data.name) >= 0 && data.value.length > 0) {
          const spliceRequiredFields = requiredFieldsArray.filter(v => v !== data.name);

          this.setState({
            requiredFieldsArray: spliceRequiredFields,
          });
        }
      }

      this.newSuggestProduct[data.name] = data.value;
    }
  }

  render() {
    const {
      listSuggestProducts,
      isFetchingSuggestProducts,
      suggestProduct,
      isShowSuggestProduct,
      listCategories,
      formattedListBrands,
      fetchListBrands,
      fetchListOfCategory,
      listOfRemedies,
      isFetchingListOfRemedies,
      getListOfAllRemedies,
      fetchListOfAllStrains,
      listStrains,
      isFetchingListStrains,
      fetchRemediesByStrain,
      listRemediesByStrain,
      isFetchingRemediesByStrain,
      isCreatingNewSuggestProduct,
    } = this.props;
    const { showSuggestProduct, animation, isApprovedDelete, product, isEditSuggestProduct, isOpenModalCreateSuggestProduct, requiredFieldsArray } = this.state;

    return (
      <Container style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
        <Grid>
          <GridColumn width={16} style={{ width: '100%' }}>
            <PanelTools listSuggestProducts={listSuggestProducts} isFetchingSuggestProducts={isFetchingSuggestProducts} createSuggestProduct={this.createSuggestProduct} />
          </GridColumn>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised loading={isFetchingSuggestProducts}>
              <Table compact size="mini" padded>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Image</TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Category</TableHeaderCell>
                    <TableHeaderCell>Brand</TableHeaderCell>
                    <TableHeaderCell textAlign="center">Actions</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listSuggestProducts.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>{product.id}</TableCell>
                      <TableCell><Image size="tiny" src={product.image && product.image.wide} /></TableCell>
                      <TableCell>
                        <a onClick={() => this.showSuggestProduct(product.id)}>
                          {product.name}
                        </a>
                      </TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>{product.brand}</TableCell>
                      <TableCell textAlign="right">
                        <Button size="mini" secondary type="button" onClick={() => this.showAndEditSuggestProduct(product.id)}>
                          <Icon name="edit" />
                          Edit
                        </Button>
                        <Button size="mini" negative type="button" onClick={() => this.deleteSuggestProduct(product)}>
                          <Icon name="remove" />
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Segment>
          </GridColumn>
        </Grid>
        <Modal
          scrolling
          open={showSuggestProduct}
          closeIcon={<Icon name="close" link onClick={this.onCloseSuggestProduct} />}
          onClose={this.onCloseSuggestProduct}
          className={cx(animation ? 'slide-in-bottom' : 'slide-out-bottom')}
        >
          <ModalHeader>
            Suggest Product
          </ModalHeader>
          <ModalContent>
            <ModalDescription>
              <ShowSuggestProduct
                suggestProduct={suggestProduct}
                isEditSuggestProduct={isEditSuggestProduct}
                isShowSuggestProduct={isShowSuggestProduct}
                listCategories={listCategories}
                formattedListBrands={formattedListBrands}
                fetchListBrands={fetchListBrands}
                fetchListOfCategory={fetchListOfCategory}
                listOfRemedies={listOfRemedies}
                isFetchingListOfRemedies={isFetchingListOfRemedies}
                getListOfAllRemedies={getListOfAllRemedies}
              />
            </ModalDescription>
          </ModalContent>
          <ModalActions>
            {
              isEditSuggestProduct
                ? <Button size="mini" primary type="button" onClick={() => this.createOrUpdateNewSuggestProduct('update')}>Update</Button>
                : <Button size="mini" primary type="button" onClick={this.onEditSuggestProduct}>Edit</Button>
            }
            <Button size="mini" secondary type="button" onClick={this.onCloseSuggestProduct}>Close</Button>
          </ModalActions>
        </Modal>
        <Modal basic size="small" open={isApprovedDelete}>
          <ModalHeader>
            Delete suggest product
          </ModalHeader>
          <ModalContent>
            {product && <p>{`Are you sure delete this ${product.name} suggest product?`}</p>}
          </ModalContent>
          <ModalActions>
            <Button type="button" basic color="green" inverted onClick={this.approveDeleteLocation}>
              <Icon name="checkmark" />
              Yes
            </Button>
            <Button type="button" basic color="red" inverted onClick={this.declineDeleteLocation}>
              <Icon name="remove" />
              No
            </Button>
          </ModalActions>
        </Modal>
        <Modal
          open={isOpenModalCreateSuggestProduct}
          closeIcon={<Icon name="close" link onClick={this.closeCreateModalSuggestProduct} />}
          onClose={this.closeCreateModalSuggestProduct}
          scrolling
          className={cx(animation ? 'slide-in-bottom' : 'slide-out-bottom')}
          id="create-suggest-product"
        >
          <ModalHeader>
            Create product
          </ModalHeader>
          <ModalContent>
            <ModalDescription>
              <CreateSuggestProduct
                listCategories={listCategories}
                formattedListBrands={formattedListBrands}
                fetchListBrands={fetchListBrands}
                fetchListOfCategory={fetchListOfCategory}
                listOfRemedies={listOfRemedies}
                isFetchingListOfRemedies={isFetchingListOfRemedies}
                getListOfAllRemedies={getListOfAllRemedies}
                fetchListOfAllStrains={fetchListOfAllStrains}
                listStrains={listStrains}
                isFetchingListStrains={isFetchingListStrains}
                fetchRemediesByStrain={fetchRemediesByStrain}
                listRemediesByStrain={listRemediesByStrain}
                isFetchingRemediesByStrain={isFetchingRemediesByStrain}
                onChange={this.onChange}
                requiredFieldsArray={requiredFieldsArray}
                isCreatingNewSuggestProduct={isCreatingNewSuggestProduct}
              />
            </ModalDescription>
          </ModalContent>
          <ModalActions>
            <Button size="mini" type="button" primary onClick={() => this.createOrUpdateNewSuggestProduct('create')}>Create</Button>
            <Button size="mini" type="button" secondary onClick={this.closeCreateModalSuggestProduct}>Close</Button>
          </ModalActions>
        </Modal>
      </Container>
    );
  }
}

export default SuggestProducts;
