export const getStoreInformation = state => state.storeInformation;

export const getIsFetchingStoreInformation = state => state.isFetchingStoreInformation;

export const getPolygonsForStore = state => state.polygonsForStore;

export const getIsFetchingPolygonsForStore = state => state.isFetchingPolygonsForStore;

export const getDeliveryLocationData = state => state.deliveryLocationData;
export const getIsFetchingDeliveryLocationData = state => state.isFetchingDeliveryLocationData;

export const getListWithDeliveryLocations = state => state.listWithDeliveryLocations;
export const getIsFetchingListWithDeliveryLocation = state => state.isFethchingListWithDeliveryLocation;

export const getIsEditStoreInformation = state => state.isEditStoreInformation;

export const getNewStoreImage = state => state.storeImage;
export const getRerenderTable = state => state.rerenderTable;

export const getIsFetchingStoreProducts = state => state.isFetchingStoreProducts;
export const getListSortOfCategoriesWithProducts = state => state.listSortOfCategoriesWithProducts;

export const getProductsOutOfStock = state => state.productsOutOfStock;
export const getProductsAvaiable = state => state.productsAvaiable;

export const getListCategories = state => state.listCategories;
export const getFormattedListProducts = state => state.formattedListProducts;
export const getFormattedListBrands = state => state.formattedListBrands;

export const getIsFetchingListOrders = state => state.isFetchingListOrders;
export const getListOrders = state => state.listOrders;

export const getIsFetchingShowOrder = state => state.isFetchingShowOrder;
export const getOrder = state => state.order;
export const getOrdersProcessing = state => state.ordersProcessing;

export const getUpdateOrder = state => state.updateOrder;
export const getIsSearchingProduct = state => state.isSearchingProduct;

export const getIsSuccessAddProduct = state => state.isSuccessAddProduct;

export const getProductsInStock = state => state.productsInStock;

export const getListSuggestProducts = state => state.listSuggestProducts;
export const getIsFetchingSuggestProducts = state => state.isFetchingSuggestProducts;

export const getSuggestProduct = state => state.suggestProduct;
export const getIsShowSuggestProduct = state => state.isShowSuggestProduct;

export const getListStrains = state => state.listStrains;
export const getIsFetchingListStrains = state => state.isFetchingListStrains;

export const getListRemediesByStrain = state => state.listRemediesByStrain;
export const getIsFetchingRemediesByStrain = state => state.isFetchingRemediesByStrain;

export const getActiveCategoryItems = state => state.activeCategoryItems;

export const getNumberOfDeliveryLocations = state => state.numberOfDeliveryLocations;
export const getNumberOfOrdersPending = state => state.numberOfOrdersPending;
export const getNumberOfProductsInStock = state => state.numberOfProductsInStock;
export const getUpdateTabWithPanes = state => state.updateTabWithPanes;

export const getIsCreatingNewSuggestProduct = state => state.isCreatingNewSuggestProduct;
