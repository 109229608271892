import React from 'react';

import { isNil, getElementType, getUnhandledProps, useKeyOnly } from 'utils/lib';
import classnames from 'classnames/bind';
import styles from './Table.scss';

const cx = classnames.bind(styles);

const TableHeader = (props) => {
  const { children, className, content, fullWidth } = props;
  const classes = cx(useKeyOnly(fullWidth, 'full-width'), className);
  const rest = getUnhandledProps(TableHeader, props);
  const ElementType = getElementType(TableHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

TableHeader.defaultProps = {
  as: 'thead',
};

export default TableHeader;
