import React, { Component } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';

import classnames from 'classnames/bind';
import styles from './TextField.module.scss';

require('react-datetime/css/react-datetime.css');

const cx = classnames.bind(styles);

const replaceNumber = (value) => {
  if (value) {
    const USNumber = value
      .toString()
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !USNumber[2]
      ? USNumber[1]
      : `(${USNumber[1]}) ${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;
    return value;
  }
  return '';
};

const replaceDate = (value) => {
  if (value) {
    const USDate = value
      .toString()
      .replace(/-/g, '')
      .match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    value = !USDate[2]
      ? USDate[1]
      : `${USDate[1]}-${USDate[2]}${USDate[3] ? `-${USDate[3]}` : ''}`
    return value;
  }
  return "";
}

class TextField extends Component {
  state = {
    error: false,
  };

  onChange = (value) => {
    const { mask, onChangeField } = this.props;
    const error = false;

    if (mask === 'email') {
    }

    this.setState({
      error,
    });

    if (mask === 'birth_date' && value.length > 10) return;

    onChangeField(value, mask);
  };

  onChangePassword = (value) => {
    const { mask, onChangePassword } = this.props;
    let error = false;

    if (value.trim().length < 8) {
      if (mask === 'passwordOne') {
        error = 'Hint: At least 8 characters';
      }
    }

    this.setState({
      error,
    });
    onChangePassword(value, mask);
  };

  returnRenderInput = () => {
    const { mask, value, disabled, inputType } = this.props;

    if (mask === 'birth_date') {
      return (
        <input
          disabled={disabled}
          className={cx('text-field-calendar', disabled && 'text-field-calendar-disabled')}
          value={replaceDate(value)}
          placeholder="YYYY-MM-DD"
          onChange={e => this.onChange(e.target.value)}
        />
      );
    }

    if (mask === 'passwordOne' || mask === 'passwordTwo') {
      return (
        <input
          disabled={disabled}
          type={inputType}
          value={!value ? '' : value}
          className={cx('text-field-input', disabled && 'text-field-input--disabled')}
          onChange={e => this.onChangePassword(e.target.value)}
        />
      );
    }

    if (mask === 'address') {
      return (
        <input
          disabled={disabled}
          type={inputType}
          value={!value || value === 'null' ? '' : value}
          className={cx('text-field-input', disabled && 'text-field-input--disabled')}
          onChange={e => this.onChange(e.target.value)}
        />
      );
    }

    return (
      <input
        disabled={disabled}
        type={inputType}
        value={mask === 'phone' ? replaceNumber(value) : !value ? '' : value}
        className={cx('text-field-input', disabled && 'text-field-input--disabled')}
        onChange={e => this.onChange(e.target.value)}
      />
    );
  };

  render() {
    const { label, placeholder, mask, inputType, value, disabled, error } = this.props;

    return (
      <div
        className={cx(
          'text-field-block',
          mask === 'phone' && 'text-field-block--phone',
          disabled && 'text-field-block--disabled',
          inputType === 'password' && value === '' && 'placeholder-asterisk'
        )}
      >
        <label className={cx('label-name')} htmlFor={label}>
          {label}
        </label>
        <div
          className={cx(value && value.trim().length > 0 ? 'placeholder-off' : 'placeholder-on')}
        >
          {placeholder}
        </div>
        {this.returnRenderInput()}
        <div>
          <hr
            className={cx(
              error ? 'delimeter-input-error' : 'delimeter-input-default',
              disabled && 'delimeter-input-disabled'
            )}
          />
        </div>
        <div className={cx(error ? 'error-message' : 'default-message')}>{error}</div>
      </div>
    );
  }
}

export default TextField;
