import React from 'react';

const getStructureDelivery = () => {
  const MESSAGE_TEXT_ONE = 'These deliveries service you area.';
  const MESSAGE_TEXT_TWO = "Don't see the product you want?";
  const MESSAGE_TEXT_THREE = 'These deliveries services you area overnight.';
  const MESSAGE_TEXT_FOUR = 'This address is only serviced by next day delivery.';

  const MESSAGE_LINK_ONE = (
    <span>
      Check out our <a onClick={() => this.goToOvernightStores()}>overnight</a> for more options
    </span>
  );
  const MESSAGE_LINK_TWO = (
    <span>
      Check out our <a onClick={() => this.goToSameDayStores()}>same day</a> delivery for more
      options
    </span>
  );
  const MESSAGE_LINK_THREE = (
    <span>
      Check out other <a onClick={() => this.goToSameDayStores()}>same day</a> or
      <a onClick={() => this.goToOvernightStores()}>overnight</a>
      delivery options
    </span>
  );
  const MESSAGE_LINK_FOUR = (
    <span>
      Check out other <a onClick={() => this.goToOvernightStores()}>overnight</a>} or
      <a onClick={() => this.goToSameDayStores()}>same day</a>
      delivery options
    </span>
  );
  const MESSAGE_LINK_FIVE = (
    <span>
      Check out other <a onClick={() => this.goToSameDayStores()}>same day</a>
    </span>
  );

  return {
    multiple: {
      stores: {
        multiple_same_day: {
          message_text: MESSAGE_TEXT_ONE,
          message_link: 1,
          redirect: false,
        },
        multiple_next_day: {
          message_text: MESSAGE_TEXT_THREE,
          message_link: 2,
          redirect: false,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 3,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 4,
        },
      },
      redirect: false,
    },
    multiple_single: {
      stores: {
        multiple_same_day: {
          message_text: MESSAGE_TEXT_ONE,
          message_link: 1,
        },
        multiple_next_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 3,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 5,
        },
      },
      redirect: false,
    },
    multiple_none: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
        },
        multiple_next_day: {
          message_text: null,
          message_link: null,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 5,
        },
        menue_page_next_day: {
          message_text: null,
          message_link: null,
        },
      },
      redirect: false,
    },
    single_multiple: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
        multiple_next_day: {
          message_text: MESSAGE_TEXT_ONE,
          message_link: 2,
          redirect: false,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 6,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 4,
        },
      },
      redirect: false,
    },
    single: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
        multiple_next_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 6,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 5,
        },
      },
      redirect: true,
    },
    single_none: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
        multiple_next_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: null,
          message_link: null,
        },
        menue_page_next_day: {
          message_text: null,
          message_link: null,
        },
      },
      redirect: true,
    },
    none_multiple: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
          redirect: false,
        },
        multiple_next_day: {
          message_text: MESSAGE_TEXT_FOUR,
          message_link: null,
          redirect: false,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: null,
          message_link: null,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_TWO,
          message_link: 6,
        },
      },
      redirect: true,
    },
    none_single: {
      stores: {
        multiple_same_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
        multiple_next_day: {
          message_text: null,
          message_link: null,
          redirect: true,
        },
      },
      products: {
        menue_page_same_day: {
          message_text: null,
          message_link: null,
        },
        menue_page_next_day: {
          message_text: MESSAGE_TEXT_FOUR,
          message_link: null,
        },
      },
      redirect: true,
    },
    none: {},
  };
};

export default getStructureDelivery;
