import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './RadioButton.module.scss';

const cx = classnames.bind(styles);

const RadioButton = (props) => {
  const { active, onClick, label, name } = props;
  let option = active;
  if (option === undefined || option === null) {
    option = false;
  }
  return (
    <div className={cx("md-radio")}>
      <input id={name} type="radio" name={name} checked={option} onChange={() => onClick(option)} />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

RadioButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

export default RadioButton;
