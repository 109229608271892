import React, { Component } from 'react';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Form from 'components/Form/Form';
import Rating from 'components/Rating';
import Header from 'components/Typography/Header/Header';
import Button from 'components/Button/Button';
import TextArea from 'components/TextArea/TextArea';
import Image from 'components/Image/Image';

class Comment extends Component {
  state = {
    comment: this.props.comment,
    rating: 0,
  }

  onChange = (e, data) => {
    this.setState({
      comment: data.value,
    });
  }

  addComment = () => {
    const { addComment } = this.props;
    const { comment } = this.state;

    addComment(comment);
  }

  updateComment = () => {
    const { updateComment } = this.props;
    const { comment, rating } = this.state;

    updateComment(comment, rating);
  }

  onRate = (e, data) => {

    this.setState({
      rating: data.rating,
    })
  }

  render() {
    const { rating, productImage, isEdit } = this.props;
    const { comment } = this.state;

    return (
      <Grid>
        <GridRow>
          <GridColumn style={{ display: 'flex', justifyContent: 'center' }} width={16} textAlign="center">
            <Image src={productImage} size="small" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16} textAlign="center">
            <Header as="h4">Rate this product:</Header>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16} textAlign="center" verticalAlign="middle">
            <Rating disabled={!isEdit} color="teal" style={{ fontSize: '1.7rem' }} icon="star" defaultRating={rating} maxRating={5} onRate={isEdit ? this.onRate : () => null} />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <Form reply style={{ marginTop: '1em', textAlign: 'center' }}>
              <div style={{ margin: '0 0 1em' }}>
                <TextArea value={comment} placeholder="Write your comment here..." onChange={this.onChange} />
              </div>
              {
                isEdit
                  ? (
                    <Button
                      style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}
                      color="teal"
                      content="Edit comment"
                      labelPosition="left"
                      icon="edit"
                      primary
                      type="button"
                      onClick={this.updateComment}
                    />
                  )
                  : (
                    <Button
                      style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}
                      color="teal"
                      content="Add comment"
                      labelPosition="left"
                      icon="edit"
                      primary
                      type="button"
                      onClick={this.addComment}
                    />
                  )
              }
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default Comment;
