import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  markers: null,
  center: null,
  messageFail: false,
  isFetching: false,
  infoBoxPosition: null,
  viewInfoBox: false,
  geocodeData: null,
  address: '',
  isFetchingCurrentPosition: false,
  viewStoresSupportCreditCard: false
};

export default handleActions(
  {
    [constants.FETCH_LIST_MARKERS]: (state, action) => ({
      ...state,
      isFetching: true,
      messageFail: false
    }),

    [constants.FETCH_LIST_MARKERS_SUCCESS]: (state, action) => {
      return {
        ...state,
        markers: action.markers,
        isFetching: false,
        messageFail: false,
        center: action.center
      };
    },

    [constants.FETCH_LIST_MARKERS_FAIL]: (state, action) => ({
      ...state,
      messageFail: true,
      isFetching: false
    }),

    [constants.TOGGLE_MARKER_INFO]: (state, action) => ({
      ...state,
      markers: action.markers
    }),

    [constants.SET_INFOBOX_POSITION]: (state, action) => ({
      ...state,
      infoBoxPosition: action.infoBoxPosition,
      viewInfoBox: action.viewInfoBox
    }),

    [constants.FILTER_STORES_SUPPORT_CARD_PICK_UP]: (state, action) => ({
      ...state,
      viewStoresSupportCreditCard: action.trigger
    }),

    [constants.GET_CURRENT_GEOLOCATION]: (state, action) => ({
      ...state,
      isFetchingCurrentPosition: action.isFetchingCurrentPosition
    }),

    [constants.DENIED_GEOLOCATION]: (state, action) => ({
      ...state,
      isFetchingCurrentPosition: action.isFetchingCurrentPosition
    }),

    [constants.PROMPT_GEOLOCATION]: (state, action) => ({
      ...state,
      isFetchingCurrentPosition: action.isFetchingCurrentPosition
    }),

    [constants.GET_CURRENT_GEOLOCATION_SUCCESS]: (state, action) => {
      let address = state.address;

      if (address === '') {
        address = action.geocodeData.formatted_address;
      }
      return {
        ...state,
        geocodeData: action.geocodeData,
        address,
        isFetchingCurrentPosition: false
      };
    },

    [constants.GET_CURRENT_GEOLOCATION_FAIL]: (state, action) => ({
      ...state,
      isFetchingCurrentPosition: false
    }),

    [constants.SET_GEOCODE_BY_ADDRESS]: (state, action) => ({
      ...state,
      geocodeData: action.geocodeData
    }),

    [constants.SET_ADDRESS]: (state, action) => ({
      ...state,
      address: action.address
    }),

    [constants.CLEAR_FIELD_ADDRESS]: (state, action) => ({
      ...state,
      address: '',
      geocodeData: null
    }),

    [constants.FETCH_NEW_LIST_MARKERS_SUCCESS]: (state, action) => {
      const markers = state.markers;
      const newMarkers = action.markers;

      const existIndex = markers.map(v => v.id);

      const uniqueMarkers = newMarkers.filter(
        v => existIndex.indexOf(v.id) === -1
      );
      const concatMarkers = markers.concat(uniqueMarkers);

      return {
        ...state,
        markers: concatMarkers
      };
    }
  },
  initialState
);
