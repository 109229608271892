import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import TextArea from 'components/TextArea/TextArea';

import Label from 'components/Label/Label';
import Image from 'components/Image/Image';
import Icon from 'components/Icons/Icon';
import ImageUpload from 'components/ImageUpload';
import Loader from 'components/Loaders/Loader';

import styles from './CreateSuggestProduct.module.scss';
import {
  FIELDS_SUGGEST_PRODUCT,
  INPUT_FIELDS,
  TEXTAREA_FIELDS,
  FIELDS_WITH_LIST,
  OPTIONS_PACKING,
  OPTIONS_PLANT_TYPE,
} from '../../services';

const cx = classnames.bind(styles);

const EXCLUDE_FIELDS = ['updated_at', 'created_at', 'used_in_stores'];

class CreateSuggestProduct extends Component {
  state = {}

  getListOfAllRemedies = () => {
    const { listOfRemedies, getListOfAllRemedies } = this.props;

    if (listOfRemedies && listOfRemedies.length === 0) {
      getListOfAllRemedies();
    }
  }

  getListOfAllCategories = () => {
    const { listCategories, fetchListOfCategory } = this.props;

    if (listCategories && listCategories.length === 0) {
      fetchListOfCategory();
    }
  }

  getListOfAllBrands = () => {
    const { formattedListBrands, fetchListBrands } = this.props;

    fetchListBrands();
  }

  getListOfAllStrains = () => {
    const { fetchListOfAllStrains } = this.props;

    fetchListOfAllStrains();
  }

  renderEditGroups = () => {
    const groupsOptions = [{
      key: 1,
      text: 'Feed',
      value: 'feed',
    }];

    return <Dropdown selection multiple fluid name="groups" options={groupsOptions} search onChange={this.onChange} />;
  }

  onSelectStrain = (e, data) => {
    const { value } = data;
    const { fetchRemediesByStrain } = this.props;

    if (value) {
      this.setState({
        selectStrain: value,
      }, () => {
        fetchRemediesByStrain(value);
      });
    }
  }

  onChange = (e, data) => {
    const { onChange } = this.props;

    onChange(e, data);
  }

  renderRemediesByStrain = data => data.map(value => (
    <Label style={{ marginBottom: '0.142857em' }} key={value.id} color="teal">{value.name}</Label>
  ))

  render() {
    const {
      listCategories,
      formattedListBrands,
      listOfRemedies,
      isFetchingListOfRemedies,
      listStrains,
      isFetchingListStrains,
      listRemediesByStrain,
      isFetchingRemediesByStrain,
      requiredFieldsArray,
      isCreatingNewSuggestProduct
    } = this.props;

    const { selectStrain } = this.state;

    return (
      <Segment basic loading={isCreatingNewSuggestProduct}>
        <Grid verticalAlign="middle">
          {
            Object.keys(FIELDS_SUGGEST_PRODUCT).map((field, key) => (
              EXCLUDE_FIELDS.indexOf(field) >= 0 ? null : (
                <GridRow key={key}>
                  <GridColumn width={4} style={{ width: '25%', fontWeight: 600 }}>
                    {FIELDS_SUGGEST_PRODUCT[field]}
                  </GridColumn>
                  {INPUT_FIELDS.indexOf(field) >= 0 && <GridColumn width={12} style={{ width: '75%' }}><Input fluid name={field} onChange={this.onChange} error={requiredFieldsArray.indexOf(field) >= 0} /></GridColumn>}
                  {FIELDS_WITH_LIST.indexOf(field) >= 0 && <GridColumn width={12} style={{ width: '75%' }}><Dropdown selection fluid name={field} options={field === 'packing' ? OPTIONS_PACKING : OPTIONS_PLANT_TYPE} onChange={this.onChange} error={requiredFieldsArray.indexOf(field) >= 0} /></GridColumn>}
                  {TEXTAREA_FIELDS.indexOf(field) >= 0
                    && (
                      <GridColumn width={12} style={{ width: '75%' }}>
                        <Form>
                          <FormField error={requiredFieldsArray.indexOf(field) >= 0}>
                            <TextArea style={{ width: '100%' }} fluid name={field} onChange={this.onChange} />
                          </FormField>
                          <div style={{ marginTop: '0.25em' }}>Possible markdown syntax: ~subscript~ ^superscript^ **bold** _italic_</div>
                        </Form>
                      </GridColumn>
                    )}
                  {field === 'groups' && <GridColumn width={12} style={{ width: '75%' }}>{this.renderEditGroups()}</GridColumn>}
                  {field === 'category' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown options={listCategories} selection fluid search onClick={this.getListOfAllCategories} onChange={this.onChange} name="category_id" error={requiredFieldsArray.indexOf('category_id') >= 0} /></GridColumn>}
                  {field === 'brand' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown options={formattedListBrands} selection fluid search onClick={this.getListOfAllBrands} onChange={this.onChange} name={field} /></GridColumn>}
                  {field === 'remedies' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown loading={isFetchingListOfRemedies} options={listOfRemedies} selection fluid search onClick={this.getListOfAllRemedies} onChange={this.onChange} name={field} /></GridColumn>}
                  {field === 'strains' && <GridColumn width={12} style={{ width: '75%' }}><Dropdown loading={isFetchingListStrains} options={listStrains} wrapSelection={false} placeholder="Select strain" selection fluid search onClick={this.getListOfAllStrains} onChange={this.onSelectStrain} name={field} /></GridColumn>}
                  {field === 'strains_remedies' && <GridColumn width={12} style={{ width: '75%' }}>{!selectStrain ? <Label>Select strains to see it's remedies</Label> : isFetchingRemediesByStrain ? <Loader center inline /> : this.renderRemediesByStrain(listRemediesByStrain)}</GridColumn>}
                  {field === 'image' && (
                    <GridColumn width={12} style={{ width: '75%' }}>
                      <div className={cx('upload-image')}>
                        <Icon size="big" name="upload" className={cx('icon-upload')} />
                        <ImageUpload />
                      </div>
                    </GridColumn>
                  )}
                </GridRow>
              )))
          }
        </Grid>
      </Segment>
    );
  }
}

export default CreateSuggestProduct;
