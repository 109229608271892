import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import Loaders from 'components/Loaders/Loader';
import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalActions from 'components/ModalWindow/ModalActions';
import Icon from 'components/Icons/Icon';

import Main from './components/Sections/Main';
import Additional from './components/Sections/Additional';
import Extra from './components/Sections/Extra';
import Timezone from './components/Sections/Timezone';
import styles from './StoreInformation.module.scss';
import WorkingTime from './components/Sections/ModalContent/WorkingTime';

const cx = classnames.bind(styles);

const REQUIRED_STORE_INFORMATION_FIELDS = ['name', 'tax', 'min_order_price', 'delivery_time']

class StoreInformation extends Component {
  state = {
    toggleButton: true,
    disabled: true,
    loading: false,
    openMessage: false,
    openModal: false,
  };

  onClickEdit = () => {
    const { editStoreInformation } = this.props;
    const isEditStoreInformation = true;

    this.setState({
      toggleButton: false,
      disabled: false,
    }, () => {
      editStoreInformation(isEditStoreInformation);
    });
  };

  checkFieldsOnErrors = (storeInformation) => {
    let trigger = true;
    Object.keys(storeInformation).forEach((field) => {
      if (REQUIRED_STORE_INFORMATION_FIELDS.indexOf(field) >= 0) {
        if (storeInformation[field].length === 0) {
          trigger = false;
        }
      }
    })

    return trigger;
  }

  onSave = () => {
    const { storeInformation, updateStoreInformation, storeImage } = this.props;
    if (this.checkFieldsOnErrors(storeInformation)) {
      const formData = new FormData();

      Object.keys(storeInformation).forEach((field) => {
        if (field !== 'logo' && field !== 'work_time') {
          formData.append([`store[${field}]`], storeInformation[field]);
        }

        if (storeImage) {
          formData.append([`store[logo]`], storeImage);
        }

        if (field === 'work_time') {
          if (this.work_time) {
            formData.append([`store[working_hours]`], JSON.stringify(this.work_time))
          } else {
            formData.append([`store[working_hours]`], storeInformation[field])
          }
        }
      })

      updateStoreInformation(formData);
    }
  };

  onChange = (e, data) => {
    const { name, value, checked, type } = data;
    const { changeStoreInformation } = this.props;

    changeStoreInformation(name, type === 'checkbox' ? !checked : value.trim());
  };

  onCancel = () => {
    const { fetchStoreInformation } = this.props;

    this.setState({
      disabled: true,
      toggleButton: true,
    }, () => {
      fetchStoreInformation();
    })
  }

  openModalWithWorkingTime = () => {
    this.setState({
      openModal: !this.state.openModal,
    })
  }

  onChnageWorkingTime = (workingTime) => {
    const { changeStoreInformation } = this.props;

    changeStoreInformation('work_time', workingTime)
    this.work_time = workingTime;
  }

  render() {
    const { storeInformation, isFetchingStoreInformation } = this.props;

    const { disabled, openMessage, openModal } = this.state;

    if (!storeInformation || isFetchingStoreInformation) {
      return (
        <Container style={{ marginTop: '1.5em' }}>
          <Grid>
            <GridColumn width={16}>
              <Segment raised style={{ height: '100vh' }}>
                <Loaders active inline="centered" />
              </Segment>
            </GridColumn>
          </Grid>
        </Container>
      )
    }

    return (
      <Container style={{ marginTop: '1.5em' }} className={cx('custom-container')}>
        <Grid>
          <GridColumn width={16}>
            <Segment raised>
              <Form storeInformation={storeInformation}>
                <Main
                  disabled={disabled}
                  name={storeInformation.name}
                  description={storeInformation.description}
                  onChange={this.onChange}
                  error={storeInformation.name.length === 0}
                />
                <Additional
                  disabled={disabled}
                  storeInformation={storeInformation}
                  tax={storeInformation.tax}
                  onChange={this.onChange}
                />
                <Extra disabled={disabled} storeInformation={storeInformation} onChange={this.onChange} />
                <Timezone disabled={disabled} storeInformation={storeInformation} openModalWithWorkingTime={this.openModalWithWorkingTime} onChange={this.onChange} />
                <div style={{ marginTop: '2em', textAlign: 'right' }}>
                  {this.state.toggleButton && !isFetchingStoreInformation ? (
                    <Button secondary size="mini" onClick={this.onClickEdit}>
                      Edit
                    </Button>
                  ) : (
                      <div>
                        <Button
                          primary
                          size="mini"
                          onClick={this.onSave}
                          type="button"
                        >
                          Save
                        </Button>
                        <Button secondary size="mini" onClick={this.onCancel} type="button">
                          Cancel
                        </Button>
                      </div>
                    )}
                </div>
              </Form>
            </Segment>
          </GridColumn>
        </Grid>
        <Modal
          open={openModal}
          closeIcon={<Icon name="close" link onClick={this.openModalWithWorkingTime} />}
          onClose={this.openModalWithWorkingTime}
          size="tiny"
        >
          <ModalHeader><Icon name="calendar" size="large" />Working hours</ModalHeader>
          <ModalContent>
            <WorkingTime actualWorkTime={storeInformation && storeInformation.work_time} onChange={this.onChnageWorkingTime} />
          </ModalContent>
          <ModalActions style={{ textAlign: 'center' }}>
            <Button type="button" onClick={this.openModalWithWorkingTime} primary>apply</Button>
          </ModalActions>
        </Modal>
      </Container>
    );
  }
}

export default StoreInformation;
