import React, { Component } from 'react';
import queryString from 'query-string';
import PlacesAutocomplete from 'react-places-autocomplete';
import notification from 'components/Notification';
import { withTheme } from '@callstack/react-theme-provider';

import { saveLocalStorage, getLocallyDeliveryAddress } from 'services';
import debounce from 'services/debounce';

import classnames from 'classnames/bind';

import Drawer from 'components/Drawer';
import Input from 'components/Input/Input';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Background from './components/Background';
import Header from './components/Header';
import Footer from './components/Footer';

import styles from './WelcomePage.module.scss';

const cx = classnames.bind(styles);
const GOOGLE_STATUS_CODE = ['ZERO_RESULTS', 'OK', 'UNKNOWN_ERROR'];

class WelcomePage extends Component {
  state = {
    disabled: false,
    readonly: true,
    spinner: false,
    errorDelivery: false,
    openModal: false,
    formatted_address: null,
    sidebar: false,
    selectAddress: '',
    exitAnimation: false,
    address: '',
  };

  // componentWillMount() {
  //   if (typeof window !== 'object') return;
  //   if (!('geolocation' in window.navigator)) return;

  //   this.getCurrentGeolocation(true, 5000, null);
  // }

  componentDidMount() {
    const { location, router, address, domainPath } = this.props;
    if (queryString.parse(location.search).from_getheally) {
      if (queryString.parse(location.search).user === 'doctor') {
        router.push(`${domainPath}doctor`);
      } else {
        router.push(`${domainPath}user_orders`);
      }
    }

    // this.setSelectAddress(address);

    if (!window.isMobile && !window.isTablet) {
      window.addEventListener('click', this.handleWindowFocus);
    } else {
      window.addEventListener('focusout', this.handleFocusOut);
    }
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  componentWillUnmount() {
    this.willUnmount = true;
    window.removeEventListener('click', this.handleWindowFocus);
    window.addEventListener('focusout', this.handleFocusOut);
  }

  setSelectAddress = (selectAddress) => {
    this.setState({
      selectAddress,
    });
  }

  handleWindowFocus = debounce((event) => {
    const { setAddress } = this.props;
    const { selectAddress } = this.state;
    const searchInputElement = document.getElementById('search-input-container');

    if (!searchInputElement.contains(event.target)) {
      if (this.suggestions && this.suggestions.length > 0 && selectAddress.length === 0) {
        this.setState({
          address: this.suggestions[0].description,
        }, () => {
          setAddress(this.suggestions[0].description);
        });
      }
    }
  }, 100);

  handleFocusOut = (event) => {
    const { setAddress } = this.props;
    const { selectAddress } = this.state;

    if (this.suggestions && this.suggestions.length > 0 && event.relatedTarget === null && selectAddress.length === 0) {
      this.setState({
        address: this.suggestions[0].description,
      }, () => {
        setAddress(this.suggestions[0].description);
      });
    }
  }

  getCurrentGeolocation = (enableHighAccuracy, timeout, maximumAge) => {
    const { getCurrentGeolocation, geocodeData } = this.props;
    if (geocodeData) return;

    getCurrentGeolocation(enableHighAccuracy, timeout, maximumAge);
  };

  handleChange = (address) => {
    this.setState({
      address,
    });

    if (window.fbq) {
      window.fbq('track', 'Search');
    }
  };

  handleSelect = (selectAddress) => {
    this.setState({
      errorDelivery: false,
      selectAddress,
      address: selectAddress,
    });
  };

  handleEnterKeyDown = (e) => {
    const { selectAddress } = this.state;
    const { setAddress } = this.props;
    if (e.keyCode === 13) {
      if (selectAddress && selectAddress.length > 3) {
        this.goToDelivery();
      } else if (this.suggestions && this.suggestions.length > 0) {
        setAddress(this.suggestions[0].description);
        //this.goToDelivery();
      }
    }
  };

  handleFocus = () => {
    const { clearFieldAddress, setAddress } = this.props;
    const { selectAddress, address } = this.state;

    if (this.state.errorDelivery) {
      clearFieldAddress('');
    }
    this.setState({
      formatted_address: null,
      readonly: false,
    });

    if (this.suggestions && this.suggestions.length > 0 && selectAddress.length === 0) {
      this.setState({
        address: this.suggestions[0].description,
      }, () => {
        setAddress(this.suggestions[0].description);
      });
    }
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    if (GOOGLE_STATUS_CODE.indexOf(status) < 0) {
      window.Raven && window.Raven.captureMessage(`Error from Google Maps API ${status}`, { level: 'error' });
    }
    clearSuggestions();
  };

  goToHeally = () => {
    window.location = 'https://getheally.com/';
  };

  onSignOut = () => {
    const { onSignOut, router, domainPath } = this.props;

    onSignOut(router, false, domainPath);
  };

  sendAddresInSentry = (address) => {
    window.Raven && window.Raven.captureMessage(`Wrong address ${address}`, { level: 'info' });
  };

  goToDelivery = async () => {
    const { geocodeData, setDeliveryAddress, resetStores, domainPath } = this.props;
    const { address } = this.state;
    let deliveryAddress = address;

    if (this.suggestions && this.suggestions.length > 0 && address.length === 0) {
      deliveryAddress = this.suggestions[0].description;
    } else if (address.length === 0) {
      this.sendAddresInSentry(address);
      notification.error({
        message: 'Error',
        description: <span>
          The above address you entered is not found, if you are sure that
          {' '}
          <strong>{address}</strong>
          {' '}
          is a valid address please provide the zip code only in the search so we can show you the delivery options available to you
        </span>,
      });
      return;
    }

    if (!deliveryAddress || deliveryAddress.length === 0) {
      notification.warning({ message: 'Warning', description: 'Please enter your delivery address' });
      return;
    }

    if (deliveryAddress.indexOf('@') >= 0) {
      notification.error({ message: 'Error', description: 'Please use US postal address for delivery' });
      return;
    }

    setDeliveryAddress(deliveryAddress);
    await resetStores();

    clearTimeout(this.counter);
    this.setState({
      exitAnimation: true,
    }, () => {
      this.counter = setTimeout(() => {
        this.props.router.push(`${domainPath}stores?location=${deliveryAddress}`);
      }, 300);
    });
  };

  goToPickUp = () => {
    const { domainPath } = this.props;

    if (!this.state.formatted_address || this.state.address === '') {
      this.renderError('Please enter your pickup address');
      return;
    }
    this.props.router.push(`${domainPath}pick_up?location=${this.state.formatted_address}`);
  };

  openModal = (modalBodyName, renderForm) => {
    const { openModalWindow } = this.props;
    openModalWindow(modalBodyName, renderForm);
  };

  renderError = (message) => {
    const { defaultStyles } = this.state;
    this.setState({
      errorDelivery: true,
      defaultStyles,
    });
  };

  handleOutsideClick = (e) => {
    const root = document.getElementById('app');
    if (root && this.node) {
      if (this.node.contains(root)) {
        return;
      }
    }

    this.toggleSidebar();
  };

  setContainerRef = (node) => {
    this.node = node;
  };

  toggleSidebar = () => {
    if (!this.state.sidebar) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      sidebar: !prevState.sidebar,
    }));
  };

  onClickSidebarItem = (link) => {
    const { router } = this.props;

    this.toggleSidebar();
    router.push(link);
  };

  goToAddress = (link) => {
    const { router } = this.props;

    router.push(link);
  }

  render() {
    const {
      onSignOut,
      successLogIn,
      isFetchingCurrentPosition,
      userInfo,
      router,
      brandInformation,
      theme,
      isFetchingUserInfo,
      domainPath
    } = this.props;

    const options = {
      componentRestrictions: { country: 'us' },
    };

    const brandStyles = brandInformation.brand ? { color: '#636363' } : null;

    const { exitAnimation, address } = this.state;

    return (
      <div
        style={{ fontFamily: theme.typography.fontFamily }}
        className={cx('page-container')}
        ref={this.setContainerRef}
      >
        <Background brandInformation={brandInformation} />
        <Drawer
          open={this.state.sidebar}
          onClickItem={this.onClickSidebarItem}
          userInfo={userInfo}
          router={router}
          domainPath={domainPath}
        />
        <div className={cx('menu-container')}>
          <Header
            onSignOut={this.onSignOut}
            successLogIn={successLogIn}
            goToHeally={this.goToHeally}
            openModal={this.openModal}
            openSidebar={this.toggleSidebar}
            sidebar={this.state.sidebar}
            router={router}
            brandInformation={brandInformation}
            devicePrefix={theme.device}
            isFetchingUserInfo={isFetchingUserInfo}
          />
          <Grid textAlign="center" verticalAlign="middle" style={{ height: '100vh' }}>
            <GridColumn>
              <div className={cx('page-wrapper')}>
                <div className={cx(exitAnimation ? 'fade-out-top' : null)}>
                  <Image
                    className={cx('logo')}
                    src={
                      brandInformation.brand
                        ? brandInformation.brand.brand_logo.origin
                        : require('images/logo.png')
                    }
                    alt=""
                  />
                </div>
                {/* <img className={cx('logo-mobile')} src={require('images/logo_mobile.png')} alt="" /> */}
                <GridRow textAlign="center">
                  <div style={brandStyles} className={cx('main-description')}>
                    Health At Your Fingertips
                  </div>
                </GridRow>
                <div className={cx('main-content', exitAnimation ? 'fade-out-bottom' : null)} id="search-input-container">
                  <PlacesAutocomplete
                    value={address}
                    onChange={this.handleChange}
                    onSelect={addressDelivery => this.handleSelect(addressDelivery)}
                    onError={this.handleError}
                    searchOptions={options}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                      if (suggestions && suggestions.length > 0) {
                        this.suggestions = suggestions;
                      }
                      return (
                        <div className={cx('search-bar-container')}>
                          <div className={cx('search-input-container')} id="autocomlete-container">
                            <Input
                              fluid
                              size="large"
                              icon={{ name: 'angle right', link: true, onClick: this.goToDelivery }}
                              link
                              className={cx('custom-input')}
                              {...getInputProps({
                                placeholder: isFetchingCurrentPosition
                                  ? 'Getting your geolocation...'
                                  : 'Enter your zip code or delivery address...',
                                readOnly:
                                  window.isMobile || window.isTablet ? false : this.state.readonly,
                                // className: cx('search-input'),
                                onFocus: this.handleFocus,
                                onKeyDown: this.handleEnterKeyDown,
                              })}
                            />
                            {suggestions && suggestions.length > 0 && (
                              <div className={cx('autocomplete-container')}>
                                {suggestions.map((suggestion) => {
                                  const className = cx('suggestion-item', `suggestion-item--${theme.device}`, {
                                    'suggestion-item--active': suggestion.active,
                                  });

                                  return (
                                    /* eslint-disable react/jsx-key */
                                    <div {...getSuggestionItemProps(suggestion, { className })}>
                                      <strong>{suggestion.formattedSuggestion.mainText}</strong>
                                      {' '}
                                      <small>{suggestion.formattedSuggestion.secondaryText}</small>
                                    </div>
                                  );
                                  /* eslint-enable react/jsx-key */
                                })}
                                {suggestions && suggestions.length < 5
                                  && (
                                    <div className={cx('search-bar-footer')}>
                                      <div>
                                        <img src={require('images/Powered_by_google.png')} className={cx('search-bar-footer-image')} />
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>
                  <div className={cx('button-group')}>
                    <Button
                      type="button"
                      primary
                      style={{ backgroundColor: theme.button.secondary, marginRight: 0, fontSize: '1.14285714rem' }}
                      disabled={this.state.spinner}
                      onClick={this.goToDelivery}
                      fluid
                      size="large"
                      className={cx('custom-button-delivery')}
                    >
                      Delivery
                  </Button>
                  </div>
                </div>

                <div className={cx('card-yet-group')}>
                  <div style={brandStyles} className={cx('card-yet-description', `card-yet-description--${theme.device}`)}>
                    Need a doctors recommendation?
                  {' '}
                    <br className={cx('break-word')} />
                    <a
                      className={cx('card-yet-link', `card-yet-link--${theme.device}`)}
                      href={
                        brandInformation.brand
                          ? brandInformation.brand.doctor_link
                          : 'https://getheally.com'
                      }
                    >
                      See a doctor now
                  </a>
                  </div>
                </div>

                <div className={cx('medical-description')} />
              </div>
            </GridColumn>
          </Grid>
          <div className={cx('page-content')}>

          </div>

          <Footer
            openModal={this.openModal}
            successLogIn={successLogIn}
            brandStyles={brandStyles}
            devicePrefix={theme.device}
            isFetchingUserInfo={isFetchingUserInfo}
            goToAddress={this.goToAddress}
            domainPath={domainPath}
          />
        </div>
      </div>
    );
  }
}

export default withTheme(WelcomePage);
