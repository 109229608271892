import React from 'react';
import ReactGA from 'react-ga';
import uuidv1 from 'uuid/v1';

import { getLocalStorage, saveLocalStorage } from 'services';

ReactGA.initialize('UA-107902222-1');

if (!getLocalStorage('client_uid')) {
  const uuid = uuidv1();
  if (uuid && uuid !== undefined) {
    saveLocalStorage('client_uid', uuidv1());
  }
}

const withTracker = (WrappedComponent) => {
  const HOC = (props) => {
    let url = '';
    if (window.location.search === '') url = `?client_uid=${getLocalStorage('client_uid')}`;
    else url = `&client_uid=${getLocalStorage('client_uid')}`;

    if (window.$zopim) {
      window.$zopim(() => {
        // update visitor's path with specific page url and title
        window.$zopim.livechat.sendVisitorPath({
          url: `${window.location.href}${url}`,
          title: `${window.location.hostname}`,
        });
      });
    }
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
    }

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
