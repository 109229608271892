import React, { Component } from 'react';
import Image from 'components/Image';

import classnames from 'classnames/bind';
import styles from './StoreInfo.module.scss';

const cx = classnames.bind(styles);

class StoreInfo extends Component {
  componentDidMount() {
    const { store, fetchStoreInfo, bufferStoreInfo } = this.props;

    fetchStoreInfo(store.store_id, bufferStoreInfo);
  }
  render() {
    const { onClick, store, bufferStoreInfo } = this.props;

    const storeInfo = bufferStoreInfo[store.store_id] && bufferStoreInfo[store.store_id];

    return (
      <div className={cx('store-info-container')}>
        {storeInfo && (
          <img
            onClick={() => onClick(storeInfo)}
            src={storeInfo.logo.in_cart}
            alt={storeInfo.name}
            title={storeInfo.name}
          />
        )}
      </div>
    );
  }
}

export default StoreInfo;
