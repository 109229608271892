import React from 'react';
import classnames from 'classnames/bind';

import { getUnhandledProps, useValueAndKey } from 'utils/lib';
import styles from './Table.scss';

import TableCell from './TableCell';

const cx = classnames.bind(styles);

const TableHeaderCell = (props) => {
  const { as, className, sorted } = props;
  const classes = cx(useValueAndKey(sorted, 'sorted'), className);
  const rest = getUnhandledProps(TableHeaderCell, props);

  return <TableCell {...rest} as={as} className={classes} />;
};

TableHeaderCell.defaultProps = {
  as: 'th',
};

export default TableHeaderCell;
