export const FETCH_STORES = 'FETCH_STORES';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAIL = 'FETCH_STORES_FAIL';

export const SAVE_DOCTOR_CART = 'SAVE_DOCTOR_CART';
export const SAVE_DOCTOR_CART_SUCCESS = 'SAVE_DOCTOR_CART_SUCCESS';
export const SAVE_DOCTOR_CART_FAIL = 'SAVE_DOCTOR_CART_FAIL';

export const ERASE_DATA_SAVE_CART = 'ERASE_DATA_SAVE_CART';

export const SET_LINK_WITH_PRODUCTS = 'SET_LINK_WITH_PRODUCTS';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const DELETE_DOCTOR_CART = 'DELETE_DOCTOR_CART';
export const DELETE_DOCTOR_CART_SUCCESS = 'DELETE_DOCTOR_CART_SUCESS';
export const DELETE_DOCTOR_CART_FAIL = 'DELETE_DOCTOR_CART_FAIL';

export const FETCH_DOCTOR_INFO = 'FETCH_DOCTOR_INFO';
export const FETCH_DOCTOR_INFO_SUCCESS = 'FETCH_DOCTOR_INFO_SUCCESS';
export const FETCH_DOCTOR_INFO_FAIL = 'FETCH_DOCTOR_INFO_FAIL';

export const CHANGE_PATIENT_CART_EMAIL = 'CHANGE_PATIENT_CART_EMAIL';
