import React from 'react';
import classnames from 'classnames/bind';

import { isNil, getElementType, getUnhandledProps } from 'utils/lib';

import styles from './Header.scss';

const cx = classnames.bind(styles);

const HeaderContent = (props) => {
  const { children, className, content } = props;
  const classes = cx('content', className);
  const rest = getUnhandledProps(HeaderContent, props);
  const ElementType = getElementType(HeaderContent, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default HeaderContent;
