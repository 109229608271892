import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil, getElementType, getUnhandledProps
} from 'utils/lib'

import styles from './Placeholder.module.scss';

const cx = classnames.bind(styles);

const PlaceholderParagraph = (props) => {
  const { children, className, content } = props;
  const classes = cx('paragraph', className);
  const rest = getUnhandledProps(PlaceholderParagraph, props);
  const ElementType = getElementType(PlaceholderParagraph, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  )
}

export default PlaceholderParagraph;
