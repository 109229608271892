import React from 'react';
import Header from 'components/Typography/Header/Header';

import classnames from 'classnames/bind';
import styles from './Prices.modules.scss';

import "odometer/themes/odometer-theme-default.css";

const cx = classnames.bind(styles);

const Prices = (props) => {
  const { name, price, customStyle } = props;
  return (
    <div className={cx('total-block')}>
      <Header
        className={cx('total-title')}
        size={customStyle ? 'medium' : 'large'}>
        {name}
      </Header>
      {/* <div
        style={
          customStyle
            ? { fontSize: customStyle.fontSize, paddingLeft: customStyle.paddingLeft }
            : null
        }
        className={cx('total-title')}
      >
        {name}
      </div> */}
      <div style={{
        transition: '0.8s ease-out',
        fontSize: '1.5em',
        margin: '0px 22px',
        color: '#5595b0',
        fontWeight: '800',
        transitionProperty: 'background-color, color, opacity',
        display: 'flex',
      }}>
        $&nbsp;
        {price}
      </div>
      {/* <AnimatedNumber
        value={price}
        stepPrecision={2}
        style={{
          transition: '0.8s ease-out',
          fontSize: customStyle ? customStyle.fontSize : 24,
          margin: '0px 22px',
          color: '#5595b0',
          fontWeight: '800',
          transitionProperty: 'background-color, color, opacity',
        }}
        frameStyle={perc => (perc === 100 ? {} : null)}
        duration={300}
        formatValue={n => `$${n}`}
      /> */}
    </div>
  );
};

export default Prices;
