import React, { Component } from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';
import {
  useKeyOnly,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOrValueAndKey,
  useValueAndKey,
} from 'utils/lib';
import styles from './Icon.css';

const cx = classnames.bind(styles);

class Icon extends Component {
  static defaultProps = {
    as: 'i',
  };

  getIconAriaOptions = () => {
    const ariaOptions = {};
    const { 'aria-label': ariaLabel, 'aria-hidden': ariaHidden } = this.props;

    if (_.isNil(ariaLabel)) {
      ariaOptions['aria-hidden'] = 'true';
    } else {
      ariaOptions['aria-label'] = ariaLabel;
    }

    if (!_.isNil(ariaHidden)) {
      ariaOptions['aria-hidden'] = ariaHidden;
    }

    return ariaOptions;
  };

  render() {
    const {
      bordered,
      circular,
      className,
      color,
      corner,
      disabled,
      fitted,
      flipped,
      inverted,
      link,
      loading,
      name,
      rotated,
      size,
    } = this.props;

    const names = name.split(' ') || [];

    const classes = cx(
      color,
      ...names,
      size,
      useKeyOnly(bordered, 'bordered'),
      useKeyOnly(circular, 'circular'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(fitted, 'fitted'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(link, 'link'),
      useKeyOnly(loading, 'loading'),
      useKeyOrValueAndKey(corner, 'corner'),
      useValueAndKey(flipped, 'flipped'),
      useValueAndKey(rotated, 'rotated'),
      'icon',
      className
    );

    const rest = getUnhandledProps(Icon, this.props);
    const ElementType = getElementType(Icon, this.props);
    const ariaOptions = this.getIconAriaOptions();

    return <ElementType {...rest} {...ariaOptions} className={classes} />;
  }
}

Icon.create = createShorthandFactory(Icon, value => ({ name: value }));

export default Icon;
