import { Component } from 'react';

import getNodeFromProps from './utils/getNodeFromProps';
import handleClassNamesChange from './utils/handleClassNamesChange';
import NodeRegistry from './utils/NodeRegistry';

const nodeRegistry = new NodeRegistry();

export default class MountNode extends Component {
  shouldComponentUpdate({ className: nextClassName }) {
    const { className: currentClassName } = this.props;

    return nextClassName !== currentClassName;
  }

  componentDidMount() {
    const node = getNodeFromProps(this.props);

    if (node) {
      nodeRegistry.add(node, this);
      nodeRegistry.emit(node, handleClassNamesChange);
    }
  }

  componentDidUpdate() {
    const node = getNodeFromProps(this.props);

    if (node) nodeRegistry.emit(node, handleClassNamesChange);
  }

  componentWillUnmount() {
    const node = getNodeFromProps(this.props);

    if (node) {
      nodeRegistry.del(node, this);
      nodeRegistry.emit(node, handleClassNamesChange);
    }
  }

  render() {
    return null;
  }
}
