import React, { PureComponent } from 'react';

const TEXT_MESSAGE = 'Coupon is not applicable for this store. Please apply this coupon at ';

class MessageOne extends PureComponent {
  state = {
    handleRender: true,
  };

  showAllStores = () => {
    this.setState({
      handleRender: false,
    });
  };

  render() {
    const { data } = this.props;
    const { handleRender } = this.state;

    if (data.length > 3 && handleRender) {
      return (
        <div>
          {TEXT_MESSAGE}
          <b>{data.slice(0, 3).join(', ')} </b>
          <a onClick={this.showAllStores} style={{ color: '#4bc6b4' }}>
            ...more
          </a>
        </div>
      );
    }
    return (
      <div>
        {TEXT_MESSAGE} <b>{data.join(', ')}</b>
      </div>
    );
  }
}

export default MessageOne;
