import React, { Component } from 'react';

import classnames from 'classnames/bind';

import { getElementType, getUnhandledProps } from 'utils/lib';

import styles from './Table.scss';

const cx = classnames.bind(styles);

const TableBody = (props) => {
  const { children, className } = props;
  const classes = cx(className);
  const rest = getUnhandledProps(TableBody, props);
  const ElementType = getElementType(TableBody, props);

  return (
    <ElementType {...rest} className={classes}>
      {children}
    </ElementType>
  );
};

TableBody.defaultProps = {
  as: 'tbody',
};

export default TableBody;
