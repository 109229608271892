import React, { Component } from 'react';

import classnames from 'classnames/bind';
import styles from './ImageUpload.module.scss';

const cx = classnames.bind(styles);

class ImageUpload extends Component {
  state = {
    file: '',
  };

  handleChange = (e) => {
    const { imagePreview, handleError } = this.props;
    if (!window.FileReader) {
      handleError('The file API is not supported on this browser yet.');
      return;
    }

    if (!e.target.files) {
      handleError('This browser does not seem to support the files property of file inputs.');
      return;
    }

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 10242880) {
      handleError('The file size is too large - more than 15Mb. Please select another file.');
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file,
      });
      imagePreview(file, reader.result);
    };

    reader.readAsDataURL(file);
  };

  render() {
    return (
      <input
        type="file"
        accept="image/*,.pdf"
        className={cx('file-upload')}
        onChange={e => this.handleChange(e)}
      />
    );
  }
}

export default ImageUpload;
