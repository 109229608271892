import React, { Component, Fragment } from 'react';
import ModalWindow from 'components/ModalWindow';

import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Segment from 'components/Segment/Segment';
import Divider from 'components/Divider';

import OrderTitle from './components/OrderTitle';
import OrderInfo from './components/OrderInfo';

import ModalContent from './components/ModalContent';

import classnames from 'classnames/bind';
import styles from './History.module.scss';

const cx = classnames.bind(styles);

class History extends Component {
  renderReceivedOrders = (receivedOrder) => {
    const { onOrderAgain, goToStore, fetchStoreInfo, bufferStoreInfo, onClick, handleRate, editComment } = this.props;

    const order = receivedOrder.products.map((product, key) => (
      <div className={cx('orderInfo-wrapper')} key={key}>
        <OrderInfo
          product={product}
          onClick={() => onClick(product, receivedOrder.order)}
          handleRate={(e, rating) => handleRate(e, rating, product, receivedOrder.order)}
          editComment={() => editComment(product, receivedOrder.order)}
        />
      </div>
    ));

    return (
      <GridRow>
        <GridColumn width={16}>
          <Segment
            key={receivedOrder.order && receivedOrder.order.id}
          >
            <OrderTitle
              order={receivedOrder.order}
              onOrderAgain={onOrderAgain}
              fetchStoreInfo={fetchStoreInfo}
              bufferStoreInfo={bufferStoreInfo}
              onClick={goToStore}
            />
            <Divider />
            {order}
          </Segment>
        </GridColumn>
      </GridRow>
    );
  };

  render() {
    const {
      receivedOrders,
      openModalStoreType,
      closeModalStoreType,
      fetchStoreInfo,
      orderInfo,
      storeInfo,
      onContinueOrderAgain,
      isFetchingStoreInfo,
    } = this.props;

    return (
      <Fragment>
        <GridRow>
          <GridColumn width={16}>
            <h1 className={cx('history-title')}>History</h1>
          </GridColumn>
        </GridRow>
        {receivedOrders && receivedOrders.map(this.renderReceivedOrders)}
        {openModalStoreType ? (
          <ModalWindow
            openModal={openModalStoreType}
            closeModal={closeModalStoreType}
            content={(
              <ModalContent
                orderInfo={orderInfo}
                storeInfo={storeInfo}
                fetchStoreInfo={fetchStoreInfo}
                isFetchingStoreInfo={isFetchingStoreInfo}
                onCancel={closeModalStoreType}
                onContinue={onContinueOrderAgain}
              />
            )}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default History;
