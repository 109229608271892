import React, { Component } from 'react';

import getArguments from 'services/image';

export default class Image extends Component {
  state = {
    width: 0,
    height: 0,
    isNoImage: false,
  };

  resizeImage = () => {
    const { width, height } = this.props;

    if (this.image == null) {
      return;
    }

    const originalWidth = this.image.naturalWidth;
    const originalHeight = this.image.naturalHeight;
    const widthRatio = width / originalWidth;
    const heightRatio = height / originalHeight;
    if (widthRatio < heightRatio) {
      this.setState({
        width: originalWidth * widthRatio,
        height: originalHeight * widthRatio,
      });
    } else {
      this.setState({
        width: originalWidth * heightRatio,
        height: originalHeight * heightRatio,
      });
    }
  };

  showNoImage = () => {
    const { noImageSrc } = this.props;
    if (noImageSrc === undefined) {
      return;
    }
    this.setState({
      isNoImage: true,
    });
  };

  render() {
    const {
      width,
      height,
      backgroundColor,
      src,
      alt,
      noImageSrc,
      onClick,
      noImageAlt,
      styles,
    } = this.props;
    const style = {
      wrapper: {
        position: 'relative',
        width,
        height,
        backgroundColor,
      },
      image: {
        display: 'block',
        width: this.state.width,
        height: this.state.height,
        cursor: 'pointer',
        ...styles,
      },
    };
    if (this.state.isNoImage) {
      return (
        <div style={getArguments(this.props.style, style.wrapper)}>
          <img
            ref={this.image}
            src={noImageSrc}
            alt={noImageAlt || 'noImage'}
            style={style.image}
          />
        </div>
      );
    }
    return (
      <div style={getArguments(this.props.style)}>
        <img
          ref={(node) => {
            this.image = node;
          }}
          onClick={onClick && onClick}
          src={src}
          alt={alt}
          title={alt || ''}
          style={style.image}
          onLoad={() => this.resizeImage()}
          onError={this.showNoImage}
        />
      </div>
    );
  }
}
