import React from 'react';
import classnames from 'classnames/bind';

import {
  getElementType, getUnhandledProps, useKeyOnly
} from 'utils/lib'

import styles from './Placeholder.module.scss';

const cx = classnames.bind(styles);

const PlaceholderImage = (props) => {
  const { className, square, rectangular } = props;
  const classes = cx(
    useKeyOnly(square, 'square'),
    useKeyOnly(rectangular, 'rectangular'),
    'image',
    className,
  );
  const rest = getUnhandledProps(PlaceholderImage, props);
  const ElementType = getElementType(PlaceholderImage, props);

  return <ElementType {...rest} className={classes} />
}

export default PlaceholderImage;
