const detectedDevice = () => {
  if (window.isMobile) return 'mobile';
  if (window.isTablet) return 'tablet';
  return 'desktop';
};

const themes = {
  normal: {
    device: detectedDevice(),
    button: {
      primary: '#f6e0a3',
      secondary: '#59c2b1',
      danger: '#f8a186',
    },
    layout: {
      headerColor: '#4bc6b4',
      footerColor: '#128fbc',
      background: '',
      logo: '',
      logoSmall: '',
    },
    typography: {
      primary: '#ffffff',
      secondary: '#636363',
      fontFamily: "'MyriardPro-LightSemiExt', -apple-system, Arial, sans-serif",
    },
    links: {
      external_url: '',
      doctor_link: 'https://getheally.com',
    },
  },
  dark: {
    device: detectedDevice(),
    button: {
      primary: '#5C6BC0',
      secondary: '#82A3B3',
      danger: '#F44336',
    },
    typography: {
      fontFamily: "-apple-system, 'Rubik', Arial, sans-serif",
    },
    layout: {
      headerColor: '#876a50',
      footerColor: '#8f8f8d',
    },
  },
};

export { themes as default };
