import { connect } from 'react-redux';
import Category from '../components/Category';

import { getCategoryData } from 'modules/category/selectors';

const mapStateToProps = state => ({
  categoryData: getCategoryData(state.category),
})

export default connect(mapStateToProps, null)(Category);
