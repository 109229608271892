import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { request, generateQuery } from 'services/api';
import { getLocalStorage } from 'services';
import getStructureDelivery from 'mocks/structureDelivery';

import notification from 'components/Notification';
import * as constantsCart from '../cart/constants';
import * as constantsProducts from '../products/constants';
import * as constants from './constants';

const bufferRequests = [];
const MESSAGE_SAME_DAY_DELIVERY_ONE = 'Below are partners that deliver to you within same day of order placed.';
const MESSAGE_SAME_DAY_DELIVERY_TWO = 'Below are the services that will deliver overnight.';
const MESSAGE_SAME_DAY_DELIVERY_THREE = 'The following address is currently served by our next day partners only.';

const PAYMENT_METHODS = {
  cash: 'cash_payment',
  card: 'card_payment',
  online_payment: 'online_payment'
}

const fetchStoresMethod = (query, dispatch, router, location) => request(`/api/v1/stores/delivery_search?address=${query}`)
  .then(response => response.json())
  .then((json) => {
    if (json.data.length > 0) {
      const storesSameDay = json.data.filter(v => v.same_day_delivery);
      const storesOvernight = json.data.filter(v => !v.same_day_delivery);
      const listDataStructureDelivery = getStructureDelivery();

      if (storesSameDay.length > 1 && storesOvernight.length > 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.multiple,
          payload: json.data,
          sameDay: true,
        });
      } else if (storesSameDay.length > 1 && storesOvernight.length === 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.multiple_single,
          payload: json.data,
          redirectIntoStoreOvernight: storesOvernight[0],
          sameDay: true,
        });
      } else if (storesSameDay.length > 1 && storesOvernight.length === 0) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.multiple_none,
          payload: json.data,
          sameDay: true,
        });
      } else if (storesSameDay.length === 1 && storesOvernight.length > 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.single_multiple,
          payload: json.data,
          redirectIntoStoreSameDay: storesSameDay[0],
          sameDay: true,
        });
      } else if (storesSameDay.length === 1 && storesOvernight.length === 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.single,
          payload: json.data,
          redirectIntoStoreSameDay: storesSameDay[0],
          redirectIntoStoreOvernight: storesOvernight[0],
          sameDay: true,
        });
      } else if (storesSameDay.length === 1 && storesOvernight.length === 0) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.single_none,
          payload: json.data,
          redirectIntoStoreSameDay: storesSameDay[0],
          sameDay: true,
        });
      } else if (storesSameDay.length === 0 && storesOvernight.length > 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.none_multiple,
          payload: json.data,
          sameDay: false,
        });
      } else if (storesSameDay.length === 0 && storesOvernight.length === 1) {
        dispatch({
          type: [constants.HANDLE_DELIVERY_DATA],
          data: listDataStructureDelivery.none_single,
          payload: json.data,
          redirectIntoStoreOvernight: storesOvernight[0],
          sameDay: false,
        });
      }

      // dispatch({
      //   type: [constants.HANDLE_SAME_DAY_DELIVERY],
      //   payload: json.data,
      //   value: true,
      //   oneStoreForSameDayDelivery: json.data,
      //   messageForSameDayDelivery: MESSAGE_SAME_DAY_DELIVERY_THREE,
      //   messageForStoresOvernight: true,
      //   viewAllStores: true,
      // });
    } else {
      dispatch({
        type: [constants.FETCH_STORES_SUCCESS],
        payload: json.data,
        isSameDayDelivery: false,
        viewAllStores: false,
        messageForSameDayDelivery: '',
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: [constants.FETCH_STORES_FAIL],
      payload: true,
    });
    throw err;
  });

export const fetchStores = (location, brandInformation, router, shortGroupName) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_STORES],
  });
  geocodeByAddress(location)
    .then((results) => {
      let zip = generateQuery('postal_code', results[0]);

      const locality = generateQuery('sublocality_level_1', results[0], false);
      const city = generateQuery('locality', results[0], false);
      const county = generateQuery('administrative_area_level_2', results[0]);
      const state = generateQuery('administrative_area_level_1', results[0]);
      const country = generateQuery('country', results[0]);
      let query = [locality, city, county, state, country].join(',').replace(/^,+/, ',');
      if (!zip) {
        getLatLng(results[0]).then((res) => {
          const geocoder = window.google && new window.google.maps.Geocoder();
          geocoder.geocode({ location: res }, (result) => {
            if (result) {
              const geoData = result.find(value => value.types[0] === 'postal_code');
              if (geoData) {
                zip = generateQuery('postal_code', geoData);
                query += `&zip=${zip}`;
              }
              if (brandInformation && brandInformation.brand) {
                query += `&brand_short_name=${brandInformation.brand.short_name}`;
              }

              if (shortGroupName) {
                query += `&group_short_name=${shortGroupName}`;
              }
              fetchStoresMethod(query, dispatch, router, location);
            }
          });
        });
      } else {
        query += `&zip=${zip}`;
        if (brandInformation && brandInformation.brand) {
          query += `&brand_short_name=${brandInformation.brand.short_name}`;
        }

        if (shortGroupName) {
          query += `&group_short_name=${shortGroupName}`;
        }

        fetchStoresMethod(query, dispatch, router, location);
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_STORES_FAIL],
        payload: true,
      });
      throw err;
    });
};

export const fetchStoreInfo = (id, bufferInfoStores, payment = false) => (dispatch) => {
  if (bufferInfoStores[id] || bufferRequests.indexOf(id) >= 0) {
    dispatch({
      type: [constants.RETURN_BUFFER_STORES_INFO],
      storeInfo: bufferInfoStores[id],
    });

    if (payment) {
      let paymentMethod = null;
      const isExistPayemntMethod = payment && payment.length > 0 && PAYMENT_METHODS[payment];

      if (isExistPayemntMethod && bufferInfoStores[id][isExistPayemntMethod]) {
        paymentMethod = payment;
      } else if (bufferInfoStores[id].online_payment && !bufferInfoStores[id].card_payment) {
        paymentMethod = 'online_payment';
      } else if (!bufferInfoStores[id].online_payment && !bufferInfoStores[id].card_payment) {
        paymentMethod = 'cash';
      }
      dispatch({
        type: [constantsCart.SET_PAYMENT_METHOD],
        paymentMethod,
      });
    }

    return new Promise(resolve => resolve(bufferInfoStores[id]));
  }
  dispatch({
    type: [constants.FETCH_STORE_INFO],
  });
  return request(`/api/v1/stores/${id}`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.FETCH_STORE_INFO_SUCCESS],
          storeInfo: json.data,
        });

        if (payment) {
          let paymentMethod = null;
          const isExistPayemntMethod = payment && payment.length > 0 && PAYMENT_METHODS[payment];

          if (isExistPayemntMethod && json.data[isExistPayemntMethod]) {
            paymentMethod = payment;
          } else if (json.data.online_payment && !json.data.card_payment) {
            paymentMethod = 'online_payment';
          } else if (!json.data.online_payment && !json.data.card_payment) {
            paymentMethod = 'cash';
          }
          dispatch({
            type: [constantsCart.SET_PAYMENT_METHOD],
            paymentMethod,
          });
        }
        if (bufferRequests.length === 0) {
          bufferRequests.push(id);
        }

        if (bufferRequests.indexOf(id) === -1) {
          bufferRequests.push(id);
        }

        return new Promise(resolve => resolve(json.data));
      }
      if (json.error_code === 'record_not_found') {
        dispatch({
          type: [constantsProducts.FETCH_LIST_PRODUCTS_FAIL],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_STORE_INFO_FAIL],
      });
    });
};

export const filterStoresSupportCreditCard = trigger => (dispatch) => {
  dispatch({
    type: [constants.FILTER_STORES_SUPPORT_CREDIT_CARD],
    trigger,
  });
};

export const saveInfoStoreQuery = (storeQuery, stores, sameDayDelivery) => (dispatch) => {
  const storesDay = stores.listStores.filter(v => v.same_day_delivery);
  const storesOvernight = stores.listStores.filter(v => !v.same_day_delivery);
  dispatch({
    type: [constants.SAVE_INFO_STORE_QUERY],
    storeQuery,
    oneStoreForSameDayDelivery: stores.listStores,
    messageForStoresOvernight:
      (storesDay.length > 1 && storesOvernight.length > 1)
        || (storesDay.length === 1 && storesOvernight.length > 1 && !sameDayDelivery)
        || (storesOvernight.length === 1 && storesDay.length > 1 && sameDayDelivery)
        || (storesDay.length > 1 && storesOvernight.length === 0 && sameDayDelivery)
        || (storesDay.length === 0 && storesOvernight.length > 1 && !sameDayDelivery)
        ? sameDayDelivery
        : !sameDayDelivery,
  });
};

const fetchShippingCostInfoMethod = (storeInfo, query, dispatch) => request(`/api/v1/stores/${storeInfo.id}/location_details?location=${query}`)
  .then(response => response.json())
  .then((json) => {
    if (json.status === 'ERROR') {
      if (json.error_code === 'delivery_location.not_found') {
        notification.warning({
          message: 'Warning',
          description: 'Choosen delivery address is not set for this store',
        });
        dispatch({
          type: constantsCart.FETCH_SHIPPING_COST_INFO_FAIL,
          shippingCost: parseFloat(storeInfo.default_delivery_cost),
        });
      }
    } else {
      dispatch({
        type: [constantsCart.FETCH_SHIPPING_COST_INFO_SUCCESS],
        shippingCost: json.data.delivery_cost,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: [constantsCart.FETCH_SHIPPING_COST_INFO_FAIL],
      shippingCost: parseFloat(storeInfo.default_delivery_cost),
    });
  });

export const fetchShippingCostInfo = (storeInfo, query) => (dispatch) => {
  dispatch({
    type: [constantsCart.FETCH_SHIPPING_COST_INFO],
  });
  fetchShippingCostInfoMethod(storeInfo, query, dispatch);
};

export const handleSameDayDelivery = (value, stores) => dispatch => new Promise((resolve) => {
  dispatch({
    type: [constants.HANDLE_SAME_DAY_DELIVERY],
    value,
    payload: stores,
    messageForSameDayDelivery: value
      ? MESSAGE_SAME_DAY_DELIVERY_ONE
      : MESSAGE_SAME_DAY_DELIVERY_TWO,
  });
  resolve(true);
});

export const viewStoresWithOvernightDelivery = value => dispatch => new Promise((resolve) => {
  dispatch({
    type: [constants.VIEW_STORES_WITH_OVERNIGHT_DELIVERY],
    messageForSameDayDelivery: !value
      ? MESSAGE_SAME_DAY_DELIVERY_TWO
      : MESSAGE_SAME_DAY_DELIVERY_ONE,
    viewAllStores: false,
    isSameDayDelivery: value,
  });
  resolve(true);
});

export const handleGoBackToProducts = () => dispatch => new Promise((resolve) => {
  dispatch({
    type: [constants.HANDLE_GO_BACK_TO_PRODUCTS],
    oneStoreForSameDayDelivery: true,
  });
  resolve(true);
});

export const changeDeliveryOption = value => dispatch => new Promise((resolve) => {
  dispatch({
    type: [constants.CHANGE_DELIVERY_OPTION],
    sameDay: value,
  });
  resolve(true);
});

export const resetStores = () => dispatch => new Promise(resolve => {
  dispatch({
    type: [constants.RESET_STORES]
  });
  resolve(true);
})

export const resetStoresAndStoreInformation = () => dispatch => new Promise((resolve) => {
  dispatch({
    type: [constants.RESET_STORES_AND_STORE_INFORMATION],
  });
  resolve(true);
});
