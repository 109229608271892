import React, { Component } from 'react';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import TwoAuthFactor from './components/TwoAuthFactor';

class Auth extends Component {
  onSignIn = (email, password, stateModal, router) => {
    const { onSignIn, signInInfo } = this.props;

    onSignIn(email, password, stateModal, router);
  };

  onSignUp = (firstname, lastname, email, password, passwordRepeat, phone, stateModal, router) => {
    const { onSignUp } = this.props;

    onSignUp(firstname, lastname, email, password, passwordRepeat, phone, stateModal, router);
  };

  resetPassword = (email) => {
    const { resetPassword, stateModal } = this.props;

    resetPassword(email);
  };

  sendNewPassword = (password, passwordRepeat, resetPasswordToken) => {
    const { sendNewPassword, router, stateModal } = this.props;

    sendNewPassword(password, passwordRepeat, resetPasswordToken, router);
  };

  onSignOut = (router) => {
    const { onSignOut } = this.props;

    onSignOut(router);
  }

  render() {
    const {
      renderContent,
      renderForm,
      signInInfo,
      signUpInfo,
      stateModal,
      forgotPasswordTrigger,
      resetPasswordMessages,
      resetPasswordToken,
      closeModalWindow,
      router,
      sendNewPasswordMessageFail,
      isPartnerLogin,
      resentCode,
      authBrowserCode,
      phoneNumber
    } = this.props;

    let body = null;

    if (renderContent === 'changePassword') {
      body = (
        <ChangePassword
          sendNewPassword={this.sendNewPassword}
          resetPasswordToken={resetPasswordToken}
          sendNewPasswordMessageFail={sendNewPasswordMessageFail}
        />
      );
    }

    if (renderContent === 'forgotPassword') {
      body = (
        <ForgotPassword
          resetPassword={this.resetPassword}
          resetPasswordMessages={resetPasswordMessages}
          closeModal={closeModalWindow}
        />
      );
    }

    if (renderContent === 'signup') {
      body = (
        <SignUp
          onSignUp={this.onSignUp}
          signUpInfo={signUpInfo}
          stateModal={stateModal}
          router={router}
          closeModal={closeModalWindow}
          renderForm={renderForm}
          isPartnerLogin={isPartnerLogin}
          phoneNumber={phoneNumber}
        />
      );
    }

    if (renderContent === 'signin') {
      body = (
        <SignIn
          onSignIn={this.onSignIn}
          renderForm={renderForm}
          signInInfo={signInInfo}
          stateModal={stateModal}
          router={router}
          isPartnerLogin={isPartnerLogin}
        />
      );
    }

    if (renderContent === 'twoAuth') {
      body = (
        <TwoAuthFactor
          resentCode={resentCode}
          authBrowserCode={authBrowserCode}
          router={router}
          onSignOut={this.onSignOut}
        />
      );
    }

    return <div>{body}</div>;
  }
}

export default Auth;
