import React, { createElement } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  createHTMLLable,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useWidthProp,
} from 'utils/lib';

import styles from './Form.css';

const cx = classnames.bind(styles);

const FormField = (props) => {
  const {
    children,
    className,
    content,
    control,
    disabled,
    error,
    inline,
    label,
    required,
    type,
    width,
  } = props;

  const classes = cx(
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(error, 'error'),
    useKeyOnly(inline, 'inline'),
    useKeyOnly(required, 'required'),
    useWidthProp(width, 'wide'),
    'field',
    className
  );

  const rest = getUnhandledProps(FormField, props);
  const ElementType = getElementType(FormField, props);

  if (_.isNil(control)) {
    if (_.isNil(label)) {
      return (
        <ElementType {...rest} className={classes}>
          {isNil(children) ? content : children}
        </ElementType>
      );
    }

    return (
      <ElementType {...rest} className={classes}>
        {createHTMLLable(label, { autoGenerateKey: false })}
      </ElementType>
    );
  }

  const controlProps = { ...rest, content, children, disabled, required, type };

  if (control === 'input' && (type === 'checkbox' || type === 'radio')) {
    return (
      <ElementType className={classes}>
        <label>
          {createElement(control, controlProps)}
          {' '}
          {label}
        </label>
      </ElementType>
    );
  }

  // if (control === Checkbox || control === Radio) {
  //   return (
  //     <ElementType className={classes}>
  //       {createElement(control, { ...controlProps, label })}
  //     </ElementType>
  //   );
  // }

  return (
    <ElementType className={classes}>
      {createHTMLLable(label, {
        defaultProps: { htmlFor: _.get(controlProps, 'id') },
        autoGenerateKey: false,
      })}
      {createElement(control, controlProps)}
    </ElementType>
  );
};

export default FormField;
