import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Header from 'components/Typography/Header/Header';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Label from 'components/Label/Label';
import Checkbox from 'components/CheckBox/Checkbox';

import styles from './Additional.module.scss';

const labelStyle = {
  width: '25%',
  paddingLeft: '1em',
  paddingRight: '1em',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

const cx = classnames.bind(styles);

class Additional extends Component {
  render() {
    const { disabled, storeInformation, tax, onChange } = this.props;

    return (
      <div style={{ marginTop: '2em' }}>
        <Header as="h4" block>
          Additional section
        </Header>
        <div style={{ display: 'flex' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Require recommendation:</label>
            <div className={cx('checkbox-container')}>
              <Checkbox
                toggle
                disabled={disabled}
                checked={storeInformation.require_recommendation}
                onClick={onChange}
                name="require_recommendation"
                style={{ opacity: 1 }}
              />
            </div>
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Card payment:</label>
            <div className={cx('checkbox-container')}>
              <Checkbox style={{ opacity: 1 }} toggle disabled={disabled} checked={storeInformation.card_payment} onClick={onChange} name="card_payment" />
            </div>
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} error={tax.length === 0} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Tax:</label>
            <div className={cx('input-container')}>
              <Input
                focus={!disabled}
                fluid
                placeholder="Enter tax price..."
                disabled={disabled}
                icon="percent"
                defaultValue={tax}
                onChange={onChange}
                name='tax'
                type='number'
                style={{ opacity: 1 }}
                className={cx('main-style')}
              />
            </div>
            {
              tax.length === 0 &&
              <Label basic color="red" pointing="left">
                Please enter a value
              </Label>
            }
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Min order price:</label>
            <div className={cx('input-container')}>
              <Input
                placeholder="Enter min order price..."
                fluid
                disabled={disabled}
                focus={!disabled}
                icon="dollar"
                name='min_order_price'
                onChange={onChange}
                style={{ opacity: 1 }}
                className={cx('main-style')}
              />
            </div>
          </FormField>
        </div>
      </div>
    );
  }
}

export default Additional;
