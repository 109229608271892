import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import queryString from 'query-string';
import { withTheme } from '@callstack/react-theme-provider';

import LoadingProducts from 'components/Loaders/Products';
import LoadingCategories from 'components/Loaders/Categories';
import LoadingRemedies from 'components/Loaders/Remedies';
import Portal from 'components/Portal';
import SearchAddressMessage from 'components/Messages/SearchAddress';
import Icon from 'components/Icons/Icon';
import Transition from 'components/Transition/Transition';

import notification from 'components/Notification';
import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import debounce from 'services/debounce';
import SliderCards from './components/SliderCards';
import SliderCategories from './components/SliderCategories';
import SliderRemedies from './components/SliderRemedies';
import ModalBody from './components/ModalContent';
import LogoStore from './components/LogoStore';
import AnimationFrame from './components/AnimationFrame';
import DeliveryMessage from './components/DeliveryMessage';
import ModalProductInformation from './components/ModalProductInformation';


import { saveLocalStorage, parseAddressHost } from 'services';

import classnames from 'classnames/bind';
import styles from './Products.module.scss';

//import Scroll from 'react-scroll';
import ScrollAnim from 'rc-scroll-anim';


// const Events = Scroll.Events;
// const scrollSpy = Scroll.scrollSpy;
// const Element = Scroll.Element;
// const scrollElement = Scroll.animateScroll;
// const scroller = Scroll.scroller;

const { Element } = ScrollAnim;

const cx = classnames.bind(styles);

const encodeQueryData = data => {
  const ret = [];
  for (const d in data) ret.push(`${d}=${data[d]}`);
  return ret.join('&');
};

class Products extends Component {
  state = {
    modalActive: false,
    windowHeight: window.innerHeight,
    toggleRemedy: true,
    activeRemedy: null,
    productInfo: [],
    isOpenProductInformation: false,
  };

  componentDidMount() {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }

    const {
      location,
      fetchListProducts,
      brandInformation,
      fetchStoreInfo,
      bufferStoreInfo,
      listStores,
      history,
      fetchStores,
      sendErrorWithStoreId,
      deliveryAddress,
      setDeliveryAddress,
    } = this.props;

    let storeId = queryString.parse(location.search).store;
    const remedyId = queryString.parse(location.search).remedy;
    const groupName = parseAddressHost(window.location.host);

    if (!storeId) {
      sendErrorWithStoreId();
      return;
    }

    if (storeId && storeId.indexOf('?') >= 0) {
      storeId = storeId.substr(0, storeId.indexOf('?'));
    }

    fetchListProducts(storeId, remedyId, brandInformation, groupName);

    const address = queryString.parse(location.search).location;

    if (deliveryAddress.data.enteringAddress === '' || deliveryAddress.data.enteringAddress !== address) {
      setDeliveryAddress(address);
    }

    if (address) {
      fetchStores(address, brandInformation, history, groupName);
    }
    fetchStoreInfo(parseInt(storeId, 10), bufferStoreInfo).then((resp) => {
      if (resp && resp.name && document) {
        document.title = `Heally - ${resp.name}`;
      }
    });

    // Events.scrollEvent.register('begin');
    // Events.scrollEvent.register('end', () => {
    //   if (this.state.activeRemedy) {
    //     this.handleClickRemedy(this.state.activeRemedy);
    //     this.setState({
    //       toggleRemedy: true,
    //       activeRemedy: null,
    //     });
    //   }
    // });
    if (this.state.activeRemedy) {
      this.handleClickRemedy(this.state.activeRemedy);
      this.setState({
        toggleRemedy: true,
        activeRemedy: null,
      });
    }

    window.addEventListener('resize', this.handleResize);

    //scrollSpy.update();
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  componentWillMount() {
    const { location } = this.props;

    const productId = queryString.parse(location.search).product;

    if (productId) {
      this.setState({
        isOpenProductInformation: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { products } = this.props;
    if (
      nextProps.products.errorMessage !== products.errorMessage &&
      nextProps.products.errorMessage === 'record_not_found'
    ) {
      const hardCloseModal = true;
      this.closeModal(hardCloseModal);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillUnmount() {
    //Events.scrollEvent.remove('begin');
    //Events.scrollEvent.remove('end');

    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.timeout);
  }

  onCloseCardBuy = (categoryId, product) => {
    const { onBuyProductClose, addToCartProduct, totalQuantity, resetCart } = this.props;
    onBuyProductClose(categoryId, product.id);
    let reset = false;
    if (totalQuantity === 1) {
      reset = true;
    }

    addToCartProduct(product, 0, reset);
  };

  onBuy = (categoryId, product, quantity) => {
    const { storeId, location, totalQuantity } = this.props;
    const { productInfo } = this.state;

    if (storeId && parseInt(storeId) !== parseInt(queryString.parse(location.search).store) && totalQuantity !== 0) {
      notification.warning({
        message: 'Warning',
        description: 'You have pending purchase in another store!',
      });
    } else {
      const productCard = document.getElementById(`${product.id}_product`);
      if (productCard) {
        const position = productCard.getBoundingClientRect();
        const productInformation = {
          productInformation: product,
          y: position.y,
          x: position.x,
          width: !window.isMobile ? 290 : 158,
          height: !window.isMobile ? 181 : 98,
        };
        const productInfoConcat = productInfo.concat(productInformation);
        this.setState({
          productInfo: productInfoConcat,
        });
      }
      this.setProduct(product, quantity, categoryId);
    }
  };

  onBuyNow = (product, quantity) => {
    const { history, domainPath } = this.props;

    history.push(`${domainPath}cart`);
  };

  onBuyProduct = (categoryId, product) => {
    const { onBuyProduct } = this.props;
    onBuyProduct(categoryId, product.id);
  };

  setProduct = (product, quantity, categoryId) => {
    const { addToCartProduct, totalQuantity, initializeCart, location, onBuyProduct } = this.props;
    const storeType = queryString.parse(location.search).order_type;
    const storeId = queryString.parse(location.search).store;

    addToCartProduct(product, quantity, false, null, storeType, storeId);
    onBuyProduct(categoryId, product.id);
  };

  closeModal = (hardCloseModal = false) => {
    const { location, history } = this.props;

    if (queryString.parse(location.search).product) {
      const objectQueryString = queryString.parse(location.search);
      delete objectQueryString.product;

      const queryStrings = encodeQueryData(objectQueryString);
      history.replace(`${location.pathname}?${queryStrings}`);
    }
    this.setState({
      isOpenProductInformation: false,
    }, () => {
      if (hardCloseModal) {
        notification.warning({
          message: 'Warning',
          description: 'Sorry, this product is currently out of stock',
        });
      }
    });
  };

  handleClick = (categoryId, card) => {
    // event.cancelBubble = true;
    const { openModal, location, history, fetchProductInformation } = this.props;

    history.replace(`${location.pathname}${location.search}&product=${card.id}`);

    this.setState({
      isOpenProductInformation: true,
      card,
      categoryId,
    });
  };

  handleResize = debounce(() => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }, 250);

  handleClickImageCategory = (id, offset) => {
    if (window.isMobile) {
      // scroller.scrollTo(id, {
      //   isDynamic: true,
      //   spy: true,
      //   smooth: true,
      //   duration: 500,
      //   offset,
      // });
    }
  };

  removeToCart = (product, quanitty) => {
    const { onBuyProductClose, addToCartProduct, totalQuantity } = this.props;
    let reset = false;
    onBuyProductClose(this.state.categoryId, product.id);
    if (totalQuantity === 1) {
      reset = true;
    }
    addToCartProduct(product, 0, reset);
  };

  fetchProductInformation = product => {
    const { fetchProductInformation, location, history } = this.props;
    fetchProductInformation(product, location, history);
  };

  addToCart = (product, quantity) => {
    this.onBuy(this.state.categoryId, product, quantity);
  };

  handleClickRemedy = remedy => {
    const { handleClickRemedy, location, history } = this.props;

    const remedyLocation = queryString.parse(location.search);
    const pathname = `${location.pathname}`;

    if (remedyLocation.remedy == remedy.id) {
      delete remedyLocation.remedy;
    } else {
      remedyLocation.remedy = remedy.id;
    }

    const encodeQuery = encodeQueryData(remedyLocation);

    history.replace(`${pathname}?${encodeQuery}`);

    handleClickRemedy(remedy);
  };

  debouceScrollToTop = debounce(remedy => {
    this.handleClickRemedy(remedy);
    this.scrollToTop();
    this.setState({
      toggleRemedy: true,
    });
  }, 250);

  handleToggleRemedy = (remedy, toggle) => {
    this.setState(
      {
        toggleRemedy: false,
      },
      () => {
        this.debouceScrollToTop(remedy);
      }
    );
  };

  handleIsSameDayDelivery = (value) => {
    const { viewStoresWithOvernightDelivery, history, deliveryAddress, domainPath } = this.props;

    viewStoresWithOvernightDelivery(value).then(resp => {
      if (resp) {
        history.push(`${domainPath}stores?location=${deliveryAddress.data.enteringAddress}`);
      }
    });
  };

  onClickCategory = (category, info_page_html) => {
    const { history, domainPath, setCategoryDescription } = this.props;

    setCategoryDescription(info_page_html);

    history.push(`${domainPath}category/${category}`);
  }

  renderSliderCards = (value, key) => {
    const { itemsProducts, remediesData, products, theme, rating, } = this.props;
    const { windowHeight } = this.state;
    const listCategoriesLength = products.listCategories.length - 1;

    return (
      <Element id={value.name.toLowerCase()} key={value.id}>
        <SliderCards
          categories={value}
          onBuy={this.onBuy}
          paddingBottom={key === listCategoriesLength}
          onBuyNow={this.onBuyNow}
          setProduct={this.setProduct}
          itemsProducts={itemsProducts}
          handleClick={this.handleClick}
          onCloseCardBuy={this.onCloseCardBuy}
          devicePrefix={theme.device}
          windowHeight={windowHeight}
          id={key}
          rating={rating}
          onClickCategory={this.onClickCategory}
        />
      </Element>
    );
  };

  removeAnimationFrameElement = value => {
    const { removeAnimationFrameElement } = this.props;

    removeAnimationFrameElement(value);
  };

  scrollToTop = () => {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollBy(0, -50);
      this.timeOut = setTimeout(() => this.scrollToTop(), 10);
    } else {
      clearTimeout(this.timeOut);
    }
  };

  goToOvernightStores = () => {
    const { changeDeliveryOption, deliveryAddress, history, setNameCategoryForHeaderButton, domainPath } = this.props;

    setNameCategoryForHeaderButton('cbd');
    changeDeliveryOption(false).then((resp) => {
      if (resp) history.push(`${domainPath}stores?location=${deliveryAddress.data.enteringAddress}`);
    });
  };

  goToSameDayStores = () => {
    const { changeDeliveryOption, deliveryAddress, history, setNameCategoryForHeaderButton, domainPath } = this.props;

    setNameCategoryForHeaderButton('cbd');
    changeDeliveryOption(true).then((resp) => {
      if (resp) history.push(`${domainPath}stores?location=${deliveryAddress.data.enteringAddress}`);
    });
  };

  goToDelivery = async (address) => {
    const { history, setDeliveryAddress, domainPath } = this.props;

    await setDeliveryAddress(address);
    history.push(`${domainPath}stores?location=${address}`);
  }

  closeProductInformation = () => {
    const { location, history, resetProductInformation } = this.props;

    if (queryString.parse(location.search).product) {
      const objectQueryString = queryString.parse(location.search);
      delete objectQueryString.product;

      const queryStrings = encodeQueryData(objectQueryString);
      history.replace(`${location.pathname}?${queryStrings}`);
    }
    this.setState({
      isOpenProductInformation: false,
    }, () => {
      resetProductInformation();
    })
  }

  render() {
    const {
      products,
      location,
      itemsProducts,
      storeId,
      remediesData,
      listCategories,
      categoriesName,
      totalQuantity,
      oneStoreForSameDelivery,
      storeInfo,
      messageForStoresOvernight,
      theme,
      listStores,
      deliveryMessage,
      sameDay,
      nameCategoryForHeaderButton,
      brandInformation,
      fetchProductComments,
      successLogIn,
      addComment,
      newComment,
      updateComment,
      updComment,
      setRatingForProductCard,
    } = this.props;

    const { modalActive, card, openPortal, productInfo, isOpenProductInformation } = this.state;

    let viewProducts;
    let viewCategories;
    let viewRemedies;
    let disabledModalButton = false;

    const devicePrefix = theme.device;
    if (products.messageFail || listCategories && listCategories.length === 0) {
      if (brandInformation && brandInformation.brand) {
        return (
          <div className={cx('event-container')}>
            <div className={cx('text-message')}>
              Please return to
              {' '}
              <a href={brandInformation.brand.external_url}>{brandInformation.brand.external_url}</a>
              {' '}
              as we currently do not have delivery in your area
            </div>
          </div>
        );
      }
      return (
        <SearchAddressMessage
          goToDelivery={this.goToDelivery}
          message={<p>There are no products in this store that met the search options you selected. Please get back later</p>}
        />
      );
    }

    if (products.isFetching && !listCategories) {
      viewProducts = <LoadingProducts />;
      viewCategories = <LoadingCategories />;
    } else if (listCategories && listCategories.length > 0) {
      viewProducts = (
        <div className={cx('container')}>{listCategories.map(this.renderSliderCards)}</div>
      );
      viewCategories = (
        <SliderCategories
          name={listCategories}
          onClick={this.scrollTo}
          windowHeight={this.state.windowHeight}
          remedyActive={remediesData.remedyActive}
          toggleRemedy={this.state.toggleRemedy}
          handleToggle={this.handleToggleRemedy}
          categoryIndex={this.state.categoryIndex}
          handleClickImageCategory={this.handleClickImageCategory}
          devicePrefix={devicePrefix}
        />
      );
    }

    if (!remediesData.filterRemedies) {
      viewRemedies = <LoadingRemedies />;
    } else if (remediesData.filterRemedies.length > 0) {
      viewRemedies = (
        <SliderRemedies
          windowHeight={this.state.windowHeight}
          remedies={remediesData.filterRemedies}
          handleClickRemedy={this.handleClickRemedy}
          devicePrefix={devicePrefix}
        />
      );
    }

    if (
      storeId
      !== (queryString.parse(location.search)
        && parseInt(queryString.parse(location.search).store))
      && totalQuantity !== 0
    ) {
      disabledModalButton = true;
    }

    const isSameDay = listStores && listStores.length >= 2 && storeInfo && !storeInfo.same_day_delivery ? false : sameDay;

    return (
      <div className={cx('open-wrapper', 'fade-in')}>
        {viewCategories}
        <div className={cx('remedy-name-wrapper')}>{viewRemedies}</div>
        {deliveryMessage && !products.isFetching && nameCategoryForHeaderButton === 'cbd'
          && <DeliveryMessage messages={deliveryMessage.products} sameDay={isSameDay} goToOvernightStores={this.goToOvernightStores} goToSameDayStores={this.goToSameDayStores} devicePrefix={devicePrefix} />
        }
        {viewProducts}
        {modalActive
          && (
            <Transition visible={modalActive} animation="fade" duration={{ show: 500, hide: 500 }}>
              <Modal
                scrolling
                dimmed
                open={modalActive}
                onClose={() => this.closeModal()}
                closeIcon={<Icon name="close" link onClick={() => this.closeModal()} />}
              >
                <ModalContent>
                  <ModalBody
                    product={products.product}
                    location={location}
                    disabledModalButton={disabledModalButton}
                    isFetching={products.isFetchingProduct}
                    fetchProductInformation={this.fetchProductInformation}
                    itemsProducts={itemsProducts}
                    addToCart={this.addToCart}
                    removeToCart={this.removeToCart}
                    onBackToShop={() => this.closeModal()}
                    handleClick={this.handleClick}
                    onBuyNow={this.onBuyNow}
                    closeModal={() => this.closeModal()}
                    card={card}
                    storeInfo={storeInfo}
                  />
                </ModalContent>
              </Modal>
            </Transition>
          )
        }
        {openPortal && (
          <Portal>
            {productInfo.map((product, index) => (
              <AnimationFrame
                product={product}
                key={`${index}_animation_frame`}
                id={product.id}
                index={index}
                removeAnimationFrameElement={this.removeAnimationFrameElement}
              />
            ))}
          </Portal>
        )}
        <Modal
          scrolling
          open={isOpenProductInformation}
          onClose={() => this.closeProductInformation()}
          size="large"
          // className={cx('modal-fullscreen')}
          closeIcon={<Icon className={cx('custom-icon-cross')} name="close" color="grey" link onClick={() => this.closeProductInformation()} />}
        >
          <ModalContent>
            <ModalProductInformation
              product={products.product}
              location={location}
              disabledModalButton={disabledModalButton}
              isFetching={products.isFetchingProduct}
              fetchProductInformation={this.fetchProductInformation}
              itemsProducts={itemsProducts}
              addToCart={this.addToCart}
              removeToCart={this.removeToCart}
              onBackToShop={() => this.closeModal()}
              handleClick={this.handleClick}
              onBuyNow={this.onBuyNow}
              closeModal={() => this.closeModal()}
              card={card}
              storeInfo={storeInfo}
              fetchProductComments={fetchProductComments}
              productComments={products.productComments}
              onBackToShop={this.closeProductInformation}
              successLogIn={successLogIn}
              addComment={addComment}
              newComment={newComment}
              updateComment={updateComment}
              updComment={updComment}
              setRatingForProductCard={setRatingForProductCard}
            />
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

export default withTheme(Products);
