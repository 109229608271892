import React from 'react';
import MessageOne from './components/MessageOne';
import MessageTwo from './components/MessageTwo';

const messages = {
  message_one: {
    description: arr => <MessageOne data={arr} />,
  },
  message_two: {
    description: MessageTwo,
  },
};

export default messages;
