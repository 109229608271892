import { request } from 'services/api';
import notification from 'components/Notification';
import * as constants from './constants';

const base_url = '/seller_panel';

const makeLinks = (arr, dispatch) => {
  const urls = arr.map(value => fetch(`${base_url}/delivery_locations/${value}`, {
    credentials: 'include',
  })
    .then(response => response.json())
    .then(json => ({
      data: json.data,
      id: value,
    })));

  return urls;
};

const returnCategoryId = (name, data) => {
  let id = null;
  data.forEach((v) => {
    if (v.name === name) {
      id = v.id;
    }
  });
  return id;
};

const compare = (el1, el2, index) => (el1[index] === el2[index] ? 0 : (el1[index] < el2[index] ? -1 : 1));

export const fetchStoreInformation = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_STORE_INFORMATION],
  });
  request(`${base_url}/store`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_STORE_INFORMATION_SUCCESS],
        storeInformation: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_STORE_INFORMATION_FAIL],
      });
    });
};

export const changeStoreInformation = (name, value) => (dispatch) => {
  dispatch({
    type: [constants.CHANGE_STORE_INFORMATION],
    name,
    value,
  });
};

export const updateStoreInformation = store => (dispatch) => {
  dispatch({
    type: [constants.UPDATE_STORE_INFORMATION],
  });
  request(`${base_url}/store`, 'PUT', store)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.UPDATE_STORE_INFORMATION_SUCCESS],
        });
        notification.success({
          message: 'Success',
          description: 'Your data is save.',
          placement: 'bottom',
        });
      } else {
        dispatch({
          type: [constants.UPDATE_STORE_INFORMATION_FAIL],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.UPDATE_STORE_INFORMATION_FAIL],
      });
    });
};

const fetchAllDeliveryLocationData = (arr, dispatch) => {
  const listUrl = makeLinks(arr, dispatch);

  Promise.all(listUrl)
    .then((data) => {
      const arrWithDeliveryLocationData = {};
      data.forEach((value) => {
        arrWithDeliveryLocationData[value.id] = value.data;
      });

      dispatch({
        type: [constants.SET_LIST_WITH_DELIVERY_LOCATIONS_SUCCESS],
        listWithDeliveryLocations: arrWithDeliveryLocationData,
        numberOfDeliveryLocations: Object.keys(arrWithDeliveryLocationData).length,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.SET_LIST_WITH_DELIVERY_LOCATIONS_FAIL],
      });
    });
};

export const fetchPolygonsForStore = storeId => (dispatch) => {
  dispatch({
    type: [constants.FETCH_POLYGONS_FOR_STORE],
  });
  request(`/api/v1/stores/${storeId}/polygons`)
    .then(response => response.json())
    .then((json) => {
      const updatePolygons = [];
      json.data[0].polygons.forEach((polygon, i) => {
        updatePolygons[i] = {
          polygon_coordinate: [],
        };
        const newArrWithPolygons = [];
        if (polygon.polygon_coordinate) {
          polygon.polygon_coordinate.forEach((v) => {
            newArrWithPolygons.push({
              lat: v[1],
              lng: v[0],
            });
          });
        }
        updatePolygons[i] = {
          id: polygon.id,
          polygon_coordinate: newArrWithPolygons,
        };
      });

      const updateCoordinates = [];
      const arrWithIds = json.data[0].coordinates.map(v => v.id);

      json.data[0].coordinates.forEach((coordinate, i) => {
        updateCoordinates.push({
          lat: coordinate.coordinate[1],
          lng: coordinate.coordinate[0],
          id: coordinate.id,
        });
      });

      const newData = {
        polygons: updatePolygons,
        center: {
          lat: json.data[0].center[0],
          lng: json.data[0].center[1],
        },
        coordinates: updateCoordinates,
      };

      dispatch({
        type: [constants.FETCH_POLYGONS_FOR_STORE_SUCCESS],
        data: newData,
      });

      fetchAllDeliveryLocationData(arrWithIds, dispatch);
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_POLYGONS_FOR_STORE_FAIL],
      });
    });
};

export const fetchDeliveryLocationIds = id => (dispatch) => {
  dispatch({
    type: [constants.FETCH_DELIVERY_LOCATION_IDS],
  });
  request(`${base_url}/delivery_locations`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_DELIVERY_LOCATION_IDS_SUCCESS],
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_DELIVERY_LOCATION_IDS_FAIL],
      });
    });
};

export const fetchDeliveryLocationData = (id, deliveryLocationData) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_DELIVERY_LOCATION_DATA],
  });
  if (deliveryLocationData) {
    dispatch({
      type: [constants.FETCH_DELIVERY_LOCATION_DATA_SUCCESS],
      deliveryLocationData,
    });
  } else {
    request(`${base_url}/delivery_locations/${id}`)
      .then(response => response.json())
      .then((json) => {
        dispatch({
          type: [constants.FETCH_DELIVERY_LOCATION_DATA_SUCCESS],
          deliveryLocationData: json.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: [constants.FETCH_DELIVERY_LOCATION_DATA_FAIL],
        });
      });
  }
};

export const createDeliveryLocation = (min_order_price, delivery_cost, formatted_address, location, storeId, paths, position, listWithDeliveryLocations) => (dispatch) => {
  dispatch({
    type: [constants.CREATE_DELIVERY_LOCATION],
  });
  return request(`${base_url}/store/delivery_locations`, 'POST', { formatted_address, min_order_price, delivery_cost, location })
    .then(response => response.json())
    .then((json) => {
      //dispatch(fetchPolygonsForStore(storeId));
      const deliveryLocationId = json.data.id;
      const newListWithDeliveryLocation = { ...listWithDeliveryLocations };

      newListWithDeliveryLocation[deliveryLocationId] = {
        coordinates: position,
        delivery_cost,
        formatted_address,
        location,
        location_type: 'address',
        min_order_price,
        polygon_coordinates: paths,
      };

      dispatch({
        type: [constants.CREATE_DELIVERY_LOCATION_SUCCESS],
        listWithDeliveryLocations: newListWithDeliveryLocation,
        numberOfDeliveryLocations: Object.keys(newListWithDeliveryLocation).length,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.CREATE_DELIVERY_LOCATION_FAIL],
      });
    });
};

export const deleteDeliveryLocation = (id, storeId) => (dispatch) => {
  dispatch({
    type: [constants.DELETE_DELIVERY_LOCATION],
  });
  request(`${base_url}/delivery_locations/${id}`, 'DELETE', {})
    .then(response => response.json())
    .then((json) => {
      dispatch(fetchPolygonsForStore(storeId));
      dispatch({
        type: [constants.DELETE_DELIVERY_LOCATION_SUCCESS],
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.DELETE_DELIVERY_LOCATION_FAIL],
      });
    });
};

export const updateDeliveryLocation = (store, min_order_price, delivery_cost, listWithDeliveryLocations) => (dispatch) => {
  dispatch({
    type: [constants.UPDATE_DELIVERY_LOCATION],
  });
  request(`${base_url}/delivery_locations/${store.id}`, 'PATCH', { formatted_address: store.formatted_address, min_order_price, delivery_cost, location: store.location })
    .then(response => response.json())
    .then((json) => {
      listWithDeliveryLocations[store.id] = { ...json.data };
      dispatch({
        type: [constants.UPDATE_DELIVERY_LOCATION_SUCCESS],
        listWithDeliveryLocations,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.UPDATE_DELIVERY_LOCATION_FAIL],
      });
    });
};

export const editStoreInformation = value => (dispatch) => {
  dispatch({
    type: [constants.EDIT_STORE_INFORMATION],
    value,
  });
};

export const setNewStoreLogo = image => (dispatch) => {
  dispatch({
    type: [constants.SET_NEW_STORE_LOGO],
    image,
  });
};

export const fetchListOfCategory = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_OF_CATEGORY],
  });
  return request(`/api/v1${base_url}/store_products/get_all_categories`)
    .then(response => response.json())
    .then((json) => {
      const formattedListCategores = json.data.map(v => ({
        key: v.id,
        id: v.id,
        text: v.name,
        value: v.id,
      }))
        .sort((el1, el2) => compare(el1, el2, 'text'));

      dispatch({
        type: [constants.FETCH_LIST_OF_CATEGORY_SUCCESS],
        payload: formattedListCategores,
      });
      return json.data;
    })
    .catch({
      type: [constants.FETCH_LIST_OF_CATEGORY_FAIL],
    });
};

export const fetchStoreProducts = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_STORE_PRODUCTS],
  });
  request(`/api/v1${base_url}/store_products`)
    .then(response => response.json())
    .then((json) => {
      dispatch(fetchListOfCategory()).then((resp) => {
        const productsOutOfStock = json.data.filter(el => el.out_of_stock);
        const productsInStock = json.data.filter(el => !el.out_of_stock);
        const productsAvaiable = json.data;

        const listSortOfCategoriesWithProducts = {};
        json.data.forEach((v) => {
          if (!listSortOfCategoriesWithProducts[v.category.name]) {
            listSortOfCategoriesWithProducts[v.category.name] = {
              products: [],
              position: v.category.position,
              name: v.category.name,
              id: returnCategoryId(v.category.name, resp),
            };
          }
          listSortOfCategoriesWithProducts[v.category.name].products.push(v);
        });

        const sortedObject = Object.keys(listSortOfCategoriesWithProducts)
          .sort((a, b) => listSortOfCategoriesWithProducts[a].position - listSortOfCategoriesWithProducts[b].position)
          .reduce((prev, curr, i) => {
            prev[i] = listSortOfCategoriesWithProducts[curr];
            return prev;
          }, []);

        const activeCategoryItems = sortedObject.map(v => v.name);

        dispatch({
          type: [constants.FETCH_STORE_PRODUCTS_SUCCESS],
          payload: sortedObject,
          productsOutOfStock,
          productsAvaiable,
          productsInStock,
          activeCategoryItems,
        });
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_STORE_PRODUCTS_FAIL],
      });
    });
};

export const sortProducts = productsIds => (dispatch) => {
  dispatch({
    type: [constants.SORT_PRODUCTS],
  });
  request(`/api/v1${base_url}/store_products/sort_products`, 'POST', { ids: productsIds })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR') {
        dispatch({
          type: [constants.SORT_PRODUCTS_FAIL],
        });
        notification.warning({
          message: 'Warning',
          description: 'Problem with sort products',
        });
      }
      dispatch({
        type: [constants.SORT_PRODUCTS_SUCCESS],
      });
    })
    .catch((err) => {
      notification.warning({
        message: 'Warning',
        description: 'Problem with sort products',
      });
      dispatch({
        type: [constants.SORT_PRODUCTS_FAIL],
      });
    });
};

export const sortCategories = (newCategories, categoresIds) => (dispatch) => {
  dispatch({
    type: [constants.SORT_CATEGORIES],
    payload: newCategories,
  });
  request(`/api/v1${base_url}/store_products/sort_categories`, 'POST', { ids: categoresIds })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR') {
        dispatch({
          type: [constants.SORT_CATEGORIES_FAIL],
        });
        notification.warning({
          message: 'Warning',
          description: 'Problem with sort categories',
        });
      }
      dispatch({
        type: [constants.SORT_CATEGORIES_SUCCESS],
      });
    })
    .catch((err) => {
      notification.warning({
        message: 'Warning',
        description: 'Problem with sort categories',
      });
      dispatch({
        type: [constants.SORT_CATEGORIES_FAIL],
      });
    });
};

export const updateStoreProduct = (id, price, out_of_stock) => (dispatch) => {
  dispatch({
    type: [constants.UPDATE_STORE_PRODUCT],
  });
  return request(`/api/v1${base_url}/store_products/${id}`, 'PATCH', { price, out_of_stock })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.UPDATE_STORE_PRODUCT_SUCCESS],
        productInfo: json.data,
      });
    })
    .catch({
      type: [constants.SORT_CATEGORIES_FAIL],
    });
};

export const deleteStoreProduct = id => (dispatch) => {
  dispatch({
    type: [constants.DELETE_STORE_PRODUCT],
  });
  return request(`/api/v1${base_url}/store_products/${id}`, 'DELETE', {})
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.DELETE_STORE_PRODUCT_SUCCESS],
        id,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.DELETE_STORE_PRODUCT_FAIL],
      });
    });
};

export const fetchListBrands = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_BRANDS],
  });
  request(`/api/v1${base_url}/store_products/get_all_brands`)
    .then(response => response.json())
    .then((json) => {
      const formattedListBrands = json.data.map(v => ({
        key: v.id,
        id: v.id,
        text: v.name,
        value: v.name,
      }))
        .sort((el1, el2) => compare(el1, el2, 'text'));
      dispatch({
        type: [constants.FETCH_LIST_BRANDS_SUCCESS],
        formattedListBrands,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_LIST_BRANDS_FAIL],
      });
    });
};

export const addProduct = (product_id, price, brand_id, category_id) => (dispatch) => {
  dispatch({
    type: [constants.ADD_PRODUCT],
  });
  return request(`/api/v1${base_url}/store_products`, 'POST', { product_id, price, brand_id, category_id })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.ADD_PRODUCT_SUCCESS],
        product: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.ADD_PRODUCT_FAIL],
      });
    });
};

export const searchOfListProducts = (q, brand_id, category_id) => (dispatch) => {
  dispatch({
    type: [constants.SEARCH_PRODUCT],
  });
  request(`/api/v1${base_url}/store_products/find_products?q=${q}&brand_id=${brand_id}&category_id=${category_id}`)
    .then(response => response.json())
    .then((json) => {
      const formattedListProducts = json.data.map(el => ({
        key: el.id,
        id: el.id,
        text: el.name,
        value: el.name,
        category_name: el.category_name,
        brand_name: el.brand,
        packing: el.packing,
        image: {
          avatar: false,
          src: el.image.wide,
        },
        description: `Packing: ${el.packing}, Category: ${el.category_name}`,
      }))
        .sort((el1, el2) => compare(el1, el2, 'text'));

      dispatch({
        type: [constants.SEARCH_PRODUCT_SUCCESS],
        formattedListProducts,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.SEARCH_PRODUCT_FAIL],
      });
    });
};

export const fetchListOrders = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_ORDERS],
  });
  request(`/api/v1${base_url}/orders`)
    .then(response => response.json())
    .then((json) => {
      const ordersProcessing = json.data.filter(v => v.status === 'processing');
      dispatch({
        type: [constants.FETCH_LIST_ORDERS_SUCCESS],
        listOrders: json.data,
        ordersProcessing: ordersProcessing.length,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_LIST_ORDERS_FAIL],
      });
    });
};

export const showOrder = id => (dispatch) => {
  dispatch({
    type: [constants.SHOW_ORDER],
  });
  request(`/api/v1${base_url}/orders/${id}`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.SHOW_ORDER_SUCCESS],
        order: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.SHOW_ORDER_FAIL],
      });
    });
};

export const updateOrder = (id, order) => (dispatch) => {
  dispatch({
    type: [constants.UPDATE_ORDER],
  });
  return request(`/api/v1${base_url}/orders/${id}`, 'PATCH', { ...order })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.UPDATE_ORDER_SUCCESS],
        order: json.data,
      });
      dispatch(fetchListOrders());
    })
    .catch((err) => {
      dispatch({
        type: [constants.UPDATE_ORDER_FAIL],
      });
    });
};

export const massUpdateProducts = out_of_stock => (dispatch) => {
  dispatch({
    type: [constants.MASS_UPDATE_PRODUCTS],
  });
  request(`/api/v1${base_url}/store_products/mass_update`, 'PATCH', { out_of_stock })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.MASS_UPDATE_PRODUCTS_SUCCESS],
        outOfStock: out_of_stock,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.MASS_UPDATE_PRODUCTS_FAIL],
      });
    });
};

export const massDeleteProducts = ids => (dispatch) => {
  dispatch({
    type: [constants.MASS_DELETE_PRODUCTS],
  });
  request(`/api/v1${base_url}/store_products/mass_destroy`, 'DELETE', { ids })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.MASS_DELETE_PRODUCTS_SUCCESS],
        ids,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.MASS_DELETE_PRODUCTS_FAIL],
      });
    });
};

export const fetchSuggestProducts = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_SUGGEST_PRODUCTS],
  });
  request(`/api/v1${base_url}/products`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_SUGGEST_PRODUCTS_SUCCESS],
        listSuggestProducts: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_SUGGEST_PRODUCTS_FAIL],
      });
    });
};

export const showSuggestProduct = id => (dispatch) => {
  dispatch({
    type: [constants.SHOW_SUGGEST_PRODUCT],
  });
  request(`/api/v1${base_url}/products/${id}`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.SHOW_SUGGEST_PRODUCT_SUCCESS],
        suggestProduct: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.SHOW_SUGGEST_PRODUCT_FAIL],
      });
    });
};

export const deleteSuggestProduct = id => (dispatch) => {
  dispatch({
    type: [constants.DELETE_SUGGEST_PRODUCT],
  });
  request(`/api/v1${base_url}/products/${id}`, 'DELETE', {})
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.DELETE_SUGGEST_PRODUCT_SUCCESS],
        id,
      });
      dispatch(fetchSuggestProducts());
    })
    .catch((err) => {
      dispatch({
        type: [constants.DELETE_SUGGEST_PRODUCT_FAIL],
      });
    });
};

export const fetchListOfAllStrains = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_OF_ALL_STRAINS],
  });
  request(`/api/v1${base_url}/products/get_strains`)
    .then(response => response.json())
    .then((json) => {
      const listStrains = json.data.map(value => ({
        key: value.id,
        text: value.name,
        value: value.id,
      }));
      dispatch({
        type: [constants.FETCH_LIST_OF_ALL_STRAINS_SUCCESS],
        listStrains,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_LIST_OF_ALL_STRAINS_FAIL],
      });
    });
};

export const fetchRemediesByStrain = id => (dispatch) => {
  dispatch({
    type: [constants.FETCH_REMEDIES_BY_STRAIN],
  });
  request(`/api/v1${base_url}/products/remedies_by_strain/${id}`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_REMEDIES_BY_STRAIN_SUCCESS],
        listRemediesByStrain: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_REMEDIES_BY_STRAIN_FAIL],
      });
    });
};

export const setActiveCategoryItems = activeCategoryItems => (dispatch) => {
  dispatch({
    type: [constants.SET_ACTIVE_CATEGORY_ITEMS],
    activeCategoryItems,
  });
};

export const createNewSuggestProduct = suggestProduct => (dispatch) => {
  dispatch({
    type: [constants.CREATE_NEW_SUGGEST_PRODUCT],
  });
  request(`/api/v1${base_url}/products`, 'POST', { ...suggestProduct })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.CREATE_NEW_SUGGEST_PRODUCT_SUCCESS],
      });
      dispatch(fetchSuggestProducts());
    })
    .catch((err) => {
      dispatch({
        type: [constants.CREATE_NEW_SUGGEST_PRODUCT_FAIL],
      });
    });
};

export const updateSuggestProduct = suggestProduct => (dispatch) => {
  dispatch({
    type: [constants.UPDATE_SUGGEST_PRODUCT],
  });
  request(`/api/v1${base_url}/products/${suggestProduct.id}`, 'PATCH', { ...suggestProduct })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.UPDATE_SUGGEST_PRODUCT_SUCCESS],
      });
      dispatch(fetchSuggestProducts());
    })
    .catch((err) => {
      dispatch({
        type: [constants.UPDATE_SUGGEST_PRODUCT_FAIL],
      });
    });
};
