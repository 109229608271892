/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
import React, { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  createHTMLInput,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  handleRef,
  partitionHTMLProps,
  useKeyOnly,
  useValueAndKey,
} from 'utils/lib';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Label from 'components/Label/Label';

import styles from './Input.css';

const cx = classnames.bind(styles);

class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    focus: PropTypes.bool,
    fluid: PropTypes.bool,
    loading: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left']),
    actionPosition: PropTypes.oneOf(['left']),
    inverted: PropTypes.bool,
    transparent: PropTypes.bool,
  };

  static defaultProps = {
    type: 'text',
  };

  focus = () => this.inputRef.focus();

  select = () => this.inputRef.select();

  computeTabIndex = () => {
    const { disabled, tabIndex } = this.props;

    if (!_.isNil(tabIndex)) return tabIndex;
    if (disabled) return -1;
  };

  computeIcon = () => {
    const { loading, icon } = this.props;
    if (!_.isNil(icon)) return icon;
    if (loading) return 'spinner';
  };

  handleChildOverrides = (child, defaultProps) => ({
    ...defaultProps,
    ...child.props,
    ref: (c) => {
      handleRef(child.ref, c);
      this.handleInputRef(c);
    },
  });

  handleChange = (e) => {
    const value = _.get(e, 'target.value');

    _.invoke(this.props, 'onChange', e, { ...this.props, value });
  };

  handleInputRef = c => (this.inputRef = c);

  partitionProps = () => {
    const { disabled, type } = this.props;

    const tabIndex = this.computeTabIndex();
    const unhandled = getUnhandledProps(Input, this.props);
    const [htmlInputProps, rest] = partitionHTMLProps(unhandled);

    return [
      {
        ...htmlInputProps,
        disabled,
        type,
        tabIndex,
        onChange: this.handleChange,
        ref: this.handleInputRef,
      },
      rest,
    ];
  };

  render() {
    const {
      action,
      actionPosition,
      children,
      className,
      disabled,
      error,
      fluid,
      focus,
      icon,
      iconPosition,
      input,
      inverted,
      label,
      labelPosition,
      loading,
      size,
      transparent,
      type,
    } = this.props;

    const iconPositions = iconPosition && useValueAndKey(iconPosition, 'icon').split(' ') || [];
    const actionPositions = actionPosition && useValueAndKey(actionPosition, 'action').split(' ') || [];

    const classes = cx(
      'ui',
      size,
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(error, 'error'),
      useKeyOnly(fluid, 'fluid'),
      useKeyOnly(focus, 'focus'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(loading, 'loading'),
      useKeyOnly(transparent, 'transparent'),
      useKeyOnly(action, 'action'),
      useKeyOnly(icon || loading, 'icon'),
      useValueAndKey(labelPosition, 'labeled') || useKeyOnly(label, 'labeled'),
      'input',
      ...actionPositions,
      ...iconPositions,
      className
    );

    const ElementType = getElementType(Input, this.props);
    const [htmlInputProps, rest] = this.partitionProps();

    if (!isNil(children)) {
      const childElements = _.map(Children.toArray(children), (child) => {
        if (child.type !== 'input') return child;
        return cloneElement(child, this.handleChildOverrides(child, htmlInputProps));
      });

      return (
        <ElementType {...rest} className={classes}>
          {childElements}
        </ElementType>
      );
    }

    const actionElement = Button.create(action, { autoGenerateKey: false });
    const labelElement = Label.create(label, {
      defaultProps: {
        className: cx('label', _.includes(labelPosition, 'corner') && labelPosition),
      },
      autoGenerateKey: false,
    });

    return (
      <ElementType {...rest} className={classes}>
        {actionPosition === 'left' && actionElement}
        {labelPosition !== 'right' && labelElement}
        {createHTMLInput(input || type, { defaultProps: htmlInputProps, autoGenerateKey: false })}
        {Icon.create(this.computeIcon(), { autoGenerateKey: false })}
        {actionPosition !== 'left' && actionElement}
        {labelPosition === 'right' && labelElement}
      </ElementType>
    );
  }
}

Input.create = createShorthandFactory(Input, type => ({ type }));

export default Input;
