import React, { cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';

import keyboardKey from 'keyboard-key';
import {
  AutoControlledComponent as Component,
  eventStack,
  doesNodeContainClick,
  handleRef,
} from 'utils/lib';
import _ from 'lodash';
import PortalInner from './PortalInner';
import Ref from '../Ref/Ref';

class Portal extends Component {
  static autoControlledProps = ['open'];

  static Inner = PortalInner;

  static propTypes = {
    closeOnDocumentClick: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    eventPool: PropTypes.string,
    openOnTriggerClick: PropTypes.bool,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    closeOnDocumentClick: true,
    closeOnEscape: true,
    eventPool: 'default',
    openOnTriggerClick: true,
  };

  componentWillUnmount() {
    clearTimeout(this.mouseEnterTimer);
    clearTimeout(this.mouseLeaveTimer);
  }

  closeWithTimeout = (e, delay) => {
    const eventClone = { ...e };
    return setTimeout(() => this.close(eventClone), delay || 0);
  }

  handlePortalMouseLeave = (e) => {
    const { closeOnPortalMouseLeave, mouseLeaveDelay } = this.props;

    if (!closeOnPortalMouseLeave) return;

    if (e.target !== this.portalNode) return;

    this.mouseLeaveTimer = this.closeWithTimeout(e, mouseLeaveDelay);
  };

  handlePortalMouseEnter = () => {
    const { closeOnPortalMouseLeave } = this.props;

    if (!closeOnPortalMouseLeave) return;

    clearTimeout(this.mouseLeaveTimer);
  };

  handleDocumentClick = (e) => {
    const { closeOnDocumentClick } = this.props;

    if (
      !this.portalNode
      || doesNodeContainClick(this.triggerNode, e)
      || doesNodeContainClick(this.portalNode, e)
    ) {
      return;
    }

    if (closeOnDocumentClick) {
      this.close(e);
    }
  };

  handleEscape = (e) => {
    if (!this.props.closeOnEscape) return;
    if (keyboardKey.getCode(e) !== keyboardKey.Escape) return;

    this.close(e);
  };

  handleMount = (e, { node: target }) => {
    const { eventPool } = this.props;

    this.portaNode = target;

    eventStack.sub('mouseleave', this.handlePortalMouseLeave, { pool: eventPool, target });
    eventStack.sub('mouseenter', this.handlePortalMouseEnter, { pool: eventPool, target });
    eventStack.sub('click', this.handleDocumentClick, { pool: eventPool });
    eventStack.sub('keydown', this.handleEscape, { pool: eventPool });

    _.invoke(this.props, 'onMount', null, this.props);
  };

  handleUnmount = (e, { node: target }) => {
    const { eventPool } = this.props;

    this.portaNode = null;

    eventStack.unsub('mouseleave', this.handlePortalMouseLeave, { pool: eventPool, target });
    eventStack.unsub('mouseenter', this.handlePortalMouseEnter, { pool: eventPool, target });
    eventStack.unsub('click', this.handleDocumentClick, { pool: eventPool });
    eventStack.unsub('keydown', this.handleEscape, { pool: eventPool });

    _.invoke(this.props, 'onUnmount', null, this.props);
  };

  handleTriggerRef = (c) => {
    this.triggerNode = c;
    handleRef(this.props.triggerRef, c);
  };

  handleTriggerBlur = (e, ...rest) => {
    const { trigger, closeOnTriggerBlur } = this.props;

    _.invoke(trigger, 'props.onBlur', e, ...rest);

    const didFocusPortal = _.invoke(this, 'portalNode.contains', e.relatedTarget);

    if (!closeOnTriggerBlur || didFocusPortal) return;

    this.close(e);
  };

  handleTriggerClick = (e, ...rest) => {
    const { trigger, closeOnTriggerClick, openOnTriggerClick } = this.props;
    const { open } = this.state;

    _.invoke(trigger, 'props.onClick', e, ...rest);

    if (open && closeOnTriggerClick) {
      this.close(e);
    } else if (!open && openOnTriggerClick) {
      this.open(e);
    }
  };

  handleTriggerFocus = (e, ...rest) => {
    const { trigger, openOnTriggerFocus } = this.props;

    _.invoke(trigger, 'props.onFocus', e, ...rest);

    if (!openOnTriggerFocus) return;
    this.open(e);
  };

  handleTriggerMouseLeave = (e, ...rest) => {
    clearTimeout(this.mouseEnterTimer);

    const { trigger, closeOnTriggerMouseLeave, mouseLeaveDelay } = this.props;

    _.invoke(trigger, 'props.onMouseLeave', e, ...rest);

    if (!closeOnTriggerMouseLeave) return;

    this.mouseLeaveTimer = this.closeWithTimeout(e, mouseLeaveDelay);
  };

  handleTriggerMouseEnter = (e, ...rest) => {
    clearTimeout(this.mouseLeaveTimer);

    const { trigger, mouseEnterDelay, openOnTriggerMouseEnter } = this.props;

    _.invoke(trigger, 'props.onMouseEnter', e, ...rest);

    if (!openOnTriggerMouseEnter) return;

    this.mouseEnterTimer = this.openWithTimeout(e, mouseEnterDelay);
  };

  close = (e) => {
    const { onClose } = this.props;
    if (onClose) onClose(e, this.props);

    this.trySetState({ open: false });
  };

  open = (e) => {
    const { onOpen } = this.props;
    if (onOpen) onOpen(e, this.props);

    this.trySetState({ open: true });
  };

  openWithTimeout = (e, delay) => {
    const eventClone = { ...e };
    return setTimeout(() => this.open(eventClone), delay || 0);
  };

  render() {
    const { children, mountNode, trigger } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        {open && (
          <PortalInner
            mountNode={mountNode}
            onMount={this.handleMount}
            onUnmount={this.handleUnmount}
          >
            {children}
          </PortalInner>
        )}
        {trigger && (
          <Ref innerRef={this.handleTriggerRef}>
            {cloneElement(trigger, {
              onBlur: this.handleTriggerBlur,
              onClick: this.handleTriggerClick,
              onFocus: this.handleTriggerFocus,
              onMouseLeave: this.handleTriggerMouseLeave,
              onMouseEnter: this.handleTriggerMouseEnter,
            })}
          </Ref>
        )}
      </Fragment>
    );
  }
}

export default Portal;
