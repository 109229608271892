import React, { Component } from 'react';

import Plus from 'images/svg/plus';
import Minus from 'images/svg/minus';

import classnames from 'classnames/bind';
import styles from './OzPacking.module.scss';

const cx = classnames.bind(styles);

class OzPacking extends Component {
  state = {
    lastStep: this.props.itemProduct ? this.props.itemProduct.quantity : this.props.lastStep,
    steps: this.props.steps,
    danger: false,
    disabledPlus: false,
  };

  componentWillReceiveProps(nextProps) {
    const { itemProduct } = this.props;

    if (
      nextProps.itemProduct &&
      itemProduct &&
      itemProduct.quantity != nextProps.itemProduct.quantity
    ) {
      this.setState({
        lastStep: nextProps.itemProduct.quantity,
      });
    }
  }

  onMinus = (lastStep) => {
    const { closeCardBuy, setProduct } = this.props;

    const steps = this.state.steps;
    const indexStep = steps.indexOf(lastStep);

    if (indexStep < steps.length - 1) {
      setProduct(steps[indexStep + 1]);
      this.setState({
        lastStep: steps[indexStep + 1],
        disabledPlus: false,
      });
    } else {
      closeCardBuy();
    }
  };

  onPlus = (lastStep) => {
    const { setProduct } = this.props;

    const steps = this.state.steps;
    const indexStep = steps.indexOf(lastStep);

    if (indexStep !== 0) {
      setProduct(steps[indexStep - 1]);
      this.setState({
        lastStep: steps[indexStep - 1],
        danger: false,
      });
    }

    if (indexStep === 0) {
      this.setState({
        disabledPlus: true,
      });
    }
  };

  render() {
    const { price, packing } = this.props;
    let currentPrice = null;
    let lastStep = this.state.lastStep;

    const steps = this.state.steps;
    const indexStep = steps.indexOf(lastStep);

    let danger = this.state.danger;
    let disabledPlus = this.state.disabledPlus;

    if (lastStep === undefined) {
      lastStep = steps[steps.length - 1];
    }
    currentPrice = price[lastStep];

    if (steps[indexStep] === steps[steps.length - 1] || steps[indexStep] === undefined) {
      danger = true;
    }

    if (steps.length === 1 || indexStep === 0) {
      disabledPlus = true;
    }

    return (
      <div className={cx('content-block')}>
        <div className={cx('counter-measure')}>
          <button className={cx('button-trigger')} onClick={() => this.onMinus(lastStep)}>
            <div className={cx('container-button')}>
              <Minus style={danger ? { fill: 'rgb(253, 161, 133)' } : null} />
            </div>
          </button>
          <div className={cx('measure')}>
            {lastStep}&nbsp;{packing}
          </div>
          <button
            className={disabledPlus ? cx('button-disabled') : cx('button-trigger')}
            onClick={() => this.onPlus(lastStep)}
          >
            <div className={cx('container-button')}>
              <Plus />
            </div>
          </button>
        </div>
        <div className={cx('cost')}>
          $&nbsp;{currentPrice && Math.round(currentPrice) === 0
            ? currentPrice.toFixed(2)
            : Math.round(currentPrice)}
        </div>
      </div>
    );
  }
}

export default OzPacking;
