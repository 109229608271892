import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cart from '../components/Cart';

import classnames from 'classnames/bind';
import styles from './CartWrapper.module.scss';

import {
  fetchCartProducts,
  addToCartProduct,
  setDeliveryAddress,
  setPhoneNumber,
  setOrderNotes,
  removeFromCart,
  applyDiscountCode,
  setDiscountCode,
  setPaymentMethod,
  setLocallyDeliveryAddress,
  fetchCartInfo,
  setLocallyAddressWithZip,
} from 'modules/cart/actions';

import {
  createOrder,
  checkAuthUser,
  closeModal,
  closeModalRecommendation,
  disabledReloadComponent,
  closeModalWithPhotoId,
} from 'modules/orders/actions';

import { fetchStoreInfo, fetchShippingCostInfo } from 'modules/stores/actions';

import { fetchClientTokenForPaymentOnline } from 'modules/payments/actions';

import { changeDeliveryAddress, setAuthPosition } from 'modules/base/actions';

import { getOnlinePaymentsInfo } from 'modules/payments/selectors';

import {
  getCartProducts,
  getTotalPrice,
  getTotalQuantity,
  getItemsProducts,
  getStoreId,
  getTaxPrice,
  getPriceWithTax,
  getStoreType,
  getAddToCartLoader,
  getOrderNotes,
  getPhoneNumber,
  getCartConflict,
  getTriggerCartConflict,
  getIsFetchingCartInfo,
  getCouponType,
  getDiscountCode,
  getPrices,
  getCosts,
  getResultPrice,
  getPaymentMethod,
  getLocallyDeliveryAddress,
  getLocallyStateAddress,
  getItemsDeliveryCost,
  getSumPrice,
  getDiscountPrice,
  getDeliveryPrice,
  getIsSuccessApplyDiscountCode,
} from 'modules/cart/selectors';

import { openModalWindow, saveUserProfile } from 'modules/user/actions';

import { getLogInInfo, getIsFetchingUserInfo, getUserInfo } from 'modules/user/selectors';

import {
  getShowModal,
  getCurrentOrder,
  getIsCreateOrder,
  getModalRecommendation,
  getReloadPaymentOnlineComponent,
  getPhotoId,
  getRequirePhotoId,
} from 'modules/orders/selectors';

import { getStoreInfo, getBufferStoreInfo, getDeliveryCost } from 'modules/stores/selectors';

import { getBrandInformation, getDeliveryAddress, getAuthPosition } from 'modules/base/selectors';

const cx = classnames.bind(styles);

class CartWrapper extends Component {
  render() {
    const component = (
      <Cart
        cart={this.props.cart}
        itemsProducts={this.props.itemsProducts}
        totalPrice={this.props.totalPrice}
        totalQuantity={this.props.totalQuantity}
        storeId={this.props.storeId}
        storeType={this.props.storeType}
        storeInfo={this.props.storeInfo}
        bufferStoreInfo={this.props.bufferStoreInfo}
        showModal={this.props.showModal}
        currentOrder={this.props.currentOrder}
        priceWithTax={this.props.priceWithTax}
        successLogIn={this.props.successLogIn}
        addToCartLoader={this.props.addToCartLoader}
        deliveryAddress={this.props.deliveryAddress}
        orderNotes={this.props.orderNotes}
        phoneNumber={this.props.phoneNumber}
        isCreateOrder={this.props.isCreateOrder}
        cartConflict={this.props.cartConflict}
        triggerCartConflict={this.props.triggerCartConflict}
        fetchCartProducts={this.props.fetchCartProducts}
        addToCartProduct={this.props.addToCartProduct}
        fetchStoreInfo={this.props.fetchStoreInfo}
        removeFromCart={this.props.removeFromCart}
        setDeliveryAddress={this.props.setDeliveryAddress}
        setOrderNotes={this.props.setOrderNotes}
        setPhoneNumber={this.props.setPhoneNumber}
        closeModal={this.props.closeModal}
        closeModalRecommendation={this.props.closeModalRecommendation}
        checkAuthUser={this.props.checkAuthUser}
        createOrder={this.props.createOrder}
        location={this.props.location}
        history={this.props.history}
        modalRecommendation={this.props.modalRecommendation}
        openModal={this.props.openModalWindow}
        applyDiscountCode={this.props.applyDiscountCode}
        couponType={this.props.couponType}
        setDiscountCode={this.props.setDiscountCode}
        discountCode={this.props.discountCode}
        fetchShippingCostInfo={this.props.fetchShippingCostInfo}
        deliveryCost={this.props.deliveryCost}
        prices={this.props.prices}
        costs={this.props.costs}
        resultPrice={this.props.resultPrice}
        setPaymentMethod={this.props.setPaymentMethod}
        paymentMethod={this.props.paymentMethod}
        userInfo={this.props.userInfo}
        fetchClientTokenForPaymentOnline={this.props.fetchClientTokenForPaymentOnline}
        onlinePayment={this.props.onlinePayment}
        changeDeliveryAddress={this.props.changeDeliveryAddress}
        locallyDeliveryAddress={this.props.locallyDeliveryAddress}
        locallyStateDeliveryAddress={this.props.locallyStateDeliveryAddress}
        fetchCartInfo={this.props.fetchCartInfo}
        itemsDeliveryCost={this.props.itemsDeliveryCost}
        sumPrice={this.props.sumPrice}
        taxPrice={this.props.taxPrice}
        discountPrice={this.props.discountPrice}
        deliveryPrice={this.props.deliveryPrice}
        isSuccessApplyDiscountCode={this.props.isSuccessApplyDiscountCode}
        setLocallyAddressWithZip={this.props.setLocallyAddressWithZip}
        brandInformation={this.props.brandInformation}
        reloadPaymentOnlineComponent={this.props.reloadPaymentOnlineComponent}
        disabledReloadComponent={this.props.disabledReloadComponent}
        hasPhotoId={this.props.hasPhotoId}
        saveUserProfile={this.props.saveUserProfile}
        closeModalWithPhotoId={this.props.closeModalWithPhotoId}
        domainPath={this.props.domainPath}
        requirePhotoId={this.props.requirePhotoId}
        isFetchingCartInfo={this.props.isFetchingCartInfo}
        isFetchingUserInfo={this.props.isFetchingUserInfo}
        authPosition={this.props.authPosition}
        setAuthPosition={this.props.setAuthPosition}
      />
    );
    return (
      <div
        style={window.isTablet ? { display: 'block' } : null}
        className={cx('cart-main-wrapper')}
      >
        {component}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cart: getCartProducts(state.cart),
  itemsProducts: getItemsProducts(state.cart),
  totalPrice: getTotalPrice(state.cart),
  totalQuantity: getTotalQuantity(state.cart),
  storeId: getStoreId(state.cart),
  storeType: getStoreType(state.cart),
  storeInfo: getStoreInfo(state.stores),
  bufferStoreInfo: getBufferStoreInfo(state.stores),
  showModal: getShowModal(state.orders),
  currentOrder: getCurrentOrder(state.orders),
  priceWithTax: getPriceWithTax(state.cart),
  successLogIn: getLogInInfo(state.user),
  addToCartLoader: getAddToCartLoader(state.cart),
  deliveryAddress: getDeliveryAddress(state.base),
  orderNotes: getOrderNotes(state.cart),
  phoneNumber: getPhoneNumber(state.cart),
  isCreateOrder: getIsCreateOrder(state.orders),
  cartConflict: getCartConflict(state.cart),
  triggerCartConflict: getTriggerCartConflict(state.cart),
  isFetchingCartInfo: getIsFetchingCartInfo(state.cart),
  isFetchingUserInfo: getIsFetchingUserInfo(state.user),
  modalRecommendation: getModalRecommendation(state.orders),
  couponType: getCouponType(state.cart),
  discountCode: getDiscountCode(state.cart),
  deliveryCost: getDeliveryCost(state.stores),
  prices: getPrices(state.cart),
  costs: getCosts(state.cart),
  resultPrice: getResultPrice(state.cart),
  paymentMethod: getPaymentMethod(state.cart),
  userInfo: getUserInfo(state.user),
  onlinePayment: getOnlinePaymentsInfo(state.payments),
  locallyDeliveryAddress: getLocallyDeliveryAddress(state.cart),
  locallyStateDeliveryAddress: getLocallyStateAddress(state.cart),
  itemsDeliveryCost: getItemsDeliveryCost(state.cart),
  sumPrice: getSumPrice(state.cart),
  taxPrice: getTaxPrice(state.cart),
  discountPrice: getDiscountPrice(state.cart),
  deliveryPrice: getDeliveryPrice(state.cart),
  isSuccessApplyDiscountCode: getIsSuccessApplyDiscountCode(state.cart),
  brandInformation: getBrandInformation(state.base),
  reloadPaymentOnlineComponent: getReloadPaymentOnlineComponent(state.orders),
  hasPhotoId: getPhotoId(state.orders),
  requirePhotoId: getRequirePhotoId(state.orders),
  authPosition: getAuthPosition(state.base),
});

export default connect(
  mapStateToProps,
  {
    fetchCartProducts,
    addToCartProduct,
    fetchStoreInfo,
    removeFromCart,
    setDeliveryAddress,
    setOrderNotes,
    setPhoneNumber,
    closeModal,
    closeModalRecommendation,
    checkAuthUser,
    createOrder,
    openModalWindow,
    applyDiscountCode,
    setDiscountCode,
    fetchShippingCostInfo,
    setPaymentMethod,
    fetchClientTokenForPaymentOnline,
    changeDeliveryAddress,
    fetchCartInfo,
    setLocallyAddressWithZip,
    disabledReloadComponent,
    saveUserProfile,
    closeModalWithPhotoId,
    setAuthPosition
  }
)(CartWrapper);
