import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import { createShorthandFactory, getElementType, getUnhandledProps, useKeyOnly } from 'utils/lib';
import Icon from 'components/Icons/Icon';
import styles from './Accordion.css';

const cx = classnames.bind(styles);

class AccordionTitle extends Component {
  handleClick = e => _.invoke(this.props, 'onClick', e, this.props);

  render() {
    const { active, children, className, content } = this.props;

    const classes = cx(useKeyOnly(active, 'active'), 'title', className);
    const rest = getUnhandledProps(AccordionTitle, this.props);
    const ElementType = getElementType(AccordionTitle, this.props);
    if (_.isNil(content)) {
      return (
        <ElementType {...rest} className={classes} onClick={this.handleClick}>
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType {...rest} className={classes} onClick={this.handleClick}>
        {content}
      </ElementType>
    );
  }
}

AccordionTitle.create = createShorthandFactory(AccordionTitle, content => ({ content }));

export default AccordionTitle;
