import React, { Component } from 'react';
import classnames from 'classnames/bind';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { withGoogleMap, GoogleMap, Marker, Polygon, InfoWindow } from 'react-google-maps';

import Segment from 'components/Segment/Segment';
import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import notification from 'components/Notification';

import styles from './ModalBody.module.scss';

const cx = classnames.bind(styles);

const generateQuery = (name, results, isShort = true) => {
  const res = results.address_components.find(value => value.types.indexOf(name) > -1);

  if (!res) return undefined;
  return isShort ? res.short_name : res.long_name;
};

const MapWithAMarker = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={props.zoom}
    defaultCenter={props.position}
    center={props.position}
    ref={props.onMapLoad}
  >
    <Marker position={props.position} onClick={props.onToggleOpen}>
      {props.isOpen && (
        <InfoWindow onCloseClick={props.onToggleOpen}>
          <div style={{ opacity: 0.75, padding: '12px' }}>
            <div>
              <span style={{ fontSize: '16px', fontColor: '#08233B' }}>Min order price:</span>
              <span style={{ fontWeight: 700 }}>$23.00</span>
            </div>
            <div>
              <span style={{ fontSize: '16px', fontColor: '#08233B' }}>Shipping cost:</span>
              <span style={{ fontWeight: 700 }}>$14.00</span>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
    {props.paths.length > 0 && (
      <Polygon
        paths={props.paths}
        options={{
          fillColor: '#4ac6b4',
          strokeColor: '#4ac6b4',
        }}
      />
    )}
    {props.polygons && props.polygons.length > 0 && props.polygons.map(v => v)}
  </GoogleMap>
));

class ModalBody extends Component {
  state = {
    address: '',
    zoom: 8,

    position: {
      lat: 36.778259,
      lng: -119.417931,
    },
    polygons: [],
    paths: [],
    fields: {
      min_order_price: {
        value: '',
        error: false,
      },
      delivery_cost: {
        value: '',
        error: false,
      },
    },
    selectAddress: '',
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  onChange = (e, field) => {
    const { fields } = this.state;

    fields[field.name].value = field.value;
    fields[field.name].error = false;

    this.setState({
      fields,
    });
  }

  handleSelect = (address) => {
    const { fetchDeliveryLocationData } = this.props;
    this.setState({
      address,
      selectAddress: address,
      isSearchingGooglePlace: true,
    });
    geocodeByAddress(address)
      .then((results) => {
        const formattedAddress = results[0].formatted_address;
        const zip = generateQuery('postal_code', results[0]);

        const locality = generateQuery('sublocality_level_1', results[0], false);
        const city = generateQuery('locality', results[0], false);
        const county = generateQuery('administrative_area_level_2', results[0]);
        const state = generateQuery('administrative_area_level_1', results[0]);
        const country = generateQuery('country', results[0]);
        const normalizedAddress = [locality, city, county, state, country].join(',').replace(/^,+/, ',');

        getLatLng(results[0])
          .then((latlng) => {
            fetch(
              `https://nominatim.openstreetmap.org/search.php?q=${address}&polygon_geojson=1&format=json`
            )
              .then(res => res.json())
              .then((json) => {
                const paths = [];
                if (json[0].geojson.type === 'Polygon') {
                  const coordinates = json[0].geojson.coordinates.length === 1
                    ? json[0].geojson.coordinates[0]
                    : json[0].geojson.coordinates[0][0];

                  coordinates.forEach((v) => {
                    paths.push({
                      lat: v[1],
                      lng: v[0],
                    });
                  });
                }
                this.setState({
                  paths,
                  position: latlng,
                  normalizedAddress,
                  formattedAddress,
                  isSearchingGooglePlace: false,
                });
              });
          });
      });
  };

  addShippingAddress = () => {
    const { fields, formattedAddress, normalizedAddress, paths, position } = this.state;
    const { createDeliveryLocation, storeInformation, listWithDeliveryLocations, handleClose } = this.props;
    let hasError = false;

    Object.keys(fields).forEach((v) => {
      if (fields[v].value.length === 0) {
        fields[v].error = true;
        hasError = true;
      }
    });

    if (hasError) {
      this.setState({
        fields,
      });
      return;
    }

    createDeliveryLocation(
      fields.min_order_price.value,
      fields.delivery_cost.value,
      formattedAddress, normalizedAddress,
      storeInformation.id,
      paths,
      position,
      listWithDeliveryLocations
    ).then((res) => {
      Object.keys(fields).forEach((v) => {
        fields[v].error = false;
        fields[v].value = '';
      });
      this.setState({
        fields,
      }, () => {
        handleClose('success_save', position);
      });
    })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'There was a problem saving your delivery location data',
        });
      });
  }

  render() {
    const { handleClose } = this.props;
    const { selectAddress, isSearchingGooglePlace, fields } = this.state;

    return (
      <Segment basic loading={isSearchingGooglePlace} style={{ padding: '0em' }}>
        <Form unstackable>
          <FormField>
            <label>Min order price</label>
            <Input
              name="min_order_price"
              size="small"
              error={fields.min_order_price.error}
              fluid
              placeholder="price..."
              icon="dollar"
              onChange={this.onChange}
              defaultValue={fields.min_order_price.value}
              type="number"
            />
          </FormField>
          <FormField>
            <label>Shipping cost</label>
            <Input
              name="delivery_cost"
              size="small"
              error={fields.delivery_cost.error}
              fluid
              placeholder="price..."
              icon="dollar"
              onChange={this.onChange}
              defaultValue={fields.delivery_cost.value}
              type="number"
            />
          </FormField>
          <FormField>
            <label style={{ width: '25%' }}>Delivery address</label>
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              highlightFirstSuggestion
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <Input
                    {...getInputProps({ placeholder: 'address...' })}
                    fluid
                    icon="map marker alternate"
                    loading={loading}
                  />
                  {suggestions.length > 0 && (
                    <div className={cx('autocomplete-container')}>
                      {suggestions.map((suggestion) => {
                        const className = cx('suggestion-item', {
                          'suggestion-item--active': suggestion.active,
                        });
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </FormField>
          <Container style={{ marginTop: '1.5em' }}>
            {/* <MapWithAMarker
              zoom={this.state.zoom}
              position={this.state.position}
              paths={this.state.paths}
              containerElement={<div style={{ height: '400px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
            /> */}
            {
              selectAddress.length > 0
              && (
                <iframe
                  width="100%"
                  height="600"
                  src={`https://www.google.com/maps/embed/v1/place?q=${selectAddress}&key=AIzaSyB4u6hv7R5EmduUjaFH8YjLf9sLfyvZzEc`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              )
            }
          </Container>
          <Container style={{ marginTop: '1.5em', textAlign: 'right' }}>
            <Button type="button" primary size="small" onClick={this.addShippingAddress}>
              Add
            </Button>
            <Button type="button" secondary size="small" onClick={handleClose}>
              Cancel
            </Button>
          </Container>
        </Form>
      </Segment>
    );
  }
}

export default ModalBody;
