import React, { Component } from 'react';
import { withTheme } from '@callstack/react-theme-provider';
import queryString from 'query-string';
import scroll from 'scroll';
import scrollDoc from 'scroll-doc';
import ease from 'ease-component';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import Button from 'components/Button/Button';
import Header from 'components/Typography/Header/Header';
import HeaderContent from 'components/Typography/Header/HeaderContent';
import HeaderSubheader from 'components/Typography/Header/HeaderSubheader';
import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import Icon from 'components/Icons/Icon';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalActions from 'components/ModalWindow/ModalActions';
import ModalPhotoId from 'components/ModalWindow/ModalBodies/ModalPhotoId';
import ImageUpload from 'components/ImageUpload';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Divider from 'components/Divider';
import Segment from 'components/Segment/Segment';
import Loader from 'components/Loaders/Loader';
import Cross from 'components/Icons/Cross';

import ProductInfo from 'components/ProductInfo';
// import ModalWindow from 'components/ModalWindow';
import { saveLocalStorage, getLocalStorage } from 'services';
import notification from 'components/Notification';
import Form from 'components/Form/Form';
import Spinner from 'components/Loaders/Spin';
import debounce from 'services/debounce';
import classnames from 'classnames/bind';
import DeliveryAddress from './components/DeliveryAddress';
import CellPhone from './components/CellPhone';
import PaymentMethod from './components/PaymentMethod';
import DeliveryNotes from './components/DeliveryNotes';
import StoreInfo from './components/StoreInfo';
import DiscountCode from './components/DiscountCode';
import Prices from './components/Prices';

import ModalOrderContent from './components/ModalContent';
import ModalRedirectContent from './components/ModalRedirectContent';
import ModalRecommendationContent from './components/ModalRecommendationContent';
import PaymentOnline from './components/PaymentMethod/PaymentOnline';
import ModalGenerateOrderContent from './components/ModalGenerateOrderContent';



import styles from './Cart.module.scss';

const cx = classnames.bind(styles);

const secondPrice = {
  fontSize: '20px',
  paddingLeft: '14px'
}

const scrollElement = scrollDoc();

class Cart extends Component {
  selectSecondAddress = true;

  state = {
    paymentMethod: 'cash',
    showModalRedirect: false,
    showModalGenerateOrder: false,
    cardDetails: null,
    nonce: null,
    checkFieldsOnError: {
      phoneNumber: false,
      streetNameForInputCart: false,
      addressWithZipForInputCart: false,
    },
  };

  async componentDidMount() {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }

    const {
      fetchCartProducts,
      successLogIn,
      location,
      history,
      cartConflict,
      triggerCartConflict,
      itemsProducts,
      createOrder,
      applyDiscountCode,
      storeInfo,
      fetchShippingCostInfo,
      paymentMethod,
      setLocallyDeliveryAddress,
      storeId,
      bufferStoreInfo,
      fetchStoreInfo,
      deliveryAddress,
      authPosition,
      discountCode
    } = this.props;
    const loginWithSidebar = authPosition.type === 'sidebar';

    await fetchCartProducts();
    if (discountCode && discountCode.length > 0) {
      await applyDiscountCode(discountCode, false);
    }

    if (storeId) {
      const data = await fetchStoreInfo(storeId, bufferStoreInfo, paymentMethod || true);

      await fetchShippingCostInfo(data, deliveryAddress.data.addressForQuery);
      if (
        queryString.parse(location.search).from_getheally
        && Object.keys(itemsProducts).length > 0
        && !triggerCartConflict
        && !loginWithSidebar
        && paymentMethod !== 'online_payment'
      ) {
        this.onGenerateOrder();
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { itemsProducts, deliveryPrice, disabledReloadComponent } = this.props;

    if (
      deliveryPrice !== nextProps.deliveryPrice
      && nextProps.deliveryPrice > 0
      && Object.keys(itemsProducts).length > 0
      && deliveryPrice !== 0
    ) {
      notification.info({
        message: 'Information',
        description: `Shipping cost for the location you set is $${nextProps.deliveryPrice}`,
      });
    }
    if (nextProps.reloadPaymentOnlineComponent) {
      setTimeout(() => {
        disabledReloadComponent();
      }, 300);
    }
  }

  checkFields = (data) => {
    Object.keys(data).forEach((v) => {
      if (data[v]) return true;
    });
  }

  removeClassFromBody = () => {
    if (document.body && document.body.className && document.body.className.replace) {
      document.body.className = document.body.className.replace(cx('dimmed'), '');
    }
  }

  onGenerateOrder = () => {
    const {
      createOrder,
      storeType,
      storeInfo,
      successLogIn,
      deliveryAddress,
      phoneNumber,
      orderNotes,
      openModal,
      paymentMethod,
      locallyDeliveryAddress,
      locallyStateDeliveryAddress,
      location,
      setAuthPosition
    } = this.props;
    console.log(paymentMethod);
    if (!queryString.parse(location.search).from_getheally && (!paymentMethod || paymentMethod.length === 0)) {
      notification.warning({
        message: 'Warning',
        description: 'Please choose your payment method',
        placement: 'bottom',
      });
      return;
    }

    if (
      (storeType === 'delivery' && !deliveryAddress)
      || deliveryAddress.data.streetNameForInputCart === ''
      || deliveryAddress.data.addressWithZipForInputCart === ''
    ) {
      notification.warning({
        message: 'Warning',
        description: 'Delivery address should not be empty!',
        placement: 'bottom',
      });
      return;
    }

    if (!this.selectSecondAddress) {
      notification.warning({
        message: 'Warning',
        description: 'City, State or Zip are invalid - please select from dropdown!',
        placement: 'bottom',
      })
      return;
    }

    if (phoneNumber === '' || phoneNumber.length === 0) {
      notification.warning({
        message: 'Warning',
        description: 'Phone number should not be empty!',
        placement: 'bottom',
      });
      return;
    }

    if (paymentMethod === 'online_payment') {
      if (this.instance && this.instance.isPaymentMethodRequestable()) {
        this.instance
          .requestPaymentMethod()
          .then((res) => {
            if (res && res.nonce) {
              this.setState({
                showModalGenerateOrder: true,
                nonce: res.nonce,
                cardDetails: res.details,
              }, () => {
                if (window.isMobile) {
                  document.body.classList.add(cx('dimmed'));
                }
              });
            }
          })
          .catch((err) => {
            if (err.name === 'DropinError') {
              notification.warning({
                message: 'Warning',
                description: err.message,
              });
            }
          });
      } else {
        notification.warning({
          message: 'Warning',
          description: 'Your credit card is invalid',
        });
      }
      return;
    }

    // saveLocalStorage('orderNotes', orderNotes);

    if (!successLogIn && !queryString.parse(location.search).from_getheally) {
      openModal('heally');

      const type = '';
      setAuthPosition(type);
      // saveLocalStorage('loginWith', '');
      return;
    }

    this.setState({
      showModalGenerateOrder: true,
    }, () => {
      if (window.isMobile) {
        document.body.classList.add(cx('dimmed'));
      }

      if (window.fbq) {
        window.fbq('track', 'InitiateCheckout');
      }
    });
  };

  createOrder = () => {
    const {
      createOrder,
      orderNotes,
      phoneNumber,
      paymentMethod,
      brandInformation,
      deliveryAddress,
      discountCode
    } = this.props;
    const { nonce } = this.state;

    const brandShortName = brandInformation && brandInformation.brand ? brandInformation.brand.short_name : null;

    this.setState(
      {
        showModalGenerateOrder: false,
      },
      () => {
        this.removeClassFromBody();
        const outer = document.getElementById('cart-container');
        scroll.top(scrollElement, 300, { duration: 50 }, { ease: ease.inOutBounce });
        createOrder(
          paymentMethod,
          deliveryAddress,
          orderNotes,
          phoneNumber,
          false,
          discountCode,
          false,
          null,
          nonce,
          brandShortName,
        );
      }
    );
  };

  onGenerateOrderWithRecommendation = () => {
    const {
      createOrder,
      phoneNumber,
      orderNotes,
      storeInfo,
      locallyDeliveryAddress,
      locallyStateDeliveryAddress,
      paymentMethod,
      brandInformation,
      deliveryAddress,
      discountCode
    } = this.props;
    const { nonce } = this.state;

    const brandShortName = brandInformation && brandInformation.brand ? brandInformation.brand.short_name : null;

    createOrder(
      paymentMethod,
      deliveryAddress,
      orderNotes,
      phoneNumber,
      true,
      discountCode,
      false,
      null,
      nonce,
      brandShortName,
    );
  };

  onKeepShopping = () => {
    const { history, storeId, storeType, deliveryAddress, domainPath } = this.props;

    history.push(`${domainPath}products?store=${storeId}&order_type=${storeType}&location=${deliveryAddress.data.enteringAddress}`);
  };

  changeDeliveryAddress = (locallyAddress, field, event) => {
    const { changeDeliveryAddress } = this.props;
    if (field === 'addressWithZipForInputCart') {
      this.selectSecondAddress = false;
    }
    changeDeliveryAddress(locallyAddress, field, event);
  };

  onChangeDeliveryAddress = address => {
    const { setDeliveryAddress } = this.props;
    setDeliveryAddress(address);
  };

  onChangePhoneNumber = (phoneNumber) => {
    const { setPhoneNumber } = this.props;
    if (phoneNumber.length <= 14) {
      setPhoneNumber(phoneNumber);
    }
  };

  onChangeOrderNotes = (orderNotes) => {
    const { setOrderNotes } = this.props;
    setOrderNotes(orderNotes);
  };

  // saveDiscoountCode = debounce((discountCode) => {
  //   saveLocalStorage('coupon', discountCode);
  // }, 250);

  setDiscountCode = (discountCode) => {
    const { setDiscountCode } = this.props;
    setDiscountCode(discountCode);

    // this.saveDiscoountCode(discountCode);
  };

  onCancel = () => {
    this.setState({
      showModalRedirect: false,
    });
  };

  onRedirect = () => {
    const { checkAuthUser } = this.props;
    checkAuthUser(true);
    window.location = '/users/auth/doorkeeper';
  };

  setProduct = (product, quantity) => {
    const { addToCartProduct, discountCode } = this.props;
    let isDiscountCode = null;

    if (discountCode && discountCode.length > 0) {
      isDiscountCode = discountCode;
    }
    if (quantity === 0) {
      this.removeFromCart(product, quantity);
    } else {
      addToCartProduct(product, quantity, false, isDiscountCode);
    }
  };

  changePaymentMethod = paymentMethod => {
    const { successLogIn, openModal, location, history, setPaymentMethod } = this.props;

    if (paymentMethod === 'online_payment' && !successLogIn) {
      if (!queryString.parse(location.search).pay_online) {
        history.replace(`${location.pathname}${location.search}?pay_online=true`);
      }
      openModal('heally');
      //saveLocalStorage('loginWith', '');
      return;
    }
    // saveLocalStorage('paymentMethod', paymentMethod);
    setPaymentMethod(paymentMethod);
  };

  removeFromCart = (product, quantity) => {
    const { addToCartProduct, removeFromCart, cart, discountCode, fetchCartInfo } = this.props;
    let reset = false;
    let isDiscountCode = null;

    if (discountCode && discountCode.length > 0) {
      isDiscountCode = discountCode;
    }

    if (cart.itemsProducts && Object.keys(cart.itemsProducts).length === 1) {
      reset = true;
    }

    removeFromCart(product).then((res) => {
      if (res === 'OK') {
        addToCartProduct(product, 0, reset, isDiscountCode).then(data => {
          fetchCartInfo(false);
        });
      }
    });
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  closeModalRecommendation = () => {
    const { closeModalRecommendation } = this.props;

    closeModalRecommendation();
  };

  onCloseModalGenerateOrder = () => {
    this.setState({
      showModalGenerateOrder: false,
    }, () => {
      this.removeClassFromBody();
    });
  };

  goToListOrder = () => {
    const { history, domainPath } = this.props;
    history.push(`${domainPath}user_orders`);
    this.closeModal();
  };

  goToHeally = () => {
    window.location = 'https://getheally.com';
  };

  goToProductInforamtion = (id) => {
    const { location, storeId, storeType, history, deliveryAddress, domainPath } = this.props;

    history.push(`${domainPath}products?store=${storeId}&order_type=${storeType}&product=${id}&location=${deliveryAddress.data.enteringAddress}`);
  };

  applyDiscountCode = (code) => {
    const { applyDiscountCode, } = this.props;
    if (code.trim().length === 0) {
      notification.warning({
        message: 'Warning',
        description: 'Discount code not be empty!',
      });
    } else if (!/^([a-zA-Z0-9 _-]+)$/.test(code)) {
      notification.warning({
        message: 'Warning',
        description: 'Only digits and letters are allowed as the coupon name',
      });
    } else {
      // saveLocalStorage('coupon', code).then(() => {
      this.coupon = code;
      applyDiscountCode(code, true);
      // });
    }
  };

  setInstancePayment = instance => {
    this.instance = instance;
  };

  handleSelectAddress = (address, field, event) => {
    const { storeInfo, changeDeliveryAddress } = this.props;

    if (!this.selectSecondAddress) {
      this.selectSecondAddress = true;
    }

    changeDeliveryAddress(address, field, event, storeInfo);
  };

  saveToLocalStorage = (field, value) => {
    saveLocalStorage(field, value);
  };

  onFocusField = (name) => {
    const { checkFieldsOnError } = this.state;

    checkFieldsOnError[name] = false;

    this.setState({
      checkFieldsOnError,
    });
  }

  handleFocusDeliveryAddress = () => {
    const { setLocallyAddressWithZip } = this.props;
    const deliveryAddress = getLocalStorage('deliveryAddress');

    // setLocallyAddressWithZip(deliveryAddress);
  }

  onError = (message) => {
    notification.warning({
      message: 'Warning',
      description: message,
    });
  };

  loadPassportImage = (picture, imagePreview) => {
    this.setState({
      passportData: picture,
      passportPreviewImage: imagePreview,
    });
  }

  cancelLoadPassportImage = () => {
    this.setState({
      passportData: null,
      passportPreviewImage: null,
    });
  }

  saveUserPassprotImage = async (onlyPhotoId = false, isCreateMethod) => {
    const { passportData } = this.state;
    const { saveUserProfile } = this.props;

    if (passportData) {
      const formData = new FormData();

      formData.append(['user[id_photo]'], passportData);

      await saveUserProfile(formData, onlyPhotoId);
      if (isCreateMethod) {
        this.createOrder();
      }
    } else {
      this.onError('The image could not be loaded.');
    }
  }

  renderProductInfo = (value, key) => {
    const { itemsProducts } = this.props;

    let itemProduct = null;

    if (itemsProducts && itemsProducts[value.id]) {
      itemProduct = Object.assign(itemsProducts[value.id]);
    }
    return (
      <ProductInfo
        key={value.id}
        product={value}
        itemProduct={itemProduct}
        setProduct={this.setProduct}
        removeFromCart={this.removeFromCart}
        onClick={this.goToProductInforamtion}
      />
    );
  };

  render() {
    const {
      cart,
      //totalPrice,
      storeInfo,
      storeId,
      showModal,
      currentOrder,
      storeType,
      bufferStoreInfo,
      //taxPrice,
      priceWithTax,
      fetchStoreInfo,
      addToCartLoader,
      deliveryAddress,
      phoneNumber,
      isCreateOrder,
      history,
      modalRecommendation,
      orderNotes,
      couponType,
      discountCode,
      theme,
      deliveryCost,
      prices,
      costs,
      resultPrice,
      successLogIn,
      paymentMethod,
      userInfo,
      fetchClientTokenForPaymentOnline,
      onlinePayment,
      locallyDeliveryAddress,
      locallyStateDeliveryAddress,
      itemsDeliveryCost,
      sumPrice,
      taxPrice,
      discountPrice,
      deliveryPrice,
      isSuccessApplyDiscountCode,
      setLocallyAddressWithZip,
      reloadPaymentOnlineComponent,
      hasPhotoId,
      saveUserProfile,
      closeModalWithPhotoId,
      requirePhotoId,
      isFetchingCartInfo,
      isFetchingUserInfo
    } = this.props;

    if (cart.fetchCartProductsError) {
      return (
        <div className={cx('event-container')}>
          <div className={cx('text-message')}>Sorry, something wrong ;(</div>
        </div>
      );
    }

    if (isFetchingCartInfo || isFetchingUserInfo) {
      return (
        <div className={cx('event-container')}>
          <Loader active inline="center" />
        </div>
      )
    }

    const totalDiscountCost = discountPrice && discountPrice > 0 ? sumPrice - discountPrice : 0;
    const totalTaxCost = ((sumPrice - totalDiscountCost) * parseFloat(taxPrice)) / 100;
    const totalPrice = sumPrice - totalDiscountCost + (deliveryPrice + itemsDeliveryCost) + totalTaxCost;
    const totalShippingCost = deliveryPrice + itemsDeliveryCost;

    const displayedDiscountCost = totalDiscountCost && parseFloat(totalDiscountCost.toFixed(2));
    const displayedTaxCost = totalTaxCost && parseFloat(totalTaxCost.toFixed(2));
    const displayedTotalPrice = totalPrice && parseFloat(totalPrice.toFixed(2));
    const displayedShippingCost = totalShippingCost && parseFloat(totalShippingCost.toFixed(2));

    const { checkFieldsOnError, passportData } = this.state;

    if (requirePhotoId) {
      return (
        <Modal
          open={requirePhotoId}
          // closeIcon={<Icon name="close" link onClick={closeModalWithPhotoId} />}
          // onClose={closeModalWithPhotoId}
          size="tiny"
        >
          <ModalHeader>
            <Icon name="file alternate outline" />
            Load document
          </ModalHeader>
          <ModalContent>
            <p className={cx('photo-info')}>Your order is place however due to regulation of Cannabis/CBD sales the seller requires that you upload your federal or state id to prove you are over the age of 21 or over the age of 18 and are a medical patient</p>
            {passportData && <p className={cx('photo-info')} style={{ color: '#5595b0' }}>{passportData.name}</p>}
          </ModalContent>
          <ModalActions style={{ textAlign: 'center' }}>
            {
              passportData
                ? (
                  <>
                    <Button secondary type="button" onClick={this.cancelLoadPassportImage}>
                      <Icon name="cancel" />
                      Cancel
                        </Button>
                    <Button primary type="button" onClick={() => this.saveUserPassprotImage(true)}>
                      <Icon name="upload" />
                      Load
                    </Button>
                  </>
                )
                : (
                  <Button primary type="button" style={{ position: 'relative' }}>
                    <Icon name="file image outline" />
                    Take photo or choose file
                    <ImageUpload imagePreview={this.loadPassportImage} handleError={this.onError} />
                  </Button>
                )
            }
          </ModalActions>
        </Modal>
      )
    }

    if (showModal) {
      return (
        <>
          <Modal
            open={showModal}
            size="tiny"
            closeIcon={<Cross onClick={this.closeModal} />}
            onClose={this.closeModal}
            runConfetti
          >
            <ModalHeader>Order information</ModalHeader>
            <ModalContent>
              <ModalOrderContent order={currentOrder} onClick={this.goToListOrder} />
            </ModalContent>
          </Modal>
        </>
      );
    }

    if (cart.cartProducts && cart.cartProducts.length === 0 && !cart.isFetchingCartProducts) {
      return (
        <div className={cx('event-container')}>
          <div className={cx('event-block')}>
            <Header as="h2" icon>
              <Icon name="cart" circular size="large" />
              <HeaderContent>Unfortunately, your cart is empty</HeaderContent>
              <HeaderSubheader>Please add shomething in your cart</HeaderSubheader>
            </Header>
            <Button secondary size="medium" onClick={storeId && storeType ? this.onKeepShopping : () => history.goBack()}>keep shopping</Button>
          </div>
        </div>
      );
    }

    return (
      <Segment loading={cart.isFetchingCartProducts} basic id="cart-container">
        <div className={cx('wrapper')}>
          <div className={cx('container')}>
            <Grid>
              <GridRow className={cx('reversed')}>
                <GridColumn computer={10} tablet={10} verticalAlign="middle" mobile={16}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 className={cx('main-title')}>1. Check the order details:</h1>
                    <StoreInfo
                      storeInfo={storeInfo}
                      storeId={storeId}
                      fetchStoreInfo={fetchStoreInfo}
                      bufferStoreInfo={bufferStoreInfo}
                    />
                  </div>
                </GridColumn>
                <GridColumn computer={6} tablet={6} textAlign="right" verticalAlign="middle" mobile={16} className={cx('buttons-wrapper')}>
                  <Button type="button" onClick={this.onKeepShopping} color="teal" size="medium">
                    keep shopping
                  </Button>
                </GridColumn>
              </GridRow>
              {cart.cartProducts && cart.cartProducts.map(this.renderProductInfo)}
              <Divider className={cx('delimeter')} />
              <GridRow className={cx('reversed')}>
                <GridColumn computer={9} tablet={9} verticalAlign="middle" mobile={16}>
                  <DiscountCode
                    applyDiscountCode={this.applyDiscountCode}
                    discountCode={discountCode}
                    setDiscountCode={this.setDiscountCode}
                    isSuccessApplyDiscountCode={isSuccessApplyDiscountCode}
                  />
                </GridColumn>
                <GridColumn computer={6} tablet={7} textAlign="right" verticalAlign="middle" mobile={16}>
                  {cart.cartProducts
                    && cart.cartProducts.length > 0 && (
                      <div>
                        <Prices name="tax:" price={displayedTaxCost} customStyle={secondPrice} />
                        {displayedDiscountCost && displayedDiscountCost > 0 ? (
                          <Prices
                            name="discount:"
                            price={displayedDiscountCost}
                            customStyle={secondPrice}
                          />
                        ) : null}
                        {displayedShippingCost && displayedShippingCost > 0 ? (
                          <Prices
                            name="shipping cost:"
                            price={displayedShippingCost}
                            customStyle={secondPrice}
                          />
                        ) : null}
                        <Prices name="total:" price={displayedTotalPrice} />
                      </div>
                    )}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  <PaymentMethod
                    paymentMethod={paymentMethod}
                    changePaymentMethod={this.changePaymentMethod}
                    bufferStoreInfo={bufferStoreInfo}
                    storeId={storeId}
                    storeInfo={storeInfo}
                    numberPosition={storeType === 'delivery' ? 3 : 2}
                    successLogIn={successLogIn}
                  />
                </GridColumn>
              </GridRow>
              {paymentMethod === 'online_payment' && successLogIn && !reloadPaymentOnlineComponent && (
                <GridRow>
                  <GridColumn width={16}>
                    <div style={{ marginBottom: '2em' }}>
                      <PaymentOnline
                        onlinePayment={onlinePayment}
                        userInfo={userInfo}
                        fetchClientTokenForPaymentOnline={fetchClientTokenForPaymentOnline}
                        setInstancePayment={this.setInstancePayment}
                      />
                    </div>
                  </GridColumn>
                </GridRow>
              )
              }
              {storeType === 'delivery' ? (
                <GridRow>
                  <GridColumn width={16}>
                    <DeliveryAddress
                      deliveryAddress={deliveryAddress}
                      onChange={this.onChangeDeliveryAddress}
                      handleSelectAddress={this.handleSelectAddress}
                      numberPosition={storeType === 'delivery' ? 4 : 3}
                      changeDeliveryAddress={this.changeDeliveryAddress}
                      setLocallyAddressWithZip={setLocallyAddressWithZip}
                      saveToLocalStorage={this.saveToLocalStorage}
                      onFocusField={this.onFocusField}
                      errorAddress={checkFieldsOnError.locallyDeliveryAddress}
                      errorState={checkFieldsOnError.locallyStateDeliveryAddress}
                      handleFocus={this.handleFocusDeliveryAddress}
                    />
                  </GridColumn>
                </GridRow>
              ) : null}
              <GridRow>
                <GridColumn width={16}>
                  <CellPhone
                    paymentMethod={paymentMethod}
                    setPhoneNumber={this.onChangePhoneNumber}
                    phoneNumber={phoneNumber}
                    numberPosition={storeType === 'delivery' ? 5 : 4}
                    saveToLocalStorage={this.saveToLocalStorage}
                    onFocusField={this.onFocusField}
                    error={checkFieldsOnError.phoneNumber}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  <DeliveryNotes
                    value={orderNotes}
                    onChange={this.onChangeOrderNotes}
                    numberPosition={storeType === 'delivery' ? 6 : 5}
                    saveToLocalStorage={this.saveToLocalStorage}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16} textAlign="right" className={cx('button-group')}>
                  <Button className={cx('cart-button-custom')} type="button" style={{ backgroundColor: 'white' }} onClick={this.onKeepShopping} secondary size="medium">
                    keep shopping
                  </Button>
                  <Button
                    onClick={this.onGenerateOrder}
                    color="teal"
                    size="medium"
                    type="button"
                  >
                    place your order
                  </Button>
                </GridColumn>
              </GridRow>
            </Grid>
          </div>
          {this.state.showModalRedirect ? (
            <Modal
              size="mini"
              open={this.state.showModalRedirect}
              onClose={this.onCancel}
              closeIcon={<Cross onClick={this.onCancel} />}

            >
              <ModalContent>
                <ModalRedirectContent order={currentOrder} onRedirect={this.onRedirect} />
              </ModalContent>
            </Modal>
          ) : null}
          {this.state.showModalGenerateOrder ? (
            <Modal
              open={this.state.showModalGenerateOrder}
              size={window.isMobile ? 'fullscreen' : 'tiny'}
              closeIcon={<Cross onClick={this.onCloseModalGenerateOrder} />}
              dimmerOff={window.isMobile}
              style={window.isMobile ? { margin: 0, borderRadius: 0 } : null}
              scrolling={!window.isMobile}
              onClose={this.onCloseModalGenerateOrder}
            >
              <ModalContent className={cx('custom-content')} style={window.isMobile ? { overflowY: 'auto', height: '100%' } : null}>
                <ModalGenerateOrderContent
                  storeInformation={storeInfo}
                  totalPrice={displayedTotalPrice}
                  payment={paymentMethod}
                  products={cart.cartProducts}
                  phoneNumber={phoneNumber}
                  createOrder={this.createOrder}
                  cardDetails={this.state.cardDetails}
                  deliveryAddress={deliveryAddress}
                />
              </ModalContent>
            </Modal>
          ) : null}
          {modalRecommendation ? (
            <Modal
              open={modalRecommendation}
              closeIcon={<Cross onClick={this.closeModalRecommendation} />}
              onClose={this.closeModalRecommendation}
              size="mini"
            >
              <ModalContent>
                <ModalRecommendationContent
                  createOrderWithRecommendation={this.onGenerateOrderWithRecommendation}
                  goToHeally={this.goToHeally}
                />
              </ModalContent>
            </Modal>
          ) : null}
          {hasPhotoId ? (
            <Modal
              open={hasPhotoId}
              closeIcon={<Cross onClick={closeModalWithPhotoId} />}
              onClose={closeModalWithPhotoId}
              size="tiny"
            >
              <ModalHeader>
                <Icon name="file alternate outline" />
                Load document
              </ModalHeader>
              <ModalContent>
                <p className={cx('photo-info')}>Your order is place however due to regulation of Cannabis/CBD sales the seller requires that you upload your federal or state id to prove you are over the age of 21 or over the age of 18 and are a medical patient</p>
                {passportData && <p className={cx('photo-info')} style={{ color: '#5595b0' }}>{passportData.name}</p>}
              </ModalContent>
              <ModalActions style={{ textAlign: 'center' }}>
                {/* <ModalPhotoId saveUserProfile={saveUserProfile} /> */}
                {
                  passportData
                    ? (
                      <>
                        <Button secondary type="button" onClick={this.cancelLoadPassportImage}>
                          <Icon name="cancel" />
                          Cancel
                        </Button>
                        <Button primary type="button" onClick={() => this.saveUserPassprotImage(false, true)}>
                          <Icon name="upload" />
                          Load
                        </Button>
                      </>
                    )
                    : (
                      <Button primary type="button" style={{ position: 'relative' }}>
                        <Icon name="file image outline" />
                        Take photo or choose file
                        <ImageUpload imagePreview={this.loadPassportImage} handleError={this.onError} />
                      </Button>
                    )
                }
              </ModalActions>
            </Modal>
          ) : null}
        </div>
      </Segment >
    );
  }
}

export default Cart;
