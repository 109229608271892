import React from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Container from 'components/Container/Container';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import List from 'components/List/List';
import ListItem from 'components/List/ListItem';

import Image from 'components/Image/Image';

import styles from './Footer.module.scss';

const cx = classnames.bind(styles);

const logo = require('images/logo_white_footer.png');

const Footer = () => {
  return (
    <Segment id="seller_footer" inverted vertival style={{ padding: '6em 0em', backgroundColor: '#5595b0' }}>
      <Container>
        <Grid inverted stackable>
          <GridRow>
            <GridColumn width={4}>
              <List link inverted>
                <ListItem as="a">
                  <Image src={logo} />
                </ListItem>
              </List>
            </GridColumn>
            <GridColumn width={4} verticalAlign="middle">
              <List link inverted>
                <ListItem className={cx('list-item-white')} as="a" href="mailto:support@heallybuy.com">Contact us</ListItem>
              </List>
            </GridColumn>
            <GridColumn width={4} verticalAlign="middle">
              <List link inverted>
                <ListItem className={cx('list-item-white')} as="a">Privacy policy</ListItem>
              </List>
            </GridColumn>
            <GridColumn width={4} verticalAlign="middle" textAlign="right">
              <List link inverted>
                <ListItem style={{ textAlign: 'left' }} className={cx('list-item-white')} as="a">+1 (650) 300-4334</ListItem>
                <ListItem style={{ textAlign: 'left' }} className={cx('list-item-teal')} as="a">support@heallybuy.com</ListItem>
              </List>
            </GridColumn>
          </GridRow>
        </Grid>
      </Container>
    </Segment>
  )
}

export default Footer;
