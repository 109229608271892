import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import styles from './List.scss';

const cx = classnames.bind(styles);

const ListHeader = (props) => {
  const { children, className, content } = props;
  const classes = cx('header', className);
  const rest = getUnhandledProps(ListHeader, props);
  const ElementType = getElementType(ListHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ListHeader.create = createShorthandFactory(ListHeader, content => ({ content }));

export default ListHeader;
