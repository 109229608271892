import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import { createShorthandFactory, getUnhandledProps } from 'utils/lib';

import styles from './Dropdown.css';

const cx = classnames.bind(styles);

class DropdownSearchInput extends Component {
  static defaultProps = {
    autoComplete: 'off',
    type: 'text',
  }

  handleChange = (e) => {
    const value = _.get(e, 'target.value');

    _.invoke(this.props, 'onChange', e, { ...this.props, value });
  }

  render() {
    const { autoComplete, className, tabIndex, type, value } = this.props;
    const classes = cx('search', className);
    const rest = getUnhandledProps(DropdownSearchInput, this.props);

    return (
      <input
        {...rest}
        aria-autocomplete="list"
        autoComplete={autoComplete}
        className={classes}
        onChange={this.handleChange}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
    );
  }
}

DropdownSearchInput.create = createShorthandFactory(DropdownSearchInput, type => ({ type }))

export default DropdownSearchInput;
