import React, { Component } from 'react';

import Minus from 'images/svg/minus';
import Plus from 'images/svg/plus';

import classnames from 'classnames/bind';
import styles from './GramPacking.module.scss';

const cx = classnames.bind(styles);

class GramPacking extends Component {
  state = {
    lastStep: this.props.itemProduct ? this.props.itemProduct.quantity : this.props.lastStep,
    steps: this.props.steps,
    danger: false,
    disabledPlus: false,
  };

  componentWillReceiveProps(nextProps) {
    const { itemProduct } = this.props;

    if (
      nextProps.itemProduct &&
      itemProduct &&
      itemProduct.quantity != nextProps.itemProduct.quantity
    ) {
      this.setState({
        lastStep: nextProps.itemProduct.quantity,
      });
    }
  }

  onMinus = (lastStep) => {
    const { closeCardBuy, setProduct } = this.props;

    const steps = this.state.steps;
    const indexStep = steps.indexOf(parseFloat(lastStep));

    if (indexStep !== 0) {
      setProduct(steps[indexStep - 1].toString());
      this.setState({
        lastStep: steps[indexStep - 1],
        disabledPlus: false,
      });
    } else {
      closeCardBuy();
    }
  };

  onPlus = (lastStep) => {
    const steps = this.state.steps;
    const indexStep = steps.indexOf(parseFloat(lastStep));
    const { setProduct } = this.props;

    if (indexStep < steps.length - 1) {
      setProduct(steps[indexStep + 1].toString());
      this.setState({
        lastStep: steps[indexStep + 1],
        danger: false,
      });
    }

    if (indexStep === steps.length - 1) {
      this.setState({
        disabledPlus: true,
      });
    }
  };

  render() {
    const { price } = this.props;
    const steps = this.state.steps;
    const indexStep = steps.indexOf(parseFloat(this.state.lastStep));

    let danger = this.state.danger;
    let lastStep = this.state.lastStep;
    let currentPrice = null;
    let disabledPlus = this.state.disabledPlus;

    if (lastStep === undefined) {
      lastStep = this.state.steps[0];
    }
    currentPrice = price[lastStep];

    if (indexStep === 0 || indexStep === -1) {
      danger = true;
    }

    if (steps.length === 1 || indexStep === steps.length - 1) {
      disabledPlus = true;
    }
    return (
      <div className={cx('content-block')}>
        <div className={cx('counter-measure')}>
          <button className={cx('button-trigger')} onClick={() => this.onMinus(lastStep)}>
            <div className={cx('container-button')}>
              <Minus style={danger ? { fill: 'rgb(253, 161, 133)' } : null} />
            </div>
          </button>

          <div className={cx('measure')}>{lastStep}&nbsp;gr</div>
          <button
            className={disabledPlus ? cx('button-disabled') : cx('button-trigger')}
            onClick={() => this.onPlus(lastStep)}
          >
            <div className={cx('container-button')}>
              <Plus />
            </div>
          </button>
        </div>
        <div className={cx('cost')}>
          $&nbsp;{currentPrice && Math.round(currentPrice) === 0
            ? currentPrice.toFixed(2)
            : Math.round(currentPrice)}
        </div>
      </div>
    );
  }
}

export default GramPacking;
