import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib'

import styles from './Placeholder.module.scss';

const cx = classnames.bind(styles);

const PlaceholderHeader = (props) => {
  const { children, className, content, image } = props;
  const classes = cx(useKeyOnly(image, 'image'), 'header', className);
  const rest = getUnhandledProps(PlaceholderHeader, props);
  const ElementType = getElementType(PlaceholderHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  )
}

export default PlaceholderHeader;
