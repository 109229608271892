export const getShowModal = state => state.showModal;

export const getCurrentOrder = state => state.currentOrder;

export const getListOrders = state => state.listOrders;

export const getListOrdersError = state => state.listOrdersError;

export const getListReceivedOrders = state => state.receivedOrders;

export const getOpenModalStoreType = state => state.openModalStoreType;

export const getModalRecommendation = state => state.modalRecommendation;

export const getOrderInfo = state => state.orderInfo;

export const getIsFetchingUserOrders = state => state.isFetchingUserOrders;

export const getIsCreateOrder = state => state.isCreateOrder;

export const getIsFetchingOrderInformation = state => state.isFetchingOrderInformation;

export const getOrderInformation = state => state.orderInformation;

export const getInactiveStore = state => state.inactiveStore;

export const getReloadPaymentOnlineComponent = state => state.reloadPaymentOnlineComponent;

export const getPhotoId = state => state.hasPhotoId;

export const getRequirePhotoId = state => state.requirePhotoId;
