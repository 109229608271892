import React from 'react';

import classnames from 'classnames/bind';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';
import styles from './List.scss';

const cx = classnames.bind(styles);

const ListDescription = (props) => {
  const { children, className, content } = props;
  const classes = cx(className, 'description');
  const rest = getUnhandledProps(ListDescription, props);
  const ElementType = getElementType(ListDescription, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ListDescription.create = createShorthandFactory(ListDescription, content => ({ content }));

export default ListDescription;
