import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  createHTMLDivision,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useValueAndKey,
} from 'utils/lib';


import styles from './Progress.css';

const cx = classnames.bind(styles);

class Progress extends Component {
  calculatePercent = () => {
    const { percent, total, value } = this.props;

    if (!_.isUndefined(percent)) return percent;
    if (!_.isUndefined(total) && !_.isUndefined(value)) return (value / total) * 100;
  }

  computeValueText = (percent) => {
    const { progress, total, value } = this.props;

    if (progress === 'value') return value;
    if (progress === 'ratio') return `${value}/${total}`;
    return `${percent}%`;
  }

  getPercent = () => {
    const { precision, progress, total, value } = this.props;
    const percent = _.clamp(this.calculatePercent(), 0, 100);

    if (!_.isUndefined(total) && !_.isUndefined(value) && progress === 'value') {
      return (value / total) * 100;
    }
    if (progress === 'value') return value;
    if (_.isUndefined(precision)) return percent;
    return _.round(percent, precision);
  }

  isAutoSuccess = () => {
    const { autoSuccess, percent, total, value } = this.props;

    return autoSuccess && (percent >= 100 || value >= total);
  }

  renderLabel = () => {
    const { children, content, label } = this.props;

    if (!isNil(children)) return <div className="label">{children}</div>;
    if (!isNil(content)) return <div className="label">{content}</div>;
    // return createHTMLDivision(label, {
    //   autoGenerateKey: false,
    //   defaultProps: { className: 'label' },
    // })
  }

  renderProgress = (percent) => {
    const { precision, progress } = this.props;

    if (!progress && _.isUndefined(precision)) return;
    return <div className="progress">{this.computeValueText(percent)}</div>;
  }

  render() {
    const {
      active,
      attached,
      className,
      color,
      disabled,
      error,
      indicating,
      inverted,
      size,
      success,
      warning,
    } = this.props;

    const classes = cx(
      'ui',
      color,
      size,
      useKeyOnly(active || indicating, 'active'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(error, 'error'),
      useKeyOnly(indicating, 'indicating'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(success || this.isAutoSuccess(), 'success'),
      useKeyOnly(warning, 'warning'),
      useValueAndKey(attached, 'attached'),
      'progress',
      className,
    );
    const rest = getUnhandledProps(Progress, this.props);
    const ElementType = getElementType(Progress, this.props);
    const percent = this.getPercent() || 0;

    return (
      <ElementType {...rest} className={classes} data-percent={Math.floor(percent)}>
        <div className="bar" style={{ width: `${percent}%` }}>
          {this.renderProgress(percent)}
        </div>
        {this.renderLabel()}
      </ElementType>
    );
  }
}

export default Progress;
