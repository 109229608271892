import { connect } from 'react-redux';
import User from '../components/User';

import {
  getUpdateUserInfo,
  getIsFetchingUserInfo,
  getIsSaveUserProfile,
  gethUserAuthorizedWithHash,
  getLogInInfo,
} from 'modules/user/selectors';
import { openModalWindow, fetchUserInfo } from 'modules/user/actions';
import { changeUserProfile, cancelChangeUserProfile, saveUserProfile, sendAuthorizationHash } from 'modules/user/actions';

const mapStateToProps = state => ({
  userInfo: getUpdateUserInfo(state.user),
  isFetchingUserInfo: getIsFetchingUserInfo(state.user),
  isSaveUserProfile: getIsSaveUserProfile(state.user),
  userAuthorizedWithHash: gethUserAuthorizedWithHash(state.user),
  successLogIn: getLogInInfo(state.user),
});

export default connect(
  mapStateToProps,
  {
    changeUserProfile,
    cancelChangeUserProfile,
    saveUserProfile,
    openModalWindow,
    sendAuthorizationHash,
    fetchUserInfo,
  }
)(User);
