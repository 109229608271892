import React from 'react';
import PropTypes from 'prop-types';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useValueAndKey,
  useTextAlignProp,
  useKeyOrValueAndKey,
  useKeyOnly,
} from 'utils/lib';

import classnames from 'classnames/bind';
import Image from 'components/Image/Image';
import Icon from 'components/Icons/Icon';
import styles from './Header.scss';

import HeaderSubheader from './HeaderSubheader';
import HeaderContent from './HeaderContent';

const cx = classnames.bind(styles);

const Header = (props) => {
  const {
    attached,
    block,
    children,
    className,
    color,
    content,
    disabled,
    dividing,
    floated,
    icon,
    image,
    inverted,
    size,
    sub,
    subheader,
    textAlign,
  } = props;

  const classes = cx(
    'ui',
    color,
    size,
    useKeyOnly(block, 'block'),
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(dividing, 'dividing'),
    useValueAndKey(floated, 'floated'),
    useKeyOnly(icon === true, 'icon'),
    useKeyOnly(image === true, 'image'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(sub, 'sub'),
    useKeyOrValueAndKey(attached, 'attached'),
    useTextAlignProp(textAlign),
    'header',
    className
  );

  const rest = getUnhandledProps(Header, props);
  const ElementType = getElementType(Header, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  const iconElement = Icon.create(icon, { autoGenerateKey: false });
  const imageElement = Image.create(image, { autoGenerateKey: false });
  const subheaderElement = HeaderSubheader.create(subheader, { autoGenerateKey: false });

  if (iconElement || imageElement) {
    return (
      <ElementType {...rest} className={classes}>
        {iconElement || imageElement}
        {(content || subheaderElement) && (
          <HeaderContent>
            {content}
            {subheaderElement}
          </HeaderContent>
        )}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {content}
      {subheaderElement}
    </ElementType>
  );
};

Header.propTypes = {
  block: PropTypes.bool,
  color: PropTypes.string,
};

export default Header;
