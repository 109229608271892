import React from 'react';

const Plus = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 286.054 286.054"
    style={{ enableBackground: 'new 0 0 286.054 286.054' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        style={{ fill: '#4bc6b4' }}
        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
		c78.987,0,143.027-64.031,143.027-143.027C286.045,64.04,222.014,0,143.027,0z M143.027,259.236
		c-64.183,0-116.209-52.026-116.209-116.209s52.026-116.2,116.209-116.2c64.174,0,116.209,52.017,116.209,116.2
		S207.201,259.236,143.027,259.236z M196.832,125.149h-35.936V89.392c0-4.934-4.005-8.939-8.939-8.939h-17.878
		c-4.943,0-8.939,4.005-8.939,8.939v35.757H89.401c-4.952,0-8.957,4.005-8.957,8.939v17.878c0,4.943,4.005,8.939,8.957,8.939h35.748
		v35.676c0,4.934,3.996,8.93,8.939,8.93h17.878c4.934,0,8.939-3.996,8.939-8.93v-35.676h35.936c4.952,0,8.957-3.996,8.957-8.939
		v-17.878C205.789,129.162,201.775,125.149,196.832,125.149z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default Plus;
