/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Comment.css';

const cx = classnames.bind(styles);

const CommentGroup = (props) => {
  const { className, children, collapsed, content, minimal, size, threaded } = props;

  const classes = cx(
    'ui',
    size,
    useKeyOnly(collapsed, 'collapsed'),
    useKeyOnly(minimal, 'minimal'),
    useKeyOnly(threaded, 'threaded'),
    'comments',
    className,
  );
  const rest = getUnhandledProps(CommentGroup, props);
  const ElementType = getElementType(CommentGroup, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default CommentGroup;
