import React from 'react';

import classnames from 'classnames/bind';
import styles from './LogoStore.module.scss';

const cx = classnames.bind(styles);

const LogoStore = props => (
  <div className={cx('logo-store-container')}>
    <img src={props.storeInfo && props.storeInfo.logo.in_cart} alt="" />
  </div>
);

export default LogoStore;
