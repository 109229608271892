import { useCallback } from 'react';
import notification from 'components/Notification';

export const useMessage = () => {
  return useCallback((message, description, duration, status, onClose) => {
    if (notification[status]) {
      notification[status]({
        message,
        description,
        duration,
        onClose
      })
    }
  }, [])
}
