import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Input from 'components/Input/Input';
import Checkbox from 'components/CheckBox/Checkbox';

import styles from './Gram.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '37.5%',
  fontWeight: '700',
  color: 'rgba(0,0,0,.87)',
  fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
};

class Gram extends Component {
  state = {
    checked: this.props.outOfStock
  }

  onChange = (e, field) => {
    const { onChange } = this.props;
    const { value, name } = field;

    if (value.length === 0) {
      this.setState({
        [name]: true,
      });
      return null;
    }
    onChange(value, name);
  }

  onToggle = () => {
    const { toggleOutOfStock } = this.props;
    this.setState({
      checked: !this.state.checked,
    }, () => {
      toggleOutOfStock(this.state.checked);
    })
  }

  render() {
    const { price } = this.props;
    const { checked } = this.state;

    return (
      <Grid>
        <GridRow>
          <GridColumn width={6} style={{ width: '37.5%' }}>
            <label style={labelStyle}>out of stock</label>
          </GridColumn>
          <GridColumn width={10} style={{ width: '62.5%' }}>
            <Checkbox checked={checked} onClick={this.onToggle} />
          </GridColumn>
        </GridRow>
        {Object.keys(price).map(v => (
          <GridRow style={{ alignItems: 'center' }}>
            <GridColumn
              width={6}
              style={labelStyle}
            >
              {v}&nbsp;g

            </GridColumn>
            <GridColumn width={10} style={{ width: '62.5%' }}>
              <Input
                size="small"
                fluid
                placeholder="price..."
                icon="dollar"
                onChange={this.onChange}
                type="number"
                defaultValue={price[v]}
                name={v}
                error={this.state[v]}
              />
            </GridColumn>
          </GridRow>
        ))}
      </Grid>
    )
  }
}

export default Gram;
