import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';

import { fetchStoreInformation, setNewStoreLogo } from 'modules/seller/actions';

import {
  getStoreInformation,
  getIsFetchingStoreInformation,
  getIsEditStoreInformation,
  getOrdersProcessing,
  getIsFetchingListOrders,
  getListWithDeliveryLocations,
  getIsFetchingListWithDeliveryLocation,
  getProductsInStock,
  getNumberOfDeliveryLocations,
  getNumberOfOrdersPending,
  getNumberOfProductsInStock,
  getIsFetchingStoreProducts,
  getUpdateTabWithPanes,
} from 'modules/seller/selectors';

import { onSignOut } from 'modules/user/actions';

import { getIsFetchingUserInfo, getUserInfo } from 'modules/user/selectors';

import SellerLayout from '../../components/SellerLayout';

import styles from './SellerLayout.module.scss';

const cx = classnames.bind(styles);

class SellerLayoutWrapper extends Component {
  state = {};

  render() {
    return (
      <SellerLayout {...this.props}>{this.props.children}</SellerLayout>
    );
  }
}

const mapStateToProps = state => ({
  storeInformation: getStoreInformation(state.seller),
  isFetchingStoreInformation: getIsFetchingStoreInformation(state.seller),
  isEditStoreInformation: getIsEditStoreInformation(state.seller),
  ordersProcessing: getOrdersProcessing(state.seller),
  isFetchingListOrders: getIsFetchingListOrders(state.seller),
  listWithDeliveryLocations: getListWithDeliveryLocations(state.seller),
  isFethchingListWithDeliveryLocation: getIsFetchingListWithDeliveryLocation(state.seller),
  productsInStock: getProductsInStock(state.seller),
  numberOfDeliveryLocations: getNumberOfDeliveryLocations(state.seller),
  numberOfOrdersPending: getNumberOfOrdersPending(state.seller),
  numberOfProductsInStock: getNumberOfProductsInStock(state.seller),
  isFetchingStoreProducts: getIsFetchingStoreProducts(state.seller),
  updateTabWithPanes: getUpdateTabWithPanes(state.seller),
  isFetchingUserInfo: getIsFetchingUserInfo(state.user),
  userInfo: getUserInfo(state.user),
});

export default connect(
  mapStateToProps,
  {
    fetchStoreInformation,
    setNewStoreLogo,
    onSignOut
  }
)(SellerLayoutWrapper);
