/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import {
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useMultipleProp,
  useTextAlignProp,
  useValueAndKey,
  useVerticalAlignProp,
  useWidthProp,
} from 'utils/lib';

import styles from './Grid.css';

const cx = classnames.bind(styles);

const GridColumn = (props) => {
  const {
    children,
    className,
    computer,
    color,
    floated,
    largeScreen,
    mobile,
    only,
    stretched,
    tablet,
    textAlign,
    verticalAlign,
    widescreen,
    width,
  } = props;

  const floateds = (floated && useValueAndKey(floated, 'floated').split(' ')) || [];
  const wides = (width && useWidthProp(width, 'wide').split(' ')) || [];

  const classes = cx(
    color,
    useKeyOnly(stretched, 'stretched'),
    useMultipleProp(only, 'only'),
    useTextAlignProp(textAlign),
    ...floateds,
    useVerticalAlignProp(verticalAlign),
    useWidthProp(computer, 'wide computer'),
    useWidthProp(largeScreen, 'wide large screen'),
    useWidthProp(mobile, 'wide mobile'),
    useWidthProp(tablet, 'wide tablet'),
    useWidthProp(widescreen, 'wide widescreen'),
    ...wides,
    'column',
    className
  );
  const rest = getUnhandledProps(GridColumn, props);
  const ElementType = getElementType(GridColumn, props);

  return (
    <ElementType {...rest} className={classes}>
      {children}
    </ElementType>
  );
};

GridColumn.create = createShorthandFactory(GridColumn, children => ({ children }));

GridColumn.propTypes = {
  floated: PropTypes.string,
};

export default GridColumn;
