import React from 'react';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useTextAlignProp,
} from 'utils/lib';

import classnames from 'classnames/bind';
import styles from './Container.css';

const cx = classnames.bind(styles);

const Container = (props) => {
  const { children, className, content, fluid, text, textAlign } = props;

  const classes = cx(
    'ui',
    useKeyOnly(text, 'text'),
    useKeyOnly(fluid, 'fluid'),
    useTextAlignProp(textAlign),
    'container',
    className
  );

  const rest = getUnhandledProps(Container, props);
  const ElementType = getElementType(Container, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default Container;
