import React, { Component } from 'react';
import classnames from 'classnames/bind';
import Webcam from 'react-webcam';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalActions from 'components/ModalWindow/ModalActions';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import Image from 'components/Image/Image';

import styles from './ModalPhotoId.module.scss';

const cx = classnames.bind(styles);

class ModalPhotoId extends Component {
  state = {
    open: false,
    imageSrc: null,
    videoConstraints: {
      width: 1280,
      height: 720,
      facingMode: window.isMobile || window.isTablet ? { exact: 'environment' } : 'user',
    },
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  close = () => this.setState({ open: false });

  open = () => this.setState({ open: true });

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({
      imageSrc,
    });
  };

  retakePhoto = () => {
    this.setState({
      imageSrc: null,
    });
  }

  onSave = () => {
    const { imageSrc } = this.state;
    const { saveUserProfile, fieldForSave } = this.props;

    let field = 'user[id_photo]';

    if (fieldForSave) {
      field = fieldForSave;
    }

    if (imageSrc) {
      const formData = new FormData();

      formData.append([field], imageSrc);

      saveUserProfile(formData);
    }
  }

  changeFacingMode = () => {
    const { videoConstraints } = this.state;

    if (videoConstraints.facingMode.exact) {
      this.setState({
        videoConstraints: {
          width: 1280,
          height: 720,
          facingMode: 'user',
        },
      });
    } else {
      this.setState({
        videoConstraints: {
          width: 1280,
          height: 720,
          facingMode: { exact: 'environment' },
        },
      });
    }
  }

  render() {
    const { imageSrc, videoConstraints } = this.state;

    return (
      <Modal
        open={this.state.open}
        onOpen={this.open}
        onClose={this.close}
        closeIcon={<Icon name="close" link onClick={this.close} />}
        size="tiny"
        scrolling
        trigger={(
          <Button primary icon>
            <Icon name="photo" />
            Take photo
          </Button>
        )}
      >
        <ModalHeader>Photo</ModalHeader>
        <ModalContent>
          {
            imageSrc
              ? <Image src={imageSrc} />
              : (
                <Webcam
                  audio={false}
                  ref={this.setRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  width="100%"
                  height="100%"
                  className={cx('webcam-container')}
                />
              )
          }
        </ModalContent>
        <ModalActions style={{ textAlign: 'center' }}>
          {
            imageSrc
              ? (
                <div>
                  <Button type="button" onClick={this.retakePhoto}>retake photo</Button>
                  <Button type="button" primary onClick={this.onSave}>Save</Button>
                </div>
              )
              : (
                <div>
                  <Button disabled={imageSrc && true} onClick={this.capture}>
                    <Icon name="photo" />
                    Capture photo
                  </Button>
                  {window.isMobile || window.isTablet ? <Button type="button" onClick={this.changeFacingMode}><Icon name="sync" /></Button> : null}
                </div>
              )
          }
        </ModalActions>
      </Modal>
    );
  }
}

export default ModalPhotoId;
