/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames/bind';

import {
  createHTMLImage,
  getElementType,
  getUnhandledProps,
  htmlImageProps,
  partitionHTMLProps,
} from 'utils/lib';

import styles from './Comment.css';

const cx = classnames.bind(styles);

const CommentAvatar = (props) => {
  const { className, src } = props;

  const classes = cx('avatar', className);
  const rest = getUnhandledProps(CommentAvatar, props);
  const [imageProps, rootProps] = partitionHTMLProps(rest, { htmlProps: htmlImageProps });
  const ElementType = getElementType(CommentAvatar, props);

  return (
    <ElementType {...rootProps} className={classes}>
      {createHTMLImage(src, { autoGenerateKey: false, defaultProps: imageProps })}
    </ElementType>
  );
};

export default CommentAvatar;
