import React, { Component } from 'react';
import Icon from 'components/Icons/Icon';
import Accordion from 'components/Accordion/Accordion';
import AccordionTitle from 'components/Accordion/AccordionTitle';
import AccordionContent from 'components/Accordion/AccordionContent';
import Marker from 'components/Icons/Marker';
import Button from 'components/Button/Button';
import Transition from 'components/Transition/Transition';
import Divider from 'components/Divider';
import Image from 'components/Image/Image';

import classnames from 'classnames/bind';
import styles from './ModalGenerateOrderContent.module.scss';
import PaymentMethod from '../PaymentMethod';

const cx = classnames.bind(styles);

const image = 'https://s3-us-west-1.amazonaws.com/heallymarketplace/no-photo_290x181.png';

class ModalGenerateOrderContent extends Component {
  state = {
    activeIndex: 0
  };


  handleClick = () => {

  }

  render() {
    const {
      storeInformation,
      totalPrice,
      payment,
      products,
      phoneNumber,
      createOrder,
      cardDetails,
      deliveryAddress,
    } = this.props;

    const { activeIndex } = this.state;

    return (
      <div className={cx('container')}>
        <div style={{ height: '100%' }}>
          <div
            style={{
              textAlign: 'center',
              margin: '18px',
              fontSize: '18px',
              textTransform: 'uppercase',
              color: 'rgb(75, 198, 180)',
              fontWeight: 'bold',
              fontFamily: "'Proxima Nova Soft', sans-serif",
            }}
          >
            Confirm your order
        </div>
          <div className={cx('segment')}>
            <Accordion className={cx('container-accordion')}>
              <AccordionTitle active={activeIndex === 0} index={0} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em' }}>
                <Icon
                  name="building outline"
                  size="large"
                  style={{ color: '#314659' }}
                />
                <span style={{ marginLeft: '0.5em', display: 'inline' }} className={cx('header')}>
                  {storeInformation && storeInformation.name}
                </span>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow={false}
                disabled
                key="1"
              >
                Something information about time
            </AccordionContent>

              <AccordionTitle active={activeIndex === 1} index={1} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em', display: 'flex' }}>
                <Icon
                  name="shipping fast"
                  size="large"
                  style={{ display: 'block', color: '#314659' }}
                />
                <div style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
                  <span style={{ display: 'inline' }} className={cx('header')}>
                    {deliveryAddress.data.streetNameForInputCart},&nbsp;{deliveryAddress.data.addressWithZipForInputCart}
                  </span>
                </div>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow={false}
                disabled
                key="2"
              >
                Something information about delivery address
            </AccordionContent>

              <AccordionTitle active={activeIndex === 2} index={2} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em' }}>
                <Icon
                  name="mobile alternate"
                  size="large"
                  style={{ color: '#314659' }}
                />
                <span style={{ marginLeft: '0.5em' }} className={cx('header')}>{phoneNumber}</span>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow={false}
                disabled
                key="3"
              >
                Something information about phone
            </AccordionContent>

              <AccordionTitle active={activeIndex === 3} index={3} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em' }}>
                <Icon
                  size="large"
                  name={payment === 'cash' ? 'money bill alternate outline' : 'credit card outline'}
                  style={{ color: '#314659' }}
                />
                <span style={{ marginLeft: '0.5em' }} className={cx('header')}>
                  {payment === 'cash' || payment === 'card' ? (
                    <span style={{ textTransform: 'capitalize' }}>{payment}</span>
                  ) : (
                      cardDetails && <span>{`${cardDetails.cardType}`} &nbsp;&bull;&nbsp;&bull;&nbsp;&bull;&nbsp;&bull;&nbsp; {`${cardDetails.lastFour}`}</span>
                    )}
                </span>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow={false}
                disabled
                key="4"
              >
                Something information about card
            </AccordionContent>

              <AccordionTitle active={activeIndex === 4} index={4} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em' }}>
                <Icon
                  name="shopping basket"
                  size="large"
                  style={{ color: '#314659' }}
                />
                <span style={{ marginLeft: '0.5em' }} className={cx('header')}>{products && products.length} items</span>
                <div className={cx('meta')}>
                  {products &&
                    products.map((product, key) => {
                      return (
                        <Transition key={`${key}_image`} visible animation={'fade'} duration={300}>
                          <Image
                            src={product.image && product.image.default_proxy}
                            size="mini"
                            style={{ padding: '5px', width: '45px' }}
                          />
                        </Transition>
                      );
                    })}
                </div>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow
                key="5"
              />

              <AccordionTitle active={activeIndex === 5} index={5} onClick={this.handleClick} style={{ padding: '1em 0em 0.5em 0em' }}>
                <Icon
                  name="clock outline"
                  size="large"
                  style={{ color: '#314659' }}
                />
                <span style={{ marginLeft: '0.5em' }} className={cx('header')}>
                  {storeInformation && storeInformation.delivery_time}
                </span>
              </AccordionTitle>
              <Divider style={{ margin: '0.35rem 0rem' }} />
              <AccordionContent
                showArrow={false}
                disabled
                key="6"
              >
                Something information about delivery time
            </AccordionContent>
            </Accordion>
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  margin: '0px 10px',
                }}
              >
                <div style={{ fontSize: '20px', color: '#1c5872', textTransform: 'uppercase' }}>
                  Total
              </div>
                <div style={{ fontSize: '22px', color: 'rgb(85, 149, 176)' }}>${totalPrice}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('container-actions')}>
          <Button type="button" fluid color="teal" onClick={createOrder}>place order</Button>
        </div>
      </div>
    );
  }
}

export default ModalGenerateOrderContent;
