import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import styles from './Item.scss';

const cx = classnames.bind(styles);

const ItemMeta = (props) => {
  const { children, className, content } = props;
  const classes = cx('meta', className);
  const rest = getUnhandledProps(ItemMeta, props);
  const ElementType = getElementType(ItemMeta, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ItemMeta.craete = createShorthandFactory(ItemMeta, content => ({ content }));

export default ItemMeta;
