/* eslint-disable no-undef */
const getArguments = () => {
  let res = {};
  for (let i = 0; i < arguments.length; ++i) {
    if (arguments[i]) Object.assign(res, arguments[i]);
  }
  return res;
};

export default getArguments;
