import React from 'react';
import classnames from 'classnames/bind';

import { isNil, getElementType, getUnhandledProps } from 'utils/lib';
import styles from './Item.scss';
import ItemContent from './ItemContent';
import ItemImage from './ItemImage';

const cx = classnames.bind(styles);

const Item = (props) => {
  const { children, className, content, description, extra, header, image, meta } = props;

  const classes = cx('item', className);
  const rest = getUnhandledProps(Item, props);
  const ElementType = getElementType(Item, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {ItemImage.create(image, { autoGenerateKey: false })}

      <ItemContent
        content={content}
        description={description}
        extra={extra}
        header={header}
        meta={meta}
      />
    </ElementType>
  );
};

export default Item;
