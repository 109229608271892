import React from 'react';
import DeliveryLinks from 'mocks/deliveryLinks';
import Header from 'components/Typography/Header/Header';

import classnames from 'classnames/bind';
import styles from './DeliveryMessage.module.scss';

const cx = classnames.bind(styles);

const DeliveryMessage = (props) => {
  const { messages, sameDay, goToOvernightStores, goToSameDayStores, devicePrefix } = props;

  return (
    <div className={cx('delivery-message-wrapper')}>
      {sameDay ? (
        <div
          className={cx('delivery-message-content', `delivery-message-content--${devicePrefix}`)}
        >
          <Header as="h3" style={{ color: '#00aa92', fontFamily: "'Proxima Nova Soft', sans-serf", fontWeight: 400 }}>
            <span>{messages.menue_page_same_day.message_text}</span>
            <DeliveryLinks
              index={messages.menue_page_same_day.message_link}
              goToOvernightStores={goToOvernightStores}
              goToSameDayStores={goToSameDayStores}
            />
          </Header>
        </div>
      ) : (
          <div
            className={cx('delivery-message-content', `delivery-message-content--${devicePrefix}`)}
          >
            <Header as="h3" style={{ color: '#00aa92', fontFamily: "'Proxima Nova Soft', sans-serf", fontWeight: 400 }}>
              <span>{messages.menue_page_next_day.message_text}</span>
              <DeliveryLinks
                index={messages.menue_page_next_day.message_link}
                goToOvernightStores={goToOvernightStores}
                goToSameDayStores={goToSameDayStores}
              />
            </Header>
          </div>
        )}
    </div>
  );
};

export default DeliveryMessage;
