import React from 'react';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import classnames from 'classnames/bind';
import styles from './Modal.css';

const cx = classnames.bind(styles);

const ModalHeader = (props) => {
  const { children, className, content } = props;
  const classes = cx(className, 'header');
  const rest = getUnhandledProps(ModalHeader, props);
  const ElementType = getElementType(ModalHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ModalHeader.create = createShorthandFactory(ModalHeader, content => ({ content }));

export default ModalHeader;
