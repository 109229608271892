import React from 'react';
import classnames from 'classnames/bind';

import { isNil, getElementType, getUnhandledProps, useTextAlignProp } from 'utils/lib';
import styles from './Card.css';

const cx = classnames.bind(styles);

const CardDescription = (props) => {
  const { children, className, content, textAlign } = props;
  const classes = cx(useTextAlignProp(textAlign), 'description', className);
  const rest = getUnhandledProps(CardDescription, props);
  const ElementType = getElementType(CardDescription, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default CardDescription;
