import { request } from 'services/api';
import getRemedyColor from 'services/remediesColor';
import * as constants from './constants';

const compare = (el1, el2, index) => (el1[index] === el2[index] ? 0 : (el1[index] < el2[index] ? -1 : 1));

const getUniqueRemediesId = categories => new Promise((resolve) => {
  const remedyArrayIds = [];
  categories.forEach((category) => {
    category.products.forEach((product) => {
      product.remedy_ids.forEach((remedy) => {
        remedyArrayIds.push(remedy);
      });
    });
  });
  const reduceRemedies = remedyArrayIds.reduce((prev, cur) => {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});
  resolve(Object.keys(reduceRemedies));
});

export const handleClickRemedy = remedy => (dispatch) => {
  dispatch({
    type: [constants.HANDLE_CLICK_REMEDY],
    remedy,
  });
};

export const fetchRemedies = (categories, remedyId) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_REMEDIES],
  });
  return request('/api/v1/remedies')
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        getUniqueRemediesId(categories).then((remedySetIds) => {
          const filterRemedy = [];
          json.data.forEach((remedy, index) => {
            if (remedySetIds.indexOf(remedy.id.toString()) >= 0) {
              filterRemedy.push({
                ...remedy,
                position: index,
                color: getRemedyColor(index),
                active: false,
              });
            }
          });
          filterRemedy.sort((a, b) => a.position - b.position);
          dispatch({
            type: [constants.FETCH_REMEDIES_SUCCESS],
            remedies: json.data,
            filterRemedy,
          });
          if (remedyId) {
            const remedy = json.data.filter(obj => obj.id == remedyId);
            remedy[0].active = false;
            dispatch(handleClickRemedy(remedy[0]));
          }
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_REMEDIES_FAIL],
      });
    });
};

export const getListOfAllRemedies = () => (dispatch) => {
  dispatch({
    type: [constants.GET_ALL_REMEDIES],
  });
  request('/api/v1/remedies')
    .then(response => response.json())
    .then((json) => {
      const listOfRemedies = json.data.map(v => ({
        key: v.id,
        id: v.id,
        text: v.name,
        value: v.name,
      }))
        .sort((el1, el2) => compare(el1, el2, 'text'));

      dispatch({
        type: [constants.GET_ALL_REMEDIES_SUCCESS],
        listOfRemedies,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.GET_ALL_REMEDIES_FAIL],
      });
    });
};
