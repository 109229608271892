import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib'

import styles from './Placeholder.module.scss';

const cx = classnames.bind(styles);

const Placeholder = (props) => {
  const { children, className, content, fluid, inverted } = props;
  const classes = cx(
    'ui',
    useKeyOnly(fluid, 'fluid'),
    useKeyOnly(inverted, 'inverted'),
    'placeholder',
    className,
  );
  const rest = getUnhandledProps(Placeholder, props)
  const ElementType = getElementType(Placeholder, props)

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  )
}

export default Placeholder
