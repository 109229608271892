import React, { Component, Fragment } from 'react';
import classnames from 'classnames/bind';
import spacetime from 'spacetime';

import Container from 'components/Container/Container';
import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';
import Statistic from 'components/Typography/Statistic/Statistic';
import StatisticValue from 'components/Typography/Statistic/StatisticValue';
import StatisticLabel from 'components/Typography/Statistic/StatisticLabel';

import Table from 'components/Table/Table';
import TableHeader from 'components/Table/TableHeader';
import TableRow from 'components/Table/TableRow';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import TableBody from 'components/Table/TableBody';
import TableCell from 'components/Table/TableCell';
import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Input from 'components/Input/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import DropdownHeader from 'components/Dropdown/DropdownHeader';
import DropdownMenu from 'components/Dropdown/DropdownMenu';
import DropdownItem from 'components/Dropdown/DropdownItem';

import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import ModalActions from 'components/ModalWindow/ModalActions';

import notification from 'components/Notification';
import Popup from 'components/Popup/Popup';

import UpdateOrder from './components/Modals/UpdateOrder';
import ShowOrder from './components/Modals/ShowOrder';

import styles from './ManageOrders.module.scss';

const cx = classnames.bind(styles);

const EMAIL_MESSAGE = 'Email notification is sent for In the works, Shipped and Rejected statuses'

const options = [
  {
    key: 1,
    text: 'In the works',
    value: 'processed',
    icon: <Popup position="bottom center" inverted trigger={<Icon name="mail outline" />} content={EMAIL_MESSAGE} />,
  },
  {
    key: 2,
    text: 'Received by seller',
    value: 'processing',
    icon: <Popup position="bottom center" inverted trigger={<Icon name="mail" color="grey" inverted />} content={EMAIL_MESSAGE} />,
  },
  {
    key: 3,
    text: 'Shipped',
    value: 'received',
    icon: <Popup position="bottom center" inverted trigger={<Icon name="mail outline" />} content={EMAIL_MESSAGE} />,
  },
  {
    key: 4,
    text: 'Completed',
    value: 'completed',
    icon: <Popup position="bottom center" inverted trigger={<Icon name="mail" color="grey" inverted />} content={EMAIL_MESSAGE} />,
  },
  {
    key: 5,
    text: 'Rejected',
    value: 'rejected',
    icon: <Popup position="bottom center" inverted trigger={<Icon name="mail outline" />} content={EMAIL_MESSAGE} />,
  },
];

const tagOptions = [
  {
    key: 'Rejected',
    text: 'Rejected',
    value: 'rejected',
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'Processed',
    text: 'In the works',
    value: 'processed',
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 'Processing',
    text: 'Received by seller',
    value: 'processing',
    label: { color: '#fff59d', empty: true, circular: true },
  },
  {
    key: 'Received',
    text: 'Shipped',
    value: 'received',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Completed',
    text: 'Completed',
    value: 'completed',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 'Cancel',
    text: 'Cancel',
    value: null,
    label: { color: 'black', empty: true, circular: true },
  },
];

const tableRowStatus = {
  rejected: 'negative',
  shipped: 'positive',
  received: 'warning',
};

const EMAIL_NOTIFICATION = ['processed', 'received', 'rejected'];

const ICON_BY_STATUS = {
  processed: 'mail outline',
  processing: 'mail',
  received: 'mail outline',
  completed: 'mail',
  rejected: 'mail outline',
};

class ManageOrders extends Component {
  state = {
    isEditOrder: false,
    isShowOrder: false,
  }

  componentDidMount() {
    const { fetchListOrders } = this.props;

    fetchListOrders();
  }

  onEditOrder = (id) => {
    const { showOrder } = this.props;

    this.setState({
      isEditOrder: true,
    }, () => {
      showOrder(id);
    });
  }

  showOrder = (id) => {
    const { showOrder } = this.props;
    this.setState({
      isShowOrder: true,
    }, () => {
      showOrder(id);
    });
  }

  closeOrder = () => {
    this.setState({
      isShowOrder: false,
    });
  }

  onCloseEditOrder = () => {
    this.setState({
      isEditOrder: false,
      order: null,
      isSuccessUpdate: false,
    });
  }

  editCurrentOrder = (order) => {
    this.setState({
      isShowOrder: false,
      isEditOrder: true,
      order,
    });
  }

  onChangeOrder = (order) => {
    this.order = order;
  }

  onUpdate = () => {
    const { updateOrder } = this.props;
    if (this.order) {
      const { id, address, notes, phone, delivery_cost, time, date, status, manager_notes, delivery_time } = this.order;
      const deliveryTime = date && time ? Date.parse(`${date}T${time}`) / 1000 : Date.parse(delivery_time) / 1000;

      const newOrder = {
        address,
        notes,
        phone,
        delivery_cost,
        delivery_time: deliveryTime,
        aasm_state: status,
        manager_notes,
      };

      updateOrder(id, newOrder).then(() => {
        this.setState({
          isSuccessUpdate: true,
        }, () => {
          clearTimeout(this.couter);
          this.couter = setTimeout(() => {
            this.onCloseEditOrder();
          }, 1500);
        });
      });
    }
  }

  changeOrderStatus = (e, data, order) => {
    const { updateOrder } = this.props;
    if (data) {
      if (EMAIL_NOTIFICATION.indexOf(data.value) === -1) {
        notification.info({
          message: 'Info',
          description: 'Email notification is sent for In the works, Shipped and Rejected statuses',
          enableFontStyle: true,
        });
      }

      const newOrder = {
        aasm_state: data.value,
      };
      updateOrder(order.id, newOrder);
    }
  }

  renderDateTime = (value) => {
    const s = spacetime(value);
    const date = s.format('numeric-uk');
    const time = s.format('time');

    return `${date} ${time}`;
  }

  filterOrders = (e, data) => {
    this.setState({
      filter: data.value,
    });
  }

  render() {
    const { isEditOrder, isShowOrder, productId, filter, isSuccessUpdate } = this.state;
    const { isFetchingListOrders, listOrders, isFetchingShowOrder, order, updateOrder } = this.props;

    const orderProcessing = listOrders.filter(v => v.status === 'processing');
    const orderReceived = listOrders.filter(v => v.status === 'received');

    const newListOrder = filter ? listOrders.filter(v => v.status === filter) : listOrders;

    return (
      <Container style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
        <Grid>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised>
              <div>
                <Statistic horizontal style={{ margin: '0em' }}>
                  <StatisticValue>{orderProcessing.length}</StatisticValue>
                  <StatisticLabel>orders received</StatisticLabel>
                </Statistic>
                <Statistic horizontal>
                  <StatisticValue>{orderReceived.length}</StatisticValue>
                  <StatisticLabel>orders shipped</StatisticLabel>
                </Statistic>
                <div style={{ float: 'right', height: '42px', display: 'flex', alignItems: 'center' }}>
                  <Dropdown
                    text="Filter Orders"
                    labeled
                    button
                    simple
                    className={cx('dropdown-filter')}
                    options={tagOptions}
                    iconName="filter"
                    onChange={this.filterOrders}
                  />
                </div>
              </div>
            </Segment>
          </GridColumn>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised loading={isFetchingListOrders}>
              <Table compact size="mini" padded style={{ position: 'relative' }}>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>User</TableHeaderCell>
                    <TableHeaderCell>Brand</TableHeaderCell>
                    <TableHeaderCell>Final order price</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Created at (+04:00 UTC)</TableHeaderCell>
                    <TableHeaderCell textAlign="center">Actions</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {newListOrder.map((v, key) => (
                    <Fragment key={key}>
                      <TableRow className={cx(`${v.status}-status-color`)}>
                        <TableCell>{v.id}</TableCell>
                        <TableCell>{v.email}</TableCell>
                        <TableCell />
                        <TableCell textAlign="center">
                          <span style={{ fontWeight: 'bold' }}>
                            $
                            {v.final_order_price}
                          </span>
                        </TableCell>
                        <TableCell style={{ position: 'relative' }}>
                          <Dropdown value={v.status} iconByValue={<Popup inverted trigger={<Icon name={ICON_BY_STATUS[v.status]} color={ICON_BY_STATUS[v.status] === 'mail' && 'grey'} inverted={ICON_BY_STATUS[v.status] === 'mail'} />} content={EMAIL_MESSAGE} />} options={options} selection fluid onChange={(e, data) => this.changeOrderStatus(e, data, v)} />
                        </TableCell>
                        <TableCell>{this.renderDateTime(v.creation_date)}</TableCell>
                        <TableCell textAlign="right">
                          <Button size="mini" type="button" onClick={() => this.showOrder(v.id)}><Icon style={{ margin: 0 }} name="eye" /></Button>
                          <Button size="mini" type="button" onClick={() => this.onEditOrder(v.id)}><Icon style={{ margin: 0 }} name="edit" /></Button>
                        </TableCell>
                      </TableRow>
                      {v.manager_notes
                        && (
                          <TableRow key={`${key}_manager_notes`}>
                            <TableCell colspan={8} className={cx('manager-notes-cell', `${v.status}-status-color`)}>
                              <Icon name="comment outline" size="large" />
                              <span className={cx('manager-notes')}>{v.manager_notes}</span>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </Segment>
          </GridColumn>
        </Grid>
        <Modal
          open={isEditOrder}
          closeIcon={<Icon name="close" link onClick={this.onCloseEditOrder} />}
          onClose={this.onCloseEditOrder}
          size="tiny"
          scrolling
        >
          <ModalHeader>
            <Icon name="file alternate outline" />
            Update order
          </ModalHeader>
          <ModalContent>
            <h4 className={cx(isSuccessUpdate ? 'slide-in-right' : 'slide-out-right')} style={{ color: 'green', textAlign: 'center' }}>{isSuccessUpdate ? 'Success' : null}</h4>
            <ModalDescription>
              <UpdateOrder updateOrder={updateOrder} order={order} isFetchingShowOrder={isFetchingShowOrder} onChange={this.onChangeOrder} />
            </ModalDescription>
          </ModalContent>
          <ModalActions style={{ textAlign: 'center' }}>
            <Button type="button" primary onClick={this.onUpdate}>Update</Button>
          </ModalActions>
        </Modal>
        <Modal
          scrolling
          open={isShowOrder}
          closeIcon={<Icon name="close" link onClick={this.closeOrder} />}
          onClose={this.closeOrder}
        >
          <ModalHeader>
            <Icon name="file alternate outline" />
            Show order
          </ModalHeader>
          <ModalContent>
            <ModalDescription>
              <ShowOrder showOrder={this.showOrder} isFetchingShowOrder={isFetchingShowOrder} order={order} />
            </ModalDescription>
          </ModalContent>
          <ModalActions>
            <Button size="mini" color="teal" type="button" onClick={() => this.editCurrentOrder(order)}>Edit</Button>
            <Button size="mini" secondary type="button" onClick={() => this.closeOrder()}>Close</Button>
          </ModalActions>
        </Modal>
      </Container>
    );
  }
}

export default ManageOrders;
