import React, { Component, Fragment } from 'react';
import classnames from 'classnames/bind';
import Input from 'components/Input/Input';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';

import styles from './WorkingTime.module.scss';
import { getDay } from 'services';
import convertTime from 'services/datetime';

const cx = classnames.bind(styles);

const DAYS_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SUT'];

class WorkingTime extends Component {
  state = {
    actualWorkTime: this.props.actualWorkTime,
    daysWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SUT'],
  }

  onChange = (e, field) => {
    const { actualWorkTime, onChange } = this.props;

    if (field) {
      const splitField = field.name.split('-');
      const day = DAYS_WEEK.indexOf(splitField[0]);

      if (!actualWorkTime[day]) {
        actualWorkTime[day] = [];
      }
      actualWorkTime[day][splitField[1]] = field.value;

      onChange(actualWorkTime);
    }
  }

  clearInputs = (key) => {
    const { actualWorkTime, onChange } = this.props;

    actualWorkTime[key] = null;
    onChange(actualWorkTime);
  }

  render() {
    const { actualWorkTime } = this.props;
    const { daysWeek } = this.state;

    return (
      <Fragment>
        {actualWorkTime && daysWeek.map((day, key) => {
          return (
            <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em', justifyContent: 'center' }}>
              <div style={{ width: '35px', fontWeight: '600' }}>
                {day}
              </div>
              <div>
                <Input
                  type='time'
                  name={`${day}-0`}
                  style={{ marginLeft: '1em' }}
                  defaultValue={actualWorkTime[key] && actualWorkTime[key][0] ? actualWorkTime[key][0] : ''}
                  onChange={this.onChange}
                  labelPosition="right"
                  label={{ basic: true, content: 'AM', style: { padding: '.78571429em', fontWeight: 600, backgroundColor: 'rgba(34, 36, 38, 0.15)' } }}
                />
                <Input
                  type='time'
                  name={`${day}-1`}
                  style={{ marginLeft: '1em' }}
                  defaultValue={actualWorkTime[key] && actualWorkTime[key][1] ? actualWorkTime[key][1] : ''}
                  onChange={this.onChange}
                  labelPosition="right"
                  label={{ basic: true, content: 'PM', style: { padding: '.78571429em', fontWeight: 600, backgroundColor: 'rgba(34, 36, 38, 0.15)' } }}
                />
              </div>
              <Button style={{ background: 'none' }} type="button" size="tiny" onClick={() => this.clearInputs(key)}><Icon name="close" /></Button>
            </div>
          )
        })}
      </Fragment>
    )
  }
}

export default WorkingTime;
