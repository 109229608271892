import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
} from 'utils/lib';

import Icon from 'components/Icons/Icon';
import styles from './Menu.scss';


const cx = classnames.bind(styles);

class MenuItem extends Component {
  handleClick = (e) => {
    const { disabled } = this.props;

    if (!disabled) _.invoke(this.props, 'onClick', e, this.props);
  };

  render() {
    const {
      active,
      children,
      className,
      color,
      content,
      disabled,
      fitted,
      header,
      icon,
      link,
      name,
      onClick,
      position,
    } = this.props;

    const classes = cx(
      color,
      position,
      useKeyOnly(active, 'active'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(icon === true || (icon && !(name || content)), 'icon'),
      useKeyOnly(header, 'header'),
      useKeyOnly(link, 'link'),
      useKeyOrValueAndKey(fitted, 'fitted'),
      'item',
      className
    );
    const ElementType = getElementType(MenuItem, this.props, () => {
      if (onClick) return 'a';
    });
    const rest = getUnhandledProps(MenuItem, this.props);

    if (!isNil(children)) {
      return (
        <ElementType {...rest} className={classes} onClick={this.handleClick}>
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType {...rest} className={classes} onClick={this.handleClick}>
        {Icon.create(icon, { autoGenerateKey: false })}
        {isNil(content) ? _.startCase(name) : content}
      </ElementType>
    );
  }
}

MenuItem.create = createShorthandFactory(MenuItem, val => ({ content: val, name: val }));

export default MenuItem;
