import React from 'react';
// import Image from 'components/Image';
// import Star from 'images/svg/stars';
import OnlineCard from 'images/svg/cards';

import Card from 'components/Card/Card';
import CardContent from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';
import CardDescription from 'components/Card/CardDescription';
import Image from 'components/Image/Image';
import Rating from 'components/Rating';

import { getDay } from 'services';
import convertTime from 'services/datetime';

import classnames from 'classnames/bind';
import styles from './StoreCard.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { infoCard, id, onClick, sameDay } = props;
  const workTime =
    infoCard && infoCard.actual_work_time.length > 0
      ? `${convertTime(infoCard.actual_work_time[1][0])} - ${convertTime(infoCard.actual_work_time[1][1])}`
      : '';
  return (
    <Card itemScope className={cx(sameDay ? 'fade-in-right' : 'fade-in-left', 'hover-animation', 'card-custom')} onClick={() => onClick(infoCard)}>
      {/* {infoCard.partner ? (
        <div className={cx('card-heally-express')}>
          <img src={require('images/heally_express.svg')} alt="" />
        </div>
      ) : null} */}
      <Image fluid src={infoCard.logo.default} alt={infoCard.name} className={cx('image-custom')} label={infoCard.partner ? { as: 'span', content: 'heally express', ribbon: true, style: { left: 'calc(-1rem)', top: '1.2em', position: 'absolute', background: '#fda285', color: 'white', textTransform: 'uppercase' } } : null} />
      <CardContent style={{ borderTop: 'none', display: 'flex', alignItems: 'center', minHeight: '58px' }} className={infoCard.works ? cx('card-description-enabled') : cx('card-description-disabled')}>
        <CardHeader style={{ color: '#363f48' }} className={cx('card-title')}>{infoCard.name}</CardHeader>
      </CardContent>
      <div style={{ paddingLeft: '1em', paddingRight: '1em' }} className={infoCard.works ? cx('card-description-enabled') : cx('card-description-disabled')}>
        <div style={{ paddingLeft: '1em', paddingRight: '1em', border: '1px solid #5595b0', boxShadow: '0 0 2px 0 #5595b0', opacity: 0.31, mixBlendMode: 'luminosity' }} />
      </div>
      <CardContent style={{ borderTop: 'none' }} className={infoCard.works ? cx('card-description-enabled') : cx('card-description-disabled')}>
        <div className={cx('time-block')}>
          {infoCard.works ? (
            <CardDescription>
              <div className={cx('title-open')}>open now</div>
              <div className={cx('time')}>{workTime}</div>
            </CardDescription>
          ) : (
              <CardDescription>
                <div className={cx('title-closed')}>closed</div>
                {workTime ? (
                  <div className={cx('time')}>{workTime}</div>
                ) : (
                    <div style={{ width: '140px', height: '21px' }} />
                  )}
              </CardDescription>
            )}
          {infoCard.delivery_time !== '' && (
            <div style={{ marginTop: '0.5em' }}>
              <div className={cx('title-delivery')}>Delivery time</div>
              <div className={cx('time')}>{infoCard.delivery_time}</div>
            </div>
          )}
        </div>
      </CardContent>
      <CardContent style={{ borderTop: 'none' }} className={infoCard.works ? cx('card-description-enabled') : cx('card-description-disabled')}>
        <div className={cx('card-footer')}>
          <Rating maxRating={5} icon="star" rating={5} disabled defaultRating={5} clearable={false} />
          {infoCard.card_payment ? <OnlineCard /> : <div />}
        </div>
      </CardContent>
    </Card>
  );
};
