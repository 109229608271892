import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Progress from 'components/Progress/Progress';
import Header from 'components/Typography/Header/Header';

const OPACITY = [1, 0.8, 0.6, 0.4, 0.2];

class Reviews extends Component {
  state = {};

  render() {
    const { reviews } = this.props;
    return (
      <div style={{ marginTop: '1.5em' }}>
        <Header as="h3">
          Reviews
        </Header>
        <Grid style={{ marginBottom: '0em' }}>
          {Object.keys(reviews).sort().reverse().map((review, key) => {
            return (
              <GridRow style={{ paddingBottom: '0em', paddingTop: key > 0 && '0em' }} key={key}>
                <GridColumn width={2} textAlign="center">
                  <span style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)' }}>{review}</span>
                </GridColumn>
                <GridColumn width={14} verticalAlign="middle">
                  <Progress size="small" percent={reviews[review] * 10} color="teal" style={{ background: 'none', margin: '0em', opacity: OPACITY[key] }} />
                </GridColumn>
              </GridRow>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default Reviews;
