export const FETCH_CART_INFO = 'FETCH_CART_INFO';
export const FETCH_CART_INFO_SUCCESS = 'FETCH_CART_INFO_SUCCESS';
export const FETCH_CART_INFO_FAIL = 'FETCH_CART_INFO_FAIL';

export const FETCH_CART_PRODUCTS = 'FETCH_CART_PRODUCTS';
export const FETCH_CART_PRODUCTS_SUCCESS = 'FETCH_CART_PRODUCTS_SUCCESS';
export const FETCH_CART_PRODUCTS_FAIL = 'FETCH_CART_PRODUCTS_FAIL';

export const INITIALIZE_CART = 'INITIALIZE_CART';
export const INITIALIZE_CART_SUCCESS = 'INITIALIZE_CART_SUCCESS';
export const INITIALIZE_CART_FAIL = 'INITIALIZE_CART_FAIL';

export const RESET_CART = 'RESET_CART';
export const RESET_CART_SUCCESS = 'RESET_CART_SUCCESS';
export const RESET_CART_FAIL = 'RESET_CART_FAIL';

export const ADD_TO_CART_PRODUCT = 'ADD_TO_CART_PRODUCT';
export const ADD_TO_CART_PRODUCT_SUCCESS = 'ADD_TO_CART_PRODUCT_SUCCESS';
export const ADD_TO_CART_PRODUCT_FAIL = 'ADD_TO_CART_PRODUCT_FAIL';

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';

export const CART_CONFLICT = 'CART_CONFLICT';

export const CART_PICK = 'CART_PICK';
export const CART_PICK_SUCCESS = 'CART_PICK_SUCCESS';
export const CART_PICK_FAIL = 'CART_PICK_FAIL';

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';

export const SET_ORDER_NOTES = 'SET_ORDER_NOTES';

export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';

export const SET_USER_INFO = 'SET_USER_INFO';

export const SET_TOTAL_QUANTITY = 'SET_TOTAL_QUANTITY';

export const APPLY_DISCOUNT_CODE = 'APPLY_DISCOUNT_CODE';
export const APPLY_DISCOUNT_CODE_SUCCESS = 'APPLY_DISCOUNT_CODE_SUCCESS';
export const APPLY_DISCOUNT_CODE_FAIL = 'APPLY_DISCOUNT_CODE_FAIL';

export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const ANIMATION_FRAME = 'ANIMATION_FRAME';

export const RESET_INFO_CART = 'RESET_INFO_CART';

export const FETCH_SHIPPING_COST_INFO = 'FETCH_SHIPPING_COST_INFO';
export const FETCH_SHIPPING_COST_INFO_SUCCESS = 'FETCH_SHIPPING_COST_INFO_SUCCESS';
export const FETCH_SHIPPING_COST_INFO_FAIL = 'FETCH_SHIPPING_COST_INFO_FAIL';

export const RESET_STATE_PRICES_AND_COSTS = 'RESET_STATE_PRICES_AND_COSTS';

export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

export const SET_LOCALLY_DELIVERY_ADDRESS = 'SET_LOCALLY_DELIVERY_ADDRESS';
export const SET_DELIVERY_ADDRESS_WITH_ZIP = 'SET_DELIVERY_ADDRESS_WITH_ZIP';

export const RESET_CART_WITH_PRODUCTS = 'RESET_CART_WITH_PRODUCTS';
