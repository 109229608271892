import _ from 'lodash';
import { isBrowser } from 'utils/lib';

const getNodeFromProps = props => {
  const { node } = props;

  if (isBrowser()) {
    if (_.isNil(node)) return document.body;
    return node;
  }
};

export default getNodeFromProps;
