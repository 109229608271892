import React, { Component } from 'react';

import Minus from 'images/svg/minus';
import Plus from 'images/svg/plus';

import classnames from 'classnames/bind';
import styles from './PiecePacking.module.scss';

const cx = classnames.bind(styles);

class PiecePacking extends Component {
  state = {
    price: this.props.price,
    counter: this.props.itemProduct ? parseInt(this.props.itemProduct.quantity) : 1,
    danger: false,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.itemProduct &&
      this.props.itemProduct &&
      this.props.itemProduct.quantity != parseInt(nextProps.itemProduct.quantity)
    ) {
      this.setState({
        counter: parseInt(nextProps.itemProduct.quantity),
      });
    }
  }

  onPlus = () => {
    const { setProduct } = this.props;
    setProduct(this.state.counter + 1);
    this.setState({
      counter: this.state.counter + 1,
      danger: false,
    });
  };

  onMinus = () => {
    const { setProduct, closeCardBuy } = this.props;

    if (this.state.counter > 1) {
      setProduct(this.state.counter - 1);
      this.setState({
        counter: this.state.counter - 1,
      });
    } else {
      closeCardBuy();
    }
  };

  render() {
    const { packing } = this.props;
    let danger = this.state.danger;

    if (this.state.counter === 1) {
      danger = true;
    }
    return (
      <div className={cx('content-block')}>
        <div className={cx('counter-measure')}>
          <button className={cx('button')} onClick={() => this.onMinus(this.state.lastStep)}>
            <div className={cx('container-button')}>
              <Minus style={danger ? { fill: 'rgb(253, 161, 133)' } : null} />
            </div>
          </button>

          <div className={cx('measure')}>{this.state.counter}</div>

          <button className={cx('button')} onClick={() => this.onPlus(this.state.lastStep)}>
            <div className={cx('container-button')}>
              <Plus />
            </div>
          </button>
        </div>
        <div className={cx('cost')}>
          $&nbsp;{Math.round(parseFloat(this.state.price) * this.state.counter) === 0
            ? parseFloat(this.state.price.toFixed(2)) * this.state.counter
            : Math.round(parseFloat(this.state.price) * this.state.counter)}
        </div>
      </div>
    );
  }
}

export default PiecePacking;
