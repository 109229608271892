export const FETCH_LIST_MARKERS = 'FETCH_LIST_MARKERS';
export const FETCH_LIST_MARKERS_SUCCESS = 'FETCH_LIST_MARKERS_SUCCESS';
export const FETCH_LIST_MARKERS_FAIL = 'FETCH_LIST_MARKERS_FAIL';

export const TOGGLE_MARKER_INFO = 'TOGGLE_MARKER_INFO';

export const FETCH_NEW_LIST_MARKERS = 'FETCH_NEW_LIST_MARKERS';
export const FETCH_NEW_LIST_MARKERS_SUCCESS = 'FETCH_NEW_LIST_MARKERS_SUCCESS';
export const FETCH_NEW_LIST_MARKERS_FAIL = 'FETCH_NEW_LIST_MARKERS_FAIL';

export const SET_INFOBOX_POSITION = 'SET_INFOBOX_POSITION';

export const CLEAR_FIELD_ADDRESS = 'CLEAR_FIELD_ADDRESS';

export const SET_ADDRESS = 'SET_ADDRESS';

export const SET_GEOCODE_BY_ADDRESS = 'SET_GEOCODE_BY_ADDRESS';

export const DENIED_GEOLOCATION = 'DENIED_GEOLOCATION';
export const PROMPT_GEOLOCATION = 'PROMPT_GEOLOCATION';

export const FILTER_STORES_SUPPORT_CARD_PICK_UP =
  'FILTER_STORES_SUPPORT_CARD_PICK_UP';

export const GET_CURRENT_GEOLOCATION = 'GET_CURRENT_GEOLOCATION';
export const GET_CURRENT_GEOLOCATION_SUCCESS =
  'GET_CURRENT_GEOLOCATION_SUCCESS';
export const GET_CURRENT_GEOLOCATION_FAIL = 'GET_CURRENT_GEOLOCATION_FAIL';
