export const getGeography = state => state;

export const getInfoBoxPosition = state => state.infoBoxPosition;

export const getViewInfoBox = state => state.viewInfoBox;

export const getGeocodeData = state => state.geocodeData;

export const getAddress = state => state.address;

export const getFetchingCurrentPosition = state =>
  state.isFetchingCurrentPosition;
