import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import classnames from 'classnames/bind';

import Card from 'components/Card/Card';
import Icon from 'components/Icons/Icon';

import styles from './Card.module.scss';

const cx = classnames.bind(styles);

const CardWrapper = ({ title, price, metricChange, colorArea, up, data }) => {
  return (
    <Card className={cx('card-container')}>
      <div className={cx('card-stacked')}>
        <div className={cx('card-metrics')}>
          <div className={cx('card-metric')}>
            <div className={cx('card-metric-title')}>{title}</div>
            <div className={cx('card-metric-value')}>{price}</div>
            <div style={{ color: up ? '#00b5ad' : 'red' }} className={cx('card-metric-change')}>
              <Icon name={up ? 'angle up' : 'angle down'} color={up ? "#00b5ad" : "red"} /> {metricChange}%
                      </div>
          </div>
        </div>
      </div>
      <div className={cx('card-chart')}>
        <AreaChart
          width={210.5}
          height={100}
          data={data}
          margin={{
            top: 5, right: 0, left: 0, bottom: 0,
          }}
        >
          <Area type="monotone" dataKey="uv" stroke={colorArea || '#8884d8'} fill={colorArea || '#8884d8'} />
        </AreaChart>
      </div>
    </Card>
  )
}

export default CardWrapper
