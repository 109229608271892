import React from 'react';
import { isForwardRef } from 'react-is';

import RefForward from './RefForward';
import RefFindNode from './RefFindNode';

const Ref = (props) => {
  const { children, innerRef } = props;

  const child = React.Children.only(children);
  const ElementType = isForwardRef(child) ? RefForward : RefFindNode;

  return <ElementType innerRef={innerRef}>{child}</ElementType>;
};

export default Ref;
