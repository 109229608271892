import React from 'react';

import classnames from 'classnames/bind';
import styles from './Tab.module.scss';

const cx = classnames.bind(styles);

const Tab = props => {
  return (
    <div className={cx('tab', props.isActive ? 'tab-active' : '')}>
      <a
        className={cx('tab-link')}
        onClick={e => {
          e.preventDefault();
          props.onClick(props.tabIndex);
        }}
      >
        {props.name}
      </a>
    </div>
  );
};

export default Tab;
