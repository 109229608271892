import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useTextAlignProp,
  useValueAndKey,
} from 'utils/lib';
import styles from './Segment.css';

const cx = classnames.bind(styles);

const Segment = (props) => {
  const {
    attached,
    basic,
    children,
    circular,
    className,
    clearing,
    color,
    compact,
    content,
    disabled,
    floated,
    inverted,
    loading,
    placeholder,
    padded,
    piled,
    raised,
    secondary,
    size,
    stacked,
    tertiary,
    textAlign,
    vertical,
  } = props;

  const classes = cx(
    'ui',
    color,
    size,
    useKeyOnly(basic, 'basic'),
    useKeyOnly(circular, 'circular'),
    useKeyOnly(clearing, 'clearning'),
    useKeyOnly(compact, 'compact'),
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(loading, 'loading'),
    useKeyOnly(placeholder, 'placeholder'),
    useKeyOnly(piled, 'piled'),
    useKeyOnly(raised, 'raised'),
    useKeyOnly(secondary, 'secondary'),
    useKeyOnly(stacked, 'stacked'),
    useKeyOnly(tertiary, 'tertiary'),
    useKeyOnly(vertical, 'vertical'),
    useKeyOrValueAndKey(attached, 'attached'),
    useKeyOrValueAndKey(padded, 'padded'),
    useTextAlignProp(textAlign),
    useValueAndKey(floated, 'floated'),
    'segment',
    className
  );

  const rest = getUnhandledProps(Segment, props);
  const ElementType = getElementType(Segment, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

Segment.propTypes = {
  raised: PropTypes.bool,
};

export default Segment;
