import React from 'react';
import classnames from 'classnames/bind';
import ReactSwipeEvents from 'react-swipe-events';

import Divider from 'components/Divider';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';

import styles from './Sidebar.module.scss';

const cx = classnames.bind(styles);

const Sidebar = ({ handleClick, open, linksName, handleClickItem, storeInformation, onSignOut }) => {
  return (
    <ReactSwipeEvents onSwipedLeft={handleClick}>
      <div className={cx('sidebar', open && 'sidebar-open')}>
        <div style={{ marginTop: '1rem' }}>
          <span className={cx('menu-item')} onClick={() => handleClickItem('store_info')}>
            <div className={cx('menu-item-name')}>
              {storeInformation && storeInformation.name}
            </div>
          </span>
        </div>
        <Divider />
        {linksName && Object.keys(linksName).map((link, index) => (
          <div key={index}>
            <span className={cx('menu-item')} onClick={() => handleClickItem(link)}>
              <div className={cx('menu-item-name')}>
                <Icon name={linksName[link].icon} size="large" />
                &nbsp;&nbsp;
                {linksName[link].name}
              </div>
            </span>
          </div>
        ))}
        <Divider />
        <div style={{ padding: '0 16px' }}>
          <Button fluid type="button" color="teal" onClick={onSignOut}>Log Out</Button>
        </div>
      </div>
    </ReactSwipeEvents>
  )
}

export default Sidebar;
