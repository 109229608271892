import { request } from 'services/api';
import * as constants from './constants';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { getLocallyDeliveryAddress } from 'services';
import { generateQuery } from 'services/api';
import notification from 'components/Notification';

import { fetchShippingCostInfo } from '../stores/actions';

const WHITE_LIST = {
  staging: 'staging.heallybuy.com',
  preprod: 'preprod.heallybuy.com',
};

async function getPostalCode(results) {
  const data = await getLatLng(results[0]);

  const geocoder = window.google && new window.google.maps.Geocoder();

  return new Promise(resolve => {
    geocoder.geocode({ location: data }, (geocodeResult) => {
      const geoData = geocodeResult.find(value => value.types.indexOf('postal_code') >= 0);
      if (geoData) {
        resolve({
          zip: generateQuery('postal_code', geoData),
          addressWithZipForInputCart: geoData.formatted_address,
        })
      }
    })
  })
}

async function getLocation(results) {
  const locality = await generateQuery('sublocality_level_1', results[0], false);
  const city = await generateQuery('locality', results[0], false);
  const county = await generateQuery('administrative_area_level_2', results[0]);
  const state = await generateQuery('administrative_area_level_1', results[0]);
  const country = await generateQuery('country', results[0]);

  const location = [locality, city, county, state, country].join(',').replace(/^,+/, ',');

  return location;
}

const checkAddress = (host) => {
  const splitHost = host.split('.');
  const addr = splitHost.filter(v => WHITE_LIST[v]);

  if (addr.length > 0) {
    return [...new Set(addr)][0];
  }

  return null;
};

const setBrand = brand => (dispatch) => {
  dispatch({
    type: [constants.SET_BRAND],
  });

  request(`/api/v1/brand_info?short_name=${brand}`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.SET_BRAND_SUCCESS],
          brand: json.data,
        });
      } else {
        dispatch({
          type: [constants.SET_BRAND_FAIL],
        });

        const getAddress = checkAddress(window.location.host);

        if (getAddress) {
          window.location.hostname = WHITE_LIST[getAddress];
        } else {
          window.location.hostname = 'heallybuy.com';
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.SET_BRAND_FAIL],
      });
      throw err;
    });
};

export const setChildrenComponent = childrenComponentForEmptyPage => (dispatch) => {
  dispatch({
    type: [constants.SET_CHILDREN_COMPONENT_FOR_EMPTY_PAGE],
    childrenComponentForEmptyPage,
  });
};

export const fetchNotFoundCode = () => (dispatch) => {
  request('/empty');
};

export const setNameCategoryForHeaderButton = name => (dispatch) => {
  dispatch({
    type: [constants.SET_NEW_CATEGORY_FOR_HEADER_BUTTON],
    name,
  });
};

export const setDeliveryAddress = (deliveryAddress) => async (dispatch) => {
  dispatch({
    type: [constants.SET_DELIVERY_ADDRESS],
  })

  try {
    const results = await geocodeByAddress(deliveryAddress)
    let zip = generateQuery('postal_code', results[0]);
    const location = await getLocation(results);
    const streetNameForInputCart = getLocallyDeliveryAddress(deliveryAddress);
    let dataWithZipCode = await getPostalCode(results);

    if (!dataWithZipCode) {
      dataWithZipCode = {
        zip,
        addressWithZipForInputCart: results[0].formatted_address
      }
    }

    dispatch({
      type: [constants.SET_DELIVERY_ADDRESS_SUCCESS],
      payload: {
        enteringAddress: deliveryAddress,
        location,
        streetNameForInputCart,
        addressWithZipForInputCart: dataWithZipCode.addressWithZipForInputCart,
        addressForQuery: `${location}&zip=${dataWithZipCode.zip}`,
        zip: dataWithZipCode.zip,
      }
    })
  } catch (err) {
    dispatch({
      type: [constants.SET_DELIVERY_ADDRESS_FAIL],
    })
  }
}

export const changeDeliveryAddress = (address, field, event, storeInfo) => async (dispatch) => {
  dispatch({
    type: [constants.CHANGE_DELIVERY_ADDRESS],
  });
  try {
    if (event === 'change') {
      dispatch({
        type: [constants.CHANGE_DELIVERY_ADDRESS_SUCCESS],
        value: address,
        field
      })
    }

    if (event === 'select') {
      dispatch({
        type: [constants.SELECT_DELIVERY_ADDRESS]
      })
      const results = await geocodeByAddress(address);
      const location = await getLocation(results);

      if (!results || !location) {
        dispatch({
          type: [constants.SELECT_DELIVERY_ADDRESS_FAIL]
        });
        notification.error({
          message: 'Warning',
          description: "Can't process your address."
        })
      } else {
        const dataWithZipCode = await getPostalCode(results);
        const addressForQuery = `${location}&zip=${dataWithZipCode.zip}`;

        dispatch({
          type: [constants.SELECT_DELIVERY_ADDRESS_SUCCESS],
          value: dataWithZipCode.addressWithZipForInputCart,
          location,
          addressForQuery,
          field
        })

        if (storeInfo) {
          dispatch(fetchShippingCostInfo(storeInfo, addressForQuery));
        }
      }
    }

  } catch (err) {
    dispatch({
      type: [constants.CHANGE_DELIVERY_ADDRESS_FAIL],
    })
  }
}

export const setAuthPosition = (type) => dispatch => {
  dispatch({
    type,
  })
}

export { setBrand as default };
