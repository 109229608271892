import React from 'react';

import classnames from 'classnames/bind';
import styles from './Background.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const brandName = props.brandInformation.brand;

  return (
    <div className={cx('background-container')}>
      <div
        className={cx('background-image')}
        style={
          brandName
            ? { backgroundImage: brandName.background ? `url(${brandName.background})` : 'none' }
            : null
        }
      />
      <div
        className={cx('background-shadow')}
        style={brandName ? { background: brandName.background ? 'black' : 'white' } : null}
      />
    </div>
  );
};
