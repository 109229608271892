import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import classnames from 'classnames/bind';

import useWindowSize from 'react-use/lib/useWindowSize';

import Loader from 'components/Loaders/Loader';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Header from 'components/Typography/Header/Header';
import Segment from 'components/Segment/Segment';
import Checkbox from 'components/CheckBox/Checkbox';
import NewProductCard from 'components/NewProductCard';

import ProductCard from 'components/ProductCard';
import CardBuy from 'components/ProductCard/CardBuy';
import LoadingProducts from 'components/Loaders/Products';
import LoadingCategories from 'components/Loaders/Categories';
import LoadingRemedies from 'components/Loaders/Remedies';

import NextArrowButton from 'routes/routes/Products/components/Products/components/NextArrow/NextArrowButton';
import PrevArrowButton from 'routes/routes/Products/components/Products/components/PrevArrow/PrevArrowButton';

import SliderCategories from 'routes/routes/Products/components/Products/components/SliderCategories';
import SliderRemedies from 'routes/routes/Products/components/Products/components/SliderRemedies';

import debounce from 'services/debounce';
import throttle from 'services/throttle';

import { useMessage } from 'hooks/message.hook';

import styles from './Products.module.scss';

const cx = classnames.bind(styles);

const Products = ({
  fetchListProducts,
  products,
  initializeCart,
  totalQuantity,
  addToCartProduct,
  itemsProducts,
  history,
  domainPath,
  setLinkWithProducts,
  onBuyProduct,
  storeId,
  listCategories,
  remediesData,
  theme,
  location,
  handleClickRemedy
}) => {
  const [triggerProductDetails, setTriggerProductDetails] = useState(false);
  const [newProductCard, setNewProductCard] = useState(false);
  const [preButtonClassName, setPreButtonClassName] = useState('scale-out-center');
  const [nextButtonClassName, setNextButtonClassName] = useState('scale-in-center');
  const [toggleRemedy, setToggleRemedy] = useState(true);
  const [activeRemedy, setActiveRemedy] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const message = useMessage();
  const { search, pathname } = window.location;
  const { store, order_type } = queryString.parse(search);
  const { height } = useWindowSize();
  const categoryContainer = useRef();
  const devicePrefix = theme ? theme.device : 'desktop';

  let viewCategories;
  let viewRemedies;

  useEffect(() => {
    setLinkWithProducts(`${pathname}${search}`);
    fetchListProducts(store, null, null, null);
  }, [])

  const onBuy = async (product, quantity, categoryId) => {
    const hasConflictStores = storeId !== parseInt(store) && totalQuantity !== 0;

    if (hasConflictStores) {
      message('Error', 'You have pending purchase in another store!', 5, 'error');
    } else {
      if (totalQuantity === 0) {
        try {
          await initializeCart(order_type, store);
          addToCartProduct(product, quantity)
          onBuyProduct(categoryId, product.id);

        } catch (error) {
          message('Error', error.message, 5, 'error');
        }
      } else {
        addToCartProduct(product, quantity);
        onBuyProduct(categoryId, product.id);
      }
    }
  }

  const onCloseCardBuy = (categoryId, product) => {
    let reset = false;
    if (totalQuantity === 1) {
      reset = true;
    }

    addToCartProduct(product, 0, reset)
  }

  const openProductDeatails = (categoryId, card) => {
    setTriggerProductDetails(true);
  }

  const goToCart = async () => {
    history.push(`${domainPath}doctor/cart`);
  }

  const scrollToDirection = (direction, id) => {
    let widthContainer = 600;
    const element = document.getElementById(id);

    if (height <= 750 && !window.isTablet) {
      widthContainer = 792;
    }

    const far = widthContainer * direction;
    const pos = element.scrollLeft + far;

    if (element.scroll) {
      element.scroll({ left: pos, behavior: 'smooth' });
    } else {
      element.scrollLeft = pos;
    }
  }

  const scrollToTop = () => {
    let timeOut = null;
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollBy(0, -50);
      timeOut = setTimeout(() => scrollToTop(), 10);
    }
    clearTimeout(timeOut);
  }

  const encodeQueryData = (data) => {
    const ret = [];
    for (const d in data) ret.push(`${d}=${data[d]}`);
    return ret.join('&');
  };

  const clickRemedy = (remedy) => {
    const remedyLocation = queryString.parse(location.search);
    const pathname = `${location.pathname}`;

    if (remedyLocation.remedy == remedy.id) {
      delete remedyLocation.remedy;
    } else {
      remedyLocation.remedy = remedy.id;
    }

    const encodeQuery = encodeQueryData(remedyLocation);

    history.replace(`${pathname}?${encodeQuery}`);

    handleClickRemedy(remedy);
  };

  const debouceScrollToTop = debounce((remedy) => {
    clickRemedy(remedy);
    scrollToTop();
    setToggleRemedy(true);
  }, 250)

  const handleToggleRemedy = (remedy, toggle) => {
    setToggleRemedy(false);
    debouceScrollToTop(remedy);
  };

  const handleScroll = throttle((id) => {
    const element = document.getElementById(id);
    const scrollLeft = Math.ceil(element.scrollLeft);
    const widthContainer = element.scrollWidth - element.clientWidth;

    let nextButtonClassName = 'scale-in-center';
    let preButtonClassName = 'scale-in-center';

    if (scrollLeft === widthContainer || scrollLeft - 1 === widthContainer) {
      nextButtonClassName = 'scale-out-center';
    }

    if (scrollLeft === 0) {
      preButtonClassName = 'scale-out-center';
    }

    setNextButtonClassName(nextButtonClassName);
    setPreButtonClassName(preButtonClassName);
    setCategoryName(id);

  }, 100);

  if (products.isFetching && !listCategories) {
    viewCategories = <LoadingCategories />;
  } else if (listCategories && listCategories.length > 0) {
    viewCategories = (
      <SliderCategories
        name={listCategories}
        windowHeight={height}
        remedyActive={remediesData.remedyActive}
        toggleRemedy={toggleRemedy}
        handleToggle={handleToggleRemedy}
        categoryIndex={null}
        handleClickImageCategory={null}
        devicePrefix={devicePrefix}
      />
    );
  }

  if (!remediesData.filterRemedies) {
    viewRemedies = <LoadingRemedies />;
  } else if (remediesData.filterRemedies.length > 0) {
    viewRemedies = (
      <SliderRemedies
        windowHeight={height}
        remedies={remediesData.filterRemedies}
        handleClickRemedy={clickRemedy}
        devicePrefix={devicePrefix}
      />
    );
  }

  return (
    <Segment basic loading={products.isFetching} style={{ height: '100%' }}>
      <GridRow>
        <GridColumn width={16}>
          <div className={cx('doctor-categoryes-wrapper')}>
            {viewCategories}
          </div>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width={16}>
          <div className={cx('remedy-name-wrapper')}>{viewRemedies}</div>
        </GridColumn>
      </GridRow>
      <Grid>
        <GridRow>
          <GridColumn width={16} textAlign="center">
            <Header as="h1">Products</Header>
          </GridColumn>
        </GridRow>
        {/* <GridRow>
          <GridColumn width={16} textAlign="right">
            <Checkbox
              toggle
              checked={newProductCard}
              onClick={() => setNewProductCard(!newProductCard)}
              name="new_products"
              style={{ opacity: 1 }}
              label="Beta"
            />
          </GridColumn>
        </GridRow> */}
        {listCategories && listCategories.map(category => (
          <GridRow key={category.id}>
            <GridColumn width={16}>
              <Header style={{ color: '#128fbc' }} as="h2">{category.name}</Header>
            </GridColumn>
            <GridColumn width={16}>
              {
                !newProductCard ?
                  <div style={{ position: 'relative' }}>
                    <PrevArrowButton
                      id={category.name.toLowerCase()}
                      customClassName={cx(categoryName === category.name.toLowerCase() ? preButtonClassName : 'scale-out-center')}
                      visible={category.products.length < 3 || window.isTablet}
                      toScroll={direction => scrollToDirection(direction, category.name.toLowerCase())}
                    />
                    <div
                      style={
                        category.products.length < 4
                          || window.isTablet
                          || (nextButtonClassName === 'scale-out-center')
                          ? { opacity: 0 }
                          : null
                      }
                      className={cx('fade-to-white-right')}
                    />
                    <div style={preButtonClassName === 'scale-out-center' ? { opacity: 0 } : null} className={cx('fade-to-white-left')} />
                    <div
                      id={category.name.toLowerCase()}
                      style={{ overflowX: 'auto', overflowY: 'hidden', display: 'flex', width: '100%', position: 'relative' }}
                      onScroll={window.isTablet ? null : () => handleScroll(category.name.toLowerCase())}
                    >
                      {
                        category.products.map((product => (
                          <div className={cx('flip-card', itemsProducts[product.id] && 'flip-card-rotate')} key={product.id}>
                            <div className={cx('flip-card-inner', itemsProducts[product.id] && 'flip-card-inner-rotate')}>
                              <CardBuy
                                key={product.id}
                                card={product}
                                setProduct={onBuy}
                                onBuyNow={goToCart}
                                itemsProducts={itemsProducts}
                                closeCardBuy={(card) => onCloseCardBuy(category.id, card)}
                                openModal={(card) => console.log(card)}
                              />
                              <ProductCard
                                key={`original${product.id}`}
                                card={product}
                                categoriesId={category.id}
                                onClick={(card) => openProductDeatails(category.id, card)}
                                onBuy={(card, quantity) => onBuy(card, quantity, category.id)}
                                rating={null}
                              />
                            </div>
                          </div>
                        )))
                      }
                    </div>
                    <NextArrowButton
                      id={category.name.toLowerCase()}
                      customClassName={
                        category.products.length < 3 || window.isTablet
                          ? cx('scale-out-center')
                          : cx(categoryName === category.name.toLowerCase() && nextButtonClassName)
                      }
                      toScroll={direction => scrollToDirection(direction, category.name.toLowerCase())}
                    />
                  </div>
                  :
                  <div style={{ overflowX: 'auto', overflowY: 'hidden', display: 'flex', width: '100%', position: 'relative' }}>
                    {category.products.map((product => (
                      <div style={{ position: 'relative' }}>
                        <NewProductCard card={product} />
                      </div>
                    )))
                    }
                  </div>
              }
            </GridColumn>
          </GridRow>
        ))}
      </Grid>
    </Segment>
  )
}

export default Products;
