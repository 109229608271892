import React from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { openModal, successLogIn, brandStyles, devicePrefix, isFetchingUserInfo, goToAddress, domainPath } = props;

  return (
    <div className={cx('footer')}>
      <div className={cx('footer-links')}>
        <Button style={brandStyles} type="button" onClick={() => goToAddress(`${domainPath}privacy`)} basic className={cx('link', `link--${devicePrefix}`)} inverted={!brandStyles}>
          Privacy
        </Button>
        <Button style={brandStyles} type="button" onClick={() => goToAddress(`${domainPath}terms`)} basic className={cx('link', `link--${devicePrefix}`)} inverted={!brandStyles}>
          Terms
        </Button>
        <Button
          style={brandStyles}
          type="button"
          basic
          onClick={() => window.location.href = 'https://getheally.com/patients/news'}
          className={cx('link', `link--${devicePrefix}`)}
          inverted={!brandStyles}
        >
          Blog
        </Button>
      </div>
      {!isFetchingUserInfo ? (
        successLogIn ? null : (
          <Button
            style={brandStyles}
            className={cx('button', `button--${devicePrefix}`)}
            onClick={() => openModal('auth')}
            type="button"
            basic
            inverted={!brandStyles}
          >
            partner login
          </Button>
        )
      ) : null}
    </div>
  );
};
