import _ from 'lodash';

const doesNodeContainClick = (node, e) => {
  if (_.some([e, node], _.isNil)) return false;

  if (e.target) {
    _.invoke(e.target, 'setAttribute', 'data-suir-click-target', true);

    if (document.querySelector('[data-suir-click-target=true]')) {
      _.invoke(e.target, 'removeAttribute', 'data-suir-click-target');
      return node.contains(e.target);
    }
  }

  const { clientX, clientY } = e;

  if (_.some([clientX, clientY], _.isNil)) return false;

  const clientRects = node.getClientRects();

  if (!node.offsetWidth || !node.offsetHeight || !clientRects || !clientRects.length) return false;

  const { top, bottom, left, right } = _.first(clientRects);
  if (_.some([top, bottom, left, right], _.isNil)) return false;

  return _.inRange(clientY, top, bottom + 0.001) && _.inRange(clientX, left, right, +0.001);
};

export { doesNodeContainClick }
