import React, { Component, createRef } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  doesNodeContainClick,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useVerticalAlignProp,
} from 'utils/lib';

import styles from './Dimmer.scss';

const cx = classnames.bind(styles);

class DimmerInner extends Component {
  containerRef = createRef();

  contentRef = createRef();

  componentDidMount() {
    const { active } = this.props;

    this.toggleStyles(active);
  }

  componentDidUpdate(prevProps) {
    const { active: currentActive } = this.props;
    const { active: prevActive } = prevProps;

    if (prevActive !== currentActive) this.toggleStyles(currentActive);
  }

  toggleStyles = (active) => {
    const containerRef = this.containerRef.current;

    if (!containerRef) return;

    if (active) {
      containerRef.style.setProperty('display', 'flex', 'important');
    } else {
      containerRef.style.removeProperty('display');
    }
  };

  handleClick = (e) => {
    const contentRef = this.contentRef.current;

    _.invoke(this.props, 'onClick', e, this.props);

    if (contentRef && (contentRef !== e.target && doesNodeContainClick(contentRef, e))) return;

    _.invoke(this.props, 'onClickOutside', e, this.props);
  };

  render() {
    const {
      active,
      children,
      className,
      content,
      disabled,
      inverted,
      page,
      simple,
      verticalAlign,
    } = this.props;
    const activeClasses = ['active', 'transition', 'visible'];
    const classes = cx(
      'ui',
      useKeyOnly(active, { ...activeClasses }),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(page, 'page'),
      useKeyOnly(simple, 'simple'),
      useVerticalAlignProp(verticalAlign),
      'dimmer',
      className
    );
    const rest = getUnhandledProps(DimmerInner, this.props);
    const ElementType = getElementType(DimmerInner, this.props);
    const childrenContent = isNil(children) ? content : children;

    return (
      <ElementType {...rest} className={classes} onClick={this.handleClick} ref={this.containerRef}>
        {childrenContent && (
          <div className={cx('content')} ref={this.contentRef}>
            {childrenContent}
          </div>
        )}
      </ElementType>
    );
  }
}

export default DimmerInner;
