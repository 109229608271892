import * as constants from './constants';
import { request } from 'services/api';

export const fetchClientTokenForPaymentOnline = userId => (dispatch) => {
  dispatch({
    type: [constants.FETCH_CLIENT_TOKEN],
  });
  request('/api/v1/cards/client_token')
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.FETCH_CLIENT_TOKEN_SUCCESS],
          clientToken: json.data.token,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_CLIENT_TOKEN_FAIL],
      });
    });
};
