import React from 'react';

import classnames from 'classnames/bind';
import styles from './OrderProcess.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { name, enabledCar, enabledPoint, storeType } = props;
  return (
    <div className={cx('order-container')}>
      <div className={cx('main-container')}>
        <div className={cx('img-container')}>
          {enabledCar ? (
            <img
              src={
                storeType === 'delivery'
                  ? require('images/IconCar.svg')
                  : require('images/paper_bag.svg')
              }
            />
          ) : null}
        </div>
        <div className={enabledPoint ? cx('circle-green') : cx('circle-gray')} />
        <div
          style={enabledPoint ? { color: 'rgba(0,0,0,0.85)' } : null}
          className={cx('order-title', name === 'Shipped' && 'shipped-block')}
        >
          {name}
        </div>
      </div>
    </div>
  );
};
