export const getBrandInformation = state => state;

export const getIsFetchingBrand = state => state.isFetchingBrand;

export const getChildrenComponentForEmptyPage = state => state.childrenComponentForEmptyPage;

export const getNameCategoryForHeaderButton = state => state.nameCategoryForHeaderButton;

export const getDeliveryAddress = state => state.deliveryAddress;

export const getAuthPosition = state => state.authPosition;
