import React from 'react';
import Image from 'components/Image/Image';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Rating from 'components/Rating';

import classnames from 'classnames/bind';
import styles from './OrderInfo.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { onOrderAgain, product, order, indexPosition, onClick, handleRate, editComment } = props;
  const brand = product.brand ? `${product.brand}` : '';

  return (
    <Grid>
      <GridRow>
        <GridColumn computer={3} tablet={3} mobile={6}>
          <Image style={{ cursor: 'pointer' }} src={product.image && product.image.wide} onClick={onClick} />
        </GridColumn>
        <GridColumn computer={6} tablet={6} mobile={10}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div className={cx('product-desc')}>
              <div className={cx('product-name')} onClick={onClick}>
                {product.name}
              </div>
              <div className={cx('product-desc')}>{brand && `By ${brand}`}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {product.rate && <div className={cx('rate')}>{product.rate}</div>}
              <Rating disabled={product.rate} clearable size="large" icon="star" rating={product.rate || 0} maxRating={5} color="teal" onRate={(e, data) => handleRate(e, data)} />
              {product.rate && <div style={{ display: 'inline-block', marginLeft: '0.25em' }}><a className={cx('edit-comment-link')} onClick={editComment}>( edit )</a></div>}
            </div>
          </div>
        </GridColumn>
        <GridColumn computer={2} tablet={2} verticalAlign="middle" mobile={10} textAlign={window.isMobile && 'right'}>
          <span className={cx('quantity')}>
            {product.quantity}&nbsp;{product.packing === 'gram_oz' ? null : product.packing}
          </span>
        </GridColumn>
        <GridColumn computer={5} tablet={5} verticalAlign="middle" mobile={6}>
          <div className={cx('price')}>${product.price}</div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
