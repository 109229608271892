import React from 'react';
import ToggleButton from 'components/ToggleButton';

import classnames from 'classnames/bind';
import styles from './FilterBlock.module.scss';

const cx = classnames.bind(styles);

export default props => {
  const { handleChange, stateToggle } = props;
  return (
    <div className={cx('filter-container')}>
      <div className={cx('credit-card-container')}>
        <div className={cx('card-block')}>
          <div className={cx('card-title')}>Accepts credit card</div>
          <ToggleButton handleChange={handleChange} stateToggle={stateToggle} />
        </div>
        <div className={cx('card-image')}>
          <img src={require('images/creditCard.svg')} alt="" />
        </div>
      </div>
      <div className={cx('store-search')}>
        <input type="text" placeholder="Search" />
      </div>
    </div>
  );
};
