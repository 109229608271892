import { connect } from 'react-redux';

import { saveCart, eraseDataSaveCart, changePatientCartEmail } from 'modules/doctor/actions';
import { getCartData, getLinkWithProducts, getDoctorInfo } from 'modules/doctor/selectors';

import { fetchCartProducts, removeFromCart, addToCartProduct, fetchCartInfo, resetCart } from 'modules/cart/actions';
import { getItemsProducts, getCartProducts } from 'modules/cart/selectors';

import Cart from '../components';

const mapStateToProps = (state) => ({
  itemsProducts: getItemsProducts(state.cart),
  cart: getCartProducts(state.cart),
  dataSaveCart: getCartData(state.doctor),
  linkWithProducts: getLinkWithProducts(state.doctor),
  doctorInfo: getDoctorInfo(state.doctor),
})

export default connect(mapStateToProps, {
  fetchCartProducts,
  saveCart,
  eraseDataSaveCart,
  addToCartProduct,
  removeFromCart,
  fetchCartInfo,
  resetCart,
  changePatientCartEmail
})(Cart);
