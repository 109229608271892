import React from 'react';
import _ from 'lodash';

import { AutoControlledComponent as Component, getElementType, getUnhandledProps } from 'utils/lib';

import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';

import Menu from 'components/Menu/Menu';
import TabPane from './TabPane';


class Tab extends Component {
  static autoControlledProps = ['activeIndex'];

  static defaultProps = {
    grid: { paneWidth: 12, tabWidth: 4 },
    menu: { attached: true, tabular: true },
    renderActiveOnly: true,
  }

  getInitialAutoControlledState() {
    return { activeIndex: 0 };
  }

  handleItemClick = (e, { index }) => {
    _.invoke(this.props, 'onTabChange', e, { ...this.props, activeIndex: index });
    this.trySetState({ activeIndex: index });
  }

  renderVertical(menu) {
    const { grid, menuPosition } = this.props;
    const { paneWidth, tabWidth, ...gridProps } = grid;

    const position = menuPosition || (menu.props.tabular === 'right' && 'right') || 'left';

    return (
      <Grid {...gridProps}>
        {position === 'left'
          && GridColumn.create({ width: tabWidth, children: menu }, { autoGenerateKey: false })}
        {GridColumn.create(
          {
            width: paneWidth,
            children: this.renderItems(),
            stretched: true,
          },
          { autoGenerateKey: false },
        )}
        {position === 'right'
          && GridColumn.create({ width: tabWidth, children: menu }, { autoGenerateKey: false })}
      </Grid>
    );
  }

  renderMenu() {
    const { menu, panes, menuPosition } = this.props;
    const { activeIndex } = this.state;

    if (menu.tabular === true && menuPosition === 'right') {
      menu.tabular = 'right';
    }

    return Menu.create(menu, {
      autoGenerateKey: false,
      overrideProps: {
        items: _.map(panes, 'menuItem'),
        onItemClick: this.handleItemClick,
        activeIndex,
      },
    });
  }

  renderItems() {
    const { panes, renderActiveOnly } = this.props;
    const { activeIndex } = this.state;

    if (renderActiveOnly) return _.invoke(_.get(panes, `[${activeIndex}]`), 'render', this.props);
    return _.map(panes, ({ pane }, index) => TabPane.create(pane, {
      overrideProps: {
        active: index === activeIndex,
      },
    }));
  }

  render() {
    const menu = this.renderMenu();
    const rest = getUnhandledProps(Tab, this.props);
    const ElementType = getElementType(Tab, this.props);
    const classes = this.props.className || {};

    if (menu.props.vertical) {
      return <ElementType {...rest}>{this.renderVertical(menu)}</ElementType>;
    }

    return (
      <ElementType className={classes}>
        {menu.props.attached !== 'bottom' && menu}
        {this.renderItems()}
        {menu.props.attached === 'bottom' && menu}
      </ElementType>
    );
  }
}

export default Tab;
