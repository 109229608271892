import React from 'react';
import ContentLoader from 'react-content-loader';

import classnames from 'classnames/bind';
import styles from './Remedies.module.scss';

const cx = classnames.bind(styles);

const Remedies = () => (
  <div className={cx('remedies')}>
    <div style={{ width: '476px', height: '119px' }}>
      <ContentLoader animate={false}>
        <circle cx="50" cy="40" r="38" />
        <circle cx="150" cy="40" r="38" />
        <circle cx="250" cy="40" r="38" />
        <circle cx="350" cy="40" r="38" />
      </ContentLoader>
    </div>
  </div>
);

export default Remedies;
