import React, { Component } from 'react';
import _ from 'lodash';
import { createPortal } from 'react-dom';

import { isBrowser } from 'utils/lib';
import Ref from '../Ref/Ref';

class PortalInner extends Component {
  componentDidMount() {
    _.invoke(this.props, 'onMount', null, { ...this.props, node: this.ref });
  }

  componentWillUnmount() {
    _.invoke(this.props, 'onUnmount', null, { ...this.props, node: this.ref });
  }

  handleRef = c => (this.ref = c);

  render() {
    const { children, mountNode = isBrowser() ? document.body : null } = this.props;

    return createPortal(<Ref innerRef={this.handleRef}>{children}</Ref>, mountNode);
  }
}

export default PortalInner;
