import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import ModalActions from 'components/ModalWindow/ModalActions';

import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';

import debounce from 'services/debounce';

import Packing from './components/Packing';

import styles from './AddProduct.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '37.5%',
  fontWeight: '700',
  color: 'rgba(0,0,0,.87)',
  fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
};

class AddProduct extends Component {
  state = {
    hideAnotherDropdown: false,
    formattedListProducts: this.props.formattedListProducts,
  }

  componentDidMount() {
    const { fetchListBrands } = this.props;
    fetchListBrands();
  }

  onOpen = (e, data) => {
    this.setState({
      hideAnotherDropdown: true,
    });
  }

  onClose = (e, data) => {
    this.setState({
      hideAnotherDropdown: false,
    });
  }

  onChooseCategory = (e, data) => {
    const { listCategories, product } = this.props;
    if (data.value) {
      const category = listCategories.filter(v => v.value === data.value);
      this.setState({
        category: category[0],
      }, () => {
        this.handleSearchChange(e, product ? product.name : '');
      });
    }
  }

  onChooseBrand = (e, data) => {
    const { formattedListBrands } = this.props;
    if (data.value && data.value.length > 0) {
      const brand = formattedListBrands.filter(v => v.value === data.value);

      this.setState({
        brand: brand[0],
        product: null,
      }, () => {
        this.handleSearchChange(e, '');
      });
    }
  }

  onChooseProduct = (e, data) => {
    const { formattedListProducts } = this.props;
    this.setState({
      product: null,
    }, () => {
      if (data) {
        const product = data.options.filter(v => v.value === data.value);

        this.setState({
          product: product[0],
        });
      }
    })
  }

  closeBrandsInput = () => {
    this.setState({
      inputBrands: true,
    });
  }

  openBrandsInput = () => {
    this.setState({
      inputBrands: false,
    });
  }

  addProduct = () => {
    const { product, brand, category, formattedListProducts } = this.state;
    const { addProduct, formattedListBrands, listCategories, searchOfListProducts, closeModalModalWithAddProduct } = this.props;

    if (this.product) {
      const selectFromListBrands = brand && formattedListBrands.filter(v => v.id === brand.id);
      const selectFromListCategory = category && listCategories.filter(v => v.id === category.id);
      let { price } = this.product;

      if (this.product.packing === 'piece_discrete') {
        let piecePrice = []
        Object.keys(this.product.price).forEach((v, i) => {
          piecePrice.push(this.product.price[v]);
        })

        price = piecePrice;
      } else if (this.product.packing === 'piece') {
        price = this.product.price.piece;
      }

      addProduct(this.product.id, JSON.stringify(price), selectFromListBrands && selectFromListBrands[0] ? selectFromListBrands[0].id : '', selectFromListCategory && selectFromListCategory[0] ? selectFromListCategory[0].id : '')
        .then(() => {
          this.setState({
            isSuccessAdd: true,
          }, () => {
            clearTimeout(this.counter);
            this.counter = setTimeout(() => {
              closeModalModalWithAddProduct();
            }, 1500)
          })
        })
    }
  }

  onChangeInput = (e, data) => {
    const { product } = this.state;

    this.product = product;
    if (!this.product.price) {
      this.product.price = {};
    }

    this.product.price[data.name] = data.value;
  }

  handleSearchChange = debounce((e, { searchQuery }) => {
    const { category, brand } = this.state;
    const { searchOfListProducts } = this.props;

    const brandId = brand ? brand.id : '';
    const categoryId = category ? category.id : '';

    searchOfListProducts(searchQuery || '', brandId, categoryId);
  }, 500)

  render() {
    const { open, closeModalModalWithAddProduct, listCategories, formattedListBrands, formattedListProducts, isSearchingProduct, isSuccessAddProduct } = this.props;
    const { hideAnotherDropdown, inputBrands, product, isSuccessAdd } = this.state;

    return (
      <Modal
        open={open}
        onClose={closeModalModalWithAddProduct}
        closeIcon={<Icon name="close" link onClick={closeModalModalWithAddProduct} />}
        size="tiny"
      >
        <ModalHeader>Add product</ModalHeader>
        <ModalContent>
          <h4 className={cx(isSuccessAdd ? 'slide-in-right' : 'slide-out-right')} style={{ color: 'green', textAlign: 'center' }}>{isSuccessAdd ? 'Success' : null}</h4>
          <ModalDescription>
            <Form loading={isSuccessAddProduct}>
              <FormField>
                <label style={labelStyle}>Category</label>
                <Dropdown
                  tabIndex="0"
                  role="listbox"
                  placeholder="Select category"
                  fluid
                  selection
                  search
                  options={listCategories}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  onChange={this.onChooseCategory}
                  selectOnBlur={false}
                />
              </FormField>
              <FormField>
                <label style={labelStyle}>Brand</label>
                <Dropdown
                  loading={formattedListBrands.length === 0}
                  disabled={formattedListBrands.length === 0}
                  style={hideAnotherDropdown ? { visibility: 'hidden' } : { visibility: 'visible' }}
                  placeholder="Select brand"
                  role="listbox"
                  tabIndex="1"
                  fluid
                  search
                  selection
                  options={formattedListBrands}
                  noResultsMessage="Try another search."
                  onOpen={this.closeBrandsInput}
                  onClose={this.openBrandsInput}
                  onChange={this.onChooseBrand}
                  lazyLoad
                  selectOnBlur={false}
                />
              </FormField>
              <FormField>
                <label style={labelStyle}>Product</label>
                <Dropdown
                  style={inputBrands || hideAnotherDropdown ? { visibility: 'hidden' } : { visibility: 'visible' }}
                  placeholder="Select product"
                  role="listbox"
                  tabIndex="2"
                  fluid
                  search
                  selection
                  options={formattedListProducts}
                  noResultsMessage="Try another search."
                  onOpen={this.handleSearchChange}
                  onChange={this.onChooseProduct}
                  onSearchChange={this.handleSearchChange}
                  loading={isSearchingProduct}
                  selectOnBlur={false}
                />
              </FormField>
            </Form>
          </ModalDescription>
          <ModalDescription>
            {product && product.packing
              && <Packing product={product} onChangeInput={this.onChangeInput} />
            }
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button primary type="button" onClick={this.addProduct}>add</Button>
        </ModalActions>
      </Modal>
    );
  }
}

export default AddProduct;
