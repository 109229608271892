import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Input from 'components/Input/Input';
import Checkbox from 'components/CheckBox/Checkbox';

import styles from './Piece.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '50%',
  fontWeight: '700',
  color: 'rgba(0,0,0,.87)',
  fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
};

class Piece extends Component {
  state = {
    checked: this.props.outOfStock
  }

  onChange = (e, field) => {
    const { onChange } = this.props;
    const { value, name } = field;

    if (value.length === 0) {
      this.setState({
        [name]: true,
      });
      return null;
    }
    onChange(value, name, 'piece');
  }

  onToggle = () => {
    const { toggleOutOfStock } = this.props;
    this.setState({
      checked: !this.state.checked,
    }, () => {
      toggleOutOfStock(this.state.checked);
    })
  }

  render() {
    const { price } = this.props;
    const { checked } = this.state;

    return (
      <Grid>
        <GridRow>
          <GridColumn width={8} style={{ width: '50%' }}>
            <label style={labelStyle}>out of stock</label>
          </GridColumn>
          <GridColumn width={8} style={{ width: '50%' }}>
            <Checkbox checked={checked} onClick={this.onToggle} />
          </GridColumn>
        </GridRow>
        <GridRow style={{ alignItems: 'center' }}>
          <GridColumn
            width={8}
            style={labelStyle}
          >
            Price per piece
          </GridColumn>
          <GridColumn width={8} style={{ width: '50%' }}>
            <Input
              size="small"
              fluid
              placeholder="price..."
              icon="dollar"
              onChange={this.onChange}
              type="number"
              defaultValue={price}
              name="0"
              error={this.state[0]}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    )
  }
}

export default Piece;
