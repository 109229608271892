import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import {
  handleRef,
} from 'utils/lib';

class RefFindNode extends Component {
  prevNode = null;

  componentDidMount() {
    this.prevNode = findDOMNode(this);

    handleRef(this.props.innerRef, this.prevNode);
  }

  componentDidUpdate() {
    const currentNode = findDOMNode(this);

    if (this.prevNode !== currentNode) {
      this.prevNode = currentNode;
      handleRef(this.props.innerRef, currentNode);
    }
  }

  componentWillUnmount() {
    handleRef(this.props.innerRef, null);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default RefFindNode;
