import React, { isValidElement, createRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import shallowEqual from 'shallowequal';

import {
  AutoControlledComponent as Component,
  useKeyOnly,
  isNil,
  getElementType,
  getUnhandledProps,
  isBrowser,
  eventStack,
  doesNodeContainClick,
} from 'utils/lib';

import Portal from 'components/Addons/Portal/Portal';
import Ref from 'components/Addons/Ref/Ref';
import MountNode from 'components/Addons/MountNode/MountNode';
import Icon from 'components/Icons/Icon';
import styles from './Modal.css';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalActions from './ModalActions';

import SizedConfetti from 'components/SizedConfetti/SizedConfetti';

const cx = classnames.bind(styles);

const canFit = (modalRect) => {
  const scrollHeight = modalRect.height + 0;

  const height = modalRect.height + 0;

  const contextHeight = window.innerHeight;
  const verticalCenter = contextHeight / 2;
  const topOffset = -(height / 2);

  const paddingHeight = 50;
  const startPosition = verticalCenter + topOffset;

  return startPosition + scrollHeight + paddingHeight < contextHeight;
};

const getLegacyStyles = (isFitted, centered, modalRect) => {
  const marginTop = centered && isFitted ? -(modalRect.height / 2) : 0;
  const marginLeft = -(modalRect.width / 2);

  return { marginLeft, marginTop };
};

const isLegacy = () => !window.ActiveXObject && 'ActiveXObject' in window;

class Modal extends Component {
  dimmerRef = createRef();

  ref = createRef();

  static propTypes = {
    centered: PropTypes.bool,
    dimmer: PropTypes.bool,
    closeOnDimmerClick: PropTypes.bool,
    closeOnDocumentClick: PropTypes.bool,
    eventPool: PropTypes.string,
  };

  static autoControlledProps = ['open'];

  static defaultProps = {
    centered: true,
    dimmer: true,
    closeOnDimmerClick: true,
    closeOnDocumentClick: false,
    eventPool: 'Modal',
  };

  static Header = ModalHeader;

  static Content = ModalContent;

  componentWillUnmount() {
    document.body.className = '';
    this.handlePortalUnmount();
  }

  getMountNode = () => (isBrowser() ? this.props.mountNode || document.body : null);

  handleRef = c => (this.ref = c);

  handleDimmerRef = c => (this.dimmerRef = c);

  handleDocumentMouseDown = (e) => {
    this.latestDocumentMouseDownEvent = e;
  }

  handleDocumentClick = (e) => {
    const { closeOnDimmerClick } = this.props;
    const currentDocumentMouseDownEvent = this.latestDocumentMouseDownEvent;

    if (
      !closeOnDimmerClick
      || doesNodeContainClick(this.ref.current, currentDocumentMouseDownEvent)
      || doesNodeContainClick(this.ref.current, e)
    ) { return; }

    _.invoke(this.props, 'onClose', e, this.props);
    this.trySetState({ open: false });
  };

  handleActionsOverrides = predefinedProps => ({
    onActionClick: (e, actionProps) => {
      _.invoke(predefinedProps, 'onActionClick', e, actionProps);
      _.invoke(this.props, 'onActionClick', e, this.props);

      this.handleClose(e);
    },
  });

  handlePortalMount = (e) => {
    const { eventPool } = this.props;

    this.setState({
      scrolling: false,
    });

    this.setPositionAndClassNames();

    eventStack.sub('mousedown', this.handleDocumentMouseDown, {
      pool: eventPool,
      target: this.dimmerRef.current,
    });
    eventStack.sub('click', this.handleDocumentClick, {
      pool: eventPool,
      target: this.dimmerRef.current,
    });

    _.invoke(this.props, 'onMount', e, this.props);
  };

  handlePortalUnmount = (e) => {
    const { eventPool } = this.props;

    cancelAnimationFrame(this.animationRequestId);
    eventStack.unsub('mousedown', this.handleDocumentMouseDown, {
      pool: eventPool,
      target: this.dimmerRef.current,
    })
    eventStack.unsub('click', this.handleDocumentClick, {
      pool: eventPool,
      target: this.dimmerRef.current,
    })
    _.invoke(this.props, 'onUnmount', e, this.props);
  };

  handleOpen = (e) => {
    _.invoke(this.props, 'onOpen', e, this.props);
    this.trySetState({ open: true });
  };

  handleClose = (e) => {
    _.invoke(this.props, 'onClose', e, this.props);
    this.trySetState({ open: false });
  };

  setDimmerNodeStyle = () => {
    const { current } = this.dimmerRef;

    if (current && current.style && current.style.display !== 'flex') {
      current.style.setProperty('display', 'flex', 'important');
    }
  };

  setPositionAndClassNames = () => {
    const { centered, dimmer } = this.props;

    let scrolling;
    const newState = {};
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const isFitted = canFit(rect);

      scrolling = !isFitted;

      const legacyStyles = this.legacy ? getLegacyStyles(isFitted, centered, rect) : {};

      if (!shallowEqual(this.state.legacyStyles, legacyStyles)) {
        newState.legacyStyles = legacyStyles;
      }

      if (this.state.scrolling !== scrolling) {
        newState.scrolling = scrolling;
      }
    }

    const classes = cx(
      useKeyOnly(dimmer, 'dimmable dimmed'),
      useKeyOnly(dimmer === 'blurring', ' blurring'),
      useKeyOnly(scrolling, ' scrolling'),
    );

    if (this.state.mountClasses !== classes) newState.mountClasses = classes;
    if (!_.isEmpty(newState)) this.setState(newState);

    this.animationRequestId = requestAnimationFrame(this.setPositionAndClassNames);

    this.setDimmerNodeStyle();
  }

  renderContent = (rest) => {
    const {
      actions,
      basic,
      children,
      className,
      closeIcon,
      content,
      header,
      mountNode,
      size,
      style,
    } = this.props;
    const { marginTop, mountClasses, scrolling } = this.state;

    const classes = cx(
      'ui',
      size,
      useKeyOnly(basic, 'basic'),
      useKeyOnly(this.legacy, 'legacy'),
      useKeyOnly(scrolling, 'scrolling'),
      'modal transition visible active',
      className,
    );
    const ElementType = getElementType(Modal, this.props);

    const closeIconName = closeIcon === true ? 'close' : closeIcon;
    const closeIconJSX = Icon.create(closeIconName, { overrideProps: this.handleIconOverrides });
    if (!isNil(children)) {
      return (
        <Ref innerRef={this.ref}>
          <ElementType {...rest} className={classes} style={{ marginTop, ...style }}>
            <MountNode className={mountClasses} node={mountNode} />
            {closeIconJSX}
            {children}
          </ElementType>
        </Ref>
      );
    }

    return (
      <Ref innerRef={this.ref}>
        <ElementType {...rest} className={classes} style={{ marginTop, ...style }}>
          <MountNode className={mountClasses} node={mountNode} />

          {closeIconJSX}
          {ModalHeader.create(header, { autoGenerateKey: false })}
          {ModalContent.create(content, { autoGenerateKey: false })}
          {ModalActions.create(actions, { overrideProps: this.handleActionsOverrides })}
        </ElementType>
      </Ref>
    );
  };

  render() {
    const { open } = this.state;

    const { centered, closeOnDocumentClick, dimmer, eventPool, trigger, dimmerOff, scrolling, runConfetti } = this.props;
    const mountNode = this.getMountNode();

    if (!isBrowser()) {
      return isValidElement(trigger) ? trigger : null;
    }

    const unhandled = getUnhandledProps(Modal, this.props);
    const portalPropNames = Portal.handledProps;

    const rest = _.reduce(
      unhandled,
      (acc, val, key) => {
        if (!_.includes(portalPropNames, key)) acc[key] = val;

        return acc;
      },
      {}
    );
    const portalProps = _.pick(unhandled, portalPropNames);

    const dimmerClasses = cx(
      'ui',
      dimmer === 'inverted' && 'inverted',
      !centered && 'top aligned',
      'page modals dimmer transition visible active',
    );

    return (
      <Portal
        closeOnDocumentClick={closeOnDocumentClick}
        {...portalProps}
        trigger={trigger}
        eventPool={eventPool}
        mountNode={mountNode}
        open={open}
        onClose={this.handleClose}
        onMount={this.handlePortalMount}
        onOpen={this.handleOpen}
        onUnmount={this.handlePortalUnmount}
      >
        <div style={dimmerOff ? { padding: '0px' } : null} className={dimmerClasses} ref={this.dimmerRef}>
          {runConfetti && <SizedConfetti
            run
            recycle={false}
            numberOfPieces={200}
            style={{ zIndex: 1002 }}
            colors={['#ffffff', '#4bc6b4', '#96e0d5', '#128fbc', '#7bc2dc']}
            initialVelocityY={-5}
            opacity={0.85}
            tweenDuration={15000}
          />
          }
          {this.renderContent(rest)}
        </div>
      </Portal>
    );
  }
}

export default Modal;
