import React, { Component } from 'react';
import treeChanges from 'tree-changes';

import ProductInfo from 'components/ProductInfo';

import SearchAddressMessage from 'components/Messages/SearchAddress';
import UnauthorizeUserMessage from 'components/Messages/UnauthorizeUser';
import InactiveStore from 'components/Messages/InactiveStore';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/component/Tab';
import Loader from 'components/Loaders/Loader';
import notification from 'components/Notification';
import Divider from 'components/Divider';
import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import Icon from 'components/Icons/Icon';
import Button from 'components/Button/Button';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Segment from 'components/Segment/Segment';
import Container from 'components/Container/Container';


import { saveLocalStorage } from 'services';

import classnames from 'classnames/bind';
import ProductInformation from './components/ProductInformation';
import StoreInfo from './components/StoreInfo';
import TotalPrice from './components/TotalPrice';
import History from './components/History';
import Orders from './components/Orders';
import Comment from './components/Comment';

import styles from './UserOrders.module.scss';

const cx = classnames.bind(styles);

class UserOrders extends Component {
  state = {
    openModalProductInformation: false,
    productInformation: null,
    isCreateNewOrder: false,
    isOpenRatingModal: false,
  }

  componentDidMount() {
    const { fetchUserOrders, successLogIn } = this.props;

    fetchUserOrders();
  }

  componentDidUpdate(prevProps) {
    const { changedTo } = treeChanges(prevProps, this.props);

    if (changedTo('newProductComment.status', 'success')) {
      this.onCloseRatingModal();

      notification.success({
        message: 'Success',
        description: 'Successfully',
        duration: 5,
      })
    }

    if (changedTo('updateProductComment.status', 'success')) {
      this.onCloseRatingModal();

      notification.success({
        message: 'Success',
        description: 'Successfully',
        duration: 5,
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  onOrderAgain = (id, storeId) => {
    const { addOrdersToCart } = this.props;
    addOrdersToCart(id, storeId);
  };

  goToStore = (storeInfo) => {
    const { history, domainPath } = this.props;
    history.push(`${domainPath}products?location=${storeInfo.address}&store=${storeInfo.id}&order_type=${storeInfo.store_type}`);
  };

  goToProductInformation = (product, store) => {
    const { history, domainPath } = this.props;
    if (product.store_product_id) {
      history.push(`${domainPath}products?location=${store.address}&store=${store.store_id}&order_type=${
        store.store_type
        }&product=${product.store_product_id}`);
    } else {
      this.setState({
        openModalProductInformation: true,
        productInformation: product,
      });
    }
  };

  goToDelivery = async (address) => {
    const { history, setDeliveryAddress, domainPath } = this.props;
    const resetStores = true;

    await setDeliveryAddress(address);
    history.push(`${domainPath}stores?location=${address}`);
  };

  closeModalProductInformation = () => {
    this.setState({
      openModalProductInformation: false,
      productInformation: null,
    });
  }

  createNewOrder = () => {
    this.setState({
      isCreateNewOrder: !this.state.isCreateNewOrder,
    });
  }

  handleRate = (e, data, product, order) => {

    this.setState({
      rating: data.rating,
      isOpenRatingModal: true,
      product,
      storeId: order.store_id,
    });
  }

  onCloseRatingModal = () => {
    this.setState({
      isOpenRatingModal: false,
      rating: null,
      storeId: null,
      productId: null,
      isEdit: false,
    });
  }

  addComment = (comment) => {
    const { rating, storeId, product } = this.state;
    const { addComment } = this.props;

    addComment(product.product_id, {
      rate: rating,
      comment,
      store_id: storeId,
    });
  }

  editComment = (product, order) => {
    this.setState({
      product,
      storeId: order.store_id,
      isOpenRatingModal: true,
      isEdit: true,
    })
  }

  updateComment = (comment, rate) => {
    const { product, storeId } = this.state;
    const { updateComment } = this.props;

    updateComment(product.comment_id, {
      comment,
      rate,
      store_id: storeId,
    })
  }

  render() {
    const {
      listOrders,
      listOrdersError,
      receivedOrders,
      openModalStoreType,
      closeModalStoreType,
      fetchStoreInfo,
      orderInfo,
      bufferStoreInfo,
      isFetchingStoreInfo,
      isFetchingUserOrders,
      onContinueOrderAgain,
      successLogIn,
      storeInfo,
      openModalWindow,
      inactiveStore,
      closeInActiveStoreModal,
    } = this.props;

    const { openModalProductInformation, productInformation, isCreateNewOrder, isOpenRatingModal, rating, product, isEdit } = this.state;

    if (!successLogIn) {
      return <UnauthorizeUserMessage openModalWindow={openModalWindow} />;
    }

    if (successLogIn && isFetchingUserOrders) {
      return (
        <div style={{ flex: 1 }}>
          <div className={cx('event-container')}>
            <Loader active inline="center" />
          </div>
        </div>
      );
    }

    if (listOrders.length === 0 && receivedOrders && receivedOrders.length === 0 || isCreateNewOrder) {
      return (
        <SearchAddressMessage
          goToDelivery={this.goToDelivery}
          notShowCBDLink={isCreateNewOrder}
          message={(
            <div className={cx('text-message')} style={{ fontWeight: 500 }}>
              {isCreateNewOrder ? null : <p>You have not yet placed an order on Heallybuy.</p>}
              <p>Please enter your delivery address below to place a new order:</p>
            </div>
          )}
        />
      );
    }

    if (window.isMobile) {
      return (
        <div className={cx('wrapper')}>
          <Tabs defaultActiveIndex={0}>
            <Tab name="My orders" linkClassName="link-class-0">
              <Container style={{ paddingTop: '1em' }}>
                <Grid>
                  <GridRow>
                    <GridColumn width={16} textAlign="center">
                      <Button type="button" color="teal" onClick={this.createNewOrder}>create a new order</Button>
                    </GridColumn>
                  </GridRow>
                  {listOrders.length > 0 && listOrders.map((value, key) => (
                    <GridRow>
                      <GridColumn width={16}>
                        <Segment key={value.order.id}>
                          <Grid>
                            <GridRow>
                              <GridColumn width={16} textAlign="right">
                                <StoreInfo
                                  store={value.order}
                                  bufferStoreInfo={bufferStoreInfo}
                                  onClick={this.goToStore}
                                  fetchStoreInfo={fetchStoreInfo}
                                />
                              </GridColumn>
                            </GridRow>
                            {value.products.map((product, key) => (
                              <ProductInfo
                                key={key}
                                product={product}
                                disabledPacking
                                onClick={() => this.goToProductInformation(product, value.order)}
                              />
                            ))}
                            <Divider />
                            <GridRow>
                              <GridColumn width={16} textAlign="right">
                                <TotalPrice
                                  sumPrice={value.order.sum_price || 0}
                                  discountCost={value.order.discount_cost || 0}
                                  taxCost={value.order.tax_cost || 0}
                                  shippingCost={value.order.shipping_cost || 0}
                                  itemsDeliveryCost={value.order.items_delivery_cost || 0}
                                />
                              </GridColumn>
                            </GridRow>
                            <Orders order={value.order} />
                          </Grid>
                        </Segment>
                      </GridColumn>
                    </GridRow>
                  ))}
                </Grid>
              </Container>
            </Tab>
            <Tab name="History" linkClassName="link-class-1">
              {receivedOrders && receivedOrders.length > 0 ? (
                <Container style={{ paddingTop: '1em' }}>
                  <Grid>
                    <History
                      receivedOrders={receivedOrders}
                      onOrderAgain={this.onOrderAgain}
                      openModalStoreType={openModalStoreType}
                      closeModalStoreType={closeModalStoreType}
                      orderInfo={orderInfo}
                      storeInfo={storeInfo}
                      fetchStoreInfo={fetchStoreInfo}
                      bufferStoreInfo={bufferStoreInfo}
                      goToStore={this.goToStore}
                      isFetchingStoreInfo={isFetchingStoreInfo}
                      onContinueOrderAgain={onContinueOrderAgain}
                      onClick={this.goToProductInformation}
                    />
                  </Grid>
                </Container>
              ) :
                <div style={{ width: '100%', display: 'flex', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center' }}>
                  <div className={cx('text-message')}>
                    You have no completed orders.
                  </div>
                </div>
              }
            </Tab>
          </Tabs>
          {
            openModalProductInformation
            && (
              <Modal
                open={openModalProductInformation}
                onClose={this.closeModalProductInformation}
                closeIcon={<Icon name="close" link onClick={this.closeModalProductInformation} />}
              >
                <ModalContent>
                  <ProductInformation product={productInformation} />
                </ModalContent>
              </Modal>
            )
          }
        </div>
      );
    }

    return (
      <div className={cx('wrapper')}>
        <Container style={{ paddingTop: '1em' }}>
          <Grid>
            <GridRow>
              <GridColumn width={16} textAlign="center" verticalAling="middle">
                <Button type="button" color="teal" onClick={this.createNewOrder}>create a new order</Button>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={16}>
                <h1 className={cx('title')}>My orders</h1>
              </GridColumn>
            </GridRow>
            {listOrders.length > 0 && listOrders.map((value, key) => (
              <GridRow>
                <GridColumn width={16}>
                  <Segment key={value.order.id} raised>
                    <Grid>
                      <GridRow>
                        <GridColumn width={16} textAlign="right">
                          <StoreInfo
                            store={value.order}
                            bufferStoreInfo={bufferStoreInfo}
                            onClick={this.goToStore}
                            fetchStoreInfo={fetchStoreInfo}
                          />
                        </GridColumn>
                      </GridRow>
                      {value.products.map((product, key) => (
                        <ProductInfo
                          key={key}
                          product={product}
                          disabledPacking
                          onClick={() => this.goToProductInformation(product, value.order)}
                        />
                      ))}
                      <Divider />
                      <GridRow>
                        <GridColumn width={16} textAlign="right">
                          <TotalPrice
                            sumPrice={value.order.sum_price || 0}
                            discountCost={value.order.discount_cost || 0}
                            taxCost={value.order.tax_cost || 0}
                            shippingCost={value.order.shipping_cost || 0}
                            itemsDeliveryCost={value.order.items_delivery_cost || 0}
                          />
                        </GridColumn>
                      </GridRow>
                      <Orders order={value.order} />
                    </Grid>
                  </Segment>
                </GridColumn>
              </GridRow>
            ))}
            {receivedOrders && receivedOrders.length > 0 ? (
              <History
                receivedOrders={receivedOrders}
                onOrderAgain={this.onOrderAgain}
                openModalStoreType={openModalStoreType}
                closeModalStoreType={closeModalStoreType}
                orderInfo={orderInfo}
                storeInfo={storeInfo}
                bufferStoreInfo={bufferStoreInfo}
                goToStore={this.goToStore}
                fetchStoreInfo={fetchStoreInfo}
                isFetchingStoreInfo={isFetchingStoreInfo}
                onContinueOrderAgain={onContinueOrderAgain}
                onClick={this.goToProductInformation}
                handleRate={this.handleRate}
                editComment={this.editComment}
              />
            ) : null}
            {inactiveStore
              && (
                <Modal
                  open={inactiveStore}
                  onClose={closeInActiveStoreModal}
                  closeIcon={<Icon name="close" link onClick={closeInActiveStoreModal} />}
                >
                  <ModalContent>
                    <InactiveStore goToDelivery={this.goToDelivery} address={inactiveStore} />
                  </ModalContent>
                </Modal>
              )
            }
            {
              openModalProductInformation
              && (
                <Modal
                  open={openModalProductInformation}
                  onClose={this.closeModalProductInformation}
                  closeIcon={<Icon name="close" link onClick={this.closeModalProductInformation} />}
                >
                  <ModalContent>
                    <ProductInformation product={productInformation} />
                  </ModalContent>
                </Modal>
              )
            }
            <Modal
              open={isOpenRatingModal}
              onClose={this.onCloseRatingModal}
              closeIcon={<Icon name="close" link onClick={this.onCloseRatingModal} />}
              size="tiny"
            >
              <ModalHeader style={{ textAlign: 'center' }}>
                {product && product.name}
              </ModalHeader>
              <ModalContent>
                <Comment
                  rating={rating}
                  addComment={comment => this.addComment(comment)}
                  productImage={product && product.image.default}
                  isEdit={isEdit}
                  updateComment={this.updateComment}
                  comment={product && product.comment || ''}
                />
              </ModalContent>
            </Modal>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default UserOrders;
