import React, { useEffect } from 'react';
import classnames from 'classnames/bind';

import Container from 'components/Container/Container';
import Loader from 'components/Loaders/Loader';
import Drawer from 'components/Drawer';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import DrawerItem from 'components/DrawerItem';

import styles from './DoctorLayout.module.scss';

import Header from './components/Header';
import Footer from './components/Footer';

const cx = classnames.bind(styles);


const DoctorLayout = ({
  children,
  fetchCartInfo,
  statusCart,
  totalQuantity,
  history,
  domainPath,
  doctorInfo
}) => {
  const isLoading = statusCart === 'IDLE' || doctorInfo.status === 'RUNNING';
  useEffect(() => {
    fetchCartInfo();
  }, [])

  const onClickDrawerItem = (link) => {
    history.push(link);
  }

  return (
    <>
      <div classNames={cx('container')}>
        <Header totalQuantity={totalQuantity} history={history} domainPath={domainPath} />
        {
          isLoading ?
            <Loader active center /> :
            <Container>
              <div style={{ paddingTop: '76px', height: '100vh' }}>
                <Grid style={{ height: '100%' }}>
                  <GridRow>
                    <GridColumn width={4}>
                      <Drawer open={true} theme="dark" secondaryInfoBlock={
                        <>
                          <DrawerItem
                            name="orders"
                            onClick={onClickDrawerItem}
                            iconName="file alternate outline"
                            link="doctor/orders"
                            domainPath={domainPath}
                          />
                          <DrawerItem
                            name="stores"
                            onClick={onClickDrawerItem}
                            iconName="building outline"
                            link="doctor/stores"
                            domainPath={domainPath}
                          />
                        </>
                      } />
                    </GridColumn>
                    <GridColumn width={12}>
                      {children}
                    </GridColumn>
                  </GridRow>
                </Grid>
              </div>
            </Container>
        }
        <Footer />
      </div >
    </>
  )
}

export default DoctorLayout;
