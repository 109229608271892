import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames/bind';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Segment from 'components/Segment/Segment';
import Header from 'components/Typography/Header/Header';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Icon from 'components/Icons/Icon';
import Loader from 'components/Loaders/Loader';
import Popup from 'components/Popup/Popup';
import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import ModalActions from 'components/ModalWindow/ModalActions';

import ProductInfo from 'components/ProductInfo';

import { useMessage } from 'hooks/message.hook';
import useDebounce from 'hooks/debounce.hook';

import { checkEmail } from 'services';

import styles from './Cart.module.scss';

const cx = classnames.bind(styles);

const Cart = ({
  fetchCartProducts,
  cart,
  itemsProducts,
  saveCart,
  dataSaveCart,
  domainPath,
  eraseDataSaveCart,
  removeFromCart,
  addToCartProduct,
  fetchCartInfo,
  history,
  linkWithProducts,
  resetCart,
  doctorInfo,
  changePatientCartEmail
}) => {
  const [modalWindow, setModalWindow] = useState(false);
  const [patientEmail, setPatientEmail] = useState(doctorInfo.data.patient_cart_email);
  const [fieldError, setFieldError] = useState(null);

  const debouncedPatientEmail = useDebounce(patientEmail, 500);
  const message = useMessage();

  useEffect(() => {
    if (debouncedPatientEmail) {
      changePatientCartEmail(debouncedPatientEmail);
    }
  }, [debouncedPatientEmail])

  useEffect(() => {
    fetchCartProducts();
    eraseDataSaveCart();
  }, [])

  useEffect(() => {
    if (dataSaveCart.status === 'SUCCESS') {
      message('Success', `Cart is sent to patient’s email ${patientEmail}`, 5, 'success');
    }

    if (dataSaveCart.status === 'FAIL') {
      message('Error', dataSaveCart.error, 5, 'error', () => eraseDataSaveCart());
    }
  }, [dataSaveCart.status])

  const setProduct = async (product, quantity) => {
    let reset = false;

    if (cart.itemsProducts && Object.keys(cart.itemsProducts).length === 1) {
      reset = true;
    }

    if (quantity === 0) {
      const isRemove = await removeFromCart(product);

      if (isRemove === 'OK') {
        await addToCartProduct(product, 0, reset, null);
        // fetchCartInfo(false);
      }
    } else {
      addToCartProduct(product, quantity, false, false);
    }
  }

  const deleteProduct = async (product) => {
    let reset = false;

    if (cart.itemsProducts && Object.keys(cart.itemsProducts).length === 1) {
      reset = true;
    }

    try {
      const result = await removeFromCart(product);

      if (result === 'OK') {
        await addToCartProduct(product, 0, reset, null);
        fetchCartInfo(false);
      } else {
        message('Error', 'problem with delete product from cart', 5, 'error')
      }
    } catch (error) {
      message('Error', error.message, 5, 'error')
    }
  }

  const keepShopping = () => {
    if (linkWithProducts.link && linkWithProducts.link.length > 0) {
      history.push(`${linkWithProducts.link}`);
    } else {
      history.push(`${domainPath}doctor/stores`);
    }
  }

  const openModalWindow = async () => {
    if (typeof patientEmail === 'string' && patientEmail.trim().length !== 0 && checkEmail(patientEmail)) {
      try {
        setModalWindow(true);
      } catch (error) {
        message('Error', error.message, 5, 'error')
      }
    } else {
      setFieldError('You have problem with email');
    }
  }

  const createPateintCart = async () => {
    await saveCart(patientEmail);
    setModalWindow(false);
  }

  if (cart.isFetchingCartProducts) {
    return (
      <Loader active center />
    )
  }

  return (
    <Segment basic style={{ height: '100%' }} loading={dataSaveCart.status === 'RUNNING'}>
      {
        cart.cartProducts && cart.cartProducts.length === 0 || Object.keys(itemsProducts).length === 0 ?
          <Grid style={{ height: '100%' }}>
            <GridRow style={{ height: '100%' }}>
              <GridColumn width={16} textAlign="center" verticalAlign="middle">
                <div className={cx('message')}>Please add some products to the cart</div>
                <Button type="button" onClick={keepShopping}>keep shopping</Button>
              </GridColumn>
            </GridRow>
          </Grid> :
          <Grid>
            <GridRow>
              <GridColumn width={8} textAlign="left">
                <Header as="h1" style={{ color: '#128fbc' }}>Check the order details </Header>
              </GridColumn>
              <GridColumn width={8} textAlign="right">
                <Button type="button" color="teal" onClick={openModalWindow}>Save cart</Button>
              </GridColumn>
            </GridRow>
            <GridRow style={{ paddingBottom: '0rem' }}>
              <GridColumn width={16}>
                <h2 className={cx('title')}>
                  Products
                </h2>
              </GridColumn>
            </GridRow>
            {cart.cartProducts && cart.cartProducts.map((product) => {
              let itemProduct = null;

              if (itemsProducts && itemsProducts[product.id]) {
                itemProduct = Object.assign(itemsProducts[product.id]);
              }
              return (
                <ProductInfo
                  key={product.id}
                  product={product}
                  itemProduct={itemProduct}
                  setProduct={setProduct}
                  removeFromCart={deleteProduct}
                  onClick={() => console.log('product')}
                />
              )
            })}
            <GridRow style={{ paddingBottom: '0rem' }}>
              <GridColumn width={16}>
                <h2 className={cx('title')}>
                  Patient email
                </h2>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={8}>
                <Popup
                  trigger={<Input
                    style={{ width: '100%' }}
                    inputMode="numeric"
                    placeholder="Email..."
                    value={patientEmail}
                    onChange={(e) => setPatientEmail(e.target.value)}
                    size="medium"
                    error={fieldError}
                    name="patient_cart_email"
                    onFocus={() => setFieldError(null)}
                    icon="mail"
                  />}
                  header='Email'
                  content='Please, check patient email'
                  on='focus'
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={16} textAlign="right">
                <Button type="button" onClick={keepShopping}>keep shopping</Button>
                <Button color="teal" type="button" onClick={openModalWindow}>Save cart</Button>
              </GridColumn>
            </GridRow>
          </Grid>
      }
      <Modal
        open={modalWindow}
        onClose={() => setModalWindow(false)}
        closeIcon={<Icon name="close" link onClick={() => setModalWindow(false)} />}
        size="tiny"
      >
        <ModalHeader>Save cart</ModalHeader>
        <ModalContent>
          <ModalDescription>
            <span className={cx('content-description')}>
              Save cart and send it to patient’s email {patientEmail} ?
            </span>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button primary type="button" onClick={createPateintCart}>Yes</Button>
          <Button type="button" onClick={() => setModalWindow(false)}>Cancel</Button>
        </ModalActions>
      </Modal>
    </Segment>
  )
}

export default Cart;
