import React from 'react';

const Minus = props => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 286.054 286.054" xmlSpace="preserve">
    <g>
      <path
        style={props.style ? { ...props.style } : { fill: '#4bc6b4' }}
        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
		s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
		s52.026-116.2,116.209-116.2s116.209,52.017,116.209,116.2S207.21,259.236,143.027,259.236z M196.841,125.149H89.41
		c-4.952,0-8.957,4.005-8.957,8.939v17.878c0,4.943,4.005,8.939,8.957,8.939h107.431c4.952,0,8.957-3.996,8.957-8.939v-17.878
		C205.789,129.162,201.784,125.149,196.841,125.149z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default Minus;
