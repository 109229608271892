import React from 'react';

import Button from 'components/Button/Button';

import classnames from 'classnames/bind';
import styles from './ModalRedirectContent.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { onRedirect } = props;

  return (
    <div className={cx('message-container')}>
      <div className={cx('message')}>
        Please login in-order to complete the order
      </div>
      <div className={cx('btn-container')}>
        <Button type="button" color="teal" onClick={onRedirect}>Sign-in with Heally Account</Button>
      </div>
    </div>
  );
};
