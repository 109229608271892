import React from 'react';
import Placeholder from 'components/Placeholder/Placeholder';
import PlaceholderImage from 'components/Placeholder/PlaceholderImage';
import PlaceholderLine from 'components/Placeholder/PlaceholderLine';
import PlaceholderHeader from 'components/Placeholder/PlaceholderHeader';
import PlaceholderParagraph from 'components/Placeholder/PlaceholderParagraph';
import Card from 'components/Card/Card';
import CardContent from 'components/Card/CardContent';
import CardGroup from 'components/Card/CardGroup';

import classnames from 'classnames/bind';
import styles from './Products.module.scss';
import kefirConfig from 'recompose/kefirObservableConfig';

const cx = classnames.bind(styles);

const Products = props => (
  <div className={cx('container')}>
    <div style={{ marginBottom: '50px' }}>
      <div style={{ width: '350px', height: '29px' }} className={cx('cards-container-wrapper')}>
        <Placeholder style={{ height: '1.5em' }}>
          <PlaceholderHeader>
            <PlaceholderLine length="small" />
          </PlaceholderHeader>
        </Placeholder>
      </div>
      <div className={cx('products')}>
        <div style={{ width: '942px', height: '339px' }} className={cx('cards-container-wrapper')}>
          <CardGroup itemsPerRow={3} className={cx('cards-container')}>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
          </CardGroup>
        </div>
      </div>
    </div>
    <div>
      <div style={{ width: '350px', height: '29px' }} className={cx('cards-container-wrapper')}>
        <Placeholder style={{ height: '1.5em' }}>
          <PlaceholderHeader>
            <PlaceholderLine length="small" />
          </PlaceholderHeader>
        </Placeholder>
      </div>
      <div className={cx('products')}>
        <div style={{ width: '942px', height: '339px' }} className={cx('cards-container-wrapper')}>
          <CardGroup itemsPerRow={3} className={cx('cards-container')}>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
            <Card style={{ margin: '0.875em 0.5em' }} className={cx('card-placeholder')}>
              <Placeholder>
                <PlaceholderImage style={{ height: '181px' }} />
              </Placeholder>
              <CardContent style={{ height: '158px' }}>
                <Placeholder>
                  <PlaceholderHeader>
                    <PlaceholderLine length='very short' />
                  </PlaceholderHeader>
                  <PlaceholderParagraph>
                    <PlaceholderLine length='short' />
                    <PlaceholderLine length='medium' />
                    <PlaceholderLine length='long' />
                  </PlaceholderParagraph>
                </Placeholder>
              </CardContent>
            </Card>
          </CardGroup>
        </div>
      </div>
    </div>
  </div>
);

export default Products;
