export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const FETCH_USER_ORDERS = 'FETCH_USER_ORDERS';
export const FETCH_USER_ORDERS_SUCCESS = 'FETCH_USER_ORDERS_SUCCESS';
export const FETCH_USER_ORDERS_FAIL = 'FETCH_USER_ORDERS_FAIL';

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const CLOSE_ORDER_FAIL_TOAST = 'CLOSE_ORDER_FAIL_TOAST';

export const ADD_ORDERS_TO_CART = 'ADD_ORDERS_TO_CART';
export const ADD_ORDERS_TO_CART_SUCCESS = 'ADD_ORDERS_TO_CART_SUCCESS';
export const ADD_ORDERS_TO_CART_FAIL = 'ADD_ORDERS_TO_CART_FAIL';

export const OPEN_MODAL_CHANGE_STORE_TYPE = 'OPEN_MODAL_CHANGE_STORE_TYPE';
export const CLOSE_MODAL_CHANGE_STORE_TYPE = 'CLOSE_MODAL_CHANGE_STORE_TYPE';

export const CHECK_AUTH_USER = 'CHECK_AUTH_USER';

export const OPEN_MODAL_RECOMMENDATION = 'OPEN_MODAL_RECOMMENDATION';
export const CLOSE_MODAL_RECOMMENDATION = 'CLOSE_MODAL_RECOMMENDATION';

export const CART_HAS_ISSUES = 'CART_HAS_ISSUES';

export const FETCH_ORDER_INFORMAION = 'FETCH_ORDER_INFORMAION';
export const FETCH_ORDER_INFORMAION_SUCCESS = 'FETCH_ORDER_INFORMAION_SUCCESS';
export const FETCH_ORDER_INFORMATION_FAIL = 'FETCH_ORDER_INFORMATION_FAIL';

export const CART_HAS_PROBLEM_WITH_COUPON = 'CART_HAS_PROBLEM_WITH_COUPON';
export const INACTIVE_STORE = 'INACTIVE_STORE';

export const RELOAD_PAYMENT_ONLINE_COMPONENT = 'RELOAD_PAYMENT_ONLINE_COMPONENT';

export const OPEN_MODAL_WITH_PHOTO_ID = 'OPEN_MODAL_WITH_PHOTO_ID';
export const CLOSE_MODAL_WITH_PHOTO_ID = 'CLOSE_MODAL_WITH_PHOTO_ID';

export const REQUIRE_PHOTO_ID = 'REQUIRE_PHOTO_ID';
