import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { createShorthandFactory, getUnhandledProps } from 'utils/lib';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';

class AccordionPanel extends Component {
  handleTitleOverrides = predefinedProps => ({
    onClick: (e, titleProps) => {
      _.invoke(predefinedProps, 'onClick', e, titleProps);
      _.invoke(this.props, 'onTitleClick', e, titleProps);
    },
  });

  render() {
    const { active, content, index, title } = this.props;
    return (
      <Fragment>
        {AccordionTitle.create(title, {
          autoGenerateKey: false,
          defaultProps: { active, index },
          overrideProps: this.handleTitleOverrides,
        })}
        {typeof content === 'string' ? (
          AccordionContent.create(content, {
            autoGenerateKey: false,
            defaultProps: { active },
          })
        ) : (
          <AccordionContent active={active}>{content}</AccordionContent>
        )}
      </Fragment>
    );
  }
}

AccordionPanel.create = createShorthandFactory(AccordionPanel, null);

export default AccordionPanel;
