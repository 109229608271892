import React, { Component, Fragment } from 'react';
import classnames from 'classnames/bind';

import Table from 'components/Table/Table';
import TableHeader from 'components/Table/TableHeader';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import TableBody from 'components/Table/TableBody';
import TableRow from 'components/Table/TableRow';
import TableCell from 'components/Table/TableCell';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Input from 'components/Input/Input';

import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalActions from 'components/ModalWindow/ModalActions';
import ModalHeader from 'components/ModalWindow/ModalHeader';

import styles from './TableWithShippingAddresses.module.scss';

const cx = classnames.bind(styles);

class TableWithShippingAddresses extends Component {
  state = {
    editCell: false,
  }

  onClickEdit = (key) => {
    this.setState({
      editCell: key,
    });
  };

  onClickCancel = () => {
    this.setState({
      editCell: null,
    });
  }

  onClickSave = (store) => {
    const { updateDeliveryLocation, listWithDeliveryLocations } = this.props;
    this.setState({
      editCell: false,
    }, () => {
      updateDeliveryLocation(store, this.min_order_price, this.delivery_cost, listWithDeliveryLocations);
    });
  };

  deleteDeliveryLocation = (id) => {
    this.setState({
      isApprovedDelete: true,
      id,
    });
  }


  approveDeleteLocation = () => {
    const { deleteDeliveryLocation, storeInformation } = this.props;
    const { id } = this.state;

    if (id) {
      deleteDeliveryLocation(id, storeInformation.id);
      this.declineDeleteLocation();
    }
  }

  declineDeleteLocation = () => {
    this.setState({
      isApprovedDelete: false,
      id: null,
    });
  }

  setPosition = (deliveryLocation) => {
    const { setPosition } = this.props;

    if (deliveryLocation.coordinates) {
      setPosition(deliveryLocation.coordinates);
    }
  }

  onChangeInput = (e, field) => {
    this[field.name] = field.value;
  }

  render() {
    const { listWithDeliveryLocations } = this.props;
    const { isApprovedDelete } = this.state;

    const listDL = Object.keys(listWithDeliveryLocations);
    return (
      <Fragment>
        <Table compact size="mini" padded style={{ position: 'relative' }} className={cx('table-addresses')}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Address</TableHeaderCell>
              <TableHeaderCell>Min order price</TableHeaderCell>
              <TableHeaderCell>Shipping cost</TableHeaderCell>
              <TableHeaderCell textAlign="center">Actions</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {listDL.map((value, key) => (
              <TableRow key={key} warning={this.state.editCell === key}>
                <TableCell>
                  <Icon name="map marker alternate" color="blue" />
                  <a className={cx('link')} onClick={() => this.setPosition(listWithDeliveryLocations[value])}>{listWithDeliveryLocations[value].formatted_address}</a>
                </TableCell>
                <TableCell>
                  {this.state.editCell === key ? <Input name="min_order_price" onChange={this.onChangeInput} size="mini" defaultValue={listWithDeliveryLocations[value].min_order_price} /> : `$${listWithDeliveryLocations[value].min_order_price || 0.0}`}
                </TableCell>
                <TableCell>
                  {this.state.editCell === key ? <Input name="delivery_cost" onChange={this.onChangeInput} size="mini" defaultValue={listWithDeliveryLocations[value].delivery_cost} /> : `$${listWithDeliveryLocations[value].delivery_cost || 0.0}`}
                </TableCell>
                <TableCell textAlign="right">
                  {this.state.editCell === key ? (
                    <Button size="mini" primary onClick={() => this.onClickSave(listWithDeliveryLocations[value])}>
                      <Icon name="save" style={{ margin: '0em' }} /> Save
                    </Button>
                  ) : (
                      <Button onClick={() => this.onClickEdit(key)} size="mini" secondary>
                        <Icon name="edit" style={{ margin: '0em' }} /> Edit
                      </Button>
                    )}
                  {this.state.editCell === key ? (
                    <Button size="mini" onClick={this.onClickCancel}><Icon name="ban" style={{ margin: '0em' }} /> Cancel</Button>
                  ) : (
                      <Button type="button" size="mini" negative onClick={() => this.deleteDeliveryLocation(value)}>
                        <Icon name="delete" style={{ margin: '0em' }} /> Delete
                      </Button>
                    )}
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
        <Modal basic size="small" open={isApprovedDelete}>
          <ModalHeader>
            Delete Delivery Location
          </ModalHeader>
          <ModalContent>
            <p>Are you sure delete this delivery location?</p>
          </ModalContent>
          <ModalActions>
            <Button type="button" basic color="green" inverted onClick={this.approveDeleteLocation}>
              <Icon name="checkmark" />
              Yes
            </Button>
            <Button type="button" basic color="red" inverted onClick={this.declineDeleteLocation}>
              <Icon name="remove" />
              No
            </Button>
          </ModalActions>
        </Modal>
      </Fragment>
    );
  }
}

export default TableWithShippingAddresses;
