import React, { Component } from 'react';
import _ from 'lodash';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';
import Button from 'components/Button/Button';

import classnames from 'classnames/bind';
import styles from './Modal.css';

const cx = classnames.bind(styles);

class ModalActions extends Component {
  handleButtonOverrides = predefinedProps => ({
    onClick: (e, buttonProps) => {
      _.invoke(predefinedProps, 'onClick', e, buttonProps);
      _.invoke(this.props, 'onActionsClick', e, buttonProps);
    },
  });

  render() {
    const { actions, children, className, content } = this.props;
    const classes = cx('actions', className);
    const rest = getUnhandledProps(ModalActions, this.props);
    const ElementType = getElementType(ModalActions, this.props);

    if (!isNil(children)) {
      return (
        <ElementType {...rest} className={classes}>
          {children}
        </ElementType>
      );
    }

    if (!isNil(content)) {
      return (
        <ElementType {...rest} className={classes}>
          {content}
        </ElementType>
      );
    }

    return (
      <ElementType {...rest} className={classes}>
        {_.map(actions, action => Button.create(action, { overrideProps: this.handleButtonOverrides }))}
      </ElementType>
    );
  }
}

ModalActions.create = createShorthandFactory(ModalActions, actions => ({ actions }));

export default ModalActions;
