import React, { Component } from 'react';

import RadioButton from 'components/RadioButton';
import Button from 'components/Button/Button';

import classnames from 'classnames/bind';
import styles from './PaymentMethod.module.scss';

const cx = classnames.bind(styles);

class PaymentMethod extends Component {
  changeActive = (name) => {
    const { changePaymentMethod, successLogIn } = this.props;
    changePaymentMethod(name);
  };

  render() {
    const {
      numberPosition,
      storeInfo,
      paymentMethod,
      storeId,
      bufferStoreInfo,
      successLogIn,
    } = this.props;

    const paymentComment = bufferStoreInfo[storeId] && bufferStoreInfo[storeId].payment_comment;

    let paymentBody = null;

    if (
      bufferStoreInfo[storeId] &&
      !bufferStoreInfo[storeId].cash_payment &&
      !bufferStoreInfo[storeId].card_payment &&
      bufferStoreInfo[storeId].online_payment
    ) {
      if (!successLogIn) {
        paymentBody = (
          <Button basic color="teal" size="medium" type="button" onClick={() => this.changeActive('online_payment')}>
            Input credit card
          </Button>
        );
      }
    } else {
      paymentBody = (
        <div className={cx('payment-container')}>
          {bufferStoreInfo[storeId] &&
            bufferStoreInfo[storeId].cash_payment && (
              <div className={cx('payment-cash')}>
                <RadioButton
                  active={paymentMethod === 'cash'}
                  onClick={() => this.changeActive('cash')}
                  label="Cash"
                  name="cash"
                />
              </div>
            )}
          {bufferStoreInfo[storeId] && bufferStoreInfo[storeId].card_payment ? (
            <div className={cx('payment-card')}>
              <RadioButton
                active={paymentMethod === 'card'}
                onClick={() => this.changeActive('card')}
                label="Card payment"
                name="card"
              />
            </div>
          ) : null}
          {bufferStoreInfo[storeId] && bufferStoreInfo[storeId].online_payment ? (
            <div className={cx('payment-card')}>
              <RadioButton
                active={successLogIn && paymentMethod === 'online_payment'}
                onClick={() => this.changeActive('online_payment')}
                label="Input credit card"
                name="online_payment"
              />
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div
        className={cx('payment-method-container')}
        style={paymentMethod === 'online_payment' ? { marginBottom: '0px' } : null}
      >
        <h1 style={paymentComment ? { marginBottom: '6px' } : null} className={cx('payment-title')}>
          {numberPosition}. Payment method
        </h1>
        {paymentComment && (
          <span
            style={{
              marginLeft: '32px',
              color: '#656565',
              fontWeight: 600,
              marginBottom: '12px',
              display: 'block',
            }}
          >
            {paymentComment}
          </span>
        )}
        {paymentBody}
      </div>
    );
  }
}

export default PaymentMethod;
