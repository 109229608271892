import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchCartInfo } from 'modules/cart/actions';
import { getStatusCart, getTotalQuantity } from 'modules/cart/selectors';

import { fetchDoctorInfo } from 'modules/doctor/actions';
import { getDoctorInfo } from 'modules/doctor/selectors';

import DoctorLayout from '../../components/DoctorLayout';

const DoctorLayoutContainer = (props) => {
  useEffect(() => {
    props.fetchDoctorInfo();
  }, [])

  return (
    <DoctorLayout {...props} />
  )
}

const mapStateToProps = (state) => ({
  statusCart: getStatusCart(state.cart),
  totalQuantity: getTotalQuantity(state.cart),
  doctorInfo: getDoctorInfo(state.doctor)
})

export default connect(mapStateToProps, {
  fetchCartInfo,
  fetchDoctorInfo,
})(DoctorLayoutContainer);
