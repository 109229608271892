import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  createShorthandFactory,
} from 'utils/lib';

import styles from './Header.scss';

const cx = classnames.bind(styles);

const HeaderSubheader = (props) => {
  const { children, className, content } = props;
  const classes = cx('sub', 'header', className);
  const rest = getUnhandledProps(HeaderSubheader, props);
  const ElementType = getElementType(HeaderSubheader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

HeaderSubheader.create = createShorthandFactory(HeaderSubheader, content => ({ content }));

export default HeaderSubheader;
