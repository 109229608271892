import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Statistic from 'components/Typography/Statistic/Statistic';
import StatisticValue from 'components/Typography/Statistic/StatisticValue';
import StatisticLabel from 'components/Typography/Statistic/StatisticLabel';
import Segment from 'components/Segment/Segment';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Icon from 'components/Icons/Icon';
import Loader from 'components/Loaders/Loader';
import Checkbox from 'components/CheckBox/Checkbox';

import styles from './PanelTools.module.scss';

const cx = classnames.bind(styles);

class PanelTools extends Component {
  state = {
    viewActionButtons: false,
  }

  handleViewActionButtons = () => {
    this.setState({
      viewActionButtons: !this.state.viewActionButtons,
    });
  }

  render() {
    const {
      isFetchingStoreProducts,
      productsOutOfStock,
      productsAvaiable,
      addProduct,
      selectAll,
      selectOutOfStockProducts,
      onHideCategories,
      onShowCategories,
      activeIndex,
      animateSelectAllProducts,
      deselectAll,
      productIds,
      massDeleteProducts,
      searchingProduct,
      activeSticky,
      handleToggleSticky,
    } = this.props;

    const ids = Object.keys(productIds);

    return (
      <Segment raised loading={isFetchingStoreProducts}>
        <div>
          <Statistic horizontal style={{ margin: '0em' }}>
            <StatisticValue>{productsAvaiable.length}</StatisticValue>
            <StatisticLabel>products avaible</StatisticLabel>
          </Statistic>
          <Statistic horizontal>
            <StatisticValue>{isFetchingStoreProducts ? <Loader inline center /> : productsOutOfStock.length}</StatisticValue>
            <StatisticLabel>out of stock</StatisticLabel>
          </Statistic>
          <Input className={cx('input-search')} icon="search" placeholder="Search product..." style={{ float: 'right', width: '35%' }} onChange={searchingProduct} />
        </div>
        <div className={cx('actions-block', !this.state.viewActionButtons ? 'actions-block-view' : null)}>
          <div style={{ marginRight: '1em', display: 'inline-block' }}>
            <Button size="small" type="button" color="teal" onClick={onHideCategories}>Hide</Button>
            <Button size="small" type="button" color="teal" onClick={onShowCategories}>Show</Button>
            {animateSelectAllProducts
              ? <Button style={{ marginTop: '0.25em' }} size="small" disabled={activeIndex.length === 0} type="button" color="teal" onClick={deselectAll}>{activeIndex.length === 0 ? 'no visible elements' : 'deselect all'}</Button>
              : <Button style={{ marginTop: '0.25em' }} size="small" disabled={activeIndex.length === 0} type="button" color="teal" onClick={selectAll}>{activeIndex.length === 0 ? 'no visible elements' : 'Select all'}</Button>
            }
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button size="small" disabled={ids.length === 0} type="button" onClick={selectOutOfStockProducts}>Trigger out-of-stock for selected</Button>
            <Button size="small" color="teal" type="button" onClick={addProduct}>Add product</Button>
            <Button style={{ marginTop: '0.25em' }} size="small" disabled={ids.length === 0} color="red" type="button" onClick={massDeleteProducts}>Delete product</Button>
          </div>
        </div>
        <div style={{ textAlign: 'center', paddingTop: '1em' }}>
          <Icon name={!this.state.viewActionButtons ? 'angle up' : 'angle down'} size="large" style={{ cursor: 'pointer' }} onClick={this.handleViewActionButtons} />
        </div>
        <div style={{ position: 'absolute', right: '14px', bottom: '10px' }}>
          <Checkbox toggle name="sticky" onClick={handleToggleSticky} checked={activeSticky} label="Activate Sticky" />
        </div>
      </Segment>
    );
  }
}

export default PanelTools;
