import React, { Component } from 'react';
import classnames from 'classnames/bind';
import queryString from 'query-string';
import treeChanges from 'tree-changes';

import { convertToDecimal } from 'services';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Image from 'components/Image/Image';
import Loader from 'components/Loaders/Loader';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Header from 'components/Typography/Header/Header';
import Rating from 'components/Rating';
import Icon from 'components/Icons/Icon';
import Bottle from 'components/Icons/Bottle';
import Segment from 'components/Segment/Segment';
import Form from 'components/Form/Form';
import TextArea from 'components/TextArea/TextArea';

import MDRender from 'services/MDRender';

import Comments from './components/Comments';
import Reviews from './components/Reviews';
import styles from './ModalProductInformation.css';

import Packing from '../ModalContent/components/Packing';
import Preloader from './components/Preloader';

const cx = classnames.bind(styles);

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];

const plantTypes = {
  Sativa: { color: '#FDD835', name: 'Sativa', icon: <Icon name="sun" inverted /> },
  Indica: { color: '#5094B1', name: 'Indica', icon: <Icon name="moon outline" inverted /> },
  Hybrid: { color: '#39D4CF', name: 'Hybrid', icon: <Icon name="magic" inverted /> },
  CBD: { color: '#edb3bf', name: 'CBD', icon: <Bottle /> },
  'Hybrid: Sativa-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #FDD835 85%)',
    name: 'Hybrid: Sativa',
    icon: <div style={{ display: 'inline-block' }}>
      <Icon name="magic" inverted /> :
      <Icon name="sun" inverted />
    </div>,
  },
  'Hybrid: Indica-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #5094B1 85%)',
    name: 'Hybrid: Indica',
    icon: <div style={{ display: 'inline-block', color: 'white' }}>
      <Icon name="magic" inverted /> :
      <Icon name="moon outline" inverted />
    </div>,
  },
  'Indica: Sativa-dominant': {
    color: 'linear-gradient(90deg, #5094B1 10%, #FDD835 85%)',
    name: 'Indica: Sativa',
    icon: <div style={{ display: 'inline-block' }}>
      <Icon name="moon outline" /> :
      <Icon name="sun" inverted />
    </div>,
  },
};

const getAveregeValue = async (data) => {
  const reduceRatings = data.length === 0 ? [data.rate] : data.reduce((acc, currValue) => acc + currValue);
  const averege_rate = reduceRatings.length === 1 ? data.rate : reduceRatings / data.length;

  return Math.round(averege_rate);
}

class ModalProductInformation extends Component {
  state = {
    price: 0,
    quantity: null,
    updateButton: false,
    removeButton: false,
    isOpenRatingComponent: true,
  };

  componentDidMount() {
    const { location, fetchProductInformation, card } = this.props;

    const productId = card ? card.id : queryString.parse(location.search).product;

    fetchProductInformation(productId);
  }

  async componentDidUpdate(prevProps) {
    const { changedTo } = treeChanges(prevProps, this.props);

    if (changedTo('newComment.status', 'success')) {
      await this.closeFormWithAddComment();
    }

    if (changedTo('updComment.status', 'success')) {
      await this.closeFormWithEditComment();
    }
  }

  setProduct = (quantity) => {
    this.setState({
      quantity,
      removeButton: false,
    });
    this.changeEventButton();
  }

  removeProduct = () => {
    this.setState({
      removeButton: true,
    });
  }

  removeProductFromCart = (product) => {
    const { addToCart, removeToCart, closeModal } = this.props;
    removeToCart(product);
    closeModal();
  };

  addToCart = (product) => {
    const { addToCart, closeModal } = this.props;
    let { quantity } = this.state;

    if (quantity === null) {
      if (product.packing === 'oz') {
        const steps = Object.keys(product.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a));
        quantity = steps[steps.length - 1];
      }

      if (product.packing === 'gram') {
        const steps = Object.keys(product.price).sort();
        const parseGrams = steps.map(value => parseFloat(value));
        parseGrams.sort((a, b) => a - b);
        quantity = parseGrams[0].toString();
      }

      if (product.packing === 'piece' || product.packing === 'piece_discrete') {
        quantity = 1;
      }

      if (product.packing === 'gram_oz') {
        const filterGramOz = [];
        Object.keys(product.price).forEach((price, index) => {
          if (GRAM_OZ.indexOf(price) >= 0) {
            filterGramOz.push({
              position: index,
              packing: price,
              cost: product.price[price],
            });
          }
        });
        const sortGramOz = filterGramOz
          .sort((a, b) => a.position - b.position)
          .map(value => value.packing);

        quantity = sortGramOz[0];
      }
    }
    addToCart(product, quantity);
    closeModal();
  };

  changeEventButton = () => {
    this.setState({
      updateButton: true,
    });
  };

  fetchProductComments = async (id) => {
    const { fetchProductComments } = this.props;

    fetchProductComments(id);
  }

  addComment = async () => {
    const { rate, comment } = this.state;
    const { product, storeInfo, addComment, productComments, setRatingForProductCard } = this.props;

    try {
      await addComment(product.product_id, { comment, rate, store_id: storeInfo.id }, true);
      const ratings = productComments.data.map(product => product.rate);
      const updRatings = [...ratings, rate];

      const averege_rate = await getAveregeValue(updRatings);

      this.setState({
        averegeRate: averege_rate,
      }, () => {
        setRatingForProductCard({ product_id: product.product_id, rate, averege_rate });
      })
    } catch (err) {
      console.log(err);
    }
  }

  editComment = async (item) => {
    this.setState({
      commentItem: item,
      isOpenFormWithEditComment: true,
    });
  }

  sendEditComment = async () => {
    const { newEditComment, newEditRating, commentItem } = this.state;
    const { product, storeInfo, updateComment, setRatingForProductCard, productComments } = this.props;

    const commentId = product.comment_id || commentItem.id;

    try {
      await updateComment(commentId, { comment: newEditComment, store_id: storeInfo.id, rate: newEditRating });
      await this.fetchProductComments(product.product_id);

      if (newEditRating) {
        const ratings =
          productComments.data
            .filter(value => value.id !== commentId)
            .map(product => product.rate)
        const updRatings = [...ratings, newEditRating];

        const averege_rate = await getAveregeValue(updRatings);

        this.setState({
          averegeRate: averege_rate,
        }, () => {
          setRatingForProductCard({ product_id: product.product_id, rate: newEditRating, averege_rate, });
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  openFormWithAddComment = (e, data) => {
    this.setState({
      rate: data.rating,
      isOpenFormWithAddComment: true,
    })
  }

  closeFormWithAddComment = async () => {
    this.setState({
      isOpenFormWithAddComment: false,
      isOpenRatingComponent: false,
    });
  }

  closeFormWithEditComment = async () => {
    this.setState({
      isOpenFormWithEditComment: false,
    });
  }

  render() {
    const {
      product,
      isFetching,
      itemsProducts,
      location,
      storeInfo,
      productComments,
      disabledModalButton,
      onBuyNow,
      onBackToShop,
      successLogIn,
      newComment,
    } = this.props;

    const { isOpenFormWithEditComment, isOpenFormWithAddComment, isOpenRatingComponent, averegeRate } = this.state;

    let itemProduct = null;

    if (itemsProducts && itemsProducts[queryString.parse(location.search).product]) {
      itemProduct = Object.assign({}, itemsProducts[queryString.parse(location.search).product]);
    }

    if (isFetching || product === null || typeof product === 'string') {
      return (
        <Container>
          <Preloader />
        </Container>
      );
    }

    let button = (
      <Button style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }} color="teal" type="button" onClick={() => this.addToCart(product)}>
        <Icon name="cart" />
        Add to Cart
      </Button>
    );

    if (product.in_cart) {
      button = (
        <Button style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }} color="teal" type="button" onClick={onBuyNow}>
          <Icon name="in cart" />
          Buy now
        </Button>
      );
    }
    if (product.in_cart && this.state.updateButton) {
      button = (
        <Button style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }} color="yellow" type="button" onClick={() => this.addToCart(product)}>
          <Icon name="undo" />
          Update now
        </Button>
      );
    }

    if (product.in_cart && this.state.removeButton) {
      button = (
        <Button
          style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', paddingLeft: '0.6em', paddingRight: '0.6em' }}
          color="red"
          type="button"
          onClick={() => this.removeProductFromCart(product)}
        >
          <Icon name="ban" />
          Remove product
        </Button>
      );
    }

    const brand = product.brand ? `${product.brand}` : '';

    const filterGramOz = [];

    if (product.packing === 'gram_oz') {
      Object.keys(product.price).forEach((price, index) => {
        if (GRAM_OZ.indexOf(price) >= 0) {
          filterGramOz.push({
            position: index,
            packing: price,
            cost: product.price[price],
          });
        }
      });
    }

    return (
      <Grid stackable className={cx('wrapper-product-information')}>
        <GridRow />
        <GridRow>
          <GridColumn width={10}>
            <div>
              <Header as="h3">
                {brand}
                {' '}
                {product.name}
              </Header>
            </div>
            <div style={{ marginTop: '0.5em' }}>
              <Rating className={cx('custom-rating')} disabled clearable icon="star" color="orange" rating={averegeRate || product && product.averege_rate} maxRating={5} />
              {/* <span style={{ marginLeft: '0.5em' }}>
                (
                {product.number_approved_comments || 0}
                {' '}
                comments)
              </span> */}
            </div>
            <div className={cx('product-main-information')}>
              <Image src={product && product.image.default} />
              {
                product && product.plant_type
                && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: plantTypes[product.plant_type].color,
                    borderRadius: '14px',
                    justifyContent: 'center',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    paddingTop: '0.2em',
                    position: 'absolute',
                    top: '10px',
                    right: 0,
                    marginRight: '1rem',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                  }}
                  >
                    <div style={{
                      color: 'white',
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                    >
                      {plantTypes[product.plant_type].name}
                    </div>
                    <div>
                      {plantTypes[product.plant_type].icon}
                    </div>
                  </div>
                )
              }
            </div>
            <div style={{ marginTop: '1.5em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {
                storeInfo
                && (
                  <div className={cx('sold-buy')}>
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Sold by:&nbsp;</span>
                    <span>{storeInfo.name}</span>
                  </div>
                )
              }
            </div>
            <div style={{ marginTop: '1.5em' }}>
              <div style={{
                fontSize: '1.14285714rem',
                color: 'rgba(0,0,0,.6)',
                lineHeight: '1.2em',
              }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: MDRender(product.description),
                  }}
                />
              </div>
            </div>
            <div className={cx('actions-container')}>
              <Packing
                itemProduct={itemProduct}
                price={product.price}
                filterGramOz={filterGramOz}
                packing={product.packing}
                setProduct={this.setProduct}
                removeProduct={this.removeProduct}
              />
              <div className={cx('group-buttons')}>
                <Button type="button" basic className={cx('button-back')} onClick={onBackToShop}>
                  <Icon name="arrow left" />
                  Back to Shop
                </Button>
                {disabledModalButton ? null : button}
              </div>
            </div>
            <Segment basic style={{ padding: '0em' }} loading={newComment && newComment.isCreatingProductComment}>
              {product && product.reviews
                && Object.keys(product.reviews).length > 0 && <Reviews reviews={product.reviews} />
              }
              {
                successLogIn && product && !product.comment_id && isOpenRatingComponent
                && (
                  <div className={cx('rate-wrapper')} style={{ marginTop: '3em', display: 'flex', alignItems: 'center' }}>
                    <div>
                      Rate it:
                    </div>
                    <div style={{ marginLeft: '0.5em', height: '22px' }}>
                      <Rating style={{ fontSize: '1.42857143rem' }} clearable size="huge" icon="star" maxRating={5} color="orange" onRate={this.openFormWithAddComment} />
                    </div>
                  </div>
                )
              }
              <Comments
                fetchProductComments={() => this.fetchProductComments(product.product_id)}
                productComments={productComments}
                commentId={product.comment_id}
                editComment={this.editComment}
                newComment={newComment && newComment.data}
                storeLogo={storeInfo && storeInfo.logo.default}
                isEditRating={isOpenFormWithEditComment}
                setNewRating={(e, data) => this.setState({ newEditRating: data.rating })}
              />
              {successLogIn && isOpenFormWithAddComment
                && (
                  <Form reply style={{ marginTop: '0.5em' }}>
                    <div style={{ margin: '0 0 1em' }}>
                      <TextArea placeholder="Write your comment here...Comment will be approved by admin." onChange={(e, data) => this.setState({ comment: data.value })} />
                    </div>
                    <Button
                      style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}
                      color="teal"
                      content="Add comment"
                      labelPosition="left"
                      icon="add"
                      primary
                      type="button"
                      onClick={this.addComment}
                    />
                  </Form>
                )
              }
              {
                isOpenFormWithEditComment
                && (
                  <Form reply style={{ marginTop: '0.5em' }}>
                    <div style={{ margin: '0 0 1em' }}>
                      <TextArea
                        defaultValue={this.state.commentItem.comment}
                        placeholder="Write your comment here...Comment will be approved by admin."
                        onChange={(e, data) => this.setState({ newEditComment: data.value })}
                      />
                    </div>
                    <Button
                      style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}
                      color="teal"
                      content="Edit comment"
                      labelPosition="left"
                      icon="edit"
                      primary
                      type="button"
                      onClick={this.sendEditComment}
                    />
                  </Form>
                )
              }
            </Segment>

          </GridColumn>
          <GridColumn width={6} className={cx('custom-grid-column')}>
            <div className={cx('image-wrapper')}>
              <Image src={product && product.image.default} />
            </div>
            {
              product && product.plant_type
              && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: plantTypes[product.plant_type].color,
                  borderRadius: '14px',
                  justifyContent: 'center',
                  paddingLeft: '1em',
                  paddingRight: '1em',
                  paddingTop: '0.35em',
                  paddingBottom: '0.25em',
                  position: 'absolute',
                  top: '1em',
                  right: '1em',
                  marginRight: '1rem',
                  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                }}
                >
                  <div style={{
                    color: 'white',
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                  >
                    {plantTypes[product.plant_type].name}
                  </div>
                  <div style={{ marginLeft: '0.25em' }}>
                    {plantTypes[product.plant_type].icon}
                  </div>
                </div>
              )
            }
            <Packing
              itemProduct={itemProduct}
              price={product.price}
              filterGramOz={filterGramOz}
              packing={product.packing}
              setProduct={this.setProduct}
              removeProduct={this.removeProduct}
            />
            <div className={cx('group-buttons')}>
              <Button type="button" onClick={onBackToShop} className={cx('button-back')}>
                <Icon name="arrow left" />
                Back to Shop
              </Button>
              {disabledModalButton ? null : button}
            </div>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default ModalProductInformation;
