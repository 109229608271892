import React, { Component, isValidElement } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import ListContent from './ListContent';
import ListHeader from './ListHeader';
import ListDesscription from './ListDescription';
import ListIcon from './ListIcon';

import styles from './List.scss';

const cx = classnames.bind(styles);

class ListItem extends Component {
  handleClick = (e) => {
    const { disabled } = this.props;

    if (!disabled) _.invoke(this.props, 'onClick', e, this.props);
  };

  render() {
    const {
      active,
      children,
      className,
      content,
      description,
      disabled,
      header,
      icon,
      image,
      value,
    } = this.props;

    const ElementType = getElementType(ListItem, this.props);
    const classes = cx(
      useKeyOnly(active, 'active'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(ElementType !== 'li', 'item'),
      className
    );
    const rest = getUnhandledProps(ListItem, this.props);
    const valueProp = ElementType === 'li' ? { value } : { 'data-value': value };

    if (!isNil(children)) {
      return (
        <ElementType
          {...valueProp}
          role="listitem"
          className={classes}
          onClick={this.handleClick}
          {...rest}
        >
          {children}
        </ElementType>
      );
    }

    const iconElement = ListIcon.create(icon, { autoGenerateKey: false });
    const imageElement = Image.create(image, { autoGenerateKey: false });

    if (!isValidElement(content) && _.isPlainObject(content)) {
      return (
        <ElementType
          {...valueProp}
          role="listitem"
          className={classes}
          onClick={this.handleClick}
          {...rest}
        >
          {iconElement || imageElement}
          {ListContent.create(content, {
            autoGenerateKey: false,
            defaultProps: { header, description },
          })}
        </ElementType>
      );
    }

    const headerElement = ListHeader.create(header, { autoGenerateKey: false });
    const descriptionElement = ListDesscription.create(description, { autoGenerateKey: false });

    if (iconElement || imageElement) {
      return (
        <ElementType
          {...valueProp}
          role="listitem"
          className={classes}
          onClick={this.handleClick}
          {...rest}
        >
          {iconElement || imageElement}
          {(content || headerElement || descriptionElement) && (
            <ListContent>
              {headerElement}
              {descriptionElement}
              {content}
            </ListContent>
          )}
        </ElementType>
      );
    }
    return (
      <ElementType
        {...valueProp}
        role="listitem"
        className={classes}
        onClick={this.handleClick}
        {...rest}
      >
        {headerElement}
        {descriptionElement}
        {content}
      </ElementType>
    );
  }
}

ListItem.create = createShorthandFactory(ListItem, content => ({ content }));

export default ListItem;
