import React from 'react';
import { PrevArrow } from 'images/svg/utils';

import classnames from 'classnames/bind';
import styles from './PrevArrowButton.module.scss';

const cx = classnames.bind(styles);

const PrevArrowButton = props => (
  <button
    id={`${props.id}_btn-prev`}
    className={cx('prev', props.customClassName)}
    onClick={() => props.toScroll(-1)}
  >
    <PrevArrow
      styles={{ fill: '#fff', position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
    />
  </button>
);

export default PrevArrowButton;
