const getEnvironment = () => {
  if (window.location) {
    if (window.location.host.indexOf('staging') >= 0) return 'staging';
    if (window.location.host.indexOf('preprod') >= 0) return 'preprod';
    if (window.location.host.indexOf('heallybuy') >= 0) return 'heallybuy';
  }

  return 'localhost';
};

const startRaven = (host) => {
  if (window.Raven) {
    if (host === 'staging') {
      window.Raven.config('https://10d4d99840f6423c909831d1029a116d@sentry.zimad.com/42').install();
    } else if (host === 'preprod') {
      window.Raven.config('https://2689e67c276a462bafe765dd0f8f11f7@sentry.zimad.com/41').install();
    } else if (host === 'heallybuy') {
      window.Raven.config('https://7ff82628ef0b4f09b8cecd670b6c8c4d@sentry.zimad.com/40').install();
    }
  }
};

const startFullStory = (host) => {
  if (host === 'heallybuy') {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_org = 'C816E';
    window._fs_namespace = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      g = m[e] = function (a, b) {
        g.q ? g.q.push([a, b]) : g._api(a, b);
      };
      g.q = [];
      o = n.createElement(t);
      o.async = 1;
      o.src = `https://${window._fs_host}/s/fs.js`;
      y = n.getElementsByTagName(t)[0];
      y.parentNode.insertBefore(o, y);
      g.identify = function (i, v) {
        g(l, { uid: i });
        if (v) g(l, v);
      };
      g.setUserVars = function (v) {
        g(l, v);
      };
      g.shutdown = function () {
        g('rec', !1);
      };
      g.restart = function () {
        g('rec', !0);
      };
      g.consent = function (a) {
        g('consent', !arguments.length || a);
      };
      g.identifyAccount = function (i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        g(o, v);
      };
      g.clearUserCookie = function () { };
    }(window, document, window._fs_namespace, 'script', 'user'));
  } else {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_org = 'CRR69';
    window._fs_namespace = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      g = m[e] = function (a, b) {
        g.q ? g.q.push([a, b]) : g._api(a, b);
      };
      g.q = [];
      o = n.createElement(t);
      o.async = 1;
      o.src = `https://${window._fs_host}/s/fs.js`;
      y = n.getElementsByTagName(t)[0];
      y.parentNode.insertBefore(o, y);
      g.identify = function (i, v) {
        g(l, { uid: i });
        if (v) g(l, v);
      };
      g.setUserVars = function (v) {
        g(l, v);
      };
      g.shutdown = function () {
        g('rec', !1);
      };
      g.restart = function () {
        g('rec', !0);
      };
      g.consent = function (a) {
        g('consent', !arguments.length || a);
      };
      g.identifyAccount = function (i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        g(o, v);
      };
      g.clearUserCookie = function () { };
    }(window, document, window._fs_namespace, 'script', 'user'));
  }
};

const startGtag = (id) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', id);
};

const createGtag = (host) => {
  const gtagId = host === 'heallybuy' ? 'UA-107902222-1' : 'UA-113995843-1';
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  script.type = 'text/javascript';
  script.async = true;

  const el = document.getElementsByTagName('script')[0];
  el.parentNode.insertBefore(script, el);

  startGtag(gtagId);
};

const createHubSpot = (host) => {
  if (host === 'heallybuy') {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/5248905.js';
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    const el = document.getElementsByTagName('script')[0];
    el.parentNode.insertBefore(script, el);
  }
};

export const runAnalytics = () => {
  const host = getEnvironment();

  startRaven(host);
  startFullStory(host);
  createGtag(host);
  createHubSpot(host);
};
