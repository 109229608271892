import React, { Component } from 'react';

import classnames from 'classnames/bind';
import { isNil, getElementType, getUnhandledProps, useKeyOnly } from 'utils/lib';
import Image from 'components/Image/Image';
import styles from './Card.css';
import CardContent from './CardContent';

const cx = classnames.bind(styles);

class Card extends Component {
  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) onClick(e, this.props);
  }

  render() {
    const {
      centered,
      children,
      className,
      color,
      content,
      description,
      extra,
      fluid,
      header,
      href,
      image,
      link,
      meta,
      onClick,
      raised,
    } = this.props;

    const classes = cx(
      'ui',
      color,
      useKeyOnly(centered, 'centered'),
      useKeyOnly(fluid, 'fluid'),
      useKeyOnly(link, 'link'),
      useKeyOnly(raised, 'raised'),
      'card',
      className
    );

    const rest = getUnhandledProps(Card, this.props);
    const ElementType = getElementType(Card, this.props, () => {
      if (onClick) return 'a';
    });

    if (!isNil(children)) {
      return (
        <ElementType {...rest} className={classes} href={href} onClick={this.handleClick}>
          {children}
        </ElementType>
      );
    }

    if (!isNil(content)) {
      return (
        <ElementType {...rest} className={classes} href={href} onClick={this.handleClick}>
          {content}
        </ElementType>
      );
    }

    return (
      <ElementType {...rest} className={classes} href={href} onClick={this.handleClick}>
        {Image.create(image, { autoGenerateKey: false })}
        {(description || header || meta) && (
          <CardContent description={description} header={header} meta={meta} />
        )}
        {extra && <CardContent extra={extra}>{extra}</CardContent>}
      </ElementType>
    );
  }
}

export default Card;
