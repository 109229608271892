import React from 'react';
import Icon from 'components/Icons/Icon';

import classnames from 'classnames/bind';
import styles from './NextArrow.module.scss';

const cx = classnames.bind(styles);

export default props => (
  <button
    style={props.disabled || window.isMobile ? { display: 'none' } : { display: 'block' }}
    id={`${props.id}_btn-next`}
    className={cx(props.customClassName)}
    onClick={() => props.toScroll(1)}
  >
    <Icon name="angle right" />
  </button>
);
