import * as constants from './constants';
import * as constantsStores from '../stores/constants';
import { request, removeQueryParameters } from 'services/api';
import uuid from 'uuid/v1';
import { saveLocalStorage, getLocalStorage, getLocallyDeliveryAddress } from 'services';
import messages from 'components/Messages/Errors/Coupon';

import notification from 'components/Notification';

const url = '/api/v1';

const generateDiscountMessage = (data) => {
  if (data.coupon_type === 'fix') {
    return `Your discount is $${data.coupon_amount}. Discount limit is ${
      data.coupon_max_value
      }% of total order price.`;
  }
  return `Your discount is ${data.coupon_amount}%. Discount limit is $${data.coupon_max_value}.`;
};

export const fetchCartInfo = (isFetchingCartInfo = true) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_CART_INFO],
    isFetchingCartInfo,
  });
  return request(`${url}/cart`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR' && json.error_code === 'cart.conflict') {
        dispatch({
          type: [constants.CART_CONFLICT],
        });
      } else {
        if (json.data.client_uid) saveLocalStorage('client_uid', json.data.client_uid);
        dispatch({
          type: [constants.FETCH_CART_INFO_SUCCESS],
          cartInfo: json.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_CART_INFO_FAIL],
        error: true,
      });
      throw err;
    });
};

export const fetchCartProducts = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_CART_PRODUCTS],
  });
  return request(`${url}/cart/products`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_CART_PRODUCTS_SUCCESS],
        cartProducts: json.data,
      });
      return Promise.resolve(json);
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_CART_PRODUCTS_FAIL],
        error: true,
      });
      notification.error({
        message: 'Error',
        description: 'Something wrong ;(',
      });
      throw err;
    });
};

export const resetCart = () => (dispatch) => {
  dispatch({
    type: [constants.RESET_CART],
  });
  return fetch(`${url}/cart`, { method: 'delete', credentials: 'include' })
    .then(response => response.json())
    .then((json) => {
      // saveLocalStorage('paymentMethod', '');
      dispatch({
        type: [constants.RESET_CART_SUCCESS],
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.RESET_CART_FAIL],
        error: true,
      });
      throw err;
    });
};

export const applyDiscountCode = (code, viewNotification = true, isApplyDC = false) => (dispatch) => {
  dispatch({
    type: [constants.APPLY_DISCOUNT_CODE],
    value: isApplyDC,
  });
  return fetch(`${url}/cart/coupon?coupon_code=${code}`, {
    method: 'GET',
    credentials: 'include',
  })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.APPLY_DISCOUNT_CODE_SUCCESS],
          couponCodeInfo: json.data,
        });
        if (viewNotification) {
          notification.success({
            message: 'Success',
            description: generateDiscountMessage(json.data),
          });
        }

        let listBrands = '';

        if (json.data.brands && json.data.brands.length > 0) {
          json.data.brands.forEach((brand) => {
            listBrands += `${brand}\n`;
          });
        }
        if (json.data.coupon_applied) {
          notification.info({
            message: 'Coupon is applicable to the brands:',
            description: !json.data.all_brands && listBrands.length > 0 ? listBrands : 'All brands',
            duration: 10,
            style: {
              whiteSpace: 'pre',
            },
          });
        }

        if (!json.data.all_brands && !json.data.coupon_applied) {
          notification.warning({
            message: 'Coupon is applicable to the brands:',
            description: !json.data.all_brands && listBrands.length > 0 ? listBrands : '',
            duration: 10,
            style: {
              whiteSpace: 'pre',
            },
          });
        }
      } else {
        // saveLocalStorage('coupon', '');
        dispatch({
          type: [constants.APPLY_DISCOUNT_CODE_FAIL],
        });
        if (viewNotification) {
          if (json.error_code === 'cart.coupon_not_store' && json.data) {
            notification.warning({
              message: 'Warning',
              description: messages.message_one.description(json.data),
              duration: 10,
            });
          } else {
            notification.warning({
              message: 'Warning',
              description: messages.message_two.description,
            });
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.APPLY_DISCOUNT_CODE_FAIL],
      });
      throw err;
    });
};

export const addToCartProduct = (
  product,
  quantity,
  reset = false,
  coupon,
  storeType,
  storeId
) => (dispatch) => {
  let client_uid = getLocalStorage('client_uid');

  if (!client_uid || client_uid === '') {
    const id = uuid();
    saveLocalStorage('client_uid', uuid).then(() => {
      client_uid = id;
    });
  }

  dispatch({
    type: [constants.ADD_TO_CART_PRODUCT],
    product,
    quantity,
  });
  return fetch(`${url}/cart/products`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ product_id: product.id, quantity, store_type: storeType, store_id: storeId, client_uid }),
    credentials: 'include',
  })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR') {
        if (json.error_code === 'cart.product_is_out_of_stock') {
          notification.warning({
            message: 'Warning',
            description: 'Some products in your cart are out of stock now. Refreshing cart',
          });
          dispatch(fetchCartProducts());
        }
      } else {
        dispatch({
          type: [constants.ADD_TO_CART_PRODUCT_SUCCESS],
          addProduct: json.data,
        });
        if (coupon) {
          dispatch(applyDiscountCode(coupon, false, true));
        }
        if (reset) {
          dispatch(resetCart());
        }

        if (window.fbq) {
          window.fbq('track', 'AddToCart');
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.ADD_TO_CART_PRODUCT_FAIL],
        error: true,
      });
      notification.error({
        message: 'Error',
        description: 'Something wrong.',
      });
      throw err;
    });
};

export const initializeCart = (storeType, storeId) => (dispatch) => {
  dispatch({
    type: [constants.INITIALIZE_CART],
  });
  let client_uid = getLocalStorage('client_uid');

  if (!client_uid || client_uid === '') {
    const id = uuid();
    saveLocalStorage('client_uid', uuid).then(() => {
      client_uid = id;
    });
  }

  return fetch(`${url}/cart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ store_type: storeType, store_id: storeId, client_uid }),
    credentials: 'include',
  })
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.INITIALIZE_CART_SUCCESS],
        payload: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.INITIALIZE_CART_FAIL],
        error: true,
      });
      notification.error({
        message: 'Error',
        description: 'Something wrong.',
      });
      throw err;
    });
};

export const removeFromCart = product => dispatch =>
  new Promise((resolve) => {
    dispatch({
      type: [constants.REMOVE_PRODUCT_FROM_CART],
      product,
    });
    resolve('OK');
  });

export const onCartPick = value => (dispatch) => {
  dispatch({
    type: [constants.CART_PICK],
  });
  return fetch(`${url}/cart/pick?type=${value}`, {
    method: 'POST',
    credentials: 'include',
  })
    .then(response => response.json())
    .then((json) => {
      // dispatch(fetchCartInfo());
      dispatch({
        type: [constants.CART_PICK_SUCCESS],
        cartInfo: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.CART_PICK_FAIL],
      });
      throw err;
    });
};

export const setDeliveryAddress = (deliveryAddress, resetStores = false) => (dispatch) => {
  if (resetStores) {
    dispatch({
      type: [constantsStores.RESET_STORES],
    });
  }

  dispatch({
    type: [constants.SET_DELIVERY_ADDRESS],
    deliveryAddress,
  });
};

export const setOrderNotes = orderNotes => (dispatch) => {
  dispatch({
    type: [constants.SET_ORDER_NOTES],
    orderNotes,
  });
};

export const setPhoneNumber = phoneNumber => (dispatch) => {
  dispatch({
    type: [constants.SET_PHONE_NUMBER],
    phoneNumber,
  });
};

export const setTotalQuantity = (totalQuantity, cartProducts) => (dispatch) => {
  dispatch({
    type: [constants.SET_TOTAL_QUANTITY],
    totalQuantity,
    cartProducts,
  });
};

export const setDiscountCode = discountCode => (dispatch) => {
  dispatch({
    type: [constants.SET_DISCOUNT_CODE],
    discountCode,
  });
};

export const removeAnimationFrameElement = value => (dispatch) => {
  dispatch({
    type: [constants.ANIMATION_FRAME],
    animationFrame: value,
  });
};

export const setPaymentMethod = paymentMethod => (dispatch) => {
  dispatch({
    type: [constants.SET_PAYMENT_METHOD],
    paymentMethod,
  });
};

export const setLocallyDeliveryAddress = address => (dispatch) => {
  dispatch({
    type: [constants.SET_LOCALLY_DELIVERY_ADDRESS],
    address,
  });
};

export const setLocallyAddressWithZip = addressWithZip => (dispatch) => {
  dispatch({
    type: [constants.SET_DELIVERY_ADDRESS_WITH_ZIP],
    addressWithZip,
  });
};

export const createCartInfoByHash = (hashId, router) => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_CART_INFO],
    isFetchingCartInfo: true,
  });

  try {
    const response = await request(`${url}/cart/create_by_hash?hash_id=${hashId}`);
    const json = await response.json();

    removeQueryParameters('hash_id', router);

    dispatch({
      type: [constants.FETCH_CART_INFO_SUCCESS],
      cartInfo: json.data,
    });
  } catch (err) {
    dispatch(fetchCartInfo());
  }
}
