import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useValueAndKey,
  useVerticalAlignProp,
} from 'utils/lib';

import styles from './List.scss';

import ListItem from './ListItem';

const cx = classnames.bind(styles);

class List extends Component {
  static propTypes = {
    animated: PropTypes.bool,
    divided: PropTypes.bool,
    verticalAlign: PropTypes.string,
  };

  handleItemOverrides = predefinedProps => ({
    onClick: (e, itemProps) => {
      _.invoke(predefinedProps, 'onClick', e, itemProps);
      _.invoke(this.props, 'onItemClick', e, itemProps);
    },
  });

  render() {
    const {
      animated,
      bulleted,
      celled,
      children,
      className,
      content,
      divided,
      floated,
      horizontal,
      inverted,
      items,
      link,
      ordered,
      relaxed,
      selection,
      size,
      verticalAlign,
    } = this.props;

    const classes = cx(
      'ui',
      size,
      useKeyOnly(animated, 'animated'),
      useKeyOnly(bulleted, 'bulleted'),
      useKeyOnly(celled, 'celled'),
      useKeyOnly(divided, 'divided'),
      useKeyOnly(horizontal, 'horizontal'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(link, 'link'),
      useKeyOnly(ordered, 'ordered'),
      useKeyOnly(selection, 'selection'),
      useKeyOrValueAndKey(relaxed, 'relaxed'),
      useValueAndKey(floated, 'floated'),
      useVerticalAlignProp(verticalAlign),
      'list',
      className
    );

    const rest = getUnhandledProps(List, this.props);
    const ElementType = getElementType(List, this.props);

    if (!isNil(children)) {
      return (
        <ElementType role="list" className={classes} {...rest}>
          {children}
        </ElementType>
      );
    }

    if (!isNil(content)) {
      return (
        <ElementType role="list" className={classes} {...rest}>
          {content}
        </ElementType>
      );
    }

    return (
      <ElementType role="list" className={classes} {...rest}>
        {_.map(items, item => ListItem.create(item, { overrideProps: this.handleItemOverrides }))}
      </ElementType>
    );
  }
}

export default List;
