import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './SearchBar.module.scss';

const cx = classnames.bind(styles);

class SearchBar extends Component {
  state = {
    selectAddress: '',
  }

  componentDidMount() {
    const { onFocusDefault } = this.props;
    onFocusDefault && window.addEventListener('focusout', this.handleFocusOut);
  }

  UNSAVE_componentWillMount() {
    const { onFocusDefault } = this.props;
    onFocusDefault && window.addEventListener('focusout', this.handleFocusOut);
  }

  handleFocusOut = (event) => {
    const { onSelect } = this.props;
    const { selectAddress } = this.state;

    if (window.isMobile && event.relatedTarget === null && selectAddress.length === 0 && this.suggestions && this.suggestions.length > 0) {
      this.setState({
        selectAddress: this.suggestions[0].description,
      }, () => {
        onSelect(this.suggestions[0].description);
      });
    }
  }

  handleChange = (address) => {
    const { onChange } = this.props;

    onChange(address);

    if (address.length === 0) {
      this.setState({
        selectAddress: '',
      });
    }

    if (window.fbq) {
      window.fbq('track', 'Search');
    }
  };

  handleSelect = (e, address) => {
    const { onSelect } = this.props;
    if (e.keyCode === 13) return;

    onSelect(address);
  };

  handleFocus = () => {
    const { handleFocus } = this.props;

    if (handleFocus) {
      handleFocus();
    }
  };

  render() {
    const { address, customOptions, customPlaceholder, sizeInput, onBackgroundInput, checkError, error, onFocusDefault, viewIcon, loading, disabled } = this.props;

    const options = customOptions || {
      componentRestrictions: { country: 'us' },
    };

    const placeholder = customPlaceholder || 'Enter address...';

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={(e, addressDelivery) => this.handleSelect(e, addressDelivery)}
        searchOptions={options}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          if (suggestions && suggestions.length > 0) {
            this.suggestions = suggestions;
          }
          return (
            <div className={cx('sb-container')}>
              <div className={cx('sb-input-container')}>
                <Input
                  icon={viewIcon && 'map marker alternate'}
                  iconPosition={viewIcon && 'left'}
                  size={sizeInput}
                  {...getInputProps({
                    placeholder,
                    className: cx('search-input', onBackgroundInput && 'search-input-background'),
                    onBlur: this.handleFocus,
                  })}
                  error={checkError && error}
                  onFocus={onFocusDefault || null}
                  loading={loading}
                  disabled={disabled}
                />
                {suggestions
                  && suggestions.length > 0 && (
                    <div className={cx('autocomplete-container')}>
                      {suggestions.map((suggestion) => {
                        const className = cx('suggestion-item', {
                          'suggestion-item--active': suggestion.active,
                        });

                        return (
                          /* eslint-disable react/jsx-key */
                          <div {...getSuggestionItemProps(suggestion, { className })}>
                            <strong>{suggestion.formattedSuggestion.mainText}</strong>
                            {' '}
                            <small>{suggestion.formattedSuggestion.secondaryText}</small>
                          </div>
                        );
                        /* eslint-enable react/jsx-key */
                      })}
                    </div>
                  )}
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default SearchBar;
