export const getSignInError = state => state.errorSignIn;
export const getSignInSuccess = state => state.successSignIn;
export const getSignInFetch = state => state.isFetchingSignIn;

export const getSignUpError = state => state.errorSignUp;
export const getSignUpSuccess = state => state.successSignUp;
export const getSignUpFetch = state => state.isFetchingSignUp;

export const getLogInInfo = state => state.successLogIn;

export const getStateModal = state => state.stateModal;

export const getSignUpInfo = (state) => {
  const signUpInfo = {
    errorSignUp: state.errorSignUp,
    successSignUp: state.successSignUp,
    isFetchingSignUp: state.isFetchingSignUp,
  };
  return signUpInfo;
};

export const getSignInInfo = (state) => {
  const signInInfo = {
    errorSignIn: state.errorSignIn,
    successSignIn: state.successSignIn,
    isFetchingSignIn: state.isFetchingSignIn,
  };

  return signInInfo;
};

export const getRenderContent = state => state.renderContent;

export const getUserInfo = state => state.userInfo;

export const getIsFetchingUserInfo = state => state.isFetchingUserInfo;

export const getResetPasswordMessages = state => state.resetPasswordMessages;

export const getResetPasswordToken = state => state.resetPasswordToken;

export const getUpdateUserInfo = (state) => {
  const userInfo = {};

  Object.keys(state.userInfo).map((info) => {
    userInfo[info] = {};
    userInfo[info].value = state.userInfo[info];
    userInfo[info].error = false;
  });

  return userInfo;
};

export const getToSendNewPasswordMessageFail = state => state.sendNewPasswordMessageFail;

export const getIsSaveUserProfile = state => state.isSaveUserProfile;
export const getIsPartnerLogin = state => state.isPartnerLogin;

export const gethUserAuthorizedWithHash = state => state.userAuthorizedWithHash;
