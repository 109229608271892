import React, { Component } from 'react';
import DropIn from 'braintree-web-drop-in-react';

import Loader from 'components/Loaders/Loader';
import Segment from 'components/Segment/Segment';

class PaymentOnline extends Component {
  state = {};

  componentDidMount() {
    const { fetchClientTokenForPaymentOnline, userInfo } = this.props;
    fetchClientTokenForPaymentOnline(userInfo.id);
  }

  setInstancePayment = (instance) => {
    const { setInstancePayment } = this.props;

    setInstancePayment(instance);
  };

  render() {
    const { onlinePayment } = this.props;

    if (onlinePayment.isFetchClientToken) {
      return (
        <Segment style={{ padding: '2em' }}>
          <Loader active inline='centered' />
        </Segment>
      );
    }

    return onlinePayment.clientToken ? (
      <DropIn
        options={{
          authorization: onlinePayment.clientToken,
          vaultManager: true,
          card: {
            overrides: {
              fields: {
                number: {
                  selector: '#card-number',
                  prefill: '',
                },
                expirationDate: {
                  prefill: '',
                },
              },
              styles: {
                input: {
                  'font-family': "'Proxima Nova Soft', sans-serif",
                  'color': '#656565'
                }
              },
            },
          },
        }}
        onInstance={instance => this.setInstancePayment(instance)}
      />
    ) : null;
  }
}

export default PaymentOnline;
