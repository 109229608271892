import React, { Component } from 'react';
import Form from 'components/Form/Form';
import Icon from 'components/Icons/Icon';

import CheckBox from 'components/CheckBox';
import Spinner from 'components/Loaders/Spin';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';

import Accordion from 'components/Accordion/Accordion';
import AccordionTitle from 'components/Accordion/AccordionTitle';
import AccordionContent from 'components/Accordion/AccordionContent';

import classnames from 'classnames/bind';
import styles from './SignUp.module.scss';

import { checkEmail } from 'services';

const cx = classnames.bind(styles);

const styleError = {
  border: '2px solid rgb(244, 67, 54)',
};

class SignUp extends Component {
  state = {
    userData: {
      firstname: {
        value: '',
        focus: false,
        error: false,
      },
      lastname: {
        value: '',
        focus: false,
        error: false,
      },
      email: {
        value: '',
        focus: false,
        error: false,
      },
      phone: {
        value: this.props.phoneNumber || '',
        focus: false,
        error: false,
      },
      password: {
        value: '',
        focus: false,
        error: false,
      },
      passwordRepeat: {
        value: '',
        focus: false,
        error: false,
      },
      recaptcha: {
        error: false,
      },
    },
    checked: 'client',
    termsAgree: false,
    termsAgreeError: false,
    showPasswordInput: false,
    showRepeatPasswordInput: false,
    activeIndex: 1
  };

  componentWillReceiveProps(nextProps) {
    const { signUpInfo } = this.props;
    const { userData } = this.state;

    if (
      signUpInfo.errorSignUp !== nextProps.signUpInfo.errorSignUp &&
      nextProps.signUpInfo.errorSignUp
    ) {
      const nextErrorSignUp = nextProps.signUpInfo.errorSignUp;

      Object.keys(nextErrorSignUp).forEach(field => {
        userData[field].error = nextErrorSignUp[field][0];
      });

      this.setState({
        userData,
      });
    }
  }

  onFocusField = (nameField, hint) => {
    const { userData } = this.state;

    // if (nameField === 'passwordRepeat' && ) {
    //   userData.password.error = false;
    // }

    Object.keys(userData).forEach(field => {
      if (field === nameField) {
        userData[field].focus = hint;
        userData[field].error = false;
      } else userData[field].focus = false;
    });
    this.setState({
      userData,
    });
  };

  onEmptyField = () => {
    const { userData } = this.state;
    let empty = false;

    Object.keys(userData).forEach(field => {
      if (userData[field].value === '' && field !== 'password' && field !== 'passwordRepeat') {
        userData[field].error = true;
        empty = true;
      }
    });

    return { userData, empty };
  };

  onChangeField = (value, field) => {
    const { userData } = this.state;

    userData[field].value = value;

    if (field === 'password') {
      if (this.onCheckPassword(value)) {
        userData[field].error = true;
        userData[field].focus = false;
      } else {
        userData[field].error = false;
      }
    } else if (field === 'passwordRepeat') {
      if (this.comparePasswords(value, userData.password.value) || this.onCheckPassword(value)) {
        userData[field].error = true;
        userData[field].focus = false;
      } else {
        userData[field].error = false;
      }
    } else {
      userData[field].error = false;
    }

    this.setState({
      userData,
    });
  };

  checkHeallyPassword = (password) => {
    if (password.length < 8) {
      return true;
    }
    return false;
  }

  checkClientPassword = (password) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

    if (regex.test(password)) {
      return false;
    }
    return true;
  }

  onCheckPassword = password => {
    const { stateModal } = this.props;
    if (stateModal === 'heally') {
      return this.checkHeallyPassword(password)
    }
    return this.checkClientPassword(password);
  };

  comparePasswords = (passwordOne, passwordTwo) => {
    if (passwordOne !== passwordTwo) {
      return true;
    }
    return false;
  };

  onChecked = check => {
    let checked = 'client';
    if (!check) checked = 'seller';

    this.setState({
      checked,
    });
  };

  onNotAgreeTerms = check => {
    this.setState({ termsAgree: check });

    if (check) {
      this.setState({
        termsAgreeError: false,
      });
    }
  };

  onCheckPhone = e => {
    const USNumber = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !USNumber[2]
      ? USNumber[1]
      : `(${USNumber[1]}) ${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;
    this.onChangeField(e.target.value, 'phone');
  };

  onSignUp = () => {
    const { onSignUp, stateModal, router } = this.props;
    const { userData, termsAgree } = this.state;

    Object.keys(userData).forEach(field => (userData[field].focus = false));
    userData.recaptcha.error = false;
    const userDataEmptyField = this.onEmptyField();

    if (userDataEmptyField.empty) {
      this.setState({
        userData: userDataEmptyField.userData,
      });
      return;
    }

    if (!checkEmail(userData.email.value)) {
      userData.email.error = true;
      this.setState({
        userData,
      });
      return;
    }

    // userData.password.error = this.onCheckPassword(userData.password.value);
    // userData.passwordRepeat.error = this.onCheckPassword(userData.password.value);

    // if (userData.password.error || userData.passwordRepeat.error) {
    //   this.setState({
    //     userData,
    //   });
    //   return;
    // }

    // if (this.comparePasswords(userData.password.value, userData.passwordRepeat.value)) {
    //   // userData.password.error = true;
    //   userData.passwordRepeat.error = true;
    //   Object.keys(userData).forEach(field => (userData[field].focus = false));
    //   // userData.passwordRepeat.focus = 'The passwords must match';
    //   this.setState({
    //     userData,
    //   });
    //   return;
    // }

    if (!termsAgree) {
      this.setState({
        termsAgreeError: true,
      });
      return;
    }

    this.setState({
      userData,
    });

    onSignUp(
      userData.firstname.value,
      userData.lastname.value,
      userData.email.value,
      userData.password.value,
      userData.passwordRepeat.value,
      userData.phone.value,
      stateModal,
      router
    );
  };

  goToTerms = () => {
    // Remove this method and all his call
    const { router, closeModal } = this.props;

    router.push('/terms');
    closeModal();
  };

  showPassword = () => {
    this.setState({
      showPasswordInput: !this.state.showPasswordInput,
    })
  }

  showRepeatPassword = () => {
    this.setState({
      showRepeatPasswordInput: !this.state.showRepeatPasswordInput,
    })
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { signUpInfo, renderForm, stateModal } = this.props;
    const { userData, activeIndex } = this.state;

    return (
      <Form loading={signUpInfo.isFetchingSignUp} indicator={<Spinner size="small" />}>
        <div className={cx('signup-container')}>
          {/* <div >
              <Icon style={{ fontSize: '20px' }} className={cx('arrow-back')} type="arrow-left" />
            </div> */}
          <h3 className={cx('signup-title')}>{stateModal === 'auth' ? 'seller sign up' : 'sign up'}</h3>
          <div className={cx('signup-firstname')}>
            <input
              style={userData.firstname.error ? styleError : null}
              className={cx('signup-firstname-input')}
              type="text"
              placeholder="First name"
              onFocus={() => this.onFocusField('firstname', 'Hint: John')}
              onChange={e => this.onChangeField(e.target.value.trim(), 'firstname')}
            />
            {userData.firstname.focus && (
              <span className={cx('signup-hint', 'fade-in-top')}>{userData.firstname.focus}</span>
            )}
          </div>
          <div className={cx('signup-lastname')}>
            <input
              style={userData.lastname.error ? styleError : null}
              className={cx('signup-lastname-input')}
              type="text"
              placeholder="Last name"
              onFocus={() => this.onFocusField('lastname', 'Hint: Doe')}
              onChange={e => this.onChangeField(e.target.value.trim(), 'lastname')}
            />
            {userData.lastname.focus && (
              <span className={cx('signup-hint')}>{userData.lastname.focus}</span>
            )}
          </div>
          <div className={cx('signup-email')}>
            <input
              style={userData.email.error ? styleError : null}
              className={cx('signup-email-input')}
              type="text"
              placeholder="E-mail (Login)"
              onFocus={() => this.onFocusField('email', 'Hint: test@test.com')}
              onChange={e => this.onChangeField(e.target.value.trim(), 'email')}
            />
            {userData.email.focus && (
              <span className={cx('signup-hint')}>{userData.email.focus}</span>
            )}
            {userData.email.error &&
              typeof userData.email.error === 'string' && (
                <span className={cx('signup-error')}>{`Email ${userData.email.error}`}</span>
              )}
          </div>
          <div className={cx('signup-phone')}>
            <input
              style={userData.phone.error ? styleError : null}
              className={cx('signup-phone-input')}
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Phone number"
              onFocus={() => this.onFocusField('phone', 'Hint: (123) 345-1122')}
              onChange={this.onCheckPhone}
              defaultValue={userData.phone.value}
            />
            {userData.phone.focus && (
              <span className={cx('signup-hint')}>{userData.phone.focus}</span>
            )}
          </div>
          <Accordion style={{ marginBottom: '1rem' }}>
            <AccordionTitle
              className={cx('additional-fields__title')}
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon style={{ verticalAlign: activeIndex === 0 ? 'inherit' : 'middle', transform: activeIndex === 0 ? 'rotate(0deg)' : 'rotate(-90deg)' }} name='dropdown' />
            Additional fields
            </AccordionTitle>
            <AccordionContent active={activeIndex === 0}>
              <div className={cx('signup-password')}>
                <Input
                  fluid
                  icon
                  placeholder="Password"
                  error={userData.password.error}
                  type={this.state.showPasswordInput ? 'text' : 'password'}
                  onFocus={
                    () => this.onFocusField('password', stateModal === 'heally' ?
                      'Hint: At least 8 characters' :
                      'Hint: At least 8 characters, one lowercase, one uppercase, one special char and one digit')}
                  onChange={e => this.onChangeField(e.target.value.trim(), 'password')}
                  className={cx('input-wrapper')}
                >
                  <input />
                  <Icon link style={{ cursor: 'pointer', pointerEvents: 'auto' }} name={this.state.showPasswordInput ? 'eye' : 'eye slash'} onClick={this.showPassword} />
                </Input>
                {userData.password.focus && (
                  <span className={cx('signup-hint')}>{userData.password.focus}</span>
                )}
                {userData.password.error &&
                  <span className={cx('signup-error')}>{stateModal === 'heally' ? 'At least 8 characters' : 'At least 8 characters, one lowercase, one uppercase, one special char and one digit'}</span>}
              </div>
              <div className={cx('signup-password-repeat')}>
                <Input
                  fluid
                  icon
                  type={this.state.showRepeatPasswordInput ? 'text' : 'password'}
                  placeholder="Password confirmation"
                  onFocus={() => this.onFocusField('passwordRepeat', 'Hint: The passwords must match ')}
                  onChange={e => this.onChangeField(e.target.value.trim(), 'passwordRepeat')}
                  error={userData.passwordRepeat.error}
                  className={cx('input-wrapper')}
                >
                  <input />
                  <Icon link style={{ cursor: 'pointer', pointerEvents: 'auto' }} name={this.state.showRepeatPasswordInput ? 'eye' : 'eye slash'} onClick={this.showRepeatPassword} />
                </Input>
                {userData.passwordRepeat.focus && (
                  <span className={cx('signup-hint')}>{userData.passwordRepeat.focus}</span>
                )}
                {userData.passwordRepeat.error &&
                  <span className={cx('signup-error')}>The passwords must match</span>
                }
              </div>
            </AccordionContent>
          </Accordion>
          {userData.recaptcha.error && (
            <span className={cx('error-message')}>{userData.recaptcha.error}</span>
          )}
          <div className={cx('terms-container')}>
            <CheckBox
              styles={this.state.termsAgreeError && styleError}
              checked={this.state.termsAgree}
              onChecked={this.onNotAgreeTerms}
            />
            <div className={cx('terms-title')}>
              &nbsp;&nbsp;Agree with the&nbsp;
              <a target="_blank" href="/terms" className={cx('extraterms')}>
                Terms of Service
              </a>
            </div>
          </div>
          <Button type="button" color="blue" fluid onClick={this.onSignUp} style={{ marginBottom: '1em' }}>
            sign up
          </Button>
          <Button type="button" color="lightblue" fluid onClick={() => renderForm('signin')} style={{ marginBottom: '1em' }}>
            <Icon name="arrow left" /> Log in
          </Button>
        </div>
      </Form>
    );
  }
}

export default SignUp;
