import React, { PureComponent } from 'react';
import DeliveryLinks from 'mocks/deliveryLinks';
import Header from 'components/Typography/Header/Header';

import classnames from 'classnames/bind';
import styles from './DeliveryMessage.module.scss';

const cx = classnames.bind(styles);

class DeliveryMessage extends PureComponent {
  render() {
    const { messages, sameDay, goToOvernightStores, goToSameDayStores } = this.props;
    return (
      <div className={cx('delivery-message-wrapper')}>
        {sameDay ? (
          <div className={cx('delivery-message-content')}>
            <Header as="h3" style={{ color: '#636363', fontWeight: 500 }}>
              <span>{messages.multiple_same_day.message_text}</span>
              <DeliveryLinks
                index={messages.multiple_same_day.message_link}
                goToOvernightStores={goToOvernightStores}
                goToSameDayStores={goToSameDayStores}
              />
            </Header>
          </div>
        ) : (
            <div className={cx('delivery-message-content')}>
              <Header as="h3" style={{ color: '#636363', fontWeight: 500 }}>
                <span>{messages.multiple_next_day.message_text}</span>
                <DeliveryLinks
                  index={messages.multiple_next_day.message_link}
                  goToOvernightStores={goToOvernightStores}
                  goToSameDayStores={goToSameDayStores}
                />
              </Header>
            </div>
          )}
      </div>
    );
  }
}

export default DeliveryMessage;
