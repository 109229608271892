import * as constants from './constants';
import * as constantsCart from '../cart/constants';
import { fetchCartProducts, fetchCartInfo } from '../cart/actions';
import { fetchShippingCostInfo } from '../stores/actions';
import * as constantsUser from '../user/constants';
import { request, generateQuery } from 'services/api';
import debounce from 'services/debounce';
import { saveLocalStorage } from 'services';
import { geocodeByAddress } from 'react-places-autocomplete';
import messages from 'components/Messages/Errors/Coupon';

import notification from 'components/Notification';

const url = '/api/v1';
const errorMessageListOrders = 'Error: Sorry, something wrong ;(';
const productIsMissing = 'Some products are out of stock. Please recheck your cart';
const cartIssues = 'Sorry, prices for some products were updated. Please recheck the cart.';

const getUrl = (data) => {
  const urlSting = data.map(value =>
    fetch(`${url}/orders/${value.id}/products`, {
      credentials: 'include',
    })
      .then(response => response.json())
      .then(json => ({
        products: json.data,
        order: value,
      })));

  return urlSting;
};

const fetchCartInfoAndProducts = debounce((dispatch) => {
  dispatch(fetchCartInfo());
  dispatch(fetchCartProducts());
}, 1000);

export const createOrder = (
  paymentMethod,
  address,
  notes,
  phoneNumber,
  recommendation,
  couponCode,
  from_getheally,
  storeId,
  nonce,
  brandShortName,
  fullDeliveryAddress,
) => (dispatch) => {
  // saveLocalStorage('withLogin', '');
  dispatch({
    type: [constants.CREATE_ORDER],
  });
  dispatch({
    type: [constantsCart.FETCH_CART_PRODUCTS],
  });

  return request(`${url}/orders`, 'POST', {
    is_card_payment: paymentMethod === 'card',
    address: `${address.data.streetNameForInputCart}, ${address.data.addressWithZipForInputCart}`,
    notes,
    cell_phone: phoneNumber,
    location: address.data.location,
    zip: address.data.zip,
    confirm_recommendation: recommendation,
    coupon_code: couponCode,
    nonce,
    is_online_payment: paymentMethod === 'online_payment',
    brand_short_name: brandShortName,
  })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR' || json.status === 500) {
        if (json.error_code === 'order.cart_not_ready') {
          window.location.reload();
          return;
        }

        if (json.error_code === 'order.id_photo_fault') {
          dispatch({
            type: [constants.OPEN_MODAL_WITH_PHOTO_ID],
          });
          dispatch({
            type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
            error: false,
          });
        }

        if (json.error_code === 'order.recommendation_fault') {
          dispatch({
            type: [constants.OPEN_MODAL_RECOMMENDATION],
          });
          dispatch({
            type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
            error: false,
          });
        }

        if (json.error_code === 'order.cart_has_issues') {
          notification.warning({
            message: 'Warning',
            description: cartIssues,
            duration: 10,
          });
          fetchCartInfoAndProducts(dispatch);

          dispatch({
            type: [constants.CART_HAS_ISSUES],
          });
        }

        if (json.error_code === 'order.coupon_ivalid' || json.error_code === 'order.coupon_not_store' || json.error_code === 'order.coupon_already_taken') {
          // saveLocalStorage('coupon', '');
          notification.warning({
            message: 'Warning',
            description: json.error_code === 'order.coupon_ivalid' || json.error_code === 'order.coupon_already_taken' ? messages.message_two.description : messages.message_one.description(json.data),
            duration: json.error_code === 'order.coupon_ivalid' || json.error_code === 'order.coupon_already_taken' ? 4.5 : 10,
          });
          if (window.location.search.indexOf('from_getheally') < 0) {
            fetchCartInfoAndProducts(dispatch);
          } else {
            dispatch(fetchCartProducts());
          }
          dispatch({
            type: [constants.CART_HAS_PROBLEM_WITH_COUPON],
          });
          dispatch({
            type: [constantsCart.APPLY_DISCOUNT_CODE_FAIL],
          });
        }

        if (json.error_code === 'order.card_payment_failed') {
          let description = '';
          if (json.data && json.data.length > 0) {
            json.data.forEach((err) => {
              description += `${err}\n`;
            });
          } else {
            description = 'Payment transaction declined. Please revise your cart info.';
          }

          notification.warning({
            message: 'Warning',
            description,
          });

          if (window.location.search.indexOf('from_getheally') >= 0) {
            dispatch(fetchCartProducts());
          } else {
            dispatch({
              type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
              error: false,
            });
          }
          dispatch({
            type: [constants.RELOAD_PAYMENT_ONLINE_COMPONENT],
            value: true,
          });

          dispatch({
            type: [constants.CREATE_ORDER_FAIL],
          });
        }

        if (json.error_code === 'order.min_price_requirements_error' || json.status === 500 || json.error_code === 'order.location_not_present') {
          if (json.status === 500) {
            notification.warning({
              message: 'Warning',
              description: 'There is connection problem, please revise your order/payment data and try again',
            });
          } else if (json.error_code === 'order.min_price_requirements_error') {
            notification.warning({
              message: 'Warning',
              description: `The minimum order price for this store is $${Math.ceil(json.data.min_price)}`,
            });
          } else {
            notification.warning({
              message: 'Warning',
              description: 'There was a problem with the delivery address.'
            })
          }

          if (window.location.search.indexOf('from_getheally') >= 0) {
            dispatch(fetchCartProducts());
          } else {
            dispatch({
              type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
              error: false,
            });
          }
          dispatch({
            type: [constants.CREATE_ORDER_FAIL],
          });
        }

        if (storeId) {
          dispatch(fetchShippingCostInfo(storeId, address));
        }
      } else if (json.status === 'SUCCESS') {
        // saveLocalStorage('coupon', '');
        // saveLocalStorage('orderNotes', '');
        // saveLocalStorage('paymentMethod', '');

        if (json.data.require_id_photo) {
          dispatch({
            type: [constants.REQUIRE_PHOTO_ID],
            payload: true,
          })
        }
        dispatch({
          type: [constants.CREATE_ORDER_SUCCESS],
          currentOrder: json.data,
        });
        dispatch({
          type: [constantsCart.SET_TOTAL_QUANTITY],
          totalQuantity: 0,
          cartProducts: [],
        });
        dispatch({
          type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
          error: false,
        });
        dispatch({
          type: [constantsCart.SET_DISCOUNT_CODE],
          discountCode: '',
        });
        dispatch({
          type: [constantsCart.RESET_INFO_CART],
        });
        dispatch({
          type: [constantsCart.RESET_STATE_PRICES_AND_COSTS]
        });

        if (window.fbq) {
          if (paymentMethod === 'online_payment') {
            window.fbq('track', 'AddPaymentInfo');
          }

          window.fbq('track', 'Purchase', { value: json.data.result_price, currency: 'USD' });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.CREATE_ORDER_FAIL],
      });
      notification.error({
        message: 'Error',
        description: errorMessageListOrders,
      });
      throw err;
    });
};

export const fetchUserOrders = () => (dispatch) => {
  dispatch({
    type: [constants.FETCH_USER_ORDERS],
  });

  return request(`${url}/orders`).then((response) => {
    if (response.status === 401) {
      dispatch({
        type: [constantsUser.USER_NOT_AUTHORIZE],
      });
    } else {
      response
        .json()
        .then((json) => {
          if (json.status === 'SUCCESS') {
            const processing = json.data
              .filter(value =>
                value.status === 'processing' ||
                value.status === 'processed' ||
                value.status === 'received')
              .sort((a, b) => b.id - a.id);

            const received = json.data
              .filter(value => value.status === 'completed' || value.status === 'rejected')
              .sort((a, b) => b.id - a.id);
            const urlProcessing = getUrl(processing);
            const urlReceived = getUrl(received);
            Promise.all(urlProcessing)
              .then((json) => {
                Promise.all(urlReceived)
                  .then((data) => {
                    dispatch({
                      type: [constants.FETCH_USER_ORDERS_SUCCESS],
                      listOrders: json,
                      receivedOrders: data,
                    });
                  })
                  .catch((err) => {
                    dispatch({
                      type: [constants.FETCH_USER_ORDERS_FAIL],
                    });
                    notification.error({
                      message: 'Error',
                      description: errorMessageListOrders,
                    });
                  });
              })
              .catch((err) => {
                dispatch({
                  type: [constants.FETCH_USER_ORDERS_FAIL],
                });
                notification.error({
                  message: 'Error',
                  description: errorMessageListOrders,
                });
              });
          }
        })
        .catch((err) => {
          dispatch({
            type: [constants.FETCH_USER_ORDERS_FAIL],
          });
          // toast.error(errorMessageListOrders);
          throw err;
        });
    }
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: [constants.CLOSE_MODAL],
  });
};

export const closeOrderFailToast = () => (dispatch) => {
  dispatch({
    type: [constants.CLOSE_ORDER_FAIL_TOAST],
  });
};

export const addOrdersToCart = (uri, order) => (dispatch) => {
  dispatch({
    type: [constants.ADD_ORDERS_TO_CART],
  });
  return fetch(`${uri}`, {
    credentials: 'include',
    method: 'POST',
  })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        if (json.meta && json.meta.issues && json.meta.issues.length > 0) {
          const missingProduct = json.meta.issues.filter(issue => issue.type === 'missing');

          if (missingProduct.length > 0) {
            notification.warning({
              message: 'Warning',
              description: productIsMissing,
              duration: 10,
            });
          }
        } else {
          notification.success({
            message: 'Success',
            description: 'Orders successfully added to your cart',
          });
        }
        dispatch({
          type: [constants.ADD_ORDERS_TO_CART_SUCCESS],
        });
        const prices = {
          sumPrice: json.data.sum_price || 0,
          discountPrice: json.data.discount_price || 0,
          taxPrice: json.data.tax || 0,
          shippingPrice: json.data.shipping_price || 0,
        };

        const costs = {
          taxCost: json.data.tax_cost || 0,
          discountCost: json.data.discount_cost || 0,
          shippingCost: json.data.shipping_cost || 0,
        };

        dispatch({
          type: [constantsCart.FETCH_CART_INFO_SUCCESS],
          cartInfo: json.data,
          prices,
          costs,
          resultPrice: json.data.result_price || 0,
        });
      }

      if (json.status === 'ERROR') {
        if (json.error_code === 'order.store_not_approved') {
          dispatch({
            type: [constants.INACTIVE_STORE],
            inactiveStore: order.address,
          });
        }

        if (json.error_code === 'order.cart_empty_items_list') {
          dispatch({
            type: [constants.ADD_ORDERS_TO_CART_FAIL],
          });
          notification.error({
            message: 'Error',
            description: 'Sorry, all products in this order are out-of-stock',
          });
        }
        if (json.error_code === 'order.cart_already_exists') {
          dispatch({
            type: [constants.ADD_ORDERS_TO_CART_FAIL],
          });
          notification.error({
            message: 'Error',
            description: 'Please complete purchase in your cart',
          });
        }

        if (json.error_code === 'order.store_type_invalid') {
          if (json.data && json.data.store_type === 'neither') {
            dispatch({
              type: [constants.ADD_ORDERS_TO_CART_FAIL],
            });
            notification.error({
              message: 'Error',
              description: 'Sorry, but store is closed!',
            });
          } else {
            const orderInfo = {
              storeType: json.data.store_type,
              orderId: order.id,
              storeId: order.store_id,
            };
            dispatch({
              type: [constants.OPEN_MODAL_CHANGE_STORE_TYPE],
              orderInfo,
            });
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.ADD_ORDERS_TO_CART_FAIL],
      });
      throw err;
    });
};

export const closeModalStoreType = () => (dispatch) => {
  dispatch({
    type: [constants.CLOSE_MODAL_CHANGE_STORE_TYPE],
  });
};

export const closeModalRecommendation = () => (dispatch) => {
  dispatch({
    type: [constants.CLOSE_MODAL_RECOMMENDATION],
  });
  dispatch(fetchCartProducts());
};

export const onContinueOrderAgain = (order, force = false) => (dispatch) => {
  let uri = `${url}/orders/${order.orderId}/to_cart?type=${order.storeType}`;
  if (force) uri = `${uri}&force=true`;

  dispatch(addOrdersToCart(uri, order));
};

export const checkAuthUser = auth => (dispatch) => {
  dispatch({
    type: [constants.CHECK_AUTH_USER],
    auth,
  });
};

export const fetchOrderInformation = hash => (dispatch) => {
  dispatch({
    type: [constants.FETCH_ORDER_INFORMAION],
  });
  return request(`${url}/orders/instant_view/${hash}`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.FETCH_ORDER_INFORMAION_SUCCESS],
        payload: json.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_ORDER_INFORMATION_FAIL],
      });
    });
};

export const closeInActiveStoreModal = () => (dispatch) => {
  dispatch({
    type: [constants.INACTIVE_STORE],
    inactiveStore: false,
  });
};

export const disabledReloadComponent = () => (dispatch) => {
  dispatch({
    type: [constants.RELOAD_PAYMENT_ONLINE_COMPONENT],
    value: false,
  });
};

export const closeModalWithPhotoId = () => (dispatch) => {
  dispatch({
    type: [constants.CLOSE_MODAL_WITH_PHOTO_ID],
  });

  dispatch({
    type: [constantsCart.FETCH_CART_PRODUCTS_FAIL],
    error: false,
  });
};
