import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import * as constants from './constants';

const initialState = {
  brand: null,
  theme: 'main',
  isFetchingBrand: false,
  childrenComponentForEmptyPage: 'searchAddressComponent',
  nameCategoryForHeaderButton: 'cbd',
  deliveryAddress: {
    data: {
      enteringAddress: '',
      location: '',
      streetNameForInputCart: '',
      addressWithZipForInputCart: '',
      addressForQuery: '',
    },
    isSettingDeliveryAddress: false,
    isChangingDeliveryAddress: false,
    error: null
  },
  authPosition: {
    type: ''
  }
};

export default handleActions(
  {
    [constants.SET_BRAND]: (state, action) => ({
      ...state,
      isFetchingBrand: true,
    }),

    [constants.SET_BRAND_SUCCESS]: (state, action) => ({
      ...state,
      brand: action.brand,
      isFetchingBrand: false,
    }),

    [constants.SET_BRAND_FAIL]: (state, action) => ({
      ...state,
      brand: null,
      isFetchingBrand: false,
    }),

    [constants.SET_CHILDREN_COMPONENT_FOR_EMPTY_PAGE]: (state, action) => ({
      ...state,
      childrenComponentForEmptyPage: action.childrenComponentForEmptyPage,
    }),

    [constants.SET_NEW_CATEGORY_FOR_HEADER_BUTTON]: (state, action) => ({
      ...state,
      nameCategoryForHeaderButton: action.name,
    }),

    [constants.SET_DELIVERY_ADDRESS]: (state, action) => {
      if (state.deliveryAddress) {
        return immutable(state, {
          deliveryAddress: {
            isSettingDeliveryAddress: { $set: true },
            error: { $set: null }
          }
        })
      }
      return immutable(state, {
        deliveryAddress: {
          $set: {
            data: { $set: {} },
            isSettingDeliveryAddress: { $set: true },
            error: { $set: null }
          }
        }
      })
    },
    [constants.SET_DELIVERY_ADDRESS_SUCCESS]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          data: { $set: { ...action.payload } },
          isSettingDeliveryAddress: { $set: false },
        }
      }),
    [constants.SET_DELIVERY_ADDRESS_FAIL]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          isSettingDeliveryAddress: { $set: false },
          error: { $set: 'error' },
        }
      }),

    [constants.CHANGE_DELIVERY_ADDRESS_SUCCESS]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          data: {
            [action.field]: { $set: action.value }
          },
          isChangingDeliveryAddress: { $set: false },
        }
      }),
    [constants.CHANGE_DELIVERY_ADDRESS_FAIL]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          isChangingDeliveryAddress: { $set: false },
        }
      }),

    [constants.SELECT_DELIVERY_ADDRESS]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          isChangingDeliveryAddress: { $set: true },
        }
      }),
    [constants.SELECT_DELIVERY_ADDRESS_SUCCESS]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          data: {
            [action.field]: { $set: action.value },
            location: { $set: action.location },
            addressForQuery: { $set: action.addressForQuery },
          },
          isChangingDeliveryAddress: { $set: false }
        }
      }),
    [constants.SELECT_DELIVERY_ADDRESS_FAIL]: (state, action) =>
      immutable(state, {
        deliveryAddress: {
          isChangingDeliveryAddress: { $set: false }
        }
      }),

    [constants.SET_AUTH_POSITION]: (state, action) =>
      immutable(state, {
        authPosition: {
          type: { $set: action.type }
        }
      })

  },
  initialState
);
