import React from 'react';
import classnames from 'classnames/bind';

import { getUnhandledProps, useKeyOnly } from 'utils/lib';

import styles from './Accordion.css';
import AccordionAccordion from './AccordionAccordion';

const cx = classnames.bind(styles);

const Accordion = (props) => {
  const { className, fluid, inverted, styled } = props;

  const classes = cx(
    'ui',
    useKeyOnly(fluid, 'fluid'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(styled, 'styled'),
    className
  );

  const rest = getUnhandledProps(Accordion, props);

  return <AccordionAccordion {...rest} className={classes} />;
};

export default Accordion;
