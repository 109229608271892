import { connect } from 'react-redux';
import Stores from '../components/Stores';

import {
  fetchStores,
  filterStoresSupportCreditCard,
  saveInfoStoreQuery,
  handleSameDayDelivery,
  handleGoBackToProducts,
  changeDeliveryOption,
  resetStoresAndStoreInformation,
} from 'modules/stores/actions';

import {
  setDeliveryAddress,
} from 'modules/base/actions';

import { getStores, getDeliveryMessage, getSameDay } from 'modules/stores/selectors';

import { getTotalQuantity } from 'modules/cart/selectors';
import { getBrandInformation, getDeliveryAddress } from 'modules/base/selectors';

const mapStateToProps = state => ({
  stores: getStores(state.stores),
  totalQuantity: getTotalQuantity(state.cart),
  deliveryAddress: getDeliveryAddress(state.base),
  brandInformation: getBrandInformation(state.base),
  deliveryMessage: getDeliveryMessage(state.stores),
  sameDay: getSameDay(state.stores),
});

export default connect(
  mapStateToProps,
  {
    fetchStores,
    filterStoresSupportCreditCard,
    saveInfoStoreQuery,
    handleSameDayDelivery,
    handleGoBackToProducts,
    changeDeliveryOption,
    setDeliveryAddress,
    resetStoresAndStoreInformation,
  }
)(Stores);
