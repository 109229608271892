import React, { Component, createRef } from 'react';
import classnames from 'classnames/bind';

import { withGoogleMap, GoogleMap, Marker, Polygon, InfoWindow } from 'react-google-maps';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Container from 'components/Container/Container';
import Segment from 'components/Segment/Segment';
import Header from 'components/Typography/Header/Header';
import Image from 'components/Image/Image';
import Label from 'components/Label/Label';

import Button from 'components/Button/Button';
import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Input/Input';
import Transition from 'components/Transition/Transition';
import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';
import GridRow from 'components/Grid/GridRow';
import Icon from 'components/Icons/Icon';
import Sticky from 'components/Sticky';

import Statistic from 'components/Typography/Statistic/Statistic';
import StatisticValue from 'components/Typography/Statistic/StatisticValue';
import StatisticLabel from 'components/Typography/Statistic/StatisticLabel';

import notification from 'components/Notification';

import Main from './components/Sections/Main';
import Secondary from './components/Sections/Secondary';
import TableWithShippingAddresses from './components/TableWithShippingAddresses';
import ModalBody from './components/ModalBody';

import styles from './DeliveryLocation.module.scss';

const cx = classnames.bind(styles);


class DeliveryLocation extends Component {
  state = {
    address: '',
    modalOpen: false,
    error: false,
    selectAddress: '',
    isOpen: false,
    polygons: [],
    paths: [],
    position: {
      lat: 36.778259,
      lng: -119.417931,
    },
    isSearchingGooglePlace: false,
    centerCoordinates: null,
  };

  contextRef = createRef();

  componentDidMount() {
    const { fetchPolygonsForStore, storeInformation, fetchDeliveryLocationIds } = this.props;
    const { id } = storeInformation;

    fetchPolygonsForStore(id);
    //fetchDeliveryLocationIds(id);
  }

  handleClose = (saveData = false, position) => {
    this.setState({ modalOpen: false }, () => {
      if (saveData === 'success_save') {
        notification.success({
          message: 'Success',
          description: 'New delivery location added successfully.',
        });

        this.setPosition(position);
      }
    });
  };


  onToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleOpen = () => this.setState({ modalOpen: true, selectAddress: '' });

  createDeliveryLocation = (minShippingPrice) => {
    const { createDeliveryLocation, storeInformation, listWithDeliveryLocations } = this.props;
    const { formattedAddress, normalizedAddress, paths, position } = this.state;

    if (!formattedAddress || !normalizedAddress) return;

    return new Promise((resolve, reject) => {
      createDeliveryLocation(
        minShippingPrice,
        formattedAddress,
        normalizedAddress,
        storeInformation.id,
        paths,
        position,
        listWithDeliveryLocations
      )
        .then(() => {
          this.setState({
            formattedAddress: '',
            normalizedAddress: '',
            selectAddress: '',
            paths: [],
            position: {
              lat: 36.778259,
              lng: -119.417931,
            },
          }, () => {
            this.handleClose();
          });
        });
    });
  }

  setPosition = (position) => {
    this.setState({
      centerCoordinates: position,
    })
  }

  render() {
    const {
      polygonsForStore,
      isFetchingPolygonsForStore,
      fetchDeliveryLocationData,
      deliveryLocationData,
      isFetchingDeliveryLocationData,
      listWithDeliveryLocations,
      isFethchingListWithDeliveryLocation,
      deleteDeliveryLocation,
      storeInformation,
      updateDeliveryLocation,
      rerenderTable,
      createDeliveryLocation,
    } = this.props;
    const { selectAddress, isSearchingGooglePlace, centerCoordinates } = this.state;

    const valueListWithDeliveryLocation = Object.keys(listWithDeliveryLocations).length;

    return (
      <Container style={{ marginTop: '1.5em', marginBottom: '1.5em' }} className={cx('container-custom')}>
        <Grid className={cx('grid-custom')}>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised>
              <Statistic horizontal style={{ margin: 0 }}>
                <StatisticValue>{valueListWithDeliveryLocation}</StatisticValue>
                <StatisticLabel>shipping addresses</StatisticLabel>
              </Statistic>
              {/* <Statistic horizontal>
                <StatisticValue>12</StatisticValue>
                <StatisticLabel>out of stock</StatisticLabel>
              </Statistic> */}
              <Button style={{ marginTop: '0.25em' }} floated="right" color="teal" onClick={this.handleOpen}><Icon name="map marker alternate" />Create shipping address</Button>
            </Segment>
          </GridColumn>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised loading={isFetchingPolygonsForStore} style={isFetchingPolygonsForStore ? { height: '400px' } : null}>
              {polygonsForStore && (
                <Main
                  polygonsForStore={polygonsForStore}
                  isFetchingPolygonsForStore={isFetchingPolygonsForStore}
                  fetchDeliveryLocationData={fetchDeliveryLocationData}
                  deliveryLocationData={deliveryLocationData}
                  isFetchingDeliveryLocationData={isFetchingDeliveryLocationData}
                  listWithDeliveryLocations={listWithDeliveryLocations}
                  isFethchingListWithDeliveryLocation={isFethchingListWithDeliveryLocation}
                  deleteDeliveryLocation={deleteDeliveryLocation}
                  storeInformation={storeInformation}
                  updateDeliveryLocation={updateDeliveryLocation}
                  rerenderTable={rerenderTable}
                  centerCoordinates={centerCoordinates}
                />
              )}
            </Segment>
          </GridColumn>
          <GridColumn width={16} style={{ width: '100%' }}>
            <Segment raised loading={Object.keys(listWithDeliveryLocations).length === 0 || rerenderTable}>
              <TableWithShippingAddresses
                rerenderTable={rerenderTable}
                listWithDeliveryLocations={listWithDeliveryLocations}
                deleteDeliveryLocation={deleteDeliveryLocation}
                storeInformation={storeInformation}
                updateDeliveryLocation={updateDeliveryLocation}
                setPosition={this.setPosition}
              />
            </Segment>
          </GridColumn>
          {/* <GridColumn width={window.isMobile ? 16 : 4} style={{ width: '25%' }}>
              <Sticky context={this.contextRef} offset={71}>
                <Segment raised>
                  <Secondary handleOpen={this.handleOpen} deliveryAddress={selectAddress} createDeliveryLocation={this.createDeliveryLocation} />
                </Segment>
              </Sticky>
            </GridColumn> */}
        </Grid>
        <Transition animation="fly up" duration={500} visible={this.state.modalOpen}>
          <Modal open={this.state.modalOpen} scrolling>
            <ModalHeader>Add delivery location</ModalHeader>
            <ModalContent>
              <ModalDescription>
                <ModalBody handleClose={this.handleClose} createDeliveryLocation={createDeliveryLocation} storeInformation={storeInformation} listWithDeliveryLocations={listWithDeliveryLocations} />
              </ModalDescription>
            </ModalContent>
          </Modal>
        </Transition>
      </Container>
    );
  }
}

export default DeliveryLocation;
