import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useValueAndKey,
} from 'utils/lib';
import styles from './Statistic.scss';

import StatisticValue from './StatisticValue';
import StatisticLabel from './StatisticLabel';

const cx = classnames.bind(styles);

const Statistic = (props) => {
  const {
    children,
    className,
    color,
    content,
    floated,
    horizontal,
    inverted,
    label,
    size,
    text,
    value,
  } = props;

  const classes = cx(
    'ui',
    color,
    size,
    useValueAndKey(floated, 'floated'),
    useKeyOnly(horizontal, 'horizontal'),
    useKeyOnly(inverted, 'inverted'),
    'statistic',
    className
  );

  const rest = getUnhandledProps(Statistic, props);
  const ElementType = getElementType(Statistic, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }
  if (!isNil(content)) {
    return (
      <ElementType {...rest} className={classes}>
        {content}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {StatisticValue.create(value, {
        defaultProps: { text },
        autoGenerateKey: false,
      })}
      {StatisticLabel.create(label, { autoGenerateKey: false })}
    </ElementType>
  );
};

Statistic.create = createShorthandFactory(Statistic, content => ({ content }));

export default Statistic;
