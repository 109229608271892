import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Container from 'components/Container/Container';
import Loader from 'components/Loaders/Loader';

import StoreInformation from './components/StoreInformation';
import Header from './components/Header';
import Footer from './components/Footer';
import TabPanes from './components/TabPanes';
import Sidebar from './components/Sidebar';

import styles from './SellerLayout.module.scss';

const cx = classnames.bind(styles);

const links = {
  store_info: '/seller/store_info',
  orders: '/seller/manage_orders',
  products: '/seller/manage_products',
  delivery: '/seller/delivery_location',
  suggest_products: '/seller/suggest_products',
};

const links_name = {
  store_info: {
    name: 'Store details',
    icon: "settings"
  },
  orders: {
    name: 'Orders pending',
    icon: "shopping bag"
  },
  products: {
    name: 'Products in stock',
    icon: "shopping cart"
  },
  delivery: {
    name: 'Delivery Location',
    icon: "map marker alternate"
  },
  suggest_products: {
    name: 'Suggest products',
    icon: "leaf"
  }
}

class SellerLayout extends Component {
  state = {
    activeIndex: null,
    openSidebar: false,
  }

  renderPage = (name) => {
    const { history } = this.props;

    const activeIndex = this.setActiveIndex(links[name]);

    this.setState({
      activeIndex,
    }, () => {
      history.push(links[name]);
    });
  }

  componentDidMount() {
    const { fetchStoreInformation, storeInformation } = this.props;

    if (!storeInformation) {
      fetchStoreInformation();
    }

    this.initActiveTabPane();
  }

  initActiveTabPane = () => {
    const { location } = this.props;

    const activeIndex = this.setActiveIndex(location.pathname);

    this.setState({
      activeIndex,
    });
  }

  setActiveIndex = (pathname) => {
    let setNewIndex = null;
    Object.keys(links).forEach((link, index) => {
      if (links[link].indexOf(pathname) >= 0) {
        setNewIndex = index;
      }
    });

    return setNewIndex;
  }

  goToStoreDetails = () => {
    const { history, domainPath } = this.props;

    history.push(`${domainPath}seller/store_info`);
  }

  toggleSidebar = () => {
    this.setState({
      openSidebar: true,
    })
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    const root = document.getElementById('app');
    if (root && this.node) {
      if (this.node.contains(root)) {
        return;
      }
    }

    this.toggleSidebar();
  };

  setContainerRef = (node) => {
    this.node = node;
  };

  toggleSidebar = () => {
    if (!this.state.openSidebar) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      openSidebar: !prevState.openSidebar,
    }));
  };

  render() {
    const {
      isFetchingStoreInformation,
      storeInformation,
      children,
      history,
      numberOfDeliveryLocations,
      numberOfOrdersPending,
      numberOfProductsInStock,
      isFetchingStoreProducts,
      isFethchingListWithDeliveryLocation,
      updateTabWithPanes,
      domainPath,
      onSignOut,
      isFetchingUserInfo,
      userInfo
    } = this.props;
    const { activeIndex, openSidebar } = this.state;

    if (isFetchingUserInfo) {
      return (
        <Loader active center />
      )
    }

    const isSeller = Object.keys(userInfo).length > 0 && userInfo.type === 'seller';

    const enableLoader = isFetchingStoreInformation || isFetchingStoreProducts || isFethchingListWithDeliveryLocation || updateTabWithPanes;

    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }} ref={this.setContainerRef}>
        <Header history={history} domainPath={domainPath} toggleSidebar={this.toggleSidebar} enabledSidebar={isSeller} />
        <Sidebar
          open={openSidebar}
          linksName={links_name}
          handleClickItem={this.renderPage}
          storeInformation={storeInformation}
          onSignOut={() => onSignOut(history, false, domainPath)}
        />
        <div className={cx('wrapper')}>
          {
            isSeller ?
              <div className={cx('container')}>
                <StoreInformation {...this.props} renderPage={this.goToStoreDetails} domainPath={domainPath} />
                <Container style={{ marginTop: '1.5em' }}>
                  <Segment basic loading={enableLoader}>
                    <TabPanes
                      storeInformation={storeInformation}
                      renderPage={this.renderPage}
                      activeIndex={activeIndex}
                      numberOfDeliveryLocations={numberOfDeliveryLocations}
                      numberOfOrdersPending={numberOfOrdersPending}
                      numberOfProductsInStock={numberOfProductsInStock}
                      onTabChange={this.onTabChange}
                    />
                  </Segment>
                </Container>
                {storeInformation && children}
              </div> :
              <div className={cx('message')}>
                <p>Have you had problems accessing the page?</p>
                <p>Please contact our support team: <a href="mailto:support@heallybuy.com">support@heallybuy.com</a></p>
              </div>
          }
        </div>
        <Footer history={history} />
      </div>
    );
  }
}

export default SellerLayout;
