import React from 'react';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Placeholder from 'components/Placeholder/Placeholder';
import PlaceholderLine from 'components/Placeholder/PlaceholderLine';
import PlaceholderImage from 'components/Placeholder/PlaceholderImage';
import PlaceholderParagraph from 'components/Placeholder/PlaceholderParagraph';

const Preloader = () => {
  return (
    <Grid stackable>
      <GridRow>
        <GridColumn width={10}>
          <Placeholder>
            <Placeholder style={{ display: 'block', height: '1.21428571rem', width: '50px' }} />
            <PlaceholderParagraph>
              <PlaceholderLine length='short' />
            </PlaceholderParagraph>
            <PlaceholderParagraph>
              <PlaceholderLine length='small' />
            </PlaceholderParagraph>
            <PlaceholderParagraph>
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
            </PlaceholderParagraph>
            <PlaceholderParagraph>
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
            </PlaceholderParagraph>
            <PlaceholderParagraph>
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
            </PlaceholderParagraph>
          </Placeholder>
        </GridColumn>
        <GridColumn width={6}>
          <Placeholder>
            <PlaceholderImage style={{ height: 250 }} />
            <PlaceholderParagraph>
              <Placeholder style={{ display: 'block', height: '2.71428571rem', width: '50px' }} />
            </PlaceholderParagraph>
          </Placeholder>
        </GridColumn>
      </GridRow>
    </Grid>
  )
}

export default Preloader;
