import markdown from 'markdown-it';
import markdownSub from 'markdown-it-sub';
import markdownSup from 'markdown-it-sup';

const md = markdown({ html: false, linkify: false })
  .use(markdownSub)
  .use(markdownSup);

const MDRender = text => {
  return md.render(text);
};

export default MDRender;
