/* eslint-disable no-unused-expressions */
export const dateBetween = (startDate, endDate) => {
  let second = 1000;
  let minute = second * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let distance = endDate - startDate;

  if (distance < 0) {
    return false;
  }

  let days = Math.floor(distance / day);
  let hours = Math.floor((distance % day) / hour);
  let minutes = Math.floor((distance % hour) / minute);
  let seconds = Math.floor((distance % minute) / second);

  let between = [];

  days > 0 ? between.push(`${days}d`) : false;
  hours > 0 ? between.push(`${hours}:`) : false;
  minutes > 0 ? between.push(`${minutes}:`) : false;
  seconds >= 0
    ? seconds < 10 ? between.push(`0${seconds}`) : between.push(`${seconds}`)
    : false;

  return between.join(' ');
};

export const getFormattedDate = date => {
  const formattedDate =
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + date.getDate()).slice(-2) +
    '/' +
    date.getFullYear() +
    ' ' +
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2);
  return formattedDate;
};
