import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import _ from 'lodash';

import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Header from 'components/Typography/Header/Header';
import CardGroup from 'components/Card/CardGroup';
import Image from 'components/Image/Image';

import Table from 'components/Table/Table';
import TableHeader from 'components/Table/TableHeader';
import TableRow from 'components/Table/TableRow';
import TableHeaderCell from 'components/Table/TableHeaderCell';
import TableBody from 'components/Table/TableBody';
import TableCell from 'components/Table/TableCell';

import Card from './components/Card';
import LineChart from './components/Charts/LineChart';

import styles from './Orders.module.scss';

import { useMediaQuery } from 'hooks/mediaQuery.hook';

const cx = classnames.bind(styles);

const generateData = () => {
  return _.times(6, () => {
    return {
      name: _.uniqueId(),
      uv: _.random(1890, 4000),
      pv: _.random(1890, 4000),
      amt: _.random(1890, 4000)
    }
  })
}

const Orders = ({ fetchOrders, orders }) => {
  const widthChart = useMediaQuery(
    ['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)'],
    [822, 676, 518],
    723
  );
  console.log(widthChart);
  useEffect(() => {
    fetchOrders();
  }, [])

  return (
    <Segment basic isLoading={orders.status === 'RUNNING'} style={{ paddingBottom: '76px' }}>
      <Grid>
        <GridRow>
          <GridColumn width={16}>
            <Header as="h2" style={{ color: '#128fbc' }}>Dashboard </Header>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <CardGroup itemsPerRow={4} >
              <Card
                title={'Revenue'}
                price={'$12,476.00'}
                metricChange={12}
                up
                data={generateData()}
              />
              <Card
                title={'Clicks'}
                price={'11,893'}
                metricChange={8}
                colorArea="#b5cc18"
                up
                data={generateData()}
              />
              <Card
                title={'Users'}
                price={'230,648'}
                metricChange={2}
                up={false}
                colorArea="#fbbd08"
                data={generateData()}
              />
              <Card
                title={'Conversation Rate'}
                price={'0.24%'}
                metricChange={9}
                up={false}
                colorArea="#e03997"
                data={generateData()}
              />
            </CardGroup>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn className={cx('container-line-chart')} width={16}>
            <LineChart width={widthChart} />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <Header as="h2" style={{ color: '#128fbc' }}>Secondary data </Header>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <Table compact padded className={cx('table-orders')}>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Total Price</TableHeaderCell>
                  <TableHeaderCell>Products Purchased</TableHeaderCell>
                  <TableHeaderCell>Created date</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {orders.data.map((order) =>
                  <TableRow key={order.id}>
                    <TableCell>{order.client_name}</TableCell>
                    <TableCell>${order.total_price}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        {order.list_of_products.map(product =>
                          <Image src={product.image.defualt_proxy} style={{ marginLeft: '0.25em' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{order.creation_date}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  )
}

export default Orders;
