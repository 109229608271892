import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import * as constants from './constants';

const initialState = {
  errorSignIn: null,
  successSignIn: false,
  isFetchingSignIn: false,
  errorSignUp: null,
  successSignUp: false,
  isFetchingSignUp: false,
  successSignOut: false,
  successLogIn: false,
  stateModal: false,
  renderContent: 'signin',
  userInfo: {},
  bufferUserInfo: {},
  isFetchingUserInfo: false,
  resetPasswordMessages: {
    messageSuccess: null,
    messageFail: null,
  },
  resetPasswordToken: null,
  sendNewPasswordMessageFail: null,
  isSaveUserProfile: false,
  isPartnerLogin: false,
  userAuthorizedWithHash: {
    status: 'IDLE',
    error: null,
  }
};

export default handleActions(
  {
    [constants.ON_SIGN_IN]: (state, action) => ({
      ...state,
      errorSignIn: null,
      isFetchingSignIn: true,
    }),

    [constants.ON_SIGN_IN_SUCCESS]: (state, action) => ({
      ...state,
      successSignIn: true,
      isFetchingSignIn: false,
      errorSignIn: null,
      successLogIn: true,
      stateModal: false,
      renderContent: 'signin',
      userInfo: action.userInfo,
    }),

    [constants.ON_SIGN_IN_FAIL]: (state, action) => ({
      ...state,
      errorSignIn: action.errorSignIn,
      isFetchingSignIn: false,
    }),

    [constants.ON_SIGN_UP]: (state, action) => ({
      ...state,
      errorSignUp: null,
      isFetchingSignUp: true,
    }),

    [constants.ON_SIGN_UP_SUCCESS]: (state, action) => ({
      ...state,
      successSignUp: true,
      isFetchingSignUp: false,
      successLogIn: true,
      stateModal: false,
      errorSignIn: null,
      renderContent: 'signin',
      userInfo: action.userInfo,
    }),

    [constants.ON_SIGN_UP_FAIL]: (state, action) => ({
      ...state,
      errorSignUp: action.errorSignUp,
      isFetchingSignUp: false,
    }),

    [constants.REMOVE_CURRENT_STATE]: (state, action) => {
      const errorSignUp = null;
      const errorSignIn = null;
      const resetPasswordMessages = state.resetPasswordMessages;
      resetPasswordMessages.messageFail = null;
      resetPasswordMessages.messageSuccess = null;
      return {
        ...state,
        errorSignUp,
        errorSignIn,
        stateModal: false,
        renderContent: 'signin',
        resetPasswordMessages,
        isPartnerLogin: false,
        isFetchingSignIn: false,
        isFetchingSignUp: false,
      };
    },

    [constants.ON_SIGN_OUT]: (state, action) => ({
      ...state,
    }),

    [constants.ON_SIGN_OUT_SUCCESS]: (state, action) => ({
      ...state,
      successLogIn: false,
      renderContent: 'signin',
      userInfo: {},
      isFetchingSignIn: false,
      isFetchingSignUp: false,
    }),

    [constants.OPEN_MODAL_WINDOW]: (state, action) => ({
      ...state,
      stateModal: action.modalBodyName,
      isPartnerLogin: action.isPartnerLogin,
    }),

    [constants.FETCH_USER_INFO]: (state, action) => ({
      ...state,
      isFetchingUserInfo: true,
    }),

    [constants.FETCH_USER_INFO_SUCCESS]: (state, action) => ({
      ...state,
      successLogIn: true,
      userInfo: action.userInfo,
      bufferUserInfo: action.userInfo,
      isFetchingUserInfo: false,
    }),

    [constants.FETCH_USER_INFO_FAIL]: (state, action) => ({
      isFetchingUserInfo: false,
    }),

    [constants.RENDER_FORM]: (state, action) => ({
      ...state,
      renderContent: action.form,
    }),

    [constants.FETCH_USER_INFO_FAIL]: (state, action) => ({
      ...state,
      isFetchingUserInfo: false,
    }),

    [constants.CHANGE_USER_PROFILE]: (state, action) => {
      const userInfo = { ...state.userInfo };
      userInfo[action.profile] = action.value;
      return {
        ...state,
        userInfo,
      };
    },

    [constants.CANCEL_CHANGE_USER_PROFILE]: (state, action) => ({
      ...state,
      userInfo: state.bufferUserInfo,
    }),

    [constants.USER_NOT_AUTHORIZE]: (state, action) => ({
      ...state,
      successLogIn: false,
    }),

    [constants.RESET_PASSWORD]: (state, action) => {
      const resetPasswordMessages = state.resetPasswordMessages;
      resetPasswordMessages.messageFail = null;
      resetPasswordMessages.messageSuccess = null;

      return {
        ...state,
        resetPasswordMessages,
      };
    },

    [constants.RESET_PASSWORD_SUCCESS]: (state, action) => {
      const resetPasswordMessages = state.resetPasswordMessages;
      resetPasswordMessages.messageSuccess = action.messageSuccess;
      resetPasswordMessages.messageFail = null;
      return {
        ...state,
        resetPasswordMessages,
      };
    },

    [constants.RESET_PASSWORD_FAIL]: (state, action) => {
      const resetPasswordMessages = state.resetPasswordMessages;
      resetPasswordMessages.messageFail = action.messageFail;
      resetPasswordMessages.messageSuccess = null;
      return {
        ...state,
        resetPasswordMessages,
      };
    },

    [constants.OPEN_CHANGE_PASSWORD_MODAL_WINDOW]: (state, action) => ({
      ...state,
      stateModal: action.stateModal,
      resetPasswordToken: action.token,
      renderContent: action.renderContent,
    }),

    [constants.SEND_NEW_PASSWORD_SUCCESS]: (state, action) => ({
      ...state,
      renderContent: action.renderContent,
      stateModal: action.stateModal,
      sendNewPasswordMessageFail: null,
    }),

    [constants.SEND_NEW_PASSWORD_FAIL]: (state, action) => ({
      ...state,
      sendNewPasswordMessageFail: action.sendNewPasswordMessageFail,
    }),

    [constants.SAVE_USER_PROFILE]: (state, action) => ({
      ...state,
      isSaveUserProfile: true,
    }),

    [constants.SAVE_USER_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      isSaveUserProfile: false,
    }),

    [constants.SAVE_USER_PROFILE_FAIL]: (state, action) => ({
      ...state,
      isSaveUserProfile: false,
    }),

    [constants.RENDER_TWO_FACTOR_FORM]: (state, action) => ({
      ...state,
      renderContent: 'twoAuth',
      stateModal: true,
    }),

    [constants.AUTH_BROWSER_CODE_SUCCESS]: (state, action) => ({
      ...state,
      renderContent: 'signin',
      stateModal: false,
    }),

    [constants.SEND_AUTHORIZATION_HASH]: (state, action) =>
      immutable(state, {
        userAuthorizedWithHash: {
          status: { $set: 'RUNNING' }
        }
      }),

    [constants.SEND_AUTHORIZATION_HASH_SUCCESS]: (state, action) =>
      immutable(state, {
        userAuthorizedWithHash: {
          status: { $set: 'SUCCESS' }
        },
        successLogIn: { $set: true },
        userInfo: { $set: action.payload }
      }),

    [constants.SEND_AUTHORIZATION_HASH_FAIL]: (state, action) =>
      immutable(state, {
        userAuthorizedWithHash: {
          status: { $set: 'FAIL' },
          error: { $set: 'error' }
        }
      })

  },
  initialState
);
