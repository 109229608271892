import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import styles from './Item.scss';

const cx = classnames.bind(styles);

const ItemExtra = (props) => {
  const { children, className, content } = props;
  const classes = cx('extra', className);
  const rest = getUnhandledProps(ItemExtra, props);
  const ElementType = getElementType(ItemExtra, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ItemExtra.create = createShorthandFactory(ItemExtra, content => ({ content }));

export default ItemExtra;
