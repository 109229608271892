import React, { Component } from 'react';
import _ from 'lodash';

import { getElementType, getUnhandledProps, useKeyOnly, useWidthProp } from 'utils/lib';

import classnames from 'classnames/bind';

import styles from './Form.css';

const cx = classnames.bind(styles);

class Form extends Component {
  static defaultProps = {
    as: 'form',
  };

  render() {
    const {
      action,
      children,
      className,
      error,
      inverted,
      loading,
      reply,
      size,
      success,
      unstackable,
      warning,
      widths,
    } = this.props;

    const classes = cx(
      'ui',
      size,
      useKeyOnly(error, 'error'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(loading, 'loading'),
      useKeyOnly(reply, 'reply'),
      useKeyOnly(success, 'success'),
      useKeyOnly(unstackable, 'unstackable'),
      useKeyOnly(warning, 'warning'),
      useWidthProp(widths, null, true),
      'form',
      className
    );

    const rest = getUnhandledProps(Form, this.props);
    const ElementType = getElementType(Form, this.props);

    return (
      <ElementType {...rest} action={action} className={classes} onSubmit={this.handleSubmit}>
        {children}
      </ElementType>
    );
  }
}

export default Form;
