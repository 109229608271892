import React, { forwardRef } from 'react';
import ReactConfetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

export default forwardRef((passedProps, ref) => {
  const { width, height } = useWindowSize();

  return (
    <ReactConfetti
      width={width}
      height={height}
      {...passedProps}
      ref={ref}
    />
  )
})
