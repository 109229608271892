import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  remedies: [],
  filterRemedies: null,
  remedyActive: [],
  listOfRemedies: [],
  isFetchingListOfRemedies: false,
};

export default handleActions(
  {
    [constants.FETCH_REMEDIES]: (state, action) => ({
      ...state,
      remedies: [],
      remedyActive: [],
      filterRemedies: null,
    }),
    [constants.FETCH_REMEDIES_SUCCESS]: (state, action) => ({
      ...state,
      remedies: action.remedies,
      filterRemedies: action.filterRemedy,
    }),
    [constants.FETCH_REMEDIES_FAIL]: (state, action) => ({
      ...state,
    }),
    [constants.HANDLE_CLICK_REMEDY]: (state, action) => {
      if (state.filterRemedies && state.filterRemedies.length > 0) {
        const remedy = action.remedy;
        const filterRemedies = state.filterRemedies.map((v) => {
          if (v.id === remedy.id) {
            v.active = !remedy.active;
          } else {
            v.active = false;
          }
          return v;
        });
        const remedyActive = filterRemedies.filter(v => v.active);
        return {
          ...state,
          filterRemedies,
          remedyActive,
        };
      }
      return {
        ...state,
      };
    },

    [constants.GET_ALL_REMEDIES]: (state, action) => ({
      ...state,
      isFetchingListOfRemedies: true,
    }),

    [constants.GET_ALL_REMEDIES_SUCCESS]: (state, action) => ({
      ...state,
      listOfRemedies: action.listOfRemedies,
      isFetchingListOfRemedies: false,
    }),

    [constants.GET_ALL_REMEDIES_FAIL]: (state, action) => ({
      ...state,
      isFetchingListOfRemedies: false,
    }),
  },
  initialState
);
