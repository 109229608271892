// import EventTarget from './EventTarget';
// import { normalizeHandlers, normalizeTarget } from 'utils/normalize';

// export default class EventStack {
//   targets = new Map();

//   sub(eventName, eventHandlers, options) {
//     const { target, poll } = options;
//     const eventTarget = this.getTarget(target);

//     eventTarget.addHandlers(pool, eventName, normalizeHandlers(eventHandlers));
//   }

//   unsub(eventName, eventHandlers, options) {
//     const { target, pool } = options;
//     const eventTarget = this.getTarget(target, false);

//     if (eventTarget) {
//       eventTarget.removeHandlers(pool, eventName, normalizeHandlers(eventHandlers));
//       if (!eventTarget.hasHandlers()) this.removeTarget(target);
//     }
//   }

//   getTarget = (target, autoCreate) => {
//     const normalized = normalizeTarget(target);

//     if (this.targets.has(normalized)) {
//       return this.targets.get(normalized);
//     }

//     if (!autoCreate) return null;

//     const eventTarget = new EventTarget(normalized);
//     this.targets.set(normalized, eventTarget);

//     return eventTarget;
//   };

//   removeTarget = target => {
//     this.targets.delete(normalizeTarget(target));
//   };
// }
import { instance } from '@semantic-ui-react/event-stack';

const eventStack = instance;

export { eventStack };
