import { connect } from 'react-redux';
import OrderInformation from '../components/OrderInformation';

import { fetchOrderInformation } from 'modules/orders/actions';
import { getIsFetchingOrderInformation, getOrderInformation } from 'modules/orders/selectors';

const mapStateToProps = state => ({
  isFetchingOrderInformation: getIsFetchingOrderInformation(state.orders),
  orderInformation: getOrderInformation(state.orders),
});

export default connect(
  mapStateToProps,
  {
    fetchOrderInformation,
  }
)(OrderInformation);
