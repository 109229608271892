import React from 'react';

import classnames from 'classnames/bind';

import {
  isNil,
  createShorthand,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useTextAlignProp,
} from 'utils/lib';

import styles from './Card.css';
import CardDescription from './CardDescription';
import CardHeader from './CardHeader';
import CardMeta from './CardMeta';

const cx = classnames.bind(styles);

const CardContent = (props) => {
  const { children, className, content, description, extra, header, meta, textAlign } = props;

  const classes = cx(useKeyOnly(extra, 'extra'), useTextAlignProp(textAlign), 'content', className);
  const rest = getUnhandledProps(CardContent, props);
  const ElementType = getElementType(CardContent, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  if (!isNil(content)) {
    return (
      <ElementType {...rest} className={classes}>
        {content}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {createShorthand(CardHeader, val => ({ content: val }), header, { autoGenerateKey: false })}
      {createShorthand(CardMeta, val => ({ content: val }), meta, { autoGenerateKey: false })}
      {createShorthand(CardDescription, val => ({ content: val }), description, {
        autoGenerateKey: false,
      })}
    </ElementType>
  );
};

export default CardContent;
