import { connect } from 'react-redux';

import {
  fetchStoreProducts,
  sortProducts,
  sortCategories,
  updateStoreProduct,
  deleteStoreProduct,
  fetchListBrands,
  addProduct,
  searchOfListProducts,
  massUpdateProducts,
  massDeleteProducts,
  setActiveCategoryItems,
} from 'modules/seller/actions';

import {
  getIsFetchingStoreProducts,
  getListSortOfCategoriesWithProducts,
  getProductsOutOfStock,
  getProductsAvaiable,
  getListCategories,
  getFormattedListProducts,
  getFormattedListBrands,
  getIsSearchingProduct,
  getIsSuccessAddProduct,
  getActiveCategoryItems,
} from 'modules/seller/selectors';

import ManageProducts from '../components/ManageProducts';

const mapStateToProps = state => ({
  isFetchingStoreProducts: getIsFetchingStoreProducts(state.seller),
  listSortOfCategoriesWithProducts: getListSortOfCategoriesWithProducts(state.seller),
  productsOutOfStock: getProductsOutOfStock(state.seller),
  productsAvaiable: getProductsAvaiable(state.seller),
  listCategories: getListCategories(state.seller),
  formattedListProducts: getFormattedListProducts(state.seller),
  formattedListBrands: getFormattedListBrands(state.seller),
  isSearchingProduct: getIsSearchingProduct(state.seller),
  isSuccessAddProduct: getIsSuccessAddProduct(state.seller),
  activeCategoryItems: getActiveCategoryItems(state.seller),
});

export default connect(
  mapStateToProps,
  {
    fetchStoreProducts,
    sortProducts,
    sortCategories,
    updateStoreProduct,
    deleteStoreProduct,
    fetchListBrands,
    addProduct,
    searchOfListProducts,
    massUpdateProducts,
    massDeleteProducts,
    setActiveCategoryItems,
  }
)(ManageProducts);
