import { connect } from 'react-redux';

import { fetchOrders } from 'modules/doctor/actions';
import { getOrders } from 'modules/doctor/selectors';

import Orders from '../components';

const mapStateToProps = (state) => ({
  orders: getOrders(state.doctor)
})

export default connect(mapStateToProps, {
  fetchOrders
})(Orders);
