/* eslint-disable no-restricted-globals */
const getScrollPosition = () => {
  if (window.pageYOffset !== undefined) return [pageXOffset, pageYOffset];
  const d = document;

  const sx = d.documentElement.scrollLeft || d.body.scrollLeft || 0;
  const sy = d.documentElement.scrollTop || d.body.scrollTop || 0;

  return [sx, sy];
};

export default getScrollPosition;
