import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

const captureExceptionHOC = Component =>
  class extends PureComponent {
    componentDidCatch(error, errorInfo) {
      console.log('error', error, errorInfo);
      Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      Sentry.captureException(error);
    }

    render() {
      return <Component {...this.props} />;
    }
  };

export default captureExceptionHOC;
