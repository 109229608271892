import React, { useState, useRef } from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Popup from 'components/Popup/Popup';
import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './ModalContent.module.scss';

const cx = classnames.bind(styles);

export default props => {
  const codeRef = useRef(null);
  const { order, onClick } = props;
  const [successCopy, setSuccessCopy] = useState('');

  const copyToClipboard = () => {
    if (codeRef) {
      codeRef.current.select();
      document.execCommand('copy');
      setSuccessCopy('Copied')
    }
  }

  return (
    <div className={cx('message-container')}>
      <div className={cx('message')}>
        Your order has been placed. You will receive an ETA for your order soon.
        Order number: <strong>{order && order.id}</strong>
      </div>
      {
        order && order.invitation_code &&
        <>
          <div className={cx('message')}>
            Give this invitation discount code to your friend to get discount for you both. This may be applied at cart page.
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5em' }}>
            <Input className={cx('invation-code')} readOnly ref={codeRef} value={order && order.invitation_code} size="big" />
            <Popup
              content={successCopy.length > 0 && 'Copied!'}
              on="click"
              inverted
              trigger={<Icon style={{ marginLeft: '0.5em' }} name="copy" size="big" link onClick={copyToClipboard} />}
            />
          </div>
        </>
      }
      <div className={cx('btn-container')}>
        <Button type="button" color="teal" onClick={onClick}>my orders</Button>
      </div>
    </div>
  );
};
