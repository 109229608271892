import React, { Component } from 'react';
import { checkEmail } from 'services';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './ForgotPassword.module.scss';

const cx = classnames.bind(styles);

const styleError = {
  border: 'solid 2px rgb(244, 67, 54)',
};

class ForgotPassword extends Component {
  state = {
    email: '',
    emailError: false,
    errorMessage: null,
  };
  onChange = (e) => {
    if (checkEmail(e.target.value)) {
      this.setState({
        email: e.target.value,
        emailError: false,
        errorMessage: null,
      });
    } else {
      this.setState({
        emailError: true,
      });
    }
  };

  onClick = () => {
    const { resetPassword } = this.props;
    if (this.state.emailError || this.state.email === '') {
      this.setState({
        errorMessage: 'Invalid email',
      });
    } else {
      resetPassword(this.state.email);
    }
  };
  render() {
    const { resetPasswordMessages, closeModal } = this.props;
    return (
      <Form>
        <h3 style={{ textAlign: 'center' }}>
          {resetPasswordMessages.messageSuccess ? 'Done!' : 'Enter your email address'}
        </h3>
        {resetPasswordMessages.messageSuccess ? null : (
          <FormField>
            <Input
              error={this.state.emailError ? styleError : null}
              fluid
              type="text"
              placeholder="E-mail address"
              onChange={e => this.onChange(e)}
            />
          </FormField>
        )}
        {this.state.errorMessage || resetPasswordMessages.messageFail ? (
          <div className={cx('error-message')}>
            {this.state.errorMessage || resetPasswordMessages.messageFail}
          </div>
        ) : null}
        {resetPasswordMessages.messageSuccess && (
          <div className={cx('success-message')}>{resetPasswordMessages.messageSuccess}</div>
        )}
        <div className={cx('button-group')}>
          {resetPasswordMessages.messageSuccess ? (
            <Button fluid type="button" secondary onClick={closeModal}>Close</Button>
          ) : (
            <Button fluid type="button" primary onClick={this.onClick}>Ok</Button>
          )}
        </div>
      </Form>
    );
  }
}

export default ForgotPassword;
