import React from 'react';
import classnames from 'classnames/bind';

import {
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useMultipleProp,
  useTextAlignProp,
  useVerticalAlignProp,
  useWidthProp,
} from 'utils/lib';
import styles from './Grid.css';

const cx = classnames.bind(styles);

const Grid = (props) => {
  const {
    celled,
    centered,
    children,
    className,
    columns,
    container,
    divided,
    doubling,
    inverted,
    padded,
    relaxed,
    reversed,
    stackable,
    stretched,
    textAlign,
    verticalAlign,
  } = props;

  const classes = cx(
    'ui',
    useKeyOnly(centered, 'centered'),
    useKeyOnly(container, 'container'),
    useKeyOnly(doubling, 'doubling'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(stackable, 'stackable'),
    useKeyOnly(stretched, 'stretched'),
    useKeyOrValueAndKey(celled, 'celled'),
    useKeyOrValueAndKey(divided, 'divided'),
    useKeyOrValueAndKey(padded, 'padded'),
    useKeyOrValueAndKey(relaxed, 'relaxed'),
    useMultipleProp(reversed, 'reversed'),
    useTextAlignProp(textAlign),
    useVerticalAlignProp(verticalAlign),
    useWidthProp(columns, 'column', true),
    'grid',
    className
  );

  const rest = getUnhandledProps(Grid, props);
  const ElementType = getElementType(Grid, props);

  return (
    <ElementType {...rest} className={classes}>
      {children}
    </ElementType>
  );
};

export default Grid;
