import React, { Component } from 'react';
import spacetime from 'spacetime';
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import classnames from 'classnames/bind';
import styles from './OrderTitle.module.scss';

const cx = classnames.bind(styles);
const uri = '/api/v1/orders/';

class OrderTitle extends Component {
  componentDidMount() {
    const { order, fetchStoreInfo, bufferStoreInfo } = this.props;

    fetchStoreInfo(order.store_id, bufferStoreInfo);
  }

  render() {
    const { onOrderAgain, order, onClick, bufferStoreInfo } = this.props;

    const storeInfo = bufferStoreInfo[order.store_id] && bufferStoreInfo[order.store_id];
    const finalPrice = order.sum_price - order.discount_cost + order.shipping_cost + order.tax_cost;
    return (
      <Grid>
        <GridRow verticalAlign="middle">
          <GridColumn computer={2} mobile={4}>
            <div className={cx('date')}>
              {spacetime(order.order_date * 1000, 'Canada/Pacific').format('numeric-us')}
            </div>
          </GridColumn>
          <GridColumn computer={4} mobile={4}>
            {storeInfo && (
              <Image
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onClick(storeInfo);
                }}
                src={storeInfo.logo.in_cart}
                alt={storeInfo.name}
              />
            )}
          </GridColumn>
          <GridColumn computer={3} mobile={4}>
            <div className={cx('status')}>{order.status}</div>
          </GridColumn>
          <GridColumn computer={3} mobile={4}>
            <div className={cx('price')}>${finalPrice && finalPrice.toFixed(2)}</div>
          </GridColumn>
          <GridColumn computer={4} mobile={16} className={cx('wrapper-column')}>
            <Button
              className={cx('order-button')}
              type="button"
              color="teal"
              onClick={() => onOrderAgain(`${uri}${order.id}/to_cart`, order)}
            >order again</Button>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default OrderTitle;
