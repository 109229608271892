import React, { Component } from 'react';
import { Cross } from 'images/svg/utils';

import classnames from 'classnames/bind';
import styles from './ModalWindow.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const styleCross = {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.45)',
  };

  return (
    <div
      style={props.styles && { ...props.styles }}
      className={props.openModal ? cx('modal-wrapper', 'active') : cx('modal-wrapper')}
    >
      <div
        style={props.stylesModalBody && { ...props.stylesModalBody }}
        className={props.openModal ? cx('modal', 'active') : cx('modal')}
      >
        {props.disabledCross ? null : <Cross styles={styleCross} onClick={props.closeModal} />}
        {props.openModal && props.content}
      </div>
    </div>
  );
};
