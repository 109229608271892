import React, { Component } from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';

import { getElementType, getUnhandledProps } from 'utils/lib';

import styles from './TextArea.scss';

const cx = classnames.bind(styles);

class TextArea extends Component {
  static defaultProps = {
    as: 'textarea',
    rows: 3,
  };

  componentDidMount() {
    this.updateHeight();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.autoHeight && prevProps.autoHeight) {
      this.removeAutoHeightStyles();
    }

    if ((this.props.autoHeight && !prevProps.autoHeight) || prevProps.value !== this.props.value) {
      this.updateHeight();
    }
  }

  removeAutoHeightStyles = () => {
    this.ref.style.height = null;
    this.ref.style.resize = null;
  };

  focus = () => this.ref.focus();

  handleInput = (e) => {
    const value = _.get(e, 'target.value');

    _.invoke(this.props, 'onInput', e, { ...this.props, value });
    this.updateHeight();
  };

  handleChange = (e) => {
    const value = _.get(e, 'target.value');

    _.invoke(this.props, 'onChange', e, { ...this.props, value });
  };

  updateHeight = () => {
    const { autoHeight } = this.props;
    if (!this.ref || !autoHeight) return;

    const { minHeight, borderBottomWidth, borderTopWidth } = window.getComputedStyle(this.ref);

    const borderHeight = _.sum([borderBottomWidth, borderTopWidth].map(x => parseFloat(x)));

    this.ref.style.height = 'auto';
    this.ref.style.overflowY = 'hidden';
    this.ref.style.height = `${Math.max(
      parseFloat(minHeight),
      Math.ceil(this.ref.scrollHeight + borderHeight)
    )}px`;
    this.ref.style.overflowY = '';
  };

  render() {
    const { autoHeight, rows, style, value } = this.props;
    const rest = getUnhandledProps(TextArea, this.props);
    const ElementType = getElementType(TextArea, this.props);

    const resize = autoHeight ? 'none' : '';

    return (
      <ElementType
        {...rest}
        onChange={this.handleChange}
        onInput={this.handleInput}
        ref={this.handleRef}
        rows={rows}
        style={{ resize, ...style }}
        value={value}
      />
    );
  }
}

export default TextArea;
