import { connect } from 'react-redux';

import { fetchStores } from 'modules/doctor/actions';

import { getStores } from 'modules/doctor/selectors';

import Stores from '../components';

const mapStateToProps = (state) => ({
  stores: getStores(state.doctor)
})

export default connect(mapStateToProps, {
  fetchStores
})(Stores)
