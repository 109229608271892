import React, { Component } from 'react';

import Minus from 'images/svg/minus';
import Plus from 'images/svg/plus';

import classnames from 'classnames/bind';
import styles from './PieceDiscretePacking.module.scss';

const cx = classnames.bind(styles);

class PieceDiscretePacking extends Component {
  state = {
    lastStep:
      this.props.itemProduct &&
      this.props.itemProduct.quantity &&
      this.props.itemProduct.quantity !== '' &&
      this.props.itemProduct.quantity !== null
        ? parseInt(this.props.itemProduct.quantity)
        : 1,
    danger: false,
  };

  componentWillReceiveProps(nextProps) {
    const { itemProduct } = this.props;

    if (
      nextProps.itemProduct &&
      itemProduct &&
      nextProps.itemProduct.quantity !== '' &&
      nextProps.itemProduct.quantity !== null &&
      parseInt(itemProduct.quantity) != parseInt(nextProps.itemProduct.quantity)
    ) {
      this.setState({
        lastStep: parseInt(nextProps.itemProduct.quantity),
      });
    }
  }

  onPlus = (lastStep) => {
    const { price, setProduct } = this.props;

    if (price[lastStep] && lastStep !== price.length - 1) {
      setProduct(lastStep + 1);

      this.setState({
        lastStep: lastStep + 1,
        danger: false,
      });
    }

    if (lastStep >= price.length - 1) {
      setProduct(lastStep + 1);

      this.setState({
        lastStep: lastStep + 1,
      });
    }
  };

  onMinus = (lastStep) => {
    const { closeCardBuy, price, setProduct } = this.props;

    if (lastStep !== 1) {
      setProduct(lastStep - 1);
      this.setState({
        lastStep: lastStep - 1,
      });
    } else {
      closeCardBuy();
    }
  };

  render() {
    const { price } = this.props;
    let currentPrice = null;

    let danger = this.state.danger;

    const lastStep = this.state.lastStep;

    if (lastStep >= price.length) {
      currentPrice = lastStep * price[price.length - 1];
    } else {
      currentPrice = lastStep * price[lastStep - 1];
    }

    if (lastStep === 1) {
      danger = true;
    }

    return (
      <div className={cx('content-block')}>
        <div className={cx('counter-measure')}>
          <button className={cx('button-trigger')} onClick={() => this.onMinus(lastStep)}>
            <div className={cx('container-button')}>
              <Minus style={danger ? { fill: 'rgb(253, 161, 133)' } : null} />
            </div>
          </button>
          <div className={cx('measure')}>{lastStep}</div>

          <button className={cx('button-trigger')} onClick={() => this.onPlus(lastStep)}>
            <div className={cx('container-button')}>
              <Plus />
            </div>
          </button>
        </div>
        <div className={cx('cost')}>
          $&nbsp;{currentPrice && Math.round(currentPrice) === 0
            ? currentPrice.toFixed(2)
            : Math.round(currentPrice)}
        </div>
      </div>
    );
  }
}

export default PieceDiscretePacking;
