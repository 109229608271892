import { connect } from 'react-redux';
import {
  fetchListOrders,
  showOrder,
  updateOrder,
} from 'modules/seller/actions';

import {
  getIsFetchingListOrders,
  getListOrders,
  getIsFetchingShowOrder,
  getOrder,
  getUpdateOrder,
} from 'modules/seller/selectors';

import ManageOrders from '../components/ManageOrders';


const mapStateToProps = state => ({
  isFetchingListOrders: getIsFetchingListOrders(state.seller),
  listOrders: getListOrders(state.seller),
  isFetchingShowOrder: getIsFetchingShowOrder(state.seller),
  order: getOrder(state.seller),
  isUpdateOrder: getUpdateOrder(state.seller),
});

export default connect(
  mapStateToProps,
  {
    fetchListOrders,
    showOrder,
    updateOrder,
  }
)(ManageOrders);
