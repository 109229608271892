import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import localForage from 'localforage';
import rootReducer from '../modules/rootReducer';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const reducer = persistReducer(
  {
    key: 'rrsb',
    storage: localForage,
    blacklist: ['user', 'orders', 'seller', 'stores', 'payments', 'products', 'doctor'],
    version: 1,
    migrate: (state) => {
      console.log('Migration Running!')
      return Promise.resolve(state)
    }
  },
  rootReducer,
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk, promise)));

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
