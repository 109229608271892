import React, { Component } from 'react';
import queryString from 'query-string';

import LoadingStores from 'components/Loaders/Stores';
import StoreCard from 'components/StoreCard';
import FilterBlock from 'components/FilterBlock';
import CardGroup from 'components/Card/CardGroup';
import Placeholder from 'components/Placeholder/Placeholder';
import PlaceholderLine from 'components/Placeholder/PlaceholderLine';
import PlaceholderHeader from 'components/Placeholder/PlaceholderHeader';

import DeliveryMessage from './components/DeliveryMessage';
import { parseAddressHost, getLocalStorage, saveLocalStorage } from 'services';

import classnames from 'classnames/bind';
import styles from './Stores.module.scss';

const cx = classnames.bind(styles);

const address_data = [
  {
    addresses: ['Alaska'],
    link: 'some.some'
  }
]

class Stores extends Component {
  state = {
    linkForBrand: null,
  }

  componentDidMount() {
    // fix for ios safari browser
    if (window.isMobile || window.isTablet && window.scrollTo) {
      window.scrollTo(0, 0);
    }
    const { fetchStores, location, brandInformation, history, stores, deliveryMessage, sameDay, setDeliveryAddress, resetStoresAndStoreInformation, domainPath } = this.props;

    const address = queryString.parse(location.search).location;

    const groupName = parseAddressHost(window.location.host);

    if (deliveryMessage) {
      if (sameDay && deliveryMessage.stores.multiple_same_day.redirect) {
        if (history.action && history.action === 'POP') {
          resetStoresAndStoreInformation('', true).then(() => history.push(`${domainPath}`));
        } else {
          history.push(`${domainPath}products?order_type=delivery&store=${stores.redirectIntoStoreSameDay.id}&location=${address}`);
        }
      } else if (!sameDay && deliveryMessage.stores.multiple_next_day.redirect) {
        if (history.action && history.action === 'POP') {
          resetStoresAndStoreInformation('', true).then(() => history.push(`${domainPath}`));
        } else {
          history.push(`${domainPath}products?order_type=delivery&store=${stores.redirectIntoStoreOvernight.id}&location=${address}`);
        }
      }
    }

    if (!stores.listStores) {
      fetchStores(address, brandInformation, history, groupName);
    }

    if (brandInformation && brandInformation.brand) {
      this.getExternalLinkForBrand(brandInformation.brand, address);
    }

  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  componentDidUpdate(prevProps) {
    const { deliveryMessage, sameDay, stores, history, location, deliveryAddress, brandInformation, domainPath } = this.props;
    const address = queryString.parse(location.search).location || deliveryAddress.data.enteringAddress;

    if (deliveryMessage) {
      if (sameDay && deliveryMessage.stores.multiple_same_day.redirect) {
        history.push(`${domainPath}products?order_type=delivery&store=${stores.redirectIntoStoreSameDay.id}&location=${address}`);
      } else if (!sameDay && deliveryMessage.stores.multiple_next_day.redirect) {
        history.push(`${domainPath}products?order_type=delivery&store=${stores.redirectIntoStoreOvernight.id}&location=${address}`);
      }
    }

    // if (brandInformation && brandInformation.brand) {
    //   this.getExternalLinkForBrand(brandInformation.brand, address);
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      deliveryAddress,
      fetchStores,
      brandInformation,
      history,
      stores,
      location,
      deliveryMessage,
      sameDay,
    } = this.props;

    const address = queryString.parse(location.search).location || deliveryAddress.data.enteringAddress;

    if (deliveryAddress.data.enteringAddress !== nextProps.deliveryAddress.data.enteringAddress) {
      fetchStores(nextProps.deliveryAddress.data.enteringAddress, brandInformation, history);

      if (brandInformation && brandInformation.brand) {
        this.getExternalLinkForBrand(brandInformation.brand, nextProps.deliveryAddress.data.enteringAddress);
      }
    }
  }

  handleClick = (infoCard) => {
    const { history, totalQuantity, saveInfoStoreQuery, deliveryAddress, stores, domainPath } = this.props;

    const query = `${domainPath}products?order_type=delivery&store=${infoCard.id}&location=${deliveryAddress.data.enteringAddress}`;

    saveInfoStoreQuery(query, stores, infoCard.same_day_delivery);

    history.push(query);
  };

  handleChange = (value) => {
    const { filterStoresSupportCreditCard } = this.props;
    filterStoresSupportCreditCard(value);
  };

  renderDeliveryCard = (card) => {
    const { stores } = this.props;
    return <StoreCard infoCard={card} key={card.id} id={card.id} onClick={this.handleClick} sameDay={stores.sameDay} />;
  };

  changeDeliveryOption = () => {
    const { handleSameDayDelivery, stores } = this.props;
    handleSameDayDelivery(!stores.isSameDayDelivery, stores.listStores);
  };

  goBack = () => {
    const { history, handleGoBackToProducts } = this.props;

    handleGoBackToProducts().then((resp) => {
      history.goBack();
    });
  };

  goToOvernightStores = () => {
    const { changeDeliveryOption } = this.props;

    changeDeliveryOption(false);
  };

  goToSameDayStores = () => {
    const { changeDeliveryOption } = this.props;

    changeDeliveryOption(true);
  };

  getExternalLinkForBrand = (brand, address) => {
    const stateFromAddress = address && address.split(',');
    let link = null;

    if (stateFromAddress.length > 0) {
      brand.address_data.forEach((v) => {
        if (v.addresses.indexOf(stateFromAddress[0]) >= 0) {
          link = v.link;
          return;
        }
      });
    }

    this.setState({
      linkForBrand: link || brand.external_url,
    })
  }

  renderBrandUrl = (brand) => {
    const link = this.getExternalLinkForBrand(brand);

    return (
      <div className={cx('text-message')}>
        Please return to
        <a href={link}>
          {link}&nbsp;
        </a>
        as we currently do not have delivery in your area
      </div>
    );
  }


  render() {
    const { stores, brandInformation, deliveryMessage } = this.props;
    const isLoading = stores.isFetching || !stores.listStores;
    const { linkForBrand } = this.state;

    if (stores.messageFail) {
      return (
        <div className={cx('element-container')}>
          <div className={cx('text-message')}>
            Miss Hit, We currently do not service your area, but check back with us soon!
          </div>
        </div>
      );
    }

    if (stores.listStores && stores.listStores.length === 0) {
      return (
        <div className={cx('element-container')}>
          {brandInformation && brandInformation.brand ? (
            <div className={cx('text-message')}>
              Please return to
            <a href={linkForBrand}>
                &nbsp;{linkForBrand}&nbsp;
            </a>
              as we currently do not have delivery in your area
          </div>
          ) : (
              <div className={cx('text-message')}>
                Sorry! We currently don’t have a delivery partner in your area but will definitely
                have one soon! Please check back.
            </div>
            )}
        </div>
      );
    }

    return (
      <div className={cx('wrapper', 'fade-in')}>
        <div className={cx('container')}>
          {
            isLoading ?
              <div style={{ width: '100%', marginBottom: '2em' }}>
                <Placeholder style={{ height: '1.5em', margin: '0 auto' }}>
                  <PlaceholderHeader>
                    <PlaceholderLine length="medium" />
                  </PlaceholderHeader>
                </Placeholder>
              </div> :
              deliveryMessage && (
                <DeliveryMessage
                  messages={deliveryMessage.stores}
                  sameDay={stores.sameDay}
                  goToOvernightStores={this.goToOvernightStores}
                  goToSameDayStores={this.goToSameDayStores}
                />
              )
          }
          <div className={cx('store-card-container')}>
            {isLoading ?
              <LoadingStores /> :
              <CardGroup className={cx('group-cards-container')}>
                {
                  stores.sameDay ?
                    stores.listStores
                      .filter(v => v.same_day_delivery)
                      .map(this.renderDeliveryCard) :
                    stores.listStores
                      .filter(v => !v.same_day_delivery)
                      .map(this.renderDeliveryCard)

                }
              </CardGroup>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Stores;
