import React from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useTextAlignProp,
  useVerticalAlignProp,
  useWidthProp,
} from 'utils/lib';

import Icon from 'components/Icons/Icon';
import styles from './Table.scss';


const cx = classnames.bind(styles);

const TableCell = (props) => {
  const {
    active,
    children,
    className,
    collapsing,
    content,
    disabled,
    error,
    icon,
    negative,
    positive,
    selectable,
    singleLine,
    textAlign,
    verticalAlign,
    warning,
    width,
  } = props;

  const classes = cx(
    useKeyOnly(active, 'active'),
    useKeyOnly(collapsing, 'collapsing'),
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(error, 'error'),
    useKeyOnly(negative, 'negative'),
    useKeyOnly(positive, 'positive'),
    useKeyOnly(selectable, 'selectable'),
    useKeyOnly(singleLine, 'single line'),
    useKeyOnly(warning, 'warning'),
    useTextAlignProp(textAlign),
    useVerticalAlignProp(verticalAlign),
    useWidthProp(width, 'wide'),
    className
  );

  const rest = getUnhandledProps(TableCell, props);
  const ElementType = getElementType(TableCell, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {Icon.create(icon)}
      {content}
    </ElementType>
  );
};

TableCell.defaultProps = {
  as: 'td',
};

TableCell.create = createShorthandFactory(TableCell, content => ({ content }));

export default TableCell;
