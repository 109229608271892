export const SET_BRAND = 'SET_BRAND';
export const SET_BRAND_SUCCESS = 'SET_BRAND_SUCCESS';
export const SET_BRAND_FAIL = 'SET_BRAND_FAIL';

export const SET_CHILDREN_COMPONENT_FOR_EMPTY_PAGE = 'SET_CHILDREN_COMPONENT_FOR_EMPTY_PAGE';

export const SET_NEW_CATEGORY_FOR_HEADER_BUTTON = 'SET_NEW_CATEGORY_FOR_HEADER_BUTTON';

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_DELIVERY_ADDRESS_SUCCESS = 'SET_DELIVERY_ADDRESS_SUCCESS';
export const SET_DELIVERY_ADDRESS_FAIL = 'SET_DELIVERY_ADDRESS_FAIL';

export const CHANGE_DELIVERY_ADDRESS = 'CHANGE_DELIVERY_ADDRESS';
export const CHANGE_DELIVERY_ADDRESS_SUCCESS = 'CHANGE_DELIVERY_ADDRESS_SUCCESS';
export const CHANGE_DELIVERY_ADDRESS_FAIL = 'CHANGE_DELIVERY_ADDRESS_FAIL';
export const SELECT_DELIVERY_ADDRESS = 'SELECT_DELIVERY_ADDRESS';
export const SELECT_DELIVERY_ADDRESS_SUCCESS = 'SELECT_DELIVERY_ADDRESS_SUCCESS';

export const SET_AUTH_POSITION = 'SET_AUTH_POSITION';
export const SELECT_DELIVERY_ADDRESS_FAIL = 'SELECT_DELIVERY_ADDRESS_FAIL';
