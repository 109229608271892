import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import geography from './geography';
import stores from './stores';
import products from './products';
import cart from './cart';
import user from './user';
import orders from './orders';
import remedies from './remedies';
import base from './base';
import payments from './payments';
import seller from './seller';
import category from './category';
import doctor from './doctor';

const appReducer = combineReducers({
  geography,
  stores,
  products,
  cart,
  user,
  orders,
  remedies,
  base,
  payments,
  routing: routerReducer,
  seller,
  category,
  doctor,
});

export default (state, action) => (action.type[0] === 'RESET_STORE'
  ? appReducer({ user: state.user, base: state.base }, action)
  : appReducer(state, action));
