import { request, removeQueryParameters } from 'services/api';
import queryString from 'query-string';

import uuid from 'uuid/v1';
import { saveLocalStorage } from 'services';

import notification from 'components/Notification';
import { fetchCartInfo } from '../cart/actions';
import * as constantsOrders from '../orders/constants';
import * as constantsCart from '../cart/constants';
import * as constants from './constants';

const url = '/api/v1';
const messageFail = 'User not found';

const messagesFailArray = [
  'user.invalid_password_or_email',
  'recaptcha.validation_fail',
  'recaptcha.no_param',
];

const replaceDate = (value) => {
  const date = new Date(value);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

};

const dispatchUserData = (dispatch, router, payOnline, userData) => {
  removeQueryParameters('pay_online', router);
  if (payOnline) {
    dispatch({
      type: [constantsCart.SET_PAYMENT_METHOD],
      paymentMethod: 'online_payment',
    });
  }

  if (router.location && router.location.pathname === '/') {
    const userType = userData.type;

    if (userType === 'client') {
      router.push('/user_orders');
    } else if (userType === 'admin') {
      window.location = '/manage';
    } else if (userType === 'doctor') {
      router.push('/doctor');
    } else {
      window.location = '/seller_panel';
    }
  } else {
    dispatch(fetchCartInfo());
  }

  if (router.location && router.location.pathname === '/cart' && !payOnline) {
    window.location = '/cart?from_getheally=true';
  }
  dispatch({
    type: [constants.ON_SIGN_IN_SUCCESS],
    userInfo: userData,
  });
};

export const onSignIn = (email, password, type, router) => (dispatch) => {
  dispatch({
    type: [constants.ON_SIGN_IN],
  });
  const bodyRequest = type === 'heally'
    ? { email, password }
    : { email, password, type: 'notclient' };
  request(`${url}/users/sign_in`, 'POST', bodyRequest)
    .then(response => response.json())
    .then((json) => {
      let payOnline = false;

      if (queryString.parse(router.location.search).pay_online) {
        payOnline = true;
      }
      if (json.status === 'SUCCESS') {
        if (json.data.two_factor_code) {
          dispatch({
            type: [constants.RENDER_TWO_FACTOR_FORM],
            form: 'twoAuth',
          });
        } else {
          dispatchUserData(dispatch, router, payOnline, json.data);
        }
      } else if (json.status === 'ERROR') {
        removeQueryParameters('pay_online', router);
        if (json.error_code === 'user.ready_to_getheally') {
          window.location = '/users/auth/doorkeeper';
        }

        if (json.error_code === 'user.already_logged_in') {
          window.location.reload();
        }

        if (json.error_code === 'user.must_enter_two_factor_code') {
          dispatch({
            type: [constants.RENDER_TWO_FACTOR_FORM],
            form: 'twoAuth',
          });
        }

        if (json.error_code === 'user.locked') {
          notification.error({
            message: 'Error',
            description: 'Account is blocked by administration.',
          });
        }

        if (messagesFailArray.indexOf(json.error_code) >= 0) {
          dispatch({
            type: [constants.ON_SIGN_IN_FAIL],
            errorSignIn: json,
          });
        }
      } else {
        dispatch({
          type: [constants.ON_SIGN_IN_FAIL],
          errorSignIn: json,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.ON_SIGN_IN_FAIL],
      });
      throw err;
    });
};

export const onSignUp = (
  firstname,
  lastname,
  email,
  password,
  passwordRepeat,
  phone,
  type,
  router,
) => (dispatch) => {
  dispatch({
    type: [constants.ON_SIGN_UP],
  });
  const bodyRequest = type === 'heally'
    ? {
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      password_confirmation: passwordRepeat,
      phone,
    }
    : {
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      password_confirmation: passwordRepeat,
      type: 'seller',
      phone,
    };
  request(`${url}/users/sign_up`, 'POST', bodyRequest)
    .then(response => response.json())
    .then((json) => {
      let payOnline = false;

      if (queryString.parse(router.location.search).pay_online) {
        payOnline = true;
      }
      if (json.status === 'ERROR') {
        removeQueryParameters('pay_online', router);
        let errorSignUp = {};
        if (json.error_code === 'user.not_valid') {
          errorSignUp = json.data.errors;
        }
        dispatch({
          type: [constants.ON_SIGN_UP_FAIL],
          errorSignUp,
        });
      } else if (json.status === 'SUCCESS') {
        removeQueryParameters('pay_online', router);
        if (router.location && router.location.pathname === '/') {
          if (json.data.type === 'client') {
            window.location = '/user_orders';
          } else if (json.data.type === 'admin') {
            window.location = '/manage';
          } else {
            window.location = '/seller_panel';
          }
        } else {
          dispatch(fetchCartInfo());
        }

        if (router.location && router.location.pathname === '/cart' && !payOnline) {
          window.location = '/cart?from_getheally=true';
        }
        dispatch({
          type: [constants.ON_SIGN_UP_SUCCESS],
          userInfo: json.data,
        });

        dispatch({
          type: [constantsCart.SET_PHONE_NUMBER],
          phoneNumber: phone
        })

        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration');
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.ON_SIGN_UP_FAIL],
      });
      throw err;
    });
};

export const removeCurrentState = () => (dispatch) => {
  dispatch({
    type: [constants.REMOVE_CURRENT_STATE],
  });
};

export const onSignOut = (router, renderForm, domainPath) => (dispatch) => {
  dispatch({
    type: [constants.ON_SIGN_OUT],
  });
  request(`${url}/users/sign_out`, 'DELETE', {})
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.ON_SIGN_OUT_SUCCESS],
      });
      // saveLocalStorage('paymentMethod', '');
      removeQueryParameters('pay_online', router);
      dispatch({
        type: [constantsCart.SET_PAYMENT_METHOD],
        paymentMethod: null,
      });
      saveLocalStorage('client_uid', uuid()).then(() => {
        router.push(`${domainPath}`);

        if (renderForm && renderForm === 'twoAuth') {
          dispatch({
            type: [constants.OPEN_MODAL_WINDOW],
            stateModal: true,
          });
        }
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.ON_SIGN_OUT_FAIL],
      });
    });
};

export const openModalWindow = (modalBodyName, renderContent = null) => (dispatch) => {
  dispatch({
    type: [constants.OPEN_MODAL_WINDOW],
    modalBodyName,
  });

  if (renderContent) {
    dispatch({
      type: [constants.RENDER_FORM],
      form: renderContent,
    });
  }
};

export const fetchUserInfo = (router = false) => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_USER_INFO],
  });
  request(`${url}/users/info`, 'GET')
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.FETCH_USER_INFO_SUCCESS],
          userInfo: json.data,
        });
        if (router) {
          if (json.data.type === 'doctor') {
            router.push('/doctor');
          } else {
            router.push('/user_orders');
          }
        }
      } else {
        if (json.error_code === 'user.must_enter_two_factor_code') {
          dispatch({
            type: [constants.RENDER_TWO_FACTOR_FORM],
            form: 'twoAuth',
          });
        }

        dispatch({
          type: [constants.FETCH_USER_INFO_FAIL],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_USER_INFO_FAIL],
      });
    });
};

export const renderForm = form => (dispatch) => {
  dispatch({
    type: [constants.RENDER_FORM],
    form,
  });
};

export const cancelChangeUserProfile = () => (dispatch) => {
  dispatch({
    type: [constants.CANCEL_CHANGE_USER_PROFILE],
  });
};

export const saveUserProfile = (user, onlyPhotoId) => async (dispatch) => {
  dispatch({
    type: [constants.SAVE_USER_PROFILE],
  });

  return request(`${url}/users/info`, 'PUT', user)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.SAVE_USER_PROFILE_SUCCESS],
      });
      dispatch(fetchUserInfo());
      dispatch({
        type: [constantsOrders.CLOSE_MODAL_WITH_PHOTO_ID],
      });
      notification.success({
        message: 'Success',
        description: 'Your profile save successfully.',
      });

      if (onlyPhotoId) {
        const requirePhotoId = false;
        dispatch({
          type: [constantsOrders.REQUIRE_PHOTO_ID],
          payload: requirePhotoId,
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.SAVE_USER_PROFILE_FAIL],
      });
      notification.error({
        message: 'Error',
        description: 'Unable to save profile ;(',
      });
    });
};

export const changeUserProfile = (value, profile) => (dispatch) => {
  dispatch({
    type: [constants.CHANGE_USER_PROFILE],
    value,
    profile,
  });
};

export const resetPassword = email => (dispatch) => {
  dispatch({
    type: [constants.RESET_PASSWORD],
  });
  const body = { email };
  request(`${url}/users/request_password_reset`, 'POST', body)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.RESET_PASSWORD_SUCCESS],
          messageSuccess: 'Please check your email to find the password restore instructions',
        });
      } else {
        dispatch({
          type: [constants.RESET_PASSWORD_FAIL],
          messageFail,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.RESET_PASSWORD_FAIL],
        messageFail,
      });
    });
};

export const openChangePasswordModalWindow = (token, router) => (dispatch) => {
  dispatch({
    type: [constants.OPEN_CHANGE_PASSWORD_MODAL_WINDOW],
    stateModal: 'auth',
    renderContent: 'changePassword',
    token,
  });
  router.replace('/');
};

export const sendNewPassword = (
  password,
  passwordConfirmation,
  token,
  router
) => (dispatch) => {
  dispatch({
    type: [constants.SEND_NEW_PASSWORD],
  });
  request(`${url}/users/reset_password`, 'POST', {
    reset_password_token: token,
    password,
    password_confirmation: passwordConfirmation,
  })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.SEND_NEW_PASSWORD_SUCCESS],
          renderContent: 'signin',
          stateModal: false,
        });
        dispatch(fetchUserInfo(router));
      } else {
        dispatch({
          type: [constants.SEND_NEW_PASSWORD_FAIL],
          sendNewPasswordMessageFail: 'Reset password token is not valid',
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.SEND_NEW_PASSWORD_FAIL],
      });
    });
};

export const renderSignUpForm = () => (dispatch) => {
  dispatch({
    type: [constants.RENDER_SIGN_UP_FORM],
  });
};

export const resentCode = () => (dispatch) => {
  dispatch({
    type: [constants.RESENT_CODE],
  });
  request(`${url}/authentication/resend_code`)
    .then(response => response.json())
    .then((json) => {
      dispatch({
        type: [constants.RESENT_CODE_SUCCESS],
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.RESENT_CODE_FAIL],
      });
    });
};

export const authBrowserCode = (code, router) => (dispatch) => {
  dispatch({
    type: [constants.AUTH_BROWSER_CODE],
  });
  request(`${url}/authentication/auth_browser_code`, 'POST', { code })
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'ERROR') {
        notification.error({
          message: 'Error',
          description: 'Your code is invalid.',
        });
      } else {
        let payOnline = false;

        if (queryString.parse(router.location.search).pay_online) {
          payOnline = true;
        }

        dispatch({
          type: [constants.AUTH_BROWSER_CODE_SUCCESS],
        });
        dispatchUserData(dispatch, router, payOnline, json.data);
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.AUTH_BROWSER_CODE_FAIL],
      });
    });
};

export const sendAuthorizationHash = async (hash) => dispatch => {
  dispatch({
    type: [constants.SEND_AUTHORIZATION_HASH]
  });
  request(`${url}/users/sign_by_key?key=${hash}`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.SEND_AUTHORIZATION_HASH_SUCCESS],
          payload: json.data,
        })
      } else {
        dispatch({
          type: [constants.SEND_AUTHORIZATION_HASH_FAIL],
          error: 'error',
        })
      }
    })
    .catch(err => {
      dispatch({
        type: [constants.SEND_AUTHORIZATION_HASH_FAIL],
        error: 'error'
      })
    })
}
