import React from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';

import {
  AutoControlledComponent as Component,
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useValueAndKey,
  useWidthProp,
} from 'utils/lib';

import MenuItem from './MenuItem';

import styles from './Menu.scss';

const cx = classnames.bind(styles);

class Menu extends Component {
  static autoControlledProps = ['activeIndex'];

  handleItemOverrides = predefinedProps => ({
    onClick: (e, itemProps) => {
      const { index } = itemProps;

      this.trySetState({ activeIndex: index });

      _.invoke(predefinedProps, 'onClick', e, itemProps);
      _.invoke(this.props, 'onItemClick', e, itemProps);
    },
  });

  renderItems() {
    const { items } = this.props;
    const { activeIndex } = this.state;

    return _.map(items, (item, index) => MenuItem.create(item, {
      defaultProps: {
        active: parseInt(activeIndex, 10) === index,
        index,
      },
      overrideProps: this.handleItemOverrides,
    }));
  }

  render() {
    const {
      attached,
      borderless,
      children,
      className,
      color,
      compact,
      fixed,
      floated,
      fluid,
      icon,
      inverted,
      pagination,
      pointing,
      secondary,
      size,
      stackable,
      tabular,
      text,
      vertical,
      widths,
    } = this.props;

    const fixedMenu = (fixed && useValueAndKey(fixed, 'fixed').split(' ')) || [];

    const classes = cx(
      'ui',
      color,
      size,
      useKeyOnly(borderless, 'borderless'),
      useKeyOnly(compact, 'compact'),
      useKeyOnly(fluid, 'fluid'),
      useKeyOnly(inverted, 'inverted'),
      useKeyOnly(pagination, 'pagination'),
      useKeyOnly(pointing, 'pointing'),
      useKeyOnly(secondary, 'secondary'),
      useKeyOnly(stackable, 'stackable'),
      useKeyOnly(text, 'text'),
      useKeyOnly(vertical, 'vertical'),
      useKeyOrValueAndKey(attached, 'attached'),
      useKeyOrValueAndKey(floated, 'floated'),
      useKeyOrValueAndKey(icon, 'icon'),
      useKeyOrValueAndKey(tabular, 'tabular'),
      ...fixedMenu,
      useWidthProp(widths, 'item'),
      className,
      'menu'
    );

    const rest = getUnhandledProps(Menu, this.props);
    const ElementType = getElementType(Menu, this.props);

    return (
      <ElementType className={classes}>
        {isNil(children) ? this.renderItems() : children}
      </ElementType>
    );
  }
}

Menu.create = createShorthandFactory(Menu, items => ({ items }));

export default Menu;
