import EventStack from '@semantic-ui-react/event-stack';
import React, { Component, createRef, Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';
import shallowEqual from 'shallowequal';
import { Popper } from 'react-popper';

import {
  eventStack,
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  isRefObject,
} from 'utils/lib';

import Portal from 'components/Addons/Portal/Portal';
import Ref from 'components/Addons/Ref/Ref';

import PopupContent from './PopupContent';
import PopupHeader from './PopupHeader';

import styles from './Popup.css';

const cx = classnames.bind(styles);

const positionsMapping = {
  'top center': 'top',
  'top left': 'top-start',
  'top right': 'top-end',

  'bottom center': 'bottom',
  'bottom left': 'bottom-start',
  'bottom right': 'bottom-end',

  'right center': 'right',
  'left center': 'left',
};

const positions = _.keys(positionsMapping);

const placementMapping = _.invert(positionsMapping);

class ReferenceProxy {
  constructor(refObject) {
    this.ref = refObject;
  }

  getBoundingClientRect() {
    return _.invoke(this.ref.current, 'getBoundingClientRect', {});
  }

  get clientWidth() {
    return this.getBoundingClientRect().width;
  }

  get clientHeight() {
    return this.getBoundingClientRect().height;
  }

  get parentNode() {
    return this.ref.current ? this.ref.current.parentNode : undefined;
  }
}

const createReferenceProxy = _.memoize(
  reference => new ReferenceProxy(isRefObject(reference) ? reference : { current: reference }),
);

class Popup extends Component {
  state = {}

  open = false

  triggerRef = createRef()

  static defaultProps = {
    disabled: false,
    eventsEnabled: true,
    offset: 0,
    on: ['click', 'hover'],
    pinned: false,
    position: 'top left',
  }

  static getDerivedStateFromProps(props, state) {
    if (state.closed || state.disabled) return {};

    const unhandledProps = getUnhandledProps(Popup, props);
    const contentRestProps = _.reduce(
      unhandledProps,
      (acc, val, key) => {
        if (!_.includes(Portal.handledProps, key)) acc[key] = val;

        return acc;
      },
      {},
    );
    const portalRestProps = _.pick(unhandledProps, Portal.handledProps);

    return { contentRestProps, portalRestProps };
  }

  componentDidUpdate(prevProps) {
    const depsEqual = shallowEqual(this.props.popperDependencies, prevProps.popperDependencies);

    if (!depsEqual) {
      this.handleUpdate();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  getPortalProps = () => {
    const portalProps = {};

    const { on, hoverable } = this.props;
    const normalizedOn = _.isArray(on) ? on : [on];

    if (hoverable) {
      portalProps.closeOnPortalMouseLeave = true;
      portalProps.mouseLeaveDelay = 300;
    }
    if (_.includes(normalizedOn, 'hover')) {
      portalProps.openOnTriggerClick = false;
      portalProps.closeOnTriggerClick = false;
      portalProps.openOnTriggerMouseEnter = true;
      portalProps.closeOnTriggerMouseLeave = true;

      portalProps.mouseLeaveDelay = 70;
      portalProps.mouseEnterDelay = 50;
    }
    if (_.includes(normalizedOn, 'click')) {
      portalProps.openOnTriggerClick = true;
      portalProps.closeOnTriggerClick = true;
      portalProps.closeOnDocumentClick = true;
    }
    if (_.includes(normalizedOn, 'focus')) {
      portalProps.openOnTriggerFocus = true;
      portalProps.closeOnTriggerBlur = true;
    }

    return portalProps;
  }

  hideOnScroll = (e) => {
    this.setState({ closed: true });

    eventStack.unsub('scroll', this.hideOnScroll, { target: window });
    this.timeoutId = setTimeout(() => {
      this.setState({ closed: false });
    }, 50);

    this.handleClose(e);
  }

  handleClose = (e) => {
    _.invoke(this.props, 'onClose', e, this.props);
  }

  handleOpen = (e) => {
    _.invoke(this.props, 'onOpen', e, this.props);
  }

  handlePortalMount = (e) => {
    _.invoke(this.props, 'onMount', e, this.props);
  }

  handlePortalUnmount = (e) => {
    this.positionUpdate = null;
    _.invoke(this.props, 'onUnmount', e, this.props);
  }

  handleUpdate() {
    if (this.positionUpdate) this.positionUpdate();
  }

  renderContent = ({
    placement: popperPlacement,
    ref: popperRef,
    scheduleUpdate,
    style: popperStyle,
  }) => {
    const {
      basic,
      children,
      className,
      content,
      hideOnScroll,
      flowing,
      header,
      inverted,
      size,
      style,
      wide,
    } = this.props;
    const { contentRestProps } = this.state;

    this.positionUpdate = scheduleUpdate;

    const classes = cx(
      'ui',
      placementMapping[popperPlacement],
      size,
      useKeyOrValueAndKey(wide, 'wide'),
      useKeyOnly(basic, 'basic'),
      useKeyOnly(flowing, 'flowing'),
      useKeyOnly(inverted, 'inverted'),
      'popup transition visible',
      className,
    );
    const ElementType = getElementType(Popup, this.props);
    const styles = {
      left: 'auto',
      right: 'auto',
      ...popperStyle,
      ...style,
    };

    return (
      <Ref innerRef={popperRef}>
        <ElementType {...contentRestProps} className={classes} style={styles}>
          {isNil(children) ? (
            <Fragment>
              {PopupHeader.create(header, { autoGenerateKey: false })}
              {PopupContent.create(content, { autoGenerateKey: false })}
            </Fragment>
          ) : (
              children
            )}
          {hideOnScroll && <EventStack on={this.hideOnScroll} name="scroll" target="window" />}
        </ElementType>
      </Ref>
    );
  }

  render() {
    const {
      context,
      disabled,
      eventsEnabled,
      offset,
      pinned,
      popperModifiers,
      position,
      trigger,
    } = this.props;
    const { closed, portalRestProps } = this.state;

    if (closed || disabled) return trigger;

    const modifiers = _.merge(
      {
        arrow: { enabled: false },
        flip: { enabled: !pinned },
        keepTogether: { enabled: !!offset },
        offset: { offset },
      },
      popperModifiers,
    );
    const referenceElement = createReferenceProxy(_.isNil(context) ? this.triggerRef : context);

    const mergedPortalProps = { ...this.getPortalProps(), ...portalRestProps };

    return (
      <Portal
        {...mergedPortalProps}
        onClose={this.handleClose}
        onMount={this.handlePortalMount}
        onOpen={this.handleOpen}
        onUnmount={this.handlePortalUnmount}
        trigger={trigger}
        triggerRef={this.triggerRef}
      >
        <Popper
          eventsEnabled={eventsEnabled}
          modifiers={modifiers}
          placement={positionsMapping[position]}
          referenceElement={referenceElement}
        >
          {this.renderContent}
        </Popper>
      </Portal>
    );
  }
}

export default Popup;
