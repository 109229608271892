import React from 'react';
import classnames from 'classnames/bind';

import Container from 'components/Container/Container';

import styles from './Privacy.module.scss';

const cx = classnames.bind(styles);

const customStyles = {
  p: {
    fontSize: '16px',
    color: '#666',
    lineHeight: '1.54em',
  },
  h4: {
    fontSize: '18px',
    color: '#333333',
    fontWeight: '600',
    textTransform: 'none',
  }
}

export default () => {
  return (
    <div className={cx('privacy-wrapper')}>
      <Container text>
        <div style={{ padding: '0px 12px' }}>
          <div>
            <h1>Privacy</h1>
          </div>
          <div className={cx('privacy-content')} itemProp="mainEntityOfPage" style={{ lineHeight: '1.54em' }}>
            <p style={customStyles.p}><strong><em>This Privacy Policy was last updated and posted on 10/03/2017</em></strong><br />
              This privacy policy (“<strong>Privacy Policy</strong>”) sets out how Heally, Inc. (“<strong>we</strong>”, “<strong>us</strong>”, or “<strong>Company</strong>”) uses and protects any information that you give when you use <a href="http://www.getHeally.com">http://www.getHeally.com</a> or our Services, as described in the Heally Terms of Use (“<strong>Terms of Use</strong>”). We are committed to protecting the privacy of your Personally Identifiable Information (as defined below) to the extent possible, subject to provisions of state and federal law. This Privacy Policy governs all of the Company’s websites (including mobile versions, mobile applications, widgets, and so on) (collectively, “<strong>Website</strong>”), regardless of whether or how use our services (“<strong>Services</strong>”). This Privacy Policy is incorporated into and is subject to our Terms of Use, which covers your use of the Website and Services. Your use of the same can be terminated upon your violation of our Terms of Use or Privacy Policy. Capitalized terms that are not defined in the Privacy Policy have the meaning given to them in the Terms of Use.<br />
              You are bound by the terms of this Privacy Policy and consent to the collection, use, and disclosure of visitor information pursuant to all of the terms of this Privacy Policy. By using the Website or the Services, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, you should not use our Website or Services. If you have additional questions or would like further information, please write to us at privacy@getheallly.com.</p>
            <ol>
              <li>
                <h4 style={customStyles.h4}><strong>Collecting Personally Identifiable Information</strong></h4>
                <p style={customStyles.p}>We define Personally Identifiable Information as individually identifiable information about an individual consumer collected online by us from that individual and maintained by us in an accessible form. Personally Identifiable Information can include: (1) A first and last name. (2) A home or other physical address, including street name and name of a city or town. (3) An e-mail address. (4) A telephone number. (5) Any other identifier that permits the physical or online contacting of a specific individual. (6) Information concerning a user that the Website or Services collects online from the user and maintains in personally identifiable form in combination with an identifier described above.While you may use some of the functionality of the Website or Services without registering, many of the specific tools and services on the Website or Services require registration. We collect information that you provide to us when you register with the Website and complete your profile, or when you ask us for Services or additional information or to resolve complaints or concerns. Specifically, we may collect, store and use the following categories of Personally Identifiable Information:</p>
                <ul>
                  <li><u>Personal information</u>: Information which identifies you as a person, such as full name, phone number, e-mail address, or date of birth.</li>
                  <li><u>Demographic information</u>, such as location, gender, or age.</li>
                  <li><u>Medical information</u>, including but not limited to your applicable Evaluation; notes from medical examinations connected to obtaining the Evaluation; the name, license number, and contact details of the Licensed Health Care Provider you select; the verification number for the Evaluation, as well its effective date and expiration; and, the details of any Services used or orders placed through the Website.</li>
                  <li><u>MetaData</u>: Information about your computer and about your visits to and use of the Website or Services (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths.</li>
                  <li><u>Communications</u>: Information contained in or relating to any communication that you send to us or send through the Website or in connection with the Services (including the communication content and metadata associated with the communication).</li>
                  <li><u>Payment information</u>. In addition, when you order products or services through the Services, Company, or a third party PCI compliant payment processor, may collect your credit/debit card information. This information is secured using industry-standard encryption technology in the applicable payment processor system. To the extent Company has access to this information it will use this information solely to complete your order and will not share this information with outside parties other than the payment processor, except to the extent necessary to complete the order or comply with applicable law.</li>
                </ul>
                <p style={customStyles.p}>We may use publicly available sources outside of the Company to verify or supplement the information you give us. For example, we may obtain address updates from the U.S. Postal Service or demographic information from direct marketing companies. We use this data to help us maintain accurate records and to improve the products and services that we deliver to you.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong><strong>Using Personally Identifiable Information<br />
                </strong></strong></h4>
                <p style={customStyles.p}>Personal information submitted to us through our website will be used for the purposes specified in this Privacy Policy or on relevant pages of the Website or as necessary to provide the Services.We may use your Personally Identifiable Information to:</p>
                <ul>
                  <li>enable your use of the Website or Services;</li>
                  <li>send you marketing communications, including information relating to the businesses of carefully-selected third parties which we think may be of interest to you, such as electronic newsletters, marketing material, special offers, promotional materials;</li>
                  <li>process transactions or provide you with information on behalf of third parties regarding available products, services, events;</li>
                  <li>provide customer support;</li>
                  <li>identify your product and service preferences so that you can be informed of new or additional products, services and promotions;</li>
                  <li>personalize your experience (your information helps us to better respond to your individual needs);</li>
                  <li>improve customer service (your information helps us to more effectively respond to your customer service requests and support needs);</li>
                  <li>administer a contest, promotion, survey or other site feature;</li>
                  <li>send periodic emails;</li>
                  <li>for other purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.</li>
                </ul>
                <p style={customStyles.p}>By using the Website or the Services, you accept these messages. A user may, at any time, opt out of receiving email newsletters and promotional material (See more about Opt Out below).<br />
                  The Company is the sole owner of the Personally Identifiable Information collected on this Website or through the Services. That’s why we don’t sell, rent, license or share any of the Personally Identifiable Information you provide to us with companies outside of the Company for their own promotional or marketing use without your explicit, affirmative consent.<br />
                  There are occasions, however, where we must share your information to provide you with the Services you requested or for legal reasons. The Company uses the Personally Identifiable Information that you provide to respond to your questions, to provide you the Services you select, to send you emails about the Website, and to inform you of material changes to this Privacy Policy. You are responsible for maintaining the truthfulness and accuracy of the information you submit to us, such as your contact information or any information provided as part of registration. The Service will disable a user’s account if the information provided is determined to be false or no longer current.</p>
                <ul>
                  <li>We may disclose your Personally Identifiable Information to your selected Licensed Health Care Provider when you make an appointment or elect to receive an Evaluation in the Services.</li>
                  <li>We may provide you certain areas of service associated with third parties as promotional partners, which may require the disclosure of your Personally Identifiable Information. If you decide to engage with such third parties, register for products or/and services, download their content, make communication, your Personally Identifiable Information will be disclosed to them. If you sign in to a Third party Area with a username and password obtained on the Service, your Personally Identifiable Information may be disclosed to the third parties. We are not liable and responsible for the practices and data collection/protection policies of such third parties. We recommend you to review privacy policy and/or internal privacy rules of such third parties before making engagement with them. You access these sites at your own risk and we make no representations or warranties about the content, completeness, or accuracy of these links or the sites hyperlinked to the Website, or about the privacy and security of information transmitted to or through them. You agree that we shall not be responsible for any loss or damage of any sort incurred.</li>
                  <li>We may disclose your Personally Identifiable Information to the extent that we are required to do so by law, or in connection with any ongoing or prospective legal proceedings.</li>
                  <li>We may offer contests, sweepstakes and other promotions (“<strong>Promotion</strong>”) through the Service that may require registration. If you participate in a Promotion, it means you agree to their official rules that govern that Promotion. If you enter such Promotion, your Personally Identifiable Information may be disclosed to third parties or the public. The reason of such disclosure can be administrative procedures of such Promotion, such as winner selection, price implementation, any other reasons described in official rules or required by law.</li>
                </ul>
              </li>
              <li>
                <h4 style={customStyles.h4}><strong>Disclosing Personally Identifiable Information</strong></h4>
                <p style={customStyles.p}>Because your privacy is important, we do not sell, share, lease, or license your personal information. However, we may disclose information about you if you specifically authorize us to do so or in such cases described below. We may disclose your Personally Identifiable Information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary to provide the Services or for the purposes set out in this Privacy Policy.<br />
                  We may disclose your Personally Identifiable Information to any member of our group of companies (this means our subsidiaries, parent company, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this Privacy Policy. The disclosure is also possible in connection to structural changes, merger, consolidation, the sale of substantially all of our interests and/or assets or other corporate change, including, the course of any due diligence process. We reserve the right to transfer information to a subsequent owner, co-owner or operator of the Service or the Website or our Company or the applicable database.<br />
                  The Service may disclose your Personally Identifiable Information as follows:</p>
                <ul>
                  <li>We may disclose your Personally Identifiable Information to your selected Licensed Health Care Provider when you make an appointment or elect to receive an Evaluation in the Services.</li>
                  <li>We may provide you certain areas of service associated with third parties as promotional partners, which may require the disclosure of your Personally Identifiable Information. If you decide to engage with such third parties, register for products or/and services, download their content, make communication, your Personally Identifiable Information will be disclosed to them. If you sign in to a Third party Area with a username and password obtained on the Service, your Personally Identifiable Information may be disclosed to the third parties. We are not liable and responsible for the practices and data collection/protection policies of such third parties. We recommend you to review privacy policy and/or internal privacy rules of such third parties before making engagement with them. You access these sites at your own risk and we make no representations or warranties about the content, completeness, or accuracy of these links or the sites hyperlinked to the Website, or about the privacy and security of information transmitted to or through them. You agree that we shall not be responsible for any loss or damage of any sort incurred.</li>
                  <li>We may disclose your Personally Identifiable Information to the extent that we are required to do so by law, or in connection with any ongoing or prospective legal proceedings.</li>
                  <li>We may offer contests, sweepstakes and other promotions (“<strong>Promotion</strong>”) through the Service that may require registration. If you participate in a Promotion, it means you agree to their official rules that govern that Promotion. If you enter such Promotion, your Personally Identifiable Information may be disclosed to third parties or the public. The reason of such disclosure can be administrative procedures of such Promotion, such as winner selection, price implementation, any other reasons described in official rules or required by law.</li>
                  <li>The Company may combine any information you provide us with information from other users to create aggregate data to display on the Website or Services. This sort of statistical information is called aggregate data because it reflects the characteristics of a large group of anonymous people. Aggregate data does not contain any information that could be used to identify you or contact you. For example, we might inform third parties regarding the number of users of the Website and the activities they conduct while on our site. We may use aggregate data or share it with our business partners so that the information and services we provide best meet the needs of our users. Aggregate data also helps advertisers and sponsors know how effectively they are reaching and meeting the needs of their target audience. In addition, Company may make use of de-identified information in accordance with our Terms of Use.</li>
                  <li>Any information, including personally identifiable information, that you voluntarily choose to include in a publicly accessible area of the Services will be available to anyone who has access to that content, including other users.</li>
                  <li>We may work with third parties to provide Website, Mobile Application development, hosting, maintenance, and other Services for us (“Third Party Service Providers”). To the extent it is necessary for these Third Party Service Providers to complete their contractual obligations to us, these third parties may have access to or process your personally identifiable information. Generally, these disclosures are made under terms comparable to this policy, and the recipients are limited to using the information the purpose for which it was provided. By using the Website or the Services, you are authorizing us to disclose to Third Party Service Providers the information required to complete the request. These Third Party Service Providers are not controlled by Company and personal information disclosed to them is subject to the applicable Third Party Service Provider’s privacy policy and security practices. Therefore, we encourage you to review the privacy policies of any Third Party Service Provider whose products you purchase through this Site or the Services. We may also provide your information to third-party vendors who provide services or functions on our behalf, including credit card processing, business analytics, customer service, marketing, distribution of surveys or sweepstakes programs, and fraud prevention, and we may authorize third-party vendors to collect information on our behalf, including as necessary to operate features of the Website or Services. Third-party vendors have access to and may collect information only as needed to perform their functions and are not permitted to share or use the information for any other purpose. They are also required to follow the same data security practices that we ourselves adhere to.</li>
                  <li>We may disclose information to third parties, including the third party cannabis dispensary, collective, or delivery service (collectively “Third Party Service Providers”), with whom we may jointly offer products or services, or whose products or services may be offered on our Website or through the Services. You can tell when a Third Party Service Provider is involved in a product or service you have requested because their name will appear, either alone or with ours. If you choose to access these optional third party services or communicate with these Third Party Service Providers, we may share information about you, including your personal information, with those Third Party Service Providers . Please note that we do not control the privacy practices of these third-parties.</li>
                  <li>We make certain automatically collected and other aggregate non-personally-identifiable information available to third parties, to: (i) comply with various reporting obligations; (ii) for business or marketing purposes; or (iii) to assist such parties in understanding our users’ interests, habits, and usage patterns for certain programs, content, services, advertisements, promotions, and/or functionality available through the Services.</li>
                  <li>We also reserve the right to disclose your information that we believe, in good faith, is appropriate or necessary to take precautions against liability; to protect Company and others from fraudulent, abusive, or unlawful uses or activity; to investigate and defend ourselves against any third party claims or allegations; to assist government enforcement agencies; to protect the security or integrity of the Services; to protect the rights, property, or personal safety of, Company, our users, or others, or as otherwise required by law. In such cases, we reserve the right to raise or waive any legal objection or right available to us.</li>
                  <li>If you were referred to the Website from another site (for example, through a link you clicked on another site that directed you to this one), we may share some information about you with that referring website. We have not placed limitations on any referring website’s use of your personal information and we encourage you to review the privacy policies of any website that referred you here.</li>
                  <li>We may share your personal information with our Affiliates. This sharing enables us to provide you with information about products and services which might interest you. To the extent that our Affiliates have access to your information, they will follow practices that are at least as restrictive as the practices described in this Privacy Policy. They also will comply with applicable laws governing the transmission of promotional communications and, at a minimum, give you an opportunity in any commercial email they send to choose not to receive such email messages in the future.</li>
                  <li>We may share your personal information in connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</li>
                </ul>
                <p style={customStyles.p}>Other than as set out above, you will be notified when personal information about you will be shared with third parties, and you will have an opportunity to choose not to have us share such information. You may opt-out of cookie tracking and analysis. The Services do not otherwise respond to ‘do not track’ signals.</p>
                <p style={customStyles.p}>When we deliver ads based on data about your use of the Services, we keep your identity anonymous. We won’t identify you personally to third parties, except in accordance with this privacy policy. Of course, if you choose to respond to an advertised offer with your name and contact information, you will no longer be anonymous to the advertiser.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>User warranties</strong></h4>
                <p style={customStyles.p}>User is responsible for truthful, accurate information, which is provided to us. If the provided information is false, we may disable the user’s account.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>Tracking technologies</strong></h4>
                <ul>
                  <li><u>Cookies</u> Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Website. This type of information is collected to make the Website more useful to you and to tailor the experience with us to meet your special interests and needs. You can typically remove and reject cookies from our Website with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how our Website works for you. Please note that if you delete, or choose not to accept, cookies from the Services, you may not be able to utilize the features of the Services to their fullest potential.</li>
                  <li><u>Third Party Web Beacons. </u> In addition to Cookies, we use tracking technologies such as beacons, browser fingerprinting, tags, and scripts, to analyze trends, administering the website, track users’ movements around the Website, and to gather demographic information about our user base as a whole, to prevent fraudulent activity, to improve security or to allow you to make use of Service functionality; to assess the performance of the Services, including as part of our analytic practices or otherwise to improve the content, products or services offered through the Services. Tracking Technologies may set, change, alter or modify settings or configurations on your computer, or mobile or other device.<br />
                    Third parties may also use Tracking Technologies in connection with our Services, which may include the collection of information about your online activities and across third-party web sites or online services. We do not control those Tracking Technologies and we are not responsible or liable for them.</li>
                  <li><u>Mobile Device Information</u> We do not access or store information that you have saved on your mobile device such as contacts and appointments. However, in order to provide the Services, the Website may interact with some features on your mobile device, such as your GPS locator. In such case, we will ask for your consent with respect to identifying your location.</li>
                  <li><u>Automatically Collected Information</u> When you use the Services or open one of our HTML e-mails, we may automatically record certain information from your web browser by using different types of technology, including “clear gifs” or “web beacons.” This “automatically collected” information may include your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after the Services, the pages you view on the Services, and the dates and times that you visit, access, or use the Services. This information is gathered for all users. We also may collect information about your online activity, such as pages viewed. Our goals in collecting this automatic information include helping customize your user experience and inhibiting fraud.</li>
                  <li><u>Mobile Application Information</u> We collect information about the Mobile Application functionality that you access and use. This allows us to identify those areas of the Mobile Application that are of interest to our customers so that we can refine and continuously improve the Mobile Application. The information we collect for this purpose does not enable us to directly identify you. The Mobile Application also sends us the device’s unique identifier (or “UID”), a sequence of numbers or characters that are unique to your mobile device. We use this only on the first opening of the App so that we can confirm to our advertising networks the number of downloads resulting from clicks on their respective ad banners and other marketing tools. The Mobile Application will also send us error-reporting information in the event that it crashes or hangs. This enables us to investigate the error and to improve the stability of the Mobile Application for future releases. As part of these error reports, the Mobile Application sends us information about the mobile device type and version, the UID, the time the error occurred, the feature being used and the state of the application when the error occurred. We do not use this information for any purpose other than investigating and remedying the error. You can exercise control over what information the Mobile Application sends to us either by changing the settings of the Mobile Application under its setting menu or changing the settings of your mobile device.</li>
                </ul>
              </li>
              <li>
                <h4 style={customStyles.h4}><strong>Do not track mechanism</strong></h4>
                <p style={customStyles.p}>We do not track our Customers /Users over time and across third party websites to provide targeted advertising and therefore do not respond to Do Not Track (DNT) signals. However, some third party sites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you. If you are visiting such sites, different browsers allow you to set the DNT signal on your browser so that third parties (particularly advertisers) know you do not want to be tracked.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>Third party’s Websites and Services</strong></h4>
                <p style={customStyles.p}>Our Services may facilitate interaction between the Services and your account on a third-party website and/or application, such as a Third Party Service Provider, a Licensed Health Care Provider, a medical cannabis delivery service website, or a medical cannabis collective website. The third-party operator may be involved by providing certain Personally Identifiable Information to us. We may provide third-party site interfaces or links on the Website or Services to facilitate your sending a communication from there. We use and collect this information in accordance with this PP. The Service may include functionality that allows certain kinds of interactions between the Service and your account on a third-party web site or application, such as a medical cannabis cooperative, collective, or delivery service’s website. The use of this functionality may involve the third-party operator providing certain information, including Personal Information, to us. For example, we may provide third-party sites’ interfaces or links on the Service to facilitate your sending a communication from the Service. Heally may at its sole discretion collect and use this information as Personal Information.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong> Information provided by third parties</strong></h4>
                <p style={customStyles.p}>We may receive information about you from any third parties, including, but not limited to a medical cannabis collective’s website, users of the Services, affiliates and other, that we consider relevant to your use of the Services. We collect and use this information as Personal information at our sole discretion.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>Information about location</strong></h4>
                <p style={customStyles.p}>In order to verify your location, provide relevant data based on location, share it with service providers, we may use location based services to determine your location. After you give us permission, we will provide third parties (who we work with) access to your location information.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>Children Online Privacy Protection Act (COPPA) Compliance</strong></h4>
                <p style={customStyles.p}>The features, programs, promotions and other aspects of our service requiring the submission of personally identifiable information are not intended for anyone under 18 years of age. We do not knowingly collect personally identifiable information from children under the age of 18 other than minor children receiving the service through registration by their parent or legal guardian. If you are under 18 then you may not use or access our website or services at any time or in any manner. If you are a parent or guardian of a child under the age of 18 and believe he or she has disclosed personally identifiable information to us please contact us at privacy@getheally.com. A parent or guardian of a child under the age of 18 may review and request deletion of such child’s personally identifiable information as well as prohibit the use thereof.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>The Health Insurance Portability and Accountability Act of 1996 (HIPAA) Compliance</strong></h4>
                <p style={customStyles.p}>The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is federal legislation that created national standards to protect the privacy of patients’ medical records and other personal health information.</p>
                <p style={customStyles.p}><u>HIPAA Safeguards:</u> The HIPAA Privacy and Security Rule establish standards to protect the privacy and security individuals’ medical records and other personal health information. We implement appropriate safeguards to protect the privacy and security of personal health information, and set limits and conditions on the uses and disclosures that may be made of such information without patient authorization.</p></li>
              <li>
                <h4 style={customStyles.h4}><strong>Security of Personally Identifiable Information</strong></h4>
                <p style={customStyles.p}>We use reasonable physical, technical and administrative safeguards consistent with standard industry practice to prevent the loss, misuse or alteration of your Personally Identifiable Information. These include the safeguards noted below.</p>
                <p style={customStyles.p}>We will store all the Personally Identifiable Information you provide on our secure (password- and firewall-protected) servers.</p>
                <ul>
                  <li><u>Confidentiality:</u> We restrict access to Personally Identifiable Information collected about you on the Website to select employees, licensees, consultants, service providers, or others who need to know that information to provide services to you or in the course of conducting our normal business operations. We also advise them about their responsibility to protect Customer data and we provide them with appropriate guidelines for adhering to our company’s business ethics standards and confidentiality policies. If you are a former customer, we protect your information in the same manner that we treat information about our current customers.</li>
                  <li><u>Password:</u> To provide you with an increased level of security, we utilize password security measures. Online access to your Personally Identifiable Information is protected with a password you select. We strongly recommend that you do not disclose your password to anyone. We will never ask you for your password in any unsolicited communication (including unsolicited correspondence such as letters, phone calls, or email messages).</li>
                  <li><u>Encryption:</u> When you interact on the Website or otherwise through the Services, your Personally Identifiable Information is transmitted through the Internet using Secure Socket Layer (SSL) technology. SSL technology causes your browser to encrypt your Personally Identifiable Information before transmitting it to our secure server. Encryption is the translation of data into a secret code. Once the data resides on our servers, the data remains encrypted. SSL technology, an industry standard, is designed to prevent someone other than operators of our web site from capturing and viewing your Personally Identifiable Information.</li>
                </ul>
                <p style={customStyles.p}>You are responsible for keeping the password you use for accessing our Website or Services confidential; we will not ask you for your password (except when you log in to our website).</p>
                <p style={customStyles.p}>Despite our efforts to protect your Personally Identifiable Information, there is always some risk that an unauthorized third party may find a way around our security systems or that transmissions of your information over the Internet may be intercepted. Therefore, we make no representations or warranties with regard to the sufficiency of the above mentioned security measures. No data transmission over the Internet or through mobile devices can be guaranteed to be 100% secure. While we strive to protect your Personally Identifiable Information from unauthorized access, use or disclosure, we cannot ensure or warrant the security of any information you transmit to us on the Website or Services. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information. Therefore, the Company is not responsible for any actual or consequential damages that result from a lapse in compliance with this Privacy Policy as a result of a security breach or technical malfunction.</p></li>
              <li>
                <h4 style={customStyles.h4}>Amendments</h4>
                <p style={customStyles.p}>We may update this policy from time to time by publishing a new version on the Website. The most recent version of the Policy is reflected by the “last updated” date located at the top of this Policy. Your continued use of the Website or Services following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.</p></li>
              <li>
                <h4 style={customStyles.h4}>Updating information</h4>
                <p style={customStyles.p}>Please let us know if the Personally Identifiable Information that we hold about you needs to be corrected or updated. Please contact Customer Service for detailed instructions and assistance.</p></li>
              <li>
                <h4 style={customStyles.h4}>Links to other Websites</h4>
                <p style={customStyles.p}>You may encounter links to other websites of service providers not directly affiliated with us. Please be aware that we are not responsible for the information practices of external service providers. We recommend you review the privacy statements of each external websites that collects Personally Identifiable Information.</p></li>
              <li>
                <h4 style={customStyles.h4}>Opt Out</h4>
                <p style={customStyles.p}>We may send you newsletters regarding upcoming features and promotions. You can opt out from receiving the newsletters by clicking on the “unsubscribe” link at the bottom of any email newsletter, or you may send a request to privacy@getheally.com. Your e-mail address will be removed from our marketing list. Please allow us a reasonable period of time in order to satisfy your request, as some promotions may already be in progress. We may use the e-mail and text addresses you provide when you register, to send appointment confirmations and reminders. You cannot opt out from receiving these email and text messages as it would affect the functioning of the service you are registering to use. Other emails that you cannot opt out of are emails we may send to update this Privacy Policy or for information about your accounts and our business dealings with you. These emails will be sent to you as long as you are a registered user of the Website. If you wish to no longer be a registered user of the Website, please send your request to privacy@getheally.com.</p>
                <p style={customStyles.p}>You may request us to refrain from using your information in any other way not listed above by contacting us at privacy@getheally.com or by contacting us as indicated in the “Contact Us” section of this Privacy Policy below.</p>
                <p style={customStyles.p}>You may, of course, decline to share certain personally identifiable information with us, in which case we may not be able to provide to you some of the features and functionality of the Services. You may update, correct, or delete your profile information and preferences at any time by accessing your Account preferences page through the Services. Please note that while your changes are reflected promptly in active user databases, we may retain all information you submit for a variety of purposes, including backups and archiving, prevention of fraud and abuse, and analytics.</p>
                <p style={customStyles.p}>Third-party advertisers (the “Advertisers”) may provide you with advertisements that you may see on the Services or on other affiliated websites. To improve the relevancy and help measure the effectiveness of such advertisements, the Advertisers may use cookies, Web beacons, clear gifs or similar technologies. These are used to record users’ activity, such as the pages visited, and to learn what types of information are of most interest to the users. Use of these technologies by Advertisers is subject to their own privacy policies and is not covered by our privacy policy. If you do not want Company or Advertisers to follow your usage of our Services via cookies and Web beacons, you can opt out of Cookies/Web beacons from the individual Advertiser’s website. Opting out of these services does not mean you will no longer receive online advertising. It does mean that the service from which you opted out will no longer deliver ads tailored to your Web preferences and usage patterns. Please note that, if you opt out and later delete your cookies, use a different browser, or use a different computer or other electronic device, you will need to renew your opt-out choices.</p></li>
              <li>
                <h4 style={customStyles.h4}>Visitors of Website from Outside the United States</h4>
                <p style={customStyles.p}>The Company is governed by the laws of the United States of America. By using the Website or Services and submitting any Personally Identifiable Information, visitors from outside the United States (i) acknowledge that the Website and Services are subject to U.S. law, (ii) consent to the transfer of Personally Identifiable Information to the U.S., and (iii) waive any claims that may arise under their own jurisdiction.</p></li>
              <li>
                <h4 style={customStyles.h4}>California Privacy Rights:</h4>
                <p style={customStyles.p}>Under California law, California residents are entitled to ask us for a notice describing what categories of Personal Information we share with third parties for their direct marketing purposes. The notice will identify the categories of Personal Information shared with third parties, as well as the name and address of the third parties that receive such Personal Information. If you want a copy of this notice, please submit a written request to the following address:</p>
                <address>Heally, Inc.<br />
                  6701 Mission Street, Ste. 7,<br />
                  Daly City, CA 94014</address>
              </li>
              <li>
                <h4 style={customStyles.h4}>Contact Us</h4>
                <p style={customStyles.p}>The Company welcomes your questions and comments about this Privacy Policy. If you have any questions, please contact us at privacy@getheally.com. If you do not receive adequate resolution of a privacy related problem, you may contact us at:</p>
                <address>Heally Inc., a California C corporation<br />
                  Address: 6701 Mission Street, Ste. 7,<br />
                  Daly City, CA 94014<br />
                  Telephone: (650) 300-4334<br />
                  privacy@getheally.com</address>
              </li>
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};
