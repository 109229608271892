import React from 'react';

import classnames from 'classnames/bind';
import styles from './Remedy.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { remedy, onClickRemedy, devicePrefix } = props;

  let fontSize = remedy.name.length > 8 ? '0.8em' : '1em';

  if (window.isMobile) {
    fontSize = '0.8em';
  }

  const styleRemedy = remedy.active
    ? {
      boxShadow: `#${remedy.color} 0px 0px 0px 2.5px`,
      color: 'white',
      backgroundColor: `#${remedy.color}`,
      fontSize,
      padding: '2px',
      transition: 'background-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    }
    : {
      boxShadow: `#${remedy.color} 0px 0px 0px 2.5px`,
      color: '#656565',
      fontSize,
      padding: '2px',
      transition: 'all 450ms ease 0ms',
    };
  return (
    <div className={cx(`${devicePrefix}-remedy-container`)}>
      <button
        style={styleRemedy}
        className={cx(`${devicePrefix}-remedy`)}
        onClick={() => onClickRemedy(remedy)}
      >
        {remedy.name}
      </button>
    </div>
  );
};
