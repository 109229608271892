/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import classnames from 'classnames/bind';
import styles from './UnauthorizeUser.module.scss';

const cx = classnames.bind(styles);

const UnauthorizeUser = (props) => {
  const { message, component, openModalWindow, name } = props;
  return (
    <div className={cx('container')}>
      <div className={cx('message')}>
        Please{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            openModalWindow('heally');
          }}
        >
          login
        </a>{' '}
        to see your {name ? 'profile' : 'orders'}
      </div>
    </div>
  );
};

export default UnauthorizeUser;
