import React from 'react';
import classnames from 'classnames/bind';

import Icon from 'components/Icons/Icon';
import Cross from 'components/Icons/Cross';
import Button from 'components/Button/Button';
import Container from 'components/Container/Container';
import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import MenuMenu from 'components/Menu/MenuMenu';

import styles from './Header.module.scss';

const cx = classnames.bind(styles);

export default props => {
  const {
    onSignOut,
    successLogIn,
    goToHeally,
    openModal,
    openSidebar,
    sidebar,
    router,
    brandInformation,
    devicePrefix,
    isFetchingUserInfo,
  } = props;

  const brandStyles = props.brandInformation.brand ? { color: '#636363' } : null;
  const icon = !sidebar && <Icon size="large" name="bars" inverted onClick={openSidebar} className={cx('menu-white-custom')} />;

  return (
    <>
      <Menu secondary className={cx('header-menu')}>
        <MenuItem header>
          {successLogIn && icon}
        </MenuItem>
        <MenuMenu position="right">
          <MenuItem className={cx('auth-menu')}>
            {!isFetchingUserInfo ? (
              successLogIn ? (
                <Button
                  style={brandStyles}
                  type="button"
                  basic
                  className={cx('link', `link--${devicePrefix}`, 'button-cancel')}
                  onClick={() => onSignOut(router)}
                  inverted
                >
                  Log out
            </Button>
              ) : (
                  <div>
                    <Button
                      style={brandStyles}
                      className={cx('link', `link--${devicePrefix}`)}
                      onClick={() => openModal('heally')}
                      type="button"
                      basic
                      inverted={!brandStyles}
                    >
                      Log in
              </Button>
                    <Button color="orange" type="button" className={cx('button-pink')} onClick={() => openModal('heally', 'signup')}>
                      Sign up
              </Button>
                  </div>
                )
            ) : null}
          </MenuItem>
        </MenuMenu>
      </Menu>
    </>
  );
};
