import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import * as constants from './constants';

const initialState = {
  stores: {
    data: [],
    status: 'IDLE',
    error: null,
  },
  dataSaveCart: {
    data: {},
    status: 'IDLE',
    error: null,
  },
  linkWithProducts: {
    link: null
  },
  orders: {
    data: [],
    status: 'IDLE',
    error: null,
  },
  doctorInfo: {
    data: {},
    status: 'IDLE',
    error: null,
  }
}

export default handleActions(
  {
    [constants.FETCH_STORES]: (state, action) =>
      immutable(state, {
        stores: {
          status: { $set: 'RUNNING' },
          error: { $set: null }
        }
      }),

    [constants.FETCH_STORES_SUCCESS]: (state, action) =>
      immutable(state, {
        stores: {
          status: { $set: 'SUCCESS' },
          data: { $set: action.payload },
          error: { $set: null }
        }
      }),

    [constants.FETCH_STORES_FAIL]: (state, action) =>
      immutable(state, {
        stores: {
          status: { $set: 'FAIL' },
          data: { $set: [] },
          error: { $set: action.error }
        }
      }),

    [constants.SAVE_DOCTOR_CART]: (state, action) =>
      immutable(state, {
        dataSaveCart: {
          status: { $set: 'RUNNING' },
          data: { $set: {} },
          error: { $set: null }
        }
      }),
    [constants.SAVE_DOCTOR_CART_SUCCESS]: (state, action) =>
      immutable(state, {
        dataSaveCart: {
          status: { $set: 'SUCCESS' },
          data: { $set: action.payload }
        }
      }),
    [constants.SAVE_DOCTOR_CART_FAIL]: (state, action) =>
      immutable(state, {
        dataSaveCart: {
          status: { $set: 'FAIL' },
          error: { $set: action.error }
        }
      }),

    [constants.ERASE_DATA_SAVE_CART]: (state, action) =>
      immutable(state, {
        dataSaveCart: {
          status: { $set: 'IDLE' },
          data: { $set: {} }
        }
      }),

    [constants.SET_LINK_WITH_PRODUCTS]: (state, action) =>
      immutable(state, {
        linkWithProducts: {
          link: { $set: action.link }
        }
      }),

    [constants.FETCH_ORDERS]: (state, action) =>
      immutable(state, {
        orders: {
          status: { $set: 'RUNNING' },
          error: { $set: null }
        }
      }),

    [constants.FETCH_ORDERS_SUCCESS]: (state, action) =>
      immutable(state, {
        orders: {
          data: { $set: action.payload },
          status: { $set: 'SUCCESS' }
        }
      }),

    [constants.FETCH_ORDERS_FAIL]: (state, action) =>
      immutable(state, {
        orders: {
          status: { $set: 'FAIL' },
          error: { $set: action.error }
        }
      }),

    [constants.DELETE_DOCTOR_CART_SUCCESS]: (state, _) =>
      immutable(state, {
        dataSaveCart: {
          status: { $set: 'IDLE' },
          data: { $set: {} }
        }
      }),

    [constants.FETCH_DOCTOR_INFO]: (state, _) =>
      immutable(state, {
        doctorInfo: {
          status: { $set: 'RUNNING' },
          error: { $set: null }
        }
      }),
    [constants.FETCH_DOCTOR_INFO_SUCCESS]: (state, { payload }) =>
      immutable(state, {
        doctorInfo: {
          status: { $set: 'SUCCESS' },
          data: { $set: payload }
        }
      }),
    [constants.FETCH_DOCTOR_INFO_FAIL]: (state, { error }) =>
      immutable(state, {
        doctorInfo: {
          status: { $set: 'FAIL' },
          error: { $set: error }
        }
      }),
    [constants.CHANGE_PATIENT_CART_EMAIL]: (state, { payload }) =>
      immutable(state, {
        doctorInfo: {
          data: {
            patient_cart_email: { $set: payload }
          }
        }
      })
  },
  initialState
);
