import React from 'react';

import { createShorthandFactory, getUnhandledProps } from 'utils/lib';
import Image from 'components/Image/Image';

const ItemImage = (props) => {
  const { size } = props;

  const rest = getUnhandledProps(ItemImage, props);

  return <Image {...rest} size={size} ui={!!size} wrapped />;
};

ItemImage.create = createShorthandFactory(ItemImage, content => ({ content }));

export default ItemImage;
