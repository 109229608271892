import React, { Component } from 'react';

import classnames from 'classnames/bind';
import styles from './Tabs.module.scss';

const cx = classnames.bind(styles);

class Tabs extends Component {
  state = {
    activeTabIndex: this.props.defaultActiveIndex
  };

  handleTabClick = tabIndex => {
    this.setState({
      activeTabIndex:
        tabIndex === this.state.activeTabIndex
          ? this.props.defaultActiveIndex
          : tabIndex
    });
  };

  renderChildrenWithTabsApiAdProps = () => {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex
      });
    });
  };

  renderActiveTabContent = () => {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  };

  render() {
    return (
      <div className={cx('tabs')}>
        <div className={cx('tabs-container')}>
          {this.renderChildrenWithTabsApiAdProps()}
        </div>
        <div>{this.renderActiveTabContent()}</div>
      </div>
    );
  }
}

export default Tabs;
