import { connect } from 'react-redux';
import Products from '../components/Products';

import {
  fetchListProducts,
  fetchProductInformation,
  onBuyProduct,
  onBuyProductClose,
  filterProducts,
  sendErrorWithStoreId,
  fetchProductComments,
  resetProductInformation,
  addComment,
  updateComment,
  setRatingForProductCard,
} from 'modules/products/actions';
import {
  addToCartProduct,
  initializeCart,
  resetCart,
  removeAnimationFrameElement,
} from 'modules/cart/actions';
import { handleClickRemedy } from 'modules/remedies/actions';
import { viewStoresWithOvernightDelivery, fetchStoreInfo, changeDeliveryOption, fetchStores } from 'modules/stores/actions';
import {
  getTotalQuantity,
  getItemsProducts,
  getStoreId,
} from 'modules/cart/selectors';
import { getListProducts, getCheckoutProducts, getNewComment, getUpdateComment, getRating } from 'modules/products/selectors';
import { getRemedies } from 'modules/remedies/selectors';

import { getBrandInformation, getNameCategoryForHeaderButton, getDeliveryAddress, } from 'modules/base/selectors';
import { getStoreInfo, getOneStoreForSameDelivery, getBufferStoreInfo, getMessageForStoresOvernight, getListStores, getDeliveryMessage, getSameDay } from 'modules/stores/selectors';

import { setNameCategoryForHeaderButton, setDeliveryAddress, } from 'modules/base/actions';

import { getLogInInfo } from 'modules/user/selectors';

import { setCategoryDescription } from 'modules/category/actions';

const mapStateToProps = (state) => {
  const products = getListProducts(state.products);
  const remediesData = getRemedies(state.remedies);

  let listCategories = products.listCategories;
  let categoriesName = products.categoriesName;

  if (remediesData.remedyActive.length > 0) {
    listCategories = products.listCategories
      .map((category) => {
        const cloneCategory = Object.assign({}, category);
        cloneCategory.products = cloneCategory.products.filter((product) => {
          let flag = false;
          remediesData.remedyActive.map((remedy) => {
            if (product.remedy_ids.indexOf(remedy.id) >= 0) {
              flag = true;
              return;
            }
          });
          return flag;
        });
        return cloneCategory;
      })
      .filter(v => v.products.length > 0);
    categoriesName = listCategories.map(v => v.name);
  }

  return {
    products: getListProducts(state.products),
    totalQuantity: getTotalQuantity(state.cart),
    itemsProducts: getItemsProducts(state.cart),
    storeId: getStoreId(state.cart),
    brandInformation: getBrandInformation(state.base),
    storeInfo: getStoreInfo(state.stores),
    deliveryAddress: getDeliveryAddress(state.base),
    oneStoreForSameDelivery: getOneStoreForSameDelivery(state.stores),
    bufferStoreInfo: getBufferStoreInfo(state.stores),
    categoriesName,
    listCategories,
    remediesData,
    messageForStoresOvernight: getMessageForStoresOvernight(state.stores),
    listStores: getListStores(state.stores),
    checkoutProducts: getCheckoutProducts(state.products),
    deliveryMessage: getDeliveryMessage(state.stores),
    sameDay: getSameDay(state.stores),
    nameCategoryForHeaderButton: getNameCategoryForHeaderButton(state.base),
    successLogIn: getLogInInfo(state.user),
    newComment: getNewComment(state.products),
    updComment: getUpdateComment(state.products),
    rating: getRating(state.products),
  };
};

export default connect(
  mapStateToProps,
  {
    fetchListProducts,
    fetchProductInformation,
    onBuyProduct,
    onBuyProductClose,
    addToCartProduct,
    initializeCart,
    resetCart,
    handleClickRemedy,
    filterProducts,
    viewStoresWithOvernightDelivery,
    fetchStoreInfo,
    removeAnimationFrameElement,
    changeDeliveryOption,
    setDeliveryAddress,
    fetchStores,
    sendErrorWithStoreId,
    setNameCategoryForHeaderButton,
    fetchProductComments,
    resetProductInformation,
    addComment,
    updateComment,
    setRatingForProductCard,
    setCategoryDescription,
  }
)(Products);
