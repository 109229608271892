import React from 'react';
import { convertToDecimal } from 'services';

import MDRender from 'services/MDRender';

import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import Rating from 'components/Rating';

import classnames from 'classnames/bind';
import styles from './ProductCard.module.scss';
import PlantType from './PlantType';

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];
const cx = classnames.bind(styles);

export default (props) => {
  const { card, devicePrefix, windowHeight } = props;
  let cost = '';
  let quantity = null;
  const brand = card.brand ? `${card.brand}` : '';

  if (card && card.price instanceof Object && Object.keys(card.price).length === 0) {
    return null;
  }

  switch (card.packing) {
    case 'oz': {
      const steps = Object.keys(card.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a));
      cost = card.price[steps[steps.length - 1]];
      quantity = steps[steps.length - 1];
      break;
    }

    case 'piece': {
      cost = card.price;
      quantity = 1;
      break;
    }

    case 'gram': {
      const grams = Object.keys(card.price);
      const parseGrams = grams.map(value => parseFloat(value));
      parseGrams.sort((a, b) => a - b);
      cost = card.price[parseGrams[0]];
      quantity = parseGrams[0].toString();
      break;
    }

    case 'piece_discrete': {
      cost = card.price[0];
      quantity = 1;
      break;
    }

    case 'gram_oz': {
      const filterGramOz = [];
      Object.keys(card.price).forEach((price, index) => {
        if (GRAM_OZ.indexOf(price) >= 0) {
          filterGramOz.push({
            position: index,
            packing: price,
            cost: card.price[price],
          });
        }
      });
      const sortGramOz = filterGramOz
        .sort((a, b) => a.position - b.position)
        .map(value => value.packing);
      cost = card.price[sortGramOz[0]];
      quantity = sortGramOz[0];
      break;
    }

    default:
      break;
  }

  const markdownDesc = MDRender(card.description);

  let productName = card.name;

  if (windowHeight <= 750 && productName.length > 66) {
    productName = `${(brand + productName).slice(0, 58)}...`;
  } else if (productName.length >= 99) {
    productName = `${(brand + productName).slice(0, 63)}...`;
  }

  let averegeRate = card.averege_rate;

  if (props.rating && props.rating.product_id && props.rating.product_id === card.product_id) {
    if (averegeRate !== props.rating.averege_rate) {
      averegeRate = props.rating.averege_rate;
    }
  }

  return (
    <div itemScope className={cx('card-container', 'flip-card-front', card.in_cart && 'flip-card-front-blur')} id={`${card.id}_product`}>
      <div
        className={cx(window.isTablet || window.isMobile ? 'card-image-tablet' : 'card-image')}
      >
        <Image
          itemProp="image"
          className={cx('card-product-image')}
          src={card.image && card.image.wide}
          alt=""
          fluid
          onClick={() => cost && props.onClick(card)}
        />
      </div>
      {card.plant_type && <PlantType type={card.plant_type} devicePrefix={devicePrefix} />}
      <div className={cx('card-description')}>
        <div className={cx('title-container')} style={!card.plant_type && window.isMobile ? { margin: '0 0 0 0.25rem' } : null}>
          <h2 itemProp="name" className={cx('title')} onClick={() => cost && props.onClick(card)}>
            {`${productName}`}
          </h2>
          {/* <Card onClick={onBuyNow} /> */}
        </div>
        <div
          itemProp="description"
          className={cx('description', `description--${devicePrefix}`)}
          dangerouslySetInnerHTML={{
            __html: markdownDesc.length > 115 ? `${markdownDesc.slice(0, 115)}...` : markdownDesc,
          }}
        />
        {averegeRate && averegeRate !== '-' &&
          <div className={cx('rating-wrapper')}>
            <Rating className={cx('custom-rating')} color="orange" maxRating={5} icon="star" disabled rating={averegeRate} />
          </div>
        }
        <div className={cx('card-footer')}>
          <Button
            // disabled={!cost}
            type="button"
            color="teal"
            itemProp="price"
            disabled={!cost}
            style={{ textTransform: 'none' }}
            className={cx('custom-button')}
            // className={cx(!cost ? 'button-off' : 'button', `button--${devicePrefix}`)}
            onClick={() => props.onBuy(card, quantity)}
          >
            {`Add to Сart $${
              !cost ? 0 : Math.round(cost) === 0 ? cost.toFixed(2) : Math.round(cost)
              }`}
          </Button>
        </div>
      </div>
    </div >
  );
};
