import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { hot } from 'react-hot-loader';

import withTracker from 'HOC/logViewPage';
import captureException from 'HOC/captureException';
import WelcomePage from './containers/WelcomePage';

import SellerLayout from './containers/SellerLayout';
import DoctorLayout from './containers/DoctorLayout';

import User from './routes/User/containers/User';
import UserOrders from './routes/UserOrders/containers/UserOrders';
import OrderInformation from './routes/OrderInformation/containers/OrderInformation';
import Privacy from './routes/Privacy/containers/Privacy';
import Terms from './routes/Terms/containers/Terms';
import Empty from './routes/Empty/containers/Empty';
import AppContainer from './containers/AppContainer';
import StoreInformation from './routes/Seller/StoreInformation/containers/StoreInformaion';
import DeliveryLocation from './routes/Seller/DeliveryLocation/containers/DeliveryLocation';
import ManageProducts from './routes/Seller/ManageProducts/containers/ManageProducts';
import ManageOrders from './routes/Seller/ManageOrders/containers/ManageOrders';
import SuggestProducts from './routes/Seller/SuggestProducts/containers/SuggestProducts';
import Category from './routes/Category/containers/Category';
import Loader from 'components/Loaders/Loader';
import Cart from './routes/Cart/containers/Cart';
import Products from './routes/Products/containers/Products';
import Stores from './routes/Stores/containers/Stores';

import DoctorStores from './routes/Doctor/Stores/container/Stores';
import DoctorProducts from './routes/Doctor/Products/container/Products';
import DoctorCart from './routes/Doctor/Cart/container/Cart';
import DoctorOrders from './routes/Doctor/Orders/container/Orders';

const history = createBrowserHistory();
const MainLayout = React.lazy(() => import('./containers/MainLayout'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Switch>
    <Route
      {...rest}
      render={props => (
        <AppContainer {...props}>
          <Layout {...props} domainPath={rest.domainPath}>
            <Component {...props} domainPath={rest.domainPath} />
          </Layout>
        </AppContainer>
      )}
    />
  </Switch>
)

const BaseLayout = props => props.children;

const AltLayout = props => <Suspense fallback={<Loader active />}><MainLayout {...props}>{props.children}</MainLayout></Suspense>;

const SellerPage = props => <SellerLayout {...props}>{props.children}</SellerLayout>;

const DoctorPage = props => <DoctorLayout {...props} />

const domainPath = window.location.hostname.match('getheally') ? '/marketplace/' : '/';

export default hot(module)(() => (
  <BrowserRouter>
    <Switch>
      <AppRoute
        exact
        path={`${domainPath}`}
        layout={BaseLayout}
        component={withTracker(captureException(WelcomePage))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}stores`}
        layout={AltLayout}
        component={withTracker(captureException(Stores))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}products`}
        layout={AltLayout}
        component={withTracker(captureException(Products))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}category/:category`}
        layout={AltLayout}
        component={withTracker(captureException(Category))}
        domainPath={domainPath}
      />
      <AppRoute path={`${domainPath}cart`} layout={AltLayout} component={withTracker(captureException(Cart))} domainPath={domainPath} />
      <AppRoute path={`${domainPath}user`} layout={AltLayout} component={withTracker(captureException(User))} domainPath={domainPath} />
      <AppRoute
        path={`${domainPath}user_orders`}
        layout={AltLayout}
        component={withTracker(captureException(UserOrders))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}order/:hash`}
        layout={AltLayout}
        component={withTracker(captureException(OrderInformation))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}privacy`}
        layout={AltLayout}
        component={withTracker(captureException(Privacy))}
        domainPath={domainPath}
      />
      <AppRoute path={`${domainPath}terms`} layout={AltLayout} component={withTracker(captureException(Terms))} domainPath={domainPath} />
      <AppRoute
        path={`${domainPath}seller/store_info`}
        layout={SellerPage}
        component={withTracker(captureException(StoreInformation))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}seller/delivery_location`}
        layout={SellerPage}
        component={withTracker(captureException(DeliveryLocation))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}seller/manage_products`}
        layout={SellerPage}
        component={withTracker(captureException(ManageProducts))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}seller/manage_orders`}
        layout={SellerPage}
        component={withTracker(captureException(ManageOrders))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}seller/suggest_products`}
        layout={SellerPage}
        component={withTracker(captureException(SuggestProducts))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}doctor/stores`}
        layout={DoctorPage}
        component={withTracker(captureException(DoctorStores))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}doctor/products`}
        layout={DoctorPage}
        component={withTracker(captureException(DoctorProducts))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}doctor/cart`}
        layout={DoctorPage}
        component={withTracker(captureException(DoctorCart))}
        domainPath={domainPath}
      />
      <AppRoute
        path={`${domainPath}doctor/orders`}
        layout={DoctorPage}
        component={withTracker(captureException(DoctorOrders))}
        domainPath={domainPath}
      />
      <AppRoute path={`${domainPath}empty`} layout={AltLayout} component={withTracker(captureException(Empty))} domainPath={domainPath} />
      <Redirect from={`${domainPath}seller`} to={`${domainPath}seller/store_info`} domainPath={domainPath} />
      <Redirect from={`${domainPath}doctor`} to={`${domainPath}doctor/stores`} domainPath={domainPath} />
      <Redirect from="*" to={`${domainPath}empty`} status={404} domainPath={domainPath} />
    </Switch>
  </BrowserRouter>
));
