import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import SearchBar from 'components/SearchBar';

import Button from 'components/Button';

import classnames from 'classnames/bind';
import styles from './SearchAddress.module.scss';

const cx = classnames.bind(styles);

class SearchAddress extends Component {
  state = {
    value: '',
    error: false,
  };

  onChange = (address) => {
    this.setState({
      value: address,
      error: false,
    });
  };

  handleSelect = (address) => {
    const { goToDelivery } = this.props;

    goToDelivery(address);
  };

  render() {
    const { message, notShowCBDLink } = this.props;
    const { value, error } = this.state;

    const defaultStyles = {
      root: {
        width: '100%',
      },
      input: {
        boxSizing: 'border-box',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)',
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontSize: '16px',
        border: 'honeydew',
        padding: '12px 36px 12px 12px',
        borderRadius: '2px',
        outline: 'none',
      },
      autocompleteContainer: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        borderRadius: '0 0 5px 5px',
        borderTop: '1px solid #e6e6e6',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        overflow: 'hidden',
        border: 'none',
        zIndex: 13,
      },
    };

    const inputProps = {
      placeholder: 'Please enter your delivery address...',
      onChange: this.onChange,
      value,
    };

    const options = {
      componentRestrictions: { country: 'us' },
    };

    return (
      <div className={cx('search-address-container')}>
        <div style={{ padding: '0 10px' }}>
          {/* <div style={{ textAlign: 'center', marginBottom: '1em' }}>
            <a href="/products?order_type=delivery&store=30" style={{ color: '#00cab4', fontWeight: 'bold', fontSize: '24px', textDecoration: 'underline' }}>Shop CBD 25% off</a>
          </div> */}
          <div className={cx('text-message')}> {message} </div>
          <div className={cx('search-address-wrapper')}>
            <SearchBar
              address={this.state.value}
              onChange={this.onChange}
              onSelect={this.handleSelect}
            />
            {/* <PlacesAutocomplete
              googleLogo={false}
              inputProps={inputProps}
              options={options}
              styles={defaultStyles}
              onSelect={address => this.handleSelect(address)}
            /> */}
          </div>
          {error && <span className={cx('error-message')}>{error}</span>}
        </div>
      </div>
    );
  }
}

export default SearchAddress;
