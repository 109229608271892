/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import classnames from 'classnames/bind';

import { isNil, getElementType, getUnhandledProps, useVerticalAlignProp } from 'utils/lib';
import ItemHeader from './ItemHeader';
import ItemMeta from './ItemMeta';
import ItemDescription from './ItemDescription';
import ItemExtra from './ItemExtra';

import styles from './Item.scss';

const cx = classnames.bind(styles);

const ItemContent = (props) => {
  const { children, className, content, description, extra, header, meta, verticalAlign } = props;

  const aligned = (verticalAlign && useVerticalAlignProp(verticalAlign).split(' ')) || [];
  const classes = cx(...aligned, 'content', className);
  const rest = getUnhandledProps(ItemContent, props);
  const ElementType = getElementType(ItemContent, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={cx('middle', 'aligned', 'content')}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {ItemHeader.create(header, { autoGenerateKey: false })}
      {ItemMeta.create(meta, { autoGenerateKey: false })}
      {ItemDescription.create(description, { autoGenerateKey: false })}
      {ItemExtra.create(extra, { autoGenerateKey: false })}
      {content}
    </ElementType>
  );
};

export default ItemContent;
