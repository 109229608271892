import React from 'react';
import classnames from 'classnames/bind';

import {
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  isNil,
} from 'utils/lib';

import styles from './Accordion.css';

const cx = classnames.bind(styles);

const AccordionContent = (props) => {
  const { active, children, className, content } = props;
  const classes = cx('content', useKeyOnly(active, 'active'), className);
  const rest = getUnhandledProps(AccordionContent, props);
  const ElementType = getElementType(AccordionContent, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

AccordionContent.create = createShorthandFactory(AccordionContent, content => ({ content }));

export default AccordionContent;
