import { connect } from 'react-redux';

import {
  getListSuggestProducts,
  getIsFetchingSuggestProducts,
  getSuggestProduct,
  getIsShowSuggestProduct,
  getListCategories,
  getFormattedListBrands,
  getListStrains,
  getIsFetchingListStrains,
  getListRemediesByStrain,
  getIsFetchingRemediesByStrain,
  getIsCreatingNewSuggestProduct,
} from 'modules/seller/selectors';

import {
  getListOfRemedies,
  getIsFetchingListOfRemedies,
} from 'modules/remedies/selectors';

import {
  getListOfAllRemedies,
} from 'modules/remedies/actions';

import {
  fetchSuggestProducts,
  showSuggestProduct,
  deleteSuggestProduct,
  fetchListBrands,
  fetchListOfCategory,
  fetchListOfAllStrains,
  fetchRemediesByStrain,
  createNewSuggestProduct,
  updateSuggestProduct,
} from 'modules/seller/actions';

import SuggestProducts from '../components/SuggestProducts';

const mapStateToProps = state => ({
  listSuggestProducts: getListSuggestProducts(state.seller),
  isFetchingSuggestProducts: getIsFetchingSuggestProducts(state.seller),
  suggestProduct: getSuggestProduct(state.seller),
  isShowSuggestProduct: getIsShowSuggestProduct(state.seller),
  listCategories: getListCategories(state.seller),
  formattedListBrands: getFormattedListBrands(state.seller),
  listOfRemedies: getListOfRemedies(state.remedies),
  isFetchingListOfRemedies: getIsFetchingListOfRemedies(state.remedies),
  listStrains: getListStrains(state.seller),
  isFetchingListStrains: getIsFetchingListStrains(state.seller),
  listRemediesByStrain: getListRemediesByStrain(state.seller),
  isFetchingRemediesByStrain: getIsFetchingRemediesByStrain(state.seller),
  isCreatingNewSuggestProduct: getIsCreatingNewSuggestProduct(state.seller),
});

export default connect(
  mapStateToProps,
  {
    fetchSuggestProducts,
    showSuggestProduct,
    deleteSuggestProduct,
    fetchListBrands,
    fetchListOfCategory,
    getListOfAllRemedies,
    fetchListOfAllStrains,
    fetchRemediesByStrain,
    createNewSuggestProduct,
    updateSuggestProduct,
  }
)(SuggestProducts);
