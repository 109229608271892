import React from 'react';
import classnames from 'classnames/bind';

import Notification from 'rc-notification';
import Icon from 'components/Icons/Icon';

import styles from './Notification.scss';

const cx = classnames.bind(styles);

const notificationInstance = {};
let defaultDuration = 4.5;
let defaultTop = 24;
let defaultBottom = 24;
let defaultPlacement = 'topRight';
let defaultGetContainer;

const setNotificationConfig = (options) => {
  const { duration, placement, bottom, top, getContainer } = options;

  if (duration !== undefined) {
    defaultDuration = duration;
  }
  if (placement !== undefined) {
    defaultPlacement = placement;
  }
  if (bottom !== undefined) {
    defaultBottom = bottom;
  }
  if (top !== undefined) {
    defaultTop = top;
  }
  if (getContainer !== undefined) {
    defaultGetContainer = getContainer;
  }
};

function getPlacementStyle(placement) {
  let style;
  switch (placement) {
    case 'topLeft':
      style = {
        left: 0,
        top: defaultTop,
        bottom: 'auto',
      };
      break;
    case 'topRight':
      style = {
        right: 0,
        top: defaultTop,
        bottom: 'auto',
      };
      break;
    case 'bottomLeft':
      style = {
        left: 0,
        top: 'auto',
        bottom: defaultBottom,
      };
      break;
    case 'bottom':
      style = {
        bottom: defaultBottom,
        top: 'auto',
        right: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginRight: '0px',
      };
      break;
    default:
      style = {
        right: 0,
        top: 'auto',
        bottom: defaultBottom,
      };
      break;
  }
  return style;
}

function getNotificationInstance(
  prefixCls,
  args,
  placement,
  callback,
) {
  const cacheKey = `${prefixCls}-${placement}`;
  if (notificationInstance[cacheKey]) {
    callback(notificationInstance[cacheKey]);
    return;
  }
  (Notification).newInstance(
    {
      prefixCls,
      className: cx(`${prefixCls}-${placement}`),
      style: getPlacementStyle(placement),
      getContainer: defaultGetContainer,
      closeIcon: <Icon color="grey" style={{ position: 'absolute', top: '5px', right: '5px', outline: 'none', cursor: 'pointer' }} className={`${prefixCls}-close-icon`} name="close" onClose={args.onClose} />,
    },
    (notification) => {
      notificationInstance[cacheKey] = notification;
      callback(notification);
    },
  );
}

const typeToIcon = {
  success: 'check circle',
  info: 'info circle',
  error: 'close',
  warning: 'warning circle',
};

const colorToIcon = {
  success: 'green',
  info: 'blue',
  error: 'red',
  warning: 'yellow',
};

function notice(args) {
  const outerPrefixCls = args.prefixCls || 'heally-notification';
  const prefixCls = cx(`${outerPrefixCls}--notice`);

  const duration = args.duration === undefined ? defaultDuration : args.duration;

  let iconNode = null;
  if (args.icon) {
    iconNode = <span className={cx(`${prefixCls}-icon`)}>{args.icon}</span>;
  } else if (args.type) {
    const iconType = typeToIcon[args.type];
    iconNode = (
      <Icon color={colorToIcon[args.type]} style={{ position: 'absolute', marginLeft: '4px', lineHeight: '24px', left: '10px', cursor: 'pointer' }} size="large" className={cx(`${prefixCls}-icon ${prefixCls}-icon-${args.type}`)} name={iconType} onClose={args.onClose} />
    );
  }
  const autoMarginTag = !args.description && iconNode ? (
    <span className={`${prefixCls}-message-single-line-auto-margin`} />
  ) : null;
  getNotificationInstance(
    outerPrefixCls,
    args,
    args.placement || defaultPlacement,
    (notification) => {
      notification.notice({
        content: (
          <div className={iconNode ? cx(`heally-notification-with-icon`) : ''}>
            {iconNode}
            <div className={cx(`heally-notification-message`, args.enableFontStyle ? 'custom-message' : null)}>
              {autoMarginTag}
              {args.message}
            </div>
            <div className={cx(`heally-notification-description`, args.enableFontStyle ? 'custom-description' : null)}>{args.description}</div>
            {args.btn ? <span className={cx(`${prefixCls}-btn`)}>{args.btn}</span> : null}
          </div>
        ),
        duration,
        closable: true,
        onClose: args.onClose,
        onClick: args.onClick,
        key: args.key,
        style: args.style || {},
        className: args.className,
      });
    },
  );
}

const api = {
  open: notice,
  close(key) {
    Object.keys(notificationInstance).forEach(cacheKey => notificationInstance[cacheKey].removeNotice(key));
  },
  config: setNotificationConfig,
  destroy() {
    Object.keys(notificationInstance).forEach((cacheKey) => {
      notificationInstance[cacheKey].destroy();
      delete notificationInstance[cacheKey];
    });
  },
};

['success', 'info', 'warning', 'error'].forEach((type) => {
  api[type] = args => api.open({
    ...args,
    type,
  });
});

api.warn = api.warning;

export default api;
