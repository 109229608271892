import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { ThemeProvider } from '@callstack/react-theme-provider';

import Auth from 'components/Auth';
//import Button from 'components/Button';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalActions from 'components/ModalWindow/ModalActions';
import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';
import Cross from 'components/Icons/Cross';

import notification from 'components/Notification';
import Modal from 'components/ModalWindow/Modal';

import themes from 'styles/themes';

import {
  onSignIn,
  onSignUp,
  onSignOut,
  fetchUserInfo,
  removeCurrentState,
  renderForm,
  openModalWindow,
  resetPassword,
  openChangePasswordModalWindow,
  sendNewPassword,
  resentCode,
  authBrowserCode,
} from 'modules/user/actions';

import {
  getLogInInfo,
  getStateModal,
  getSignInInfo,
  getSignUpInfo,
  getUserInfo,
  getRenderContent,
  getResetPasswordMessages,
  getResetPasswordToken,
  getToSendNewPasswordMessageFail,
  getIsPartnerLogin,
} from 'modules/user/selectors';

import setBrand from 'modules/base/actions';
import { getIsFetchingBrand, getBrandInformation } from 'modules/base/selectors';

import { getPhoneNumber } from 'modules/cart/selectors';

import { getLocalStorage, saveLocalStorage } from 'services';

import classnames from 'classnames/bind';
import styles from './AppContainer.module.scss';

const cx = classnames.bind(styles);
const whiteAddressList = ['staging', 'preprod', 'local'];

const encodeQueryData = (data) => {
  const ret = [];
  for (const d in data) ret.push(`${d}=${data[d]}`);
  return ret.join('&');
};

class AppContainer extends Component {
  state = {
    agePermissionModal: false,
  };

  async componentDidMount() {
    const {
      fetchUserInfo,
      successLogIn,
      location,
      openChangePasswordModalWindow,
      history,
      setBrand,
      brandInformation,
    } = this.props;

    const resetPasswordToken = queryString.parse(location.search).reset_password_token;
    const accountBlocked = queryString.parse(location.search).blocked;
    const parseAddress = this.parseAddressHost(window.location.host);

    if (parseAddress && !brandInformation.brand) {
      setBrand(parseAddress);
    }

    if (accountBlocked) {
      notification.error({
        message: 'Error',
        description: 'Account is blocked by administration.',
      });
    }

    if (resetPasswordToken) {
      openChangePasswordModalWindow(resetPasswordToken, history);
    }

    if (!successLogIn) {
      await fetchUserInfo();
    }

    if (!getLocalStorage('agePermission', false)) {
      this.openAgePermissionModal();
    }
  }

  openAgePermissionModal = () => {
    this.setState({
      agePermissionModal: true,
    });
  };

  saveAgePermission = () => {
    saveLocalStorage('agePermission', true, false).then(() => {
      this.setState({
        agePermissionModal: false,
      });
    });
  };

  goToGoogle = () => {
    window.location = 'http://www.google.com';
  };

  parseAddressHost = (address) => {
    const fullAddress = address;
    const partsAddress = fullAddress.split('.');

    if (partsAddress.length >= 3) {
      if (whiteAddressList.indexOf(partsAddress[0]) >= 0 || partsAddress.indexOf('g') >= 0) {
        return null;
      }
      return partsAddress[0];
    }
    return null;
  };

  removeCurrentState = () => {
    const { removeCurrentState, location, history } = this.props;

    if (queryString.parse(location.search).pay_online) {
      const objectQueryString = queryString.parse(location.search);
      delete objectQueryString.pay_online;

      const queryStrings = encodeQueryData(objectQueryString);

      history.replace(`${location.pathname}?${queryStrings}`);
    }

    removeCurrentState();
  }

  render() {
    const {
      children,
      location,
      history,
      onSignIn,
      onSignUp,
      renderForm,
      stateModal,
      signInInfo,
      renderContent,
      signUpInfo,
      resetPassword,
      resetPasswordMessages,
      sendNewPassword,
      resetPasswordToken,
      isFetchingBrand,
      brandInformation,
      sendNewPasswordMessageFail,
      isPartnerLogin,
      resentCode,
      authBrowserCode,
      onSignOut,
      phoneNumber
    } = this.props;

    const { agePermissionModal } = this.state;

    if (isFetchingBrand) {
      return <div />;
    }

    if (agePermissionModal) {
      return (
        <Modal
          open={agePermissionModal}
          size="mini"
        >
          <ModalContent>
            <p style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Are you over 21 years old?</p>
          </ModalContent>
          <ModalActions style={{ padding: '1rem 1rem !important' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                style={{ marginBottom: '1rem' }}
                secondary
                onClick={this.saveAgePermission}
                color="teal"
              >
                Yes, I'm 21+
                  </Button>
              <Button style={{ marginBottom: '1rem' }} secondary onClick={this.goToGoogle}>No</Button>
            </div>
          </ModalActions>
        </Modal>
      )
    }

    return (
      <ThemeProvider
        theme={brandInformation.brand ? themes[brandInformation.brand.style] : themes.normal}
      >
        <div>
          {children}
          <Modal
            open={stateModal && true}
            size={renderContent === 'twoAuth' ? 'tiny' : 'mini'}
            closeIcon={<Cross onClick={this.removeCurrentState} />}
          >
            <ModalContent>
              <Auth
                renderContent={renderContent}
                onSignIn={onSignIn}
                onSignUp={onSignUp}
                stateModal={stateModal}
                signInInfo={signInInfo}
                signUpInfo={signUpInfo}
                renderForm={renderForm}
                closeModal={this.removeCurrentState}
                resetPassword={resetPassword}
                resetPasswordMessages={resetPasswordMessages}
                sendNewPassword={sendNewPassword}
                resetPasswordToken={resetPasswordToken}
                closeModalWindow={this.removeCurrentState}
                router={history}
                sendNewPasswordMessageFail={sendNewPasswordMessageFail}
                resentCode={resentCode}
                authBrowserCode={authBrowserCode}
                onSignOut={onSignOut}
                phoneNumber={phoneNumber}
              />
            </ModalContent>
          </Modal>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  stateModal: getStateModal(state.user),
  signInInfo: getSignInInfo(state.user),
  signUpInfo: getSignUpInfo(state.user),
  renderContent: getRenderContent(state.user),
  resetPasswordMessages: getResetPasswordMessages(state.user),
  resetPasswordToken: getResetPasswordToken(state.user),
  isFetchingBrand: getIsFetchingBrand(state.base),
  brandInformation: getBrandInformation(state.base),
  sendNewPasswordMessageFail: getToSendNewPasswordMessageFail(state.user),
  isPartnerLogin: getIsPartnerLogin(state.user),
  phoneNumber: getPhoneNumber(state.cart)
});

export default connect(
  mapStateToProps,
  {
    fetchUserInfo,
    onSignIn,
    onSignUp,
    removeCurrentState,
    renderForm,
    resetPassword,
    sendNewPassword,
    setBrand,
    openChangePasswordModalWindow,
    resentCode,
    authBrowserCode,
    onSignOut,
  }
)(AppContainer);
