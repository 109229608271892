import React from 'react';
import classnames from 'classnames/bind';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';
import styles from './Label.css';

const cx = classnames.bind(styles);

const LabelDetail = (props) => {
  const { children, className, content } = props;
  const classes = cx('detail', className);
  const rest = getUnhandledProps(LabelDetail, props);
  const ElementType = getElementType(LabelDetail, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

LabelDetail.create = createShorthandFactory(LabelDetail, val => ({ content: val }));

export default LabelDetail;
