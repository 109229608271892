import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Container from 'components/Container/Container';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Tab from 'components/Tab/Tab';

import Icon from 'components/Icons/Icon';
import MenuItem from 'components/Menu/MenuItem';
import Label from 'components/Label/Label';

import Transition from 'components/Transition/Transition';

import styles from './TabPanes.module.scss';

const cx = classnames.bind(styles);

const label_style = {
  background: '#999',
  color: '#fff',
  marginLeft: '1em',
  padding: '.3em .78571429em',
};

class TabPanes extends Component {
  state = {
    panes: [],
  }

  renderPanes = () => [
    {
      menuItem: (
        <MenuItem key="store_info" onClick={() => this.props.renderPage('store_info')}>
          <Icon name="settings" />
          Store details
          </MenuItem>
      ),
      render: () => null,
    },
    {
      menuItem: (
        <MenuItem key="orders" onClick={() => this.props.renderPage('orders')}>
          <Icon name="shopping bag" />
          Orders pending
            <Label style={label_style}>{this.props.numberOfOrdersPending}</Label>
        </MenuItem>
      ),
      render: () => null,
    },
    {
      menuItem: (
        <MenuItem key="products_in_stock" onClick={() => this.props.renderPage('products')}>
          <Icon name="shopping cart" />
          Products in stock
            <Label style={label_style}>{this.props.numberOfProductsInStock}</Label>
        </MenuItem>
      ),
      render: () => null,
    },
    {
      menuItem: (
        <MenuItem key="delivery_locations" onClick={() => this.props.renderPage('delivery')}>
          <Icon name="map marker alternate" />
          Delivery location
            <Label style={label_style}>{this.props.numberOfDeliveryLocations}</Label>
        </MenuItem>
      ),
      render: () => null,
    },
    {
      menuItem: (
        <MenuItem key="suggest_products" onClick={() => this.props.renderPage('suggest_products')}>
          <Icon name="leaf" />
          Suggest products
        </MenuItem>
      ),
      render: () => null,
    },
  ]

  render() {
    const { panes } = this.state;
    const { activeIndex, onTabChange } = this.props;

    return (
      <Tab grid={{ paneWidth: 12, tabWidth: 5 }} className={cx('tabs-container')} panes={this.renderPanes()} menu={{ secondary: true, pointing: true }} activeIndex={activeIndex} onTabChange={onTabChange} />
    );
  }
}

export default TabPanes;
