import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Header from 'components/Typography/Header/Header';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Label from 'components/Label/Label';
import Checkbox from 'components/CheckBox/Checkbox';

import styles from './Extra.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '25%',
  paddingLeft: '1em',
  paddingRight: '1em',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

class Extra extends Component {
  render() {
    const { disabled, storeInformation, onChange } = this.props;

    return (
      <div style={{ marginTop: '2em' }}>
        <Header as="h4" block>
          Extra section
        </Header>
        <div style={{ display: 'flex' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Same day delivery flag:</label>
            <div className={cx('checkbox-container')}>
              <Checkbox
                toggle
                disabled={disabled}
                checked={storeInformation.same_day_delivery}
                onClick={onChange}
                name="same_day_delivery"
                style={{ opacity: 1 }}
              />
            </div>
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Delivery time:</label>
            <div className={cx('input-container')}>
              <Input
                focus={!disabled}
                fluid
                placeholder="10:00am - 5:00pm"
                disabled={disabled}
                defaultValue={storeInformation.delivery_time}
                name="delivery_time"
                onChange={onChange}
                style={{ opacity: 1 }}
                className={cx('main-style')}
              />
            </div>
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Default Shipping cost:</label>
            <div className={cx('input-container')}>
              <Input
                focus={!disabled}
                fluid
                disabled={disabled}
                icon="dollar"
                defaultValue={storeInformation.default_delivery_cost}
                name="default_delivery_cost"
                onChange={onChange}
                type="number"
                style={{ opacity: 1 }}
                className={cx('main-style')}
              />
            </div>
          </FormField>
        </div>
      </div>
    );
  }
}

export default Extra;
