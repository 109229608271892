import React, { Component } from 'react';

import MDRender from 'services/MDRender';
import Image from 'components/Image';
import Icon from 'components/Icons/Icon';

import classnames from 'classnames/bind';
import styles from './ProductInformation.module.scss';

const cx = classnames.bind(styles);

class ProductInformation extends Component {
  renderProductImage = (image) => {
    if (window.isMobile) {
      return (
        <div className={cx('product-image')} style={{ width: 'auto', height: 'auto' }}>
          <img width={307} height="auto" src={image && image.default} alt="" />
        </div>
      );
    }

    if (window.isTablet && window.isLandscape) {
      return (
        <div
          style={window.isLandscape ? { width: '283px', height: '218px' } : null}
          className={cx('product-image')}
        >
          <Image width={283} height={218} src={image && image.default} />
        </div>
      );
    }

    return (
      <div style={{ width: '404px', height: '311px' }}>
        <Image width={405} height={312} src={image && image.default} />
      </div>
    );
  };

  render() {
    const { product } = this.props;
    return (
      <div className={cx('wrapper')}>
        <div className={cx('name')}>{`${product.name}`}</div>
        <div className={cx('content')}>
          <div className={cx('image-wrapper')}>
            {this.renderProductImage(product.image)}
          </div>

          <div className={cx('description')}>
            <div
              style={window.isTablet && window.isLandscape ? { height: '125px' } : null}
              className={cx('description-content')}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: MDRender(product.description),
                }}
              />
            </div>
            <div style={{ display: 'flex' }} className={cx('block-unavaible')}>
              <Icon name="ewarning circle" color="yellow" style={{ fontSize: '24px' }} />
              <span style={{ marginLeft: '6px', fontSize: '16px' }}>Sorry, this product is currently out of stock</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductInformation;
