export const FIELDS_SUGGEST_PRODUCT = {
  name: 'Name',
  brand: 'Brand',
  approved: 'Approved',
  causer: 'Causer',
  category: 'Category',
  packing: 'Packing',
  description: 'Description',
  strains: 'Strains',
  strains_remedies: 'Strains Remedies',
  remedies: 'Remedies',
  groups: 'Groups',
  cbd: 'CBD',
  additional_cbd_info: 'Additional CBD info',
  thc: 'THC',
  additional_thc_info: 'Additional THC info',
  total_size: 'Total size',
  terpene: 'Terpene',
  serving: 'Serving',
  plant_type: 'Plant type',
  updated_at: 'Updated at',
  created_at: 'Created_at',
  image: 'Image',
  used_in_stores: 'Used in stores',
};

export const INPUT_FIELDS = ['name', 'cbd', 'additional_thc_info', 'additional_cbd_info', 'total_size', 'terpene', 'serving', 'thc'];
export const FIELDS_WITH_LIST = ['packing', 'plant_type'];
export const TEXTAREA_FIELDS = ['description'];

export const OPTIONS_PACKING = [
  {
    key: 1,
    text: 'piece',
    value: 'piece',
  },
  {
    key: 2,
    text: 'oz',
    value: 'oz',
  },
  {
    key: 3,
    text: 'gram',
    value: 'gram',
  },
  {
    key: 4,
    text: 'gram/oz',
    value: 'gram_oz',
  },
  {
    key: 5,
    text: 'piece/descrete',
    value: 'piece_descrete',
  },
];

export const OPTIONS_PLANT_TYPE = [
  {
    key: 1,
    text: 'CBD',
    value: 'cbd',
  },
  {
    key: 2,
    text: 'Sativa',
    value: 'Sativa',
  },
  {
    key: 3,
    text: 'Hybrid',
    value: 'Hybrid',
  },
  {
    key: 4,
    text: 'Hybrid: Indica-dominant',
    value: 'Hybrid: Indica-dominant',
  },
  {
    key: 5,
    text: 'Hybrid: Sativa-dominant',
    value: 'Hybrid: Sativa-dominant',
  },
];
