import React, { useState, useEffect } from 'react';

import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './CellPhone.module.scss';

import useDebounce from 'hooks/debounce.hook';

const cx = classnames.bind(styles);

export default (props) => {
  const { phoneNumber, setPhoneNumber, numberPosition, saveToLocalStorage, onFocusField, error } = props;
  const [phone, setPhone] = useState(phoneNumber);
  const deboucedPhoneNumber = useDebounce(phone, 500);

  useEffect(() => {
    if (deboucedPhoneNumber) {
      setPhoneNumber(deboucedPhoneNumber);
    }
  }, [deboucedPhoneNumber]);

  const addMask = (value) => {
    const USNumber = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    const number = !USNumber[2]
      ? USNumber[1]
      : `(${USNumber[1]}) ${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;

    return number;
  }

  const changePhoneHandler = event => {
    const phoneWithMask = addMask(event.target.value);

    if (phoneNumber.length <= 14) {
      setPhone(phoneWithMask);
    }
  }

  return (
    <div className={cx('cell-phone-container')}>
      <h1 className={cx('cell-phone-title')}>
        {numberPosition}
        . Cell phone
      </h1>
      <div className={cx('cell-phone-input-container')}>
        <Input
          type="tel"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="Enter phone..."
          value={phone}
          onChange={(e) => changePhoneHandler(e)}
          // onBlur={updatePhoneNumber}
          className={cx('cell-phone-input')}
          size="medium"
          error={error}
          name="phoneNumber"
          onFocus={() => onFocusField('phoneNumber')}
        />
      </div>
    </div>
  );
};
