import React from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Header from 'components/Typography/Header/Header';
import Icon from 'components/Icons/Icon';

import styles from './Category.css';

const cx = classnames.bind(styles);

const Category = ({ categoryData, match, history }) => {

  return (
    <div className={cx('category-wrapper')}>
      <Segment basic loading={categoryData.status === 'RUNNING'}>
        <Grid container stackable>
          <GridRow>
            <GridColumn width={1}>
              <Icon
                size="big"
                link
                name="long arrow alternate left"
                style={{ color: 'rgb(18, 143, 188)' }}
                onClick={() => history.goBack()}
              />
            </GridColumn>
            <GridColumn width={15}>
              <Header as="h1" style={{ color: 'rgb(18, 143, 188)' }}>{match.params.category}</Header>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={16}>
              <div className={cx('info-category-wrapper')} dangerouslySetInnerHTML={{
                __html: categoryData.data
              }}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Segment>
    </div>
  )
}

export default Category;
