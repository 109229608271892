import { connect } from 'react-redux';

import { fetchListProducts, onBuyProduct } from 'modules/products/actions';

import { setLinkWithProducts } from 'modules/doctor/actions'

import {
  addToCartProduct,
  initializeCart,
} from 'modules/cart/actions';

import { handleClickRemedy } from 'modules/remedies/actions';

import { getListProducts } from 'modules/products/selectors';
import { getTotalQuantity, getItemsProducts, getStoreId } from 'modules/cart/selectors';
import { getRemedies } from 'modules/remedies/selectors';

import Products from '../components';

const mapStateToProps = (state) => {
  const products = getListProducts(state.products);
  const remediesData = getRemedies(state.remedies);

  let listCategories = products.listCategories;
  let categoriesName = products.categoriesName;

  if (remediesData.remedyActive.length > 0) {
    listCategories = products.listCategories
      .map((category) => {
        const cloneCategory = Object.assign({}, category);
        cloneCategory.products = cloneCategory.products.filter((product) => {
          let flag = false;
          remediesData.remedyActive.map((remedy) => {
            if (product.remedy_ids.indexOf(remedy.id) >= 0) {
              flag = true;
              return;
            }
          });
          return flag;
        });
        return cloneCategory;
      })
      .filter(v => v.products.length > 0);
    categoriesName = listCategories.map(v => v.name);
  }

  return {
    products: getListProducts(state.products),
    totalQuantity: getTotalQuantity(state.cart),
    itemsProducts: getItemsProducts(state.cart),
    storeId: getStoreId(state.cart),
    categoriesName,
    listCategories,
    remediesData,
  }
}

export default connect(mapStateToProps, {
  fetchListProducts,
  initializeCart,
  addToCartProduct,
  setLinkWithProducts,
  onBuyProduct,
  handleClickRemedy
})(Products)
