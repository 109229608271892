import React from 'react';

import classnames from 'classnames/bind';
import styles from './Cross.module.scss';

const cx = classnames.bind(styles);

const Cross = props => (
  <button className={cx('cross-icon')} onClick={props.onClick}>
    <div>
      <svg
        viewBox="0 0 24 24"
        className={cx('cross-svg')}
      >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </div>
  </button>
);

export default Cross;
