import { Children, Component } from 'react';
import { findDOMNode } from 'react-dom';

import { handleRef } from 'utils/handleRef';

export default class Ref extends Component {
  componentDidMount() {
    handleRef(this.props.innerRef, findDOMNode(this));
  }

  componentWillUnmount() {
    handleRef(this.props.innerRef, null);
  }

  render() {
    const { children } = this.props;

    return Children.only(children);
  }
}
