import React from 'react';

const Marker = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 426.667 426.667"
    style={{ enableBackground: 'new 0 0 426.667 426.667' }}
    xmlSpace="preserve"
    width="16px"
    height="16px"
  >
    <g>
      <g>
        <path
          d="M213.333,0C130.88,0,64,66.88,64,149.333c0,112,149.333,277.333,149.333,277.333s149.333-165.333,149.333-277.333    C362.667,66.88,295.787,0,213.333,0z M213.333,202.667c-29.44,0-53.333-23.893-53.333-53.333S183.893,96,213.333,96    s53.333,23.893,53.333,53.333S242.773,202.667,213.333,202.667z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default Marker;
