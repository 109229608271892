import { request } from 'services/api';
import * as constantsCart from 'modules/cart/constants';
import * as constants from './constants';

export const fetchStores = () => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_STORES]
  });

  try {
    const response = await request('/api/v1/doctor_panel/stores');
    const json = await response.json();

    dispatch({
      type: [constants.FETCH_STORES_SUCCESS],
      payload: json.data
    })
  } catch (err) {
    dispatch({
      type: [constants.FETCH_STORES_FAIL],
      error: err,
    })
  }
}

export const saveCart = (email) => async (dispatch) => {
  dispatch({
    type: [constants.SAVE_DOCTOR_CART]
  })

  try {
    const response = await request('/api/v1/doctor_panel/cart', 'POST', { email });
    const json = await response.json();
    if (json.status >= 400) {
      dispatch({
        type: [constants.SAVE_DOCTOR_CART_FAIL],
        error: json.error,
      })
    } else {
      dispatch({
        type: [constants.SAVE_DOCTOR_CART_SUCCESS],
        payload: json.data
      });
      dispatch(deleteDoctorCart());
    }
  } catch (err) {
    dispatch({
      type: [constants.SAVE_DOCTOR_CART_FAIL],
      error: err,
    })
  }
}

export const deleteDoctorCart = () => async (dispatch) => {
  dispatch({
    type: [constants.DELETE_DOCTOR_CART]
  });

  try {
    const response = await request('/api/v1/doctor_panel/cart', 'DELETE', {});
    const json = await response.json();

    if (json.status >= 400) {
      dispatch({
        type: [constants.DELETE_DOCTOR_CART_FAIL],
        error: json.error,
      })
    } else {
      dispatch({
        type: [constants.DELETE_DOCTOR_CART_SUCCESS],
        payload: json.data
      })
      dispatch({
        type: [constantsCart.RESET_INFO_CART],
      })
    }
  } catch (error) {
    dispatch({
      type: [constants.DELETE_DOCTOR_CART_FAIL],
      payload: error
    })
  }
}

export const eraseDataSaveCart = () => async (dispatch) => {
  dispatch({
    type: [constants.ERASE_DATA_SAVE_CART]
  });
  dispatch({
    type: [constantsCart.RESET_CART_WITH_PRODUCTS]
  })
}

export const setLinkWithProducts = (link) => async (dispatch) => {
  dispatch({
    type: [constants.SET_LINK_WITH_PRODUCTS],
    link
  })
}

export const fetchOrders = () => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_ORDERS]
  });

  try {
    const response = await request('/api/v1/doctor_panel/orders');
    const json = await response.json();

    dispatch({
      type: [constants.FETCH_ORDERS_SUCCESS],
      payload: json.data
    })
  } catch (error) {
    dispatch({
      type: [constants.FETCH_ORDERS_FAIL],
      error,
    })
  }
}

export const fetchDoctorInfo = () => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_DOCTOR_INFO]
  });

  try {
    const response = await request('/api/v1/doctor_panel/doctors/info');
    const json = await response.json();

    dispatch({
      type: [constants.FETCH_DOCTOR_INFO_SUCCESS],
      payload: json.data
    })
  } catch (error) {
    dispatch({
      type: [constants.FETCH_DOCTOR_INFO_FAIL],
      error
    })
  }
}

export const changePatientCartEmail = (value) => async (dispatch) => {
  dispatch({
    type: [constants.CHANGE_PATIENT_CART_EMAIL],
    payload: value
  })
}
