import React from 'react';
import Placeholder from 'components/Placeholder/Placeholder';
import PlaceholderImage from 'components/Placeholder/PlaceholderImage';
import PlaceholderLine from 'components/Placeholder/PlaceholderLine';
import PlaceholderHeader from 'components/Placeholder/PlaceholderHeader';
import PlaceholderParagraph from 'components/Placeholder/PlaceholderParagraph';
import Card from 'components/Card/Card';
import CardContent from 'components/Card/CardContent';
import CardGroup from 'components/Card/CardGroup';

import classnames from 'classnames/bind';
import styles from './Stores.module.scss';

const cx = classnames.bind(styles);

const Stores = props => (
  <div className={cx('container')}>
    <div className={cx('stores-wrapper')}>
      <CardGroup className={cx('group-cards-container')}>
        <Card style={{ margin: '0.875em 0.5em' }}>
          <Placeholder>
            <PlaceholderImage style={{ height: '181px' }} />
          </Placeholder>
          <CardContent style={{ height: '158px' }}>
            <Placeholder>
              <PlaceholderHeader>
                <PlaceholderLine length='medium' />
              </PlaceholderHeader>
              <PlaceholderParagraph>
                <PlaceholderLine length='short' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
              <PlaceholderParagraph>
                <PlaceholderLine length='medium' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
            </Placeholder>
          </CardContent>
        </Card>
        <Card style={{ margin: '0.875em 0.5em' }}>
          <Placeholder>
            <PlaceholderImage style={{ height: '181px' }} />
          </Placeholder>
          <CardContent style={{ height: '158px' }}>
            <Placeholder>
              <PlaceholderHeader>
                <PlaceholderLine length='medium' />
              </PlaceholderHeader>
              <PlaceholderParagraph>
                <PlaceholderLine length='short' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
              <PlaceholderParagraph>
                <PlaceholderLine length='medium' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
            </Placeholder>
          </CardContent>
        </Card>
        <Card style={{ margin: '0.875em 0.5em' }}>
          <Placeholder>
            <PlaceholderImage style={{ height: '181px' }} />
          </Placeholder>
          <CardContent style={{ height: '158px' }}>
            <Placeholder>
              <PlaceholderHeader>
                <PlaceholderLine length='medium' />
              </PlaceholderHeader>
              <PlaceholderParagraph>
                <PlaceholderLine length='short' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
              <PlaceholderParagraph>
                <PlaceholderLine length='medium' />
                <PlaceholderLine length='long' />
              </PlaceholderParagraph>
            </Placeholder>
          </CardContent>
        </Card>
      </CardGroup>
    </div>
  </div>
);

export default Stores;
