import React, { Component } from 'react';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './ChangePassword.module.scss';

const cx = classnames.bind(styles);

const inputError = {
  border: 'solid 2px rgb(244, 67, 54)',
};

class ChangePassword extends Component {
  state = {
    password: {
      value: '',
      error: false,
    },
    passwordRepeat: {
      value: '',
      error: false,
    },
    errorForm: null,
  };

  checkPassword = (e, element) => {
    const statePassword = this.state[element];
    const password = e.target.value.trim();

    statePassword.value = password;

    if (password.length < 8) {
      statePassword.error = true;
    } else {
      statePassword.error = false;
    }

    this.setState({
      [element]: statePassword,
      errorForm: null,
    });
  };

  submitNewPassword = () => {
    const { sendNewPassword, resetPasswordToken } = this.props;

    if (this.state.password.value.length < 8 || this.state.passwordRepeat.value.length < 8) {
      return this.renderError('Password should be at least 8 characters length');
    }

    if (this.state.password.value !== this.state.passwordRepeat.value) {
      return this.renderError('Password fields should match');
    }

    return sendNewPassword(
      this.state.password.value,
      this.state.passwordRepeat.value,
      resetPasswordToken
    );
  };

  renderError = (error) => {
    this.setState({
      errorForm: error,
    });
  };

  render() {
    const { sendNewPasswordMessageFail } = this.props;
    return (
      <Form>
        <h3 style={{ textAlign: 'center' }}>Change password</h3>
        <FormField>
          <Input
            error={this.state.password.error}
            fluid
            type="password"
            placeholder="New password"
            onChange={e => this.checkPassword(e, 'password')}
          />
        </FormField>
        <FormField>
          <Input
            error={this.state.passwordRepeat.error}
            fluid
            type="password"
            placeholder="Repeat password"
            onChange={e => this.checkPassword(e, 'passwordRepeat')}
          />
        </FormField>
        {sendNewPasswordMessageFail && (
          <div className={cx('error-message')}>{sendNewPasswordMessageFail}</div>
        )}
        {this.state.errorForm && <div className={cx('error-message')}>{this.state.errorForm}</div>}
        <Button type="button" primary fluid onClick={this.submitNewPassword}>Submit</Button>
      </Form>
    );
  }
}

export default ChangePassword;
