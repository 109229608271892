import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import styles from './TwoAuthFactor.module.scss';

const cx = classnames.bind(styles);

class TwoAuthFactor extends Component {
  state = {
    code: '',
    error: false,
  }

  authBrowserCode = () => {
    const { code } = this.state;
    const { authBrowserCode, router } = this.props;

    if (code.trim().length === 0) {
      this.setState({
        error: true,
      })
    } else {
      authBrowserCode(code, router);
    }
  }

  onChange = (e, data) => {
    this.setState({
      code: data.value,
      error: false,
    })
  }

  render() {
    const { resentCode, onSignOut, router } = this.props;
    const { code, error } = this.state;

    return (
      <Form>
        <h3 style={{ textAlign: 'center' }}>Please enter the verification code</h3>
        <FormField>
          <Input error={error} fluid placeholder="Code" onChange={this.onChange} value={code} />
        </FormField>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button secondary type="button" onClick={resentCode}>resent code</Button>
          <Button secondary type="button" onClick={() => onSignOut(router)}>Change user</Button>
          <Button type="button" primary onClick={this.authBrowserCode}>Check</Button>
        </div>
      </Form>
    )
  }
}

export default TwoAuthFactor;
