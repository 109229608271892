import React from 'react';
import { NextArrow } from 'images/svg/utils';

import classnames from 'classnames/bind';
import styles from './NextArrowButton.module.scss';

const cx = classnames.bind(styles);

const NextArrowButton = props => (
  <button
    id={`${props.id}_btn-next`}
    className={cx('next', props.customClassName)}
    onClick={() => props.toScroll(1)}
  >
    <NextArrow
      styles={{ fill: '#fff', position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
    />
  </button>
);

export default NextArrowButton;
