import React from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  AutoControlledComponent as Component,
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';
import styles from './Accordion.css';

import AccordionPanel from './AccordionPanel';

const cx = classnames.bind(styles);

class AccordionAccordion extends Component {
  static defaultProps = {
    exclusive: true,
  };

  static autoControlledProps = ['activeIndex'];

  getInitialAutoControlledState({ exclusive }) {
    return { activeIndex: exclusive ? -1 : [] };
  }

  computeNewIndex = (index) => {
    const { exclusive } = this.props;
    const { activeIndex } = this.state;

    if (exclusive) return index === activeIndex ? -1 : index;
    // check to see if index is in array, and remove it, if not then add it
    return _.includes(activeIndex, index) ? _.without(activeIndex, index) : [...activeIndex, index];
  };

  handleTitleClick = (e, titleProps) => {
    const { index } = titleProps;

    this.trySetState({ activeIndex: this.computeNewIndex(index) });
    _.invoke(this.props, 'onTitleClick', e, titleProps);
  };

  isIndexActive = (index) => {
    const { exclusive } = this.props;
    const { activeIndex } = this.state;

    return exclusive ? activeIndex === index : _.includes(activeIndex, index);
  };

  render() {
    const { className, children, panels } = this.props;

    const classes = cx('accordion', className);
    const rest = getUnhandledProps(AccordionAccordion, this.props);
    const ElementType = getElementType(AccordionAccordion, this.props);
    return (
      <ElementType {...rest} className={classes}>
        {isNil(children)
          ? _.map(panels, (panel, index) => AccordionPanel.create(panel, {
            defaultProps: {
              active: this.isIndexActive(index),
              index,
              onTitleClick: this.handleTitleClick,
            },
          }))
          : children}
      </ElementType>
    );
  }
}

AccordionAccordion.create = createShorthandFactory(AccordionAccordion, content => ({ content }));

export default AccordionAccordion;
