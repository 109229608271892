import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import InfoDeliveryLocation from './components/InfoDeliveryLocation';


class CustomMarker extends Component {
  state = {
    isOpen: null,
  }

  handleToggleOpen = (position) => {
    const { fetchDeliveryLocationData, deliveryLocationData } = this.props;

    this.setState({
      isOpen: {
        lat: position.lat,
        lng: position.lng,
      },
    }, () => {
      //fetchDeliveryLocationData(position, deliveryLocationData);
    });
  };

  onCloseInfoWindow = () => {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { position, deliveryLocationData } = this.props;
    const { isOpen } = this.state;

    const hasDeliveryLocationData = Object.keys(deliveryLocationData).length > 0 && deliveryLocationData[position.id];

    return (
      <Marker position={position} onClick={() => this.handleToggleOpen(position)}>
        {isOpen && <InfoWindow position={this.state.isOpen}>
          <h1>hello</h1>
        </InfoWindow>}
      </Marker>
    )
  }
}

export default CustomMarker;
