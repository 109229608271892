export const FETCH_STORES = 'FETCH_STORES';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAIL = 'FETCH_STORES_FAIL';

export const FETCH_STORE_INFO = 'FETCH_STORE_INFO';
export const FETCH_STORE_INFO_SUCCESS = 'FETCH_STORE_INFO_SUCCESS';
export const FETCH_STORE_INFO_FAIL = 'FETCH_STORE_INFO_FAIL';

export const FILTER_STORES_SUPPORT_CREDIT_CARD = 'FILTER_STORES_SUPPORT_CREDIT_CARD';

export const RETURN_BUFFER_STORES_INFO = 'RETURN_BUFFER_STORES_INFO';

export const SAVE_INFO_STORE_QUERY = 'SAVE_INFO_STORE_QUERY';

export const HANDLE_SAME_DAY_DELIVERY = 'HANDLE_SAME_DAY_DELIVERY';

export const REDIRECT_TO_PRODUCTS_PAGE = 'REDIRECT_TO_PRODUCTS_PAGE';

export const RESET_STORES = 'RESET_STORES';

export const VIEW_STORES_WITH_OVERNIGHT_DELIVERY = 'VIEW_STORES_WITH_OVERNIGHT_DELIVERY';

export const HANDLE_GO_BACK_TO_PRODUCTS = 'HANDLE_GO_BACK_TO_PRODUCTS';
export const HANDLE_DELIVERY_DATA = 'HANDLE_DELIVERY_DATA';
export const CHANGE_DELIVERY_OPTION = 'CHANGE_DELIVERY_OPTION';

export const RESET_STORES_AND_STORE_INFORMATION = 'RESET_STORES_AND_STORE_INFORMATION';
