import React, { Component } from 'react';
import { Cross } from 'images/svg/utils';
import Plus from 'images/svg/plus';
import Minus from 'images/svg/minus';
import { convertToDecimal } from 'services';
import Icon from 'components/Icons/Icon';

import debounce from 'services/debounce';

import classnames from 'classnames/bind';
import styles from './Packing.module.scss';

const cx = classnames.bind(styles);

class Packing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastStep: this.props.itemProduct && this.props.itemProduct.quantity,
      stepsGramOz:
        this.props.filterGramOz.length > 0 &&
        this.props.filterGramOz.sort((a, b) => a.position - b.position).map(value => value.packing),
      stepsGram:
        this.props.product.packing === 'gram' &&
        Object.keys(this.props.product.price).sort((a, b) => a - b),
      stepsOz:
        this.props.product.packing === 'oz' &&
        Object.keys(this.props.product.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a)),
      disabledPlus: false,
      disabledMinus: false,
    };

    this.handleSetProduct = debounce(this.handleSetProduct, 500).bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.itemProduct &&
  //     nextProps.itemProduct.quantity !== this.state.lastStep
  //   ) {
  //     this.setState({
  //       lastStep: nextProps.itemProduct.quantity
  //     });
  //   }
  // }

  onPlus = (lastStep) => {
    const { product, setProduct } = this.props;
    const steps = this.state.steps;

    if (product.packing !== 'piece') {
      if (
        product.packing === 'gram_oz' &&
        this.state.stepsGramOz.indexOf(lastStep) < this.state.stepsGramOz.length - 1
      ) {
        const gramStep = this.state.stepsGramOz.indexOf(lastStep);

        this.handleSetProduct(product, this.state.stepsGramOz[gramStep + 1]);
        this.setState({
          lastStep: this.state.stepsGramOz[gramStep + 1],
          disabledMinus: false,
        });
      }
      if (
        product.packing === 'gram_oz' &&
        this.state.stepsGramOz.indexOf(lastStep) === this.state.stepsGramOz.length - 1
      ) {
        this.setState({
          disabledPlus: true,
        });
      }

      if (product.packing === 'piece_discrete') {
        this.handleSetProduct(product, parseInt(lastStep) + 1);
        this.setState({
          lastStep: parseInt(lastStep) + 1,
          disabledMinus: false,
        });
      }

      if (product.packing === 'oz' && this.state.stepsOz.indexOf(lastStep) !== 0) {
        this.handleSetProduct(
          product,
          this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) - 1]
        );
        this.setState({
          lastStep: this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) - 1],
          disabledMinus: false,
        });
      }

      if (product.packing === 'oz' && this.state.stepsOz.indexOf(lastStep) === 0) {
        this.setState({
          disabledPlus: true,
        });
      }

      if (
        product.packing === 'gram' &&
        this.state.stepsGram.indexOf(lastStep) < this.state.stepsGram.length - 1
      ) {
        const gramStep = this.state.stepsGram.indexOf(lastStep.toString());
        this.handleSetProduct(product, this.state.stepsGram[gramStep + 1]);
        this.setState({
          lastStep: this.state.stepsGram[gramStep + 1],
          disabledMinus: false,
        });
      }

      if (
        product.packing === 'gram' &&
        this.state.stepsGram.indexOf(lastStep) === this.state.stepsGram.length - 1
      ) {
        this.setState({
          disabledPlus: true,
        });
      }
    } else {
      this.handleSetProduct(product, parseInt(this.state.lastStep) + 1);
      this.setState({
        lastStep: parseInt(this.state.lastStep) + 1,
        disabledMinus: false,
      });
    }
  };

  onMinus = (lastStep) => {
    const { product, setProduct } = this.props;
    const steps = this.state.steps;

    if (product.packing !== 'piece') {
      if (product.packing === 'gram_oz') {
        if (this.state.stepsGramOz.indexOf(lastStep) !== 0) {
          this.handleSetProduct(
            product,
            this.state.stepsGramOz[this.state.stepsGramOz.indexOf(lastStep) - 1]
          );
          this.setState({
            lastStep: this.state.stepsGramOz[this.state.stepsGramOz.indexOf(lastStep) - 1],
            disabledPlus: false,
          });
        } else {
          this.handleSetProduct(product, 0);
        }
      }

      if (product.packing === 'piece_discrete') {
        if (lastStep !== 1) {
          this.handleSetProduct(product, parseInt(lastStep) - 1);
          this.setState({
            lastStep: parseInt(lastStep) - 1,
            disabledPlus: false,
          });
        } else {
          this.handleSetProduct(product, 0);
        }
      }

      if (product.packing === 'oz') {
        if (this.state.stepsOz.indexOf(lastStep) < this.state.stepsOz.length - 1) {
          this.handleSetProduct(
            product,
            this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) + 1]
          );
          this.setState({
            lastStep: this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) + 1],
            disabledPlus: false,
          });
        } else {
          this.handleSetProduct(product, 0);
        }
      }

      if (product.packing === 'gram') {
        if (this.state.stepsGram.indexOf(lastStep) !== 0) {
          this.handleSetProduct(
            product,
            this.state.stepsGram[this.state.stepsGram.indexOf(lastStep) - 1]
          );
          this.setState({
            lastStep: this.state.stepsGram[this.state.stepsGram.indexOf(lastStep) - 1],
            disabledPlus: false,
          });
        } else {
          this.handleSetProduct(product, 0);
        }
      }
    }

    if (product.packing === 'piece') {
      if (parseInt(lastStep) > 1) {
        this.handleSetProduct(product, parseInt(lastStep) - 1);
        this.setState({
          lastStep: lastStep - 1,
          disabledPlus: false,
        });
      } else {
        this.handleSetProduct(product, 0);
      }
    }
  };

  handleSetProduct(product, step) {
    const { setProduct } = this.props;

    setProduct(product, step);
  }

  render() {
    const { removeFromCart, product, disabledPacking } = this.props;
    let currentPrice = null;
    let lastStep = this.state.lastStep;
    let disabledPlus = this.state.disabledPlus;
    let disabledMinus = this.state.disabledMinus;

    if (!disabledPacking) {
      switch (product.packing) {
        case 'piece':
          lastStep = this.state.lastStep;
          currentPrice = product.price * this.state.lastStep;
          if (lastStep == '1') {
            disabledMinus = true;
          }
          break;
        case 'oz':
          if (lastStep === undefined) {
            lastStep = this.state.stepsOz[this.state.stepsOz.length - 1];
          }
          if (this.state.stepsOz.indexOf(lastStep) === 0) {
            disabledPlus = true;
          }

          if (this.state.stepsOz.indexOf(lastStep) === this.state.stepsOz.length - 1) {
            disabledMinus = true;
          }
          currentPrice = product.price[lastStep];
          break;
        case 'gram':
          if (lastStep === undefined) {
            const parseGrams = this.state.stepsGram.map(value => parseFloat(value));
            parseGrams.sort((a, b) => a - b);
            lastStep = parseGrams[0];
          }

          if (this.state.stepsGram.indexOf(lastStep) === this.state.stepsGram.length - 1) {
            disabledPlus = true;
          }

          if (this.state.stepsGram.indexOf(lastStep) === 0) {
            disabledMinus = true;
          }
          currentPrice = product.price[lastStep];
          break;
        case 'piece_discrete':
          lastStep = this.state.lastStep;
          disabledPlus = false;
          if (lastStep >= product.price.length) {
            currentPrice = lastStep * product.price[product.price.length - 1];
          } else {
            currentPrice = lastStep * product.price[lastStep - 1];
          }

          if (lastStep === 1) {
            disabledMinus = true;
          }
          break;
        case 'gram_oz':
          lastStep = this.state.lastStep;

          if (this.state.stepsGramOz.indexOf(lastStep) === 0) {
            disabledMinus = true;
          }

          if (this.state.stepsGramOz.indexOf(lastStep) === this.state.stepsGramOz.length - 1) {
            disabledPlus = true;
          }
          currentPrice = product.price[lastStep];
          break;
        default:
          break;
      }
    }

    if (disabledPacking) {
      currentPrice = parseFloat(product.price);
      lastStep = product.quantity;
    }

    if (
      this.state.stepsOz.length === 1 ||
      this.state.stepsGram.length === 1 ||
      this.state.stepsGramOz.length === 1
    ) {
      disabledPlus = true;
    }
    return (
      <div className={disabledPacking ? cx('disabled-packing') : cx('measure-container')}>
        <div className={cx('measure-wrapper')}>
          {disabledPacking ? null : (
            <button
              type="button"
              className={cx('measure-button-active')}
              onClick={() => this.onMinus(lastStep)}
            >
              <div className={cx('container-button-small')}>
                <Minus style={disabledMinus ? { fill: 'rgb(253, 161, 133)' } : null} />
              </div>
            </button>
          )}

          <div style={disabledPacking ? { fontSize: '1.5em' } : null} className={cx('measure')}>
            {product.packing === 'oz' || product.packing === 'gram'
              ? `${lastStep}${product.packing}`
              : lastStep}
          </div>
          {disabledPacking ? null : (
            <button
              type="button"
              className={disabledPlus ? cx('measure-button-disabled') : cx('measure-button-active')}
              onClick={() => this.onPlus(lastStep)}
            >
              <div className={cx('container-button-small')}>
                <Plus />
              </div>
            </button>
          )}
        </div>
        <div className={cx('product-cost')}>${currentPrice && typeof currentPrice === 'string' ? parseInt(currentPrice).toFixed(2) : currentPrice.toFixed(2)}</div>
      </div>
    );
  }
}

export default Packing;
