/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Comment.css';

const cx = classnames.bind(styles);

const Comment = (props) => {
  const { className, children, collapsed, content } = props;

  const classes = cx(useKeyOnly(collapsed, 'collapsed'), 'comment', className);
  const rest = getUnhandledProps(Comment, props);
  const ElementType = getElementType(Comment, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default Comment;
