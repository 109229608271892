import { cloneElement, Component } from 'react';
import {
  handleRef,
} from 'utils/lib';

class RefForward extends Component {
  handleRefOverride = (node) => {
    const { children, innerRef } = this.props;

    handleRef(children.ref, node);
    handleRef(innerRef, node);
  }

  render() {
    const { children } = this.props;

    return cloneElement(children, {
      ref: this.handleRefOverride,
    });
  }
}

export default RefForward;
