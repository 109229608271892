import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import Container from 'components/Container/Container';
import Segment from 'components/Segment/Segment';
import Header from 'components/Typography/Header/Header';
import Image from 'components/Image/Image';
import Label from 'components/Label/Label';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import List from 'components/List/List';
import ListItem from 'components/List/ListItem';
import Icon from 'components/Icons/Icon';
import ImageUpload from 'components/ImageUpload';
import LabelDetail from 'components/Label/LabelDetail';
import Loader from 'components/Loaders/Loader';
import Checkbox from 'components/CheckBox/Checkbox';

import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';

import Transition from 'components/Transition/Transition';

import styles from './StoreInformation.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  float: 'right',
  textAlign: 'center',
  background: '#999',
  color: '#fff',
  marginTop: '-.15em',
  marginBottom: '-.15em',
  padding: '.3em .78571429em',
};

const links = {
  orders: 'seller/manage_orders',
  products: 'seller/manage_products',
  delivery: 'seller/delivery_location',
};

class StoreInformation extends Component {
  state = {
    imagePreview: null,
    picture: null,
    activeItem: '',
  };

  handleItemClick = (e, { name }) => {
    const { history, domainPath } = this.props;
    this.setState({ activeItem: name }, () => {
      history.push(`${domainPath}${links[name]}`);
    });
  }

  setNewStoreLogo = (picture, imagePreview) => {
    const { setNewStoreLogo } = this.props;
    this.setState({
      picture,
      imagePreview,
    }, () => {
      setNewStoreLogo(picture);
    });
  }

  handleItemClick = (e, { name }) => {
    const { history, domainPath } = this.props;
    this.setState({ activeItem: name }, () => {
      history.push(`${domainPath}${links[name]}`);
    });
  }

  onClick = (e, data) => {
    console.log(e, data);
  }

  render() {
    const {
      storeInformation,
      isFetchingStoreInformation,
      isEditStoreInformation,
      ordersProcessing,
      isFetchingListOrders,
      listWithDeliveryLocations,
      isFethchingListWithDeliveryLocation,
      productsInStock,
      renderPage,
    } = this.props;
    const { imagePreview, activeItem } = this.state;

    if (!storeInformation) {
      return <div />;
    }
    const listWithDeliveryLocationsLength = Object.keys(listWithDeliveryLocations);

    return (
      <Transition visible animation="fade" duration={300}>
        <Container style={{ marginTop: '1.5em' }} className={cx('custom-container')}>
          <Segment raised loading={isFetchingStoreInformation} style={isFetchingStoreInformation ? { height: '154px' } : null}>
            <Grid className={cx('custom-grid')} stackable>
              <GridRow>
                <GridColumn width={3} className={cx('custom-gridcolumn')}>
                  <Header as="h2" style={{ marginTop: '0em', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Image
                      size="small"
                      src={imagePreview || storeInformation.logo && storeInformation.logo.default}
                      className={cx('store-logo')}
                    />
                    {isEditStoreInformation
                      && (
                        <div className={isEditStoreInformation ? cx('edit-store-logo') : null} style={{ width: '150px', height: '94px', position: 'absolute', top: 0, left: 0, margin: '0.5em 0.5em' }}>
                          <ImageUpload imagePreview={this.setNewStoreLogo} />
                        </div>
                      )
                    }
                  </Header>
                </GridColumn>
                <GridColumn width={9} textAlign="center" verticalAlign="middle">
                  <span className={cx('header-title')}><a onClick={() => renderPage('store')}>{storeInformation.name}</a></span>
                </GridColumn>
                <GridColumn width={4}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Label
                      as="span"
                      color="teal"
                      style={{ margin: '0 .75em .75em 0', display: 'flex', justifyContent: 'space-between' }}
                      image
                    >
                      <Icon name="check" />
                      <div>Approved</div>
                      <LabelDetail>ON</LabelDetail>
                    </Label>
                    <Label
                      as="span"
                      color="orange"
                      style={{ margin: '0 .75em .75em 0', display: 'flex', justifyContent: 'space-between' }}
                      image
                    >
                      <Icon name="lightning" />
                      <div>Express</div>
                      <LabelDetail>ON</LabelDetail>
                    </Label>
                    <Label
                      // className={cx('ribbon-label-pay-custom')}
                      as="span"
                      color="blue"
                      style={{ margin: '0 .75em 0em 0', display: 'flex', justifyContent: 'space-between' }}
                      image
                    >
                      <Icon name="credit card" />
                      <div>Pay online</div>
                      <LabelDetail>ON</LabelDetail>
                    </Label>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Segment>
        </Container>
      </Transition>
    );
  }
}

export default StoreInformation;
