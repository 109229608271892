export const openMarket = {
  path:
    'M80,35.017C80,18.438,66.566,5,49.999,5C33.43,5,20,18.438,20,35.017c0,4.909,1.185,9.538,3.272,13.627h-0.009   l26.736,46.534l26.736-46.534h-0.009C78.813,44.555,80,39.926,80,35.017z M36.375,35.017c0-7.529,6.1-13.632,13.624-13.632   c7.523,0,13.625,6.103,13.625,13.632c0,7.453-5.974,13.504-13.393,13.627h-0.464C42.35,48.521,36.375,42.47,36.375,35.017z',
  fillOpacity: 1,
  fillColor: '#17a59c',
  strokeWeight: 0,
  scale: 0.5,
  anchor: window.google && new window.google.maps.Point(40, 92)
};

export const closedMarket = {
  path:
    'M80,35.017C80,18.438,66.566,5,49.999,5C33.43,5,20,18.438,20,35.017c0,4.909,1.185,9.538,3.272,13.627h-0.009   l26.736,46.534l26.736-46.534h-0.009C78.813,44.555,80,39.926,80,35.017z M36.375,35.017c0-7.529,6.1-13.632,13.624-13.632   c7.523,0,13.625,6.103,13.625,13.632c0,7.453-5.974,13.504-13.393,13.627h-0.464C42.35,48.521,36.375,42.47,36.375,35.017z',
  fillOpacity: 1,
  fillColor: '#888888',
  strokeWeight: 0,
  scale: 0.5,
  anchor: window.google && new window.google.maps.Point(40, 92)
};

export const cityMarker = {
  path:
    'M80,35.017C80,18.438,66.566,5,49.999,5C33.43,5,20,18.438,20,35.017c0,4.909,1.185,9.538,3.272,13.627h-0.009   l26.736,46.534l26.736-46.534h-0.009C78.813,44.555,80,39.926,80,35.017z M36.375,35.017c0-7.529,6.1-13.632,13.624-13.632   c7.523,0,13.625,6.103,13.625,13.632c0,7.453-5.974,13.504-13.393,13.627h-0.464C42.35,48.521,36.375,42.47,36.375,35.017z',
  fillOpacity: 0.9,
  fillColor: '#5595b0',
  strokeWeight: 0,
  scale: 0.5
};
