import React, { Component } from 'react';
import Plus from 'images/svg/plus';
import Minus from 'images/svg/minus';
import { convertToDecimal } from 'services';

import classnames from 'classnames/bind';
import styles from './Packing.module.scss';

const cx = classnames.bind(styles);

class Packing extends Component {
  state = {
    lastStep: this.props.itemProduct ? this.props.itemProduct.quantity : this.props.lastStep,
    stepsGramOz:
      this.props.filterGramOz.length > 0 &&
      this.props.filterGramOz.sort((a, b) => a.position - b.position).map(value => value.packing),
    stepsGram: this.props.packing === 'gram' && Object.keys(this.props.price).sort((a, b) => a - b),
    stepsOz:
      this.props.packing === 'oz' &&
      Object.keys(this.props.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a)),
    stepsPiece:
      this.props.packing === 'piece' && Object.keys(this.props.price).sort((a, b) => a - b),
    quantityPiece: this.props.itemProduct ? parseInt(this.props.itemProduct.quantity) : 1,
    disabledPlus: false,
    disabledMinus: false,
  };

  onPlus = (lastStep) => {
    const { packing, setProduct, price } = this.props;

    if (packing !== 'piece') {
      if (
        packing === 'gram_oz' &&
        this.state.stepsGramOz.indexOf(lastStep) < this.state.stepsGramOz.length - 1
      ) {
        const gramStep = this.state.stepsGramOz.indexOf(lastStep.toString());

        setProduct(this.state.stepsGramOz[gramStep + 1]);
        this.setState({
          lastStep: this.state.stepsGramOz[gramStep + 1],
          disabledMinus: false,
        });
      }
      if (
        packing === 'gram_oz' &&
        this.state.stepsGramOz.indexOf(lastStep) === this.state.stepsGramOz.length - 1
      ) {
        this.setState({
          disabledPlus: true,
        });
      }

      if (packing === 'piece_discrete') {
        setProduct(lastStep + 1);
        this.setState({
          quantityPiece: lastStep + 1,
          disabledMinus: false,
        });
      }

      if (packing === 'oz' && this.state.stepsOz.indexOf(lastStep) === 0) {
        this.setState({
          disabledPlus: true,
        });
      }

      if (packing === 'oz' && this.state.stepsOz.indexOf(lastStep) !== 0) {
        setProduct(this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) - 1]);
        this.setState({
          lastStep: this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) - 1],
          disabledMinus: false,
        });
      }

      if (
        packing === 'gram' &&
        this.state.stepsGram.indexOf(lastStep) < this.state.stepsGram.length - 1
      ) {
        const gramStep = this.state.stepsGram.indexOf(lastStep.toString());

        setProduct(this.state.stepsGram[gramStep + 1]);
        this.setState({
          lastStep: this.state.stepsGram[gramStep + 1],
          disabledMinus: false,
        });
      }

      if (
        packing === 'gram' &&
        this.state.stepsGram.indexOf(lastStep) === this.state.stepsGram.length - 1
      ) {
        this.setState({
          disabledPlus: true,
        });
      }
    } else {
      setProduct(this.state.quantityPiece + 1);
      this.setState({
        quantityPiece: this.state.quantityPiece + 1,
        disabledMinus: false,
        animate: true
      }, () => {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          this.setState({
            animate: false
          })
        }, 350)
      });
    }
  };

  onMinus = (lastStep) => {
    const { packing, setProduct, removeProduct } = this.props;

    if (packing !== 'piece') {
      if (packing === 'gram_oz' && this.state.stepsGramOz.indexOf(lastStep) === 0) {
        removeProduct();
      }
      if (packing === 'gram_oz' && this.state.stepsGramOz.indexOf(lastStep) !== 0) {
        setProduct(this.state.stepsGramOz[this.state.stepsGramOz.indexOf(lastStep) - 1]);
        this.setState({
          lastStep: this.state.stepsGramOz[this.state.stepsGramOz.indexOf(lastStep) - 1],
          disabledPlus: false,
        });
      }

      if (packing === 'piece_discrete' && lastStep !== 1) {
        setProduct(lastStep - 1);
        this.setState({
          quantityPiece: lastStep - 1,
        });
      }

      if (packing === 'piece_discrete' && lastStep === 1) {
        removeProduct();
      }

      if (
        packing === 'oz' &&
        this.state.stepsOz[this.state.stepsOz.indexOf(lastStep)] ===
        this.state.stepsOz[this.state.stepsOz.length - 1]
      ) {
        removeProduct();
      }
      if (
        packing === 'oz' &&
        this.state.stepsOz.indexOf(lastStep) < this.state.stepsOz.length - 1
      ) {
        setProduct(this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) + 1]);
        this.setState({
          lastStep: this.state.stepsOz[this.state.stepsOz.indexOf(lastStep) + 1],
          disabledPlus: false,
        });
      }

      if (packing === 'gram' && this.state.stepsGram.indexOf(lastStep) === 0) {
        removeProduct();
      }
      if (packing === 'gram' && this.state.stepsGram.indexOf(lastStep) !== 0) {
        setProduct(this.state.stepsGram[this.state.stepsGram.indexOf(lastStep) - 1]);
        this.setState({
          lastStep: this.state.stepsGram[this.state.stepsGram.indexOf(lastStep) - 1],
          disabledPlus: false,
        });
      }
    }

    if (packing === 'piece' && this.state.quantityPiece === 1) {
      removeProduct();
    }

    if (packing === 'piece' && this.state.quantityPiece > 1) {
      setProduct(this.state.quantityPiece - 1);
      this.setState({
        quantityPiece: this.state.quantityPiece - 1,
        disabledPlus: false,
      });
    }
  };

  render() {
    const { price, packing } = this.props;
    let currentPrice = null;
    let lastStep = this.state.lastStep;
    let disabledPlus = this.state.disabledPlus;
    let disabledMinus = this.state.disabledMinus;

    switch (packing) {
      case 'piece':
        lastStep = this.state.quantityPiece;
        currentPrice = price * this.state.quantityPiece;
        if (lastStep === 1) {
          disabledMinus = true;
        }
        break;
      case 'oz':
        if (lastStep === undefined) {
          lastStep = this.state.stepsOz[this.state.stepsOz.length - 1];
        }

        if (this.state.stepsOz.indexOf(lastStep) === 0) {
          disabledPlus = true;
        }

        if (this.state.stepsOz.indexOf(lastStep) === this.state.stepsOz.length - 1) {
          disabledMinus = true;
        }

        currentPrice = price[lastStep];
        break;
      case 'gram':
        if (lastStep === undefined) {
          const parseGrams = this.state.stepsGram.map(value => parseFloat(value));
          parseGrams.sort((a, b) => a - b);
          lastStep = parseGrams[0];
        }

        if (
          this.state.stepsGram.length === 1 ||
          this.state.stepsGram.indexOf(lastStep) === this.state.stepsGram.length - 1
        ) {
          disabledPlus = true;
        }

        if (this.state.stepsGram.indexOf(lastStep) <= 0) {
          disabledMinus = true;
        }

        currentPrice = price[lastStep];
        break;
      case 'piece_discrete':
        lastStep = this.state.quantityPiece;
        disabledPlus = false;
        if (lastStep >= price.length) {
          currentPrice = lastStep * price[price.length - 1];
        } else {
          currentPrice = lastStep * price[lastStep - 1];
        }

        if (lastStep === 1) {
          disabledMinus = true;
        }
        break;
      case 'gram_oz':
        if (lastStep === undefined) {
          lastStep = this.state.stepsGramOz[0];
        }
        if (this.state.stepsGramOz.indexOf(lastStep) === this.state.stepsGramOz.length - 1) {
          disabledPlus = true;
        }

        if (this.state.stepsGramOz.indexOf(lastStep) <= 0) {
          disabledMinus = true;
        }
        currentPrice = price[lastStep];
        break;
      default:
        break;
    }

    return (
      <div className={cx('description-cost')}>
        <div className={cx('description-value')}>
          <div>
            <button
              type="button"
              onClick={() => this.onMinus(lastStep)}
              className={disabledMinus ? cx('button-plus-disabled') : cx('button-minus')}
            >
              <div className={cx('container-button')}>
                <Minus type={disabledMinus} />
              </div>
            </button>
          </div>
          <div className={cx('value-oz')}>
            {packing === 'oz' || packing === 'gram'
              ? `${lastStep} ${packing.slice(0, 2)}`
              : lastStep}
          </div>
          <div>
            <button
              type="button"
              onClick={() => this.onPlus(lastStep)}
              className={disabledPlus ? cx('button-plus-disabled') : cx('button-plus', this.state.animate && 'animate')}
            >
              <div className={cx('container-button')}>
                <Plus />
              </div>
            </button>
          </div>
        </div>
        <div className={cx('cost')}>${currentPrice && Math.round(currentPrice) === 0 ? currentPrice.toFixed(2) : Math.round(currentPrice)}</div>
      </div>
    );
  }
}

export default Packing;
