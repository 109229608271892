import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './Spin.module.scss';

const cx = classnames.bind(styles);

const Spin = (props) => {
  const { size } = props;
  return <div className={cx('spin-loader', size)} />;
};

Spin.propTypes = {
  size: PropTypes.string.isRequired,
};

export default Spin;
