import React, { Component } from 'react';

import Divider from 'components/Divider';
import Container from 'components/Container/Container';
import Segment from 'components/Segment/Segment';
import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';
import Loader from 'components/Loaders/Loader';

import ProductInfo from 'components/ProductInfo';
import TotalPrice from 'routes/routes/UserOrders/components/UserOrders/components/TotalPrice';

import classnames from 'classnames/bind';
import styles from './OrderInformation.module.scss';

const cx = classnames.bind(styles);

const ORDER_STATUS = {
  processing: 'Received by seller',
  processed: 'In the works',
  received: 'Out for delivery',
};

const labelStyle = {
  display: 'block',
  margin: '0 0 .28571429rem 0',
  color: 'rgba(0,0,0,.87)',
  fontSize: '.92857143em',
  fontWeight: '700',
  textTransform: 'none',
}

class OrderInformation extends Component {
  componentWillMount() {
    const { fetchOrderInformation, match } = this.props;

    if (match.params && match.params.hash) {
      fetchOrderInformation(match.params.hash);
    }
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  render() {
    const { isFetchingOrderInformation, orderInformation } = this.props;

    if (isFetchingOrderInformation || !orderInformation) {
      return (
        <div className={cx('order-wrapper')}>
          <div className={cx('event-container')}>
            <Loader active inline center />
          </div>
        </div>
      );
    }

    return (
      <div className={cx('order-wrapper')}>
        <Container style={{ paddingTop: '1em', paddingBottom: '1em' }} text className={cx('order-container')}>
          <Segment raised>
            <Grid>
              <GridRow>
                <GridColumn width={16}>
                  <div className={cx('order-title')}>
                    <h1>Order Information</h1>
                  </div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>OrderID:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.id}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Order status:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{ORDER_STATUS[orderInformation.status]}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Delivery time:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.delivery_time}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Username:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.user_name}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Phone:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.phone}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Payment method:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.is_card_payment ? 'card' : 'cash'}</div>
                </GridColumn>
              </GridRow>
              <GridRow centered>
                <GridColumn width={8} textAlign="left">
                  <label style={labelStyle}>Delivery address:</label>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                  <div>{orderInformation.address}</div>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn widht={16}>
                  <Divider horizontal className={cx('products-divider')}>products information</Divider>
                </GridColumn>
              </GridRow>
              {orderInformation.order_products.map((product, key) => (
                <ProductInfo key={key} product={product} disabledPacking onClick={() => null} />
              ))}
              <GridRow>
                <GridColumn width={16}>
                  <Divider horizontal className={cx('products-divider')}>summary</Divider>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  <TotalPrice
                    sumPrice={orderInformation.sum_price || 0}
                    discountCost={orderInformation.discount_cost || 0}
                    taxCost={orderInformation.tax_cost || 0}
                    shippingCost={orderInformation.shipping_cost || 0}
                    itemsDeliveryCost={orderInformation.items_delivery_cost || 0}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default OrderInformation;
