import React, { Component } from 'react';
import classnames from 'classnames/bind';
import _ from 'lodash';

import {
  someByType,
  isNil,
  createShorthand,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import Icon from 'components/Icons/Icon';
import Image from 'components/Image/Image';
import Label from 'components/Label/Label';

import styles from './Dropdown.css';

const cx = classnames.bind(styles);

class DropdownItem extends Component {
  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) onClick(e, this.props);
  }

  onMouseEnter = (e) => {
    const { onMouseEnter } = this.props;

    if (onMouseEnter) onMouseEnter(e, this.props);
  }

  onMouseLeave = () => {
    // this.setState({
    //   openLabel: false,
    // })
  }

  render() {
    const {
      active,
      children,
      className,
      content,
      disabled,
      description,
      icon,
      image,
      label,
      selected,
      text,
    } = this.props;


    const classes = cx(
      useKeyOnly(active, 'active'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(selected, 'selected'),
      'item',
      className,
    );

    const iconName = _.isNil(icon)
      ? someByType(children, 'DropdownMenu') && 'dropdown'
      : icon;
    const rest = getUnhandledProps(DropdownItem, this.props)
    const ElementType = getElementType(DropdownItem, this.props)
    const ariaOptions = {
      role: 'option',
      'aria-disabled': disabled,
      'aria-checked': active,
      'aria-selected': selected,
    };

    if (!isNil(children)) {
      return (
        <ElementType {...rest} {...ariaOptions} className={classes} onClick={this.handleClick}>
          {children}
        </ElementType>
      );
    }

    const iconElement = Icon.create(iconName, { autoGenerateKey: false });
    const imageElement = Image.create(image, { autoGenerateKey: false });
    const labelElement = Label.create(label, { autoGenerateKey: false });
    const descriptionElement = createShorthand('div', val => ({ children: val }), description, {
      defaultProps: { className: cx('description') },
      autoGenerateKey: false,
    });
    const textElement = createShorthand(
      'div',
      val => ({ children: val }),
      isNil(content) ? text : content,
      { defaultProps: { className: cx('text') }, autoGenerateKey: false },
    );

    return (
      <ElementType {...rest} {...ariaOptions} className={classes} onClick={this.handleClick} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {imageElement}
        {iconElement}
        {labelElement}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {textElement}
          {descriptionElement}
        </div>
      </ElementType>
    );
  }
}

DropdownItem.create = createShorthandFactory(DropdownItem, opts => opts);

export default DropdownItem;
