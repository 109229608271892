import React from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useTextAlignProp,
  useWidthProp,
} from 'utils/lib';
import styles from './Card.css';
import Card from './Card';

const cx = classnames.bind(styles);

const CardGroup = (props) => {
  const {
    centered,
    children,
    className,
    content,
    doubling,
    items,
    itemsPerRow,
    stackable,
    textAlign,
  } = props;

  const classes = cx(
    'ui',
    useKeyOnly(centered, 'centered'),
    useKeyOnly(doubling, 'doubling'),
    useKeyOnly(stackable, 'stackable'),
    useTextAlignProp(textAlign),
    useWidthProp(itemsPerRow),
    'cards',
    className
  );

  const rest = getUnhandledProps(CardGroup, props);
  const ElementType = getElementType(CardGroup, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }
  if (!isNil(content)) {
    return (
      <ElementType {...rest} className={classes}>
        {content}
      </ElementType>
    );
  }

  const itemsJSX = _.map(items, (item) => {
    const key = item.key || [item.header, item.description].join('-');
    return <Card key={key} {...item} />;
  });

  return (
    <ElementType {...rest} className={classes}>
      {itemsJSX}
    </ElementType>
  );
};

export default CardGroup;
