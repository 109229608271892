import React from 'react';

import Label from 'components/Label/Label';

import classnames from 'classnames/bind';
import styles from './PlantType.module.scss';

const cx = classnames.bind(styles);

const plantTypes = {
  Sativa: { color: '#FDD835', name: 'Sativa' },
  Indica: { color: '#5094B1', name: 'Indica' },
  Hybrid: { color: '#39D4CF', name: 'Hybrid' },
  CBD: { color: '#edb3bf', name: 'CBD' },
  'Hybrid: Sativa-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #FDD835 85%)',
    name: 'Hybrid: Sativa',
  },
  'Hybrid: Indica-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #5094B1 85%)',
    name: 'Hybrid: Indica',
  },
  'Indica: Sativa-dominant': {
    color: 'linear-gradient(90deg, #5094B1 10%, #FDD835 85%)',
    name: 'Indica: Sativa',
  },
};

const PlantType = (props) => {
  const { type, style, devicePrefix } = props;

  if (!plantTypes[type] || type === '') {
    return <div style={{ width: '0px' }} />;
  }

  return (
    <Label
      size="large"
      style={{ color: 'white', background: plantTypes[type].color, ...style }}
      className={cx('plant-type', `plant-type--${devicePrefix}`)}
    >
      {plantTypes[type].name}
    </Label>
  );
};

export default PlantType;
