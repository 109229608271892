import React from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';

import classnames from 'classnames/bind';
import styles from './ModalRecommendation.module.scss';

const cx = classnames.bind(styles);

export default (props) => {
  const { createOrderWithRecommendation, goToHeally } = props;

  return (
    <div className={cx('message-container')}>
      <div className={cx('message')}>Please confirm that you have a current recommendation</div>
      <div className={cx('btn-container')}>
        <Button
          type="button"
          color="teal"
          onClick={createOrderWithRecommendation}
        >
          Yes
              </Button>
        <Button type="button" color="teal" onClick={goToHeally}>NO, GET ONE NOW</Button>
      </div>
    </div>
  );
};
