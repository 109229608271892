import React, { Component } from 'react';
import Button from 'components/Button';

import Spinner from 'components/Loaders/Spin';

import classnames from 'classnames/bind';
import styles from './ModalContent.module.scss';

const cx = classnames.bind(styles);

const pickupMessage =
  'Sorry, delivery from this store is not more possible. Do you want to pickup the order by address: ';
const deliveryMessage =
  "Sorry, store's dispensary is not more available. Do you want store to deliver this order to your address?";

class ModalContent extends Component {
  componentDidMount() {
    const { orderInfo, fetchAddressDelivery, fetchStoreInfo } = this.props;
    if (orderInfo && orderInfo.storeType === 'pickup') {
      fetchStoreInfo(orderInfo.storeId);
    }
  }
  render() {
    const { orderInfo, onContinue, onCancel, storeInfo, isFetchingStoreInfo } = this.props;
    let address = '';
    if (isFetchingStoreInfo) {
      return (
        <div>
          <Spinner size="small" />
        </div>
      );
    }

    if (storeInfo) {
      address = storeInfo.address;
    }

    return (
      <div className={cx('modal-container')}>
        <div className={cx('modal-description')}>
          {orderInfo && orderInfo.storeType === 'pickup'
            ? pickupMessage + address
            : deliveryMessage}
        </div>
        <div className={cx('group-button')}>
          <Button name="Continue" type="yellow" onClick={() => onContinue(orderInfo)} />
          <Button name="Cancel" type="yellow" onClick={onCancel} />
        </div>
      </div>
    );
  }
}

export default ModalContent;
