/* eslint-disable no-undef */
import * as constants from './constants';
import { request } from 'services/api';
import { openMarket, closedMarket } from 'images/svg';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';

const generateQuery = (results, lat, lng) => {
  let query = '';
  let trigger = false;
  results.address_components.forEach((value) => {
    if (value.types[0] === 'locality' || value.types[0] === 'postal_code') {
      trigger = true;
    }
  });

  if (trigger) {
    query = `?lat=${lat}&lon=${lng}`;
  } else {
    results.address_components.forEach((value) => {
      if (value.types[0] === 'administrative_area_level_1') {
        query = `?state=${value.short_name}`;
      }
    });
  }

  if (query === '') {
    query = `?lat=${lat}&lon=${lng}`;
  }

  return query;
};

const geocodeLatLng = (position) => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK') {
        const geocodeData = results.find(value => value.types[0] === 'street_address');

        resolve(geocodeData);
      } else {
        reject(null);
      }
    });
  });
};

export const fetchListMarkers = location => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_MARKERS],
  });
  geocodeByAddress(location).then((results) => {
    getLatLng(results[0])
      .then(({ lat, lng }) => {
        const query = generateQuery(results[0], lat, lng);
        return request(`/api/v1/stores/pickup_search${query}`)
          .then(response => response.json())
          .then((json) => {
            const markers = json.data.sort((a, b) => b.id - a.id).map((entity) => {
              let marker = openMarket;
              if (!entity.works) {
                marker = closedMarket;
              }
              return {
                position: new google.maps.LatLng(entity.latitude, entity.longitude),
                ...entity,
                showInfo: false,
                center: false,
                icon: marker,
              };
            });

            dispatch({
              type: [constants.FETCH_LIST_MARKERS_SUCCESS],
              markers,
              center: new google.maps.LatLng(lat, lng),
            });
          })
          .catch((err) => {
            dispatch({
              type: [constants.FETCH_LIST_MARKERS_FAIL],
              payload: true,
            });
            throw err;
          });
      })
      .catch((err) => {
        dispatch({
          type: [constants.FETCH_LIST_MARKERS_FAIL],
          payload: true,
        });
        throw err;
      });
  });
};

export const handleMarkerClick = (targetMarker, listMarkers) => {
  const markers = listMarkers.map((marker) => {
    if (marker === targetMarker) {
      return {
        ...marker,
        showInfo: true,
      };
    }
    return {
      ...marker,
      showInfo: false,
    };
  });

  return {
    type: [constants.TOGGLE_MARKER_INFO],
    markers,
  };
};

export const handleMarkerClose = (listMarkers) => {
  const markers = listMarkers.map(marker => ({
    ...marker,
    showInfo: false,
  }));

  return {
    type: [constants.TOGGLE_MARKER_INFO],
    markers,
  };
};

export const fetchNewMarkers = (boundingBox, lat, lon) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_NEW_LIST_MARKERS],
  });
  return request(`/api/v1/stores/pickup_search?bounding_box=${boundingBox}&lat=${lat}&lon=${lon}`)
    .then(response => response.json())
    .then((json) => {
      const markers = json.data.sort((a, b) => b.id - a.id).map((entity) => {
        let marker = openMarket;
        if (!entity.works) marker = closedMarket;
        return {
          position: new google.maps.LatLng(entity.latitude, entity.longitude),
          ...entity,
          showInfo: false,
          center: false,
          icon: marker,
        };
      });
      dispatch({
        type: [constants.FETCH_NEW_LIST_MARKERS_SUCCESS],
        markers,
      });
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_NEW_LIST_MARKERS_FAIL],
        payload: true,
      });
      throw err;
    });
};

export const setInfoBoxPosition = (infoBoxPosition, viewInfoBox) => (dispatch) => {
  dispatch({
    type: [constants.SET_INFOBOX_POSITION],
    infoBoxPosition,
    viewInfoBox,
  });
};

export const filterStoresPickUpSupportCreditCard = trigger => (dispatch) => {
  dispatch({
    type: [constants.FILTER_STORES_SUPPORT_CARD_PICK_UP],
    trigger,
  });
};

export const getCurrentGeolocation = (enableHighAccuracy, timeout, maximumAge) => (dispatch) => {
  if (!('permissions' in window.navigator)) return;
  window.navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
    if (permission.state === 'denied') {
      dispatch({
        type: [constants.DENIED_GEOLOCATION],
        isFetchingCurrentPosition: false,
      });
    } else if (permission.state === 'prompt') {
      dispatch({
        type: [constants.PROMPT_GEOLOCATION],
        isFetchingCurrentPosition: false,
      });
    } else {
      dispatch({
        type: [constants.GET_CURRENT_GEOLOCATION],
        isFetchingCurrentPosition: true,
      });
    }
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        return geocodeLatLng(latLng)
          .then((result) => {
            dispatch({
              type: [constants.GET_CURRENT_GEOLOCATION_SUCCESS],
              geocodeData: result,
            });
          })
          .catch((err) => {
            dispatch({
              type: [constants.GET_CURRENT_GEOLOCATION_FAIL],
            });
          });
      },
      (err) => {
        dispatch({ type: [constants.GET_CURRENT_GEOLOCATION_FAIL] });
      },
      {
        enableHighAccuracy,
        timeout,
        maximumAge,
      }
    );
  });
};

export const setGeocodeByAddress = geocodeData => (dispatch) => {
  dispatch({
    type: [constants.SET_GEOCODE_BY_ADDRESS],
    geocodeData,
  });
};

export const setAddress = address => (dispatch) => {
  dispatch({
    type: [constants.SET_ADDRESS],
    address,
  });
};

export const clearFieldAddress = address => (dispatch) => {
  dispatch({
    type: [constants.CLEAR_FIELD_ADDRESS],
  });
};
