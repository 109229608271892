import React, { Component, Fragment } from 'react';
import classnames from 'classnames/bind';

import CommentGroup from 'components/Comment/CommentGroup';
import Comment from 'components/Comment/Comment';
import CommentAvatar from 'components/Comment/CommentAvatar';
import CommentAuthor from 'components/Comment/CommentAuthor';
import CommentMetadata from 'components/Comment/CommentMetadata';
import CommentText from 'components/Comment/CommentText';
import CommentActions from 'components/Comment/CommentActions';
import CommentAction from 'components/Comment/CommentAction';
import CommentContent from 'components/Comment/CommentContent';

import Placeholder from 'components/Placeholder/Placeholder';
import PlaceholderHeader from 'components/Placeholder/PlaceholderHeader';
import PlaceholderLine from 'components/Placeholder/PlaceholderLine';


import Rating from 'components/Rating';
import Form from 'components/Form/Form';
import TextArea from 'components/TextArea/TextArea';
import Header from 'components/Typography/Header/Header';
import Button from 'components/Button/Button';

import styles from './Comments.scss';

const cx = classnames.bind(styles);

const getFormattedDate = (date, separate) => {
  const year = date.getFullYear().toString().substr(-2);

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  const hour = date.getUTCHours().toString();

  let minutes = date.getUTCMinutes().toString();
  minutes = minutes.length > 1 ? minutes : `0${minutes}`;

  let seconds = date.getUTCSeconds().toString();
  seconds = seconds.length > 1 ? seconds : `0${seconds}`;

  return `${month}${separate}${day}${separate}${year} ${hour}:${minutes}:${seconds}`;
};

const smiles = {
  5: require('images/smiles/happy_64px.png'),
  4: require('images/smiles/smile_64px.png'),
  3: require('images/smiles/depression_64px.png'),
  2: require('images/smiles/crying_64px.png'),
  1: require('images/smiles/confused_64px.png'),
};

class Comments extends Component {
  state = {}

  componentDidMount() {
    const { fetchProductComments } = this.props;

    fetchProductComments();
  }

  render() {
    const { productComments, commentId, editComment, newComment, storeLogo, isEditRating, setNewRating } = this.props;

    return (
      productComments.data && productComments.data.length > 0
        ? (
          <CommentGroup>
            <Header as="h3" dividing>
              Comments
            </Header>
            {/* <div>
          <Rating style={{ fontSize: '2rem' }} icon="star" defaultRating={0} maxRating={5} color="teal" />
        </div> */}
            {
              productComments.isFetchingProductComments
                ? (
                  <Placeholder>
                    <PlaceholderHeader image>
                      <PlaceholderLine />
                      <PlaceholderLine />
                    </PlaceholderHeader>
                    <PlaceholderHeader image>
                      <PlaceholderLine />
                      <PlaceholderLine />
                    </PlaceholderHeader>
                    <PlaceholderHeader image>
                      <PlaceholderLine />
                      <PlaceholderLine />
                    </PlaceholderHeader>
                  </Placeholder>
                )
                : productComments.data.map(item => (
                  <Comment key={item.id}>
                    <CommentAvatar src={smiles[item.rate]} />
                    <CommentContent>
                      {!item.user_name || item.user_name === ''
                        ? <CommentAuthor style={{ color: '#616161' }}>heally client</CommentAuthor>
                        : <CommentAuthor>{item.user_name}</CommentAuthor>
                      }
                      <CommentMetadata style={{ marginLeft: '0em' }}>
                        <div style={{ marginTop: '0.25em' }}>
                          <Rating
                            styles={{ fontSize: '0.62857143rem' }}
                            disabled={!isEditRating}
                            icon="star"
                            defaultRating={item.rate}
                            maxRating={5}
                            color="orange"
                            size="small"
                            onRate={isEditRating ? (e, data) => setNewRating(e, data) : null}
                            className={cx('custom-rating')}
                          />
                        </div>
                        <div>{getFormattedDate(new Date(item.time), '/')}</div>
                      </CommentMetadata>
                      <CommentText>{item.comment}</CommentText>
                      {
                        (item.id === commentId || newComment.id === item.id)
                        && (
                          <CommentActions>
                            <CommentAction onClick={() => editComment(item)}>Edit</CommentAction>
                          </CommentActions>
                        )
                      }
                    </CommentContent>
                    {
                      item.reply
                      && (
                        <CommentGroup style={{ margin: '-1.5em 0 -1em 1.25em', padding: '2em 0 2em 2.25em' }}>
                          <Comment>
                            <CommentAvatar src={storeLogo} />
                            <CommentContent>
                              <CommentAuthor>@shop manager</CommentAuthor>
                              <CommentText>{item.reply}</CommentText>
                            </CommentContent>
                          </Comment>
                        </CommentGroup>
                      )
                    }
                  </Comment>
                ))
            }
          </CommentGroup>
        )
        : null
    );
  }
}

export default Comments;
