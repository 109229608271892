import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Header from 'components/Typography/Header/Header';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import List from 'components/List/List';
import ListItem from 'components/List/ListItem';
import ListContent from 'components/List/ListContent';
import ListHeader from 'components/List/ListHeader';
import ListDescription from 'components/List/ListDescription';
import ListIcon from 'components/List/ListIcon';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Label from 'components/Label/Label';

import { getDay } from 'services';
import convertTime from 'services/datetime';

import styles from './TimeZone.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '25%',
  paddingLeft: '1em',
  paddingRight: '1em',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

const timeZoneData = [
  {
    key: 0,
    text: 'Hawaii',
    value: 'Hawaii',
  },
  {
    key: 1,
    text: 'Alaska',
    value: 'Alaska',
  },
  {
    key: 2,
    text: 'Pacific Time (US & Canada)',
    value: 'Pacific Time (US & Canada)',
  },
  {
    key: 3,
    text: 'Mountain Time (US & Canada)',
    value: 'Mountain Time (US & Canada)',
  },
  {
    key: 4,
    text: 'Central Time (US & Canada)',
    value: 'Central Time (US & Canada)',
  },
  {
    key: 5,
    text: 'Eastern Time (US & Cananda)',
    value: 'Eastern Time (US & Cananda)',
  },
];

class Timezone extends Component {
  state = {}

  onChange = (e, data) => {
    const { onChange } = this.props;

    onChange(e, data);
  }

  render() {
    const { disabled, storeInformation, openModalWithWorkingTime } = this.props;

    return (
      <div style={{ marginTop: '2em' }}>
        <Header as="h4" block>
          Timezone section
        </Header>
        <div style={{ display: 'flex' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Timezone:</label>
            <div className={cx('input-container')}>
              <Dropdown
                fluid
                options={timeZoneData}
                selection
                disabled={disabled}
                defaultValue={storeInformation.time_zone}
                onChange={this.onChange}
                name="time_zone"
                style={{ opacity: 1 }}
              />
            </div>
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Working time:</label>
            <div style={{ paddingLeft: '1em', paddingRight: '1em', width: '25%' }}>
              <List relaxed size="small">
                {storeInformation && storeInformation.work_time
                  ? storeInformation.work_time.map((v, key) => (
                    v
                    && (
                      <ListItem style={{ display: 'flex' }}>
                        <ListIcon name="calendar" size="large" verticalAlign="middle" />
                        <ListContent>
                          <ListHeader style={{ marginBottom: '5px' }}>
                            {getDay(key)}
                          </ListHeader>
                          <ListDescription>
                            {storeInformation.work_time[key]
                              && storeInformation.work_time[key][0]
                              && convertTime(storeInformation.work_time[key][0]) || <Label color="red">not set</Label>}
                            {' '}
                            -
                            {' '}
                            {storeInformation.work_time[key]
                              && storeInformation.work_time[key][1]
                              && convertTime(storeInformation.work_time[key][1]) || <Label color="red">not set</Label>}
                          </ListDescription>
                        </ListContent>
                      </ListItem>
                    )
                  )) : null
                }
              </List>
            </div>
            {!disabled && <Button size="mini" type="button" secondary onClick={openModalWithWorkingTime}>Edit</Button>}
          </FormField>
        </div>
      </div>
    );
  }
}

export default Timezone;
