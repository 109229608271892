import React from 'react';
import Icon from 'components/Icons/Icon';

import classnames from 'classnames/bind';
import styles from './PrevArrow.module.scss';

const cx = classnames.bind(styles);

export default props => (
  <button
    style={props.disabled ? { display: 'none' } : { display: 'block' }}
    id={`${props.id}_btn-prev`}
    className={cx(props.customClassName)}
    onClick={() => props.toScroll(-1)}
  >
    <Icon name="angle left" />
  </button>
);
