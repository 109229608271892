import { request } from 'services/api';
import notification from 'components/Notification';
import * as constants from './constants';
import * as constantsRemedies from '../remedies/constants';
import { fetchRemedies } from '../remedies/actions';

export const fetchListProducts = (
  storeId,
  remedyId,
  brandInformation,
  groupShortName
) => (dispatch) => {
  dispatch({
    type: [constants.FETCH_LIST_PRODUCTS],
  });
  dispatch({
    type: [constantsRemedies.FETCH_REMEDIES],
  });
  let queryString = `/api/v1/stores/${storeId}/categories`;

  if (brandInformation && brandInformation.brand) {
    queryString += `?brand_short_name=${brandInformation.brand.short_name}`;
  }

  if (groupShortName && brandInformation && brandInformation.brand) {
    queryString += `&group_short_name=${groupShortName}`;
  } else if (groupShortName) {
    queryString += `?group_short_name=${groupShortName}`;
  }

  return request(queryString)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        const categoriesName = json.data.map(v => v.name);
        const checkoutProducts = [];
        json.data.forEach((v) => {
          v.products.forEach((product) => {
            if (product.in_cart) checkoutProducts.push(product);
          });
        });
        dispatch({
          type: [constants.FETCH_LIST_PRODUCTS_SUCCESS],
          categories: json.data,
          categoriesName,
          checkoutProducts,
        });
        dispatch(fetchRemedies(json.data, remedyId));

        if (window.fbq) {
          window.fbq('track', 'ViewContent');
        }
      } else {
        notification.error({
          message: 'Error',
          description: 'Sorry, something wrong',
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_LIST_PRODUCTS_FAIL],
        payload: true,
      });
      notification.error({
        message: 'Error',
        description: 'Sorry, something wrong',
      });
      throw err;
    });
};

export const fetchProductInformation = productId => (dispatch) => {
  dispatch({
    type: [constants.FETCH_PRODUCT_INFORMATION],
  });
  return request(`/api/v1/products/${productId}`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.FETCH_PRODUCT_INFORMATION_SUCCESS],
          payload: json.data,
        });
      } else if (json.error_code === 'record_not_found') {
        dispatch({
          type: [constants.FETCH_PRODUCT_INFORMATION_FAIL],
          error_message: 'record_not_found',
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_PRODUCT_INFORMATION_FAIL],
        error_message: 'error',
        payload: true,
      });
    });
};

export const onBuyProduct = (categoryId, productId) => (dispatch) => {
  dispatch({
    type: [constants.BUY_PRODUCT],
    productId,
    categoryId,
  });
};

export const onBuyProductClose = (categoryId, productId) => (dispatch) => {
  dispatch({
    type: [constants.BUY_PRODUCT_CLOSE],
    categoryId,
    productId,
  });
};

const checkRemedies = (ids, remediesActive) => {
  const trigger = [];
  remediesActive.forEach((v) => {
    if (ids.indexOf(v.id) >= 0) {
      trigger.push(true);
    }
  });
  return trigger;
};

export const filterProducts = (remedies, categories) => {
  const remediesActive = remedies.filter(v => v.active);
  const newCategories = [...categories];

  newCategories.forEach((category) => {
    category.products.forEach((product) => {
      if (remediesActive.length > 0) {
        const value = checkRemedies(product.remedy_ids, remediesActive);
        if (value.length > 0) {
          product.active = true;
        } else {
          product.active = false;
        }
      } else {
        product.active = false;
      }
    });
  });
  const categoriesName = newCategories.map(v => v.name);
  return (dispatch) => {
    dispatch({
      type: [constants.FILTER_PRODUCTS],
      categoriesName,
      categories: newCategories,
    });
  };
};

export const sendErrorWithStoreId = () => (dispatch) => {
  dispatch({
    type: [constants.SEND_ERROR_WITH_STORE_ID],
  });
};

export const fetchProductComments = id => async (dispatch) => {
  dispatch({
    type: [constants.FETCH_PRODUCT_COMMENTS],
  });

  return request(`/api/v1/products/${id}/product_comments`)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.FETCH_PRODUCT_COMMENTS_SUCCESS],
          payload: json.data,
        });
      } else {
        dispatch({
          type: [constants.FETCH_PRODUCT_COMMENTS_FAIL],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.FETCH_PRODUCT_COMMENTS_FAIL],
      });
    });
};

export const resetProductInformation = () => (dispatch) => {
  dispatch({
    type: [constants.RESET_PRODUCT_INFORMATION],
  })
}

export const addComment = (productId, data, triggerForFetchProductComments) => async (dispatch) => {
  dispatch({
    type: [constants.ADD_COMMENT],
  })

  return request(`/api/v1/products/${productId}/product_comments`, 'POST', data)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.ADD_COMMENT_SUCCESS],
          payload: json.data,
        })
        if (triggerForFetchProductComments) {
          dispatch(fetchProductComments(productId));
        }
      } else {
        dispatch({
          type: [constants.ADD_COMMENT_FAIL],
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: [constants.ADD_COMMENT_FAIL],
      })
    })
}

export const updateComment = (productId, data) => async (dispatch) => {
  dispatch({
    type: [constants.UPDATE_COMMENT],
  })

  return request(`/api/v1/product_comments/${productId}`, 'PATCH', data)
    .then(response => response.json())
    .then((json) => {
      if (json.status === 'SUCCESS') {
        dispatch({
          type: [constants.UPDATE_COMMENT_SUCCESS],
          payload: json.data
        })
      } else {
        dispatch({
          type: [constants.UPDATE_COMMENT_FAIL],
        })
      }
    })
    .catch(err => {
      dispatch({
        type: [constants.UPDATE_COMMENT_FAIL]
      })
    })
}

export const setRatingForProductCard = (data) => async (dispatch) => {
  dispatch({
    type: [constants.SET_RATING_FOR_PRODUCT_CARD],
    payload: data,
  })
}
