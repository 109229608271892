import React from 'react';
import classnames from 'classnames/bind';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';
import styles from './Modal.css';

const cx = classnames.bind(styles);

const ModalContent = (props) => {
  const { children, className, content, image, scrolling } = props;

  const classes = cx(
    className,
    useKeyOnly(image, 'image'),
    useKeyOnly(scrolling, 'scrolling'),
    'content'
  );
  const rest = getUnhandledProps(ModalContent, props);
  const ElementType = getElementType(ModalContent, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ModalContent.create = createShorthandFactory(ModalContent, content => ({ content }));

export default ModalContent;
