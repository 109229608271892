import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import {
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useMultipleProp,
  useVerticalAlignProp,
  useWidthProp,
  useTextAlignProp,
} from 'utils/lib';
import styles from './Grid.css';

const cx = classnames.bind(styles);

const GridRow = (props) => {
  const {
    centered,
    children,
    className,
    color,
    columns,
    divided,
    only,
    reversed,
    stretched,
    textAlign,
    verticalAlign,
  } = props;

  const classes = cx(
    color,
    useKeyOnly(centered, 'centered'),
    useKeyOnly(divided, 'divided'),
    useKeyOnly(stretched, 'stretched'),
    useMultipleProp(only, 'only'),
    useMultipleProp(reversed, 'reversed'),
    useTextAlignProp(textAlign),
    useVerticalAlignProp(verticalAlign),
    useWidthProp(columns, 'column', true),
    'row',
    className
  );
  const rest = getUnhandledProps(GridRow, props);
  const ElementType = getElementType(GridRow, props);

  return (
    <ElementType {...rest} className={classes}>
      {children}
    </ElementType>
  );
};

export default GridRow;
