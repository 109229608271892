import React, { Component, createRef } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import classnames from 'classnames/bind';

import debounce from 'services/debounce';

import Container from 'components/Container/Container';
import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';
import Segment from 'components/Segment/Segment';
import Label from 'components/Label/Label';
import Header from 'components/Typography/Header/Header';
import Image from 'components/Image/Image';
import Accordion from 'components/Accordion/Accordion';
import AccordionTitle from 'components/Accordion/AccordionTitle';
import AccordionContent from 'components/Accordion/AccordionContent';

import Ref from 'components/Ref/Ref';
import Sticky from 'components/Sticky';

import Icon from 'components/Icons/Icon';
import SortableCategoryItem from './components/SortableCategoryItem';
import ProductEditInfo from './components/ModalBody/ProductEditInfo';
import PanelTools from './components/PanelTools';
import AddProduct from './components/ModalBody/AddProduct';


import styles from './ManageProducts.module.scss';

const cx = classnames.bind(styles);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DragHandleCategory = SortableHandle(() => <Icon name="align justify" size="large" style={{ cursor: 'grab' }} />);

const getItemStyle = (isDragging, draggableStyle) => ({
  display: isDragging && 'none',
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'none',
});

const CategoryItem = SortableElement(props => (
  <GridColumn width={16}>
    <Segment raised style={{ minHeight: '67px' }}>
      <AccordionTitle style={{ marginRight: '1rem', display: 'inline-block' }} active={props.activeIndex.indexOf(props.value.name) >= 0} index={props.index} onClick={(e, titleProps) => props.handleClick(e, titleProps, props.value.name)}>
        <div style={{ cursor: 'pointer' }}>
          <Icon name={props.activeIndex.indexOf(props.value.name) >= 0 ? 'caret down' : 'caret right'} />
          <Header as="h3" style={{ display: 'inline' }}>{props.value.name}</Header>
        </div>
      </AccordionTitle>
      <div style={{ display: 'inline-block', float: 'right', padding: '0.5em 0' }}>
        <DragHandleCategory />
      </div>
      {/* <Divider /> */}
      <AccordionContent
        key={props.index}
        active={props.activeIndex.indexOf(props.value.name) >= 0}
        className={cx('fade-in', props.disableAccordionContent ? 'disable-content' : null)}
      >
        <SortableCategoryItem
          items={props.value.products}
          openModalWithEditProduct={props.openModalWithEditProduct}
          sortProducts={props.sortProducts}
          selectAllProducts={props.selectAllProducts}
          triggerOutOfStock={props.triggerOutOfStock}
          deselectAllProducts={props.deselectAllProducts}
          animateSelectAllProducts={props.animateSelectAllProducts}
          updateGlobalStateWithProducts={props.updateGlobalStateWithProducts}
          categoryName={props.value.name}
          arrWithProductsForDelete={props.arrWithProductsForDelete}
        />
      </AccordionContent>
    </Segment>
  </GridColumn>
));

const SortableCategory = SortableContainer(
  ({
    className,
    categories,
    items,
    itemClass,
    activeIndex,
    handleClick,
    shouldUseDragHandle,
    disableAccordionContent,
    openModalWithEditProduct,
    sortProducts,
    selectAllProducts,
    triggerOutOfStock,
    deselectAllProducts,
    animateSelectAllProducts,
    updateGlobalStateWithProducts,
    arrWithProductsForDelete,
  }) => {
    const listWithCategories = Object.keys(categories);
    return (
      <Accordion fluid>
        <Grid>
          {
            listWithCategories.map((v, index) => (
              <CategoryItem
                sortIndex={index}
                key={`category-${index}`}
                index={index}
                value={categories[v]}
                items={items}
                activeIndex={activeIndex}
                handleClick={handleClick}
                disableAccordionContent={disableAccordionContent}
                openModalWithEditProduct={openModalWithEditProduct}
                sortProducts={sortProducts}
                selectAllProducts={selectAllProducts}
                triggerOutOfStock={triggerOutOfStock}
                deselectAllProducts={deselectAllProducts}
                animateSelectAllProducts={animateSelectAllProducts}
                updateGlobalStateWithProducts={updateGlobalStateWithProducts}
                arrWithProductsForDelete={arrWithProductsForDelete}
              />
            ))
          }
        </Grid>
      </Accordion>
    );
  }
);


class ManageProducts extends Component {
  state = {
    activeIndex: [],
    panels: [],
    animation: true,
    categories: ['Edibles', 'Flowers', 'Accessories', 'Topicals'],
    disableAccordionContent: false,
    openModal: false,
    animation: false,
    productInfo: null,
    selectAllProducts: false,
    triggerOutOfStock: false,
    deselectAllProducts: false,
    animateSelectAllProducts: false,
    productIds: {},
    activeSticky: false,
  };

  contextRef = createRef();

  componentDidMount() {
    const { fetchStoreProducts, listSortOfCategoriesWithProducts } = this.props;
    if (!listSortOfCategoriesWithProducts) {
      fetchStoreProducts();
    }

    document.body.className = cx('body-wrapper');
    const footer = document.getElementById('seller_footer');
    footer.style.display = 'none';
  }

  componentWillUnmount() {
    document.body.className = document.body.className.replace(cx('body-wrapper'), '');
    const footer = document.getElementById('seller_footer');
    footer.style.display = 'block';
  }

  handleClick = (e, titleProps, name) => {
    const { activeCategoryItems, setActiveCategoryItems } = this.props;
    const { active } = titleProps;

    if (activeCategoryItems.indexOf(name) === -1 && !active) {
      activeCategoryItems.push(name);
    } else {
      activeCategoryItems.splice(activeCategoryItems.indexOf(name), 1);
    }

    this.setState({ animation: true }, () => setActiveCategoryItems(activeCategoryItems));
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { listSortOfCategoriesWithProducts, sortCategories } = this.props;
    const newCategories = arrayMove(listSortOfCategoriesWithProducts, oldIndex, newIndex);

    const categoryIds = newCategories.map(v => v.id);

    this.setState({
      categories: newCategories,
      disableAccordionContent: false,
    }, () => {
      sortCategories(newCategories, categoryIds);
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const panels = reorder(
      this.state.panels,
      result.source.index,
      result.destination.index
    );

    this.setState({
      panels,
    });
  }

  onSortStart = () => {
    this.setState({
      disableAccordionContent: true,
    });
  }

  openModalWithEditProduct = (info) => {
    this.setState({
      openModal: true,
      animation: true,
      productInfo: info,
    });
  }

  closeModal = () => {
    this.setState({
      animation: false,
    }, () => {
      clearTimeout(this.startTimeout);
      this.startTimeout = setTimeout(() => {
        this.setState({
          openModal: false,
          productInfo: null,
        });
      }, 300);
    });
  }

  addProduct = () => {
    this.setState({
      openModalWithAddProduct: true,
    });
  }

  closeModalModalWithAddProduct = () => {
    this.setState({
      openModalWithAddProduct: false,
    });
  }

  selectAll = () => {
    const { listSortOfCategoriesWithProducts } = this.props;
    const newProductIds = {};

    const productIds = listSortOfCategoriesWithProducts.map(v => ({
      [v.name]: {
        ids: v.products.map(el => el.id),
      },
    }));

    productIds.forEach((el) => {
      Object.keys(el).forEach((k) => {
        newProductIds[k] = {
          ids: el[k].ids,
        };
      });
    });


    this.setState({
      selectAllProducts: !this.state.selectAllProducts,
      animateSelectAllProducts: !this.state.animateSelectAllProducts,
      productIds: newProductIds,
    });
  }

  deselectAll = () => {
    this.setState({
      deselectAllProducts: !this.state.deselectAllProducts,
      animateSelectAllProducts: false,
      productIds: {},
    });
  }

  selectOutOfStockProducts = () => {
    const { productIds } = this.state;
    const { massUpdateProducts, listSortOfCategoriesWithProducts } = this.props;
    this.setState({
      triggerOutOfStock: !this.state.triggerOutOfStock,
    }, () => {
      const objWithIds = {};

      Object.keys(productIds).forEach((v) => {
        productIds[v].ids.forEach((el) => {
          listSortOfCategoriesWithProducts.forEach((cat) => {
            cat.products.forEach((prod) => {
              if (prod.id === el) {
                objWithIds[el] = !prod.out_of_stock;
              }
            });
          });
        });
      });

      massUpdateProducts(objWithIds);
    });
  }

  onShowCategories = () => {
    const { listSortOfCategoriesWithProducts, setActiveCategoryItems } = this.props;

    const arrWithNamesCategory = listSortOfCategoriesWithProducts.map(v => v.name);

    setActiveCategoryItems(arrWithNamesCategory);
  }

  onHideCategories = () => {
    const { setActiveCategoryItems } = this.props;
    this.setState({
      selectAllProducts: false,
    }, () => {
      setActiveCategoryItems([]);
    });
  }

  updateGlobalStateWithProducts = (ids, categoryName) => {
    const { productIds } = this.state;
    const newProductIds = { ...productIds };


    if (!newProductIds[categoryName]) {
      newProductIds[categoryName] = {
        ids: [],
      };
    }

    if (newProductIds[categoryName].ids.indexOf(ids) === -1) {
      newProductIds[categoryName].ids = ids;
    } else {
      newProductIds[categoryName].ids.splice(newProductIds[categoryName].ids.indexOf(ids), 1);
    }

    if (newProductIds[categoryName].ids.length === 0) {
      delete newProductIds[categoryName];
    }

    this.setState({
      productIds: newProductIds,
    });
  }

  massDeleteProducts = () => {
    const { productIds } = this.state;
    const { massDeleteProducts } = this.props;

    const arrProductsIds = [];

    Object.keys(productIds).forEach((el) => {
      arrProductsIds.push(...productIds[el].ids);
    });

    this.setState({
      arrWithProductsForDelete: arrProductsIds,
    }, () => {
      setTimeout(() => {
        massDeleteProducts(arrProductsIds);
      }, 1000);
    });
  }

  searchingProduct = debounce((e, data) => {
    const { listSortOfCategoriesWithProducts, setActiveCategoryItems } = this.props;

    const filterActiveIndex = [];

    if (data.value.trim().length > 0) {
      listSortOfCategoriesWithProducts.forEach((category) => {
        category.products.forEach((product) => {
          if (product.name.toLowerCase().indexOf(data.value.toLowerCase()) >= 0) {
            filterActiveIndex.push(category.name);
          }
        });
      });
    }

    setActiveCategoryItems(filterActiveIndex);
  }, 500)

  handleToggleSticky = () => this.setState(prevState => ({ activeSticky: !prevState.activeSticky }))

  render() {
    const {
      animation,
      openModalWithAddProduct,
      selectAllProducts,
      triggerOutOfStock,
      deselectAllProducts,
      animateSelectAllProducts,
      productIds,
      arrWithProductsForDelete,
      activeSticky,
    } = this.state;
    const {
      isFetchingStoreProducts,
      listSortOfCategoriesWithProducts,
      productsOutOfStock,
      productsAvaiable,
      sortProducts,
      updateStoreProduct,
      deleteStoreProduct,
      listCategories,
      formattedListProducts,
      fetchListBrands,
      formattedListBrands,
      addProduct,
      searchOfListProducts,
      isSearchingProduct,
      isSuccessAddProduct,
      activeCategoryItems,
    } = this.props;

    return (
      <Ref innerRef={this.contextRef}>
        <Container style={{ marginTop: '1.5em', marginBottom: '1.5em' }} stackable>
          <Grid>
            <GridColumn width={16} style={{ width: '100%' }}>
              <Sticky active={activeSticky} context={this.contextRef} offset={52}>
                <PanelTools
                  isFetchingStoreProducts={isFetchingStoreProducts}
                  productsOutOfStock={productsOutOfStock}
                  productsAvaiable={productsAvaiable}
                  addProduct={this.addProduct}
                  selectAll={this.selectAll}
                  selectOutOfStockProducts={this.selectOutOfStockProducts}
                  onHideCategories={this.onHideCategories}
                  onShowCategories={this.onShowCategories}
                  activeIndex={activeCategoryItems}
                  animateSelectAllProducts={animateSelectAllProducts}
                  deselectAll={this.deselectAll}
                  productIds={productIds}
                  massDeleteProducts={this.massDeleteProducts}
                  searchingProduct={this.searchingProduct}
                  activeSticky={activeSticky}
                  handleToggleSticky={this.handleToggleSticky}
                />
              </Sticky>
            </GridColumn>
            {listSortOfCategoriesWithProducts && (
              <SortableCategory
                axis="y"
                handleClick={this.handleClick}
                useDragHandle
                categories={listSortOfCategoriesWithProducts}
                items={this.state.products}
                activeIndex={activeCategoryItems}
                onSortEnd={this.onSortEnd}
                updateBeforeSortStart={this.onSortStart}
                disableAccordionContent={this.state.disableAccordionContent}
                openModalWithEditProduct={this.openModalWithEditProduct}
                sortProducts={sortProducts}
                selectAllProducts={selectAllProducts}
                triggerOutOfStock={triggerOutOfStock}
                deselectAllProducts={deselectAllProducts}
                animateSelectAllProducts={animateSelectAllProducts}
                updateGlobalStateWithProducts={this.updateGlobalStateWithProducts}
                arrWithProductsForDelete={arrWithProductsForDelete}
              />
            )}
          </Grid>
          <ProductEditInfo
            openModal={this.state.openModal}
            animation={this.state.animation}
            closeModal={this.closeModal}
            productInfo={this.state.productInfo}
            updateStoreProduct={updateStoreProduct}
            deleteStoreProduct={deleteStoreProduct}
          />
          {
            openModalWithAddProduct
            && (
              <AddProduct
                open={openModalWithAddProduct}
                listCategories={listCategories}
                formattedListProducts={formattedListProducts}
                fetchListBrands={fetchListBrands}
                formattedListBrands={formattedListBrands}
                addProduct={addProduct}
                searchOfListProducts={searchOfListProducts}
                isSearchingProduct={isSearchingProduct}
                closeModalModalWithAddProduct={this.closeModalModalWithAddProduct}
                isSuccessAddProduct={isSuccessAddProduct}
              />
            )
          }
        </Container>
      </Ref>
    );
  }
}

export default ManageProducts;
