import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useValueAndKey,
  useVerticalAlignProp,
} from 'utils/lib';
import styles from './List.scss';

import ListHeader from './ListHeader';
import ListDescription from './ListDescription';

const cx = classnames.bind(styles);

const ListContent = (props) => {
  const { children, className, content, description, floated, header, verticalAlign } = props;

  const classes = cx(
    useValueAndKey(floated, 'floated'),
    useVerticalAlignProp(verticalAlign),
    'content',
    className
  );

  const rest = getUnhandledProps(ListContent, props);
  const ElementType = getElementType(ListContent, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {ListHeader.create(header)}
      {ListDescription.create(description)}
      {content}
    </ElementType>
  );
};

ListContent.create = createShorthandFactory(ListContent, content => ({ content }));

export default ListContent;
