import React from 'react';

import classnames from 'classnames/bind';

import { createShorthandFactory, getUnhandledProps, useVerticalAlignProp } from 'utils/lib';
import Icon from 'components/Icons/Icon';
import styles from './List.scss';

const cx = classnames.bind(styles);

const ListIcon = (props) => {
  const { className, verticalAlign } = props;
  const classes = cx(useVerticalAlignProp(verticalAlign), className);
  const rest = getUnhandledProps(ListIcon, props);

  return <Icon {...rest} className={classes} />;
};

ListIcon.create = createShorthandFactory(ListIcon, name => ({ name }));

export default ListIcon;
