import React, { useState, useEffect } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import classnames from 'classnames/bind';
import styles from './DiscountCode.module.scss';

import useDebounce from 'hooks/debounce.hook';

const cx = classnames.bind(styles);

const DiscountCode = (props) => {
  const {
    applyDiscountCode,
    numberPosition,
    setDiscountCode,
    discountCode,
    isSuccessApplyDiscountCode,
  } = props;

  const [showDCInput, setShowDCInput] = useState(false);
  const [valueDC, setValueDC] = useState(discountCode);
  const debounceDiscountCode = useDebounce(valueDC, 500);

  useEffect(() => {
    if (debounceDiscountCode) {
      setDiscountCode(debounceDiscountCode);
    }
  }, [debounceDiscountCode])


  const applyDC = async (discountCode) => {
    setShowDCInput(false);
    applyDiscountCode(discountCode);
  };

  return (
    <div className={cx('dc-container')}>
      <h1 className={cx('dc-title')}>2. Discount code</h1>
      {!showDCInput && isSuccessApplyDiscountCode ? (
        <div className={cx('dc-input-container')}>
          <a className={cx('dc-link')} onClick={() => setShowDCInput(true)}>
            {discountCode}
          </a>
        </div>
      ) : (
          <div className={cx('dc-input-container')}>
            <Input
              size="medium"
              value={valueDC}
              placeholder="Code"
              maxLength="20"
              className={cx('dc-input')}
              onChange={e => setValueDC(e.target.value && e.target.value.trim())}
            />
            <Button
              style={{ marginLeft: '0.25em' }}
              onClick={() => applyDC(discountCode)}
              size="medium"
              color="teal"
              type="button"
              basic
            >
              apply
            </Button>
          </div>
        )}
    </div>
  );
}

export default DiscountCode;
