import React, { useState, useEffect } from 'react';
import TextArea from 'components/TextArea/TextArea';
import Form from 'components/Form/Form';

import classnames from 'classnames/bind';
import styles from './DeliveryNotes.module.scss';

import useDebounce from 'hooks/debounce.hook';

const cx = classnames.bind(styles);

export default (props) => {
  const { numberPosition, onChange, value, saveToLocalStorage } = props;
  const [note, setNote] = useState(value);
  const debouncedNote = useDebounce(note, 500);

  useEffect(() => {
    if (debouncedNote) {
      onChange(debouncedNote)
    }
  }, [debouncedNote]);

  return (
    <div className={cx('delivery-notes-container')}>
      <h1 className={cx('delivery-notes-title')}>
        {numberPosition}
        . Order notes
      </h1>
      <Form>
        <TextArea
          value={note}
          onChange={e => setNote(e.target.value)}
          // onBlur={e => saveToLocalStorage('orderNotes', e.target.value)}
          className={cx('delivery-textarea')}
          size="medium"
          style={{ minHeight: 148 }}
        />
      </Form>
    </div>
  );
};
