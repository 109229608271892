import React, { Component } from 'react';

import SearchAddressMessage from 'components/Messages/SearchAddress';
import Header from 'components/Typography/Header/Header';
import HeaderContent from 'components/Typography/Header/HeaderContent';
import HeaderSubheader from 'components/Typography/Header/HeaderSubheader';
import Icon from 'components/Icons/Icon';
import classnames from 'classnames/bind';
import { saveLocalStorage } from 'services';
import styles from './Empty.module.scss';


const cx = classnames.bind(styles);

class Empty extends Component {
  state = {};

  componentDidMount() {
    const { fetchNotFoundCode } = this.props;
    fetchNotFoundCode();
  }

  goToDelivery = async (address) => {
    const { history, setDeliveryAddress, domainPath } = this.props;
    const resetStores = true;

    await setDeliveryAddress(address);
    history.push(`${domainPath}stores?location=${address}`);
  };

  render() {
    const { childrenComponentForEmptyPage } = this.props;

    return (
      <SearchAddressMessage
        goToDelivery={this.goToDelivery}
        message={childrenComponentForEmptyPage === 'searchAddressComponent' ? (
          <Header as="h2" icon>
            <Icon name="user md" circular size="large" />
            <HeaderContent>Page does not exist.</HeaderContent>
            <HeaderSubheader>Please enter address below to find something real</HeaderSubheader>
          </Header>
        ) : (
            <Header as="h2" icon>
              <Icon name="warning sign" circular size="large" />
              <HeaderSubheader>THC products are currently not available in your area</HeaderSubheader>
            </Header>
          )}
      />
    );
  }
}

export default Empty;
