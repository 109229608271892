import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';
import styles from './Statistic.scss';

const cx = classnames.bind(styles);

const StatisticValue = (props) => {
  const { children, className, content, text } = props;

  const classes = cx(useKeyOnly(text, 'text'), 'value', className);
  const rest = getUnhandledProps(StatisticValue, props);
  const ElementType = getElementType(StatisticValue, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

StatisticValue.create = createShorthandFactory(StatisticValue, content => ({ content }));

export default StatisticValue;
