import React, { Children, cloneElement, createRef } from 'react';
import EventStack from '@semantic-ui-react/event-stack';
import classnames from 'classnames/bind';
import _ from 'lodash';
import shallowEqual from 'shallowequal';

import {
  AutoControlledComponent as Component,
  isNil,
  doesNodeContainClick,
  getElementType,
  getUnhandledProps,
  objectDiff,
  useKeyOnly,
  useKeyOrValueAndKey,
  eventStack,
} from 'utils/lib';

import Ref from 'components/Addons/Ref/Ref';
import Icon from 'components/Icons/Icon';
import Label from 'components/Label/Label';
import Loader from 'components/Loaders/Loader';
import DropdownHeader from './DropdownHeader';
import DropdownItem from './DropdownItem';
import DropdownMenu from './DropdownMenu';
import DropdownSearchInput from './DropdownSearchInput';

import styles from './Dropdown.css';

const cx = classnames.bind(styles);

const getKeyOrValue = (key, value) => (_.isNil(key) ? value : key);

class Dropdown extends Component {
  static defaultProps = {
    additionLabel: 'Add ',
    additionPosition: 'top',
    closeOnBlur: true,
    closeOnEscape: true,
    deburr: false,
    icon: 'dropdown',
    minCharacters: 1,
    noResultsMessage: 'No results found.',
    openOnFocus: true,
    renderLabel: ({ text }) => text,
    searchInput: 'text',
    selectOnBlur: true,
    selectOnNavigation: true,
    wrapSelection: true,
  }

  static autoControlledProps = ['open', 'searchQuery', 'selectedLabel', 'value', 'upward']

  searchRef = createRef()

  sizerRef = createRef()

  ref = createRef()

  getInitialAutoControlledState() {
    return { focus: false, searchQuery: '' };
  }

  componentWillMount() {
    const { open, value } = this.state;

    this.setValue(value);
    this.setSelectedIndex(value);

    if (open) {
      this.open();
    }
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    if (!shallowEqual(nextProps.value, this.props.value)) {
      this.setValue(nextProps.value);
      this.setSelectedIndex(nextProps.value);
    }

    if (
      !_.isEqual(this.getKeyAndValues(nextProps.options), this.getKeyAndValues(this.props.options))
    ) {
      this.setSelectedIndex(undefined, nextProps.options);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(nextProps, this.props) || !shallowEqual(nextState, this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const { closeOnBlur, minCharacters, openOnFocus, search } = this.props;

    if (!prevState.focus && this.state.focus) {
      if (!this.isMouseDown) {
        const openable = !search || (search && minCharacters === 1 && !this.state.open);
        if (openOnFocus && openable) this.open();
      }
    } else if (prevState.focus && !this.state.focus) {
      if (!this.isMouseDown && closeOnBlur) {
        this.close();
      }
    }

    if (!prevState.open && this.state.open) {
      this.setOpenDirection();
      this.scrollSelectedItemIntoView();
    } else if (prevState.open && !this.state.open) {
      this.handleClose();
    }
  }

  setValue = (value) => {
    this.trySetState({ value });
  }

  setSelectedIndex = (
    value = this.state.value,
    optionsProps = this.props.options,
    searchQuery = this.state.searchQuery,
  ) => {
    const { multiple } = this.props;
    const { selectedIndex } = this.state;
    const options = this.getMenuOptions(value, optionsProps, searchQuery);
    const enabledIndicies = this.getEnabledIndices(options);

    let newSelectedIndex;

    if (!selectedIndex || selectedIndex < 0) {
      const firstIndex = enabledIndicies[0];

      newSelectedIndex = multiple
        ? firstIndex
        : this.getMenuItemIndexByValue(value, options) || enabledIndicies[0];
    } else if (multiple) {
      if (selectedIndex >= options.length - 1) {
        newSelectedIndex = enabledIndicies[enabledIndicies.length - 1];
      }
    } else {
      const activeIndex = this.getMenuItemIndexByValue(value, options);
      newSelectedIndex = _.includes(enabledIndicies, activeIndex) ? activeIndex : undefined;
    }

    if (!newSelectedIndex || newSelectedIndex < 0) {
      newSelectedIndex = enabledIndicies[0];
    }

    this.setState({ selectedIndex: newSelectedIndex });
  }

  getMenuOptions = (
    value = this.state.value,
    options = this.props.options,
    searchQuery = this.state.searchQuery,
  ) => {
    const { additionLabel, additionPosition, allowAdditions, deburr, multiple, search } = this.props;

    let filteredOptions = options;

    if (multiple) {
      filteredOptions = _.filter(filteredOptions, opt => !_.includes(value, opt.value));
    }

    if (search && searchQuery) {
      if (_.isFunction(search)) {
        filteredOptions = search(filteredOptions, searchQuery);
      } else {
        const strippedQuery = deburr ? _.deburr(searchQuery) : searchQuery;

        const re = new RegExp(_.escapeRegExp(strippedQuery), 'i');

        filteredOptions = _.filter(filteredOptions, opt => re.test(deburr ? _.deburr(opt.text) : opt.text));
      }
    }

    if (
      allowAdditions
      && search
      && searchQuery
      && !_.some(filteredOptions, { text: searchQuery })
    ) {
      const additionLabelElement = React.isValidElement(additionLabel)
        ? React.cloneElement(additionLabel, { key: 'addition-label' })
        : additionLabel || '';

      const addItem = {
        key: 'addition',
        text: [additionLabelElement, <b key="addition-query">{searchQuery}</b>],
        value: searchQuery,
        className: cx('addition'),
        'data-additional': true,
      };
      if (additionPosition === 'top') filteredOptions.unshift(addItem);
      else filteredOptions.push(addItem);
    }

    return filteredOptions;
  }

  getEnabledIndices = (givenOptions) => {
    const options = givenOptions || this.getMenuOptions();

    return _.reduce(
      options,
      (memo, item, index) => {
        if (!item.disabled) memo.push(index);
        return memo;
      },
      [],
    );
  };

  getItemByValue = (value) => {
    const { options } = this.props;

    return _.find(options, { value });
  }

  getMenuItemIndexByValue = (value, givenOptions) => {
    const options = givenOptions || this.getMenuOptions();

    return _.findIndex(options, ['value', value]);
  }

  getDropdownAriaOptions = () => {
    const { loading, disabled, search, multiple } = this.props;
    const { open } = this.state;
    const ariaOptions = {
      role: search ? 'combobox' : 'listbox',
      'aria-busy': loading,
      'aria-disabled': disabled,
      'aria-expanded': !!open,
    };
    if (ariaOptions.role === 'listbox') {
      ariaOptions['aria-multiselectable'] = multiple;
    }
    return ariaOptions;
  }

  getDropdownMenuAriaOptions() {
    const { search, multiple } = this.props;
    const ariaOptions = {};

    if (search) {
      ariaOptions['aria-multiselectable'] = multiple;
      ariaOptions.role = 'listbox';
    }
    return ariaOptions;
  }

  getSelectedItem = () => {
    const { selectedIndex } = this.state;
    const options = this.getMenuOptions();

    return _.get(options, `[${selectedIndex}]`);
  }

  computeSearchInputTabIndex = () => {
    const { disabled, tabIndex } = this.props;

    if (!_.isNil(tabIndex)) return tabIndex;
    return disabled ? -1 : 0;
  }

  computeSearchInputWidth = () => {
    const { searchQuery } = this.state;

    if (this.sizerRef.current && searchQuery) {
      this.sizerRef.current.style.display = 'inline';
      this.sizerRef.current.textContent = searchQuery;
      const searchWidth = Math.ceil(this.sizerRef.current.getBoundingClientRect().width);
      this.sizerRef.current.style.removeProperty('display');

      return searchWidth;
    }
  }

  getKeyAndValues = options => (options ? options.map(option => _.pick(option, ['key', 'value'])) : options)

  getMenuOptions = (
    value = this.state.value,
    options = this.props.options,
    searchQuery = this.state.searchQuery,
  ) => {
    const { additionLabel, additionPosition, allowAdditions, deburr, multiple, search } = this.props;

    let filteredOptions = options;

    if (multiple) {
      filteredOptions = _.filter(filteredOptions, opt => !_.includes(value, opt.value));
    }

    if (search && searchQuery) {
      if (_.isFunction(search)) {
        filteredOptions = search(filteredOptions, searchQuery);
      } else {
        const strippedQuery = deburr ? _.deburr(searchQuery) : searchQuery;

        const re = new RegExp(_.escapeRegExp(strippedQuery), 'i');

        filteredOptions = _.filter(filteredOptions, opt => re.test(deburr ? _.deburr(opt.text) : opt.text));
      }
    }

    // insert the "add" item
    if (
      allowAdditions
      && search
      && searchQuery
      && !_.some(filteredOptions, { text: searchQuery })
    ) {
      const additionLabelElement = React.isValidElement(additionLabel)
        ? React.cloneElement(additionLabel, { key: 'addition-label' })
        : additionLabel || '';

      const addItem = {
        key: 'addition',
        text: [additionLabelElement, <b key="addition-query">{searchQuery}</b>],
        value: searchQuery,
        className: cx('addition'),
        'data-additional': true,
      };
      if (additionPosition === 'top') filteredOptions.unshift(addItem);
      else filteredOptions.push(addItem);
    }

    return filteredOptions;
  }

  handleChange = (e, value) => {
    _.invoke(this.props, 'onChange', e, { ...this.props, value });
  }

  closeOnChange = (e) => {
    const { closeOnChange, multiple } = this.props;
    const shouldClose = _.isUndefined(closeOnChange) ? !multiple : closeOnChange;

    if (shouldClose) this.close(e);
  }

  makeSelectedItemActive = (e) => {
    const { open, value } = this.state;
    const { multiple } = this.props;

    const item = this.getSelectedItem();
    const selectedValue = _.get(item, 'value');

    if (_.isNil(selectedValue) || !open) return;

    const newValue = multiple ? _.union(this.state.value, [selectedValue]) : selectedValue;
    const valueHasChanged = multiple ? !!_.difference(newValue, value).length : newValue !== value;

    if (valueHasChanged) {
      this.setValue(newValue);
      this.setSelectedIndex(newValue);
      this.handleChange(e, newValue);

      if (item['data-additional']) {
        _.invoke(this.props, 'onAddItem', e, { ...this.props, value: selectedValue });
      }
    }
  }


  closeOnDocumentClick = (e) => {
    if (!this.props.closeOnBlur) return;

    if (this.ref.current && doesNodeContainClick(this.ref.current, e)) return;

    this.close();
  }

  handleMouseDown = (e) => {
    this.isMouseDown = true;
    _.invoke(this.props, 'onMouseDown', e, this.props);
    document.addEventListener('mouseup', this.handleDocumentMouseUp);
  }

  handleDocumentMouseUp = () => {
    this.isMouseDown = false;
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
  }

  handleClick = (e) => {
    const { minCharacters, search } = this.props;
    const { open, searchQuery } = this.state;

    _.invoke(this.props, 'onClick', e, this.props);
    e.stopPropagation();

    if (!search) return this.toggle(e);
    if (open) {
      _.invoke(this.searchRef.current, 'focus');
      return;
    }
    if (searchQuery.length >= minCharacters || minCharacters === 1) {
      this.open(e);
      return;
    }
    _.invoke(this.searchRef.current, 'focus');
  }

  handleIconClick = (e) => {
    const { clearable } = this.props;
    const hasValue = this.hasValue();

    _.invoke(this.props, 'onClick', e, this.props);
    e.stopPropagation();

    if (clearable && hasValue) {
      this.clearValue(e);
    } else {
      this.toggle(e);
    }
  }

  clearSearchQuery = (value) => {
    const { searchQuery } = this.state;
    if (searchQuery === undefined || searchQuery === '') return;

    this.trySetState({ searchQuery: '' });
    this.setSelectedIndex(value, undefined, '');
  }

  handleItemClick = (e, item) => {
    const { multiple, search } = this.props;
    const { value: currentValue } = this.state;
    const { value } = item;

    e.stopPropagation();
    if (multiple || item.disabled) e.nativeEvent.stopImmediatePropagation();
    if (item.disabled) return;

    const isAdditionItem = item['data-additional'];
    const newValue = multiple ? _.union(this.state.value, [value]) : value;
    const valueHasChanged = multiple
      ? !!_.difference(newValue, currentValue).length
      : newValue !== currentValue;

    if (valueHasChanged) {
      this.setValue(newValue);
      this.setSelectedIndex(value);

      this.handleChange(e, newValue);
    }

    this.clearSearchQuery(value);
    this.closeOnChange(e);

    if (isAdditionItem) _.invoke(this.props, 'onAddItem', e, { ...this.props, value });

    if (search) _.invoke(this.searchRef.current, 'focus');
  }

  handleFocus = (e) => {
    const { focus } = this.state;

    if (focus) return;

    _.invoke(this.props, 'onFocus', e, this.props);
    this.setState({ focus: true });
  }

  handleBlur = (e) => {
    const currentTarget = _.get(e, 'currentTarget');
    if (currentTarget && currentTarget.contains(document.activeElement)) return;

    const { closeOnBlur, multiple, selectOnBlur } = this.props;
    if (this.isMouseDown) return;

    _.invoke(this.props, 'onBlur', e, this.props);

    if (selectOnBlur && !multiple) {
      this.makeSelectedItemActive(e);
      if (closeOnBlur) this.close();
    }

    this.setState({ focus: false });
    this.clearSearchQuery();
  }

  handleSearchChange = (e, { value }) => {
    e.stopPropagation();

    const { minCharacters } = this.props;
    const { open } = this.state;
    const newQuery = value;

    _.invoke(this.props, 'onSearchChange', e, { ...this.props, searchQuery: newQuery });
    this.trySetState({ searchQuery: newQuery }, { selectedIndex: 0 });

    if (!open && newQuery.length >= minCharacters) {
      this.open();
      return;
    }

    if (open && minCharacters !== 1 && newQuery.length < minCharacters) this.close();
  }

  handleClose = () => {
    const hasSearchFocus = document.activeElement === this.searchRef.current;
    if (!hasSearchFocus) {
      this.ref.current.blur();
    }

    const hasDropdownFocus = document.activeElement === this.ref.current;
    const hasFocus = hasSearchFocus || hasDropdownFocus;

    this.setState({ focus: hasFocus });
  }

  toggle = e => (this.state.open ? this.close(e) : this.open(e));

  hasValue = () => {
    const { multiple } = this.props;
    const { value } = this.state;

    return multiple ? !_.isEmpty(value) : !_.isNil(value) && value !== '';
  }

  renderText = () => {
    const { multiple, placeholder, search, text, iconByValue } = this.props;
    const { searchQuery, value, open } = this.state;
    const hasValue = this.hasValue();

    const classes = cx(
      placeholder && !hasValue && 'default',
      'text',
      search && searchQuery && 'filtered',
    );
    let _text = placeholder;
    if (searchQuery) {
      _text = null;
    } else if (text) {
      _text = text;
    } else if (open && !multiple) {
      _text = _.get(this.getSelectedItem(), 'text');
    } else if (hasValue) {
      _text = _.get(this.getItemByValue(value), 'text');
    }

    return (
      <div className={classes} role="alert" aria-live="polite" aria-atomic>
        {iconByValue}
        {_text}
      </div>
    );
  }

  handleSearchInputOverrides = predefinedProps => ({
    onChange: (e, inputProps) => {
      _.invoke(predefinedProps, 'onChange', e, inputProps);
      this.handleSearchChange(e, inputProps);
    },
  })

  handleLabelClick = (e, labelProps) => {
    e.stopPropagation();
    this.setState({ selectedLabel: labelProps.value });
    _.invoke(this.props, 'onLabelClick', e, labelProps);
  }

  handleLabelRemove = (e, labelProps) => {
    e.stopPropagation();
    const { value } = this.state;
    const newValue = _.without(value, labelProps.value);

    this.setValue(newValue);
    this.setSelectedIndex(newValue);
    this.handleChange(e, newValue);
  }

  renderSearchInput = () => {
    const { search, searchInput } = this.props;
    const { searchQuery } = this.state;

    return (
      search && (
        <Ref innerRef={this.searchRef}>
          {DropdownSearchInput.create(searchInput, {
            defaultProps: {
              style: { width: this.computeSearchInputWidth() },
              tabIndex: this.computeSearchInputTabIndex(),
              value: searchQuery,
            },
            overrideProps: this.handleSearchInputOverrides,
          })}
        </Ref>
      )
    );
  }

  renderSearchSizer = () => {
    const { search, multiple } = this.props;

    return search && multiple && <span className="sizer" ref={this.sizerRef} />;
  }

  renderLabels = () => {
    const { multiple, renderLabel } = this.props;

    const { selectedLabel, value } = this.state;
    if (!multiple || _.isEmpty(value)) {
      return;
    }
    const selectedItems = _.map(value, this.getItemByValue);

    return _.map(_.compact(selectedItems), (item, index) => {
      const defaultProps = {
        active: item.value === selectedLabel,
        as: 'a',
        key: getKeyOrValue(item.key, item.value),
        onClick: this.handleLabelClick,
        onRemove: this.handleLabelRemove,
        value: item.value,
      };
      return Label.create(renderLabel(item, index, defaultProps), { defaultProps });
    });
  }

  renderOptions = () => {
    const { lazyLoad, multiple, search, noResultsMessage } = this.props;
    const { open, selectedIndex, value } = this.state;

    if (lazyLoad && !open) return null;

    const options = this.getMenuOptions();

    if (noResultsMessage !== null && search && _.isEmpty(options)) {
      return <div className={cx('message')}>{noResultsMessage}</div>;
    }

    const isActive = multiple
      ? optValue => _.includes(value, optValue)
      : optValue => optValue === value;

    return _.map(options, (opt, i) => DropdownItem.create({
      active: isActive(opt.value),
      onClick: this.handleItemClick,
      selected: selectedIndex === i,
      ...opt,
      key: getKeyOrValue(opt.key, opt.value),
      style: { ...opt.style, pointerEvents: 'all' },
    }));
  }

  renderMenu = () => {
    const { children, direction, header } = this.props;
    const { open } = this.state;
    const ariaOptions = this.getDropdownMenuAriaOptions();

    if (!isNil(children)) {
      const menuChild = Children.only(children);
      const className = cx(direction, useKeyOnly(open, 'visible'), menuChild.props.className);

      return cloneElement(menuChild, { className, ...ariaOptions });
    }

    return (
      <DropdownMenu {...ariaOptions} direction={direction} open={open}>
        {DropdownHeader.create(header, { autoGenerateKey: false })}
        {this.renderOptions()}
      </DropdownMenu>
    );
  }

  scrollSelectedItemIntoView = () => {
    if (!this.ref.current) return;
    const menu = this.ref.current.querySelector('.menu.visible');
    if (!menu) return;
    const item = menu.querySelector('.item.selected');
    if (!item) return;
    const isOutOfUpperView = item.offsetTop < menu.scrollTop;
    const isOutOfLowerView = item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight;

    if (isOutOfUpperView) {
      menu.scrollTop = item.offsetTop;
    } else if (isOutOfLowerView) {
      menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight;
    }
  }

  setOpenDirection = () => {
    if (!this.ref.current) return;

    const menu = this.ref.current.querySelector('.menu.visible');

    if (!menu) return;

    const dropdownRect = this.ref.current.getBoundingClientRect();
    const menuHeight = menu.clientHeight;
    const spaceAtTheBottom = document.documentElement.clientHeight - dropdownRect.top - dropdownRect.height - menuHeight;
    const spaceAtTheTop = dropdownRect.top - menuHeight;

    const upward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;

    if (!upward !== !this.state.upward) {
      this.trySetState({ upward });
    }
  }

  open = (e) => {
    const { disabled, open, search } = this.props;

    if (disabled) return;
    if (search) _.invoke(this.searchRef.current, 'focus');

    _.invoke(this.props, 'onOpen', e, this.props);

    this.trySetState({ open: true });
    this.scrollSelectedItemIntoView();
  }

  close = (e) => {
    const { open } = this.state;

    if (open) {
      _.invoke(this.props, 'onClose', e, this.props);
      this.trySetState({ open: false });
    }
  }

  render() {
    const {
      basic,
      button,
      className,
      compact,
      disabled,
      error,
      fluid,
      floating,
      icon,
      inline,
      item,
      labeled,
      loading,
      multiple,
      pointing,
      search,
      selection,
      scrolling,
      simple,
      trigger,
      style,
      iconName
    } = this.props;

    const { focus, open, upward } = this.state;

    const classes = cx(
      'ui',
      useKeyOnly(open, 'active visible'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(error, 'error'),
      useKeyOnly(loading, 'loading'),

      useKeyOnly(basic, 'basic'),
      useKeyOnly(button, 'button'),
      useKeyOnly(compact, 'compact'),
      useKeyOnly(fluid, 'fluid'),
      useKeyOnly(floating, 'floating'),
      useKeyOnly(inline, 'inline'),
      useKeyOnly(labeled, 'labeled'),
      useKeyOnly(item, 'item'),
      useKeyOnly(multiple, 'multiple'),
      useKeyOnly(search, 'search'),
      useKeyOnly(selection, 'selection'),
      useKeyOnly(simple, 'simple'),
      useKeyOnly(scrolling, 'scrolling'),
      useKeyOnly(upward, 'upward'),
      useKeyOrValueAndKey(pointing, 'pointing'),
      'dropdown',
      className,
    );

    const rest = getUnhandledProps(Dropdown, this.props);
    const customStyle = style || {};
    const ElementType = getElementType(Dropdown, this.props);
    const ariaOptions = this.getDropdownAriaOptions(ElementType, this.props);

    return (
      <Ref innerRef={this.ref}>
        <ElementType
          style={customStyle}
          {...ariaOptions}
          className={classes}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
          onMouseDown={this.handleMouseDown}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          tabIndex={this.computeTabIndex}
        >
          {this.renderLabels()}
          {this.renderSearchInput()}
          {this.renderSearchSizer()}
          {trigger || this.renderText()}
          {Icon.create(icon, {
            overrideProps: this.handleIconOverrides,
            autoGenerateKey: false,
          })}
          {this.renderMenu()}
          {open && <EventStack name="click" on={this.closeOnDocumentClick} />}
        </ElementType>
      </Ref>
    );
  }
}

export default Dropdown;
