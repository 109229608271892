import React, { useState, useEffect } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { generateQuery } from 'services/api';
import SearchBar from 'components/SearchBar';
import Input from 'components/Input/Input';

import classnames from 'classnames/bind';
import styles from './DeliveryAddress.module.scss';

import useDebounce from 'hooks/debounce.hook';

const cx = classnames.bind(styles);

export default (props) => {
  const {
    numberPosition,
    changeDeliveryAddress,
    locallyDeliveryAddress,
    locallyStateDeliveryAddress,
    saveToLocalStorage,
    onFocusField,
    errorAddress,
    errorState,
    deliveryAddress,
    handleSelectAddress
  } = props;
  const [address, setAddress] = useState(deliveryAddress.data.streetNameForInputCart);
  const debouncedAddress = useDebounce(address, 500);

  useEffect(() => {
    if (debouncedAddress) {
      changeDeliveryAddress(debouncedAddress, 'streetNameForInputCart', 'change')
    }
  }, [debouncedAddress])

  const geocodeByAddressAndSetNewLocallyAddressWithZip = (locallyStateDeliveryAddress) => {
    const { setLocallyAddressWithZip } = props;

    geocodeByAddress(locallyStateDeliveryAddress).then((results) => {
      const zip = generateQuery('postal_code', results[0]);

      if (!zip) {
        getLatLng(results[0]).then((res) => {
          const geocoder = window.google && new window.google.maps.Geocoder();
          geocoder.geocode({ location: res }, (result) => {
            const geoData = result.find(value => value.types.indexOf('postal_code') >= 0);
            if (geoData) {
              setLocallyAddressWithZip(geoData.formatted_address);
            }
          });
        });
      } else if (results[0] && results[0].formatted_address) {
        setLocallyAddressWithZip(results[0].formatted_address);
      }
    });
  }

  const handleChange = (address) => {
    const { setLocallyAddressWithZip } = props;

    setLocallyAddressWithZip(address);
  };

  const handleSelect = (address) => {
    const { handleSelectAddress } = props;

    handleSelectAddress(address);
    this.geocodeByAddressAndSetNewLocallyAddressWithZip(address);
  }

  const handleFocus = () => {
    const { handleFocus } = props;

    handleFocus();
  }

  const changeSearcBarAddress = (address) => {
    const { changeDeliveryAddress } = props;

    changeDeliveryAddress(address, 'addressWithZipForInputCart', 'change');
  }


  const defaultStyles = {
    input: {
      backgroundColor: '#f6f7f9',
      border: 'solid 1px #c1c1c1',
      width: '100%',
      padding: '12px 12px 12px 28px',
      boxSizing: 'border-box',
      outline: 'none',
      fontSize: '14px',
    },
    autocompleteContainer: {
      overflow: 'hidden',
      border: 'solid 1px #c1c1c1',
      borderTop: 0,
    },
  };

  const options = {
    componentRestrictions: { country: 'us' },
  };

  return (
    <div className={cx('delivery-address')}>
      <h1 className={cx('delivery-title')}>
        {numberPosition}
        . Delivery address
        </h1>
      <div className={cx('delivery-address-wrapper')}>
        <div style={{ width: '100%' }}>
          <div className={cx('local-address-name')}>Address</div>
          <div>
            <Input
              placeholder="Enter address..."
              type="text"
              value={address}
              size="medium"
              className={cx('input-delivery-address')}
              error={deliveryAddress.data.streetNameForInputCart.length === 0}
              onChange={e => setAddress(e.target.value)}
              onFocus={() => onFocusField('streetNameForInputCart')}
              error={errorAddress}
            />
          </div>
        </div>
        <div className={cx('local-state-container')}>
          <div className={cx('local-state-name')}>City, State, Zip</div>
          <div className={cx('delivery-container')}>
            <SearchBar
              address={deliveryAddress.data.addressWithZipForInputCart}
              onChange={changeSearcBarAddress}
              onSelect={address => handleSelectAddress(address, 'addressWithZipForInputCart', 'select')}
              handleFocus={handleFocus}
              customOptions={{ types: ['(regions)'], componentRestrictions: { country: 'us' } }}
              customPlaceholder="Enter city or state"
              sizeInput="medium"
              onBackgroundInput
              checkError
              error={errorState}
              onFocusDefault={() => onFocusField('addressWithZipForInputCart')}
              loading={deliveryAddress.isChangingDeliveryAddress}
              disabled={deliveryAddress.isChangingDeliveryAddress}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
