import React, { Component, createRef } from 'react';
import classnames from 'classnames/bind';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import FormGroup from 'components/Form/FormGroup';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Icon from 'components/Icons/Icon';

import styles from './Secondary.module.scss';

const cx = classnames.bind(styles);

class Secondary extends Component {
  state = {
    fields: {
      min_order_price: {
        value: '',
        error: false,
      },
      delivery_cost: {
        value: 'value',
        error: false,
      },
    },
  }

  createNewDeliveryLocation = () => {
    const { fields } = this.state;
    const { createDeliveryLocation } = this.props;
    let hasError = false;

    Object.keys(fields).forEach((v) => {
      if (fields[v].value.length === 0) {
        fields[v].error = true;
        hasError = true;
      }
    });

    if (hasError) {
      this.setState({
        fields,
      });
      return;
    }

    createDeliveryLocation(fields.min_order_price.value).then((res) => {
      if (res) {
        Object.keys(fields).forEach((v) => {
          fields[v].error = false;
          fields[v].value = '';
        });
        this.setState({
          fields,
        });
      }
    });
  }

  onChange = (e, field) => {
    const { fields } = this.state;

    fields[field.name].value = field.value;
    fields[field.name].error = false;

    this.setState({
      fields,
    });
  }

  render() {
    const { fields } = this.state;
    const { handleOpen, deliveryAddress } = this.props;
    return (
      <Form unstackable>
        <FormField>
          <label>Min order price</label>
          <Input
            name="min_order_price"
            size="small"
            error={fields.min_order_price.error}
            fluid
            placeholder="price..."
            icon="dollar"
            onChange={this.onChange}
            defaultValue={fields.min_order_price.value}
            type="number"
          />
        </FormField>
        <FormField disabled>
          <label>Shipping cost</label>
          <Input
            name="delivery_cost"
            size="small"
            error={fields.delivery_cost.error}
            fluid
            placeholder="price..."
            icon="dollar"
            onChange={this.onChange}
            defaultValue={fields.delivery_cost.value}
            type="number"
            disabled
          />
        </FormField>
        <FormField inline>
          <Icon name="map marker alternate" color="blue" />
          <a className={cx('link')} onClick={handleOpen}>{deliveryAddress.length === 0 ? 'Choose address...' : deliveryAddress}</a>
        </FormField>
        <FormField>
          <Button type="button" size="small" fluid primary onClick={this.createNewDeliveryLocation}>
            <Icon name="plus" />
            Create
                    </Button>
        </FormField>
      </Form>
    );
  }
}

export default Secondary;
