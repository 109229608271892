import React, { Component } from 'react';

import Input from 'components/Input/Input';
import Label from 'components/Label/Label';

const labelStyle = {
  width: '37.5%',
  fontWeight: '700',
  color: 'rgba(0,0,0,.87)',
  fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
  margin: '0em 0em 0.28571429rem 0em',
  fontSize: '0.92857143em',
};

const packingName = {
  oz: 'oz',
  gram: 'gram',
  gram_oz: 'gram oz',
  piece_discrete: 'piece descrete',
  piece: 'piece',
}

class Packing extends Component {
  state = {
    oz: { '1/8': '', '1/4': '', '1/2': '', '1': '', '2': '' },
    gram: { '0.25': '', '0.5': '', '1': '', '2': '' },
    'gram_oz': { '0.25': '', '0.5': '', '1': '', '2': '', '1/8': '' },
    'piece_discrete': { 'For 1 product': '', 'For each of 2': '', 'For each of 3': '', 'For each of 4': '', 'For each of 5': '', 'For each of 6': '', 'For each of 7': '', 'For each of 8 and more': '' },
    piece: { 'piece': '' }
  }

  render() {
    const { product, onChangeInput } = this.props;
    return (
      <div style={{ marginTop: '1em' }}>
        <label style={labelStyle}>Price</label>
        {Object.keys(this.state[product.packing]).map(v => (
          <Input
            name={v}
            fluid
            packing={product.packing}
            icon="dollar"
            value={this.state[product.packing[v]]}
            type="number"
            onChange={onChangeInput}
            labelPosition="right"
            style={{ marginTop: '0.5em', width: '50%' }}
          >
            <Label basic>$</Label>
            <input />
            <Label>{v}
              {' '}
              {packingName[product.packing]}
            </Label>
          </Input>
        ))}
      </div>
    )
  }
}

export default Packing;
