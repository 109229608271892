import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Segment from 'components/Segment/Segment';
import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Checkbox from 'components/CheckBox/Checkbox';
import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';

import Grid from 'components/Grid/Grid';
import GridRow from 'components/Grid/GridRow';
import GridColumn from 'components/Grid/GridColumn';

import Modal from 'components/ModalWindow/Modal';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalContent from 'components/ModalWindow/ModalContent';
import ModalDescription from 'components/ModalWindow/ModalDescription';
import ModalActions from 'components/ModalWindow/ModalActions';

import GramOz from './components/Packing/GramOz';
import Gram from './components/Packing/Gram';
import Oz from './components/Packing/Oz';
import Piece from './components/Packing/Piece';
import PieceDescrete from './components/Packing/PieceDescrete';

import styles from './ProductEditInfo.module.scss';

const cx = classnames.bind(styles);

class ProductEditInfo extends Component {
  state = {}

  onChange = (value, name, packing) => {
    const { productInfo } = this.props;

    let updatePrice = productInfo.price;

    if (value.length === 0) {
      return null;
    }

    if (packing === 'piece') {
      updatePrice = parseFloat(value);
      this.productPrices = updatePrice;
    } else {
      updatePrice[name] = `${parseFloat(value)}`;
      this.productPrices = JSON.stringify(updatePrice);
    }
  }

  toggleOutOfStock = (value) => {
    this.productOutOfStock = value;
  }

  updateStoreProduct = () => {
    const { productInfo, updateStoreProduct, closeModal } = this.props;

    updateStoreProduct(productInfo.id, this.productPrices, this.productOutOfStock).then(() => {
      this.setState({
        isSuccessUpdate: true,
      }, () => {
        this.productPrices = null;
        clearTimeout(this.counter);
        this.counter = setTimeout(() => {
          this.setState({
            isSuccessUpdate: false,
          }, () => {
            closeModal();
          });
        }, 3000);
      });
    });
  }

  deleteStoreProduct = () => {
    const { deleteStoreProduct, productInfo, closeModal } = this.props;

    this.setState({
      loading: true,
    });
    deleteStoreProduct(productInfo.id).then(() => {
      this.setState({
        loading: false,
      }, () => {
        closeModal();
      });
    });
  }

  render() {
    const { openModal, animation, closeModal, productInfo } = this.props;
    const { isSuccessUpdate, loading } = this.state;

    return (
      <Modal
        open={openModal}
        closeIcon={<Icon name="close" link onClick={closeModal} />}
        onClose={closeModal}
        size="mini"
        className={animation ? cx('fade-in-top') : cx('fade-out-top')}
      >
        <ModalHeader style={{ textAlign: 'center' }}>
          <Icon name="edit" />
          Product Edit
        </ModalHeader>
        <ModalContent>
          <h4 className={cx(isSuccessUpdate ? 'slide-in-right' : 'slide-out-right')} style={{ color: 'green', textAlign: 'center' }}>{isSuccessUpdate ? 'Success' : null}</h4>
          <ModalDescription>
            {
              openModal
              && (
                <Segment basic loading={loading}>
                  {productInfo && productInfo.packing === 'gram_oz' && <GramOz price={productInfo.price} outOfStock={productInfo.out_of_stock} onChange={this.onChange} toggleOutOfStock={this.toggleOutOfStock} />}
                  {productInfo && productInfo.packing === 'gram' && <Gram price={productInfo.price} outOfStock={productInfo.out_of_stock} onChange={this.onChange} toggleOutOfStock={this.toggleOutOfStock} />}
                  {productInfo && productInfo.packing === 'oz' && <Oz price={productInfo.price} outOfStock={productInfo.out_of_stock} onChange={this.onChange} toggleOutOfStock={this.toggleOutOfStock} />}
                  {productInfo && productInfo.packing === 'piece' && <Piece price={productInfo.price} outOfStock={productInfo.out_of_stock} onChange={this.onChange} toggleOutOfStock={this.toggleOutOfStock} />}
                  {productInfo && productInfo.packing === 'piece_discrete' && <PieceDescrete price={productInfo.price} outOfStock={productInfo.out_of_stock} onChange={this.onChange} toggleOutOfStock={this.toggleOutOfStock} />}
                </Segment>
              )
            }
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button type="button" disabled={loading} negative onClick={this.deleteStoreProduct}>Delete</Button>
          <Button type="button" disabled={loading} primary onClick={this.updateStoreProduct}>Update</Button>
        </ModalActions>
      </Modal>
    );
  }
}

export default ProductEditInfo;
