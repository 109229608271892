/* eslint-disable global-require */
import 'babel-polyfill';
import 'whatwg-fetch';
import './styles';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { runAnalytics } from 'services/analytics';
import { deleteLocalStorageItem } from 'services';
import Loader from 'components/Loaders/Loader';

import { store, persistor } from './store';
import Root from './routes';

runAnalytics();
deleteLocalStorageItem('persist:rrsb');

render(
  <Provider store={store}>
    <PersistGate loading={<Loader center active />} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

/* eslint-enable global-require */
