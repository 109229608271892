import React, { cloneElement, Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import { normalizeTransitionDuration, useKeyOnly } from 'utils/lib';
import styles from './Transition.css';

const cx = classnames.bind(styles);

const TRANSITION_TYPE = {
  ENTERING: 'show',
  EXITING: 'hide',
};

export const DIRECTIONAL_TRANSITIONS = [
  'browse',
  'browse right',
  'drop',
  'fade',
  'fade up',
  'fade down',
  'fade left',
  'fade right',
  'fly up',
  'fly down',
  'fly left',
  'fly right',
  'horizontal flip',
  'vertical flip',
  'scale',
  'slide up',
  'slide down',
  'slide left',
  'slide right',
  'swing up',
  'swing down',
  'swing left',
  'swing right',
  'zoom',
];

class Transition extends Component {
  static defaultProps = {
    animation: 'fade',
    duration: 500,
    visible: true,
    mountOnShow: true,
    transitionOnMount: false,
    unmountOnHide: false,
  };

  static ENTERED = 'ENTERED';

  static ENTERING = 'ENTERING';

  static EXITED = 'EXITED';

  static EXITING = 'EXITING';

  static UNMOUNTED = 'UNMOUNTED';

  constructor(...args) {
    super(...args);

    const { iniitial: status, next } = this.computeInitialStatuses();
    this.nextStatus = next;
    this.state = { status };
  }

  componentDidMount() {
    this.updateStatus();
  }

  UNSAVE_componentWillReceiveProps(nextProps) {
    const { current: status, next } = this.computeStatuses(nextProps);

    this.nextStatus = next;
    if (status) this.setState({ status });
  }

  componentDidUpdate() {
    this.updateStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  handleStart = () => {
    const { duration } = this.props;
    const status = this.nextStatus;

    this.nextStatus = null;
    this.setState({ status, animating: true }, () => {
      const durationType = TRANSITION_TYPE[status];
      const durationValue = normalizeTransitionDuration(duration, durationType);

      _.invoke(this.props, 'onStart', null, { ...this.props, status });
      this.timeoutId = setTimeout(this.handleComplete, durationValue);
    });
  };

  handleComplete = () => {
    const { status: current } = this.state;

    _.invoke(this.props, 'onComplete', null, { ...this.props, status: current });

    if (this.nextStatus) {
      this.handleStart();
      return;
    }

    const status = this.computeCompletedStatus();
    const callback = current === Transition.ENTERING ? 'onShow' : 'onHide';

    this.setState({ status, animating: false }, () => {
      _.invoke(this.props, callback, null, { ...this.props, status });
    });
  };

  updateStatus = () => {
    const { animating } = this.state;

    if (this.nextStatus) {
      this.nextStatus = this.computeNextStatus();
      if (!animating) this.handleStart();
    }
  };

  computeCompletedStatus = () => {
    const { unmountOnHide } = this.props;
    const { status } = this.state;

    if (status === Transition.ENTERING) return Transition.ENTERED;
    return unmountOnHide ? Transition.UNMOUNTED : Transition.EXITED;
  };

  computeClasses = () => {
    const { animation, children } = this.props;
    const { animating, status } = this.state;

    const childClasses = _.get(children, 'props.className');
    const directional = _.includes(DIRECTIONAL_TRANSITIONS, animation);

    if (directional) {
      return cx(
        animation,
        childClasses,
        useKeyOnly(animating, 'animating'),
        useKeyOnly(status === Transition.ENTERING, 'in'),
        useKeyOnly(status === Transition.EXITING, 'out'),
        useKeyOnly(status === Transition.EXITED, 'hidden'),
        useKeyOnly(status !== Transition.EXITED, 'visible'),
        'transition'
      );
    }

    return cx(animation, childClasses, useKeyOnly(animating, 'animating transition'));
  };

  computeInitialStatuses = () => {
    const { visible, mountOnShow, transitionOnMount, unmountOnHide } = this.props;

    if (visible) {
      if (transitionOnMount) {
        return {
          initial: Transition.EXITED,
          next: Transition.ENTERING,
        };
      }
      return { initial: Transition.ENTERED };
    }

    if (mountOnShow || unmountOnHide) return { initial: Transition.UNMOUNTED };
    return { initial: Transition.EXITED };
  };

  computeNextStatus = () => {
    const { animating, status } = this.state;

    if (animating) return status === Transition.ENTERING ? Transition.EXITING : Transition.ENTERING;
    return status === Transition.ENTERED ? Transition.EXITING : Transition.ENTERING;
  };

  computeStatuses = (props) => {
    const { status } = this.state;
    const { visible } = props;

    if (visible) {
      return {
        current: status === Transition.UNMOUNTED && Transition.EXITED,
        next:
          status !== Transition.ENTERING && status !== Transition.ENTERED && Transition.ENTERING,
      };
    }

    return {
      next: (status === Transition.ENTERING || status === Transition.ENTERED) && Transition.EXITING,
    };
  };

  computeStyle = () => {
    const { children, duration } = this.props;
    const { status } = this.state;

    const childStyle = _.get(children, 'props.style');
    const type = TRANSITION_TYPE[status];
    const animationDuration = type && `${normalizeTransitionDuration(duration, type)}ms`;

    return { ...childStyle, animationDuration };
  };

  render() {
    const { children } = this.props;
    const { status } = this.state;

    if (status === Transition.UNMOUNTED) return null;
    return cloneElement(children, {
      className: this.computeClasses(),
      style: this.computeStyle(),
    });
  }
}

export default Transition;
