import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import scroll from 'scroll';
import ease from 'ease-component';

import classnames from 'classnames/bind';
import PrevArrow from '../PrevArrow';
import NextArrow from '../NextArrow';

import Remedy from './components/Remedy';

import styles from './SliderRemedies.module.scss';

const cx = classnames.bind(styles);

class SliderRemedies extends Component {
  componentDidMount() {
    if ('scrollBehavior' in document.documentElement.style) {
      this.scrollBehavior = true;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  scrollToDirection = (direction, id, arrow) => {
    const { windowHeight } = this.props;
    let widthContainer = 405;
    if (window.isTablet) {
      widthContainer = 475;
    } else if (windowHeight <= 750) {
      widthContainer = 405;
    } else {
      widthContainer = 476;
    }

    const far = widthContainer * direction;
    const pos = document.getElementById(id).scrollLeft + far;

    if (this.scrollBehavior) {
      document.getElementById(id).scroll({ left: pos, behavior: 'smooth' });
    } else {
      scroll.left(document.getElementById(id), pos, { ease: ease.inOutBounce });
    }

    if (
      (Math.ceil(document.getElementById(id).scrollLeft)
        === document.getElementById(id).scrollWidth - document.getElementById(id).clientWidth
        && arrow === 'next')
      || (Math.ceil(document.getElementById(id).scrollLeft) - 1
        === document.getElementById(id).scrollWidth - document.getElementById(id).clientWidth
        && arrow === 'next')
    ) {
      if (this.scrollBehavior) {
        document.getElementById(id).scroll({ left: 0, behavior: 'smooth' });
      } else {
        scroll.left(document.getElementById(id), 0, { ease: ease.inOutBounce });
      }
    }

    if (pos === widthContainer * -1) {
      if (this.scrollBehavior) {
        document.getElementById(id).scroll({
          left: document.getElementById(id).scrollWidth - document.getElementById(id).clientWidth,
          behavior: 'smooth',
        });
      } else {
        scroll.left(
          document.getElementById(id),
          document.getElementById(id).scrollWidth - document.getElementById(id).clientWidth,
          { ease: ease.inOutBounce }
        );
      }
    }
  };

  render() {
    const { remedies, handleClickRemedy, windowHeight, devicePrefix } = this.props;
    let widthStyle = null;

    if (remedies && remedies.length > 0 && remedies.length < 5) {
      if (window.isMobile && remedies.length < 4) {
        widthStyle = 356;
      } else if (!window.isMobile) {
        widthStyle = 118 * remedies.length;
      }
    }

    return (
      <div
        style={{ width: `${widthStyle}px` }}
        className={cx(`${devicePrefix}-remedy-container`, remedies && 'remedy-container-active')}
      >
        {window.isMobile ? (
          <div
            className={cx('remedy-elements-container', `${devicePrefix}-remedy-elements-container`)}
          >
            <div className={cx('remedy-elements-wrapper')}>
              {remedies
                && remedies.map((remedy, key) => (
                  <Remedy
                    key={remedy.id}
                    remedy={remedy}
                    onClickRemedy={handleClickRemedy}
                    devicePrefix={devicePrefix}
                  />
                ))}
            </div>
          </div>
        ) : (
          <div
            id="remedies_slider"
            className={cx(`${devicePrefix}-remedies-elements-scroller`)}
            style={{}}
          >
            <PrevArrow
              disabled={(remedies && remedies.length <= 4) || window.isTablet}
              id="remedy"
              customClassName={cx('remedy-arrow-prev')}
              toScroll={direction => this.scrollToDirection(direction, 'remedies_slider', 'prev')}
            />
            {remedies.map((remedy, key) => (
              <Remedy
                key={remedy.id}
                remedy={remedy}
                onClickRemedy={handleClickRemedy}
                devicePrefix={devicePrefix}
              />
            ))}
            <NextArrow
              disabled={(remedies && remedies.length <= 4) || window.isTablet}
              id="remedy"
              customClassName={cx('remedy-arrow-next')}
              toScroll={direction => this.scrollToDirection(direction, 'remedies_slider', 'next')}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SliderRemedies;
