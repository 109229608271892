import { handleActions } from 'redux-actions';
import _ from 'lodash';

import * as constants from './constants';

const initialState = {
  storeInformation: null,
  isFetchingStoreInformation: false,
  polygonsForStore: null,
  isFetchingPolygonsForStore: false,
  isFetchingDeliveryLocationData: false,
  deliveryLocationData: {},
  listWithDeliveryLocations: {},
  isFethchingListWithDeliveryLocation: false,
  isEditStoreInformation: false,
  storeImage: null,
  rerenderTable: false,
  isFetchingStoreProducts: false,
  listSortOfCategoriesWithProducts: null,
  productsOutOfStock: [],
  productsAvaiable: [],
  listCategories: [],
  formattedListProducts: [],
  formattedListBrands: [],
  isFetchingListOrders: false,
  listOrders: [],
  isFetchingShowOrder: false,
  order: null,
  updateOrder: false,
  isSearchingProduct: false,
  isSuccessAddProduct: false,
  ordersProcessing: 0,
  productsInStock: [],
  listSuggestProducts: [],
  isFetchingSuggestProducts: false,
  suggestProduct: null,
  isShowSuggestProduct: false,
  listStrains: [],
  isFetchingListStrains: false,
  listRemediesByStrain: [],
  isFetchingRemediesByStrain: false,
  activeCategoryItems: [],
  numberOfDeliveryLocations: null,
  numberOfOrdersPending: null,
  numberOfProductsInStock: null,
  updateTabWithPanes: false,
  isCreatingNewSuggestProduct: false,
};

export default handleActions(
  {
    [constants.UPDATE_STORE_INFORMATION]: (state, action) => ({
      ...state,
      isFetchingStoreInformation: true,
    }),

    [constants.UPDATE_STORE_INFORMATION_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingStoreInformation: false,
    }),

    [constants.UPDATE_STORE_INFORMATION_FAIL]: (state, action) => ({
      ...state,
      isFetchingStoreInformation: false,
    }),

    [constants.FETCH_STORE_INFORMATION]: (state, action) => ({
      ...state,
      isFetchingStoreInformation: true,
      storeImage: null,
    }),

    [constants.FETCH_STORE_INFORMATION_SUCCESS]: (state, action) => ({
      ...state,
      storeInformation: action.storeInformation,
      isFetchingStoreInformation: false,
      numberOfDeliveryLocations: action.storeInformation.number_of_delivery_locations,
      numberOfOrdersPending: action.storeInformation.orders_pending,
      numberOfProductsInStock: action.storeInformation.products_in_stock,
    }),

    [constants.FETCH_STORE_INFORMATION_FAIL]: (state, action) => ({
      ...state,
      isFetchingStoreInformation: false,
    }),

    [constants.CHANGE_STORE_INFORMATION]: (state, action) => {
      const storeInformation = _.clone(state.storeInformation);

      storeInformation[action.name] = action.value;

      return {
        ...state,
        storeInformation,
      };
    },

    [constants.FETCH_POLYGONS_FOR_STORE]: (state, action) => ({
      ...state,
      isFetchingPolygonsForStore: true,
    }),

    [constants.FETCH_POLYGONS_FOR_STORE_SUCCESS]: (state, action) => ({
      ...state,
      polygonsForStore: action.data,
      isFetchingPolygonsForStore: false,
      listWithDeliveryLocations: {},
    }),

    [constants.FETCH_POLYGONS_FOR_STORE_FAIL]: (state, action) => ({
      ...state,
      isFetchingPolygonsForStore: false,
    }),

    [constants.FETCH_DELIVERY_LOCATION_DATA]: (state, action) => ({
      ...state,
      isFetchingDeliveryLocationData: true,
    }),

    [constants.FETCH_DELIVERY_LOCATION_DATA_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingDeliveryLocationData: false,
      deliveryLocationData: action.deliveryLocationData,
    }),

    [constants.FETCH_DELIVERY_LOCATION_DATA_FAIL]: (state, action) => ({
      ...state,
      isFetchingDeliveryLocationData: false,
    }),

    [constants.SET_LIST_WITH_DELIVERY_LOCATIONS]: (state, action) => ({
      isFethchingListWithDeliveryLocation: true,
    }),

    [constants.SET_LIST_WITH_DELIVERY_LOCATIONS_SUCCESS]: (state, action) => ({
      ...state,
      listWithDeliveryLocations: action.listWithDeliveryLocations,
      isFethchingListWithDeliveryLocation: false,
      numberOfDeliveryLocations: action.numberOfDeliveryLocations,
    }),

    [constants.SET_LIST_WITH_DELIVERY_LOCATIONS_FAIL]: (state, action) => ({
      isFethchingListWithDeliveryLocation: false,
    }),

    [constants.DELETE_DELIVERY_LOCATION]: (state, action) => ({
      ...state,
      isFethchingListWithDeliveryLocation: true,
    }),

    [constants.DELETE_DELIVERY_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      isFethchingListWithDeliveryLocation: false,
      listWithDeliveryLocations: {},
    }),

    [constants.CREATE_DELIVERY_LOCATION]: (state, action) => ({
      ...state,
      listWithDeliveryLocations: {},
    }),

    [constants.CREATE_DELIVERY_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      listWithDeliveryLocations: action.listWithDeliveryLocations,
      numberOfDeliveryLocations: action.numberOfDeliveryLocations,
    }),

    [constants.EDIT_STORE_INFORMATION]: (state, action) => ({
      ...state,
      isEditStoreInformation: action.value,
    }),

    [constants.SET_NEW_STORE_LOGO]: (state, action) => ({
      ...state,
      storeImage: action.image,
    }),

    [constants.UPDATE_DELIVERY_LOCATION]: (state, action) => ({
      ...state,
      rerenderTable: true,
    }),

    [constants.UPDATE_DELIVERY_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      rerenderTable: false,
      listWithDeliveryLocations: action.listWithDeliveryLocations,
    }),

    [constants.UPDATE_DELIVERY_LOCATION_FAIL]: (state, action) => ({
      ...state,
      rerenderTable: false,
    }),

    [constants.FETCH_STORE_PRODUCTS]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
    }),

    [constants.FETCH_STORE_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      listSortOfCategoriesWithProducts: action.payload,
      isFetchingStoreProducts: false,
      productsOutOfStock: action.productsOutOfStock,
      productsAvaiable: action.productsAvaiable,
      productsInStock: action.productsInStock,
      activeCategoryItems: action.activeCategoryItems,
    }),

    [constants.FETCH_STORE_PRODUCTS_FAIL]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: false,
    }),

    [constants.SORT_CATEGORIES]: (state, action) => ({
      ...state,
      listSortOfCategoriesWithProducts: action.payload,
    }),

    [constants.UPDATE_STORE_PRODUCT]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
    }),

    [constants.UPDATE_STORE_PRODUCT_SUCCESS]: (state, action) => {
      const { listSortOfCategoriesWithProducts } = state;
      const newProductsInStock = [];
      const newProductsOutOfStock = [];

      listSortOfCategoriesWithProducts.forEach((elements, index) => {
        elements.products.forEach((element, key) => {
          if (element.id === action.productInfo.id) {
            elements.products[key] = action.productInfo;
          }
        });
      });

      listSortOfCategoriesWithProducts.forEach((el) => {
        el.products.forEach((product) => {
          if (product.out_of_stock) {
            newProductsOutOfStock.push(product);
          } else {
            newProductsInStock.push(product);
          }
        });
      });

      return {
        ...state,
        listSortOfCategoriesWithProducts,
        isFetchingStoreProducts: false,
        productsInStock: newProductsInStock,
        productsOutOfStock: newProductsOutOfStock,
        numberOfProductsInStock: newProductsInStock.length,
      };
    },

    [constants.DELETE_STORE_PRODUCT]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
    }),

    [constants.DELETE_STORE_PRODUCT_SUCCESS]: (state, action) => {
      const { listSortOfCategoriesWithProducts } = state;
      const newProductsInStock = [];
      const newProductsOutOfStock = [];

      listSortOfCategoriesWithProducts.forEach((elements, index) => {
        elements.products.forEach((element, key) => {
          if (element.id === action.id) {
            elements.products.splice(key, 1);
          }
        });

        if (elements.products.length === 0) {
          listSortOfCategoriesWithProducts.splice(index, 1);
        }
      });

      listSortOfCategoriesWithProducts.forEach((el) => {
        el.products.forEach((product) => {
          if (product.out_of_stock) {
            newProductsOutOfStock.push(product);
          } else {
            newProductsInStock.push(product);
          }
        });
      });

      return {
        ...state,
        listSortOfCategoriesWithProducts,
        isFetchingStoreProducts: false,
        productsInStock: newProductsInStock,
        productsOutOfStock: newProductsOutOfStock,
        numberOfProductsInStock: newProductsInStock.length,
      };
    },

    [constants.FETCH_LIST_OF_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      listCategories: action.payload,
    }),

    [constants.FETCH_LIST_BRANDS_SUCCESS]: (state, action) => ({
      ...state,
      formattedListBrands: action.formattedListBrands,
    }),

    [constants.SEARCH_PRODUCT]: (state, action) => ({
      ...state,
      isSearchingProduct: true,
    }),

    [constants.SEARCH_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
      formattedListProducts: action.formattedListProducts,
      isSearchingProduct: false,
    }),

    [constants.FETCH_LIST_ORDERS]: (state, action) => ({
      ...state,
      isFetchingListOrders: true,
    }),

    [constants.FETCH_LIST_ORDERS_SUCCESS]: (state, action) => ({
      ...state,
      listOrders: action.listOrders,
      isFetchingListOrders: false,
      numberOfOrdersPending: action.ordersProcessing,
    }),

    [constants.FETCH_LIST_ORDERS_FAIL]: (state, action) => ({
      ...state,
      isFetchingListOrders: false,
    }),

    [constants.SHOW_ORDER]: (state, action) => ({
      ...state,
      isFetchingShowOrder: true,
    }),

    [constants.SHOW_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      order: action.order,
      isFetchingShowOrder: false,
    }),

    [constants.SHOW_ORDER_FAIL]: (state, action) => ({
      ...state,
      isFetchingShowOrder: false,
    }),

    [constants.UPDATE_ORDER]: (state, action) => ({
      ...state,
      isFetchingListOrders: true,
      order: null,
      updateTabWithPanes: true,
    }),

    [constants.UPDATE_ORDER_SUCCESS]: (state, action) => {
      const { listOrders } = state;
      const { brand, creation_date, user_email, final_order_price, id, status } = action.order;

      const newListOrders = listOrders.map(order => (order.id === id
        ? {
          brand,
          creation_date,
          email: user_email,
          final_order_price,
          id,
          status,
        } : order));

      const newOrdersProcessing = newListOrders.filter(v => v.status === 'processing');

      return {
        ...state,
        listOrders: newListOrders,
        isFetchingListOrders: false,
        numberOfOrdersPending: newOrdersProcessing.length,
        updateTabWithPanes: false,
      };
    },

    [constants.ADD_PRODUCT]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
      isSuccessAddProduct: true,
    }),

    [constants.ADD_PRODUCT_SUCCESS]: (state, action) => {
      const { listSortOfCategoriesWithProducts, activeCategoryItems } = state;

      const filterListWithProducts = listSortOfCategoriesWithProducts.filter(element => element.name === action.product.category.name);
      let newListSortOfCategores = [];
      let newActiveCategoryItems = [];

      if (filterListWithProducts.length > 0) {
        newActiveCategoryItems = [...activeCategoryItems];
        newListSortOfCategores = listSortOfCategoriesWithProducts.map((element) => {
          if (element.name === action.product.category.name) {
            element.products.push(action.product);
          }
          return element;
        });
      } else {
        newActiveCategoryItems = [...activeCategoryItems, action.product.category.name];
        newListSortOfCategores = [...listSortOfCategoriesWithProducts];
        newListSortOfCategores.push({
          products: [action.product],
          position: action.product.category.position,
          name: action.product.category.name,
          id: action.product.category.id,
        });
      }

      const productsInStock = [];
      const productsOutOfStock = [];

      newListSortOfCategores.forEach((el) => {
        el.products.forEach((v) => {
          if (v.out_of_stock) {
            productsOutOfStock.push(v);
          } else {
            productsInStock.push(v);
          }
        });
      });

      return {
        ...state,
        listSortOfCategoriesWithProducts: newListSortOfCategores,
        isFetchingStoreProducts: false,
        isSuccessAddProduct: false,
        numberOfProductsInStock: productsInStock.length,
        activeCategoryItems: newActiveCategoryItems,
      };
    },

    [constants.MASS_UPDATE_PRODUCTS]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
    }),

    [constants.MASS_UPDATE_PRODUCTS_SUCCESS]: (state, action) => {
      const { listSortOfCategoriesWithProducts } = state;

      listSortOfCategoriesWithProducts.forEach((elements, index) => {
        elements.products.forEach((product, key) => {
          Object.keys(action.outOfStock).forEach((value) => {
            if (value == product.id) {
              product.out_of_stock = action.outOfStock[value];
            }
          });
        });
      });

      const productsInStock = [];
      const productsOutOfStock = [];

      listSortOfCategoriesWithProducts.forEach((el) => {
        el.products.forEach((v) => {
          if (v.out_of_stock) {
            productsOutOfStock.push(v);
          } else {
            productsInStock.push(v);
          }
        });
      });

      return {
        ...state,
        listSortOfCategoriesWithProducts,
        isFetchingStoreProducts: false,
        productsInStock,
        productsOutOfStock,
        numberOfProductsInStock: productsInStock.length,
      };
    },

    [constants.MASS_UPDATE_PRODUCTS_FAIL]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: false,
    }),

    [constants.MASS_DELETE_PRODUCTS]: (state, action) => ({
      ...state,
      isFetchingStoreProducts: true,
    }),

    [constants.MASS_DELETE_PRODUCTS_SUCCESS]: (state, action) => {
      const { listSortOfCategoriesWithProducts } = state;
      listSortOfCategoriesWithProducts.forEach((elements, index) => {
        _.remove(elements.products, product => action.ids.indexOf(product.id) !== -1);
        if (elements.products.length === 0) {
          listSortOfCategoriesWithProducts.splice(index, 1);
        }
      });
      const productsInStock = [];
      const productsOutOfStock = [];

      listSortOfCategoriesWithProducts.forEach((el) => {
        el.products.forEach((v) => {
          if (v.out_of_stock) {
            productsOutOfStock.push(v);
          } else {
            productsInStock.push(v);
          }
        });
      });

      return {
        ...state,
        listSortOfCategoriesWithProducts,
        isFetchingStoreProducts: false,
        productsInStock,
        productsOutOfStock,
        numberOfProductsInStock: productsInStock.length,
      };
    },

    [constants.FETCH_SUGGEST_PRODUCTS]: (state, action) => ({
      ...state,
      isFetchingSuggestProducts: true,
    }),

    [constants.FETCH_SUGGEST_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      listSuggestProducts: action.listSuggestProducts,
      isFetchingSuggestProducts: false,
    }),

    [constants.FETCH_SUGGEST_PRODUCTS_FAIL]: (state, action) => ({
      ...state,
      isFetchingSuggestProducts: false,
    }),

    [constants.SHOW_SUGGEST_PRODUCT]: (state, action) => ({
      ...state,
      isShowSuggestProduct: true,
    }),

    [constants.SHOW_SUGGEST_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
      suggestProduct: action.suggestProduct,
      isShowSuggestProduct: false,
    }),

    [constants.SHOW_SUGGEST_PRODUCT_FAIL]: (state, action) => ({
      ...state,
      isShowSuggestProduct: false,
    }),

    [constants.FETCH_LIST_OF_ALL_STRAINS]: (state, action) => ({
      ...state,
      isFetchingListStrains: true,
    }),

    [constants.FETCH_LIST_OF_ALL_STRAINS_SUCCESS]: (state, action) => ({
      ...state,
      listStrains: action.listStrains,
      isFetchingListStrains: false,
    }),

    [constants.FETCH_LIST_OF_ALL_STRAINS_FAIL]: (state, action) => ({
      ...state,
      isFetchingListStrains: false,
    }),

    [constants.FETCH_REMEDIES_BY_STRAIN]: (state, action) => ({
      ...state,
      isFetchingRemediesByStrain: true,
    }),

    [constants.FETCH_REMEDIES_BY_STRAIN_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingRemediesByStrain: false,
      listRemediesByStrain: action.listRemediesByStrain,
    }),

    [constants.FETCH_REMEDIES_BY_STRAIN_FAIL]: (state, action) => ({
      ...state,
      isFetchingRemediesByStrain: false,
    }),

    [constants.SET_ACTIVE_CATEGORY_ITEMS]: (state, action) => ({
      ...state,
      activeCategoryItems: action.activeCategoryItems,
    }),

    [constants.CREATE_NEW_SUGGEST_PRODUCT]: (state, action) => ({
      ...state,
      isCreatingNewSuggestProduct: true,
    }),

    [constants.CREATE_NEW_SUGGEST_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
      isCreatingNewSuggestProduct: false,
    }),

    [constants.CREATE_NEW_SUGGEST_PRODUCT_FAIL]: (state, action) => ({
      ...state,
      isCreatingNewSuggestProduct: false,
    }),

  },
  initialState
);
