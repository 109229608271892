/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Comment.css';

const cx = classnames.bind(styles);

const CommentAction = (props) => {
  const { active, className, children, content } = props;

  const classes = cx(useKeyOnly(active, 'active'), className);
  const rest = getUnhandledProps(CommentAction, props);
  const ElementType = getElementType(CommentAction, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

CommentAction.defaultProps = {
  as: 'a',
};

export default CommentAction;
