const remedyColors = [
  '92c3da',
  'a4639e',
  '81d2cd',
  'e18295',
  '837a9b',
  '9fc6ee',
  'cf7867',
  '97e3ea',
  '81d7c9',
  'd7aa9a'
];

const getRemedyColor = id => {
  if (id > 9) {
    return remedyColors[id % 10];
  }
  return remedyColors[id];
};

export default getRemedyColor;
