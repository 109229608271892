import React, { Component } from 'react';

import { createShorthandFactory, getUnhandledProps, isBrowser } from 'utils/lib';
import Portal from 'components/Addons/Portal/Portal';
import DimmerInner from './DimmerInner';

class Dimmer extends Component {
  render() {
    const { active, page } = this.props;
    const rest = getUnhandledProps(Dimmer, this.props);

    if (page) {
      return (
        <Portal
          closeOnEscape={false}
          closeOnDocumentClick={false}
          onMount={this.handlePortalMount}
          onUnmount={this.handlePortalUnmount}
          open={active}
          opentOnTriggerClick={false}
        >
          <DimmerInner {...rest} active={active} page={page} />
        </Portal>
      );
    }

    return <DimmerInner {...rest} active={active} page={page} />;
  }
}

Dimmer.create = createShorthandFactory(Dimmer, value => ({ content: value }));

export default Dimmer;
