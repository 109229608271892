import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import styles from './Popup.css';

const cx = classnames.bind(styles);

const PopupHeader = (props) => {
  const { children, className, content } = props;
  const classes = cx('header', className);
  const rest = getUnhandledProps(PopupHeader, props);
  const ElementType = getElementType(PopupHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

PopupHeader.create = createShorthandFactory(PopupHeader, children => ({ children }));

export default PopupHeader;
