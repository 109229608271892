import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';

import styles from './Item.scss';

const cx = classnames.bind(styles);

const ItemDescription = (props) => {
  const { children, className, content } = props;
  const classes = cx('description', className);
  const rest = getUnhandledProps(ItemDescription, props);
  const ElementType = getElementType(ItemDescription, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

ItemDescription.create = createShorthandFactory(ItemDescription, content => ({ content }));

export default ItemDescription;
