export const FETCH_STORE_INFORMATION = 'FETCH_STORE_INFORMATION';
export const FETCH_STORE_INFORMATION_SUCCESS = 'FETCH_STORE_INFORMATION_SUCCESS';
export const FETCH_STORE_INFORMATION_FAIL = 'FETCH_STORE_INFORMATION_FAIL';

export const CHANGE_STORE_INFORMATION = 'CHANGE_STORE_INFORMATION';

export const UPDATE_STORE_INFORMATION = 'UPDATE_STORE_INFORMATION';
export const UPDATE_STORE_INFORMATION_SUCCESS = 'UPDATE_STORE_INFORMATION_SUCCESS';
export const UPDATE_STORE_INFORMATION_FAIL = 'UPDATE_STORE_INFORMATION_FAIL';

export const FETCH_POLYGONS_FOR_STORE = 'FETCH_POLYGONS_FOR_STORE';
export const FETCH_POLYGONS_FOR_STORE_SUCCESS = 'FETCH_POLYGONS_FOR_STORE_SUCCESS';
export const FETCH_POLYGONS_FOR_STORE_FAIL = 'FETCH_POLYGONS_FOR_STORE_FAIL';

export const FETCH_DELIVERY_LOCATION_DATA = 'FETCH_DELIVERY_LOCATION_DATA';
export const FETCH_DELIVERY_LOCATION_DATA_SUCCESS = 'FETCH_DELIVERY_LOCATION_DATA_SUCCESS';
export const FETCH_DELIVERY_LOCATION_DATA_FAIL = 'FETCH_DELIVERY_LOCATION_DATA_FAIL';

export const CREATE_DELIVERY_LOCATION = 'CREATE_DELIVERY_LOCATION';
export const CREATE_DELIVERY_LOCATION_SUCCESS = 'CREATE_DELIVERY_LOCATION_SUCCESS';
export const CREATE_DELIVERY_LOCATION_FAIL = 'CREATE_DELIVERY_LOCATION_FAIL';

export const DELETE_DELIVERY_LOCATION = 'DELETE_DELIVERY_LOCATION';
export const DELETE_DELIVERY_LOCATION_SUCCESS = 'DELETE_DELIVERY_LOCATION_SUCCESS';
export const DELETE_DELIVERY_LOCATION_FAIL = 'DELETE_DELIVERY_LOCATION_FAIL';

export const UPDATE_DELIVERY_LOCATION = 'UPDATE_DELIVERY_LOCATION';
export const UPDATE_DELIVERY_LOCATION_SUCCESS = 'UPDATE_DELIVERY_LOCATION_SUCCESS';
export const UPDATE_DELIVERY_LOCATION_FAIL = 'UPDATE_DELIVERY_LOCATION_FAIL';

export const SET_LIST_WITH_DELIVERY_LOCATIONS = 'SET_LIST_WITH_DELIVERY_LOCATIONS';
export const SET_LIST_WITH_DELIVERY_LOCATIONS_SUCCESS = 'SET_LIST_WITH_DELIVERY_LOCATIONS_SUCCESS';
export const SET_LIST_WITH_DELIVERY_LOCATIONS_FAIL = 'SET_LIST_WITH_DELIVERY_LOCATIONS_FAIL';

export const FETCH_DELIVERY_LOCATION_IDS = 'FETCH_DELIVERY_LOCATION_IDS';
export const FETCH_DELIVERY_LOCATION_IDS_SUCCESS = 'FETCH_DELIVERY_LOCATION_IDS_SUCCESS';
export const FETCH_DELIVERY_LOCATION_IDS_FAIL = 'FETCH_DELIVERY_LOCATION_IDS_FAIL';

export const EDIT_STORE_INFORMATION = 'EDIT_STORE_INFORMATION';

export const SET_NEW_STORE_LOGO = 'SET_NEW_STORE_LOGO';
export const FETCH_STORE_PRODUCTS = 'FETCH_STORE_PRODUCTS';
export const FETCH_STORE_PRODUCTS_SUCCESS = 'FETCH_STORE_PRODUCTS_SUCCESS';
export const FETCH_STORE_PRODUCTS_FAIL = 'FETCH_STORE_PRODUCTS_FAIL';

export const FETCH_LIST_OF_CATEGORY = 'FETCH_LIST_OF_CATEGORY';
export const FETCH_LIST_OF_CATEGORY_SUCCESS = 'FETCH_LIST_OF_CATEGORY_SUCCESS';
export const FETCH_LIST_OF_CATEGORY_FAIL = 'FETCH_LIST_OF_CATEGORY_FAIL';

export const SORT_PRODUCTS = 'SORT_PRODUCTS';
export const SORT_PRODUCTS_SUCCESS = 'SORT_PRODUCTS_SUCCESS';
export const SORT_PRODUCTS_FAIL = 'SORT_PRODUCTS_FAIL';

export const SORT_CATEGORIES = 'SORT_CATEGORIES';
export const SORT_CATEGORIES_SUCCESS = 'SORT_CATEGORIES_SUCCESS';
export const SORT_CATEGORIES_FAIL = 'SORT_CATEGORIES_FAIL';

export const UPDATE_STORE_PRODUCT = 'UPDATE_STORE_PRODUCT';
export const UPDATE_STORE_PRODUCT_SUCCESS = 'UPDATE_STORE_PRODUCT_SUCCESS';
export const UPDATE_STORE_PRODUCT_FAIL = 'UPDATE_STORE_PRODUCT_FAIL';

export const DELETE_STORE_PRODUCT = 'DELETE_STORE_PRODUCT';
export const DELETE_STORE_PRODUCT_SUCCESS = 'DELETE_STORE_PRODUCT_SUCCESS';
export const DELETE_STORE_PRODUCT_FAIL = 'DELETE_STORE_PRODUCT_FAIL';

export const FETCH_LIST_BRANDS = 'FETCH_LIST_BRANDS';
export const FETCH_LIST_BRANDS_SUCCESS = 'FETCH_LIST_BRANDS_SUCCESS';
export const FETCH_LIST_BRANDS_FAIL = 'FETCH_LIST_BRANDS_FAIL';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_FAIL = 'SEARCH_PRODUCT_FAIL';

export const FETCH_LIST_ORDERS = 'FETCH_LIST_ORDERS';
export const FETCH_LIST_ORDERS_SUCCESS = 'FETCH_LIST_ORDERS_SUCCESS';
export const FETCH_LIST_ORDERS_FAIL = 'FETCH_LIST_ORDERS_FAIL';

export const SHOW_ORDER = 'SHOW_ORDER';
export const SHOW_ORDER_SUCCESS = 'SHOW_ORDER_SUCCESS';
export const SHOW_ORDER_FAIL = 'SHOW_ORDER_FAIL';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const MASS_UPDATE_PRODUCTS = 'MASS_UPDATE_PRODUCTS';
export const MASS_UPDATE_PRODUCTS_SUCCESS = 'MASS_UPDATE_PRODUCTS_SUCCESS';
export const MASS_UPDATE_PRODUCTS_FAIL = 'MASS_UPDATE_PRODUCTS_FAIL';

export const MASS_DELETE_PRODUCTS = 'MASS_DELETE_PRODUCTS';
export const MASS_DELETE_PRODUCTS_SUCCESS = 'MASS_DELETE_PRODUCTS_SUCCESS';
export const MASS_DELETE_PRODUCTS_FAIL = 'MASS_DELETE_PRODUCTS_FAIL';

export const FETCH_SUGGEST_PRODUCTS = 'FETCH_SUGGEST_PRODUCTS';
export const FETCH_SUGGEST_PRODUCTS_SUCCESS = 'FETCH_SUGGEST_PRODUCTS_SUCCESS';
export const FETCH_SUGGEST_PRODUCTS_FAIL = 'FETCH_SUGGEST_PRODUCTS_FAIL';

export const SHOW_SUGGEST_PRODUCT = 'SHOW_SUGGEST_PRODUCT';
export const SHOW_SUGGEST_PRODUCT_SUCCESS = 'SHOW_SUGGEST_PRODUCT_SUCCESS';
export const SHOW_SUGGEST_PRODUCT_FAIL = 'SHOW_SUGGEST_PRODUCT_FAIL';

export const DELETE_SUGGEST_PRODUCT = 'DELETE_SUGGEST_PRODUCT';
export const DELETE_SUGGEST_PRODUCT_SUCCESS = 'DELETE_SUGGEST_PRODUCT_SUCCESS';
export const DELETE_SUGGEST_PRODUCT_FAIL = 'DELETE_SUGGEST_PRODUCT_FAIL';

export const FETCH_LIST_OF_ALL_STRAINS = 'FETCH_LIST_OF_ALL_STRAINS';
export const FETCH_LIST_OF_ALL_STRAINS_SUCCESS = 'FETCH_LIST_OF_ALL_STRAINS_SUCCESS';
export const FETCH_LIST_OF_ALL_STRAINS_FAIL = 'FETCH_LIST_OF_ALL_STRAINS_FAIL';

export const FETCH_REMEDIES_BY_STRAIN = 'FETCH_REMEDIES_BY_STRAIN';
export const FETCH_REMEDIES_BY_STRAIN_SUCCESS = 'FETCH_REMEDIES_BY_STRAIN_SUCCESS';
export const FETCH_REMEDIES_BY_STRAIN_FAIL = 'FETCH_REMEDIES_BY_STRAIN_FAIL';

export const SET_ACTIVE_CATEGORY_ITEMS = 'SET_ACTIVE_CATEGORY_ITEMS';

export const CREATE_NEW_SUGGEST_PRODUCT = 'CREATE_NEW_SUGGEST_PRODUCT';
export const CREATE_NEW_SUGGEST_PRODUCT_SUCCESS = 'CREATE_NEW_SUGGEST_PRODUCT_SUCCESS';
export const CREATE_NEW_SUGGEST_PRODUCT_FAIL = 'CREATE_NEW_SUGGEST_PRODUCT_FAIL';

export const UPDATE_SUGGEST_PRODUCT = 'UPDATE_SUGGEST_PRODUCT';
export const UPDATE_SUGGEST_PRODUCT_SUCCESS = 'UPDATE_SUGGEST_PRODUCT_SUCCESS';
export const UPDATE_SUGGEST_PRODUCT_FAIL = 'UPDATE_SUGGEST_PRODUCT_FAIL';
