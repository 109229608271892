import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
} from 'utils/lib';

import styles from './Loader.css';

const cx = classnames.bind(styles);

const Loader = (props) => {
  const {
    active,
    children,
    className,
    content,
    disabled,
    indeterminate,
    inline,
    inverted,
    size,
  } = props;

  const classes = cx(
    'ui',
    size,
    useKeyOnly(active, 'active'),
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(indeterminate, 'indeterminate'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(children || content, 'text'),
    useKeyOrValueAndKey(inline, 'inline'),
    'loader',
    className
  );

  const rest = getUnhandledProps(Loader, props);
  const ElementType = getElementType(Loader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default Loader;
