export const ON_SIGN_IN = 'ON_SIGN_IN';
export const ON_SIGN_IN_SUCCESS = 'ON_SIGN_IN_SUCCESS';
export const ON_SIGN_IN_FAIL = 'ON_SIGN_IN_FAIL';

export const ON_SIGN_UP = 'ON_SIGN_UP';
export const ON_SIGN_UP_SUCCESS = 'ON_SIGN_UP_SUCCESS';
export const ON_SIGN_UP_FAIL = 'ON_SIGN_UP_FAIL';

export const REMOVE_CURRENT_STATE = 'REMOVE_CURRENT_STATE';
export const OPEN_MODAL_WINDOW = 'OPEN_MODAL_WINDOW';

export const ON_SIGN_OUT = 'ON_SIGN_OUT';
export const ON_SIGN_OUT_SUCCESS = 'ON_SIGN_OUT_SUCCESS';
export const ON_SIGN_OUT_FAIL = 'ON_SIGN_OUT_FAIL';

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAIL = 'FETCH_USER_INFO_FAIL';

export const RENDER_FORM = 'RENDER_FORM';

export const USER_NOT_AUTHORIZE = 'USER_NOT_AUTHORIZE';

export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
export const CANCEL_CHANGE_USER_PROFILE = 'CANCEL_CHANGE_USER_PROFILE';

export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_FAIL = 'SAVE_USER_PROFILE_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const OPEN_CHANGE_PASSWORD_MODAL_WINDOW =
  'OPEN_CHANGE_PASSWORD_MODAL_WINDOW';

export const SEND_NEW_PASSWORD = 'SEND_NEW_PASSWORD';
export const SEND_NEW_PASSWORD_SUCCESS = 'SEND_NEW_PASSWORD_SUCCESS';
export const SEND_NEW_PASSWORD_FAIL = 'SEND_NEW_PASSWORD_FAIL';

export const RENDER_SIGN_UP_FORM = 'RENDER_SIGN_UP_FORM';

export const RESENT_CODE = 'RESENT_CODE';
export const RESENT_CODE_SUCCESS = 'RESENT_CODE_SUCCESS';
export const RESENT_CODE_FAIL = 'RESENT_CODE_FAIL';

export const AUTH_BROWSER_CODE = 'AUTH_BROWSER_CODE';
export const AUTH_BROWSER_CODE_SUCCESS = 'AUTH_BROWSER_CODE_SUCCESS';
export const AUTH_BROWSER_CODE_FAIL = 'AUTH_BROWSER_CODE_FAIL';

export const RENDER_TWO_FACTOR_FORM = 'RENDER_TWO_FACTOR_FORM';

export const SEND_AUTHORIZATION_HASH = 'SEND_AUTHORIZATION_HASH';
export const SEND_AUTHORIZATION_HASH_SUCCESS = 'SEND_AUTHORIZATION_HASH_SUCCESS';
export const SEND_AUTHORIZATION_HASH_FAIL = 'SEND_AUTHORIZATION_HASH_FAIL';
