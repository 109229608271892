import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import Segment from 'components/Segment/Segment';

import styles from './Tab.css';

const cx = classnames.bind(styles);

const TabPane = (props) => {
  const { active, children, className, content, loading } = props;

  const classes = cx(useKeyOnly(active, 'active'), useKeyOnly(loading, 'loading'), 'tab', className);
  const rest = getUnhandledProps(TabPane, props);
  const ElementType = getElementType(TabPane, props);

  const calculatedDefaultProps = {};
  if (ElementType === Segment) {
    calculatedDefaultProps.attached = 'bottom';
  }

  return (
    <ElementType {...calculatedDefaultProps} {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

TabPane.defaultProps = {
  as: Segment,
  active: true,
};

TabPane.create = createShorthandFactory(TabPane, content => ({ content }));

export default TabPane;
