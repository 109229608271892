import React from 'react';

import Segment from 'components/Segment/Segment';
import Statistic from 'components/Typography/Statistic/Statistic';
import StatisticValue from 'components/Typography/Statistic/StatisticValue';
import StatisticLabel from 'components/Typography/Statistic/StatisticLabel';

import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';


const PanelTools = props => (
  <Segment raised loading={props.isFetchingSuggestProducts}>
    <Statistic horizontal style={{ margin: '0em' }}>
      <StatisticValue>{props.listSuggestProducts.length}</StatisticValue>
      <StatisticLabel>suggest products</StatisticLabel>
    </Statistic>
    <Button style={{ marginTop: '0.25em' }} floated="right" color="teal" onClick={props.createSuggestProduct}><Icon name="leaf" />Create product</Button>
  </Segment>
);

export default PanelTools;
