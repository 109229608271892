import React, { Component, Fragment } from 'react';
import { SortableElement, SortableHandle, SortableContainer } from 'react-sortable-hoc';
import classnames from 'classnames/bind';
import arrayMove from 'array-move';

import Icon from 'components/Icons/Icon';

import Checkbox from 'components/CheckBox/Checkbox';
import Image from 'components/Image/Image';
import Rating from 'components/Rating';

import styles from './SortableCategoryItem.module.scss';

const cx = classnames.bind(styles);

const DragHandle = SortableHandle(() => <Icon name="align justify" style={{ position: 'absolute', left: 0, top: 0, padding: '1em', cursor: 'grab' }} />);

const colors = {
  Sativa: {
    color: '#FDD835',
  },
  Indica: {
    color: '#5094B1'
  },
  Hybrid: {
    color: '#39D4CF',
  },
  CBD: {
    color: '#edb3bf',
  },
  'Hybrid: Sativa-dominant': {
    background: 'linear-gradient(90deg, #39D4CF 10%, #FDD835 50%)',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-background-clip': 'text',
  },
  'Hybrid: Indica-dominant': {
    background: 'linear-gradient(90deg, #39D4CF 10%, #5094B1 50%)',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-background-clip': 'text',
  },
  'Indica: Sativa-dominant': {
    background: 'linear-gradient(90deg, #5094B1 10%, #FDD835 50%)',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-background-clip': 'text',
  }
}

const animations = [
  {
    animationDelay: '-0.75s',
    animationDuration: '.25s',
  },
  {
    animationDelay: '-0.5s',
    animationDuration: '.3s',
  },
  {
    animationDelay: '-0.05s',
    animationDuration: '.27s',
  },
  {
    animationDelay: '-0.2s',
    animationDuration: '.33s',
  },
  {
    animationDelay: '-0.31s',
    animationDuration: '.24s',
  },
  {
    animationDelay: '-0.15s',
    animationDuration: '.25s',
  },
  {
    animationDelay: '-0.2s',
    animationDuration: '.22s',
  },
  {
    animationDelay: '-0.3s',
    animationDuration: '.25s',
  },
  {
    animationDelay: '-0.22s',
    animationDuration: '.3s',
  },
  {
    animationDelay: '-0.05s',
    animationDuration: '.27s',
  },
];

const returnAnimationDelay = (index) => {
  if (index > 9) {
    return animations[index % 10].animationDelay;
  }
  return animations[index].animationDelay;
}

const returnAnimationDuration = (index) => {
  if (index > 9) {
    return animations[index % 10].animationDuration;
  }
  return animations[index].animationDuration;
}

const ItemValue = SortableElement(props => (
  // { props.shouldUseDragHandle && <Handle /> }
  <div
    className={cx(props.animateSelectAllProducts ? 'vibrate-1' : '', props.triggerOutOfStock && props.value.out_of_stock ? 'shadow-drop-2-center' : '', 'product-card', props.arrWithProductsForDelete && props.arrWithProductsForDelete.indexOf(props.value.id) >= 0 && 'delete-animate')}
    style={{
      ...props.style,
      borderBottom: props.value.out_of_stock ? '2px solid #db2828' : '2px solid #fafafa',
      animationDelay: props.animateSelectAllProducts && returnAnimationDelay(props.sortIndex),
      animationDuration: props.animateSelectAllProducts && returnAnimationDuration(props.sortIndex),
    }}
  >
    <Image
      src={props.value.image && props.value.image.wide}
      label={props.value.out_of_stock
        ? {
          as: 'span',
          content: 'out of stock',
          className: cx('label-out-of-stock')
        } : null}
      className={props.value.out_of_stock ? cx('filter-image') : null}
      style={{
        minHeight: '85px',
        minWidth: '140px',
      }}
    />
    {!props.animateSelectAllProducts && <DragHandle />}
    <div className={cx('product-content')}>
      <div className={cx('product-name')} style={{ ...colors[props.value.plant_type] }}>
        {props.value.name}
      </div>
    </div>
    <div style={{ backgroundColor: '#fafafa', display: 'flex', paddingLeft: '1em', alignItems: 'center' }}>
      <div className={cx('value-rate')}>{props.value.averege_rate !== '-' && props.value.averege_rate}</div>
      <Rating size="small" maxRating={5} color="teal" icon="star" disabled rating={props.value.averege_rate} />
      <div className={cx('value-comment')}>({props.value.number_approved_comments})</div>
    </div>
    <div className={cx('card-footer')} style={{ backgroundColor: '#fafafa', padding: '0.5em', paddingRight: '1em', paddingLeft: '1em' }}>
      <Checkbox checked={props.checkedProducts.indexOf(props.value.id) >= 0} onClick={(e, data) => props.onToggleChecked(e, data, props.value.id)} />
      <a className={cx('edit-product')} onClick={() => props.openModalWithEditProduct(props.value)}>Edit</a>
    </div>
  </div>
));

const SortableList = SortableContainer(
  ({ className,
    items,
    itemClass,
    shouldUseDragHandle,
    openModalWithEditProduct,
    animateSelectAllProducts,
    triggerOutOfStock,
    onToggleChecked,
    checkedProducts,
    arrWithProductsForDelete
  }) => (
      <div style={{ display: 'table', margin: '0 auto' }}>
        <div className={cx('items-container')}>
          {
            items.map((value, index) => (
              <ItemValue
                key={`item-${index}`}
                className={itemClass}
                index={index}
                value={value}
                sortIndex={index}
                openModalWithEditProduct={openModalWithEditProduct}
                animateSelectAllProducts={animateSelectAllProducts}
                triggerOutOfStock={triggerOutOfStock}
                onToggleChecked={onToggleChecked}
                checkedProducts={checkedProducts}
                arrWithProductsForDelete={arrWithProductsForDelete}
              />
            ))
          }
        </div>
      </div>
    ),
);


class SortableCategoryItem extends Component {
  state = {
    items: this.props.items,
    checkedProducts: [],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { sortProducts } = this.props;
    const newProducts = arrayMove(this.state.items, oldIndex, newIndex);
    const productIds = newProducts.map(el => el.id);

    this.setState({
      items: newProducts,
    }, () => {
      sortProducts(productIds);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { selectAllProducts, items, deselectAllProducts, updateGlobalStateWithProducts } = this.props;

    if (selectAllProducts !== nextProps.selectAllProducts) {
      const checkedProducts = items.map(v => v.id);
      this.setState({
        checkedProducts,
      })
    } else if (deselectAllProducts !== nextProps.deselectAllProducts) {
      this.setState({
        checkedProducts: [],
      })
    }
  }

  onToggleChecked = (e, data, id) => {
    const { checkedProducts } = this.state;
    const { updateGlobalStateWithProducts, categoryName } = this.props;
    const { checked } = data;
    if (checkedProducts.indexOf(id) === -1 && !checked) {
      checkedProducts.push(id);
    } else {
      checkedProducts.splice(checkedProducts.indexOf(id), 1);
    }

    this.setState({
      checkedProducts,
    }, () => {
      updateGlobalStateWithProducts(checkedProducts, categoryName);
    })
  }


  render() {
    const { items, checkedProducts } = this.state;
    const { openModalWithEditProduct, animateSelectAllProducts, triggerOutOfStock, arrWithProductsForDelete } = this.props;

    return (
      <SortableList
        transitionDuration={500}
        useDragHandle
        axis="xy"
        items={items}
        helperClass={cx('stylizedHelper')}
        className={cx('list', 'stylizedList', 'grid')}
        itemClass={cx('stylizedItem', 'gridItem')}
        onSortEnd={this.onSortEnd}
        openModalWithEditProduct={openModalWithEditProduct}
        animateSelectAllProducts={animateSelectAllProducts}
        triggerOutOfStock={triggerOutOfStock}
        onToggleChecked={this.onToggleChecked}
        checkedProducts={checkedProducts}
        arrWithProductsForDelete={arrWithProductsForDelete}
      />
    )
  }
}

export default SortableCategoryItem;
