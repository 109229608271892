import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
} from 'utils/lib';
import styles from './Statistic.scss';

const cx = classnames.bind(styles);

const StatisticLabel = (props) => {
  const { children, className, content } = props;
  const classes = cx('label', className);
  const rest = getUnhandledProps(StatisticLabel, props);
  const ElementType = getElementType(StatisticLabel, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

StatisticLabel.create = createShorthandFactory(StatisticLabel, content => ({ content }));

export default StatisticLabel;
