import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  listStores: null,
  isFetching: false,
  isFetchingStoreInfo: false,
  messageFail: true,
  storeInfo: null,
  storeQuery: null,
  bufferStoreInfo: {},
  viewStoresSupportCreditCard: false,
  isSameDayDelivery: true,
  messageForSameDayDelivery: '',
  viewAllStores: false,
  oneStoreForSameDayDelivery: null,
  triggerForGoBack: false,
  messageForStoresOvernight: false,
  deliveryMessage: null,
  sameDay: true,
  redirectIntoStore: null,
  redirectIntoStoreOvernight: null,
  deliveryCost: 0,
};

export default handleActions(
  {
    [constants.FETCH_STORES]: (state, action) => ({
      ...state,
      isFetching: true,
      messageFail: false,
    }),

    [constants.FETCH_STORES_SUCCESS]: (state, action) => ({
      ...state,
      listStores: action.payload,
      isFetching: false,
      messageFail: false,
      isSameDayDelivery: action.isSameDayDelivery,
      messageForSameDayDelivery: action.messageForSameDayDelivery,
      viewAllStores: action.viewAllStores,
      oneStoreForSameDayDelivery: action.oneStoreForSameDayDelivery,
      triggerForGoBack: false,
    }),

    [constants.FETCH_STORES_FAIL]: (state, action) => ({
      ...state,
      isFetching: false,
      messageFail: true,
    }),

    [constants.FETCH_STORE_INFO]: (state, action) => ({
      ...state,
      isFetchingStoreInfo: true,
    }),

    [constants.FETCH_STORE_INFO_SUCCESS]: (state, action) => {
      const bufferStoreInfo = state.bufferStoreInfo;
      bufferStoreInfo[action.storeInfo.id] = action.storeInfo;

      return {
        ...state,
        storeInfo: action.storeInfo,
        bufferStoreInfo,
        isFetchingStoreInfo: false,
      };
    },

    [constants.RETURN_BUFFER_STORES_INFO]: (state, action) => ({
      ...state,
      storeInfo: action.storeInfo,
    }),

    [constants.FETCH_STORE_INFO_FAIL]: (state, action) => ({
      ...state,
      isFetchingStoreInfo: false,
    }),

    [constants.SAVE_INFO_STORE_QUERY]: (state, action) => ({
      ...state,
      storeQuery: action.storeQuery,
      oneStoreForSameDayDelivery: action.oneStoreForSameDayDelivery,
      messageForStoresOvernight: action.messageForStoresOvernight,
    }),

    [constants.FILTER_STORES_SUPPORT_CREDIT_CARD]: (state, action) => ({
      ...state,
      viewStoresSupportCreditCard: action.trigger,
    }),

    [constants.HANDLE_SAME_DAY_DELIVERY]: (state, action) => ({
      ...state,
      listStores: action.payload,
      isSameDayDelivery: action.value,
      messageForSameDayDelivery: action.messageForSameDayDelivery,
      oneStoreForSameDayDelivery: action.oneStoreForSameDayDelivery,
      isFetching: false,
      triggerForGoBack: false,
      messageForStoresOvernight: action.messageForStoresOvernight,
      viewAllStores: action.viewAllStores,
    }),

    [constants.RESET_STORES]: (state, action) => ({
      ...state,
      listStores: null,
      oneStoreForSameDayDelivery: false,
      deliveryMessage: null,
      redirectIntoStoreSameDay: null,
      redirectIntoStoreOvernight: null,
      sameDay: true,
    }),

    [constants.VIEW_STORES_WITH_OVERNIGHT_DELIVERY]: (state, action) => {
      const storesDay = state.listStores.filter(v => v.same_day_delivery);
      const storesOvernight = state.listStores.filter(v => !v.same_day_delivery);
      return {
        ...state,
        messageForSameDayDelivery: action.messageForSameDayDelivery,
        oneStoreForSameDayDelivery: false,
        triggerForGoBack: true,
        viewAllStores:
          (storesDay.length > 1 && storesOvernight.length === 0)
            || (storesDay.length === 0 && storesOvernight.length > 1)
            ? true
            : action.viewAllStores,
        isSameDayDelivery:
          (storesDay.length > 1 && storesOvernight.length === 0)
            || (storesDay.length === 0 && storesOvernight.length > 1)
            ? true
            : action.isSameDayDelivery,
      };
    },

    [constants.HANDLE_GO_BACK_TO_PRODUCTS]: (state, action) => ({
      ...state,
      oneStoreForSameDayDelivery: action.oneStoreForSameDayDelivery,
    }),

    [constants.HANDLE_DELIVERY_DATA]: (state, action) => ({
      ...state,
      deliveryMessage: action.data,
      listStores: action.payload,
      isFetching: false,
      redirectIntoStoreSameDay: action.redirectIntoStoreSameDay,
      redirectIntoStoreOvernight: action.redirectIntoStoreOvernight,
      sameDay: action.sameDay,
    }),

    [constants.CHANGE_DELIVERY_OPTION]: (state, action) => ({
      ...state,
      sameDay: action.sameDay,
    }),

    [constants.RESET_STORES_AND_STORE_INFORMATION]: (state, action) => ({
      ...state,
      listStores: null,
      storeInfo: null,
      deliveryMessage: null,
    }),
  },
  initialState
);
