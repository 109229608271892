/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { dateBetween } from 'services/timer';

import classnames from 'classnames/bind';
import styles from './CountDown.module.scss';

const cx = classnames.bind(styles);

export default class CountDown extends Component {
  state = {
    remaining: null
  };

  componentDidMount() {
    this.tick();
    this.interval = setInterval(this.tick.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    let startDate = new Date();
    let endDate = new Date(this.props.options.endDate);
    let remaining = dateBetween(startDate, endDate);

    if (remaining === false) {
      window.clearInterval(this.interval);
      this.props.options['cb'] ? this.props.options.cb() : false;
    }

    this.setState({
      remaining
    });
  };

  render() {
    return (
      <div className={cx('coundown')}>
        <span>
          {this.state.remaining ? this.state.remaining : 'should be ready'}
        </span>
      </div>
    );
  }
}
