import { connect } from 'react-redux';

import { fetchNotFoundCode, setDeliveryAddress } from 'modules/base/actions';

import { getChildrenComponentForEmptyPage } from 'modules/base/selectors';
import Empty from '../components/Empty';

const mapStateToProps = state => ({
  childrenComponentForEmptyPage: getChildrenComponentForEmptyPage(state.base),
});

export default connect(
  mapStateToProps,
  {
    setDeliveryAddress,
    fetchNotFoundCode,
  }
)(Empty);
