import React, { Component } from 'react';
import classnames from 'classnames/bind';

import Header from 'components/Typography/Header/Header';
import FormField from 'components/Form/FormField';
import Input from 'components/Input/Input';
import Label from 'components/Label/Label';
import TextArea from 'components/TextArea/TextArea';

import styles from './Main.module.scss';

const cx = classnames.bind(styles);

const labelStyle = {
  width: '25%',
  paddingLeft: '1em',
  paddingRight: '1em',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

class Main extends Component {
  render() {
    const { disabled, name, description, onChange, error } = this.props;

    return (
      <div style={{ marginTop: '1.5em' }}>
        <Header as="h4" block>
          Main section
        </Header>
        <div style={{ display: 'flex' }}>
          <FormField inline className={cx('custom-form-field')} error={error} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Name:</label>
            <div className={cx('input-container')}>
              <Input
                focus={!disabled}
                fluid
                defaultValue={name}
                disabled={disabled}
                onChange={onChange}
                name="name"
                className={cx('main-style')}
                style={{ opacity: 1 }}
              />
            </div>
            {
              name.length === 0 && <Label basic color="red" pointing="left">
                Please enter a value
            </Label>
            }
          </FormField>
        </div>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <FormField inline className={cx('custom-form-field')} disabled={disabled} style={{ opacity: 1 }}>
            <label style={{ opacity: 1 }} className={cx('label-title')}>Description:</label>
            <div className={cx('textarea-container')}>
              <TextArea
                disabled={disabled}
                placeholder="Add description text..."
                defaultValue={description}
                onChange={onChange}
                name='description'
                style={{ opacity: 1 }}
              />
            </div>
          </FormField>
        </div>
      </div>
    );
  }
}

export default Main;
