import React from 'react';
import classnames from 'classnames/bind';
import _ from 'lodash';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useTextAlignProp,
  useVerticalAlignProp,
  useWidthProp,
} from 'utils/lib';

import styles from './Table.scss';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableRow from './TableRow';

const cx = classnames.bind(styles);

const Table = (props) => {
  const {
    attached,
    basic,
    celled,
    children,
    className,
    collapsing,
    color,
    columns,
    compact,
    definition,
    fixed,
    footerRow,
    headerRow,
    inverted,
    padded,
    renderBodyRow,
    selectable,
    singleLine,
    size,
    sortable,
    stackable,
    striped,
    structured,
    tableData,
    textAlign,
    unstackable,
    verticalAlign,
  } = props;

  const classes = cx(
    'ui',
    color,
    size,
    useKeyOnly(celled, 'celled'),
    useKeyOnly(collapsing, 'collapsing'),
    useKeyOnly(definition, 'definition'),
    useKeyOnly(fixed, 'fixed'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(selectable, 'selectable'),
    useKeyOnly(singleLine, 'single line'),
    useKeyOnly(sortable, 'sortable'),
    useKeyOnly(stackable, 'stackable'),
    useKeyOnly(striped, 'striped'),
    useKeyOnly(structured, 'structured'),
    useKeyOnly(unstackable, 'unstackable'),
    useKeyOrValueAndKey(attached, 'attached'),
    useKeyOrValueAndKey(basic, 'basic'),
    useKeyOrValueAndKey(compact, 'compact'),
    useKeyOrValueAndKey(padded, 'padded'),
    useTextAlignProp(textAlign),
    useVerticalAlignProp(verticalAlign),
    useWidthProp(columns, 'column'),
    'table',
    className
  );
  const rest = getUnhandledProps(Table, props);
  const ElementType = getElementType(Table, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {headerRow && (
        <TableHeader>{TableRow.create(headerRow, { defaultProps: { cellAs: 'th' } })}</TableHeader>
      )}
      <TableBody>
        {renderBodyRow
          && _.map(tableData, (data, index) => TableRow.create(renderBodyRow(data, index)))}
      </TableBody>
      {/* {footerRow && <TableFooter>{TableRow.create(footerRow)}</TableFooter>} */}
    </ElementType>
  );
};

Table.defaultProps = {
  as: 'table',
};

export default Table;
