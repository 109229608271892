import React from 'react';
import Button from 'components/Button';

const InactiveStore = props => (
  <div style={{ padding: '24px 24px 0px 24px' }}>
    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
      The store is not deliver goods at the moment. Please choose another one that deliver to your
      location.
    </p>
    <div style={{ textAlign: 'center' }}>
      <Button
        type="yellow"
        name="Search a store"
        onClick={() => props.goToDelivery(props.address)}
      />
    </div>
  </div>
);

export default InactiveStore;
