import React from 'react';
import classnames from 'classnames/bind';

import {
  isNil,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Divider.css';

const cx = classnames.bind(styles);

const Divider = (props) => {
  const {
    children,
    className,
    clearing,
    content,
    fitted,
    hidden,
    horizontal,
    inverted,
    section,
    vertical,
  } = props;

  const classes = cx(
    'ui',
    useKeyOnly(clearing, 'clearing'),
    useKeyOnly(fitted, 'fitted'),
    useKeyOnly(hidden, 'hidden'),
    useKeyOnly(horizontal, 'horizontal'),
    useKeyOnly(inverted, 'inverted'),
    useKeyOnly(section, 'section'),
    useKeyOnly(vertical, 'vertical'),
    'divider',
    className,
  );

  const rest = getUnhandledProps(Divider, props)
  const ElementType = getElementType(Divider, props)

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default Divider;
