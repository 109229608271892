import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  AutoControlledComponent as Component,
  createHTMLLabel,
  getElementType,
  getUnhandledProps,
  htmlInputAttrs,
  partitionHTMLProps,
  useKeyOnly,
} from 'utils/lib';

import styles from './Checkbox.scss';

const cx = classnames.bind(styles);

class Checkbox extends Component {
  static propTypes = {
    toggle: PropTypes.bool,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onMouseUp: PropTypes.func,
  };

  static defaultProps = {
    type: 'checkbox',
  };

  static autoControlledProps = ['checked', 'indeterminate'];

  componentDidMount() {
    this.setIndeterminate();
  }

  componentDidUpdate() {
    this.setIndeterminate();
  }

  computeTabIndex = () => {
    const { disabled, tabIndex } = this.props;

    if (!_.isNil(tabIndex)) return tabIndex;
    return disabled ? -1 : 0;
  };

  setIndeterminate = () => {
    const { indeterminate } = this.state;

    if (this.inputRef) this.inputRef.indeterminate = !!indeterminate;
  };

  canToggle = () => {
    const { disabled, radio, readOnly } = this.props;
    const { checked } = this.state;

    return !disabled && !readOnly && !(radio && checked);
  };

  handleInputRef = c => (this.inputRef = c);

  handleChange = (e, fromMouseUp) => {
    const { id } = this.props;
    const { checked, indeterminate } = this.state;

    if (!this.canToggle()) return;
    if (fromMouseUp && !_.isNil(id)) return;

    _.invoke(this.props, 'onClick', e, {
      ...this.props,
      checked: !checked,
      indeterminate: !!indeterminate,
    });
    _.invoke(this.props, 'onChange', e, { ...this.props, checked: !checked, indeterminate: false });

    this.trySetState({ checked: !checked, indeterminate: false });
  };

  handleClick = (e) => {
    const { onChange, onClick } = this.props;
    if (onChange || !onClick) return;

    onClick(e, this.props);
  };

  handleMouseDown = (e) => {
    const { checked, indeterminate } = this.state;

    _.invoke(this.props, 'onMouseDown', e, {
      ...this.props,
      checked: !!checked,
      indeterminate: !!indeterminate,
    });

    _.invoke(this.inputRef, 'focus');

    e.preventDefault();
  };

  handleMouseUp = (e) => {
    const { checked, indeterminate } = this.state;

    _.invoke(this.props, 'onMouseUp', e, {
      ...this.props,
      checked: !!checked,
      indeterminate: !!indeterminate,
    });
    this.handleChange(e, true);
  };

  render() {
    const {
      className,
      disabled,
      label,
      id,
      name,
      radio,
      readOnly,
      slider,
      toggle,
      type,
      value,
    } = this.props;
    const { checked, indeterminate } = this.state;

    const classes = cx(
      'ui',
      useKeyOnly(checked, 'checked'),
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(indeterminate, 'indeterminate'),
      useKeyOnly(_.isNil(label), 'fitted'),
      useKeyOnly(radio, 'radio'),
      useKeyOnly(readOnly, 'read-only'),
      useKeyOnly(slider, 'slider'),
      useKeyOnly(toggle, 'toggle'),
      'checkbox',
      className
    );

    const unhandled = getUnhandledProps(Checkbox, this.props);
    const ElementType = getElementType(Checkbox, this.props);
    const [htmlInputProps, rest] = partitionHTMLProps(unhandled, { htmlProps: htmlInputAttrs });

    return (
      <ElementType
        {...rest}
        className={classes}
        onChange={this.handleChange}
        onClick={this.handleClick}
      // onMouseDown={this.handleMouseDown}
      // onMouseUp={this.handleMouseUp}
      >
        <input
          {...htmlInputProps}
          checked={checked}
          className={cx('hidden')}
          disabled={disabled}
          id={id}
          name={name}
          readOnly
          ref={this.handleInputRef}
          tabIndex={this.computeTabIndex()}
          type={type}
          value={value}
        />
        {createHTMLLabel(label, { defaultProps: { htmlFor: id }, autoGenerateKey: false }) || (
          <label htmlFor={id} />
        )}
      </ElementType>
    );
  }
}

export default Checkbox;
