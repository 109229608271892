import { handleActions } from 'redux-actions';
import * as constants from './constants';
import immutable from 'immutability-helper';

const initialState = {
  categoryData: {
    status: 'IDLE',
    data: {},
    error: null,
  }
}

export default handleActions({
  [constants.FETCH_CATEGORY_DATA]: (state, actions) =>
    immutable(state, {
      categoryData: {
        status: { $set: 'RUNNING' },
        data: { $set: {} },
        error: { $set: null }
      }
    }),

  [constants.FETCH_CATEGORY_DATA_SUCCESS]: (state, actions) =>
    immutable(state, {
      categoryData: {
        status: { $set: 'SUCCESS' },
        data: { $set: actions.payload },
      }
    }),

  [constants.FETCH_CATEGORY_DATA_FAIL]: (state, actions) =>
    immutable(state, {
      categoryData: {
        status: { $set: 'FAIL' },
        error: { $set: actions.error }
      }
    })

}, initialState);
