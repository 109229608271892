import { connect } from 'react-redux';
import { getStoreInformation, getIsFetchingStoreInformation, getNewStoreImage } from 'modules/seller/selectors';
import { changeStoreInformation, fetchStoreInformation, updateStoreInformation, editStoreInformation } from 'modules/seller/actions';

import StoreInformation from '../components/StoreInformation';

const mapStateToProps = state => ({
  storeInformation: getStoreInformation(state.seller),
  isFetchingStoreInformation: getIsFetchingStoreInformation(state.seller),
  storeImage: getNewStoreImage(state.seller),
});

export default connect(
  mapStateToProps,
  {
    changeStoreInformation,
    fetchStoreInformation,
    updateStoreInformation,
    editStoreInformation,
  }
)(StoreInformation);
