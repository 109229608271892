import React from 'react';
import classnames from 'classnames/bind';

import {
  getElementType, getUnhandledProps
} from 'utils/lib'

import styles from './Placeholder.module.scss';

const cx = classnames.bind(styles);

const PlaceholderLine = (props) => {
  const { className, length } = props;
  const classes = cx('line', length, className);
  const rest = getUnhandledProps(PlaceholderLine, props);
  const ElementType = getElementType(PlaceholderLine, props);

  return <ElementType {...rest} className={classes} />
}

export default PlaceholderLine;
