const getElementType = (Component, props, getDefault) => {
  const { defaultProps = {} } = Component;

  if (props.as && props.as !== defaultProps.as) return props.as;

  if (getDefault) {
    const computeDefault = getDefault();
    if (computeDefault) return computeDefault;
  }

  if (props.href) return 'a';

  return defaultProps.as || 'div';
};

export { getElementType };
