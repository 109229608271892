import React from 'react';
import OrderProcess from './components/OrderProcess';
import { IconBigTimer } from 'images/svg/utils';
import CountDown from 'components/CountDown';

import spacetime from 'spacetime';

import classnames from 'classnames/bind';
import styles from './Orders.module.scss';

import { getFormattedDate } from 'services/timer';

const cx = classnames.bind(styles);

export default (props) => {
  const { order } = props;
  let endDate = '';
  if (order.delivery_time) {
    const date = new Date(order.delivery_time * 1000);
    endDate = getFormattedDate(date);
  }

  return (
    <div className={cx('orders-container')}>
      <div className={cx('process-container')}>
        <OrderProcess
          name="Received by seller"
          storeType={order.store_type}
          enabledCar={order.status === 'processing'}
          enabledPoint
        />
        <hr className={cx('delimeter-left')} />
        <OrderProcess
          name={order.store_type === 'delivery' ? 'In the works' : 'Ready for Pickup'}
          storeType={order.store_type}
          enabledCar={order.status === 'processed'}
          enabledPoint={order.status === 'processed' || order.status === 'received'}
        />
        <hr className={cx('delimeter-right')} />
        <OrderProcess
          name="Shipped"
          storeType={order.store_type}
          enabledCar={order.status === 'received'}
          enabledPoint={order.status === 'received'}
        />
      </div>
      <div className={cx('timer-container')}>
        <IconBigTimer />
        <div className={cx('timer-title')}>
          {/* {order.store_type === 'delivery' ? 'delivery at: ' : 'pickup at: '} */}
          {order.delivery_time
            ? `delivery at: ${spacetime(order.delivery_time * 1000).format('nice-day')}`
            : 'Heally team will provide an ETA soon'}
        </div>
      </div>
    </div>
  );
};
