import React from 'react';
import classnames from 'classnames/bind';
import _ from 'lodash';
import styles from './Rating.module.scss';

import {
  AutoControlledComponent as Component,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
} from 'utils/lib';

import RatingIcon from './RatingIcon';

const cx = classnames.bind(styles);

class Rating extends Component {
  static autoControlledProps = ['rating']

  static defaultProps = {
    clearable: 'auto',
    maxRating: 1,
  }

  handleIconClick = (e, { index }) => {
    const { clearable, disabled, maxRating, onRate } = this.props;
    const { rating } = this.state;
    if (disabled) return;

    let newRating = index + 1;
    if (clearable === 'auto' && maxRating === 1) {
      newRating = +!rating;
    } else if (clearable === true && newRating === rating) {
      newRating = 0;
    }

    this.trySetState({ rating: newRating }, { isSelecting: false });
    if (onRate) onRate(e, { ...this.props, rating: newRating });
  }

  handleIconMouseEnter = (e, { index }) => {
    if (this.props.disabled) return;

    this.setState({ selectedIndex: index, isSelecting: true });
  }

  handleMouseLeave = (...args) => {
    _.invoke(this.props, 'onMouseLeave', ...args);

    if (this.props.disabled) return;

    this.setState({ selectedIndex: -1, isSelecting: false });
  }

  render() {
    const { className, disabled, icon, maxRating, size, color } = this.props;
    const { rating, selectedIndex, isSelecting } = this.state;

    const classes = cx(
      'ui',
      icon,
      size,
      useKeyOnly(disabled, 'disabled'),
      useKeyOnly(isSelecting && !disabled && selectedIndex >= 0, 'selected'),
      'rating',
      color,
      className,
    );
    const rest = getUnhandledProps(Rating, this.props);
    const ElementType = getElementType(Rating, this.props);

    return (
      <ElementType
        {...rest}
        className={classes}
        role="radiogroup"
        onMouseLeave={this.handleMouseLeave}
        tabIndex={disabled ? 0 : -1}
      >
        {_.times(maxRating, i => (
          <RatingIcon
            tabIndex={disabled ? -1 : 0}
            active={rating >= i + 1}
            aria-checked={rating === i + 1}
            aria-posinset={i + 1}
            aria-setsize={maxRating}
            index={i}
            key={i}
            onClick={this.handleIconClick}
            onMouseEnter={this.handleIconMouseEnter}
            selected={selectedIndex >= i && isSelecting}
          />
        ))}
      </ElementType>
    );
  }
}

export default Rating;
