import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  clientToken: null,
  isFetchClientToken: false,
};

export default handleActions(
  {
    [constants.FETCH_CLIENT_TOKEN]: (state, action) => ({
      ...state,
      isFetchClientToken: true,
    }),

    [constants.FETCH_CLIENT_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      clientToken: action.clientToken,
      isFetchClientToken: false,
    }),

    [constants.FETCH_CLIENT_TOKEN_FAIL]: (state, action) => ({
      ...state,
      isFetchClientToken: false,
    }),
  },
  initialState
);
