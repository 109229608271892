import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';
import { getUnhandledProps, getElementType, useKeyOnly } from 'utils/lib';

import styles from './Rating.module.scss';

const cx = classnames.bind(styles);

class RatingIcon extends Component {
  static defaultProps = {
    as: 'i',
  }

  handleClick = (e) => {
    _.invoke(this.props, 'onClick', e, this.props);
  }

  handleMouseEnter = (e) => {
    _.invoke(this.props, 'onMouseEnter', e, this.props);
  }

  render() {
    const { active, className, selected } = this.props;
    const classes = cx(
      useKeyOnly(active, 'active'),
      useKeyOnly(selected, 'selected'),
      'icon',
      className,
    );
    const rest = getUnhandledProps(RatingIcon, this.props);
    const ElementType = getElementType(RatingIcon, this.props);

    return (
      <ElementType
        {...rest}
        className={classes}
        onClick={this.handleClick}
        onKeyUp={this.handleKeyUp}
        onMouseEnter={this.handleMouseEnter}
        role="radio"
      />
    );
  }
}

export default RatingIcon;
