import React, { Component } from 'react';
import Image from 'components/Image/Image';
import MDRender from 'services/MDRender';
import { convertToDecimal } from 'services';
import classnames from 'classnames/bind';

import Button from 'components/Button/Button';
import Transition from 'components/Transition/Transition';

import OzPacking from './components/OzPacking';
import PiecePacking from './components/PiecePacking';
import GramPacking from './components/GramPacking';
import PieceDiscretePacking from './components/PieceDiscretePacking';
import GramOzPacking from './components/GramOzPacking';

import styles from './CardBuy.module.scss';

const cx = classnames.bind(styles);

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];

class CardBuy extends Component {
  state = {};

  setProduct = (quantity) => {
    const { card, setProduct } = this.props;
    setProduct(card, quantity);
  };

  closeCardBuy = () => {
    const { card, closeCardBuy } = this.props;
    closeCardBuy(card);
  };

  render() {
    const { card, onBuyNow, itemsProducts, openModal, windowHeight } = this.props;
    let packing = '';
    let productName = card.name;
    const brand = card.brand ? `${card.brand}` : '';

    let itemProduct = null;
    if (itemsProducts && itemsProducts[card.id]) {
      itemProduct = Object.assign({}, itemsProducts[card.id]);
    }

    switch (card.packing) {
      case 'piece':
        packing = (
          <PiecePacking
            price={card.price}
            itemProduct={itemProduct}
            packing={card.packing}
            setProduct={this.setProduct}
            closeCardBuy={this.closeCardBuy}
          />
        );
        break;
      case 'oz': {
        const steps = Object.keys(card.price).sort((a, b) => convertToDecimal(b) - convertToDecimal(a));

        packing = (
          <OzPacking
            price={card.price}
            itemProduct={itemProduct}
            setProduct={this.setProduct}
            steps={steps}
            packing={card.packing}
            closeCardBuy={this.closeCardBuy}
          />
        );
        break;
      }
      case 'gram': {
        const grams = Object.keys(card.price);
        const parseGrams = grams.map(value => parseFloat(value));
        parseGrams.sort((a, b) => a - b);
        packing = (
          <GramPacking
            price={card.price}
            itemProduct={itemProduct}
            steps={parseGrams}
            setProduct={this.setProduct}
            closeCardBuy={this.closeCardBuy}
          />
        );
        break;
      }

      case 'piece_discrete':
        packing = (
          <PieceDiscretePacking
            price={card.price}
            itemProduct={itemProduct !== null && itemProduct}
            setProduct={this.setProduct}
            closeCardBuy={this.closeCardBuy}
          />
        );
        break;
      case 'gram_oz': {
        const filterGramOz = [];
        Object.keys(card.price).forEach((price, index) => {
          if (GRAM_OZ.indexOf(price) >= 0) {
            filterGramOz.push({
              position: index,
              packing: price,
              cost: card.price[price],
            });
          }
        });
        const sortGramOz = filterGramOz
          .sort((a, b) => a.position - b.position)
          .map(value => value.packing);
        packing = (
          <GramOzPacking
            price={card.price}
            steps={sortGramOz}
            itemProduct={itemProduct !== null && itemProduct}
            setProduct={this.setProduct}
            closeCardBuy={this.closeCardBuy}
          />
        );
        break;
      }

      default:
        break;
    }

    if (window.isMobile) {
      productName = productName.length > 40 ? `${productName.slice(0, 40)}...` : productName;
    } else if (windowHeight <= 750 && productName.length > 66) {
      productName = `${productName.slice(0, 65)}...`;
    } else if (productName.length > 99) {
      productName = `${productName.slice(0, 85)}...`;
    }

    return (
      <div className={cx('container', 'flip-card-back', !card.in_cart && 'flip-card-back-blur')} style={{ position: 'absolute' }}>
        <div className={cx('title')}>
          <h2 className={cx('product-name')}>{`${brand} ${productName}`}</h2>
          {/* <div className={cx('product-category')} /> */}
        </div>
        <div className={cx('content')}>
          <div className={cx('image')} onClick={() => openModal(card)}>
            <Image src={card.image && card.image.wide} />
          </div>
          {packing}
        </div>
        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{
            __html: MDRender(card.description),
          }}
        />
        <div className={cx('footer')}>
          <Button className={cx('custom-btn')} style={{ textDecoration: 'none' }} type="button" color="teal" onClick={onBuyNow}>
            Go to cart
          </Button>
        </div>
      </div>
    );
  }
}

export default CardBuy;
