import React from 'react';
import classnames from 'classnames/bind';

import Icon from 'components/Icons/Icon';

import styles from './DrawerItem.module.scss';

const cx = classnames.bind(styles);

const DrawerItem = ({ domainPath, onClick, iconName, name, link }) => {
  return (
    <div className={cx('drawer-item-container')}>
      <span
        className={cx('drawer-menu-item')}
        onClick={() => onClick(`${domainPath}${link}`)}
      >
        <div className={cx('drawer-menu-item-name')}>
          <Icon name={iconName || ''} size="large" />
          &nbsp;&nbsp;{name}
        </div>
      </span>
    </div>
  )
}

export default DrawerItem;
