import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  showModal: false,
  currentOrder: null,
  listOrders: [],
  receivedOrders: [],
  orderInfo: null,
  openModalStoreType: false,
  modalRecommendation: false,
  isCreateOrder: false,
  isFetchingUserOrders: false,
  isFetchingOrderInformation: false,
  orderInformation: null,
  inactiveStore: false,
  reloadPaymentOnlineComponent: false,
  hasPhotoId: false,
  requirePhotoId: false,
};

export default handleActions(
  {
    [constants.CREATE_ORDER]: (state, action) => ({
      ...state,
      isCreateOrder: true,
      modalRecommendation: false,
    }),

    [constants.CREATE_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      showModal: true,
      currentOrder: action.currentOrder,
      isCreateOrder: false,
      modalRecommendation: false,
    }),

    [constants.CREATE_ORDER_FAIL]: (state, action) => ({
      ...state,
      isCreateOrder: false,
    }),

    [constants.CLOSE_MODAL]: (state, action) => ({
      ...state,
      showModal: false,
    }),

    [constants.FETCH_USER_ORDERS]: (state, action) => ({
      ...state,
      isFetchingUserOrders: true,
    }),

    [constants.FETCH_USER_ORDERS_SUCCESS]: (state, action) => ({
      ...state,
      listOrders: action.listOrders,
      receivedOrders: action.receivedOrders,
      isFetchingUserOrders: false,
    }),

    [constants.FETCH_USER_ORDERS_FAIL]: (state, action) => ({
      ...state,
      isFetchingUserOrders: false,
    }),

    [constants.OPEN_MODAL_CHANGE_STORE_TYPE]: (state, action) => ({
      ...state,
      orderInfo: action.orderInfo,
      openModalStoreType: true,
    }),

    [constants.CLOSE_MODAL_CHANGE_STORE_TYPE]: (state, action) => ({
      ...state,
      openModalStoreType: false,
    }),

    [constants.CHECK_AUTH_USER]: (state, action) => ({
      ...state,
      isCreateOrder: action.auth,
    }),

    [constants.ADD_ORDERS_TO_CART_SUCCESS]: (state, action) => ({
      ...state,
      openModalStoreType: false,
    }),

    [constants.OPEN_MODAL_RECOMMENDATION]: (state, action) => ({
      ...state,
      modalRecommendation: true,
    }),

    [constants.CLOSE_MODAL_RECOMMENDATION]: (state, action) => ({
      ...state,
      modalRecommendation: false,
      isCreateOrder: false,
      hasPhotoId: false,
    }),

    [constants.CART_HAS_ISSUES]: (state, action) => ({
      ...state,
      isCreateOrder: false,
    }),

    [constants.FETCH_ORDER_INFORMAION]: (state, action) => ({
      ...state,
      isFetchingOrderInformation: true,
    }),

    [constants.FETCH_ORDER_INFORMAION_SUCCESS]: (state, action) => ({
      ...state,
      orderInformation: action.payload,
      isFetchingOrderInformation: false,
    }),

    [constants.CART_HAS_PROBLEM_WITH_COUPON]: (state, action) => ({
      ...state,
      isCreateOrder: false,
      modalRecommendation: false,
    }),

    [constants.INACTIVE_STORE]: (state, action) => ({
      ...state,
      inactiveStore: action.inactiveStore,
    }),

    [constants.RELOAD_PAYMENT_ONLINE_COMPONENT]: (state, action) => ({
      ...state,
      reloadPaymentOnlineComponent: action.value,
    }),

    [constants.OPEN_MODAL_WITH_PHOTO_ID]: (state, action) => ({
      ...state,
      hasPhotoId: true,
    }),

    [constants.CLOSE_MODAL_WITH_PHOTO_ID]: (state, action) => ({
      ...state,
      hasPhotoId: false,
    }),

    [constants.REQUIRE_PHOTO_ID]: (state, action) => ({
      ...state,
      requirePhotoId: action.payload,
    })

  },
  initialState
);
