import React from 'react';
import classnames from 'classnames/bind';

import { getElementType, getUnhandledProps, useKeyOnly, useWidthProp } from 'utils/lib';

import styles from './Form.css';

const cx = classnames.bind(styles);

const FormGroup = (props) => {
  const { children, className, grouped, inline, unstackable, widths } = props;

  const classes = cx(
    useKeyOnly(grouped, 'grouped'),
    useKeyOnly(inline, 'inline'),
    useKeyOnly(unstackable, 'unstackable'),
    useWidthProp(widths, null, true),
    'fields',
    className
  );
  const rest = getUnhandledProps(FormGroup, props);
  const ElementType = getElementType(FormGroup, props);

  return (
    <ElementType {...rest} className={classes}>
      {children}
    </ElementType>
  );
};

export default FormGroup;
