import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import ToggleButton from 'components/ToggleButton';
import Popup from 'components/Popup/Popup';
import PopupContent from 'components/Popup/PopupContent';

import PrevArrow from '../PrevArrow';
import debounce from 'services/debounce';

import classnames from 'classnames/bind';
import styles from './SliderCategories.module.scss';

import ScrollAnim from 'rc-scroll-anim';
import scroll from 'scroll';
import ease from 'ease-component';
import NextArrow from '../NextArrow';

const { Link } = ScrollAnim;

const cx = classnames.bind(styles);

const getTranslateY = (index, widthContainer) => {
  let pos = widthContainer;

  if (index < 5) {
    pos = 0;
  } else if (index >= 5 && index < 9) {
    pos = widthContainer;
  } else if (index >= 9 && index < 13) {
    pos = widthContainer * 2;
  } else if (index >= 13 && index <= 18) {
    pos = widthContainer * 3;
  }

  return pos;
};

class SliderCategories extends Component {
  categorySliderNode = React.createRef();

  state = {
    viewHeader: false,
    offsetTop: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    const nodeEl = document.getElementById('category-0');

    if (nodeEl) {
      this.setOffsetPosition(nodeEl);
    }

    if ('scrollBehavior' in document.documentElement.style) {
      this.scrollBehavior = true;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setOffsetPosition = (node) => {
    const position = node.getBoundingClientRect();
    const nodeCategorySlider = document.getElementById('categories_slider');

    const positionCategorySlider = nodeCategorySlider.getBoundingClientRect();

    this.setState({
      offsetTop: -position.y + positionCategorySlider.y + 56,
    });
  };

  scrollToDirection = (direction, id, arrow) => {
    const { scrollLeft, scrollWidth, clientWidth, windowHeight } = document.getElementById(id);
    let widthContainer = 405;
    if (window.isTablet) {
      widthContainer = 475;
    } else if (windowHeight <= 750) {
      widthContainer = 405;
    } else {
      widthContainer = 475;
    }

    const far = widthContainer * direction;
    const pos = scrollLeft + far;

    scroll.left(document.getElementById(id), pos, { duration: 50 }, { ease: ease.inOutBounce });

    if (
      (arrow === 'next' && Math.ceil(scrollLeft) === scrollWidth - clientWidth)
      || (arrow === 'next' && Math.ceil(scrollLeft) - 1 === scrollWidth - clientWidth)
    ) {
      scroll.left(document.getElementById(id), 0, { duration: 50 }, { ease: ease.inOutBounce });
    }

    if (pos === widthContainer * -1) {
      scroll.left(document.getElementById(id), scrollWidth - clientWidth, { duration: 50 }, { ease: ease.inOutBounce });
    }
  };

  handleScroll = () => {
    const { viewHeader } = this.state;
    const scrollTop = window.pageYOffset;
    if (scrollTop > 86 && !viewHeader) {
      this.setState({
        viewHeader: true,
      });
    }

    if (scrollTop <= 87 && viewHeader) {
      this.setState({
        viewHeader: false,
      });
    }
  };

  scroller = (index, categoryName) => {
    const { windowHeight } = this.props;

    let widthContainer = 0;
    if (window.isTablet) {
      widthContainer = 475;
    } else if (windowHeight <= 750) {
      widthContainer = 405;
    } else {
      widthContainer = 475;
    }

    const pos = getTranslateY(index, widthContainer);
    if (scroll && scroll.left && document.getElementById('categories_slider')) {
      scroll.left(document.getElementById('categories_slider'), pos, { duration: 150 }, { ease: ease.inOutBounce });
    }

    if (index === 0) {
      if (scroll && scroll.left && document.getElementById('categories_slider')) {
        scroll.left(document.getElementById('categories_slider'), 0, { duration: 150 }, { ease: ease.inOutBounce });
      }
    }
  };

  onSetActive = debounce((index, name) => {
    if (window.pageYOffset === 0) {
      this.scroller(0, name);
    } else {
      this.scroller(index, name);
    }
  }, 300);

  render() {
    const {
      name,
      onClick,
      windowHeight,
      remedyActive,
      handleToggle,
      toggleRemedy,
      categoryIndex,
      handleClickImageCategory,
      devicePrefix,
    } = this.props;
    const { offsetTop } = this.state;
    let widthStyle = null;

    let offsetHeight = 195;

    if (name && name.length < 5) {
      if (window.isMobile && name.length < 4) {
        widthStyle = 101 * name.length;
      } else if (windowHeight <= 750 && !window.isTablet) {
        widthStyle = 102 * name.length;
      } else {
        widthStyle = 118 * name.length;
      }
    }

    if (windowHeight > 580 && windowHeight < 750 && !window.isMobile && !window.isTablet) {
      offsetHeight = 175;
    }

    // if (window.isMobile && window.orientation === 0) {
    //   offsetHeight = ['50%'];
    // }

    // if (window.isMobile && window.orientation === 90) {
    //   offsetHeight = ['53.5%', '45.5%'];
    // }

    return (
      <div
        style={
          this.state.viewHeader
            ? { boxShadow: '0 0 0 4px white, 0 4px 4px rgba(0, 0, 0, 0.12)' }
            : null
        }
        className={cx(`${devicePrefix}-products-category-container-fixed`)}
      >
        <div
          style={{ width: widthStyle }}
          className={cx(`${devicePrefix}-products-category-wrapper`)}
        >
          <div
            ref={this.categorySliderNode}
            id="categories_slider"
            className={cx(`${devicePrefix}-category-scroller`)}
          >
            <PrevArrow
              disabled={(name && name.length <= 5) || window.isTablet}
              id="category"
              customClassName={cx('category-arrow-prev')}
              toScroll={direction => this.scrollToDirection(direction, 'categories_slider', 'prev')}
            />
            {name
              && name.map((category, key) => (
                <Popup
                  disabled={!category.description}
                  position="bottom center"
                  key={category.name}
                  trigger={(
                    <div
                      className={cx(`${devicePrefix}-image-category-container`)}
                      style={window.isMobile ? { width: '107px !important' } : null}
                      id={`${category.name}_category`}
                    >
                      <Link
                        active={cx('active')}
                        className={cx(`${devicePrefix}-category-card`)}
                        to={category.name.toLowerCase()}
                        onFocus={() => this.onSetActive(key, category.name)}
                        duration={450}
                        offsetTop={offsetHeight}
                      >
                        <img
                          width="58px"
                          height="58px"
                          src={category.logo && category.logo.default}
                          alt=""
                          onTouchTap={() => handleClickImageCategory(
                            category.name.toLowerCase(),
                            offsetHeight === -250 && key === name.length - 1 ? -260 : offsetHeight
                          )
                          }
                        />
                      </Link>
                      <span className={cx(`${devicePrefix}-category-name`)}>{category.name}</span>
                    </div>
                  )}
                >
                  <PopupContent>
                    {category.description}
                  </PopupContent>
                </Popup>
              ))}
            <NextArrow
              disabled={(name && name.length <= 5) || window.isTablet}
              id="category"
              customClassName={cx('category-arrow-next')}
              toScroll={direction => this.scrollToDirection(direction, 'categories_slider', 'next')}
            />
          </div>
        </div>
        <div
          style={window.isMobile ? { top: '0px' } : null}
          className={cx(
            'remedy-toggle-active',
            remedyActive && remedyActive.length > 0 && this.state.viewHeader && 'slide-in-top'
          )}
        >
          {remedyActive
            && remedyActive.length > 0
            && this.state.viewHeader && (
              <div className={cx('remedy-toggle-wrapper')}>
                <ToggleButton
                  size="large"
                  styles={
                    toggleRemedy
                      ? { backgroundColor: `#${remedyActive[0].color}` }
                      : { backgroundColor: '#ccc' }
                  }
                  handleChange={toggle => handleToggle(remedyActive[0], toggle)}
                  stateToggle={toggleRemedy}
                />
                <span className={cx('remedy-toggle-name-active')}>{remedyActive[0].name}</span>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default SliderCategories;
