import React from 'react';
import classnames from 'classnames/bind';

import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import Image from 'components/Image/Image';
import Icon from 'components/Icons/Icon';
import Container from 'components/Container/Container';
import Label from 'components/Label/Label';

import styles from './Header.module.scss';

const logo = require('images/logo_white.png');

const cx = classnames.bind(styles);

const Header = ({ totalQuantity, history, domainPath }) => {
  const goToPage = (link) => {
    history.push(`${domainPath}${link}`);
  }

  return (
    <Menu fixed="top" className={cx('custom-header')} inverted borderless>
      <Container style={{ display: 'flex', flexDirection: 'inherit', alignItems: 'inherit' }}>
        <MenuItem onClick={() => goToPage('')}>
          <Image style={{ width: '6.5em' }} src={logo} />
        </MenuItem>
        <MenuItem position="right" onClick={() => goToPage('doctor/cart')}>
          <Icon name="cart" size="large" style={{ cursor: 'pointer' }} className={cx('scale-icon')} />
          {
            totalQuantity >= 1 &&
            <Label as="span" circular color='yellow' floating className={cx('custom-label')}>
              {totalQuantity}
            </Label>
          }
        </MenuItem>
      </Container>
    </Menu>
  )
}

export default Header;
