import React from 'react';
import Divider from 'components/Divider';

import classnames from 'classnames/bind';
import styles from './UserEvaluation.module.scss';

const cx = classnames.bind(styles);

export default props => (
  <div>
    <Divider>Resources from GetHeally</Divider>
    <div
      className={cx('getheally-resources-container')}
      style={window.isMobile ? { flexDirection: 'column' } : null}
    >
      {props.resources.value.getheally_id_photo && (
        <div
          className={cx('getheally-id-photo')}
          onClick={() =>
            props.openPictureFromGetheally(props.resources.value.getheally_id_photo.url)
          }
        >
          <img
            style={{ width: '100%', height: 'auto' }}
            src={`${props.resources.value.getheally_id_photo.url}&thumb=1`}
          />
        </div>
      )}
      {props.resources.value.getheally_evaluation && (
        <div className={cx('getheally-user-evaluation')}>
          <img
            style={window.isMobile ? { width: '206px' } : { width: '380px' }}
            src={require('images/Adobe_PDF_Icon.svg')}
            onClick={() =>
              props.openPictureFromGetheally(props.resources.value.getheally_evaluation.url)
            }
          />
        </div>
      )}
    </div>
  </div>
);
