import React from 'react';
import ReactSwipeEvents from 'react-swipe-events';

import Button from 'components/Button/Button';

import Product from 'components/Icons/Product';
import Icon from 'components/Icons/Icon';
import Divider from 'components/Divider';

import classnames from 'classnames/bind';
import styles from './Drawer.module.scss';

const cx = classnames.bind(styles);

const userItems = [
  {
    type: 'client',
    value: 'orders',
    link: 'user_orders',
    icon: 'file alternate',
  },
  {
    type: 'client',
    value: 'my profile',
    link: 'user',
    icon: 'user',
  },
  {
    type: 'seller',
    value: 'my profile',
    link: 'user',
    icon: 'user',
  },
];

const linksForUserTypes = {
  manager: '/manage',
  seller: '/seller_panel',
  admin: '/manage',
  doctor: '/doctor'
};

const Drawer = props => (
  <ReactSwipeEvents onSwipedLeft={props.onClickItem}>
    <div
      style={
        props.theme === 'dark'
          ? { backgroundColor: '#1c5872' }
          : window.isMobile ? { backgroundColor: 'rgba(0,0,0,0.9)' } : { backgroundColor: 'rgba(0,0,0,0.5)' }
      }
      className={cx('sidebar', props.open && 'sidebar-open')}
    >
      <div className={cx('sidebar-wrapper')}>
        <div style={{ marginTop: '1rem' }}>
          <span
            className={cx('menu-item')}
            onClick={() => props.onClickItem(`${props.domainPath}`)}
          >
            <div className={cx('menu-item-name')}>
              <Icon name="home" size="large" />
              &nbsp;&nbsp;Home
            </div>
          </span>
        </div>
        {props.secondaryInfoBlock}
        {props.userInfo
          && userItems.map((value, key) => {
            if (value.type === props.userInfo.type) {
              return (
                <div key={key}>
                  <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}${value.link}`)}>
                    <div className={cx('menu-item-name')}>
                      <Icon name={value.icon} size="large" />
                      &nbsp;&nbsp;
                      {value.value}
                    </div>
                  </span>
                </div>
              );
            }
          })}
        {props.storeQuery && (
          <div>
            <span
              className={cx('menu-item')}
              onClick={() => props.onClickItem({ link: props.storeQuery })}
            >
              <div className={cx('menu-item-name')}>
                <Icon name="shopping bag" size="large" />
                &nbsp;&nbsp;return to products
              </div>
            </span>
          </div>
        )}
        {props.userInfo && props.userInfo.type === 'doctor' &&
          <div>
            <Divider />
            <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}doctor`)}>
              <div className={cx('menu-item-name')}>
                <Icon name="doctor" size="large" />
                &nbsp;Doctor panel
                </div>
            </span>
          </div>
        }
        {props.userInfo && props.userInfo.type === 'seller'
          ? (
            <div>
              <Divider />
              <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}seller/store_info`)}>
                <div className={cx('menu-item-name')}>
                  <Icon name="building" size="large" />
                  &nbsp;Store info
                </div>
              </span>
              <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}seller/delivery_location`)}>
                <div className={cx('menu-item-name')}>
                  <Icon name="map marker alternate" size="large" />
                  &nbsp;Delivery location
                </div>
              </span>
              <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}seller/manage_products`)}>
                <div className={cx('menu-item-name')}>
                  <Icon name="shopping cart" size="large" />
                  &nbsp;Manage Products
                </div>
              </span>
              <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}seller/manage_orders`)}>
                <div className={cx('menu-item-name')}>
                  <Icon name="shopping bag" size="large" />
                  &nbsp;Manage Orders
                </div>
              </span>
              <span className={cx('menu-item')} onClick={() => props.onClickItem(`${props.domainPath}seller/suggest_products`)}>
                <div className={cx('menu-item-name')}>
                  <Icon name="leaf" size="large" />
                  &nbsp;Suggested products
                </div>
              </span>
            </div>
          ) : null
        }
        {
          props.userInfo && props.userInfo.type === 'admin' ? (
            <div>
              <Divider />
              <span className={cx('menu-item')} onClick={() => window.location = linksForUserTypes[props.userInfo.type]}>
                <div className={cx('menu-item-name')}>
                  <Icon name="address card" size="large" />
                  &nbsp;Admin panel
                </div>
              </span>
            </div>
          ) : null
        }
        {props.viewButtons && (
          <div className={cx('button-wrapper')}>
            <Divider />
            {!props.successLogIn ? (
              <Button
                color="teal"
                type="button"
                onClick={() => props.openModal('heally')}
              >
                Log in

              </Button>
            ) : (
                <Button
                  color="teal"
                  type="button"
                  onClick={props.onSignOut}
                >
                  Log out

                </Button>
              )}
          </div>
        )}
      </div>
    </div>
  </ReactSwipeEvents>
);

export default Drawer;
