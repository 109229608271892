import React, { Component } from 'react';
import { connect } from 'react-redux';

import WelcomePage from '../../components/WelcomePage';

import { onSignOut, openModalWindow } from 'modules/user/actions';
import { getLogInInfo, getUserInfo, getIsFetchingUserInfo } from 'modules/user/selectors';

import { setLocallyDeliveryAddress } from 'modules/cart/actions';

import {
  getCurrentGeolocation,
  clearFieldAddress,
  setAddress,
  setGeocodeByAddress,
} from 'modules/geography/actions';

import {
  setDeliveryAddress
} from 'modules/base/actions';

import {
  resetStores
} from 'modules/stores/actions';

import {
  getFetchingCurrentPosition,
  getGeocodeData,
  getAddress,
} from 'modules/geography/selectors';

import { getBrandInformation } from 'modules/base/selectors';

class WelcomePageWrapper extends Component {
  componentWillMount() {
    const viewportmeta = document.querySelector('meta[name="viewport"]');
    viewportmeta.content =
      'width=device-width, initial-scale=1.0, user-scalable=no, viewport-fit=cover';
  }
  render() {
    const {
      history,
      address,
      onSignOut,
      setAddress,
      successLogIn,
      openModalWindow,
      clearFieldAddress,
      setDeliveryAddress,
      getCurrentGeolocation,
      isFetchingCurrentPosition,
      setGeocodeByAddress,
      userInfo,
      geocodeData,
      location,
      brandInformation,
      isFetchingUserInfo,
      resetStores,
      domainPath
    } = this.props;
    return (
      <WelcomePage
        router={history}
        address={address}
        onSignOut={onSignOut}
        setAddress={setAddress}
        geocodeData={geocodeData}
        successLogIn={successLogIn}
        openModalWindow={openModalWindow}
        clearFieldAddress={clearFieldAddress}
        setDeliveryAddress={setDeliveryAddress}
        getCurrentGeolocation={getCurrentGeolocation}
        isFetchingCurrentPosition={isFetchingCurrentPosition}
        setGeocodeByAddress={setGeocodeByAddress}
        userInfo={userInfo}
        location={location}
        brandInformation={brandInformation}
        isFetchingUserInfo={isFetchingUserInfo}
        setLocallyDeliveryAddress={setLocallyDeliveryAddress}
        resetStores={resetStores}
        domainPath={domainPath}
      />
    );
  }
}

const mapStateToProps = state => ({
  successLogIn: getLogInInfo(state.user),
  isFetchingCurrentPosition: getFetchingCurrentPosition(state.geography),
  geocodeData: getGeocodeData(state.geography),
  address: getAddress(state.geography),
  userInfo: getUserInfo(state.user),
  brandInformation: getBrandInformation(state.base),
  isFetchingUserInfo: getIsFetchingUserInfo(state.user),
});

export default connect(
  mapStateToProps,
  {
    onSignOut,
    openModalWindow,
    setDeliveryAddress,
    getCurrentGeolocation,
    clearFieldAddress,
    setAddress,
    setGeocodeByAddress,
    setLocallyDeliveryAddress,
    resetStores,
  }
)(WelcomePageWrapper);
