import React, { Component } from 'react';
import CheckBox from 'components/CheckBox';
// import Button from 'components/Button';
import Form from 'components/Form/Form';
import Spinner from 'components/Loaders/Spin';
import Icon from 'components/Icons/Icon';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import classnames from 'classnames/bind';
import styles from './SignIn.module.scss';

import { checkEmail } from 'services';

const cx = classnames.bind(styles);

const styleError = {
  border: '2px solid rgb(244, 67, 54)',
};

class SignIn extends Component {
  state = {
    email: '',
    emailError: false,
    password: '',
    passwordError: false,
    checked: 'client',
    showPasswordInput: false,
  };

  componentDidMount() {
    if (this.recaptcha) {
      this.recaptcha.execute();
    }
  }

  onCheckEmail = e => {
    if (checkEmail(e.target.value)) {
      this.setState({
        email: e.target.value,
        emailError: false,
      });
    } else {
      this.setState({
        emailError: true,
      });
    }
  };

  onCheckPassword = e => {
    const password = e.target.value.trim();
    if (password.length >= 8) {
      this.setState({
        password,
        passwordError: false,
      });
    } else {
      this.setState({
        passwordError: true,
      });
    }
  };

  onChecked = check => {
    let checked = 'client';
    if (!check) checked = 'seller';

    this.setState({
      checked,
    });
  };

  onLogin = () => {
    const { onSignIn, stateModal, router, location } = this.props;

    const email = this.state.email;
    const password = this.state.password;
    const { pathname } = window.location;

    onSignIn(email, password, stateModal, router);
  };

  showPassword = () => {
    this.setState({
      showPasswordInput: !this.state.showPasswordInput,
    });
  };

  render() {
    const enableButton =
      this.state.email.length > 0 &&
      !this.state.emailError &&
      this.state.password.length > 0 &&
      !this.state.passwordError;

    const { renderForm, signInInfo, stateModal, isPartnerLogin } = this.props;

    let errorCode = null;

    if (signInInfo.errorSignIn) {
      switch (signInInfo.errorSignIn.error_code) {
        case 'user.email_not_found':
          errorCode = 'User email not found';
          break;
        case 'user.invalid_password':
          errorCode = 'User invalid password';
          break;
        case 'user.invalid_password_or_email':
          errorCode = 'Invalid password or email';
          break;
        case 'recaptcha.validation_fail':
        case 'recaptcha.no_param':
          errorCode = 'Please answer to the Captcha question correctly';
          break;
        default:
          break;
      }
    }

    return (
      <Form loading={signInInfo.isFetchingSignIn}>
        <div className={cx('logIn-container')}>
          <h3 className={cx('logIn-title')}>{stateModal === 'auth' ? 'seller log in' : 'log in'}</h3>
          <div className={cx('logIn-email')}>
            <Input
              style={{ width: '100%' }}
              type="text"
              error={this.state.emailError}
              placeholder="E-mail (Login)"
              onChange={this.onCheckEmail}
              className={cx('input-wrapper')}
              fluid
            />
            {this.state.emailError && (
              <span className={cx('email-hint')}>Example: test@test.com</span>
            )}
          </div>
          <div className={cx('logIn-password')}>
            <Input
              fluid
              type={this.state.showPasswordInput ? 'text' : 'password'}
              error={this.state.passwordError}
              icon
              placeholder='Password'
              className={cx('input-wrapper')}
              onChange={this.onCheckPassword}>
              <input />
              <Icon link style={{ cursor: 'pointer', pointerEvents: 'auto' }} name={this.state.showPasswordInput ? 'eye' : 'eye slash'} onClick={this.showPassword} />
            </Input>
            {this.state.passwordError && (
              <span className={cx('password-hint')}>At least 8 characters</span>
            )}
          </div>
          {errorCode && <div className={cx('error-message')}>{errorCode}</div>}
          <Button
            fluid
            color="lightblue"
            onClick={enableButton ? this.onLogin : null}
            type="button"
            disabled={!enableButton}
            style={{ marginBottom: '1em' }}
          >
            log in
          </Button>
          <Button style={{ marginBottom: '1em' }} fluid type="button" color="blue" onClick={() => renderForm('signup')}>
            sign up
          </Button>
          <div className={cx('forgotPassword-container')}>
            <div className={cx('forgotPassword-title')}>If forgot password</div>
            <div>
              <a className={cx('forgotPassword-link')} onClick={() => renderForm('forgotPassword')}>
                click here
              </a>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default SignIn;
