import React, { PureComponent } from 'react';
import getScrollPosition from 'services/scrollPosition';

import classnames from 'classnames/bind';
import styles from './AnimationFrame.module.scss';

const cx = classnames.bind(styles);

class AnimationFrame extends PureComponent {
  state = {
    style: {
      transform: '',
    },
    visible: true,
  };

  componentDidMount() {
    const { product } = this.props;
    const cartPosition = document.getElementById('cart_container').getBoundingClientRect();

    this.runAnimationFrame(cartPosition, product);
  }

  componentWillMount() {
    clearTimeout(this.intervalEnd);
  }

  runAnimationFrame = (cartPosition, product) => {
    const { removeAnimationFrameElement } = this.props;

    removeAnimationFrameElement(false);
    this.setState({
      style: {
        transform: `translate(${cartPosition.x -
          product.x -
          ((product.width * 10) / 100) * 4.5}px, ${cartPosition.y -
          product.y -
          ((product.height * 10) / 100) * 4.5}px) scale(0.1)`,
        transition: 'all 600ms ease-out',
        opacity: '0.5',
      },
    });

    this.intervalEnd = setTimeout(() => {
      this.setState(
        {
          visible: false,
        },
        // () => removeAnimationFrameElement(true)
      );
    }, 600);
  };

  render() {
    const { product, id } = this.props;
    if (this.state.visible) {
      return (
        <img
          id={`${id}_animation_frame`}
          style={{
            top: `${product.y + getScrollPosition()[1]}px`,
            left: `${product.x + getScrollPosition()[0]}px`,
            ...this.state.style,
          }}
          className={cx('product-image-frame')}
          src={product.productInformation.image.wide}
          width={product.width}
          height={product.height}
          alt=""
        />
      );
    }
    return null;
  }
}

export default AnimationFrame;
