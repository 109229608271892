import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import treeChanges from 'tree-changes';

import { checkEmail } from 'services';

// import Image from 'components/Image';
// import Button from 'components/Button';
import Checkbox from 'components/CheckBox/Checkbox';
import ImageUpload from 'components/ImageUpload';
import UnauthorizeUserMessage from 'components/Messages/UnauthorizeUser';
import Loader from 'components/Loaders/Loader';
import Form from 'components/Form/Form';
import notification from 'components/Notification';
import FormField from 'components/Form/FormField';
import Label from 'components/Label/Label';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Divider from 'components/Divider';
import Image from 'components/Image/Image';
import Header from 'components/Typography/Header/Header';

import Modal from 'components/ModalWindow/Modal';
import ModalContent from 'components/ModalWindow/ModalContent';
import Icon from 'components/Icons/Icon';
import ModalHeader from 'components/ModalWindow/ModalHeader';
import ModalActions from 'components/ModalWindow/ModalActions';
import ModalPhotoId from 'components/ModalWindow/ModalBodies/ModalPhotoId';

import Grid from 'components/Grid/Grid';
import GridColumn from 'components/Grid/GridColumn';
import GridRow from 'components/Grid/GridRow';
import Popup from 'components/Popup/Popup';

import classnames from 'classnames/bind';
import UserEvaluation from './components/UserEvaluation';
import UserProfileData from './components/UserProfileData';
import styles from './User.module.scss';

const cx = classnames.bind(styles);

const iconPassprotMobile = require('images/ID_noImage.jpg');
const iconResolutionMobile = require('images/recommendation_noImage.jpg');

const encodeQueryData = (data) => {
  const ret = [];
  for (const d in data) ret.push(`${d}=${data[d]}`);
  return ret.join('&');
};

class User extends Component {
  state = {
    renderUserEdit: false,
    userProfileState: this.props.userInfo,
    privateData: {
      passwordOne: {
        value: '',
        error: false,
      },
      passwordTwo: {
        value: '',
        error: false,
      },
    },
    passportPreviewImage: null,
    passportData: null,
    resolutionPreviewImage: null,
    resolutionData: null,
    hasPhotoId: false,
    isChoosePhoto: false,
    stateLoadButton: '',
  };

  async componentDidMount() {
    const { sendAuthorizationHash, fetchUserInfo, userInfo } = this.props;
    const hashAuthorization = queryString.parse(window.location.search).authorization

    if (hashAuthorization) {
      await sendAuthorizationHash(hashAuthorization);
      this.editUserProfile();
    }

    if (!userInfo.resources) {
      fetchUserInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const { openModalWindow, successLogIn } = this.props;
    const { changedTo } = treeChanges(prevProps, this.props);

    if (changedTo('userAuthorizedWithHash.status', 'SUCCESS')) {
      this.deleteQueryParam('authorization');
      this.openModalWithTakePhoto('photoId');
    }

    if (changedTo('userAuthorizedWithHash.status', 'FAIL')) {
      this.deleteQueryParam('authorization');
      if (!successLogIn) {
        openModalWindow('heally');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userInfo !== this.state.userProfileState) {
      this.setState({
        userProfileState: nextProps.userInfo,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    window.Raven && window.Raven.captureMessage(errorInfo, { level: 'error' });
  }

  deleteQueryParam = (name) => {
    const { history } = this.props;

    const objectWithQueries = queryString.parse(window.location.search)
    delete objectWithQueries[name];

    const newQueryString = encodeQueryData(objectWithQueries);
    history.replace(`${window.location.pathname}?${newQueryString}`);
  }

  saveUserProfile = () => {
    const { saveUserProfile, userInfo } = this.props;
    const { privateData, userProfileState } = this.state;

    let pass = true;
    let emptyField = false;
    const maxBirhtDate = new Date(this.getMaxDate());
    const currentBirthData = new Date(userProfileState.birth_date.value);

    Object.keys(userProfileState).forEach((info) => {
      if (userProfileState[info].value === '' || userProfileState[info].value === 'Invalid date') {
        userProfileState[info].error = 'This field is required';
        emptyField = true;
      }
    });

    if (currentBirthData.getTime() > maxBirhtDate.getTime()) {
      notification.warning({
        message: 'Warning',
        description: 'Your age must be over 18 years old',
      });
      return;
    }

    const checkPasswords = privateData.passwordOne.value === '' && privateData.passwordTwo.value === '';

    if (!checkPasswords) {
      pass = privateData.passwordOne.value.trim().length >= 8
        && privateData.passwordTwo.value.trim().length >= 8
        && privateData.passwordOne.value === privateData.passwordTwo.value;
    }

    if (emptyField) {
      this.setState({
        userProfileState,
      });
    }

    if (!pass) {
      privateData.passwordOne.error = 'This field is required';
      privateData.passwordTwo.error = 'Password should be match';
      this.setState({
        privateData,
      });
    }

    if (emptyField || !pass) return;

    const formData = new FormData();

    Object.keys(userProfileState).forEach((profile) => {
      if (profile !== 'resources') {
        formData.append([`user[${profile}]`], userProfileState[profile].value);
      }
    });

    if (!checkPasswords) {
      formData.append('password', privateData.passwordOne.value);
      formData.append('password_confirmation', privateData.passwordTwo.value);
    }
    if (this.state.passportData) {
      formData.append('user[id_photo]', this.state.passportData);
    }

    if (this.state.resolutionData) {
      this.state.resolutionData && formData.append('user[evaluation]', this.state.resolutionData);
    }

    this.setState({
      renderUserEdit: false,
    });
    try {
      saveUserProfile(formData);
    } catch (e) {
      window.Raven && window.Raven.captureMessage('Error saveUserProfile method', { level: 'error' });
    }
  };

  onCancelUserEdit = () => {
    const { cancelChangeUserProfile } = this.props;
    const { userProfileState, privateData } = this.state;

    Object.keys(userProfileState).forEach((profile) => {
      userProfileState[profile].error = false;
    });

    Object.keys(privateData).forEach((field) => {
      privateData[field].error = false;
    });

    cancelChangeUserProfile();
    this.setState({
      renderUserEdit: false,
      userProfileState,
      privateData,
      passportPreviewImage: null,
      resolutionPreviewImage: null,
    });
  };

  onChangeUserProfile = (value, profile) => {
    const { changeUserProfile, userInfo } = this.props;
    const { userProfileState } = this.state;

    if (profile.name === 'email' && profile.name !== 'two_factor_auth') {
      if (!checkEmail(profile.value)) {
        userProfileState.email.error = 'Hint: test@test.com';
      } else {
        userProfileState.email.error = false;
      }
    }

    if (userInfo.type.value === 'seller' && profile.name === 'two_factor_auth') {
      userProfileState.two_factor_auth = {
        value: !profile.checked,
      };
    }

    if (profile.name !== 'two_factor_auth') {
      userProfileState[profile.name].value = profile.value;
    }

    this.setState({
      userProfileState,
    });
  };

  onChangePassword = (value, field) => {
    const { privateData } = this.state;
    privateData[field.name].value = field.value;

    if (field.name === 'passwordTwo') {
      if (privateData.passwordOne.value !== privateData.passwordTwo.value) {
        privateData.passwordTwo.error = 'Passwords should match';
      } else {
        privateData.passwordTwo.error = false;
      }
    }

    if (field.name === 'passwordOne' && field.value.trim().length < 8) {
      privateData.passwordOne.error = 'At least 8 characters';
    } else {
      privateData.passwordOne.error = false;
    }

    this.setState({
      privateData,
    });
  };

  onChangePasswordConfirmation = (passwordConfirmation) => {
    let passwordConfirmationError = false;
    if (passwordConfirmation.trim().length < 8) {
      passwordConfirmationError = true;
    }
    this.setState({
      passwordConfirmationError,
      passwordConfirmation,
    });
  };

  onPassportPreview = (picture, imagePreview) => {
    this.setState({
      passportData: picture,
      passportPreviewImage: imagePreview,
    });
  };

  onResolutionPreview = (picture, imagePreview) => {
    this.setState({
      resolutionData: picture,
      resolutionPreviewImage: imagePreview,
    });
  };

  onError = (message) => {
    notification.warning({
      message: 'Warning',
      description: message,
    });
  };

  editUserProfile = () => {
    this.setState({
      renderUserEdit: true,
    });
  };

  openPictureNewTab = (field) => {
    const { location } = this.props;
    const { userProfileState } = this.state;
    const addressImage = userProfileState.resources.value[field].url;

    if (addressImage) {
      window.open(`${window.location.origin}${addressImage}`, '_blank');
    }
  };

  openPictureFromGetheally = (url) => {
    const { location } = this.props;

    window.open(`${window.location.origin}${url}`, '_blank');
  };

  replaceNumber = (value) => {
    if (value) {
      const USNumber = value
        .toString()
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !USNumber[2]
        ? USNumber[1]
        : `(${USNumber[1]}) ${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;
      return value;
    }
    return '';
  };

  getMaxDate = () => {
    const dt = new Date();
    if (dt && dt.getDate()) {
      dt.setDate(dt.getDate() - 6570);
      const format_address = `${dt.getFullYear()}-${(`0${dt.getMonth() + 1}`).slice(-2)}-${(`0${dt.getDate()}`).slice(-2)}`;
      return format_address;
    }
    return '';
  }

  closeModalWithPhotoId = () => {
    this.setState({
      hasPhotoId: false,
    });
  }

  openModalWithTakePhoto = (state) => {
    this.setState({
      hasPhotoId: true,
      stateLoadButton: state,
    });
  }

  chooseIdPhoto = () => {
    this.setState({
      isChoosePhoto: true,
      hasPhotoId: false,
    });
  }

  render() {
    const { userInfo, openModalWindow, isFetchingUserInfo, isSaveUserProfile, saveUserProfile, userAuthorizedWithHash } = this.props;
    const { userProfileState, hasPhotoId, stateLoadButton, passportData, resolutionData } = this.state;

    if (isFetchingUserInfo) {
      return (
        <div className={cx('event-container')}>
          <Loader active inline="center" />
        </div>
      );
    }

    if (userInfo && Object.keys(userInfo).length === 0) {
      return <UnauthorizeUserMessage openModalWindow={openModalWindow} name="Profile" />;
    }

    const hasPassportData = userProfileState.resources
      && userProfileState.resources.value
      && userProfileState.resources.value.user_id_photo
      && !this.state.passportData;

    const hasResolutionData = userProfileState.resources
      && userProfileState.resources.value
      && userProfileState.resources.value.user_evaluation
      && !this.state.resolutionData;

    const hasResourceFromGetHeally = (userInfo.resources && userInfo.resources.value.getheally_evaluation)
      || (userInfo.resources && userInfo.resources.value.getheally_id_photo);

    return (
      <div className={cx('user-wrapper')}>
        <Form loading={isSaveUserProfile || userAuthorizedWithHash.status === 'RUNNING'}>
          <div className={cx('container')}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }} className={cx('user-profile-container')}>
              {
                hasResourceFromGetHeally
                && (
                  <div className={cx('divider-custom')}>
                    <Header as="h3" dividing>
                      Resource from GetHeally
                    </Header>
                    <FormField inline style={{ display: 'flex' }}>
                      <label style={{ width: '170px', textAlign: 'right', fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        GetHeally Id Photo:
                      </label>
                      <div style={{ width: '50%', position: 'relative' }}>
                        <Image size="small" src={`${userInfo.resources.value.getheally_id_photo.url}&thumb=1`} onClick={() => this.openPictureFromGetheally(userInfo.resources.value.getheally_id_photo.url)} />
                      </div>
                    </FormField>
                    <FormField inline style={{ display: 'flex' }}>
                      <label style={{ width: '170px', textAlign: 'right', fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        GetHeally User Evaluation:
                      </label>
                      <div style={{ width: '50%', position: 'relative' }}>
                        <Image size="small" src={require('images/Adobe_PDF_Icon.svg')} onClick={() => this.openPictureFromGetheally(userInfo.resources.value.getheally_evaluation.url)} />
                      </div>
                    </FormField>
                  </div>
                )
              }
              <Header className={cx('divider-custom')} as="h3" dividing>
                User profile
              </Header>
              <FormField inline className={cx('form-field-image-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  Id Photo
                </label>
                <div style={{ width: '300px', position: 'relative' }}>
                  <Image
                    onClick={this.state.renderUserEdit ? () => this.openPictureNewTab('user_id_photo') : null}
                    size="small"
                    src={hasPassportData ? userProfileState.resources.value.user_id_photo.url : this.state.passportPreviewImage
                      ? this.state.passportPreviewImage
                      : iconPassprotMobile}
                  />
                  {this.state.renderUserEdit && (
                    <div style={{ width: '150px', height: '100%', position: 'absolute', top: 0, left: 0, cursor: 'pointer' }} onClick={() => this.openModalWithTakePhoto('photoId')} />
                  )}
                </div>
              </FormField>
              <FormField inline className={cx('form-field-image-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  Recommendation
                </label>
                <div style={{ width: '300px', position: 'relative' }}>
                  <Image
                    onClick={this.state.renderUserEdit ? () => this.openPictureNewTab('user_evaluation') : null}
                    size="small"
                    src={hasResolutionData ? userProfileState.resources.value.user_evaluation.url : this.state.resolutionPreviewImage
                      ? this.state.resolutionPreviewImage
                      : iconResolutionMobile}
                  />
                  {this.state.renderUserEdit && (
                    <div style={{ width: '150px', height: '100%', position: 'absolute', top: 0, left: 0, cursor: 'pointer' }} onClick={() => this.openModalWithTakePhoto('recommendation')} />
                  )}
                </div>
              </FormField>
              { userInfo && userInfo.invitation_code.value && <Divider className={cx('divider-custom')} /> }
              {
                userInfo && userInfo.invitation_code.value &&
                <FormField inline className={cx('form-field-custom')} style={{ display: 'flex', alignItems: 'center', marginBottom: '0em' }}>
                  <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                    Invitation discount code
                  </label>
                  <div style={{ display: 'inline-block' }} className={cx('input-custom')}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span className={cx('invite-code')}>{userInfo.invitation_code.value}</span>
                      <Popup trigger={<Icon name="info circle" link/>}>Give this invitation discount code to your friend to get discount for you both. This may be applied at cart page.</Popup>
                    </div>
                  </div>
                </FormField>
                  }
              <Divider className={cx('divider-custom')} />
              <FormField inline className={cx('form-field-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  First name
                </label>
                <Input error={userInfo.first_name.error} name="first_name" onChange={this.onChangeUserProfile} value={userInfo.first_name.value} className={cx('input-custom')} type="text" placeholder="First name" disabled={!this.state.renderUserEdit} />
              </FormField>
              <FormField inline className={cx('form-field-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  Last Name
                </label>
                <Input error={userInfo.last_name.error} name="last_name" className={cx('input-custom')} value={userInfo.last_name.value} onChange={this.onChangeUserProfile} type="text" placeholder="Last name" disabled={!this.state.renderUserEdit} />
              </FormField>
              {userInfo.type.value !== 'seller'
                && (
                  <FormField inline className={cx('form-field-custom')}>
                    <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                      E-mail
                    </label>
                    <Input error={userInfo.email.error} name="email" className={cx('input-custom')} onChange={this.onChangeUserProfile} value={userInfo.email.value} type="text" placeholder="Email" disabled={!this.state.renderUserEdit} />
                  </FormField>
                )
              }
              <FormField inline className={cx('form-field-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  Address
                </label>
                <Input error={userInfo.address.error} name="address" value={userInfo.address.value} className={cx('input-custom')} onChange={this.onChangeUserProfile} type="text" placeholder="Address" disabled={!this.state.renderUserEdit} />
              </FormField>
              {userInfo.type.value !== 'seller'
                && (
                  <FormField inline className={cx('form-field-custom')}>
                    <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                      Birth date
                    </label>
                    <Input
                      error={userInfo.birth_date.error}
                      name="birth_date"
                      className={cx('input-custom')}
                      onChange={this.onChangeUserProfile}
                      type="date"
                      pattern="[0-9]"
                      inputMode="numeric"
                      value={userInfo.birth_date.value}
                      name="birth_date"
                      placeholder="birth date"
                      required
                      disabled={!this.state.renderUserEdit}
                      max={this.getMaxDate()}
                    />
                  </FormField>
                )
              }
              <FormField inline className={cx('form-field-custom')}>
                <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                  Tel.
                </label>
                <Input
                  pattern="[0-9]*"
                  inputMode="numeric"
                  error={userInfo.phone.error}
                  name="phone"
                  className={cx('input-custom')}
                  onChange={this.onChangeUserProfile}
                  value={this.replaceNumber(userInfo.phone.value)}
                  type="tel"
                  placeholder="Tel."
                  disabled={!this.state.renderUserEdit}
                />
              </FormField>
              {userInfo.type.value === 'seller'
                && (
                  <FormField inline className={cx('form-field-custom')} style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                      2 factor auth
                    </label>
                    <div style={{ display: 'inline-block' }} className={cx('input-custom')}>
                      <Checkbox toggle disabled={!this.state.renderUserEdit} name="two_factor_auth" onClick={this.state.renderUserEdit ? this.onChangeUserProfile : null} checked={userInfo.two_factor_auth.value} />
                    </div>
                  </FormField>
                )
              }
              <Divider className={cx('divider-custom')} />
                  {this.state.renderUserEdit
                    && (
                      <div className={cx('passwords-container')}>
                        <FormField inline className={cx('form-field-custom')}>
                          <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                            Password
                      </label>
                          <Input error={this.state.privateData.passwordOne.error} name="passwordOne" className={cx('input-custom')} onChange={this.onChangePassword} value={userInfo.phone.password} type="password" placeholder="Password" disabled={!this.state.renderUserEdit} />
                        </FormField>
                        <FormField inline className={cx('form-field-custom')}>
                          <label style={{ width: '170px', textAlign: 'right', fontWeight: 700 }} className={cx('label-name')}>
                            Repeat password
                      </label>
                          <Input error={this.state.privateData.passwordTwo.error} name="passwordTwo" className={cx('input-custom')} onChange={this.onChangePassword} value={userInfo.phone.password_confirmation} type="password" placeholder="Repeat password" disabled={!this.state.renderUserEdit} />
                        </FormField>
                        <Divider style={{ width: '100%' }} />
                      </div>
                    )
                  }
                  <div style={{ marginBottom: '1rem' }} className={cx('buttons-container')}>
                    <Button className={cx('mobile-button')} type="button" color="teal" onClick={this.state.renderUserEdit ? this.saveUserProfile : this.editUserProfile}>{this.state.renderUserEdit ? 'save' : 'edit'}</Button>
                    {this.state.renderUserEdit && (
                      <Button
                        onClick={this.onCancelUserEdit}
                        secondary
                        type="button"
                        className={cx('mobile-button')}
                      >
                        Cancel
    
                  </Button>
                    )}
                  </div>
            </div>
          </div>
        </Form>
          <Modal
            open={hasPhotoId}
            closeIcon={<Icon name="close" link onClick={this.closeModalWithPhotoId} />}
            onClose={this.closeModalWithPhotoId}
            size="tiny"
          >
            <ModalHeader>Load Document</ModalHeader>
            <ModalContent>
              <p>Please take a photo or upload an existent file. Images and PDFs are supported.</p>
              {passportData && stateLoadButton === 'photoId' && <p style={{ color: '#148fbc', fontWeight: 'bold' }}>{passportData.name}</p>}
              {resolutionData && stateLoadButton === 'recommendation' && <p style={{ color: '#148fbc', fontWeight: 'bold' }}>{resolutionData.name}</p>}
            </ModalContent>
            <ModalActions style={{ textAlign: 'center' }}>
              {/* <ModalPhotoId
              saveUserProfile={saveUserProfile}
              fieldForSave={passportData && stateLoadButton === 'photoId' ? 'user[photo_id]' : 'user[evaluation]'}
            /> */}
              {passportData || resolutionData ? (
                <Button secondary type="button" onClick={this.closeModalWithPhotoId}>
                  <Icon name="check" />
                  Done
              </Button>
              ) : null}
              <Button primary type="button" style={{ position: 'relative' }}>
                <Icon name="file alternate outline" />
                Take photo or choose file
                {
                  stateLoadButton === 'photoId'
                    ? <ImageUpload imagePreview={this.onPassportPreview} handleError={this.onError} />
                    : <ImageUpload imagePreview={this.onResolutionPreview} handleError={this.onError} />
                }
              </Button>
            </ModalActions>
          </Modal>
      </div>
        );
      }
    }
    
    export default User;
