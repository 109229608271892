import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Polygon, Marker, InfoWindow } from 'react-google-maps';

import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Icon from 'components/Icons/Icon';

import Item from 'components/Item/Item';
import ItemContent from 'components/Item/ItemContent';
import ItemHeader from 'components/Item/ItemHeader';
import ItemMeta from 'components/Item/ItemMeta';
import ItemExtra from 'components/Item/ItemExtra';
import ItemImage from 'components/Item/ItemImage';
import Label from 'components/Label/Label';
import Loader from 'components/Loaders/Loader';

import classnames from 'classnames/bind';

import styles from './Main.module.scss';
import CustomMarker from './components/Marker';

const cx = classnames.bind(styles);

const MapWithAMarker = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={props.center}
    center={props.center}
    ref={props.onMapLoad}
    zoom={props.zoom}
  >
    {Object.keys(props.listWithDeliveryLocations).length > 0 && Object.keys(props.listWithDeliveryLocations).map((pos, index) => (
      <Marker
        key={index}
        position={props.listWithDeliveryLocations[pos].coordinates}
        onClick={() => props.showInfo(index, props.listWithDeliveryLocations[pos])}
      >
        {(props.showInfoIndex === index)
          && (
            <InfoWindow>
              {
                props.isFetchingDeliveryLocationData
                  ? <Loader active inline="center" />
                  : (
                    <Item>
                      <ItemImage src="https://s3-us-west-1.amazonaws.com/heallymarketplace/no-photo_290x181.png" size="small" />
                      <ItemContent style={{ minWidth: 0, width: 'auto', display: 'block', marginLeft: 0, paddingLeft: '1.5em' }}>
                        <ItemHeader>{props.listWithDeliveryLocations[pos].formatted_address}</ItemHeader>
                        <ItemMeta style={{ margin: '0.25em 0 0.25em', display: 'flex', justifyContent: 'space-between', width: '123px' }}>
                          <span style={{ display: 'block' }}>Min order price:</span>
                          {' '}
                          <span style={{ display: 'block' }}>
                            <strong>
                              $
                              {props.listWithDeliveryLocations[pos].min_order_price || 0.0}
                            </strong>

                          </span>
                        </ItemMeta>
                        <ItemMeta style={{ margin: '0.25em 0 0.25em', display: 'flex', justifyContent: 'space-between', width: '123px' }}>
                          <span style={{ display: 'block' }}>Shipping cost:</span>
                          {' '}
                          <span style={{ display: 'block' }}>
                            <strong>
                              $
                              {props.listWithDeliveryLocations[pos].delivery_cost || 0.0}
                            </strong>

                          </span>
                        </ItemMeta>
                        <ItemExtra style={{ margin: '.5rem 0 0' }}>
                          <Label>
                            <Icon name="payment" size="small" />
                            pay online
                          </Label>
                        </ItemExtra>
                      </ItemContent>
                    </Item>
                  )
              }
            </InfoWindow>
          )
        }
      </Marker>
    ))}
    {Object.keys(props.listWithDeliveryLocations).length > 0 && Object.keys(props.listWithDeliveryLocations).map((path, key) => (
      props.listWithDeliveryLocations[path].polygon_coordinates ? (
        <Polygon
          key={key}
          paths={props.listWithDeliveryLocations[path].polygon_coordinates}
          options={{
            fillColor: '#4ac6b4',
            strokeColor: '#4ac6b4',
          }}
        />
      ) : null
    ))}
    {props.polygons && props.polygons.length > 0 && props.polygons.map(v => v)}
  </GoogleMap>
));

class Main extends Component {
  state = {
    editCell: false,
    zoom: 2,
    isOpen: false,
    showInfoIndex: null,
    centerCoordinates: {
      lat: 36.778259,
      lng: -119.417931,
    }
  };

  componentWillReceiveProps(nextProps) {
    const { centerCoordinates } = this.state;

    if (nextProps.centerCoordinates && centerCoordinates !== nextProps.centerCoordinates) {

      this.setState({
        centerCoordinates: nextProps.centerCoordinates,
        zoom: 12,
      })
    }
  }

  handleMapLoad = (map) => {
    const { polygonsForStore } = this.props;

    if (map) {
      this.mapComponent = map;
      const newBounds = window.google && new window.google.maps.LatLngBounds();

      if (polygonsForStore && polygonsForStore.coordinates) {
        polygonsForStore.coordinates.forEach(coordinate => newBounds.extend(coordinate));
      }

      map.fitBounds(newBounds);
      map.getZoom();
    }
  };

  onClickEdit = (key) => {
    this.setState({
      editCell: key,
    });
  };

  onClickCancel = () => {
    this.setState({
      editCell: null,
    });
  }

  onToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  showInfo = (showInfoIndex, deliveryLocationData) => {
    const { fetchDeliveryLocationData, listWithDeliveryLocations } = this.props;
    const { id } = deliveryLocationData;
    this.setState({
      showInfoIndex,
    }, () => {
      fetchDeliveryLocationData(id, deliveryLocationData);
    });
  }

  render() {
    const {
      polygonsForStore,
      isFetchingPolygonsForStore,
      deliveryLocationData,
      isFetchingDeliveryLocationData,
      listWithDeliveryLocations,
      isFethchingListWithDeliveryLocation,
      rerenderTable
    } = this.props;
    const { isApprovedDelete, centerCoordinates } = this.state;

    const listDL = Object.keys(listWithDeliveryLocations);

    return (
      <div>
        <Container className={cx('container-custom')}>
          <MapWithAMarker
            onMapLoad={this.handleMapLoad}
            center={centerCoordinates}
            polygons={this.state.polygons}
            coordinatesForMarkers={polygonsForStore.coordinates}
            zoom={this.state.zoom}
            onToggleOpen={this.onToggleOpen}
            isOpen={this.state.isOpen}
            listWithDeliveryLocations={listWithDeliveryLocations}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            deliveryLocationData={deliveryLocationData}
            showInfo={this.showInfo}
            showInfoIndex={this.state.showInfoIndex}
            isFetchingDeliveryLocationData={isFetchingDeliveryLocationData}
          />
        </Container>
      </div>
    );
  }
}

export default Main;
