import { connect } from 'react-redux';

import {
  getStoreInformation,
  getIsFetchingPolygonsForStore,
  getPolygonsForStore,
  getDeliveryLocationData,
  getIsFetchingDeliveryLocationData,
  getListWithDeliveryLocations,
  getIsFetchingListWithDeliveryLocation,
  getRerenderTable,
} from 'modules/seller/selectors';
import {
  fetchPolygonsForStore,
  fetchDeliveryLocationData,
  deleteDeliveryLocation,
  createDeliveryLocation,
  fetchDeliveryLocationIds,
  updateDeliveryLocation,
} from 'modules/seller/actions';

import DeliveryLocation from '../components/DeliveryLocation';

const mapStateToProps = state => ({
  storeInformation: getStoreInformation(state.seller),
  polygonsForStore: getPolygonsForStore(state.seller),
  isFetchingPolygonsForStore: getIsFetchingPolygonsForStore(state.seller),
  deliveryLocationData: getDeliveryLocationData(state.seller),
  isFetchingDeliveryLocationData: getIsFetchingDeliveryLocationData(state.seller),
  listWithDeliveryLocations: getListWithDeliveryLocations(state.seller),
  isFethchingListWithDeliveryLocation: getIsFetchingListWithDeliveryLocation(state.seller),
  rerenderTable: getRerenderTable(state.seller),
});

export default connect(
  mapStateToProps,
  {
    fetchPolygonsForStore,
    fetchDeliveryLocationData,
    deleteDeliveryLocation,
    createDeliveryLocation,
    fetchDeliveryLocationIds,
    updateDeliveryLocation,
  }
)(DeliveryLocation);
