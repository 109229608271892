import React from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';

import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useTextAlignProp,
  useVerticalAlignProp,
} from 'utils/lib';

import TableCell from './TableCell';

import styles from './Table.scss';

const cx = classnames.bind(styles);

const TableRow = (props) => {
  const {
    active,
    cellAs,
    cells,
    children,
    className,
    disabled,
    error,
    negative,
    positive,
    textAlign,
    verticalAlign,
    warning,
  } = props;

  const classes = cx(
    useKeyOnly(active, 'active'),
    useKeyOnly(disabled, 'disabled'),
    useKeyOnly(error, 'error'),
    useKeyOnly(negative, 'negative'),
    useKeyOnly(positive, 'positive'),
    useKeyOnly(warning, 'warning'),
    useTextAlignProp(textAlign),
    useVerticalAlignProp(verticalAlign),
    className
  );
  const rest = getUnhandledProps(TableRow, props);
  const ElementType = getElementType(TableRow, props);

  if (!isNil(children)) {
    return (
      <ElementType {...rest} className={classes}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...rest} className={classes}>
      {_.map(cells, cell => TableCell.create(cell, { defaultProps: { as: cellAs } }))}
    </ElementType>
  );
};

TableRow.defaultProps = {
  as: 'tr',
  cellAs: 'td',
};

TableRow.create = createShorthandFactory(TableRow, cells => ({ cells }));

export default TableRow;
