import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import * as constants from './constants';

const initialState = {
  isFetching: false,
  isFetchingProduct: false,
  messageFail: false,
  listCategories: null,
  categoriesName: [],
  product: null,
  checkoutProducts: [],
  errorMessage: null,
  productComments: {
    isFetchingProductComments: false,
    data: [],
    error: null,
  },
  newComment: {
    isCreatingProductComment: false,
    data: {},
    error: null,
    status: 'idle'
  },
  updateComment: {
    isUpdatingProductComment: false,
    data: {},
    error: null,
    status: 'idle'
  },
  rating: {
    averege_rate: null,
    product_id: null,
    rate: null,
  }
};

export default handleActions(
  {
    [constants.FETCH_LIST_PRODUCTS]: (state, action) => ({
      ...state,
      isFetching: true,
      listCategories: null,
      categoriesName: [],
      messageFail: false,
    }),

    [constants.FETCH_LIST_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      messageFail: false,
      listCategories: action.categories,
      categoriesName: action.categoriesName,
      checkoutProducts: action.checkoutProducts,
    }),

    [constants.FETCH_LIST_PRODUCTS_FAIL]: (state, action) => ({
      ...state,
      isFetching: false,
      messageFail: true,
    }),

    [constants.FETCH_PRODUCT_INFORMATION]: (state, action) => ({
      ...state,
      isFetchingProduct: true,
      product: null,
    }),

    [constants.FETCH_PRODUCT_INFORMATION_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingProduct: false,
      product: action.payload,
    }),

    [constants.FETCH_PRODUCT_INFORMATION_FAIL]: (state, action) => ({
      ...state,
      isFetchingProduct: false,
      product: null,
      errorMessage: action.error_message,
    }),

    [constants.FILTER_PRODUCTS]: (state, action) => ({
      ...state,
      //listCategories: action.categories,
      //categoriesName: action.categoriesName
    }),

    [constants.BUY_PRODUCT]: (state, action) => {
      const productId = action.productId;
      const categoryId = action.categoryId;
      const { checkoutProducts } = state;
      let bufferForProduct = null;
      let checkoutProductsConcat = [];

      const listCategories = state.listCategories.map((category) => {
        if (category.id === categoryId) {
          category.products.map((product) => {
            if (product.id === productId) {
              product.in_cart = true;
              bufferForProduct = product;
            }
          });
        }
        return category;
      });

      if (bufferForProduct) {
        checkoutProductsConcat = checkoutProducts.concat(bufferForProduct);
      }
      return {
        ...state,
        listCategories,
        checkoutProducts: checkoutProductsConcat,
      };
    },

    [constants.BUY_PRODUCT_CLOSE]: (state, action) => {
      const { checkoutProducts } = state;
      const productId = action.productId;
      const categoryId = action.categoryId;

      const listCategories = state.listCategories.map((category) => {
        if (category.id === categoryId) {
          category.products.map((product) => {
            if (product.id === productId) product.in_cart = false;
          });
        }
        return category;
      });
      if (checkoutProducts.length > 0) {
        const checkoutProductsFilter = checkoutProducts.filter((v) => {
          if (v.id !== productId) {
            return v;
          }
        });
        return {
          ...state,
          listCategories,
          checkoutProducts: checkoutProductsFilter,
        };
      }
      return {
        ...state,
        listCategories,
      };
    },

    [constants.SEND_ERROR_WITH_STORE_ID]: (state, action) => ({
      ...state,
      messageFail: true,
    }),

    [constants.FETCH_PRODUCT_COMMENTS]: (state, action) => {
      if (state.productComments) {
        return immutable(state, {
          productComments: {
            isFetchingProductComments: { $set: true },
          },
        })
      }
      return immutable(state, {
        productComments: {
          $set: {
            data: { $set: [] },
            isFetchingProductComments: { $set: true },
          }
        },
      })
    },
    [constants.FETCH_PRODUCT_COMMENTS_SUCCESS]: (state, action) => immutable(state, {
      productComments: {
        isFetchingProductComments: { $set: false },
        data: { $set: action.payload },
      },
    }),
    [constants.FETCH_PRODUCT_COMMENTS_FAIL]: (state, action) => immutable(state, {
      productComments: {
        isFetchingProductComments: { $set: false },
        data: { $set: null },
        error: { $set: 'error' },
      },
    }),

    [constants.RESET_PRODUCT_INFORMATION]: (state, action) => immutable(state, {
      product: { $set: null },
    }),

    [constants.ADD_COMMENT]: (state, action) => {
      if (state.newComment) {
        return immutable(state, {
          newComment: {
            isCreatingProductComment: { $set: true },
            error: { $set: null },
            status: { $set: 'idle' },
          },
          rating: {
            rate: { $set: null },
            product_id: { $set: null },
            averege_rate: { $set: null },
          }
        })
      }
      return immutable(state, {
        newComment: {
          $set: {
            isCreatingProductComment: { $set: true },
            error: { $set: null },
            status: { $set: 'idle' },
          }
        },
        rating: {
          $set: {
            rate: { $set: null },
            product_id: { $set: null },
            averege_rate: { $set: null },
          }
        }
      })
    },
    [constants.ADD_COMMENT_SUCCESS]: (state, action) => immutable(state, {
      newComment: {
        isCreatingProductComment: { $set: false },
        data: { $set: action.payload },
        status: { $set: 'success' }
      }
    }),
    [constants.ADD_COMMENT_FAIL]: (state, action) => immutable(state, {
      newComment: {
        isCreatingProductComment: { $set: false },
        error: { $set: 'error' },
        status: { $set: 'error' },
      }
    }),

    [constants.UPDATE_COMMENT]: (state, action) => {
      if (state.updateComment) {
        return immutable(state, {
          updateComment: {
            isUpdatingProductComment: { $set: true },
            error: { $set: null },
            status: { $set: 'idle' }
          },
          rating: {
            rate: { $set: null },
            product_id: { $set: null },
            averege_rate: { $set: null },
          },
        })
      }
      return immutable(state, {
        updateComment: {
          $set: {
            isUpdatingProductComment: { $set: true },
            error: { $set: null },
            status: { $set: 'idle' }
          }
        },
        rating: {
          $set: {
            rate: { $set: null },
            product_id: { $set: null },
            averege_rate: { $set: null },
          }
        },
      })
    },
    [constants.UPDATE_COMMENT_SUCCESS]: (state, action) => immutable(state, {
      updateComment: {
        isUpdatingProductComment: { $set: false },
        data: { $set: action.payload },
        status: { $set: 'success' },
      }
    }),
    [constants.UPDATE_COMMENT_FAIL]: (state, action) => immutable(state, {
      updateComment: {
        isUpdatingProductComment: { $set: false },
        error: { $set: 'error' },
        status: { $set: 'error' },
      }
    }),

    [constants.SET_RATING_FOR_PRODUCT_CARD]: (state, action) => {
      if (state.rating) {
        return immutable(state, {
          rating: {
            rate: { $set: action.payload.rate },
            product_id: { $set: action.payload.product_id },
            averege_rate: { $set: action.payload.averege_rate }
          }
        })
      }
      return immutable(state, {
        rating: {
          $set: {
            rate: { $set: action.payload.rate },
            product_id: { $set: action.payload.product_id },
            averege_rate: { $set: action.payload.averege_rate }
          }
        }
      })
    }
  },
  initialState
);
