import React, { Component } from 'react';
import queryString from 'query-string';

import PlantType from 'components/ProductCard/PlantType';
import Image from 'components/Image/Image';
import Spinner from 'components/Loaders/Spin';
import { convertToDecimal } from 'services';
import Loader from 'components/Loaders/Loader';
import Button from 'components/Button/Button';

import CommentGroup from 'components/Comment/CommentGroup';
import Comment from 'components/Comment/Comment';
import CommentAvatar from 'components/Comment/CommentAvatar';
import CommentAuthor from 'components/Comment/CommentAuthor';
import CommentMetadata from 'components/Comment/CommentMetadata';
import CommentText from 'components/Comment/CommentText';
import CommentActions from 'components/Comment/CommentActions';
import CommentAction from 'components/Comment/CommentAction';
import CommentContent from 'components/Comment/CommentContent';
import Header from 'components/Typography/Header/Header';
import Rating from 'components/Rating';

import Form from 'components/Form/Form';
import TextArea from 'components/TextArea/TextArea';

import MDRender from 'services/MDRender';

import classnames from 'classnames/bind';
import Packing from './components/Packing';
import styles from './ModalContent.module.scss';

const GRAM_OZ = ['0.5g', '1g', '2g', '1/8oz', '1/4oz', '1/2oz', '1oz', '2oz'];

const plantTypes = {
  Sativa: { color: '#FDD835', name: 'Sativa' },
  Indica: { color: '#5094B1', name: 'Indica' },
  Hybrid: { color: '#39D4CF', name: 'Hybrid' },
  CBD: { color: '#edb3bf', name: 'CBD' },
  'Hybrid: Sativa-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #FDD835 85%)',
    name: 'Hybrid: Sativa',
  },
  'Hybrid: Indica-dominant': {
    color: 'linear-gradient(90deg, #39D4CF 10%, #5094B1 85%)',
    name: 'Hybrid: Indica',
  },
  'Indica: Sativa-dominant': {
    color: 'linear-gradient(90deg, #5094B1 10%, #FDD835 85%)',
    name: 'Indica: Sativa',
  },
};

const cx = classnames.bind(styles);

class ModalContent extends Component {
  state = {
    price: 0,
    quantity: null,
    updateButton: false,
    removeButton: false,
  };

  componentWillMount() {
    const { location, fetchProductInformation, card } = this.props;

    const productId = card ? card.id : queryString.parse(location.search).product;

    fetchProductInformation(productId);
  }

  componentWillReceiveProps(nextProps) {
    const { closeModal } = this.props;

    if (nextProps.product && nextProps.product === 'error') {
      closeModal();
    }
  }

  setProduct = quantity => {
    this.setState({
      quantity,
      removeButton: false,
    });
    this.changeEventButton();
  };

  removeProduct = () => {
    this.setState({
      removeButton: true,
    });
  };

  removeProductFromCart = product => {
    const { addToCart, removeToCart, closeModal } = this.props;
    removeToCart(product);
    closeModal();
  };

  addToCart = product => {
    const { addToCart, closeModal } = this.props;
    let { quantity } = this.state;

    if (quantity === null) {
      if (product.packing === 'oz') {
        const steps = Object.keys(product.price).sort(
          (a, b) => convertToDecimal(b) - convertToDecimal(a)
        );
        quantity = steps[steps.length - 1];
      }

      if (product.packing === 'gram') {
        const steps = Object.keys(product.price).sort();
        const parseGrams = steps.map(value => parseFloat(value));
        parseGrams.sort((a, b) => a - b);
        quantity = parseGrams[0].toString();
      }

      if (product.packing === 'piece' || product.packing === 'piece_discrete') {
        quantity = 1;
      }

      if (product.packing === 'gram_oz') {
        const filterGramOz = [];
        Object.keys(product.price).forEach((price, index) => {
          if (GRAM_OZ.indexOf(price) >= 0) {
            filterGramOz.push({
              position: index,
              packing: price,
              cost: product.price[price],
            });
          }
        });
        const sortGramOz = filterGramOz
          .sort((a, b) => a.position - b.position)
          .map(value => value.packing);

        quantity = sortGramOz[0];
      }
    }
    addToCart(product, quantity);
    closeModal();
  };

  changeEventButton = () => {
    this.setState({
      updateButton: true,
    });
  };

  renderProductImage = (image, plantType) => {
    if (window.isMobile) {
      return (
        <div className={cx('product-image')} style={{ minHeight: '102px' }}>
          <Image fluid height="auto" size="large" src={image && image.default} alt="" label={plantType ? { as: 'span', content: plantTypes[plantType].name, ribbon: false, style: { left: 'calc(1rem)', top: '1.2em', position: 'absolute', background: plantTypes[plantType].color, color: 'white', borderRadius: '14px', boxShadow: '0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12)', padding: '0.833em 0.833em' } } : null} />
        </div>
      );
    }

    if (window.isTablet && window.isLandscape) {
      return (
        <div
          style={window.isLandscape ? { width: '283px', height: '218px' } : null}
          className={cx('product-image')}
        >
          <Image width={283} height={218} src={image && image.default} label={plantType ? { as: 'span', content: plantTypes[plantType].name, ribbon: false, style: { left: 'calc(1rem)', top: '1.2em', position: 'absolute', background: plantTypes[plantType].color, color: 'white', borderRadius: '14px', boxShadow: '0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12)' } } : null} />
        </div>
      );
    }

    return (
      <div style={{ width: '404px', height: '311px' }}>
        <Image fluid width={405} height={312} src={image && image.default} label={plantType ? { as: 'span', content: plantTypes[plantType].name, ribbon: false, style: { left: 'calc(1rem)', top: '1.2em', position: 'absolute', background: plantTypes[plantType].color, color: 'white', borderRadius: '14px', fontSize: '1.1rem', padding: '0.533em 0.833em', boxShadow: '0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12)' } } : null} />
      </div>
    );
  };

  render() {
    const {
      product,
      isFetching,
      onBackToShop,
      itemsProducts,
      onBuyNow,
      location,
      disabledModalButton,
      storeInfo,
    } = this.props;

    let itemProduct = null;

    if (itemsProducts && itemsProducts[queryString.parse(location.search).product]) {
      itemProduct = Object.assign({}, itemsProducts[queryString.parse(location.search).product]);
    }

    if (isFetching || product === null || typeof product === 'string') {
      return (
        <div className={cx('product-wrapper-spinner')}>
          <Loader active />
        </div>
      );
    }
    let button = (
      <Button style={{ textTransform: 'none' }} color="teal" type="button" onClick={() => this.addToCart(product)}>
        Add to Cart
      </Button>
    );

    if (product.in_cart) {
      button = (
        <Button style={{ textTransform: 'none' }} color="teal" type="button" onClick={onBuyNow}>
          Buy now
        </Button>
      );
    }
    if (product.in_cart && this.state.updateButton) {
      button = (
        <Button style={{ textTransform: 'none' }} color="teal" type="button" onClick={() => this.addToCart(product)}>
          Update now
        </Button>
      );
    }

    if (product.in_cart && this.state.removeButton) {
      button = (
        <Button
          style={{ textTransform: 'none' }}
          negative
          type="button"
          onClick={() => this.removeProductFromCart(product)}
        >
          Remove product
        </Button>
      );
    }

    const brand = product.brand ? `${product.brand}` : '';
    const filterGramOz = [];
    if (product.packing === 'gram_oz') {
      Object.keys(product.price).forEach((price, index) => {
        if (GRAM_OZ.indexOf(price) >= 0) {
          filterGramOz.push({
            position: index,
            packing: price,
            cost: product.price[price],
          });
        }
      });
    }

    return (
      <div className={cx('product-wrapper')}>
        <div className={cx('product-name')}>
          <div>{`${brand} ${product.name}`}</div>
          <div><Rating maxRating={5} defaultRating={5} icon='star' size='massive' /></div>
        </div>
        <div className={cx('content-wrapper')}>
          <div className={cx('product-image-wrapper')}>
            {this.renderProductImage(product.image, product.plant_type)}
            {/* {product.plant_type && <PlantType style={{ top: '15px' }} type={product.plant_type} />} */}
            {
              storeInfo
              && (
                <div className={cx('sold-buy')}>
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Sold by:&nbsp;</span>
                  <span>{storeInfo.name}</span>
                </div>
              )
            }
          </div>

          <div className={cx('product-description')}>
            <div
              style={window.isTablet && window.isLandscape ? { height: '125px' } : null}
              className={cx('description-content')}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: MDRender(product.description),
                }}
              />
            </div>
            <Packing
              itemProduct={itemProduct}
              price={product.price}
              filterGramOz={filterGramOz}
              packing={product.packing}
              setProduct={this.setProduct}
              removeProduct={this.removeProduct}
            />
            <div className={cx('button-group')}>
              <Button type="button" style={{ backgroundColor: '#fff', textTransform: 'none' }} secondary onClick={onBackToShop}>
                Back to Shop
              </Button>
              {disabledModalButton ? null : button}
            </div>
          </div>
        </div>
        <CommentGroup style={{ maxWidth: '100%' }} threaded>
          <Header as='h3' dividing>
            Comments
          </Header>

          <Comment>
            <CommentAvatar src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairSides&accessoriesType=Prescription01&hairColor=PastelPink&facialHairType=BeardMagestic&facialHairColor=Black&clotheType=ShirtScoopNeck&clotheColor=White&graphicType=Skull&eyeType=Hearts&eyebrowType=SadConcerned&mouthType=Disbelief&skinColor=Yellow' />
            <CommentContent>
              <CommentAuthor>You</CommentAuthor>
              <CommentMetadata style={{ marginLeft: '0em' }}>
                <div>Today at 5:42PM</div>
                <div><Rating disabled icon='star' defaultRating={5} maxRating={5} /></div>
              </CommentMetadata>
              <CommentText>How artistic!</CommentText>
              <CommentActions>
                <CommentAction>Reply</CommentAction>
              </CommentActions>
            </CommentContent>
          </Comment>

          <Comment>
            <CommentAvatar src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShaggyMullet&accessoriesType=Round&hairColor=BrownDark&facialHairType=BeardMedium&facialHairColor=Blonde&clotheType=ShirtCrewNeck&clotheColor=Red&eyeType=Cry&eyebrowType=UnibrowNatural&mouthType=Default&skinColor=Pale' />
            <CommentContent>
              <CommentAuthor>Artem Melchakov</CommentAuthor>
              <CommentMetadata style={{ marginLeft: '0em' }}>
                <div>Yesterday at 12:30AM</div>
                <div><Rating disabled icon='star' defaultRating={5} maxRating={5} /></div>
              </CommentMetadata>
              <CommentText>
                <p>This has been very useful for my research. Thanks as well!</p>
              </CommentText>
              <CommentActions>
                <CommentAction>Reply</CommentAction>
              </CommentActions>
            </CommentContent>
            <CommentGroup>
              <Comment>
                <CommentAvatar src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairTheCaesar&accessoriesType=Sunglasses&hairColor=Black&facialHairType=Blank&facialHairColor=Black&clotheType=BlazerShirt&clotheColor=PastelYellow&eyeType=Dizzy&eyebrowType=AngryNatural&mouthType=Twinkle&skinColor=Tanned' />
                <CommentContent>
                  <CommentAuthor>@shop manager</CommentAuthor>
                  <CommentMetadata style={{ marginLeft: '0em' }}>
                    <div>Just now</div>
                  </CommentMetadata>
                  <CommentText>Artem you are always so right :)</CommentText>
                </CommentContent>
              </Comment>
            </CommentGroup>
          </Comment>

          <Comment>
            <CommentAvatar src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairSides&accessoriesType=Prescription02&hairColor=Brown&facialHairType=BeardMedium&facialHairColor=Platinum&clotheType=ShirtScoopNeck&clotheColor=Black&eyeType=Close&eyebrowType=RaisedExcitedNatural&mouthType=Sad&skinColor=Brown' />
            <CommentContent>
              <CommentAuthor>Alex Levanov</CommentAuthor>
              <CommentMetadata style={{ marginLeft: '0em' }}>
                <div>5 days ago</div>
                <div><Rating disabled icon='star' defaultRating={5} maxRating={5} /></div>
              </CommentMetadata>
              <CommentText>Dude, this is awesome. Thanks so much</CommentText>
              <CommentActions>
                <CommentAction>Reply</CommentAction>
              </CommentActions>
            </CommentContent>
          </Comment>

          <Form reply style={{ marginTop: '1em' }}>
            <div style={{ margin: '0 0 1em' }}>
              <TextArea />
            </div>
            <Button style={{ float: 'right' }} color="teal" content='Add Reply' labelPosition='left' icon='edit' primary />
          </Form>
        </CommentGroup>
      </div>
    );
  }
}

export default ModalContent;
