import React from 'react';
import { isNil, getElementType, getUnhandledProps } from 'utils/lib';

import classsnames from 'classnames/bind';
import styles from './Modal.css';

const cx = classsnames.bind(styles);

const ModalDescription = (props) => {
  const { children, className, content } = props;
  const classes = cx('description', className);
  const rest = getUnhandledProps(ModalDescription, props);
  const ElementType = getElementType(ModalDescription, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default ModalDescription;
