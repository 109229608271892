import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames/bind';
import {
  isNil,
  createShorthandFactory,
  getElementType,
  getUnhandledProps,
  useKeyOnly,
  useKeyOrValueAndKey,
  useValueAndKey,
} from 'utils/lib';
import Icon from 'components/Icons/Icon';
import Image from 'components/Image/Image';
import styles from './Label.css';

import LabelDetail from './LabelDetail';

const cx = classnames.bind(styles);

class Label extends Component {
  static propTypes = {
    ribbon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) onClick(e, this.props);
  }

  handleIconOverrides = predefinedProps => ({
    onClick: (e) => {
      _.invoke(predefinedProps, 'onClick', e);
      _.invoke(this.props, 'onRemove', e, this.props);
    },
  })

  render() {
    const {
      active,
      attached,
      basic,
      children,
      circular,
      className,
      color,
      content,
      corner,
      detail,
      empty,
      floating,
      horizontal,
      icon,
      image,
      onRemove,
      pointing,
      removeIcon,
      ribbon,
      size,
      tag,
    } = this.props;

    const pointingClass = (pointing === true && 'pointing')
      || ((pointing === 'left' || pointing === 'right') && `${pointing} pointing`)
      || ((pointing === 'above' || pointing === 'below') && `pointing ${pointing}`);

    const pointingClasses = pointingClass && pointingClass.split(' ') || [];

    const ribbons = (ribbon && useKeyOrValueAndKey(ribbon, 'ribbon').split(' ')) || [];

    const classes = cx(
      'ui',
      color,
      ...pointingClasses,
      size,
      useKeyOnly(active, 'active'),
      useKeyOnly(basic, 'basic'),
      useKeyOnly(circular, 'circular'),
      useKeyOnly(empty, 'empty'),
      useKeyOnly(floating, 'floating'),
      useKeyOnly(horizontal, 'horizontal'),
      useKeyOnly(image === true, 'image'),
      useKeyOnly(tag, 'tag'),
      useKeyOrValueAndKey(corner, 'corner'),
      ...ribbons,
      useValueAndKey(attached, 'attached'),
      'label',
      className
    );

    const rest = getUnhandledProps(Label, this.props);
    const ElementType = getElementType(Label, this.props);

    if (!isNil(children)) {
      return (
        <ElementType {...rest} className={classes} onClick={this.handleClick}>
          {children}
        </ElementType>
      );
    }

    const removeIconShorthand = _.isUndefined(removeIcon) ? 'delete' : removeIcon;

    return (
      <ElementType className={classes} onClick={this.handleClick} {...rest}>
        {Icon.create(icon, { autoGenerateKey: false })}
        {typeof image !== 'boolean' && Image.create(image, { autoGenerateKey: false })}
        {content}
        {LabelDetail.create(detail, { autoGenerateKey: false })}
        {onRemove
          && Icon.create(removeIconShorthand, {
            autoGenerateKey: false,
            overrideProps: this.handleIconOverrides,
          })}
      </ElementType>
    );
  }
}

Label.create = createShorthandFactory(Label, value => ({ content: value }));

export default Label;
