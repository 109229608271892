export const FETCH_LIST_PRODUCTS = 'FETCH_LIST_PRODUCTS';
export const FETCH_LIST_PRODUCTS_SUCCESS = 'FETCH_LIST_PRODUCTS_SUCCESS';
export const FETCH_LIST_PRODUCTS_FAIL = 'FETCH_LIST_PRODUCTS_FAIL';

export const FETCH_PRODUCT_INFORMATION = 'FETCH_PRODUCT_INFORMATION';
export const FETCH_PRODUCT_INFORMATION_SUCCESS = 'FETCH_PRODUCT_INFORMATION_SUCCESS';
export const FETCH_PRODUCT_INFORMATION_FAIL = 'FETCH_PRODUCT_INFORMATION_FAIL';

export const BUY_PRODUCT = 'BUY_PRODUCT';
export const BUY_PRODUCT_CLOSE = 'BUY_PRODUCT_CLOSE';

export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';

export const SEND_ERROR_WITH_STORE_ID = 'SEND_ERROR_WITH_STORE_ID';

export const FETCH_PRODUCT_COMMENTS = 'FETCH_PRODUCT_COMMENTS';
export const FETCH_PRODUCT_COMMENTS_SUCCESS = 'FETCH_PRODUCT_COMMENTS_SUCCESS';
export const FETCH_PRODUCT_COMMENTS_FAIL = 'FETCH_PRODUCT_COMMENTS_FAIL';

export const RESET_PRODUCT_INFORMATION = 'RESET_PRODUCT_INFORMATION';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';

export const SET_RATING_FOR_PRODUCT_CARD = 'SET_RATING_FOR_PRODUCT_CARD';
