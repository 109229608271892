import React, { Component } from 'react';

import Form from 'components/Form/Form';
import FormField from 'components/Form/FormField';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Input/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import TextArea from 'components/TextArea/TextArea';

const options = [
  {
    key: 1,
    text: 'processed',
    value: 'processed',
  },
  {
    key: 2,
    text: 'processing',
    value: 'processing',
  },
  {
    key: 3,
    text: 'received',
    value: 'received',
  },
  {
    key: 4,
    text: 'completed',
    value: 'completed',
  },
  {
    key: 5,
    text: 'rejected',
    value: 'rejected',
  },
];

class UpdateOrder extends Component {
  state = {
    formData: this.props.order,
  }

  componentWillReceiveProps(nextProps) {
    const { order } = this.props;

    if (order !== nextProps.order) {
      this.setState({
        formData: nextProps.order,
      });
    }
  }

  onChange = (e, field) => {
    const { onChange } = this.props;
    const { formData } = this.state;

    if (field) {
      const { value, name } = field;
      formData[name] = value;

      this.setState({
        formData,
      }, () => {
        onChange(formData);
      });
    }
  }

  onUpdate = () => {
    const { order, updateOrder } = this.props;
    const { address, notes, phone, delivery_cost, date, time, aas_state, manager_notes } = this.state.formData;
    const delivery_time = `${date}${time}`;

    //updateOrder(order.id, address, notes, phone, delivery_cost, delivery_time, aas_state, manager_notes);
  }

  renderDate = (value) => {
    const date = new Date(value).toISOString().slice(0, 10);

    return date;
  }

  renderTime = (value) => {
    const time = new Date(value).toLocaleTimeString();

    return time;
  }

  onCheckPhone = (e, field) => {
    const USNumber = field.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    field.value = !USNumber[2]
      ? USNumber[1]
      : `(${USNumber[1]}) ${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;
    this.onChange(e, field);
  };

  render() {
    const { formData } = this.state;
    const { isFetchingShowOrder } = this.props;

    return (
      <Form unstackable loading={isFetchingShowOrder}>
        <FormField>
          <label>Address</label>
          <Input
            name="address"
            size="small"
            fluid
            placeholder="address"
            icon="map marker alternate"
            value={formData && formData.address}
            type="text"
            onChange={this.onChange}
            error={formData && formData.address.length === 0}
          />
        </FormField>
        <FormField>
          <label>Notes</label>
          <Input
            name="notes"
            size="small"
            fluid
            placeholder="notes"
            icon="sticky note"
            value={formData && formData.notes}
            type="text"
            onChange={this.onChange}
          />
        </FormField>
        <FormField>
          <label>Cell phone</label>
          <Input
            name="phone"
            size="small"
            fluid
            placeholder="cell phone"
            icon="phone"
            value={formData && formData.phone}
            type="tel"
            onChange={this.onCheckPhone}
            error={formData && formData.phone.length === 0}
          />
        </FormField>
        <FormField>
          <label>Shipping cost</label>
          <Input
            name="delivery_cost"
            size="small"
            fluid
            placeholder="shipping cost"
            icon="dollar"
            value={formData && formData.delivery_cost}
            type="number"
            onChange={this.onChange}
          />
        </FormField>
        <FormGroup>
          <FormField fluid style={{ width: '50%' }}>
            <label>Delivery date</label>
            <Input
              name="date"
              size="small"
              fluid
              placeholder="date"
              icon="calendar"
              type="date"
              defaultValue={formData && this.renderDate(formData.delivery_time)}
              onChange={this.onChange}
            />
          </FormField>
          <FormField fluid style={{ width: '50%' }}>
            <label>Time</label>
            <Input
              name="time"
              size="small"
              fluid
              placeholder="time"
              icon="time"
              type="time"
              defaultValue={formData && this.renderTime(formData.delivery_time)}
              onChange={this.onChange}
            />
          </FormField>
        </FormGroup>
        <FormField>
          <label>Order status</label>
          <Dropdown
            fluid
            text={formData && formData.status}
            name="aasm_state"
            onChange={this.onChange}
            selection
            options={options}
          />
        </FormField>
        <FormField>
          <label>Manager notes</label>
          <TextArea value={formData && formData.manager_notes} name="manager_notes" onChange={this.onChange} />
        </FormField>
      </Form>
    );
  }
}

export default UpdateOrder;
