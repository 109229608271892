import React from 'react';
import classnames from 'classnames/bind';

import { useTextAlignProp, isNil, getElementType, getUnhandledProps } from 'utils/lib';
import styles from './Card.css';

const cx = classnames.bind(styles);

const CardHeader = (props) => {
  const { children, className, content, textAlign } = props;
  const classes = cx(useTextAlignProp(textAlign), 'header', className);
  const rest = getUnhandledProps(CardHeader, props);
  const ElementType = getElementType(CardHeader, props);

  return (
    <ElementType {...rest} className={classes}>
      {isNil(children) ? content : children}
    </ElementType>
  );
};

export default CardHeader;
